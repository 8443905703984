import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from '../CommunicationModal.styles';

interface Props {
  onClose: () => void;
}

const WaitingForNHDetailsModal: FC<Props> = ({ onClose }) => {
  const styles = useStyles();

  return (
    <Box>
      <Typography variant="body1">
        Please fill in manager’s feedback for defining the NewHome process and sending automated “Welcome to NewHome”
        email.
      </Typography>
      <br />
      <Typography variant="body2">Team mate will not see the feedback, it’s for Talent Operations only.</Typography>
      <Box marginTop={5} className={styles.buttonsContainer}>
        <Button type="button" variant="contained" onClick={onClose}>
          Got it
        </Button>
      </Box>
    </Box>
  );
};

export default WaitingForNHDetailsModal;
