import { PeerFormData } from '@modules/EvaluationModule/interfaces/PeerEvalData';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { useAuth } from '@shared/hooks/useAuth';
import React, { FC } from 'react';
import { useStyles } from './PeerEvalPageHeader.styles';
import format from 'date-fns/format';
import { Stack, Typography } from '@mui/material';
import { ANONYM_USER_IMG } from '../../constants/constants';

interface Props {
  data: PeerFormData;
  isAnonymous: boolean;
}

export const PeerEvalPageHeader: FC<Props> = ({ data, isAnonymous }) => {
  const styles = useStyles();
  const { currentUser } = useAuth();
  const { evaluatee, evaluator, evalutionDate } = data;
  const isPeer = evaluator.id === currentUser?.id;

  return (
    <div className={styles.header}>
      <Stack flexDirection="row" columnGap="6px">
        <RockyAvatar fullName={evaluator.name} medium imageUrl={isAnonymous ? ANONYM_USER_IMG : evaluator.imageUrl} />
        <RockyAvatar fullName={evaluatee.name} medium imageUrl={evaluatee.imageUrl} />
      </Stack>
      <div>
        <Typography variant="h2" className={styles.headerTitle}>{`${isPeer ? 'Your' : evaluator.name} feedback on ${
          evaluatee.name
        }`}</Typography>
        <Typography variant="body2">{evalutionDate && format(new Date(evalutionDate), 'MMMM yy')}</Typography>
      </div>
    </div>
  );
};
