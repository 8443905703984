import React, { useState, useEffect } from 'react';
import { useStyles } from './QuestionRadioInput.styles';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AddCommentIcon from '@mui/icons-material/AddComment';
import classNames from 'classnames';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { EvalQuestionDescription } from '@modules/EvaluationModule/pages/EvalFormAndResultsPage/components/EvaluationForm/components/EvalQuestionBlock/components/EvalQuestionDescription';
import { Field } from 'formik';
import { EvalMarkData } from '@shared/interfaces/EvalMarkData';
import { getColorByFeedbackMark } from '@modules/EvaluationModule/helpers/getColorByFeedbackMark';
import { Textarea } from '@shared/components/Textarea';
import { RockyPopup } from '../RockyPopup';
import { EvaluationMarkDetails } from '@modules/EvaluationModule/interfaces/EvaluationMarkDetails';

interface Props {
  markDetail: EvaluationMarkDetails;
  questionTitle?: string;
  questionMark: string | null;
  questionDescription?: string[];
  questionName?: string;
  error?: string;
  disabled?: boolean;
  markValues: EvalMarkData[];
  classNameMarkLabel?: string;
  questionComments?: string;
  questionPlaceholder?: string;
  isWithComments?: boolean;
}

export const QuestionRadioInput: React.FC<Props> = ({
  questionTitle,
  questionMark,
  questionDescription,
  questionName,
  error,
  disabled,
  markValues,
  classNameMarkLabel,
  questionComments,
  questionPlaceholder,
  isWithComments,
  markDetail,
}) => {
  const styles = useStyles();
  const [chosenMark, setChosenMark] = useState<EvaluationMarkDetails>(markDetail);
  const [isCommentOpen, setIsCommentOpen] = useState(false);

  useEffect(() => {
    setChosenMark(markDetail);
  }, [markDetail]);

  useEffect(() => {
    if (chosenMark.notes) {
      setIsCommentOpen(true);
    }
  }, [chosenMark.notes]);

  return (
    <div className={styles.root}>
      {questionTitle && (
        <span className={classNames(styles.questionTitle, { [styles.questionTitleError]: error })}>
          {questionTitle}
        </span>
      )}
      <div className={styles.marksAndCommentContainer}>
        <div>
          <div className={styles.questionContainer}>
            {markValues.map(({ title, value }) => (
              <label
                onClick={() => setChosenMark({ mark: value, notes: null })}
                key={title}
                className={classNames(
                  styles.markBtn,
                  {
                    [styles.notActiveBtn]: chosenMark.mark && chosenMark.mark !== value,
                  },
                  classNameMarkLabel
                )}
                style={{
                  backgroundColor:
                    chosenMark.mark === value
                      ? getColorByFeedbackMark(chosenMark.mark as EvaluationMark)
                      : chosenMark.mark
                      ? ''
                      : getColorByFeedbackMark(value as EvaluationMark),
                }}
              >
                <Field type="radio" name={questionMark} className={styles.hidden} value={value} disabled={disabled} />
                {title}
              </label>
            ))}
          </div>
          {isCommentOpen && (
            <Field
              component={Textarea}
              isAutosize={true}
              placeholder={questionPlaceholder}
              maxLength={4096}
              name={questionComments}
              disabled={disabled}
              className={styles.comment}
            />
          )}
        </div>
        <div className={styles.iconsContainer}>
          {questionDescription && (
            <RockyPopup
              arrow={false}
              position={'bottom left'}
              on={'click'}
              trigger={(open) => (
                <div className={classNames(styles.icon, styles.infoIcon, { [styles.activeInfoIcon]: open })}>
                  <HelpOutlineIcon />
                </div>
              )}
              className={styles.popup}
            >
              <EvalQuestionDescription questionName={questionName} questionDescription={questionDescription} />
            </RockyPopup>
          )}
          {isWithComments && (
            <div
              className={classNames(styles.icon, styles.commentIcon)}
              onClick={() => setIsCommentOpen((state) => !state)}
            >
              <AddCommentIcon />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
