import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: 16,
    boxShadow: '0px 8px 24px rgba(143, 149, 178, 0.24)',
  },
  feedbackForm: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    gap: 12,
    width: '100%',
  },
  feedbackInput: {
    width: '100%',
    border: `1px solid ${COLORS.INPUTBORDER}`,
    flexGrow: 1,
  },
  submitButton: {
    alignSelf: 'flex-end',
    border: 'none',
    cursor: 'pointer',
    backgroundColor: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    height: 40,
    width: 120,
    fontWeight: '500',
    borderRadius: 8,
  },
  fieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
  },
  selectorsContainer: {
    display: 'flex',
    gap: 10,
  },
});
