import { EditUserGoalData } from '@modules/EvaluationModule/interfaces/EditUserGoalData';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { FormikProps } from 'formik';
import { GoalsFormField } from '../enums/GoalsFormField';

export const customValidationEditGoals = (formik: FormikProps<EditUserGoalData>, data: UserGoalPageData): boolean => {
  let result = true;
  const isExpectedResultChanged = data.worstCaseResult !== formik.values.worstCaseResult;
  const isBestCaseChanged = data.bestCaseResult !== formik.values.bestCaseResult;

  if ((isExpectedResultChanged || isBestCaseChanged) && formik.values.expectationChangeReason === '') {
    formik.setFieldError(GoalsFormField.ExpectationChangeReason, 'Required');
    result = false;
  }

  return result;
};
