import { StylesConfig } from 'react-select';
import { COLORS } from '@styles/colors';

// custom styles for Select component

const disabledColor = `linear-gradient(0deg, ${COLORS.HIGHLIGHTS.NEUTRAL}, ${COLORS.HIGHLIGHTS.NEUTRAL}), ${COLORS.DIVIDER}`;
export const feedbackSelectorCustomStyles: StylesConfig = {
  control: (base, { isDisabled }) => ({
    ...base,
    height: 52,
    border: '1px solid rgba(0 , 0, 0, 0.23)',
    boxShadow: 'none',
    '&:focus': {
      borderColor: COLORS.ACTION.PRIMARY,
    },
    '&:hover': {
      border: '1px solid black',
    },
    background: isDisabled ? disabledColor : 'transparent',
  }),
  input: (base) => ({
    ...base,
    display: 'block',
    width: 200,
    height: 30,
    paddingTop: 5,
    fontSize: '14px',
    boxShadow: 'none',

    '&:focus': {
      borderColor: COLORS.ACTION.PRIMARY,
    },
  }),
  valueContainer: (base) => ({
    ...base,
    marginLeft: 45,
  }),
  dropdownIndicator: (base, { isDisabled, isFocused }) => ({
    ...base,
    display: isDisabled ? 'none' : 'block',
    transform: isFocused ? 'rotate(180deg)' : 'none',
    position: isFocused ? 'absolute' : 'static',
    right: isFocused ? 15 : 0,
    width: 20,
    height: 20,
    fill: 'rgba(0, 0, 0, 0.54)',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    width: 20,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    display: 'none',
  }),
  loadingIndicator: (base) => ({
    ...base,
    position: 'absolute',
    right: 30,
  }),
};
