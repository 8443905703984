import React, { memo, useEffect } from 'react';
import { DataState } from '@shared/enums/DataState';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import Collapsible from 'react-collapsible';
import { QuestionItem } from './components/QuestionItem';
import { useSurveyHistory } from './hooks/useSurveyHistory';
import { useStyles } from './SurveyHistoryPage.styles';
import { UserRate } from '@modules/HappinessModule/components/UserRate/UserRate';
import { PieChart } from '@modules/HappinessModule/components/PieChart';
import Select from 'react-select';
import { selectCustomStyles } from '@styles/SelectCustomStyles';
import { ALL_DEPARTMENTS_OPTION, ALL_LOCATIONS_OPTION } from './constants/constants';
import { DataErrorView } from '@shared/components/DataErrorView';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { setResetSelectedDepartamentId, setResetSelectedLocationName } from './redux';
import { useDispatch } from 'react-redux';

const SurveyHistoryPageComponent: React.FC = () => {
  const { surveyHistoryData, locations, departments, handleLocationsOptionChange, handleDepartmentsOptionChange } =
    useSurveyHistory();
  const dispatch = useDispatch();

  const styles = useStyles();

  const isLoading = surveyHistoryData.state === DataState.Pending;
  const isFailed = surveyHistoryData.state === DataState.Rejected;
  const isLocationsLoading = locations.state !== DataState.Fulfilled;
  const isDepartmentsLoading = departments.state !== DataState.Fulfilled;

  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(pageTitles.surveyHistoryHappinessPage);

    return () => {
      dispatch(setResetSelectedLocationName());
      dispatch(setResetSelectedDepartamentId());
    };
  }, []);

  const questionsBlock =
    surveyHistoryData.data && surveyHistoryData.data.length > 0 ? (
      surveyHistoryData.data.map(({ name, groupId, questions }) => {
        const {
          average: categoryAverage,
          totalVoted: categoryTotalVoted,
          totalPeople: categoryTotalPeople,
        } = questions[0];

        return (
          <Collapsible
            key={groupId}
            trigger={
              <QuestionItem
                title={name}
                rate={categoryAverage}
                votedPeople={categoryTotalVoted}
                totalPeople={categoryTotalPeople}
              />
            }
            transitionTime={200}
          >
            {questions.map(({ average, startDate, text, totalVoted, totalPeople }, i) => {
              average = average || 0;
              totalVoted = totalVoted || 0;
              totalPeople = totalPeople || 0;
              const votedPercent = Number((totalVoted / totalPeople).toFixed(2));

              return (
                <div className={styles.subQuestionContainer} key={i}>
                  <div className={styles.subQuestions}>
                    <span>{text}</span>
                    <span className={styles.surveyDate}>{format(parseISO(startDate), 'dd MMM yyyy')}</span>
                  </div>
                  <div className={styles.subQuestionRate}>
                    <UserRate reminderCount={0} rate={average} />
                  </div>
                  <div className={styles.rateDonut}>
                    <PieChart ratio={votedPercent} />
                  </div>
                  <div className={styles.mobileRate}>{average !== null ? average.toFixed(2) : 0}</div>
                  <div className={styles.responseCount}>
                    {totalVoted}/{totalPeople}
                  </div>
                </div>
              );
            })}
          </Collapsible>
        );
      })
    ) : (
      <h3 className={styles.noDataLabel}>No data</h3>
    );

  const locationsOptions = [
    ALL_LOCATIONS_OPTION,
    ...(locations.data?.map((location) => ({ value: location, label: location })) || []).sort((location1, location2) =>
      location1.label > location2.label ? 1 : -1
    ),
  ];

  const departmentsOptions = [
    ALL_DEPARTMENTS_OPTION,
    ...(departments.data?.map(({ id, name }) => ({ value: id, label: name })) || []).sort((department1, department2) =>
      department1.label > department2.label ? 1 : -1
    ),
  ];

  return isFailed ? (
    <DataErrorView error={surveyHistoryData.error} />
  ) : (
    <div className={styles.root}>
      <div className={styles.titleHolder}>
        <h1 className={styles.historyTitle}>Survey history</h1>
        <div className={styles.selectsWrapper}>
          <Select
            styles={selectCustomStyles}
            defaultValue={ALL_LOCATIONS_OPTION}
            options={locationsOptions}
            onChange={handleLocationsOptionChange}
            isLoading={isLocationsLoading}
          />
          <Select
            styles={selectCustomStyles}
            defaultValue={ALL_DEPARTMENTS_OPTION}
            options={departmentsOptions}
            onChange={handleDepartmentsOptionChange}
            isLoading={isDepartmentsLoading}
          />
        </div>
      </div>
      <RockySkeleton isLoading={isLoading} className={styles.questionSkeleton} element={questionsBlock} count={4} />
    </div>
  );
};

export const SurveyHistoryPage = memo(SurveyHistoryPageComponent);
