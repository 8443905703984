import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { useStyles } from './SearchButton.styles';

interface Props {
  onClick: () => void;
  isTabletMenuOpen?: boolean;
}

export const SearchButton: FC<Props> = ({ onClick, isTabletMenuOpen }) => {
  const styles = useStyles();

  useEffect(() => {
    const handleSearchCombinationPressed = (e: KeyboardEvent) => {
      if (e.code === 'KeyK' && e.ctrlKey) {
        e.preventDefault();
        onClick();
      }
    };
    document.addEventListener('keydown', handleSearchCombinationPressed);
    return () => document.removeEventListener('keydown', handleSearchCombinationPressed);
  }, [onClick]);

  return (
    <div className={styles.buttonContainer} onClick={onClick}>
      <SearchRoundedIcon />
      <div className={classNames(styles.buttonText, { [styles.shown]: isTabletMenuOpen })}>
        <span>Search</span>
        <small>Ctrl + K</small>
      </div>
    </div>
  );
};
