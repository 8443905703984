import { RootState } from '@modules/App/redux/store';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { AsyncData } from '@shared/interfaces/asyncData';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { createGetSortedEvaluationTableDataSelector } from '@modules/EvaluationModule/redux/selectors';

const getSortInfo = (state: RootState): SortInfo => state.evaluation.myTeamEvalPage.sortInfo;
const getMyTeamEvalData = (state: RootState): AsyncData<MyTeamEvalData[]> =>
  state.evaluation.myTeamEvalPage.myTeamEvalData;

export const getSortedTableData = createGetSortedEvaluationTableDataSelector(getMyTeamEvalData, getSortInfo);

export const startingEvalIdSelector = (state: RootState): string => state.evaluation.myTeamEvalPage.startingEvalId;

export const sortMyTeamEvalInfoSelector = (state: RootState): SortInfo => state.evaluation.myTeamEvalPage.sortInfo;

export const failingEopIdSelector = (state: RootState): string => state.evaluation.myTeamEvalPage.failingEopId;

export const extendEvalStateSelector = (state: RootState): string => state.evaluation.myTeamEvalPage.extendEvalState;

export const rescheduleEvalStateSelector = (state: RootState): string =>
  state.evaluation.myTeamEvalPage.rescheduleEvalState;
