import { FC, memo, MouseEvent, useEffect, useRef } from 'react';
import { useStyles } from './ChatQuestionVoteBlock.styles';
import { emojisplosion } from 'emojisplosion';
import { emojiVariants } from '@shared/consts/emojiVariants';
import classNames from 'classnames';
import { ChatMessage } from '@modules/HappinessModule/interfaces/ChatMessage';
import format from 'date-fns/format';
import getTime from 'date-fns/getTime';
import parseISO from 'date-fns/parseISO';
import { useDispatch } from 'react-redux';
import { voteAction } from '../../redux/actions';
import { EmojisplosionProps } from '../../interfaces/EmojisplosionProps';
import { ReactComponent as MessageTail } from '@assets/icons/messageTail.svg';
import { ReactComponent as Logo } from '@assets/icons/logo-small.svg';
import { getBackgroundColorByRate } from '@modules/HappinessModule/helpers/getBackgroundColorByRate';
import { RockyButton } from '@shared/components/RockyButton';
import { getIsSurveyExpired } from '../../helpers/helpers';

interface Props {
  message: ChatMessage;
  latestSurvey: ChatMessage | undefined;
  shouldLaunchEmojisplosion: boolean;
}

const LAUNCHEMOJISPLOSION_DELAY_MS = 300;

const ChatQuestionVoteBlockComponent: FC<Props> = ({ message, latestSurvey, shouldLaunchEmojisplosion }) => {
  const { text, ts, reaction } = message;

  const styles = useStyles();
  const dispatch = useDispatch();

  const time = format(getTime(new Date(ts)), 'HH:mm');
  const reactionBtns = useRef<(HTMLDivElement | null)[]>([]);

  const launchEmojisplosion = ({ emojis, xPos, yPos, fontSize }: EmojisplosionProps) => {
    emojisplosion({
      emojis,
      position: { x: xPos, y: yPos },
      physics: { fontSize },
    });
  };

  useEffect(() => {
    const markExplosion = emojiVariants.find(({ mark }) => mark === reaction);
    if (!reaction || !markExplosion || latestSurvey !== message || !shouldLaunchEmojisplosion) {
      return;
    }
    const currentReactionBtn = reactionBtns.current[reaction - 1];
    if (!currentReactionBtn) {
      return;
    }
    setTimeout(() => {
      launchEmojisplosion({
        emojis: markExplosion.emojis,
        xPos: currentReactionBtn.getBoundingClientRect().right,
        yPos: currentReactionBtn.getBoundingClientRect().bottom,
        fontSize: markExplosion.fontSize,
      });
    }, LAUNCHEMOJISPLOSION_DELAY_MS);
  }, [latestSurvey, message]);

  const handleBtnClick = ({ emojis, xPos, yPos, fontSize }: EmojisplosionProps, mark: number) => {
    if (reaction) {
      return;
    }
    launchEmojisplosion({ emojis, xPos, yPos, fontSize });
    dispatch(voteAction(mark));
  };

  const surveyExpirationDate =
    latestSurvey && latestSurvey.expirationDate ? parseISO(latestSurvey.expirationDate) : null;
  const isSurveyExpired = getIsSurveyExpired(surveyExpirationDate);

  return (
    <div className={styles.root}>
      <div className={styles.avatar}>
        <Logo />
      </div>
      <div className={styles.messageBody}>
        <MessageTail className={styles.rockyMessageTail} />
        <p className={styles.question}>{text}</p>
        <div className={styles.buttonContainer}>
          {emojiVariants.map(({ mark, emojis, fontSize }, i) => (
            <div key={i} ref={(el) => (reactionBtns.current[i] = el)}>
              <RockyButton
                className={classNames(styles.button, { [styles.activeButton]: mark === reaction })}
                style={{
                  backgroundColor:
                    mark === reaction
                      ? getBackgroundColorByRate(reaction)
                      : reaction
                      ? ''
                      : getBackgroundColorByRate(i + 1),
                }}
                disabled={isSurveyExpired || (reaction !== null && mark !== reaction)}
                onClick={(e: MouseEvent<HTMLElement>) => {
                  handleBtnClick({ emojis, xPos: e.clientX, yPos: e.clientY, fontSize }, mark);
                }}
              >
                {mark}
              </RockyButton>
            </div>
          ))}
        </div>
        <div className={styles.responseMarks}>
          <p className={styles.responseText}>1 — No, definitely not</p>
          <p className={styles.responseText}>10 — Yes, absolutely!</p>
        </div>
        {latestSurvey && latestSurvey.expirationDate && isSurveyExpired && (
          <div className={styles.closedNote}>
            <p className={styles.closedSurveyTextNote}>
              This survey was closed on {format(parseISO(latestSurvey.expirationDate), 'dd MMMM yyyy')}
            </p>
          </div>
        )}
        <span className={styles.date}>{time}</span>
      </div>
    </div>
  );
};

export const ChatQuestionVoteBlock = memo(ChatQuestionVoteBlockComponent);
