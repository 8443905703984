import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { COLORS } from '@styles/colors';

const FeedbackMarkColors = {
  exceeds: COLORS.SCALEEVAL.EXCEEDS,
  successful: COLORS.SCALEEVAL.SUCCESSFUL,
  needsDevelopment: COLORS.SCALEEVAL.NEEDSDEV,
  cantEval: COLORS.SCALEEVAL.CANT,
  empty: COLORS.HIGHLIGHTS.NEUTRAL,
};
export const getColorByFeedbackMark = (mark: EvaluationMark): string => {
  return mark === EvaluationMark.Exceeds
    ? FeedbackMarkColors.exceeds
    : mark === EvaluationMark.Successful
    ? FeedbackMarkColors.successful
    : mark === EvaluationMark.NeedsDevelopment
    ? FeedbackMarkColors.needsDevelopment
    : mark === EvaluationMark.CantEval
    ? FeedbackMarkColors.cantEval
    : FeedbackMarkColors.empty;
};
