import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    background: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    width: '100%',
    maxWidth: '1400px',
    margin: '16px auto',
  },
  titleHolder: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    minHeight: 64,
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      paddingBottom: 16,
    },
  },
  selectsWrapper: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      width: '100%',
    },
  },
  noDataLabel: {
    textAlign: 'center',
    padding: '20px 0 40px',
    color: COLORS.TEXT.SECONDARY,
  },
  historyTitle: {
    fontWeight: '500',
    fontSize: 24,
  },
  subQuestionContainer: {
    padding: '8px 24px',
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'repeat(10, 1fr)',
    alignItems: 'center',

    [BREAKPOINTS.MOBILE]: {
      gridTemplateRows: '2fr 1fr',
      padding: '20px 16px',
    },
  },
  surveyDate: {
    fontSize: '12px',
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      marginTop: 10,
    },
  },
  subQuestionRate: {
    gridRow: '1/1',
    gridColumn: '4/8',

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '1/9',
      gridRow: '2/3',
    },
  },
  rateDonut: {
    gridRow: '1/1',
    gridColumn: '8/9',
    justifySelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '7/9',
    },
  },
  mobileRate: {
    display: 'none',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      gridColumn: '9/11',
      justifySelf: 'center',
      gridRow: '2/3',
      fontWeight: '500',
      fontSize: '14px',
      color: COLORS.TEXT.SECONDARY,
    },
  },
  subQuestions: {
    gridRow: '1/1',
    gridColumn: '1/4',
    display: 'flex',
    flexDirection: 'column',

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '1/7',
    },
  },
  responseCount: {
    gridRow: '1/1',
    gridColumn: '9/11',
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '9/11',
    },
  },
  questionSkeleton: {
    minHeight: 50,
    width: 'calc(100% - 20px)',
    margin: 10,

    '&:first-child': {
      marginTop: 20,
    },

    '&:last-child': {
      marginBottom: 20,
    },
  },
});
