import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  tableHeaderRow: {
    zIndex: 1,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },

    '& td:nth-child(1)': {
      paddingLeft: 24,
      minWidth: 250,
      maxWidth: 300,
      width: '25%',
    },
    '& td:nth-child(2)': {
      width: 'auto',
      minWidth: 300,
    },
    '& td:nth-child(3)': {
      width: 'auto',
      maxWidth: 100,
      paddingLeft: 20,
    },
  },
});
