import { FC, memo, useRef, useState } from 'react';
import { useStyles } from './ActionButtons.styles';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { RockyButton } from '@shared/components/RockyButton';
import { paths } from '@shared/enums/paths';
import { StartEvalModal } from '../StartEvalModal/StartEvalModal';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { useModalState } from '@shared/hooks/useModalState';
import { ScheduleEvalMetting } from '../../../ScheduleEvalMetting/ScheduleEvalMetting';
import { StartEvalData } from '@modules/EvaluationModule/interfaces/StartEvalData';
import { FailEopModal } from '../FailEopModal/FailEopModal';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { ReactComponent as ArrowDown } from '@assets/icons/ArrowDown.svg';
import { useClickOutside } from '@shared/hooks/useClickOutside';
import { RescheduleModal } from '../RescheduleModal/RescheduleModal';
import { Stack } from '@mui/material';
interface Props {
  evaluation: MyTeamEvalData;
  handleSendTheFormButtonClick: (startEvalData: StartEvalData) => void;
  handleFailEopButtonClick: (evaluationId: string, failReason: string) => void;
  officeCountry?: string;
}

const ActionButtonsComponent: FC<Props> = ({ evaluation, handleSendTheFormButtonClick, handleFailEopButtonClick }) => {
  const { openModal, closeModal, isModalOpen } = useModalState();
  const { openModal: openExtendModal, closeModal: closeExtendModal, isModalOpen: isExtendModalOpen } = useModalState();
  const { openModal: openEopModal, closeModal: closeEopModal, isModalOpen: isEopModalOpen } = useModalState();
  const styles = useStyles();
  const [isDropdownOpen, setDropdownIsOpen] = useState(false);
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setDropdownIsOpen(false);
  });

  const {
    name,
    email,
    userId,
    status,
    evaluationId,
    isSubmittedByEvaluator,
    isSubmittedByEvaluatee,
    primaryReportsTo,
    typeOfInteraction,
    evaluationDate,
    isExtendable,
  } = evaluation;

  const isEOPExtensionDisabled = !isExtendable;
  const isEop = typeOfInteraction === EvaluationType.EndOfProbation;
  const isScheduleBtnShowen = isSubmittedByEvaluatee && isSubmittedByEvaluator && status === EvaluationStatus.Ongoing;
  const isFillInFormButtonShown = status === EvaluationStatus.Ongoing;

  const dropdownDisplayCondition = isEop
    ? status === EvaluationStatus.Upcoming
    : status === EvaluationStatus.Ongoing || status === EvaluationStatus.Upcoming;

  const isStartEvalButtonShown = status === EvaluationStatus.Upcoming;

  return (
    <div className={styles.buttonsBlock} onClick={(e) => e.stopPropagation()} ref={wrapperRef}>
      <ScheduleEvalMetting
        googleCalendarEvalEventsMeetingLinkData={{
          evaluateeName: name,
          evaluateeEmail: email,
          userId,
          evaluationId,
          evaluatorEmail: primaryReportsTo.email,
        }}
        googleCalendarEvent={evaluation.googleCalendarEvent}
        isScheduleBtnShowen={isScheduleBtnShowen}
        isLoading={evaluation === null}
      />
      <div>
        <Stack direction="row">
          {isFillInFormButtonShown ? (
            <RockyButton
              small
              action
              className={dropdownDisplayCondition ? styles.button : undefined}
              to={`${paths.users}/${userId}/${paths.evaluations}/${evaluationId}`}
            >
              Provide feedback
            </RockyButton>
          ) : isStartEvalButtonShown ? (
            <RockyButton className={styles.button} action small onClick={() => openModal()}>
              Start evaluation
            </RockyButton>
          ) : null}
          {dropdownDisplayCondition && (
            <RockyButton className={styles.extendBtn} action small onClick={() => setDropdownIsOpen(true)}>
              <ArrowDown />
            </RockyButton>
          )}
        </Stack>
        {isDropdownOpen && (
          <div className={styles.extendList}>
            <span className={styles.listTitle}>{isEop ? 'End of probation' : 'Evaluation'}</span>
            {isEop ? (
              <>
                <RockyButton
                  className={styles.listButton}
                  small
                  onClick={openExtendModal}
                  disabled={isEOPExtensionDisabled}
                >
                  Extend probation
                </RockyButton>
                <RockyButton className={styles.listButton} small onClick={openEopModal}>
                  Set as failed 🚷
                </RockyButton>
              </>
            ) : (
              <RockyButton className={styles.listButton} small onClick={openExtendModal}>
                Reschedule
              </RockyButton>
            )}
          </div>
        )}
        <FailEopModal
          evaluationId={evaluationId}
          closeEopModal={closeEopModal}
          isEopModalOpen={isEopModalOpen}
          handleSubmit={handleFailEopButtonClick}
        />
        <RescheduleModal
          isEop={isEop}
          evaluationData={{
            evaluationDate,
            evaluationId,
            evaluateeName: name,
            peoplePartnerName: null,
            status: status as EvaluationStatus,
          }}
          isModalOpen={isExtendModalOpen}
          closeModal={closeExtendModal}
        />
        {isModalOpen && (
          <StartEvalModal
            evaluation={evaluation}
            handleSubmit={handleSendTheFormButtonClick}
            isModalOpen={isModalOpen}
            closeModal={closeModal}
          />
        )}
      </div>
    </div>
  );
};

export const ActionButtons = memo(ActionButtonsComponent);
