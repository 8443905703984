import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
    overflow: 'hidden',
  },
  memberBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 24,
    [BREAKPOINTS.MOBILE]: {
      paddingLeft: 16,
      paddingTop: 8,
    },
  },
  tableHeaderRow: {
    '& td:nth-child(1)': {
      paddingLeft: 24,
      minWidth: 250,
      width: '30%',
    },
    '& td:nth-child(2)': {
      paddingLeft: 24,
      width: '25%',
    },
    '& td:nth-child(3)': {
      paddingLeft: 24,
      width: '30%',
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  tableDataRow: {
    transition: 'background-color 0.1s',
    height: 70,
    backgroundColor: COLORS.WHITE,

    '& td': {
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },
    '&:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
      cursor: 'pointer',
    },

    [BREAKPOINTS.MOBILE]: {
      height: 'auto',
      marginBottom: 4,
      paddingBottom: 12,
      display: 'block',
      borderBottom: `1px solid ${COLORS.DIVIDER}`,

      '& td': {
        borderBottom: 0,
      },
    },
  },
  disabledTableDataRow: {
    '&:hover': {
      backgroundColor: COLORS.WHITE,
      cursor: 'auto',
    },
  },
  peerManager: {
    [BREAKPOINTS.MOBILE]: {
      display: 'none !important',
    },
  },
  peer: {
    [BREAKPOINTS.MOBILE]: {
      display: 'block',
    },
  },
  peerReviewDate: {
    [BREAKPOINTS.MOBILE]: {
      display: 'inline-block',
      width: '50%',
    },
  },
  peerReviewStatus: {
    [BREAKPOINTS.MOBILE]: {
      display: 'inline-block',
      width: '50%',
    },
  },
  statusBlock: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 12,
    paddingRight: 24,
  },
  personInfoBlockSkeleton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  personAvatarSkeleton: {
    width: 40,
    height: 40,
    borderRadius: 14,
    objectFit: 'cover',
  },
  meterSkeleton: {
    width: '70%',
    height: 60,
    marginLeft: 24,
  },
  personBlockSkeleton: {
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,
    gap: 8,
    borderRadius: 8,
    transition: 'all 0.2s',
    marginLeft: 24,
  },
  personNameSkeleton: {
    fontSize: '14px',
    fontWeight: '500',
    margin: 0,
    textDecoration: 'none',
    width: 160,
  },
  positionSkeleton: {
    fontSize: '13px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    margin: '2px 0 0',
    width: 80,
  },
  evaluationDate: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '14px',
    fontWeight: '500',
  },
});
