import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectRolesSenioritiesMap, selectUserPromotions } from '../redux/selectors';
import { loadRolesSenioritiesMap, loadUserPromotions } from '../redux/actions';
import { clearRolesSenioritiesMap, clearUserPromotionsData } from '../redux';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { StreamsRolesSenioritiesMapData } from '@modules/EvaluationModule/interfaces/StreamsRolesSenioritiesMapData';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { getUserEvalPromotion } from '../../EvalPage/redux/actions';
import { evalPromotionSelector } from '../../EvalPage/redux/selectors';

export const useUserPromotionsData = (params?: {
  evalId?: string;
}): {
  userPromotions: AsyncData<UserPromotionsData[]>;
  rolesSenioritiesMap: AsyncData<StreamsRolesSenioritiesMapData[]>;
  isPromotionReadable: boolean;
  isPromotionUpdatable: boolean;
  userPromotionRelatedToEval: AsyncData<UserPromotionsData>;
} => {
  const { evalId } = params ?? {};
  const userPermissions = useSelector(userPermissionsSelector);
  const isPromotionReadable = userPermissions.data?.promotions.isReadable ?? false;
  const isPromotionUpdatable = userPermissions.data?.promotions.isUpdatable ?? false;

  const userPromotions = useSelector(selectUserPromotions);
  const rolesSenioritiesMap = useSelector(selectRolesSenioritiesMap);
  const dispatch = useDispatch();
  const { id } = useParams();

  const userPromotionRelatedToEval = useSelector(evalPromotionSelector);

  useEffect(() => {
    if (!evalId || !isPromotionReadable) {
      return;
    }
    dispatch(getUserEvalPromotion(evalId));
    return () => {
      dispatch(clearRolesSenioritiesMap());
    };
  }, [evalId, isPromotionReadable]);

  useEffect(() => {
    if (!isPromotionUpdatable) {
      return;
    }
    dispatch(loadRolesSenioritiesMap());
    return () => {
      dispatch(clearRolesSenioritiesMap());
    };
  }, [isPromotionUpdatable]);

  useEffect(() => {
    if (!id || !isPromotionReadable) {
      return;
    }
    dispatch(loadUserPromotions(id));
    return () => {
      dispatch(clearUserPromotionsData());
    };
  }, [id, isPromotionReadable]);

  return { userPromotions, rolesSenioritiesMap, isPromotionReadable, isPromotionUpdatable, userPromotionRelatedToEval };
};
