import { StylesConfig } from 'react-select';
import { COLORS } from '@styles/colors';

export const searchChartCustomStyles: StylesConfig = {
  control: (base, { isFocused }) => ({
    ...base,
    height: 36,
    border: `1px solid ${isFocused ? COLORS.ACTION.PRIMARY : COLORS.INPUTBORDER}`,
    boxShadow: 'none',
    color: COLORS.TEXT.SECONDARY,
    background: COLORS.WHITE,
  }),
  input: (base) => ({
    ...base,
    display: 'block',
    marginLeft: 32,
    paddingTop: 3,
    fontSize: '14px',
    boxShadow: 'none',
    color: COLORS.TEXT.PRIMARY,
    height: 36,
  }),
  placeholder: (styles) => ({
    ...styles,
    marginLeft: 32,
    color: COLORS.TEXT.DISABLED,
    paddingTop: 3,
    height: 36,
  }),
  dropdownIndicator: (base, { isDisabled }) => ({
    ...base,
    display: isDisabled ? 'none' : 'block',
  }),
  indicatorsContainer: (styles) => ({ ...styles, display: 'none' }),
  clearIndicator: (styles) => ({ ...styles, display: 'block' }),
};
