import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  skeleton: {
    width: '70%',
    height: 22,
  },
  tableHeaderRow: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    boxShadow: `inset 0 -1px 0 0 ${COLORS.DIVIDER}`,
    position: 'sticky',
    top: -1,
    fontWeight: 500,
    fontSize: 14,
    '& td': {
      paddingLeft: 24,
    },
    '& td:nth-child(1)': {
      minWidth: 220,
      width: '30%',
    },
    '& td:nth-child(2)': {
      width: '20%',
    },
    '& td:nth-child(3)': {
      width: '15%',
    },
    '& td:nth-child(4)': {
      width: '15%',
    },
    '& td:nth-child(5)': {
      width: '20%',
    },
  },
  tableDataRow: {
    transition: 'background-color 0.1s',
    height: 54,
    backgroundColor: COLORS.WHITE,

    [BREAKPOINTS.MOBILE]: {
      width: 600,
    },

    '& td': {
      verticalAlign: 'middle',
      padding: '7px 0px 7px 24px',
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },
    '& td:last-child': {
      paddingRight: 24,
    },
  },
  highlightedText: {
    fontSize: 14,
    fontWeight: 400,
    color: COLORS.TEXT.SECONDARY,
    lineHeight: '24px',
  },
  label: {
    width: '100%',
    padding: 8,
    marginLeft: -8,
    marginRight: -8,
    borderRadius: 8,
    cursor: 'pointer',
    transition: 'background-color 0.1s linear',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
  },
});
