import React, { FC } from 'react';
import { UserRate } from '@modules/HappinessModule/components/UserRate/UserRate';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { paths } from '@shared/enums/paths';
import { Link } from 'react-router-dom';
import { useStyles } from './PeopleHappinessTableRow.styles';
import { UserHappinessTableActions } from '@modules/HappinessModule/components/UsersHappiness/components/UserHappinessTableActions';
import { HappinessPermission } from '@modules/HappinessModule/enums/HappinessPermission';
import { NO_MARK_THRESHOLD } from '@modules/HappinessModule/constants/reportConstants';
import { PeopleHappinessDataItem } from '../../interfaces/PeopleHappinessDataItem';
import { PeopleHappinessVsPreviousReaction } from '../PeopleHappinessVsPreviousReaction';

interface Props {
  dataItem: PeopleHappinessDataItem;
  tableDataRow?: string;
  depth?: number;
  displayPrevReaction?: boolean;
}

const PADDING_LEFT_FOR_PREV_REACTION = 50;

export const PeopleHappinessTableRow: FC<Props> = ({ dataItem, tableDataRow, depth, displayPrevReaction }) => {
  const styles = useStyles();

  const {
    fullName,
    reaction,
    imageUrl,
    project,
    position,
    userId,
    country,
    city,
    resolvedBy,
    meta,
    prevReaction,
    reminderCount,
  } = dataItem;
  const isResolveAvailable = meta.permissions.includes(HappinessPermission.ResolveHappiness);

  return (
    <tr key={userId} className={tableDataRow ? tableDataRow : styles.tableDataRow}>
      <td
        style={{
          paddingLeft: depth,
        }}
      >
        <Link
          className={styles.personBlock}
          to={`${paths.users}/${userId}/${paths.responses}`}
          state={{ path: location.pathname, name: 'team responses' }}
        >
          <UserReporterBlock
            isDetailInfo={true}
            user={{
              name: fullName,
              id: userId,
              position,
              imageUrl,
              city,
              country,
            }}
          />
        </Link>
      </td>
      <td>
        <div className={styles.rateHolder}>
          <UserRate rate={reaction} reminderCount={reminderCount}></UserRate>
        </div>
      </td>
      <td
        style={{
          paddingLeft: displayPrevReaction ? PADDING_LEFT_FOR_PREV_REACTION : undefined,
        }}
      >
        {displayPrevReaction ? (
          <PeopleHappinessVsPreviousReaction currentReaction={reaction} previousReaction={prevReaction} />
        ) : (
          project
        )}
      </td>
      <td>
        <UserHappinessTableActions
          isResolveAvailable={isResolveAvailable}
          userHappinessTableDataItem={{
            ...dataItem,
            name: fullName,
            mark: reaction ?? NO_MARK_THRESHOLD,
            resolvedBy: resolvedBy
              ? {
                  user: {
                    name: resolvedBy.fullName,
                    imageUrl: resolvedBy.imageUrl,
                  },
                  notes: resolvedBy.notes,
                  resolvedAt: resolvedBy.resolvedAt,
                }
              : null,
          }}
        />
      </td>
    </tr>
  );
};
