import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  tableContainer: {
    backgroundColor: COLORS.WHITE,
    borderRadius: '0 0 8px 8px',
    overfow: 'hidden',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',

    [BREAKPOINTS.MOBILE]: {
      background: 'none',
      boxShadow: 'none',
    },
  },
  question: {
    '& > div': {
      border: 'none',
    },
  },
  tableHeader: {
    padding: '20px 24px 24px',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.WHITE,

    [BREAKPOINTS.MOBILE]: {
      padding: '16px 16px 0',
      flexDirection: 'column',
      background: 'none',
    },
  },
  sliderContainer: {
    width: 300,
    marginLeft: 40,

    [BREAKPOINTS.MOBILE]: {
      width: '98%',
    },
  },
  title: {
    fontSize: '24px',
    fontWeight: '500',

    [BREAKPOINTS.MOBILE]: {
      marginBottom: -4,
    },
  },
  filters: {
    marginLeft: 'auto',
  },
  tableDataRow: {
    height: 64,

    '& td:nth-child(3)': {
      width: 'auto',
      maxWidth: 200,
      paddingLeft: 20,
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      backgroundColor: COLORS.WHITE,
      borderRadius: 8,
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
      padding: 16,
      width: '98%',
      marginBottom: 4,
      position: 'relative',

      '& td:nth-child(3)': {
        maxWidth: 'auto',
        padding: '8px 0 0',
      },
    },
  },

  cellSkeleton: {
    width: '80%',
    height: 30,
  },
});
