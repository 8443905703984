import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  mainBlock: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 2.7fr) minmax(0, 1fr)',
    columnGap: 40,
    flexWrap: 'wrap',
    padding: 24,

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
      columnGap: 0,
      display: 'flex',
    },
  },

  mainContent: {
    flexGrow: 1,
  },

  fieldsTitle: {
    fontWeight: '400',
    fontSize: 14,
    color: COLORS.TEXT.PRIMARY,
    margin: '40px 0 8px 0',
  },

  fieldsDescription: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    margin: '-8px 0 8px 0',
  },

  fieldsText: {
    width: '100%',
    height: 100,
    resize: 'none',
    border: 'none',
    boxShadow: `inset 0 0 0 1px ${COLORS.INPUTBORDER}`,
    borderRadius: 4,
    padding: 15,

    '&:focus': {
      boxShadow: `inset 0 0 0 2px ${COLORS.ACTION.PRIMARY}`,
      outline: 'none',
    },
  },

  btnContainer: {
    display: 'flex',
    marginTop: 43,
    alignItems: 'center',
    flexWrap: 'wrap',

    '& button': {
      marginRight: 30,

      [BREAKPOINTS.MOBILE]: {
        width: '100%',
        marginRight: 0,
        marginBottom: 16,
      },
    },

    '& svg': {
      marginRight: 10,
      fill: COLORS.TEXT.SECONDARY,
    },
  },

  helpInfoText: {
    maxWidth: 250,
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },

  modalHeaderStyles: {
    border: 'none',
  },

  modalBodyStyles: {
    maxWidth: 504,
    padding: '0 90px 36px 90px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  attentionModalBodyStyles: {
    maxWidth: 504,
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

  modalTitle: {
    fontWeight: '400',
    fontSize: 24,
    color: COLORS.TEXT.SECONDARY,
    margin: '35px 0 9px 0',
  },

  modalText: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    margin: '0 0 75px 0',
    textAlign: 'center',
  },

  attentionModalTitle: {
    fontWeight: '500',
    fontSize: 22,
    color: COLORS.TEXT.PRIMARY,
  },

  attentionModalAlert: {
    padding: '25px 30px 25px 50px',
    background: COLORS.STATUS.WARNING,
    width: '100%',
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.WHITE,
    marginBottom: 20,
  },

  attentionModalText: {
    padding: '25px 50px 25px 50px',
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
    marginBottom: 53,
  },

  modalBtnContainer: {
    display: 'flex',
    gap: 10,
    marginBottom: 30,
  },

  canselBtn: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.ACTION.PRIMARY,
  },

  submitBtn: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.WHITE,
  },

  reviewContainer: {
    padding: 24,
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
    },
  },

  reviewIconContainer: {
    width: 40,
    height: 40,
    background: COLORS.HIGHLIGHTS.CRITICAL,
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
  },

  reviewLabel: {
    maxWidth: 217,
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
  },

  reviewNotes: {
    background: COLORS.HIGHLIGHTS.PROMINENT,
    borderRadius: 8,
    padding: '16px 24px',
    margin: '15px 100px 0 0',
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
  },

  updateTitle: {
    width: '100%',
    fontWeight: '500',
    fontSize: 24,
    color: COLORS.TEXT.PRIMARY,
    margin: '0 0 30px 0',
  },
  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    maxWidth: 670,
  },
  anonymusBlock: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px 16px',
    gap: 4,
    background: COLORS.HIGHLIGHTS.PROMINENT,
    borderRadius: 8,
  },
});
