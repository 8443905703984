import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getFlagDataAction,
  getFlagSnapshotAction,
  getUserPermissionToModifyFlagAction,
  reopenFlagAction,
} from './actions';
import { setFlagData, setFlagDataLoading, setSnapshotData, setUserPermissionToModifyFlag } from './reducers';
import {
  fetchFlagData,
  fetchFlagSnapshot,
  getUserPermissionToModifyFlag,
  reopenFlag,
} from '@modules/HappinessModule/api/flagData';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { DataState } from '@shared/enums/DataState';
import { FlagSnapshotData } from '@modules/HappinessModule/interfaces/FlagSnapshotData';
import { PermissionAction } from '@modules/HappinessModule/enums/PermissionAction';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';

function* getFlagDataAsync({ payload }: ReturnType<typeof getFlagDataAction>) {
  try {
    const response: FlagData = yield call(fetchFlagData, payload);
    yield put(setFlagData({ data: response, state: DataState.Fulfilled }));
  } catch (error) {
    yield put(setFlagData({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}

function* getFlagSnapshotActionAsync({ payload }: ReturnType<typeof getFlagSnapshotAction>) {
  try {
    yield put(setSnapshotData({ data: null, state: DataState.Pending }));
    const response: FlagSnapshotData = yield call(fetchFlagSnapshot, payload);
    yield put(setSnapshotData({ data: response, state: DataState.Fulfilled }));
  } catch (error) {
    yield put(setSnapshotData({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}

function* getUserPermissionToModifyFlagActionAsync({
  payload,
}: ReturnType<typeof getUserPermissionToModifyFlagAction>) {
  try {
    const response: PermissionAction[] = yield call(getUserPermissionToModifyFlag, payload);
    const setAllFlagsDataAction = setUserPermissionToModifyFlag({
      data: response,
      state: DataState.Fulfilled,
    });
    yield put(setAllFlagsDataAction);
  } catch (e) {
    yield put(
      setUserPermissionToModifyFlag({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* reopenFlagAsync({ payload }: ReturnType<typeof reopenFlagAction>) {
  try {
    yield put(setFlagDataLoading());
    yield call(reopenFlag, payload);
    yield put(getFlagDataAction(payload));
  } catch (error) {
    yield put(setFlagData({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}

function* watchQuerySearch() {
  yield takeLatest(getFlagDataAction.type, getFlagDataAsync);
  yield takeLatest(getFlagSnapshotAction.type, getFlagSnapshotActionAsync);
  yield takeLatest(getUserPermissionToModifyFlagAction.type, getUserPermissionToModifyFlagActionAsync);
  yield takeLatest(reopenFlagAction.type, reopenFlagAsync);
}

export function* flagPageSaga(): Generator {
  yield all([watchQuerySearch()]);
}
