import { FLAG_PAGE_BLOCKS_PADDING } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  mainContainer: {
    padding: FLAG_PAGE_BLOCKS_PADDING,
  },
  footer: {
    padding: '20px 59px',
  },
  reopen: {
    borderRadius: '0.5rem',
    padding: '0.5rem 0.5rem 0.5rem 1rem',
    background: COLORS.HIGHLIGHTS.NEUTRAL,
  },
});
