import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useStyles } from './ManagePromotionModal.styles';
import {
  Alert,
  Autocomplete,
  Dialog,
  DialogContent,
  IconButton,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { Field, FieldProps, useFormikContext } from 'formik';
import { PromotionFormFieldsNames } from '@modules/EvaluationModule/enums/PromotionFormFieldsNames';
import { DatePicker } from '@mui/x-date-pickers';
import { RockyButton } from '@shared/components/RockyButton';
import CloseIcon from '@mui/icons-material/Close';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { UserMainData } from '@shared/interfaces/user';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';
import { AddLinkButton } from '../AddLinkButton/AddLinkButton';
import { PromotionReasons } from '@modules/EvaluationModule/enums/PromotionReasons';
import { ReactComponent as SpreadsheetIcon } from '@assets/icons/spreadsheet.svg';
import { useSelector } from 'react-redux';
import { selectUserPromotions, selectUpdatePromotionState } from '../../redux/selectors';
import { userProfileDataSelector } from '@modules/App/pages/UserProfilePage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import classNames from 'classnames';
import { COLORS } from '@styles/colors';
import { StreamsRolesSenioritiesMapData } from '@modules/EvaluationModule/interfaces/StreamsRolesSenioritiesMapData';
import { UserPromotionFormData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { isEqual } from 'lodash';
import { checkIfSeniorityExist } from '../../helpers/checkIfSeniorityExist';
import { CHANGE_REQUEST_FORM_LINK } from '@shared/consts/links';

interface Props {
  isOpen: boolean;
  closeModal: () => void;
  userData: UserMainData | null;
  isEditMode: boolean;
  rolesSenioritiesMap: StreamsRolesSenioritiesMapData[];
}

//TODO: refactor to smaller components
export const ManagePromotionModal: React.FC<Props> = ({
  isOpen,
  closeModal,
  userData,
  isEditMode,
  rolesSenioritiesMap,
}) => {
  const styles = useStyles();
  const { values, errors, setFieldValue, handleSubmit, submitCount, resetForm, initialValues, validateForm } =
    useFormikContext<UserPromotionFormData>();
  const [isLinksShown, setIsLinksShown] = useState(!!values.pdpLink || !!values.peerInterviewResultsLink);
  const [isNotesShown, setIsNotesShown] = useState(false);
  const { data: userPromotions } = useSelector(selectUserPromotions);
  const updatePromotionState = useSelector(selectUpdatePromotionState);
  const isLoading = updatePromotionState === DataState.Pending;
  const userProfileData = useSelector(userProfileDataSelector);
  const minDate =
    (userPromotions && userPromotions.length > 0 && userPromotions[0].effectiveDate) ||
    userProfileData.data?.dateOfJoin;

  const isSeniorityExist =
    values.role &&
    checkIfSeniorityExist({
      rolesSenioritiesMap,
      role: values.role,
    });

  const isSubmitDisable = isEditMode
    ? isEqual(values, initialValues)
    : values.stream === initialValues.stream &&
      values.seniority === initialValues?.seniority &&
      values.role === initialValues.role;

  const rolesOptions = useMemo(
    () =>
      rolesSenioritiesMap
        .filter((stream) => stream.id === values.stream?.id)
        .map((stream) => stream.designations)
        .flat()
        .map((role) => role),
    [rolesSenioritiesMap, values.stream?.id]
  );

  const seniorityOptions = useMemo(
    () =>
      rolesSenioritiesMap
        .find(
          (stream) => stream.id === values.stream?.id && stream.designations.some((role) => role.id === values.role?.id)
        )
        ?.designations.find((role) => role.id === values.role?.id)
        ?.seniorities?.map((seniority) => seniority),
    [rolesSenioritiesMap, values.stream?.id, values.role?.id]
  );

  const streamOptions = useMemo(() => rolesSenioritiesMap.map((stream) => stream), [rolesSenioritiesMap]);

  useEffect(() => {
    if (updatePromotionState === DataState.Fulfilled) {
      handleClose();
    }
  }, [isLoading]);

  const handleClose = () => {
    setIsNotesShown(false);
    resetForm();
    closeModal();
  };

  const handleAddPdpLink = (value: string) => {
    setFieldValue(PromotionFormFieldsNames.PdpLink, value);
  };

  const handleAddPeerLink = (value: string) => {
    setFieldValue(PromotionFormFieldsNames.PeerInterviewResultsLink, value);
  };

  const getHelperTextAndError = useCallback(
    (error: string | undefined) => {
      if (!error) return null;
      return {
        helperText: submitCount > 0 && error,
        error: !!(error && submitCount),
      };
    },
    [submitCount]
  );

  useEffect(() => {
    validateForm();
  }, [values]);

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <DialogContent sx={{ width: '440px' }}>
        <Typography variant="h2" sx={{ marginBottom: '16px' }}>
          Edit seniority or role
        </Typography>
        <IconButton
          onClick={handleClose}
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Stack direction="row" gap="20px" sx={{ marginBottom: '30px' }}>
          <RockyAvatar
            fullName={userData?.name || ''}
            imageUrl={userData?.imageUrl}
            country={userData?.country}
            city={userData?.city}
            large
          />
          <Stack>
            <Typography className={styles.mainText}>{userData?.name}</Typography>
            <Typography className={styles.secondaryText}>{`${userData?.seniority} ${
              userData?.position ?? null
            }`}</Typography>
            {userData?.primaryDepartment && (
              <Typography className={styles.secondaryText}>{userData?.primaryDepartment.name}</Typography>
            )}
          </Stack>
        </Stack>

        <Stack direction="column" gap="16px">
          <Autocomplete
            value={values.stream}
            onChange={(_, selectedValue) => {
              setFieldValue(PromotionFormFieldsNames.Stream, selectedValue);
              setFieldValue(PromotionFormFieldsNames.Role, null);
              setFieldValue(PromotionFormFieldsNames.Seniority, null);
            }}
            options={streamOptions}
            disableClearable
            limitTags={1}
            getOptionLabel={(option) => option?.name ?? null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => <TextField {...params} label="Stream" {...getHelperTextAndError(errors.stream)} />}
          />
          <Autocomplete
            value={values.role}
            onChange={(_, selectedValue) => {
              setFieldValue(PromotionFormFieldsNames.Role, selectedValue);
              setFieldValue(PromotionFormFieldsNames.Seniority, null);
            }}
            options={rolesOptions}
            disableClearable
            limitTags={1}
            getOptionLabel={(option) => option?.name ?? null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => <TextField {...params} label="Role" {...getHelperTextAndError(errors.role)} />}
          />
          <Autocomplete
            value={values.seniority ?? undefined}
            onChange={(_, selectedValue) => {
              setFieldValue(PromotionFormFieldsNames.Seniority, selectedValue);
            }}
            options={seniorityOptions ?? []}
            disableClearable
            limitTags={1}
            disabled={seniorityOptions === null || !isSeniorityExist ? true : undefined}
            getOptionLabel={(option) => option?.name ?? null}
            isOptionEqualToValue={(option, value) => option?.id === value?.id}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Seniority"
                disabled={seniorityOptions === null || !isSeniorityExist}
                placeholder={
                  seniorityOptions === null || !isSeniorityExist ? 'No seniority grades for this role' : undefined
                }
                InputLabelProps={{ shrink: !!values.seniority || seniorityOptions === null || !isSeniorityExist }}
                {...getHelperTextAndError(errors.seniority)}
              />
            )}
          />
          <Stack direction="row" gap="16px">
            <Autocomplete
              sx={{ width: '100%' }}
              value={values.reason}
              onChange={(_, selectedValue) => {
                setFieldValue(PromotionFormFieldsNames.Reason, selectedValue);
              }}
              options={Object.values(PromotionReasons)}
              isOptionEqualToValue={(option: string, value: string) => option === value}
              renderOption={(props: object, option: string) => (
                <li {...props}>
                  <div
                    className={classNames({
                      [styles.extendedOption]: option === PromotionReasons.Reassessment,
                    })}
                  >
                    {option}
                    {option === PromotionReasons.Reassessment && (
                      <Typography variant="caption" sx={{ color: COLORS.TEXT.SECONDARY, fontSize: '11px' }}>
                        Fixing the wrong assessments of seniority or role
                      </Typography>
                    )}
                  </div>
                </li>
              )}
              disableClearable
              limitTags={1}
              renderInput={(params) => (
                <TextField {...params} fullWidth label="Reason" {...getHelperTextAndError(errors.reason)} />
              )}
            />

            <DatePicker
              label="Effective from"
              views={['year', 'month']}
              openTo="month"
              minDate={minDate}
              inputFormat="MMMM yyyy"
              value={values.effectiveDate}
              disableMaskedInput
              onChange={(newDate: unknown) => {
                setFieldValue(PromotionFormFieldsNames.EffectiveDate, newDate);
              }}
              renderInput={(params) => (
                <TextField {...params} fullWidth variant="outlined" {...getHelperTextAndError(errors.effectiveDate)} />
              )}
            />
          </Stack>

          {isNotesShown ? (
            <Field name={PromotionFormFieldsNames.Notes}>
              {({ field }: FieldProps) => (
                <TextField
                  size="medium"
                  label="Notes"
                  multiline
                  minRows={4}
                  variant="outlined"
                  fullWidth
                  {...field}
                  value={field.value ?? ''}
                />
              )}
            </Field>
          ) : (
            <div onClick={() => setIsNotesShown(true)} className={styles.addBtn}>
              <span>+&nbsp;</span>Add notes
            </div>
          )}

          {isLinksShown ? (
            <Stack direction="row" gap="8px">
              {values.pdpLink && (
                <Link target="_blank" rel="noopener" className={styles.link} href={values.pdpLink!}>
                  <SpreadsheetIcon />
                  &nbsp; PDP
                </Link>
              )}
              <AddLinkButton
                link={values.pdpLink}
                type={ExternalLinksTypes.pdp}
                addLink={handleAddPdpLink}
                isInFormik
                isUpdate={!!values.pdpLink}
              />
              {values.peerInterviewResultsLink && (
                <Link target="_blank" rel="noopener" className={styles.link} href={values.peerInterviewResultsLink!}>
                  <SpreadsheetIcon />
                  &nbsp; Peer interview results
                </Link>
              )}
              <AddLinkButton
                link={values.peerInterviewResultsLink}
                type={ExternalLinksTypes.peerInterviewResultsLink}
                addLink={handleAddPeerLink}
                isInFormik
                isUpdate={!!values.peerInterviewResultsLink}
              />
            </Stack>
          ) : (
            <div onClick={() => setIsLinksShown(true)} className={styles.addBtn}>
              <span>+&nbsp;</span>Add links
            </div>
          )}
        </Stack>
        <Alert severity="info" sx={{ marginTop: '16px' }}>
          <Typography variant="body2" color={COLORS.BLUE.PRUSSIAN}>
            Please fill the{' '}
            <Link target="_blank" href={CHANGE_REQUEST_FORM_LINK}>
              change request form
            </Link>{' '}
            in case TM’s project or manager should be changed too.
          </Typography>
        </Alert>
        <Stack direction="row" gap="8px" justifyContent="right" sx={{ margin: '16px 0' }}>
          <RockyButton onClick={closeModal}>Cancel</RockyButton>
          <RockyButton onClick={handleSubmit} action isLoading={isLoading} disabled={isSubmitDisable}>
            Update
          </RockyButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
