import { UserEvalPageData } from '@modules/EvaluationModule/interfaces/UserEvalPageData';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { User } from '@shared/interfaces/user';
import React, { memo } from 'react';
import EvaluationHistoryTable from './components/EvaluationHistoryTable/EvaluationHistoryTable';
import { useStyles } from './EvaluationHistory.styles';
import EmptyEval from '@assets/img/EmptyEval.png';
import { InfoView } from '@shared/components/InfoView/InfoView';
import { EvaluationInfoViewTexts } from '@modules/EvaluationModule/enums/EvaluationInfoViewTexts';

interface Props {
  data: UserEvalPageData | null;
  currentUser: User | null;
  isDataLoading: boolean;
  onEvalStart?: (id: string) => void;
  onEopFail: (evaluationId: string, failReason: string) => void;
  currentlyStartedEvalId?: string;
  failingEopId?: string;
  officeCountry?: string;
}

const EvaluationHistoryComponent: React.FC<Props> = ({
  data,
  currentUser,
  isDataLoading,
  onEvalStart,
  onEopFail,
  currentlyStartedEvalId,
  failingEopId,
  officeCountry,
}) => {
  const styles = useStyles();

  return (
    <>
      {data?.history.length === 0 ? (
        <InfoView inComponent icon={EmptyEval} text={EvaluationInfoViewTexts.NoUpcomingEvaluations} />
      ) : (
        <div className={styles.root}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <RockySkeleton
                className={styles.titleSkeleton}
                isLoading={isDataLoading}
                element={
                  <>
                    <span className={styles.title}>Evaluation history</span>
                    {data?.history && (
                      <span className={styles.responsesCount}>
                        {data?.history.length} meeting{data?.history.length > 1 ? 's' : ''} since{' '}
                        {data?.user.employmentDate?.slice(0, 4)}
                      </span>
                    )}
                  </>
                }
              />
            </div>
          </div>
          {currentUser && (
            <EvaluationHistoryTable
              currentlyStartedEvalId={currentlyStartedEvalId}
              currentUser={currentUser}
              data={data}
              isDataLoading={isDataLoading}
              onEvalStart={onEvalStart}
              onEopFail={onEopFail}
              failingEopId={failingEopId}
              officeCountry={officeCountry}
            />
          )}
        </div>
      )}
    </>
  );
};

export const EvaluationHistory = memo(EvaluationHistoryComponent);
