import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    position: 'relative',
  },
  orgChart: {
    height: '100%',
    flexGrow: 1,

    '& svg': {
      height: '100%',
    },
  },
  absoluteContainter: {
    display: 'flex',
    alignItems: 'end',
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    pointerEvents: 'none',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      bottom: 0,
      top: 'auto',
      height: 'auto',
    },
  },
  noDataContainer: {
    top: 0,
    left: 0,
    padding: 20,
    paddingBottom: 80,
    width: '100%',
    height: '100%',
    position: 'absolute',
    opacity: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
  },
  noDataContent: {
    margin: 0,
    width: '100%',
    height: '100%',
  },
  hideOrgChart: {
    '& .svg-chart-container': {
      display: 'none',
    },
  },
});
