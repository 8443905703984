import { PermissionAction } from '@modules/HappinessModule/enums/PermissionAction';
import { RootState } from '../store';
import { UserActionsPermissions } from '@modules/App/interfaces/UserActionsPermissions';
import { AsyncData } from '@shared/interfaces/asyncData';

export const compensationPermissionsSelector = (state: RootState): PermissionAction[] | undefined =>
  state.permissions.userActionsPermissions.data?.compensation.compensation;

export const userPermissionsSelector = (state: RootState): AsyncData<UserActionsPermissions> =>
  state.permissions.userActionsPermissions;
