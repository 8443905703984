import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getQuestions } from '../../HappinessModule/redux/questions/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { setSelectedQuestion } from '@modules/HappinessModule/redux/questions/actions';
import { questionsHistorySelector, selectedQuestionSelector } from '../redux/questions/selectors';

export const useQuestionsHistory = (): void => {
  const dispatch = useDispatch();
  const questionsHistory = useSelector(questionsHistorySelector);
  const params = useParams();
  const navigate = useNavigate();
  const selectedQuestion = useSelector(selectedQuestionSelector);

  useEffect(() => {
    // get questions history once if we don't have them when change
    // current page to another where used this component
    if (!questionsHistory) {
      dispatch(getQuestions());
    }
  }, [questionsHistory]);

  useEffect(() => {
    if (questionsHistory) {
      let currentQuestionId = null;
      if (!params.questionId && selectedQuestion) {
        currentQuestionId = selectedQuestion.id;
      } else {
        const questionToSet = questionsHistory.find((q) => q.id === params.questionId) || questionsHistory[0];
        dispatch(setSelectedQuestion(questionToSet));
        currentQuestionId = questionToSet.id;
      }
      navigate(currentQuestionId);
    }
  }, [questionsHistory, params.questionId, selectedQuestion]);
};
