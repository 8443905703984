import { FC, useEffect, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import CrossIcon from '@mui/icons-material/Close';

import { useStyles } from './AddAllReviewersModal.styles';
import { RockyModal } from '@shared/components/RockyModal';
import { RockyButton } from '@shared/components/RockyButton';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  onContinue: (peers: UserInfo[]) => void;
  peers?: UserInfo[];
}

export const AddAllReviewersModal: FC<Props> = ({ isModalOpen, closeModal, onContinue, peers }) => {
  const styles = useStyles();
  const [prs, setPrs] = useState(peers);

  useEffect(() => {
    setPrs(peers);
  }, [peers]);

  const peersCount = prs?.length ?? 0;

  const handleClose = () => {
    setPrs(peers);
    closeModal();
  };

  return (
    <RockyModal title="New reviewers" isOpen={isModalOpen} onClose={handleClose} modalStyles={styles.modal} crossIcon>
      <Typography marginBottom={2}>
        {peersCount} peer{peersCount > 0 && 's'} will receive email once you add them.
      </Typography>

      <Typography marginBottom={2}>Also they will be reminded 3 times each Monday before the evaluation.</Typography>

      <ul className={styles.peerList}>
        {prs?.map((peer) => (
          <li key={peer.id} className={styles.peerListItem}>
            <UserReporterBlock user={peer} isShowEmail isDetailInfo />

            <Button
              variant="text"
              onClick={() => {
                if (prs.length === 1) return handleClose();
                setPrs(prs?.filter((p) => p.id !== peer.id));
              }}
            >
              <CrossIcon />
            </Button>
          </li>
        ))}
      </ul>

      <Stack direction="row" spacing={1} justifyContent="end" marginTop={3}>
        <RockyButton onClick={handleClose}>Cancel</RockyButton>
        <RockyButton
          action
          onClick={() => {
            if (prs) onContinue(prs);
            handleClose();
          }}
        >
          Continue
        </RockyButton>
      </Stack>
    </RockyModal>
  );
};
