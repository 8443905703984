export enum PromotionFormFieldsNames {
  Seniority = 'seniority',
  Role = 'role',
  Reason = 'reason',
  EffectiveDate = 'effectiveDate',
  Notes = 'notes',
  PdpLink = 'pdpLink',
  PeerInterviewResultsLink = 'peerInterviewResultsLink',
  Stream = 'stream',
}
