import * as Yup from 'yup';
import { phone } from 'phone';

const EMPTY_FIELD_ERROR_TEXT = 'Required field';
const INCORECT_NAME_LENGTH = 'Should be shorter that 100 character';
const INCORRECT_NUMBER_FORMAT = 'Incorrect number format';
const INCORECT_EMAIL_FORMAT = 'Should be email';

const fieldsSchema = {
  mobilePhone: Yup.string().test('mobilePhone', INCORRECT_NUMBER_FORMAT, (value) => {
    if (!value) {
      return false;
    }
    return phone(value).isValid;
  }),
  personalEmail: Yup.string().email(INCORECT_EMAIL_FORMAT).required(EMPTY_FIELD_ERROR_TEXT),
  emergencyContactPhone: Yup.string().test('emergencyContactPhone', INCORRECT_NUMBER_FORMAT, (value) => {
    if (!value) {
      return false;
    }
    return phone(value).isValid;
  }),
  emergencyContactName: Yup.string().max(100, INCORECT_NAME_LENGTH).required(EMPTY_FIELD_ERROR_TEXT),
};

export const validationSchema = Yup.object().shape(fieldsSchema);
