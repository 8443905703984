import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCompensationRangeData } from '../redux/actions';
import { setUserCompensationRangeDataLoading } from '../redux/reducers';
import { useNavigate } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { CompensationRange } from '@modules/EvaluationModule/interfaces/CompensationRange';
import { userCompensationRangeSelector } from '../redux/selectors';

export const useUserComopensationRange = (id?: string): AsyncData<CompensationRange> => {
  const navigate = useNavigate();
  const userCompensationRange = useSelector(userCompensationRangeSelector);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) {
      navigate(paths.root);
    } else {
      dispatch(getUserCompensationRangeData(id));
    }

    return () => {
      dispatch(setUserCompensationRangeDataLoading());
    };
  }, []);

  return userCompensationRange;
};
