import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  popupContainer: {
    padding: 24,
    gap: 8,
    display: 'flex',
    flexDirection: 'column',
  },
  btn: {
    backgroundColor: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    border: '1px solid transparent',
    borderRadius: 4,
    fontWeight: '500',
    padding: '10px 20px',
    transition: 'all 0.1s',

    '&[disabled]': {
      backgroundColor: COLORS.TEXT.DISABLEDBG,
      borderColor: COLORS.TEXT.DISABLED,
    },

    '&:hover:not([disabled])': {
      opacity: 0.9,
      cursor: 'pointer',
    },
  },
  btnCancel: {
    backgroundColor: COLORS.ACTION.HIGHLIGHT,
    border: 'none',
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      color: COLORS.ACTION.DARK,
      cursor: 'pointer',
    },
  },
  infoContainer: {
    marginTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 40,
  },
});
