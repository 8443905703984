import { initialValuesProject } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useStyles } from './UserInfoRow.styles';

interface Props {
  title: string;
  icon: React.ReactNode;
  value?: React.ReactNode | null;
  isLoading?: boolean;
  isFeedback?: boolean;
}

export const UserInfoRow: FC<Props> = ({ title, icon, value, isLoading, isFeedback }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const openFeedback = () => {
    dispatch(setIsFeedbackOpen({ isOpen: true, initialValues: initialValuesProject }));
  };

  return (
    <>
      {isLoading ? (
        <div className={styles.infoRow}>
          <RockySkeleton className={styles.iconSkeleton} element={<div></div>} isLoading={isLoading} />
          <div className={styles.infoRowDetail}>
            <RockySkeleton className={styles.mediumTextSkeleton} element={<div></div>} isLoading={isLoading} />
            <RockySkeleton className={styles.smallTextSkeleton} element={<div></div>} isLoading={isLoading} />
          </div>
        </div>
      ) : (
        value && (
          <div className={styles.infoRow}>
            <div className={styles.infoRowIcon}>{icon}</div>
            <div className={styles.infoRowDetail}>
              <div className={styles.secondaryText}>{title}</div>
              <div>{value}</div>
              {isFeedback && (
                <span className={styles.reportIssue} onClick={openFeedback}>
                  Report incorrect project
                </span>
              )}
            </div>
          </div>
        )
      )}
    </>
  );
};
