import { COLORS } from '@styles/colors';

export const getBackgroundColorByRate = (rate: number): string => {
  rate = +rate.toFixed(0);
  return rate === 10
    ? COLORS.SCALE10.VOTE10
    : rate === 9
    ? COLORS.SCALE10.VOTE9
    : rate === 8
    ? COLORS.SCALE10.VOTE8
    : rate === 7
    ? COLORS.SCALE10.VOTE7
    : rate === 6
    ? COLORS.SCALE10.VOTE6
    : rate === 5
    ? COLORS.SCALE10.VOTE5
    : rate === 4
    ? COLORS.SCALE10.VOTE4
    : rate === 3
    ? COLORS.SCALE10.VOTE3
    : rate === 2
    ? COLORS.SCALE10.VOTE2
    : rate === 1
    ? COLORS.SCALE10.VOTE1
    : COLORS.TEXT.PRIMARY;
};
