import { FC, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { FormikErrors } from 'formik';
import { NumericFormat } from 'react-number-format';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Button,
  Tooltip,
  Select,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import CalculateRoundedIcon from '@mui/icons-material/CalculateRounded';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import VerticalAlignTopRoundedIcon from '@mui/icons-material/VerticalAlignTopRounded';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';
import parseISO from 'date-fns/parseISO';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';

import { useStyles } from './EditCompensationModal.styles';
import { RockyButton } from '@shared/components/RockyButton';
import { CompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { useEditCompensationModalData } from '../../hooks/useEditCompensationModalData';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { EditCompensationFields } from '@modules/EvaluationModule/interfaces/EditCompensationFields';
import { formatFinancialValue } from '../../helpers/currencyOptions';
import { isAddNewCompensationCompleteSelector } from '../../redux/selectors';
import { CompensationRange } from '@modules/EvaluationModule/interfaces/CompensationRange';
import { CompensationReason } from '../../enums/CompensationReason';

interface Props {
  currentCompensation: CompensationData;
}

export const EditCompensationModal: FC<Props> = ({ currentCompensation }) => {
  const styles = useStyles();
  const { formik, userCompensationRange, period, setPeriod, user, currentUser } = useEditCompensationModalData();
  const [open, setOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [formTouched, setFormTouched] = useState(false);
  const effectiveFrom = currentCompensation.historicalCompensations[0].period;
  const isAddNewCompensationComplete = useSelector(isAddNewCompensationCompleteSelector);
  const isPeriodValid = period && isValid(period);
  const currentBaseSalary = currentCompensation.historicalCompensations?.[0]?.currentCompensation;

  const handleUpdate = () => {
    formik.validateForm().then((errors: FormikErrors<EditCompensationFields>) => {
      setFormTouched(true);
      if (isEmpty(errors) && isPeriodValid) {
        setConfirmationOpen(true);
        setOpen(false);
      }
    });
  };

  const handleModalClose = () => {
    setOpen(false);
    formik.setFieldValue('compensationRate', currentBaseSalary);
    formik.setFieldValue('reason', '');
    formik.setFieldValue('comments', '');
    setPeriod(addMonths(new Date(), 1));
    setConfirmationOpen(false);
    setFormTouched(false);
  };

  const onClose = (event: MouseEvent, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    handleModalClose();
  };

  const handleBackToEdit = useCallback(() => {
    setConfirmationOpen(false);
    setOpen(true);
  }, []);

  const validatePeriodField = () => {
    if (formTouched && !period) {
      return 'Effective from must be filled in';
    }
    if (period !== null && !isValid(period)) {
      return 'Value should be valid date';
    }
  };

  useEffect(() => {
    if (isAddNewCompensationComplete) {
      setFormTouched(false);
    }
  }, [isAddNewCompensationComplete]);

  const onСompensationRateChange = useCallback(({ floatValue, value }) => {
    const isNumber = /^([0-9](\.[0-9])*)+$/;
    if (value && !isNumber.test(value)) {
      return;
    }
    formik.setFieldValue('compensationRate', floatValue);
  }, []);

  const isExceeded =
    userCompensationRange.data && Number(formik.values.compensationRate) <= userCompensationRange.data.approvedMax;

  const rangeKeys: (keyof CompensationRange)[] = ['min', 'max', 'approvedMax'];
  const isRangeAvailable = rangeKeys.every((key) => userCompensationRange.data?.[key]);

  return (
    <div className={styles.root}>
      <RockyButton action className={styles.reviseBtn} onClick={() => setOpen(true)}>
        <CalculateRoundedIcon color="inherit" />
        Revise base
      </RockyButton>

      <Modal open={open} onClose={onClose}>
        <form className={styles.modalRoot} onSubmit={formik.handleSubmit}>
          <h2 className={styles.modalTitle}>Revise base compensation</h2>
          <div className={styles.modalItem}>
            <div className={styles.itemNameBlock}>
              <div className={styles.nameBlockTitle}>Base compensation</div>
              <div className={styles.nameBlockValue}>
                <span>$</span>
                {formatFinancialValue(currentBaseSalary)}
              </div>
            </div>
            <div className={styles.itemSelectBlock}>
              <FormControl fullWidth sx={{ margin: 0, position: 'relative' }} variant="filled">
                <NumericFormat
                  label="New base"
                  id="compensationRate"
                  name="compensationRate"
                  customInput={TextField}
                  onValueChange={onСompensationRateChange}
                  thousandSeparator={','}
                  decimalScale={2}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography variant="h2" fontWeight={300}>
                          $
                        </Typography>
                      </InputAdornment>
                    ),
                    sx: {
                      '& .MuiFilledInput-input': {
                        fontSize: 24,
                        paddingRight: '40px',
                      },
                    },
                  }}
                  value={formik.values.compensationRate}
                  error={formTouched && Boolean(formik.errors.compensationRate)}
                  variant="filled"
                  allowNegative={false}
                  fullWidth
                />
                {userCompensationRange.data &&
                  (isExceeded ? (
                    <CheckRoundedIcon className={styles.checkIcon} />
                  ) : (
                    <Tooltip title="Above the company range" placement="top">
                      <VerticalAlignTopRoundedIcon className={styles.verticalAlignTopIcon} />
                    </Tooltip>
                  ))}
                {formTouched && Boolean(formik.errors.compensationRate) && (
                  <div className={styles.error}>{formik.errors.compensationRate}</div>
                )}
              </FormControl>
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.itemNameBlock}>
              <div className={styles.nameBlockTitle}>
                Company range
                <Tooltip
                  title={`${userCompensationRange.data?.seniority} (${userCompensationRange.data?.legalLocation})`}
                  placement="top"
                >
                  <HelpOutlineOutlinedIcon color="disabled" fontSize="small" />
                </Tooltip>
              </div>
              {!userCompensationRange.data ? (
                <div className={styles.nonInfo}>Not available</div>
              ) : isRangeAvailable ? (
                <div className={styles.nameBlockRange}>
                  <span>$</span>
                  {formatFinancialValue(userCompensationRange.data.min)}&nbsp;
                  <span>.. $</span>
                  {formatFinancialValue(userCompensationRange.data.max)}&nbsp;
                  <span>.. $</span>
                  {formatFinancialValue(userCompensationRange.data.approvedMax)}
                </div>
              ) : userCompensationRange.data.legalLocation ? (
                <div className={styles.nonInfo}>Not available for {userCompensationRange.data.legalLocation}</div>
              ) : (
                <div className={`${styles.nonInfo} ${styles.nonInfoLocation}`}>Legal country not set</div>
              )}
            </div>
            <div className={styles.itemSelectBlock}>
              <FormControl variant="filled" className={styles.formSelect}>
                <InputLabel id="Reason">Reason</InputLabel>
                <Select
                  labelId="Reason"
                  value={formik.values.reason}
                  name="reason"
                  onChange={formik.handleChange}
                  error={formTouched && Boolean(formik.errors.reason)}
                >
                  {Object.values(CompensationReason).map((reason) => (
                    <MenuItem key={reason} value={reason}>
                      {reason}
                    </MenuItem>
                  ))}
                </Select>
                {formTouched && Boolean(formik.errors.reason) && (
                  <div className={styles.error}>{formik.errors.reason}</div>
                )}
              </FormControl>
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.itemNameBlock}>
              <div className={styles.nameBlockTitle}>Type</div>
              <div className={styles.nameDefaultValue}>{currentCompensation.type}</div>
            </div>
            <div className={styles.itemSelectBlock}>
              <FormControl variant="filled" className={styles.formSelect}>
                <InputLabel id="Type">Type</InputLabel>
                <Select labelId="Type" defaultValue="mounthly" disabled>
                  <MenuItem value="mounthly">{currentCompensation.type}</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className={styles.modalItem}>
            <div className={styles.itemNameBlock}>
              <div className={styles.nameBlockTitle}>Effective from</div>
              <div className={styles.nameDefaultValue}>{format(parseISO(effectiveFrom), 'MMMM yyy')}</div>
            </div>
            <div className={styles.itemSelectBlock}>
              <DatePicker
                className={classnames({ [styles.effectiveFrom]: formTouched && !period })}
                disablePast
                label="Starting from"
                openTo="month"
                views={['year', 'month']}
                value={period}
                onChange={(newValue) => {
                  setPeriod(newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    variant="filled"
                    {...params}
                    autoComplete="false"
                    inputProps={{ ...params.inputProps, placeholder: 'Month of the year' }}
                  />
                )}
              />
              {Boolean(validatePeriodField()) && <div className={styles.error}>{validatePeriodField()}</div>}
            </div>
          </div>
          <div className={styles.modalItem}>
            <TextField
              multiline
              fullWidth
              label="Add notes"
              variant="filled"
              name="comments"
              value={formik.values.comments}
              onChange={formik.handleChange}
            />
          </div>
          <div className={styles.btnContainer}>
            <Button variant="contained" className={styles.cancelBtn} onClick={handleModalClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleUpdate}>
              Update
            </Button>
          </div>
        </form>
      </Modal>
      <ConfirmationModal
        userPosition={user?.position ?? currentUser?.position}
        seniority={userCompensationRange.data?.seniority}
        country={userCompensationRange.data?.legalLocation}
        confirmationOpen={confirmationOpen}
        handleModalClose={handleModalClose}
        handleBackToEdit={handleBackToEdit}
        isExceeded={isExceeded}
        currentCompensation={currentCompensation}
        formik={formik}
        period={period}
      />
    </div>
  );
};
