import { RootState } from '@modules/App/redux/store';
import { StreamsRolesSenioritiesMapData } from '@modules/EvaluationModule/interfaces/StreamsRolesSenioritiesMapData';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';

export const selectUserPromotions = (state: RootState): AsyncData<UserPromotionsData[]> => {
  state.evaluation.userPromotionsPage.userPromotions;
  const data = state.evaluation.userPromotionsPage.userPromotions.data
    ? [...state.evaluation.userPromotionsPage.userPromotions.data].sort((a, b) =>
        new Date(a.effectiveDate) < new Date(b.effectiveDate) ? 1 : -1
      )
    : null;
  const dataState = state.evaluation.userPromotionsPage.userPromotions.state;
  const error = state.evaluation.userPromotionsPage.userPromotions.error;

  return { data, state: dataState, error };
};

export const selectUserPromotionsData = (state: RootState): UserPromotionsData[] | null =>
  state.evaluation.userPromotionsPage.userPromotions.data
    ? [...state.evaluation.userPromotionsPage.userPromotions.data].sort((a, b) =>
        new Date(a.effectiveDate) < new Date(b.effectiveDate) ? 1 : -1
      )
    : null;

export const selectAddLinkState = (state: RootState): DataState => state.evaluation.userPromotionsPage.addLinkState;

export const selectUpdatePromotionState = (state: RootState): DataState =>
  state.evaluation.userPromotionsPage.updatePromotionState;

export const selectRolesSenioritiesMap = (state: RootState): AsyncData<StreamsRolesSenioritiesMapData[]> =>
  state.evaluation.userPromotionsPage.rolesSenioritiesMap;
