import { FlagReason } from '@modules/HappinessModule/enums/FlagsReasons';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import * as Yup from 'yup';
import { FlagFormFields } from './FlagFormFields';
import { flagReasonsOptions } from './flagsReasonsObject';
import { NewHomeAvailableFromTypes } from '@modules/HappinessModule/enums/NewHomeAvailableFromTypes';
import { NewProjectAssignmentData } from '@modules/HappinessModule/interfaces/NewProjectAssignmentData';

type validationSchemaType = {
  [key in keyof Partial<FlagFields>]: Yup.AnySchema;
};

const errorMessages = {
  [FlagFormFields.severity.name]: 'Please select severity',
  [FlagFormFields.description.name]: 'Please enter description',
  [FlagFormFields.primaryTrigger.name]: 'Please select primary reason',
  [FlagFormFields.primarySubReason.name]: 'Please select primary sub-reason',
  [FlagFormFields.secondarySubReason.name]: 'Please select secondary sub-reason',
  [FlagFormFields.isNewHomeNeeded.name]: 'Please select if NewHome is needed',
  [FlagFormFields.resolutionNotes.name]: 'Please enter a resolution notes',
  [FlagFormFields.whatWasDone.name]: 'Please enter what was done',
  [FlagFormFields.suggestedActionItems.name]: 'Please enter suggested action items',
  [FlagFormFields.pros.name]: 'Please enter pros',
  [FlagFormFields.cons.name]: 'Please enter cons',
  [FlagFormFields.softSkills.name]: 'Please enter soft skills',
  [FlagFormFields.companyName.name]: 'Please enter company name',
  [FlagFormFields.hiringType.name]: 'Please select hyring type',
  [FlagFormFields.compensation.name]: 'Please enter compensation',
  [FlagFormFields.currencyType.name]: 'Please select currence type',
  [FlagFormFields.amountType.name]: 'Please select amount type',
  [FlagFormFields.newHomeResolution.name]:
    "Unable to close flag: The 'NewHome' resolution must be set first.\n Please contact the Talent Operations Team for assistance.",
  [FlagFormFields.newHomeAvailableFromType.name]: 'Please select exact date',
  [FlagFormFields.departments.name]: 'Please select at least one affected project',
};

const checkIfThereAreSubReasons = (reason: FlagReason | null) =>
  [...flagReasonsOptions.involuntary.options, ...flagReasonsOptions.voluntary.options].some(
    ({ value, subReasons }) => value === reason && subReasons && subReasons?.length > 0
  );

type validationSchemaNewProjectAssignmentType = {
  [key in keyof Partial<NewProjectAssignmentData>]: Yup.AnySchema;
};

const validationSchemaNewProjectAssignment: validationSchemaNewProjectAssignmentType = {
  startDate: Yup.date().typeError('Set a valid date').nullable(),
};

export const getValidationSchema = (isGeneralFlagInformationEditable: boolean) => {
  const fieldsSchema: validationSchemaType = {
    newProjectAssignment: Yup.object(validationSchemaNewProjectAssignment).nullable(),
    severity: Yup.string().required(errorMessages.severity).nullable(),
    description: Yup.string().required(errorMessages.description).nullable(),
    isNewHomeNeeded: Yup.string().nullable(),
    primaryTrigger: Yup.string().required(errorMessages.primaryTrigger).nullable(),
    primarySubReason: Yup.string()
      .when(FlagFormFields.primaryTrigger.name, {
        is: (primaryTrigger: FlagReason | null) => checkIfThereAreSubReasons(primaryTrigger),
        then: Yup.string().required(errorMessages.primarySubReason).nullable(),
      })
      .nullable(),
    secondarySubReason: Yup.string()
      .when(FlagFormFields.secondaryTrigger.name, {
        is: (secondaryTrigger: FlagReason | null) => checkIfThereAreSubReasons(secondaryTrigger),
        then: Yup.string().required(errorMessages.secondarySubReason).nullable(),
      })
      .nullable(),
    resolutionNotes: Yup.string()
      .when(FlagFormFields.status.name, {
        is: (status: FlagStatus) => status === FlagStatus.Positive || status === FlagStatus.Negative,
        then: Yup.string().required(errorMessages.resolutionNotes).nullable(),
      })
      .nullable(),
    lastDayOnTheProject: Yup.date().typeError('Set a valid date').nullable(),
    whatWasDone: Yup.string().required(errorMessages.whatWasDone).nullable(),
    suggestedActionItems: Yup.string().required(errorMessages.suggestedActionItems).nullable(),
    pros: Yup.string()
      .when(FlagFormFields.isNewHomeNeeded.name, {
        is: (isNewHomeNeeded: string) => isNewHomeNeeded === 'true',
        then: Yup.string().required(errorMessages.pros).nullable(),
      })
      .nullable(),
    cons: Yup.string()
      .when(FlagFormFields.isNewHomeNeeded.name, {
        is: (isNewHomeNeeded: string) => isNewHomeNeeded === 'true',
        then: Yup.string().required(errorMessages.cons).nullable(),
      })
      .nullable(),
    softSkills: Yup.string()
      .when(FlagFormFields.isNewHomeNeeded.name, {
        is: (isNewHomeNeeded: string) => isNewHomeNeeded === 'true',
        then: Yup.string().required(errorMessages.softSkills).nullable(),
      })
      .nullable(),
    newHomeResolution: Yup.string()
      .test(
        'сheck if the resolution of the new home process is settled before the flag is closed when new home is selected',
        errorMessages.newHomeResolution,
        (value, context) => {
          const isNewHomeNeeded = context.parent[FlagFormFields.isNewHomeNeeded.name];
          const flagStatus = context.parent[FlagFormFields.status.name];

          if (
            isNewHomeNeeded === 'true' &&
            (flagStatus === FlagStatus.Negative || flagStatus === FlagStatus.Positive)
          ) {
            return !value ? false : true;
          }

          return true;
        }
      )
      .nullable(),
    newHomeAvailableFrom: Yup.string()
      .when(FlagFormFields.newHomeAvailableFromType.name, {
        is: (newHomeAvailableFromType: NewHomeAvailableFromTypes) =>
          newHomeAvailableFromType === NewHomeAvailableFromTypes.ExactDate,
        then: Yup.string().required(errorMessages.newHomeAvailableFromType).nullable(),
      })
      .nullable(),
    departments: Yup.array().min(isGeneralFlagInformationEditable ? 1 : 0, errorMessages.departments),
  };

  return Yup.object().shape(fieldsSchema);
};
