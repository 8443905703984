import React, { FC } from 'react';
import { NewProjectAssignmentData } from '@modules/HappinessModule/interfaces/NewProjectAssignmentData';
import { Alert, Grid, Link, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { FlagFormFieldsNewProjectAssignment } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagFormFields';
import { FlagFieldBlockPreview } from '../../../FlagFieldBlockPreview';
import { CHANGE_REQUEST_FORM_LINK } from '@shared/consts/links';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';

interface Props {
  newProjectAssignmentData: NewProjectAssignmentData;
}

export const NewHomeProjectFoundPreview: FC<Props> = ({
  newProjectAssignmentData: {
    project,
    nearestEval,
    workload,
    currentSeniority,
    startDate,
    suggestedRaise,
    evaluationNotes,
    timeOffNotes,
  },
}) => {
  const firstRow = [
    {
      label: FlagFormFieldsNewProjectAssignment.project.label,
      value: project.name,
    },
    {
      label: FlagFormFieldsNewProjectAssignment.startDate.label,
      value: format(parseISO(startDate), 'dd MMM yyyy'),
    },
    {
      label: FlagFormFieldsNewProjectAssignment.workload.label,
      value: workload,
    },
  ];

  const secondRow = [
    {
      label: FlagFormFieldsNewProjectAssignment.currentSeniority.label,
      value: currentSeniority,
    },
    {
      label: FlagFormFieldsNewProjectAssignment.nearestEval.label,
      value: format(parseISO(nearestEval.date), 'dd MMM yyyy'),
    },
    {
      label: 'Suggested raise',
      value: suggestedRaise,
    },
  ];

  return (
    <Grid container rowGap="24px">
      <Grid container xs={12}>
        {firstRow.map((item, index) => (
          <Grid item xs={2} key={index}>
            <FlagFieldBlockPreview label={item.label} value={item.value} />
          </Grid>
        ))}
      </Grid>
      <Grid container xs={12}>
        {secondRow.map((item, index) => (
          <Grid item xs={2} key={index}>
            <FlagFieldBlockPreview label={item.label} value={item.value} />
          </Grid>
        ))}
      </Grid>
      {timeOffNotes && (
        <Grid item xs={12}>
          <FlagFieldBlockPreview label={FlagFormFieldsNewProjectAssignment.timeOffNotes.label} value={timeOffNotes} />
        </Grid>
      )}
      {evaluationNotes && (
        <Grid item xs={12}>
          <FlagFieldBlockPreview
            label={FlagFormFieldsNewProjectAssignment.evaluationNotes.label}
            value={evaluationNotes}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        <Alert icon={<TransferWithinAStationIcon color="disabled" />} severity="info">
          <Typography variant="body2" fontSize="14px">
            Please fill the{' '}
            <Link target="_blank" color="inherit" href={CHANGE_REQUEST_FORM_LINK}>
              change request form
            </Link>{' '}
            as well
          </Typography>
        </Alert>
      </Grid>
    </Grid>
  );
};
