import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';

import { useStyles, customAlertStyles, customAlertBlue } from './styles';
import { Sidebar } from '../Sidebar';
import { User } from '@shared/interfaces/user';
import { GlobalSearch } from '@modules/App/components/GlobalSearch';
import { RockyNotifications } from '@modules/App/components/RockyNotifications';
import { paths } from '@shared/enums/paths';
import { usePhysicalLocation } from '@shared/hooks/usePhysicalLocation';
import { useAmplitude } from '@shared/hooks/useAmplitude';

interface Props {
  children: ReactNode;
  currentUser: User;
}

const PHYSICAL_LOCATION_ALERT_CLOSED_KEY = 'physicalLocationInitialAlertClosed';

export const DefaultLayout: React.FC<Props> = ({ children, currentUser }) => {
  const styles = useStyles();
  const shouldShowPhysicalLocationBanner = !localStorage.getItem(PHYSICAL_LOCATION_ALERT_CLOSED_KEY);
  const [open, setOpen] = React.useState(shouldShowPhysicalLocationBanner);
  const { updateLocationAlertOpen, closeUpdateLocationAlert } = usePhysicalLocation(currentUser);
  useAmplitude(currentUser);

  const closeAlert = () => {
    setOpen(false);
    localStorage.setItem(PHYSICAL_LOCATION_ALERT_CLOSED_KEY, 'true');
  };

  return (
    <div className={classNames(styles.root)} id="layout-root">
      <Box sx={{ zIndex: 25, position: 'fixed', top: 0, width: '100%' }}>
        <Collapse in={open}>
          <Alert
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={closeAlert}
                className={styles.alertClose}
              >
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={false}
            sx={{ ...customAlertStyles, ...customAlertBlue }}
          >
            <span className={styles.labelEmoji}>🌎</span> Please check and update{' '}
            <Link
              className={styles.alertLink}
              to={`${paths.users}/${currentUser.id}/${paths.edit}`}
              onClick={closeAlert}
            >
              your address and contacts
            </Link>{' '}
            if necessary. Thank you!
          </Alert>
        </Collapse>
        <Collapse in={updateLocationAlertOpen}>
          <Alert
            action={
              <IconButton aria-label="close" color="inherit" size="small" onClick={closeUpdateLocationAlert}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }
            icon={false}
            sx={{ ...customAlertStyles, ...customAlertBlue }}
          >
            <span className={styles.labelEmoji}>🌎</span>Looks like you've been away for quite a while. Would you like
            to udpdate address in your profile?
            <Link
              className={styles.alertLink}
              to={`${paths.users}/${currentUser.id}/${paths.edit}`}
              onClick={closeUpdateLocationAlert}
            >
              Adjust your location
            </Link>
          </Alert>
        </Collapse>
      </Box>
      <GlobalSearch />
      <Sidebar currentUser={currentUser} />
      <div className={styles.body}>{children}</div>
      <div className={styles.notificationsHolder}>
        <RockyNotifications />
      </div>
    </div>
  );
};
