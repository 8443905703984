import { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Link from '@mui/material/Link';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import { Field, Form, Formik } from 'formik';
import classNames from 'classnames';

import { useStyles } from './EditUserAddressComponent.styles';
import { validationSchema } from '../../helpers/userAddressValidationSchema';
import { userPhysicalAddressUpdatingStateSelector } from '../../redux/selectors';
import { UserAddressFormValuesType, UserAddressFormFields } from './constants/userAddressFormFields';
import { AddressAutocompleteTextField } from './components/AddressAutocompleteTextField';
import { updateUserAddressData } from '../../redux/actions';
import { setUserAddressUpdatingState } from '../../redux';
import { useMetroAreas } from '../../hooks/useMetroAreas';
import { METRO_AREA_REMOTE } from './constants/metroAreasConstants';
import { useUserProfileData } from '../../hooks/useUserProfileData';
import { UserInfoRow } from '../UserInfoRow';
import { UserAddressData } from '@shared/interfaces/UserAddressData';
import { RockyButton } from '@shared/components/RockyButton';
import { DataState } from '@shared/enums/DataState';
import { paths } from '@shared/enums/paths';
import { RocketLoader } from '@shared/components/RocketLoader';
import DeliveryAddress from './components/DeliveryAddress/DeliveryAddress';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { isZohoPeopleUpdateDisabled } from '@configs/environment';
import { ZOHO_PEOPLE_UPDATE_DISABLED_INFO } from '@shared/consts/constants';
import { LoadingButton } from '@mui/lab';

export const EditUserAddressComponent: FC = () => {
  const styles = useStyles();
  const { data: user, state } = useUserProfileData();
  const isLoading = state === DataState.Pending;
  const userPhysicalAddressUpdatingState = useSelector(userPhysicalAddressUpdatingStateSelector);
  const isUpdating = userPhysicalAddressUpdatingState === DataState.Pending;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { data: userPermissions } = useSelector(userPermissionsSelector);

  useEffect(() => {
    if (userPhysicalAddressUpdatingState === DataState.Fulfilled) {
      navigate(`${paths.users}/${id}/${paths.profile}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPhysicalAddressUpdatingState]);

  useEffect(() => {
    return () => {
      dispatch(setUserAddressUpdatingState(DataState.Idle));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = (values: UserAddressFormValuesType) => {
    const payload = { ...values };
    if (!values.selectedMetroArea) payload.selectedMetroArea = METRO_AREA_REMOTE;

    dispatch(updateUserAddressData(payload));
  };

  return isLoading || !userPermissions ? (
    <div className={styles.loaderHolder}>
      <RocketLoader />
    </div>
  ) : !userPermissions?.userInfoPermissions.personal.isUpdatable ? (
    <AccessDeniedView />
  ) : !user?.physicalAddress ? null : (
    <div>
      <Stack direction="row" alignItems="center" spacing={2} className={styles.profileEditHeader}>
        <Typography variant="h2">Edit your address</Typography>
      </Stack>
      <Formik
        enableReinitialize
        initialValues={{
          ...(user.physicalAddress as UserAddressFormValuesType),
          deliveryAddress: {
            type: user.deliveryAddress.type,
            ...user.deliveryAddress.data,
          },
          selectedMetroArea: user.selectedMetroArea,
          clothesSize: user.clothesSize,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ handleChange, setFieldValue, setValues, values }) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const { availableMetroAreas, isMetroAreasLoading, closestMetroArea } = useMetroAreas({
            city: values.city || '',
            country: values.country || '',
            setClosestMetroAreaToForm: (metroArea) => {
              setFieldValue(UserAddressFormFields.selectedMetroArea.name, metroArea);
            },
          });

          // eslint-disable-next-line react-hooks/rules-of-hooks
          const setValuesForAutofillFields = useCallback(
            (value: Required<UserAddressData>) => {
              const newPhysicalAddressValues = {
                addressLine1: value.addressLine1 || values.addressLine1 || '',
                addressLine2: values.addressLine2 || '',
                city: value.city,
                state: value.state,
                country: value.country,
                zipCode: value.zipCode,
              };
              setValues({
                ...values,
                ...newPhysicalAddressValues,
              });
            },
            // eslint-disable-next-line react-hooks/exhaustive-deps
            [values]
          );

          return (
            <Form className={styles.profileEdit}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <Alert
                    severity="info"
                    variant="standard"
                    icon={<LockPersonOutlinedIcon fontSize="inherit" color="secondary" />}
                    sx={{ mt: -2, mb: -4 }}
                  >
                    <Typography variant="body2">Visible only to you, HR, and management</Typography>
                  </Alert>
                </Grid>
                <Grid item xs={12} lg={6} xl={4} sx={{ mb: 6 }}>
                  <Typography variant="h3" sx={{ mb: 4 }}>
                    Physical address
                  </Typography>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <AddressAutocompleteTextField
                        type="text"
                        variant="outlined"
                        name={UserAddressFormFields.addressLine1.name}
                        label={UserAddressFormFields.addressLine1.label}
                        value={values.addressLine1}
                        setValuesForAutofillFields={setValuesForAutofillFields}
                        fieldName={UserAddressFormFields.addressLine1.name}
                        placeholder='Street number, street name, e.g. "123 Main St."'
                        autoComplete="shipping address-line1 street-address"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        name={UserAddressFormFields.addressLine2.name}
                        label={UserAddressFormFields.addressLine2.label}
                        value={values.addressLine2}
                        onChange={handleChange}
                        inputProps={{ maxLength: 100 }}
                        placeholder="Apartment, suite, unit number, etc."
                        autoComplete="shipping address-line2"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <AddressAutocompleteTextField
                        variant="outlined"
                        name={UserAddressFormFields.city.name}
                        label={UserAddressFormFields.city.label}
                        value={values.city}
                        setValuesForAutofillFields={setValuesForAutofillFields}
                        fieldName={UserAddressFormFields.city.name}
                        autoComplete="shipping address-level2"
                        fullWidth
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextField
                        variant="outlined"
                        name={UserAddressFormFields.zipCode.name}
                        label={UserAddressFormFields.zipCode.label}
                        value={values.zipCode}
                        onChange={handleChange}
                        inputProps={{ maxLength: 10 }}
                        fullWidth
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <AddressAutocompleteTextField
                        variant="outlined"
                        name={UserAddressFormFields.state.name}
                        label={UserAddressFormFields.state.label}
                        value={values.state}
                        setValuesForAutofillFields={setValuesForAutofillFields}
                        fieldName={UserAddressFormFields.state.name}
                        placeholder="State/Province/Region"
                        fullWidth
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <AddressAutocompleteTextField
                        variant="outlined"
                        name={UserAddressFormFields.country.name}
                        label={UserAddressFormFields.country.label}
                        value={values.country}
                        setValuesForAutofillFields={setValuesForAutofillFields}
                        fieldName={UserAddressFormFields.country.name}
                        autoComplete="shipping country"
                        required
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="body1" color="text.secondary">
                        Physical address is used for statistics and for sending you occasional gifts&nbsp;
                        <Typography variant="body1" component="span" color="text.primary">
                          🎁
                        </Typography>
                        &nbsp; Please keep it up-to-date here when you're staying for a&nbsp;looong time
                        in&nbsp;different&nbsp; places.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} lg={6} xl={4} sx={{ mb: 6 }}>
                  <Typography variant="h3" sx={{ mb: 3 }}>
                    AgileEngine hybrid teams
                  </Typography>
                  <Typography variant="body1" sx={{ mb: 3 }}>
                    AgileEngine has hybrid teams in&nbsp;metro areas. We&nbsp;work remotely most of&nbsp;the&nbsp;time
                    and meet once or&nbsp;twice a&nbsp;month for&nbsp;work, events, and fun
                    team&nbsp;building&nbsp;activities.
                  </Typography>
                  {isMetroAreasLoading ? (
                    <Box sx={{ display: 'flex', mb: 4 }}>
                      <CircularProgress />
                    </Box>
                  ) : availableMetroAreas && availableMetroAreas?.length > 0 ? (
                    <Box className={styles.metroAreaRadioBtnsBlock}>
                      <FormControl sx={{ display: 'flex' }}>
                        <Field
                          name={UserAddressFormFields.selectedMetroArea.name}
                          as={RadioGroup}
                          defaultValue={closestMetroArea}
                        >
                          <FormLabel>
                            Which team you'd like
                            <br />
                            to be meeting with?
                          </FormLabel>
                          {availableMetroAreas?.map((metroArea) => (
                            <FormControlLabel
                              key={metroArea}
                              value={metroArea}
                              control={<Radio required />}
                              label={
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                  <Typography variant="body1" component="span">
                                    {metroArea}
                                  </Typography>
                                  <Typography variant="body1" component="span" color="text.disabled">
                                    metro area
                                  </Typography>
                                  {closestMetroArea === metroArea && (
                                    <Tooltip placement="top" title="Looks like it’s near you">
                                      <CommuteOutlinedIcon fontSize="small" color="secondary" />
                                    </Tooltip>
                                  )}
                                </Stack>
                              }
                            />
                          ))}
                          <FormControlLabel
                            value={METRO_AREA_REMOTE}
                            control={<Radio required />}
                            label={
                              <Stack direction="column" spacing={0}>
                                <Typography variant="body1" component="span" mt={1}>
                                  I want to work 100% remotely
                                </Typography>
                                <Typography variant="body1" component="span" color="text.secondary">
                                  and I can not attend office even once a month
                                </Typography>
                              </Stack>
                            }
                          />
                        </Field>
                        {values.selectedMetroArea === METRO_AREA_REMOTE && (
                          <Alert
                            severity="info"
                            variant="standard"
                            icon={'🤗'}
                            sx={{
                              mt: 2,
                            }}
                          >
                            Our company culture promotes in-person communication which is&nbsp;why we&nbsp;recommend
                            attending AgileEngine once&nbsp;a&nbsp;month.
                          </Alert>
                        )}
                      </FormControl>
                    </Box>
                  ) : (
                    <Alert
                      severity="info"
                      variant="standard"
                      icon={<CottageOutlinedIcon fontSize="inherit" color="secondary" />}
                      sx={{
                        mb: 2,
                      }}
                    >
                      Looks like there are no&nbsp;offices or&nbsp;hybrid teams near&nbsp;your&nbsp;location.
                    </Alert>
                  )}
                  <Link
                    href="https://sites.google.com/agileengine.com/agileengine-ua/home#h.p_uO297vaW7k2k"
                    target="_blank"
                  >
                    More about hybrid teams
                  </Link>
                </Grid>
              </Grid>
              <Divider sx={{ mb: 6 }} />
              {userPermissions.userInfoPermissions.legalLocation.isReadable && (
                <>
                  <Grid item xs={12} lg={6} xl={4} sx={{ mb: 6 }}>
                    <Typography variant="h3" sx={{ mb: 4 }}>
                      Legal address
                    </Typography>
                    <Stack spacing={2}>
                      <UserInfoRow
                        title="Legal address"
                        icon={<FolderSharedOutlinedIcon />}
                        value={<div className={styles.legalAddress}>{user.legalAddress}</div>}
                      />
                      <Divider
                        sx={{
                          width: '30%',
                        }}
                      />
                      <Typography variant="body1" color="text.secondary">
                        Legal address is used for taxes and contracts.
                        <br />
                        <Link href="mailto:accounting@agileengine.com">Contact accounting</Link> if have an update of
                        it.
                      </Typography>
                    </Stack>
                  </Grid>
                  <Divider sx={{ mb: 6 }} />
                </>
              )}

              <Grid container spacing={8}>
                <Grid item xs={12} lg={6} xl={4} sx={{ mb: 6 }}>
                  <DeliveryAddress />
                </Grid>
              </Grid>

              <Stack direction="row" spacing={1} sx={{ mb: 4 }}>
                <RouterLink
                  to={`${paths.users}/${id}/${paths.profile}`}
                  className={classNames(styles.closeLink, { [styles.disabled]: isUpdating })}
                >
                  <RockyButton isLoading={isUpdating}>Cancel</RockyButton>
                </RouterLink>
                <Tooltip placement="top" title={isZohoPeopleUpdateDisabled ? ZOHO_PEOPLE_UPDATE_DISABLED_INFO : ''}>
                  <LoadingButton
                    variant="contained"
                    loading={isUpdating}
                    type="submit"
                    disabled={isZohoPeopleUpdateDisabled}
                    sx={{
                      '&.Mui-disabled': {
                        pointerEvents: 'auto',
                      },
                    }}
                  >
                    Save changes
                  </LoadingButton>
                </Tooltip>
              </Stack>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
