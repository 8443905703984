import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector } from 'react-redux';
import { selectToms } from '@modules/App/redux/users/selectors';
import { DataState } from '@shared/enums/DataState';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import React, { FC, useEffect, useState } from 'react';
import { Avatar, Chip } from '@mui/material';
import { useFormikContext } from 'formik';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagFormFields } from '../../../constants/FlagFormFields';
import { useStyles } from './AssignedTomSelector.styles';

interface Props {
  disabled: boolean;
}

export const AssignedTomSelector: FC<Props> = ({ disabled }) => {
  const styles = useStyles();
  const { values, setFieldValue } = useFormikContext<FlagFields>();
  const [options, setOptions] = useState<UserInfo[]>([]);
  const value = values.assignedTom ? [values.assignedTom] : [];

  const { state, data } = useSelector(selectToms);
  const isLoading = state === DataState.Pending;

  useEffect(() => {
    if (state === DataState.Fulfilled && data) {
      setOptions(data);
    }
  }, [state, data]);

  return (
    <Autocomplete
      disabled={disabled}
      multiple
      value={value}
      onChange={(_, newValue) => {
        setFieldValue(
          FlagFormFields.assignedTom.name,
          newValue.length ? (newValue[0].id === values.assignedTom?.id ? newValue[1] : newValue[0]) : null
        );
      }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      options={options}
      loading={isLoading}
      filterOptions={(options, state) =>
        options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      }
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <UserReporterBlock user={option} />
        </li>
      )}
      disableClearable
      renderTags={(value: UserInfo[]) =>
        value.map((option, index) => (
          <Chip
            key={index}
            variant="filled"
            avatar={
              <Avatar
                sx={{
                  borderRadius: '50%',
                }}
                variant="circular"
                src={option.imageUrl ?? undefined}
              >
                {option.name}
              </Avatar>
            }
            label={option.name}
            onDelete={disabled ? undefined : () => setFieldValue(FlagFormFields.assignedTom.name, null)}
          />
        ))
      }
      limitTags={1}
      renderInput={(params) => (
        <TextField
          {...params}
          className={styles.assignedTomSelector}
          label={FlagFormFields.assignedTom.label}
          helperText="Only TOM can set this"
        />
      )}
    />
  );
};
