import React, { FC, ReactElement, ReactNode } from 'react';
import { useStyles } from './RockyPopup.styles';
import { Popup } from 'reactjs-popup';
import classNames from 'classnames';
import { EventType, PopupPosition } from 'reactjs-popup/dist/types';

interface Props {
  trigger: ReactElement | ((isOpen: boolean) => ReactElement);
  className?: string;
  children?: ReactNode;
  on?: EventType | EventType[];
  position?: PopupPosition | PopupPosition[];
  offsetY?: number;
  arrow?: boolean;
}

export const RockyPopup: FC<Props> = ({
  trigger,
  className,
  children,
  on = ['hover', 'focus', 'click'],
  position = 'top center',
  offsetY = 5,
  arrow,
}) => {
  const styles = useStyles();
  return (
    <Popup
      arrow={arrow}
      className={classNames(styles.popup, className)}
      position={position}
      on={on}
      trigger={trigger}
      offsetY={offsetY}
      keepTooltipInside
    >
      {children}
    </Popup>
  );
};
