import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    width: 60,
    height: 60,
  },
  meter: {
    transform: 'rotate(-90deg)',
  },
  indicator: {
    position: 'absolute',
    top: '50%',
    left: '52%',
    lineHeight: 0,
    transform: 'translate(-50%, -50%)',
    fontSize: '13px',
    fontWeight: '500',
    letterSpacing: '-0.04em',
  },
  indicatorGreen: {
    color: COLORS.STATUS.SUCCESS,
  },
  indicatorOrange: {
    color: COLORS.STATUS.WARNING,
  },
  indicatorRed: {
    color: COLORS.STATUS.CRITICAL,
  },
});
