import React, { FC, memo } from 'react';
import { COLORS } from '@styles/colors';
import { useStyles } from './PieChart.styles';
import classNames from 'classnames';

interface Props {
  ratio: number;
  onlyIndicator?: boolean;
}

const PieChartComponent: FC<Props> = ({ ratio = 0, onlyIndicator }) => {
  ratio = isNaN(ratio) ? 0 : ratio;
  const styles = useStyles();
  const radius = 25;
  const svgSize = 60;
  const fullCircle = 2 * Math.PI * radius;
  const fill = fullCircle * (1 - ratio);
  const lowResponseRateThreshold = 0.5;
  const goodResponseRateThreshold = 0.8;

  const circleProps = {
    r: radius,
    cx: svgSize / 2,
    cy: svgSize / 2,
    strokeWidth: 3,
  };
  const indicator = (
    <span
      className={classNames({
        [styles.indicator]: !onlyIndicator,
        [styles.indicatorGreen]: ratio >= goodResponseRateThreshold,
        [styles.indicatorRed]: ratio < lowResponseRateThreshold,
        [styles.indicatorOrange]: ratio >= lowResponseRateThreshold && ratio < goodResponseRateThreshold,
      })}
    >
      {(ratio * 100).toFixed(0)}%
    </span>
  );
  return (
    <>
      {onlyIndicator ? (
        indicator
      ) : (
        <div className={styles.root}>
          {indicator}
          <svg className={styles.meter} width={svgSize} height={svgSize}>
            <g>
              <circle {...circleProps} stroke={COLORS.DIVIDER} fill="none" />
              <circle
                {...circleProps}
                strokeDasharray={fullCircle}
                strokeDashoffset={fill}
                stroke={
                  ratio >= goodResponseRateThreshold
                    ? COLORS.STATUS.SUCCESS
                    : ratio >= lowResponseRateThreshold
                    ? COLORS.STATUS.WARNING
                    : COLORS.STATUS.CRITICAL
                }
                fill="none"
              />
            </g>
          </svg>
        </div>
      )}
    </>
  );
};

export const PieChart = memo(PieChartComponent);
