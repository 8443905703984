import React, { useEffect, useState } from 'react';
import { useStyles } from './EditGoalsModal.styles';
import { Dialog, DialogTitle, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { useDispatch, useSelector } from 'react-redux';
import { DataState } from '@shared/enums/DataState';
import { userGoalEditStateSelector } from '../../redux/selectors';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { setUserGoalEditNetworkError } from '../../redux';
import { EditGoalsForm } from '../EditGoalsForm/EditGoalsForm';
import { useSearchParams } from 'react-router-dom';
import { GOALS_SEACH_PARAMS } from '../../constants/searchParamsNames';

interface Props {
  data: UserGoalPageData;
  close: () => void;
  isModalOpen: boolean;
}

export const EditGoalsModal: React.FC<Props> = ({ data, isModalOpen, close }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const userGoalEditState = useSelector(userGoalEditStateSelector);
  const isLoading = userGoalEditState === DataState.Pending;
  const isFailed = userGoalEditState === DataState.Rejected;
  const [customDueDate, setCustomDueDate] = useState<string | null>(null);
  const [searchParams] = useSearchParams();
  const openParamFromUrl = searchParams.get(GOALS_SEACH_PARAMS);

  useEffect(() => {
    if (!isLoading && !isFailed && !openParamFromUrl) {
      handleModalClose();
    }
  }, [isLoading, isFailed]);

  const handleModalClose = () => {
    close();
    dispatch(setUserGoalEditNetworkError(null));
    setCustomDueDate(null);
  };

  useEffect(() => {
    if (data) {
      setCustomDueDate(format(parseISO(data.dueDate), 'MM.dd.yyyy'));
    }
  }, [data]);

  return (
    data && (
      <Dialog open={isModalOpen} fullWidth maxWidth="sm" scroll="body">
        <DialogTitle>
          Edit goal
          <IconButton aria-label="close" className={styles.goalDialogClose} onClick={handleModalClose}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <EditGoalsForm
          data={data}
          handleModalClose={handleModalClose}
          isLoading={isLoading}
          customDueDate={customDueDate}
          setCustomDueDate={setCustomDueDate}
        />
      </Dialog>
    )
  );
};
