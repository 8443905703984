import { SurveyPendingDeletionData } from '@modules/HappinessModule/interfaces/SurveyPendingDeletionData';
import { EditClientSurveyData } from '@modules/HappinessModule/interfaces/EditClientSurveyData';
import { createAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './constants';
import { NewClientSurveyData } from '@modules/HappinessModule/interfaces/NewClientSurveyData';
import { ConfirmClientSurveyData } from '@modules/HappinessModule/interfaces/ConfirmClientSurveyData';
import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';

export const loadClientSurveys = createAction(`${SLICE_NAME}/loadClientSurveys`);
export const createNewClientSurvey = createAction<NewClientSurveyData>(`${SLICE_NAME}/createNewClientSurvey`);
export const updateClientSurvey = createAction<EditClientSurveyData>(`${SLICE_NAME}/updateClientSurvey`);
export const removeClientSurvey = createAction<SurveyPendingDeletionData>(`${SLICE_NAME}/removeClientSurvey`);
export const confirmClientSurvey = createAction<ConfirmClientSurveyData>(`${SLICE_NAME}/confirmClientSurvey`);
export const setUpsertSurvey = createAction<ClientSurveyData | 'NEW' | undefined>(`${SLICE_NAME}/setUpsertSurvey`);
export const loadCategoriesList = createAction(`${SLICE_NAME}/loadCategoriesList`);
