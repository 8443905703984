import httpNodeClient from '@shared/api/httpNodeClient';
import { ClientSurveyData } from '../interfaces/ClientSurveyData';
import { SurveyPendingDeletionData } from '../interfaces/SurveyPendingDeletionData';
import { EditClientSurveyData } from '../interfaces/EditClientSurveyData';
import { NewClientSurveyData } from '../interfaces/NewClientSurveyData';

export const getClientSurveysData = (): Promise<ClientSurveyData[]> =>
  httpNodeClient.get('/client-surveys').then(({ data }) => data);

export const getCategoriesListData = (): Promise<string[]> =>
  httpNodeClient.get('/client-surveys/categories-list').then(({ data }) => data);

export const postNewClientSurvey = (newClientSurveyData: NewClientSurveyData): Promise<ClientSurveyData> =>
  httpNodeClient.post('/client-surveys', newClientSurveyData).then(({ data }) => data);

export const deleteClientSurvey = (deletedClientSurvey: SurveyPendingDeletionData): Promise<ClientSurveyData> =>
  httpNodeClient.delete(`/client-surveys/${deletedClientSurvey.id}`).then(({ data }) => data);

export const editClientSurvey = ({ id, ...editClientSurveyPayload }: EditClientSurveyData): Promise<void> =>
  httpNodeClient.patch(`/client-surveys/${id}`, editClientSurveyPayload).then(({ data }) => data);

export const putConfirmToClientSurvey = (clientSurveyId: string): Promise<void> => {
  return httpNodeClient.post(`/client-surveys/${clientSurveyId}/confirm`).then(({ data }) => data);
};
