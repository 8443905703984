import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import { UserAddressFieldsType, UserAddressFormFields } from '../../../constants/userAddressFormFields';
import { GOOGLE_AUTOCOMPLETE_RESULTS_LANGUAGE } from '../../../constants/googleAutocompleteConstants';

export const useGooglePlacesAutocomplete = ({
  fieldName,
}: {
  fieldName: UserAddressFieldsType;
}): ReturnType<typeof usePlacesService> => {
  const searchConditions = {
    street: fieldName === UserAddressFormFields.addressLine1.name,
    city: fieldName === UserAddressFormFields.city.name,
    state: fieldName === UserAddressFormFields.state.name,
    country: fieldName === UserAddressFormFields.country.name,
  };

  return usePlacesService({
    language: GOOGLE_AUTOCOMPLETE_RESULTS_LANGUAGE,
    debounce: 500,
    options: {
      input: '',
      types: searchConditions.street
        ? ['address']
        : searchConditions.city
        ? ['(cities)']
        : searchConditions.state
        ? ['(regions)']
        : searchConditions.country
        ? ['geocode']
        : undefined,
    },
  });
};
