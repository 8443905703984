import { FailEvalData } from '@modules/EvaluationModule/interfaces/FailEvalData';
import { StartEvalData } from '@modules/EvaluationModule/interfaces/StartEvalData';
import { createAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../constants/SliceName';

export const getMyTeamEvalData = createAction<void>(`${SLICE_NAME}/getMyTeamEvalData`);
export const setMyTeamEvalDataLoading = createAction<void>(`${SLICE_NAME}/setMyTeamEvalDataLoading`);
export const setSelectedEvalsAction = createAction<StartEvalData>(`${SLICE_NAME}/setSelectedEvalsAction`);
export const setStartingEvalId = createAction<string>(`${SLICE_NAME}/setStartingEvalId`);
export const failEop = createAction<FailEvalData>(`${SLICE_NAME}/failEop`);
export const setFailingEopId = createAction<string>(`${SLICE_NAME}/setFailingEopId`);
