import React, { FC, MouseEvent } from 'react';
import { useStyles } from './LinkItem.styles';
import { LinkManageButton } from '@modules/App/pages/ExternalLinksPage/components/LinkManageButton/LinkManageButton';
import { ExternalLinksTypesToNamesMap } from '@modules/App/pages/ExternalLinksPage/redux/constants';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as ClipIcon } from '@assets/icons/clipIcon.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/ArrowRight.svg';
import classnames from 'classnames';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';
import { ExternalLinkBody } from '@modules/App/pages/ExternalLinksPage/interfaces/ExternalLinkBody';

interface Props {
  link: ExternalLinkBody | null;
  type: ExternalLinksTypes;
  id: string;
  canEdit: boolean;
  isVisible: boolean;
}
export const LinkItem: FC<Props> = ({ link, id, type, canEdit, isVisible }) => {
  const styles = useStyles();

  const handleRowClick = (e: MouseEvent) => {
    if (!link || e.target !== e.currentTarget) {
      e.preventDefault();
    }
  };

  return isVisible && (link || canEdit) ? (
    <tr>
      <td className={classnames(styles.tableDataRow, { [styles.tableDataRowLink]: link })}>
        <a className={styles.root} href={link?.link} target="_blank" onClick={(e) => handleRowClick(e)}>
          <Typography variant="body1" className={classnames(styles.title, { [styles.titleLink]: link })}>
            <ClipIcon className={classnames(styles.clipIcon, { [styles.clipIconLink]: link })} />
            {ExternalLinksTypesToNamesMap[type].title}
          </Typography>
          {canEdit ? (
            <LinkManageButton link={link || null} id={id!} canEdit={canEdit} type={type} />
          ) : link ? (
            <IconButton aria-label="Edit" color="primary" size="small" className={styles.linkButton}>
              <ArrowRight />
            </IconButton>
          ) : (
            ''
          )}
        </a>
      </td>
    </tr>
  ) : (
    <></>
  );
};
