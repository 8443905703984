export enum FlagWatcherNotificationStatus {
  NotNotified = 'Not notified',
  NotBeingNotified = 'Not being notified',
  Notified = 'Notified',
  WillNotBeNotified = 'Will not be notified',
  AutoCcRequired = 'Auto CC required',
  NotifiedInCC = 'Notified in CC',
  RTIncludedInTheFlag = 'RT included in the flag',
  RTExcludedFromTheFlag = 'RT excluded from the flag',
}
