import httpNodeClient from '@shared/api/httpNodeClient';
import { CreateUserPromotionData, UpdateUserPromotionData, UserPromotionsData } from '../interfaces/UserPromotionsData';
import { StreamsRolesSenioritiesMapData } from '../interfaces/StreamsRolesSenioritiesMapData';
import { mapPromotionPayload } from './helpers/promotions';

export const getUserPromotions = (userId: string): Promise<UserPromotionsData[]> =>
  httpNodeClient.get<UserPromotionsData[]>('/promotions', { params: { userId } }).then((resp) => resp.data);

export const getRolesSenioritiesMap = (): Promise<StreamsRolesSenioritiesMapData> =>
  httpNodeClient.get<StreamsRolesSenioritiesMapData>('/promotions/roles-seniorities-map').then((resp) => resp.data);

export const postPromotion = (payload: CreateUserPromotionData): Promise<UserPromotionsData> =>
  httpNodeClient.post<UserPromotionsData>('/promotions', mapPromotionPayload(payload)).then((resp) => resp.data);

export const patchUserPromotions = ({
  id,
  payload,
}: {
  id: string;
  payload: UpdateUserPromotionData;
}): Promise<UserPromotionsData> =>
  httpNodeClient.patch<UserPromotionsData>(`/promotions/${id}`, mapPromotionPayload(payload)).then((resp) => resp.data);
