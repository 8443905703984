import { RockyModulesTabs } from '@shared/components/RockyModulesTabs';
import React from 'react';
import { useStyles } from './MyTeamFlagsPage.styles';
import { paths } from '@shared/enums/paths';
import { useMyTeamFlagsData } from './hooks/useMyTeamFlagsData';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { FlagsTable } from '../../components/FlagsTable';
import { FlagsTableHeader } from '@modules/HappinessModule/enums/FlagsTableHeader';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';

export const MyTeamFlagsPage: React.FC = () => {
  const styles = useStyles();
  const myTeamFlagsData = useMyTeamFlagsData();
  const isFailed = myTeamFlagsData.state === DataState.Rejected;
  const { isFlagsEnabled } = useUserEnabledFeatures();
  const isLoading = myTeamFlagsData.state === DataState.Pending;

  const tableHeaders = [
    { title: FlagsTableHeader.Member },
    { title: FlagsTableHeader.FlagDetails },
    { title: FlagsTableHeader.Escalated },
    { title: FlagsTableHeader.Resolved },
    { title: FlagsTableHeader.Status },
    { title: FlagsTableHeader.Actions },
  ];
  return isFailed ? (
    <DataErrorView error={myTeamFlagsData.error} />
  ) : isFlagsEnabled ? (
    <>
      <RockyModulesTabs path={paths.myTeam} flags />
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.title}>Flags ({!isLoading ? myTeamFlagsData.data?.length ?? 0 : '...'})</div>
        </div>
        <FlagsTable
          isDataLoading={isLoading}
          tableHeaderClassName={styles.tableHeader}
          tableDataRowClassName={styles.tableDataRow}
          data={myTeamFlagsData}
          tableHeaders={tableHeaders}
          tableRootClassName={styles.myTeamFlagsTable}
        />
      </div>
    </>
  ) : (
    <AccessDeniedView />
  );
};
