import classNames from 'classnames';
import React, { ChangeEvent, FC, memo } from 'react';
import { useStyles } from './Textarea.styles';
import AutosizeTextarea from 'react-textarea-autosize';

interface Props {
  className?: string;
  placeholder?: string;
  maxLength?: number;
  value?: string;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  field?: {
    name: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onBlur?: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  };
  disabled?: boolean;
  isAutosize?: boolean;
}

const TextareaComponent: FC<Props> = ({
  className,
  placeholder,
  maxLength,
  field,
  disabled,
  isAutosize = false,
  value,
  onChange,
  onKeyDown,
}) => {
  const styles = useStyles();
  if (field) {
    field.value = field.value || '';
  }

  const props = {
    className: classNames(styles.root, className),
    placeholder: placeholder,
    maxLength: maxLength,
    disabled: disabled,
    value,
    onChange,
    onKeyDown,
    ...field,
  };

  return isAutosize ? <AutosizeTextarea {...props}></AutosizeTextarea> : <textarea {...props}></textarea>;
};

export const Textarea = memo(TextareaComponent);
