import httpClient from '@shared/api/httpClient';
import { AxiosResponse } from 'axios';
import { RescheduleEvalPayload } from '../redux/evaluationsManegment';

export const patchExtendEval = (payload: RescheduleEvalPayload): Promise<AxiosResponse<void>> => {
  return httpClient.patch(`/evaluations/${payload.evaluationId}/eop-status/`, {
    eop_status: payload.eopResult,
    evaluation_date: payload.newDate,
  });
};

export const patchRescheduleEval = ({ evaluationId, ...params }: RescheduleEvalPayload): Promise<AxiosResponse<void>> =>
  httpClient.patch<void>(`/evaluations/${evaluationId}/date/`, {
    date: params.newDate,
    modification_reason: params.reason,
  });
