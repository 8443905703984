import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    marginBottom: 8,
  },
  sortField: {
    fontSize: '14px',
    fontWeight: '400',
    margin: 0,
    color: COLORS.TEXT.SECONDARY,
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    zIndex: 2,
  },
  overlayFill: {
    position: 'absolute',
    backgroundColor: COLORS.TEXT.PRIMARY,
    opacity: 0.3,
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    animation: '$fadeIn 0.2s ease-out',
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 0.3 },
  },
  sortContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    backgroundColor: COLORS.WHITE,
    borderTopLeftRadius: 12,
    borderTopRightRadius: 12,
    animation: '$slideUp 0.2s ease-out',
  },
  '@keyframes slideUp': {
    from: { bottom: '-100%' },
    to: { bottom: 0 },
  },
  sortTitle: {
    fontSize: 24,
    fontWeight: '500',
    margin: '4px 0 4px',
  },
  sortHeaderHolder: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '20px 24px 16px 40px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  close: {
    width: 26,
    height: 26,
    position: 'relative',

    '&:before, &:after': {
      position: 'absolute',
      left: 15,
      content: '""',
      height: 26,
      width: 2,
      backgroundColor: COLORS.TEXT.PRIMARY,
    },
    '&:before': {
      transform: 'rotate(45deg)',
    },
    '&:after': {
      transform: 'rotate(-45deg)',
    },
  },
  sortItemsContainer: {
    padding: '20px 20px 100px',
    display: 'flex',
    flexDirection: 'column',
    gap: 4,
  },
  sortItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 8,
    padding: '16px 16px 16px 20px',
  },
  active: {
    backgroundColor: COLORS.DIVIDER,
  },
  fieldTitle: {
    margin: 0,
    fontSize: '14px',
    fontWeight: '500',
  },
});
