import { PeerReviewFeedbackTableDataItem } from '@modules/EvaluationModule/interfaces/PeerReviewFeedbackTableDataItem';

type MyPeerReviewDataFieldsMap = {
  [key in keyof Pick<PeerReviewFeedbackTableDataItem, 'evaluationDate'>]: {
    name: key;
    label: string;
  };
};

export const MyPeerReviewDataFields: MyPeerReviewDataFieldsMap = {
  evaluationDate: {
    name: 'evaluationDate',
    label: 'Month',
  },
};
