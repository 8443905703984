import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  dialogRoot: {
    padding: '77px 90px',
    minWidth: 504,
  },
  goalDialogTitle: {
    textAlign: 'center',
    fontWeight: 500,
    fontSize: 24,
    lineheight: '120%',
    letterSpacing: '-0.04em',
    color: COLORS.TEXT.PRIMARY,
    marginBottom: 10,
  },
  list: {
    padding: 0,
    paddingLeft: 24,
    height: '100px',
    overflow: 'scroll',

    '& li': {
      marginBottom: 8,
    },
  },
});
