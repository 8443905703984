import { COLORS } from '@styles/colors';
import { select } from 'd3';
import { MutableRefObject } from 'react';

export const onNodeUpdate = (d3Container: MutableRefObject<HTMLDivElement | null>): void => {
  select(d3Container.current)
    .selectAll('.link')
    .attr('stroke', (data) => {
      const d = data as { data: { _upToTheRootHighlighted: boolean } };
      return d && d.data && d.data._upToTheRootHighlighted ? COLORS.BRAND.PRIMARY : COLORS.INPUTBORDER;
    })
    .attr('stroke-width', (data) => {
      const d = data as { data: { _upToTheRootHighlighted: boolean } };
      return d && d.data && d.data._upToTheRootHighlighted ? 3 : 1;
    })
    .attr('stroke-dasharray', (data) => {
      const d = data as { data: { originalId?: string; name: string } };
      return d && d.data && d.data.originalId ? '10 5' : null;
    });
  select(d3Container.current)
    .selectAll('rect')
    .attr('stroke', (data) => {
      const d = data as { data: { _upToTheRootHighlighted: boolean } };
      return d && d.data && d.data._upToTheRootHighlighted ? COLORS.BRAND.PRIMARY : COLORS.INPUTBORDER;
    })
    .attr('stroke-dasharray', (data) => {
      const d = data as { data: { originalId?: string; name: string } };
      return d && d.data && d.data.originalId ? '10 5' : null;
    })
    .attr('stroke-width', (data) => {
      const d = data as { data: { _upToTheRootHighlighted: boolean; originalId?: string } };
      return d && d.data && d.data._upToTheRootHighlighted ? 6 : d.data.originalId ? 2 : 0;
    })
    .attr('rx', () => 21);
  select(d3Container.current)
    .selectAll('.node-button-g')
    .attr('style', () => {
      return 'display: none';
    });
};

export const replaceNodeIdInUrl = (id: string): void => window.history.replaceState({}, '', `/org-chart/${id}`);
