import { apiUrlNodeWs } from '@configs/environment';
import { getCustomToken } from '@shared/helpers/auth';
import io, { Socket } from 'socket.io-client';

export const createWebsocketConnection = (): Socket => {
  const token = getCustomToken();

  const socket = io(apiUrlNodeWs!, {
    extraHeaders: {
      Authorization: `Bearer ${token}`,
    },
    path: '/evaluation-meeting',
  });

  return socket;
};
