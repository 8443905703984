import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    overflow: 'auto',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  evalType: {
    gridColumn: '8/14',
    margin: 0,
    padding: 0,
    fontWeight: '400',
    fontSize: '14px',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      margin: '12px 0 0',
    },
  },
  verticalLine: {
    borderRightWidth: 2,
    borderRightStyle: 'solid',
    borderRightColor: COLORS.DIVIDER,
    height: '60%',
    width: 13,
  },
  rowSkeleton: {
    boxSizing: 'border-box',
    width: 'calc(100% - 40px)',
    height: 80,
    margin: '10px 24px',
  },
  singleRowSkeleton: {
    boxSizing: 'border-box',
    width: 'calc(100% - 40px)',
    height: 40,
    margin: '10px 240px',
  },
  upcomingEval: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, 1fr)',
    alignItems: 'center',
    gridTemplateRows: '1fr',
    padding: '10px 24px',
    backgroundColor: COLORS.HIGHLIGHTS.MINT,
  },
  nextEval: {
    gridColumn: '5/12',
    padding: 0,
    margin: 0,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.WHITE,
  },

  managerBlock: {
    gridColumn: '4/8',
    display: 'flex',
    alignItems: 'center',
    gap: 10,

    [BREAKPOINTS.MOBILE]: {
      display: 'inline-block',
      verticalAlign: 'top',
      width: '50%',
    },
  },

  typeHeader: {
    gridColumn: '8/14',
  },

  managerName: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: 5,
    margin: 0,
    fontWeight: '400',
    fontSize: '14px',

    [BREAKPOINTS.MOBILE]: {
      display: 'inline-flex',
    },
  },
  noManagerBlock: {
    gridColumn: '3/5',
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },

  evalItem: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, 1fr)',
    alignItems: 'center',
    gridTemplateRows: '1fr',
    padding: '10px 24px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    transition: 'all 100ms linear',

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
      cursor: 'pointer',
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      padding: '24px 16px',
    },
  },
  nonActiveEvalItem: {
    '&:hover': {
      backgroundColor: COLORS.WHITE,
      cursor: 'auto',
    },
  },
  evalItemDate: {
    gridColumn: '1/3',
    padding: 0,
    margin: 0,
    fontWeight: '400',
    fontSize: '14px',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      fontWeight: '500',
      marginBottom: 12,
    },
  },
  participantLabel: {
    display: 'none',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      margin: '0 0 4px',
      fontSize: 13,
      color: COLORS.TEXT.DISABLED,

      '& + div': {
        display: 'inline-block',
        verticalAlign: 'middle',
        marginRight: 4,

        '& img': {
          width: 24,
          height: 24,
        },
      },
    },
  },
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, 1fr)',
    alignItems: 'center',
    gridTemplateRows: '1fr',
    padding: '16px 24px',
    fontWeight: '400',
    fontSize: '14px',
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  headerMonth: {
    gridColumn: '1/4',
  },
  headerManager: {
    gridColumn: '4/8',
  },
  tableFooter: {
    padding: '20px 24px 32px 24px',
    borderTop: `1px solid ${COLORS.DIVIDER}`,
    display: 'grid',
    gridTemplateColumns: 'repeat(15, 1fr)',
    alignItems: 'center',
    gridTemplateRows: '1fr',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      padding: '10px 16px',

      '& td': {
        display: 'block',
      },
    },
  },
  startWorkDate: {
    gridColumn: '1/3',
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      fontWeight: '500',
      marginBottom: 12,
      marginTop: 12,
    },
  },
  startWork: {
    gridColumn: '3/12',
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },
  formDoneIcon: {
    fill: COLORS.STATUS.SUCCESS,
    height: 19,
  },
  actionBtn: {
    gridColumn: '14/16',
  },
  starting: {
    background:
      'linear-gradient(90deg, #A2DA8B 5.88%, #D1ED97 17.06% , #A2DA8B 29.12%, #A2DA8B 55.88%, #D1ED97 67.06%, #A2DA8B 79.12%)',
    backgroundSize: '200%',
    animation: '$startingBg 3s infinite linear',
  },
  '@keyframes startingBg': {
    '0%': { backgroundPositionX: '100%' },
    '50%': { backgroundPositionX: '0%' },
    '100%': { backgroundPositionX: '100%' },
  },
  backdrop: {
    position: 'absolute',
    cursor: 'auto',
    backgroundColor: COLORS.WHITE,
    opacity: 0.3,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
  },
  canceling: {
    background:
      'linear-gradient(90deg, #f23535 5.88%, #fc8383 17.06% , #f23535 29.12%, #f23535 55.88%, #fc8383 67.06%, #f23535 79.12%)',
    backgroundSize: '200%',
    animation: '$startingBg 3s infinite linear',
  },
});
