import React, { FC, memo, useCallback } from 'react';
import { useStyles } from './AccessDeniedView.styles';
import { ReactComponent as AccessDenied } from '@assets/icons/AccessDenied.svg';
import classNames from 'classnames';
import { Link, useNavigate } from 'react-router-dom';
import { paths } from '@shared/enums/paths';

interface Props {
  inComponent?: boolean;
}
const AccessDeniedViewComponent: FC<Props> = ({ inComponent }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <div className={classNames(styles.contentBlock, { [styles.inComponent]: inComponent })}>
      <div>
        <AccessDenied />
      </div>
      <p className={classNames(styles.infoText, styles.infoTextTitle)}>You're not allowed to see this</p>
      <p className={styles.infoText}>
        Sorry, you probably don't have <br />
        such permissions
      </p>
      <div className={styles.buttonsContainer}>
        <button className={styles.link} onClick={goBack}>
          &larr; Back
        </button>
        <Link className={styles.link} to={paths.root}>
          Home
        </Link>
      </div>
    </div>
  );
};

export const AccessDeniedView = memo(AccessDeniedViewComponent);
