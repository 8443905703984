import { UserActionsPermissions } from '@modules/App/interfaces/UserActionsPermissions';
import { Permissions } from '@shared/interfaces/Permissions';
import { UserPromotionsPermissions } from '@modules/EvaluationModule/enums/userPromotionsPermissions';
import { PermissionAction } from '@modules/HappinessModule/enums/PermissionAction';

export const getUserPromotionsPermissions = (allPermissions: UserActionsPermissions): Permissions => {
  return {
    isReadable: allPermissions.permissions.includes(UserPromotionsPermissions.Read),
    isUpdatable: allPermissions.compensation.compensation.includes(PermissionAction.Update),
    isCreatable: false,
  };
};
