import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  modalBtnsContainer: {
    marginTop: 24,
    display: 'flex',
    gap: 12,
    width: '100%',
    justifyContent: 'flex-end',
  },
});
