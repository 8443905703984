import httpNodeClient from '@shared/api/httpNodeClient';
import { CreateUserGoalData } from '../interfaces/CreateUserGoalData';
import { EditUserGoalData } from '../interfaces/EditUserGoalData';
import { GetUserGoalsPayload } from '../interfaces/GetUserGoalsPayload';
import { UserGoalPageData } from '../interfaces/UserGoalPageData';

export const fetchUserGoalsPageData = (payload: GetUserGoalsPayload): Promise<UserGoalPageData[]> =>
  httpNodeClient
    .get<UserGoalPageData[]>('/smart-goals', { params: { userId: payload.userId, status: payload.filter } })
    .then((resp) => resp.data);

export const fetchUserAchievedGoalsDataForEval = (userId: string): Promise<UserGoalPageData[]> =>
  httpNodeClient.get<UserGoalPageData[]>(`/smart-goals/achieved-since-last-eval/${userId}`).then((resp) => resp.data);

export const patchUserGoal = (payload: EditUserGoalData): Promise<UserGoalPageData> =>
  httpNodeClient.patch<UserGoalPageData>(`/smart-goals/${payload.id}`, payload).then((resp) => resp.data);

export const postUserGoal = (payload: CreateUserGoalData): Promise<UserGoalPageData> =>
  httpNodeClient.post<UserGoalPageData>('/smart-goals/', payload).then((resp) => resp.data);
