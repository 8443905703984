import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  btnsContainer: {
    display: 'flex',
    gap: 6,
  },
  title: {
    display: 'flex',
    gap: 12,
  },
  resetBtn: {
    '&:hover svg': {
      fill: COLORS.WHITE,
    },
  },
});
