import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  rootVirtualized: {
    height: 'calc(100vh - 64px)',
    marginBottom: -20,
    minWidth: '1000px',

    [BREAKPOINTS.MOBILE]: {
      height: 'calc(100vh - 114px)',
    },
  },
  tableHeaderRow: {
    '& td:nth-child(1)': {
      paddingLeft: 24,
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  tableDataRow: {
    transition: 'background-color 0.1s',
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
    height: 70,

    '& td': {
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },
    '&:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'grid',
      height: 'auto',
      position: 'relative',
      backgroundColor: COLORS.WHITE,
      borderRadius: 8,
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
      padding: 16,
      width: '98%',
      marginBottom: 4,
      gridTemplateColumns: '1fr 1fr 1fr',
      gridTemplateRows: 'auto auto auto',
      gap: '0px 8px',
      gridTemplateAreas: `
        "member member member"
        "month peers clients"
        "status status status"`,

      '& td': {
        borderBottom: 0,
      },

      '& td:nth-child(1)': {
        gridArea: 'member',
      },
      '& td:nth-child(2)': {
        gridArea: 'month',

        '& button': {
          width: '100%',
        },
      },
      '& td:nth-child(3)': {
        position: 'absolute',
        top: 16,
        right: 16,

        '& div': {
          fontSize: 12,
          fontWeight: 500,
        },
      },
      '& td:nth-child(4)': {
        gridArea: 'clients',

        '& button': {
          width: '100%',
        },
      },
      '& td:nth-child(5)': {
        gridArea: 'peers',

        '& button': {
          width: '100%',
        },
      },
      '& td:nth-child(6)': {
        gridArea: 'status',
        paddingTop: 12,
        '& > div': {
          justifyContent: 'start',
        },
      },
    },
  },
  cellMember: {
    width: 320,
  },
  cellMonth: {
    width: 110,
  },
  cellStatus: {
    width: 130,
  },
  cellManager: {
    width: 'auto',
  },
  cellReviewers: {
    width: 100,
  },
  cellActions: {
    width: 170,
  },
  personNameSkeleton: {
    fontSize: '14px',
    fontWeight: '500',
    margin: 0,
    textDecoration: 'none',
    width: 160,
  },
  positionSkeleton: {
    fontSize: '13px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    margin: '2px 0 0',
    width: 80,
  },
  personBlock: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,
    padding: '4px 12px',
    gap: 8,
    borderRadius: 12,
    transition: 'all 0.2s',

    [BREAKPOINTS.MOBILE]: {
      padding: 0,

      '& > div': {
        alignItems: 'center',
      },
    },

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,

      '& > div > div:first-child': {
        transform: 'scale(1.05)',
      },
    },
  },
  personInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  personAvatar: {
    width: 40,
    height: 40,
    borderRadius: 14,
    objectFit: 'cover',
  },
  meterSkeleton: {
    margin: '0 auto',
    width: '70%',
    height: 60,
  },
  memberBlock: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 10,

    '& input': {
      display: 'block',
      width: 20,
      height: 20,
      cursor: 'pointer',
    },

    [BREAKPOINTS.MOBILE]: {
      padding: '0 0 12px',
    },
  },
  starting: {
    background:
      'linear-gradient(90deg, #A2DA8B 5.88%, #D1ED97 17.06% , #A2DA8B 29.12%, #A2DA8B 55.88%, #D1ED97 67.06%, #A2DA8B 79.12%)',
    backgroundSize: '200%',
    animation: '$startingBg 3s infinite linear',
  },
  '@keyframes startingBg': {
    '0%': { backgroundPositionX: '100%' },
    '50%': { backgroundPositionX: '0%' },
    '100%': { backgroundPositionX: '100%' },
  },
  backdrop: {
    position: 'absolute',
    cursor: 'auto',
    backgroundColor: COLORS.WHITE,
    opacity: 0.3,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: 5,
  },
  noClickable: {
    cursor: 'default',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  canceling: {
    background:
      'linear-gradient(90deg, #f23535 5.88%, #fc8383 17.06% , #f23535 29.12%, #f23535 55.88%, #fc8383 67.06%, #f23535 79.12%)',
    backgroundSize: '200%',
    animation: '$startingBg 3s infinite linear',
  },
});
