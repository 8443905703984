export enum AutomaticFlagWatchersGroups {
  SeniorManagers = 'seniorManagers',
  TomMembers = 'tomMembers',
  Hr = 'hr',
  HrAssistants = 'hrAssistants',
  MainFlagWatchers = 'mainFlagWatchers',
}

export const AutomaticFlagWatchersGroupsToFlagWatcherRoleMap: {
  [key in AutomaticFlagWatchersGroups]: string;
} = {
  [AutomaticFlagWatchersGroups.Hr]: 'hr',
  [AutomaticFlagWatchersGroups.HrAssistants]: 'hr assistant',
  [AutomaticFlagWatchersGroups.SeniorManagers]: 'senior manager',
  [AutomaticFlagWatchersGroups.TomMembers]: 'tom member',
  [AutomaticFlagWatchersGroups.MainFlagWatchers]: 'main flag watcher',
};
