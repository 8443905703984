import { createTheme } from '@mui/material/styles';
import typography from './typography';
import overrides from './overrides';
import palette from './palette';
export * from './variables';

declare module '@mui/material/styles' {
  interface Palette {
    brand: {
      primary: string;
      secondary: string;
    };
    highlight: {
      main: string;
      dark: string;
      hover: string;
      error: string;
      success: string;
      neutral: string;
    };
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    highlight: true;
  }
}

export const muiTheme = createTheme({
  palette,
  typography,
  spacing: (factor: number) => factor * 8,
  components: overrides,
});
