import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  dialogRoot: {
    padding: '66px 90px',
  },
  goalDialogClose: {
    position: 'absolute',
    right: 12,
    top: 16,
    color: COLORS.TEXT.DISABLED,
  },
  button: {
    fontSize: '14px',
    padding: '14px 28px',
  },
  submitButton: {
    fontSize: '14px',
    padding: '14px 28px',
    marginRight: 8,
  },
  ufoImg: {
    width: 300,
    display: 'block',
    margin: '0 auto 40px auto',
  },
  dialogTitle: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '120%',
    textAlign: 'center',
    letterSpacing: '-0.04em',
    marginBottom: 8,
  },
  dialogDescription: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    textAlign: 'center',
    color: COLORS.TEXT.SECONDARY,
    width: 300,
    margin: '0 auto 36px auto',
  },
  textarea: {
    marginBottom: 20,
  },
  error: {
    color: COLORS.STATUS.WARNING,
  },
});
