import { ChatMessage } from '@modules/HappinessModule/interfaces/ChatMessage';
import { PaginationData } from '@modules/HappinessModule/interfaces/PaginationData';
import { SendMessageData } from '@modules/HappinessModule/interfaces/SendMessageData';
import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from 'shared/interfaces/asyncData';

export const getChatData = createAction<PaginationData>('chatPage/getChatData');
export const setChatData = createAction<AsyncData<ChatMessage[]>>('chatPage/setChatData');
export const setChatDataLoading = createAction<void>('chatPage/setChatDataLoading');
export const voteAction = createAction<number>('chatPage/voteAction');
export const sendMessageAction = createAction<SendMessageData>('chatPage/sendMessageAction');
export const sendRockyMessageAction = createAction('chatPage/sendRockyMessageAction');
export const requestRevoteAction = createAction('chatPage/requestRevoteAction');
export const alreadyVotedMessageAction = createAction<number>('chatPage/alreadyVotedMessageAction');
