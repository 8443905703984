import { FLAG_PAGE_BLOCKS_PADDING } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  mainContainer: {
    padding: FLAG_PAGE_BLOCKS_PADDING,
  },
  notConfidentail: {
    backgroundColor: COLORS.HIGHLIGHTS.NOTE,
  },
});
