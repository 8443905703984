import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { State } from './types';
import { SLICE_NAME } from './constants';

const initialState: State = {
  userPromotions: { data: null, state: DataState.Idle },
  rolesSenioritiesMap: { data: null, state: DataState.Idle },
  addLinkState: DataState.Idle,
  updatePromotionState: DataState.Idle,
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUserPromotionsData(state, { payload }: PayloadAction<State['userPromotions']>) {
      state.userPromotions = payload;
    },
    clearUserPromotionsData(state) {
      state.userPromotions = {
        data: null,
        state: DataState.Idle,
      };
    },
    setRolesSenioritiesMap(state, { payload }: PayloadAction<State['rolesSenioritiesMap']>) {
      state.rolesSenioritiesMap = payload;
    },
    clearRolesSenioritiesMap(state) {
      state.rolesSenioritiesMap = {
        data: null,
        state: DataState.Idle,
      };
    },
    setAddLinkState(state, { payload }: PayloadAction<State['addLinkState']>) {
      state.addLinkState = payload;
    },
    setUpdatePromotionState(state, { payload }: PayloadAction<State['updatePromotionState']>) {
      state.updatePromotionState = payload;
    },
  },
});

export const {
  setUserPromotionsData,
  clearUserPromotionsData,
  setAddLinkState,
  setRolesSenioritiesMap,
  clearRolesSenioritiesMap,
  setUpdatePromotionState,
} = slice.actions;

export default slice.reducer;
