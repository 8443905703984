import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modal: {
    width: 500,
  },
  modalHeaderStyles: {
    paddingLeft: 50,
  },
  modalBody: {
    padding: '32px 26px',
  },
  modalContent: {
    backgroundColor: COLORS.CONTENT.MESSAGE,
    borderRadius: 4,
    padding: 18,
  },
  modalBtnsContainer: {
    marginTop: 24,
    display: 'flex',
    gap: 12,
    width: '100%',
    justifyContent: 'space-between',
  },
  button: {
    fontSize: 16,
  },
  actionBtn: {
    width: 210,
  },
  linkManageBtnDelete: {
    marginRight: 'auto',
  },
  error: {
    fontWeight: '400',
    fontSize: '13px',
    color: COLORS.STATUS.CRITICAL,
    marginTop: 5,
  },
});
