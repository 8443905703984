import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 20,
  },
  rootComponent: {
    background: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    margin: 20,
    height: '95%',
    minHeight: 400,
  },
  rootPage: {
    height: '100vh',
    width: '100vw',
  },
  mainHeading: {
    fontSize: 24,
    fontWeight: '500',
    textAlign: 'center',

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
    },
  },
  symbol: {
    fontSize: 64,
    lineHeight: '1',
    margin: '0 0 16px',
  },
  explainText: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    margin: '-8px 0 0',
  },
  buttonsContainer: {
    display: 'flex',
    gap: 16,
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    border: 'none',
    fontSize: '14px',
    padding: '6px 16px 7px',
    fontWeight: '500',
    cursor: 'pointer',
    borderRadius: 4,

    '&:hover': {
      backgroundColor: COLORS.ACTION.DARK,
    },
  },
});
