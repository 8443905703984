export const emailDomain = process.env.REACT_APP_EMAIL_DOMAIN;

export const CC_EMAILS = {
  peopleRelations: `people.relations${emailDomain}`,
  flags: `flags${emailDomain}`,
  tom: `tom${emailDomain}`,
  hrLatam: `hr_latam${emailDomain}`,
  hrEurope: `hr_europe${emailDomain}`,
  creativeDirectorDesignStudio1: `volodymyr.dzyubinskyy${emailDomain}`,
  creativeDirectorDesignStudio2: `mark.mishchenko${emailDomain}`,
};

export const CC_DESCRIPTIONS: {
  [index: string]: string;
} = {
  [CC_EMAILS.peopleRelations]: 'HR team is a part of HR department that supports the resolution of all flags',
  [CC_EMAILS.flags]: 'Key stakeholders that need to be informed of any escalation',
  [CC_EMAILS.tom]: 'Talent Operations team supports the resolution of all NewHome flags',
  [CC_EMAILS.hrLatam]: 'Latam HR team is a part of HR department that supports the resolution of all flags',
  [CC_EMAILS.hrEurope]: 'Europe HR team supports the resolution of all flags',
};

export const CC_RECIPIENTS = {
  peopleRelations: {
    userEmail: CC_EMAILS.peopleRelations,
    isCarbonCopy: true,
    description: CC_DESCRIPTIONS[CC_EMAILS.peopleRelations],
    user: null,
  },
  flags: {
    userEmail: CC_EMAILS.flags,
    isCarbonCopy: true,
    description: CC_DESCRIPTIONS[CC_EMAILS.flags],
    user: null,
  },
  tom: {
    userEmail: CC_EMAILS.tom,
    isCarbonCopy: true,
    description: CC_DESCRIPTIONS[CC_EMAILS.tom],
    user: null,
  },
  latamHr: {
    userEmail: CC_EMAILS.hrLatam,
    isCarbonCopy: true,
    description: CC_DESCRIPTIONS[CC_EMAILS.hrLatam],
    user: null,
  },
  europeHr: {
    userEmail: CC_EMAILS.hrEurope,
    isCarbonCopy: true,
    description: CC_DESCRIPTIONS[CC_EMAILS.hrEurope],
    user: null,
  },
};

export const CC_BY_COUNTRY = {
  ARGENTINA: CC_RECIPIENTS.latamHr,
  BRAZIL: CC_RECIPIENTS.latamHr,
  COLOMBIA: CC_RECIPIENTS.latamHr,
  GEORGIA: CC_RECIPIENTS.europeHr,
  GUATEMALA: CC_RECIPIENTS.latamHr,
  INDIA: CC_RECIPIENTS.europeHr,
  MEXICO: CC_RECIPIENTS.latamHr,
  POLAND: CC_RECIPIENTS.europeHr,
  PORTUGAL: CC_RECIPIENTS.europeHr,
  UKRAINE: CC_RECIPIENTS.europeHr,
  USA: CC_RECIPIENTS.latamHr,
  CANADA: CC_RECIPIENTS.europeHr,
};

export const CC_BY_GEOGRAPHY = {
  AMERICAS: CC_RECIPIENTS.latamHr,
  EURASIA: CC_RECIPIENTS.europeHr,
};
