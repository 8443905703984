export enum PDPPermissions {
  Read = 'USER:LINKS:PDP:READ',
  Update = 'USER:LINKS:PDP:UPDATE',
}

export enum OneToOnePermissions {
  Read = 'USER:LINKS:ONE_TO_ONE:READ',
  Update = 'USER:LINKS:ONE_TO_ONE:UPDATE',
}

export enum EvalsPermissions {
  Read = 'USER:LINKS:EVALS:READ',
  Update = 'USER:LINKS:EVALS:UPDATE',
}

export enum CVPermissions {
  Read = 'USER:LINKS:CV:READ',
  Update = 'USER:LINKS:CV:UPDATE',
}

export enum EMOneToOnePermissions {
  Read = 'USER:LINKS:EM_ONE_TO_ONE:READ',
  Update = 'USER:LINKS:EM_ONE_TO_ONE:UPDATE',
}

export type ExternalLinksPermissions =
  | PDPPermissions
  | OneToOnePermissions
  | EvalsPermissions
  | CVPermissions
  | EMOneToOnePermissions;
