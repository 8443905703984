import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  datePickerWrapper: {
    position: 'relative',
  },
  dateSelectorBtn: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    columnGap: 6,
  },
  overdue: {
    color: COLORS.STATUS.CRITICAL,
  },
  datePicker: {
    position: 'absolute',
    top: 40,
    zIndex: 10,
    width: 'max-content',
  },
  rotate: {
    transform: 'rotate(180deg) translateY(2px)',
  },
  toggleIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      width: 16,
      height: 16,
      marginRight: -4,
    },
  },
  dateLikeString: {
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '500',
  },
});
