import { EditUserGoalData } from '@modules/EvaluationModule/interfaces/EditUserGoalData';
import { InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { useFormikContext } from 'formik';
import React from 'react';
import PinIcon from '@mui/icons-material/Pin';
import { useStyles } from './NumericGoalsField.styles';

interface Props {
  value: number | null;
  name: string;
  label: string;
  disabled: boolean;
  error: boolean;
  placeholder: string;
  inputProps?: boolean;
  percentageOfDone?: number | null;
  labelColor?: string;
}

export const NumericGoalsField: React.FC<Props> = ({
  value,
  name,
  label,
  disabled,
  error,
  placeholder,
  inputProps,
  percentageOfDone,
  labelColor,
}) => {
  const styles = useStyles();
  const form = useFormikContext<Required<EditUserGoalData>>();

  return (
    <TextField
      placeholder={placeholder}
      label={label}
      variant="outlined"
      type="number"
      value={value}
      name={name}
      fullWidth
      autoComplete="off"
      disabled={disabled}
      helperText={
        form.errors[name as keyof EditUserGoalData] && (
          <span className={styles.fieldError}>{form.errors[name as keyof EditUserGoalData]}</span>
        )
      }
      onChange={form.handleChange}
      error={error}
      InputProps={
        inputProps
          ? percentageOfDone !== undefined
            ? {
                inputProps: { min: 0 },
                endAdornment:
                  percentageOfDone !== null ? (
                    <InputAdornment position="end">
                      <Tooltip title={`${percentageOfDone}% perfomance`} placement="top">
                        <Typography
                          variant="body2"
                          className={styles.goalCompletenessBadge}
                          sx={{
                            background: labelColor,
                          }}
                        >
                          {percentageOfDone}%
                        </Typography>
                      </Tooltip>
                    </InputAdornment>
                  ) : (
                    <Tooltip title="Pending results" placement="top">
                      <AccessTimeOutlinedIcon fontSize="small" />
                    </Tooltip>
                  ),
              }
            : {
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      title="Some measurable criteria to determine the outcome and keep it on schedule"
                      placement="top"
                    >
                      <PinIcon />
                    </Tooltip>
                  </InputAdornment>
                ),
              }
          : { inputProps: { min: 0 } }
      }
    />
  );
};
