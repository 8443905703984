import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { PeerFormData } from '@modules/EvaluationModule/interfaces/PeerEvalData';
import { PeerEvalPermissions } from '@modules/EvaluationModule/interfaces/EvalPermissions';
import { paths } from '@shared/enums/paths';
import { AsyncData } from '@shared/interfaces/asyncData';
import { getPeerEvalData, getPeerEvalPermissions } from '../redux/actions';
import { setPeerEvalDataLoading } from '../redux/reducers';
import { peerEvalDataSelector, peerEvalPermissionsSelector } from '../redux/selectors';

export const usePeerEvalData = (): {
  peerEvalData: AsyncData<PeerFormData>;
  peerEvalPermissions: AsyncData<PeerEvalPermissions>;
} => {
  const peerEvalData = useSelector(peerEvalDataSelector);
  const peerEvalPermissions = useSelector(peerEvalPermissionsSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { evalId, feedbackId } = useParams();

  useEffect(() => {
    if (!evalId || !feedbackId) {
      navigate(paths.root);

      return;
    }

    dispatch(getPeerEvalData({ evalId, feedbackId }));
    dispatch(getPeerEvalPermissions(feedbackId));
    return () => {
      dispatch(setPeerEvalDataLoading());
    };
  }, [evalId]);

  return {
    peerEvalData,
    peerEvalPermissions,
  };
};
