import {
  CreateUserPromotionData,
  UpdateUserPromotionData,
  UserPromotionSharedPayloadData,
} from '@modules/EvaluationModule/interfaces/UserPromotionsData';

export const mapPromotionPayload = (
  payload: CreateUserPromotionData | UpdateUserPromotionData
): UserPromotionSharedPayloadData => {
  const { stream, role, seniority, ...restPayload } = payload;
  return {
    ...restPayload,
    streamId: stream?.id,
    roleId: role?.id,
    seniorityId: seniority?.id,
  };
};
