import { SortInfo } from '@shared/interfaces/sortInfo';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useLocation } from 'react-router-dom';
import { getSortedTableData, sortMyTeamEvalInfoSelector } from '../redux/selectors';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { getMyTeamEvalData, setMyTeamEvalDataLoading } from '../redux/actions';

export const useMyTeamEvalData = (): {
  myTeamEvalData: AsyncData<MyTeamEvalData[]>;
  sortInfo: SortInfo;
  fromLocation?: string;
} => {
  const myTeamEvalData = useSelector(getSortedTableData);
  const sortInfo = useSelector(sortMyTeamEvalInfoSelector);
  const location = useLocation();
  const dispatch = useDispatch();
  const [fromLocation, setFromLocation] = useState(undefined);

  useEffect(() => {
    setFromLocation(location?.state);
  }, [location]);

  useEffect(() => {
    dispatch(getMyTeamEvalData());
    return () => {
      dispatch(setMyTeamEvalDataLoading());
    };
  }, []);

  return { myTeamEvalData, sortInfo, fromLocation };
};
