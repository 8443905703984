import { put, all, takeLatest, call, select, takeEvery } from 'redux-saga/effects';

import { DataState } from '@shared/enums/DataState';
import {
  getMyTeamEvalData,
  setStartingEvalId,
  setMyTeamEvalDataLoading,
  setSelectedEvalsAction,
  failEop,
  setFailingEopId,
} from './actions';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { fetchMyTeamEvalData } from '@modules/EvaluationModule/api/myTeamEvalData';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { startSelectedEvals } from '@modules/EvaluationModule/api/startSelectedEvals';
import { setMyTeamEvalData } from './reducers';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { patchCloseEval, patchEvalEopStatus } from '@modules/EvaluationModule/api/evalPageData';
import { EopResult } from '@modules/EvaluationModule/enums/EopResult';
import { getUserEvalData } from '../../UserEvalPage/redux/actions';
import { RootState } from '@modules/App/redux/store';

function* getMyTeamEvalDataAsync() {
  try {
    const setLoadingAction = setMyTeamEvalDataLoading();
    yield put(setLoadingAction);
    const myTeamEvalData: MyTeamEvalData[] = yield call(fetchMyTeamEvalData);
    const setMyTeamEvalDataAction = setMyTeamEvalData({
      data: myTeamEvalData,
      state: DataState.Fulfilled,
    });
    yield put(setMyTeamEvalDataAction);
  } catch (e) {
    yield put(
      setMyTeamEvalData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* setSelectedEvalsAsync({ payload }: ReturnType<typeof setSelectedEvalsAction>) {
  const evalIdToStart = payload.evaluationId;
  try {
    yield put(setStartingEvalId(evalIdToStart));
    yield call(startSelectedEvals, payload);
    const myTeamEvalData: MyTeamEvalData[] = yield select(
      (state) => state.evaluation.myTeamEvalPage.myTeamEvalData.data
    );
    const newMyTeamEvalData = myTeamEvalData.map((myTeamEval) => ({
      ...myTeamEval,
      status: evalIdToStart.includes(myTeamEval.evaluationId) ? EvaluationStatus.Ongoing : myTeamEval.status,
    }));

    const setMyTeamEvalDataAction = setMyTeamEvalData({
      data: newMyTeamEvalData,
      state: DataState.Fulfilled,
    });
    const startedEval = myTeamEvalData.find(({ evaluationId }) => evalIdToStart.includes(evaluationId));
    if (startedEval) {
      window.location.href = `/users/${startedEval.userId}/evaluations/${startedEval.evaluationId}`;
    }
    yield put(setMyTeamEvalDataAction);
    yield put(displayNotification('Evaluation preparations are now in progress  🙌'));
  } catch {
    yield put(displayNotification('Failed to start the evaluation 🥺'));
  }
  yield put(setStartingEvalId(''));
}

function* failEopAsync({ payload }: ReturnType<typeof failEop>) {
  try {
    yield put(setFailingEopId(payload.evaluationId));
    const userId: string | null = yield select(
      (state: RootState) => state.evaluation.userEvalPage.userEvalData.data?.user.id
    );
    yield call(patchEvalEopStatus, payload.evaluationId, {
      eopStatus: EopResult.NotPassed,
    });
    yield call(patchCloseEval, payload.evaluationId, { notes: payload.failReason, goals: '' });
    yield put(getMyTeamEvalData());
    if (userId) {
      yield put(getUserEvalData(userId));
    }
    yield put(displayNotification('This evaluation is now closed 🎉'));
  } catch {
    yield put(displayNotification("Sorry... This evaluation can't be closed 🤧"));
  }
  yield put(setFailingEopId(''));
}

function* watchGetMyTeamEvalData() {
  yield takeLatest(getMyTeamEvalData.type, getMyTeamEvalDataAsync);
}

function* watchStartCheckedEvals() {
  yield takeEvery(setSelectedEvalsAction.type, setSelectedEvalsAsync);
}

function* watchFailEop() {
  yield takeEvery(failEop.type, failEopAsync);
}

export function* myTeamEvalDataPageSaga(): Generator {
  yield all([watchGetMyTeamEvalData(), watchStartCheckedEvals(), watchFailEop()]);
}
