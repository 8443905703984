import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  goalDialogNote: {
    background: COLORS.HIGHLIGHTS.PROMINENT,
    padding: '24px 32px 24px 56px',
    margin: '0 -24px 0 0',
    borderRadius: '8px 0 0 8px',
    minHeight: '100%',
    position: 'relative',
    overflow: 'hidden',
    '& b': {
      fontWeight: 600,
      fontSize: 10,
      float: 'left',
      margin: '2px -1px 0px -27px',
      background: COLORS.BRAND.ACCENT,
      color: COLORS.WHITE,
      borderRadius: '50%',
      width: '17px',
      height: '17px',
      lineHeight: '17px',
      textAlign: 'center',
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalDialogNoteTitle: {
    margin: '0 0 16px -27px',
    '& strong': {
      fontWeight: 500,
      color: COLORS.BRAND.ACCENT,
      background: 'linear-gradient(90deg, #9C27B0 0%, #FF164E 50%, #F88720 100%), #9C27B0',
      WebkitBackgroundClip: 'text',
      backgroundClip: 'text',
      textFillColor: 'transparent',
    },
    '& span': {
      fontSize: '150%',
      display: 'inline-block',
      margin: '0 6px 0 -2px',
    },
  },
  goalDialogNoteBig: {
    position: 'absolute',
    top: '900px',
    '& span': {
      fontSize: '80px',
      lineHeight: '1',
      display: 'block',
      margin: '0 0 8px -16px',
    },
  },
  nobr: {
    whiteSpace: 'nowrap',
  },
  hideHintBtn: {
    cursor: 'pointer',
  },
});
