import { RockyAvatar } from '@shared/components/RockyAvatar';
import { UserInfo } from '@shared/interfaces/UserInfo';
import React, { FC } from 'react';
import { useStyles } from './UserInfoBlock.styles';
import Popup from 'reactjs-popup';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { additionalFieldsDescriptions } from '@modules/EvaluationModule/pages/EvalPage/constants/additionalFieldsDescriptions';
import { get } from 'lodash';
import classNames from 'classnames';
import { Comment } from '../Comment';

interface Props {
  user: UserInfo;
  feedback: EvaluationOptionsData | null;
}

export const UserInfoBlock: FC<Props> = ({ user, feedback }) => {
  const styles = useStyles();
  return (
    <div className={styles.root}>
      <div className={styles.avatar}>
        <RockyAvatar small imageUrl={user.imageUrl} fullName={user.name || ''}></RockyAvatar>
        {additionalFieldsDescriptions.some(({ questionName }) => get(feedback, questionName)) && (
          <div>
            <Comment
              title={'Feedback'}
              comment={
                <div className={styles.additionalFields}>
                  {additionalFieldsDescriptions.map(
                    ({ questionName, questionTitle }) =>
                      get(feedback, questionName) && (
                        <div key={questionName} className={styles.questionBlock}>
                          <h4 className={styles.questionTitle}>{questionTitle}</h4>
                          <p>{get(feedback, questionName)}</p>
                        </div>
                      )
                  )}
                </div>
              }
              user={user}
            />
          </div>
        )}
      </div>
      <Popup
        trigger={<p className={styles.personName}>{user.name}</p>}
        on={['hover', 'click']}
        position="bottom left"
        offsetY={-5}
        offsetX={-1}
        className={classNames(styles.popup, styles.popupName)}
      >
        <div>{user.name}</div>
      </Popup>
      <Popup
        trigger={<p className={styles.position}>{user.position}</p>}
        on={['hover', 'click']}
        position="bottom left"
        offsetY={-5}
        offsetX={-1}
        className={classNames(styles.popup, styles.popupName)}
      >
        <div>{user.position}</div>
      </Popup>
    </div>
  );
};
