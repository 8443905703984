import { SurveyQueueResponseData } from '@modules/HappinessModule/api/surveyQueue';
import { SurveyCategory, SurveyQueue } from './interfaces';

export const mapResponseDataToSurveyQueue = (responseData: SurveyQueueResponseData): SurveyQueue => ({
  categories: responseData.queue.reduce(
    (
      resultCategories: SurveyCategory[],
      { question: { question_group, id: question_id, text }, group_position, question_position, is_active }
    ) => {
      let existingCategory: SurveyCategory | undefined = resultCategories.find((c) => c.id === question_group.id);
      if (!existingCategory) {
        existingCategory = {
          name: question_group.name,
          id: question_group.id,
          questions: [],
          isActive: is_active,
        };
        resultCategories[group_position] = existingCategory;
      }
      existingCategory.questions[question_position] = { text, id: question_id };
      return resultCategories;
    },
    []
  ),
  lastSurvey: {
    date: responseData.last_survey.date,
    categoryName: responseData.last_survey.category_name,
    questionText: responseData.last_survey.question_text,
  },
});
