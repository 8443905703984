export enum FlagTriggerType {
  Voluntary = 'Voluntary',
  Involuntary = 'Involuntary',
}

export enum FlagReason {
  JobOfferPackage = 'Job offer package',
  ProjectDepartmentV = 'Project/Department (V)',
  Personal = 'Personal',
  ProjectDepartmentI = 'Project/Department (I)',
  Performance = 'Performance',
  CulturalMismatch = 'Cultural Mismatch',
  PassedAway = 'Passed Away',
  Relocation = 'Relocation',
  NewHomeV = 'New Home (V)',
  NewHomeI = 'New Home (I)',
  None = 'None',
}

export enum FlagSubreason {
  Compensation = 'Compensation',
  ContractConditions = 'Contract Conditions',
  TechnologyStack = 'Technology Stack',
  ContentTasks = 'Content (Tasks)',
  LeadTeam = 'Lead / Team',
  ProfessionalDevelopment = 'Professional Development',
  ClientDepartment = 'Client/ Department',
  OwnBusinessStudies = 'Own Business / Studies',
  Health = 'Health',
  MilitaryService = 'Military service',
  BudgetIssues = 'Budget issues',
  EndProject = 'End of the project',
  NoMatchingPositions = 'No matching positions (NH)',
  FailedInterviews = 'Failed Interviews (NH)',
  Professional = 'Professional',
  Personal = 'Personal',
  LeftBeforeAssignment = 'Left before assignment',
  JobAbandonment = 'Job Abandonment',
  HardSkills = 'Hard Skills',
  SoftSkills = 'Soft Skills',
  LegalCompliance = 'Legal Compliance',
  HardAndSoftSkills = 'Hard and soft skills',
}
