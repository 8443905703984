import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setAllLocationsData, setQuestionGroupsData } from './actions';

import { State } from './types';

const initialState: State = {
  questionGroupHistoryData: { data: null, state: DataState.Pending },
  locations: { data: null, state: DataState.Pending },
  selectedLocationName: '',
  selectedDepartmentId: '',
};

export const slice = createSlice({
  name: 'surveyHistoryPage',
  initialState,
  reducers: {
    setQuestionGroupsDataLoading(state) {
      state.questionGroupHistoryData = { data: null, state: DataState.Pending };
    },
    setAllLocationsDataLoading(state) {
      state.locations = { data: null, state: DataState.Pending };
    },
    setSelectedLocationName(state, { payload }) {
      state.selectedLocationName = payload;
    },
    setResetSelectedLocationName(state) {
      state.selectedLocationName = '';
    },
    setSelectedDepartmentId(state, { payload }) {
      state.selectedDepartmentId = payload;
    },
    setResetSelectedDepartamentId(state) {
      state.selectedDepartmentId = '';
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setQuestionGroupsData, (state, { payload }) => {
      state.questionGroupHistoryData = payload;
    });
    builder.addCase(setAllLocationsData, (state, { payload }) => {
      state.locations = payload;
    });
  },
});

export const {
  setSelectedLocationName,
  setSelectedDepartmentId,
  setResetSelectedLocationName,
  setResetSelectedDepartamentId,
} = slice.actions;

export default slice.reducer;
