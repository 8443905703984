import { DeliveryAddress } from '@shared/interfaces/DeliveryAddress';
import { UserAddressData } from '@shared/interfaces/UserAddressData';
import { UserProfileData } from '@shared/interfaces/user';

type DeliveryAddressFormFieldsTypeMap = {
  deliveryAddress: {
    [key in keyof Required<DeliveryAddress>]: {
      name: `deliveryAddress.${key}`;
      label: string;
    };
  };
};

export type UserAddressFormFieldsTypeMap = {
  [key in keyof Required<UserAddressData & Pick<UserProfileData, 'selectedMetroArea'>>]: {
    name: key;
    label: string;
  };
};

export type UserAddressFormFieldsType = DeliveryAddressFormFieldsTypeMap & UserAddressFormFieldsTypeMap;

export type UserAddressFieldsType = keyof Required<UserAddressData>;

export type UserAddressFormValuesType = Required<UserAddressData> & {
  deliveryAddress: Partial<DeliveryAddress>;
} & Pick<UserProfileData, 'selectedMetroArea' | 'closestMetroArea'>;
export const UserAddressFormFields: UserAddressFormFieldsType = {
  addressLine1: {
    name: 'addressLine1',
    label: 'Address line 1',
  },
  addressLine2: {
    name: 'addressLine2',
    label: 'Address line 2',
  },
  city: {
    name: 'city',
    label: 'City',
  },
  state: {
    name: 'state',
    label: 'State',
  },
  country: {
    name: 'country',
    label: 'Country',
  },
  zipCode: {
    name: 'zipCode',
    label: 'ZIP/Postal code',
  },
  selectedMetroArea: {
    name: 'selectedMetroArea',
    label: 'Metro Area',
  },
  deliveryAddress: {
    type: {
      name: 'deliveryAddress.type',
      label: 'Shipping',
    },
    country: {
      name: 'deliveryAddress.country',
      label: 'Country',
    },
    addressLine1: {
      name: 'deliveryAddress.addressLine1',
      label: 'Adress line 1',
    },
    addressLine2: {
      name: 'deliveryAddress.addressLine2',
      label: 'Address line 2',
    },
    city: {
      name: 'deliveryAddress.city',
      label: 'City',
    },
    zipCode: {
      name: 'deliveryAddress.zipCode',
      label: 'ZIP/Postal code',
    },
    state: {
      name: 'deliveryAddress.state',
      label: 'State',
    },
    cityAndWarehouseNumber: {
      name: 'deliveryAddress.cityAndWarehouseNumber',
      label: 'City and warehouse number',
    },
    fullName: {
      name: 'deliveryAddress.fullName',
      label: 'Full name',
    },
    phoneNumber: {
      name: 'deliveryAddress.phoneNumber',
      label: 'Phone number',
    },
  },
};
