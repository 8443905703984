import { put, all, call, takeLatest } from 'redux-saga/effects';
import {
  setClientContactData,
  getClientContactData,
  getClientSubProjectData,
  setClientSubProjectData,
  addClientContact,
  editClientContact,
  setClientSubProjectNetworkError,
} from './actions';
import {
  addClientContactStore,
  editClientContactStore,
  setClientContactDataLoading,
  setClientSubProjectDataLoading,
  setEditClientDataState,
} from './reducers';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import { ClientSubProjectData } from '@modules/EvaluationModule/interfaces/ClientSubProjectData';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import {
  postClientContact,
  fetchProjectListData,
  fetchClientSubProjectData,
  patchClientContact,
} from '@modules/EvaluationModule/api/clientContactData';

function* getClientContactDataAsync() {
  try {
    const setLoadingAction = setClientContactDataLoading();
    yield put(setLoadingAction);
    const response: ClientContactData[] = yield call(fetchProjectListData);
    const sortedResponse = Array.from(response).sort((a, b) =>
      a.name.toLocaleLowerCase() < b.name.toLocaleLowerCase()
        ? -1
        : a.name.toLocaleLowerCase() > b.name.toLocaleLowerCase()
        ? 1
        : 0
    );
    const action = setClientContactData({ data: sortedResponse, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setClientContactData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* getClientSubProjectDataAsync({ payload }: ReturnType<typeof getClientSubProjectData>) {
  try {
    if (payload === null) {
      return;
    }
    const setLoadingAction = setClientSubProjectDataLoading();
    yield put(setLoadingAction);
    const response: ClientSubProjectData[] = yield call(fetchClientSubProjectData, payload);
    const action = setClientSubProjectData({ data: response, state: DataState.Fulfilled });
    yield put(action);
    yield call(fetchProjectListData);
  } catch (error) {
    yield put(
      setClientSubProjectData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(error),
      })
    );
  }
}

function* addClientContactAsync({ payload }: ReturnType<typeof addClientContact>) {
  let hasErorr = false;
  try {
    yield put(setEditClientDataState(DataState.Pending));
    yield put(setClientSubProjectNetworkError(null));
    yield call(postClientContact, payload);
    const setClientSubProjectAction = addClientContactStore(payload);
    yield put(setClientSubProjectAction);
  } catch (error) {
    yield put(setEditClientDataState(DataState.Rejected));
    hasErorr = true;
    const e = getErrorInfo(error);
    const setClientSubProjectErorrAction = setClientSubProjectNetworkError(e?.message || null);
    yield put(setClientSubProjectErorrAction);
    setClientSubProjectData({
      data: null,
      state: DataState.Rejected,
      error: getErrorInfo(error),
    });
    yield put(displayNotification('Failed to create new client contact'));
  } finally {
    if (!hasErorr) {
      yield put(setEditClientDataState(DataState.Fulfilled));
      const setClientSubProjectErorrAction = setClientSubProjectNetworkError(null);
      yield put(setClientSubProjectErorrAction);
      yield put(
        displayNotification('Add client request successful, it may take few minutes to appear it on interface')
      );
    }
  }
}

function* editClientContactAsync({ payload }: ReturnType<typeof editClientContact>) {
  let hasErorr = false;
  try {
    yield put(setEditClientDataState(DataState.Pending));
    const setClientSubProjectAction = editClientContactStore(payload);
    yield put(setClientSubProjectAction);
    yield call(patchClientContact, payload);
  } catch (error) {
    yield put(setEditClientDataState(DataState.Rejected));
    hasErorr = true;
    const e = getErrorInfo(error);
    const setClientSubProjectErorrAction = setClientSubProjectNetworkError(e?.message || null);
    yield put(setClientSubProjectErorrAction);
    setClientSubProjectData({
      data: null,
      state: DataState.Rejected,
      error: getErrorInfo(error),
    });
    yield put(displayNotification('Failed to edit client information'));
  } finally {
    if (!hasErorr) {
      const setClientSubProjectErorrAction = setClientSubProjectNetworkError(null);
      yield put(setClientSubProjectErorrAction);
      yield put(setEditClientDataState(DataState.Fulfilled));
      yield put(displayNotification('Successfully edited!'));
    }
  }
}

function* watchGetClientContactData() {
  yield takeLatest(getClientContactData.type, getClientContactDataAsync);
  yield takeLatest(getClientSubProjectData.type, getClientSubProjectDataAsync);
  yield takeLatest(addClientContact.type, addClientContactAsync);
  yield takeLatest(editClientContact.type, editClientContactAsync);
}

export function* clientContactPageSaga(): Generator {
  yield all([watchGetClientContactData()]);
}
