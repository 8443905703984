import React, { useEffect } from 'react';
import { useStyles } from './CompensationDetails.styles';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { CompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { EditCompensationModal } from '@modules/EvaluationModule/pages/UserCompensationPage/components/EditCompensationModal/EditCompensationModal';
import { formatFinancialValue } from '@modules/EvaluationModule/pages/UserCompensationPage/helpers/currencyOptions';

interface Props {
  data: CompensationData;
  isRevisible?: boolean;
}

export const CompensationDetails: React.FC<Props> = ({ data, isRevisible }) => {
  const styles = useStyles();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(pageTitles.MyCompensationPage);
  }, []);

  return (
    <div className={styles.compPlan}>
      <h3 className={styles.blockTitle}>Current compensation&nbsp;plan</h3>
      <div className={styles.compensationItem}>
        <PaymentsOutlinedIcon color="disabled" />
        <div className={styles.compensationItemNameContainer}>
          <span className={styles.compensationItemName}>Target compensation</span>
          <span className={styles.compensationValue}>
            <span className={styles.currency}>$</span>
            {data && formatFinancialValue(data?.compensationRate)}
          </span>
        </div>
      </div>
      <div className={styles.compensationItem}>
        <ReceiptLongOutlinedIcon color="disabled" />
        <div className={styles.compensationItemNameContainer}>
          <span className={styles.compensationItemName}>Type</span>
          <span className={styles.itemValue}>{data.type}</span>
        </div>
      </div>
      <div className={styles.compensationItem}>
        <CalendarMonthOutlinedIcon color="disabled" />
        <div className={styles.compensationItemNameContainer}>
          <span className={styles.compensationItemName}>Effective from</span>
          <span className={styles.itemValue}>
            {data.historicalCompensations.length !== 0 &&
              format(parseISO(data.historicalCompensations[0].period), 'MMMM yyyy')}
          </span>
        </div>
      </div>
      {isRevisible && <EditCompensationModal currentCompensation={data} />}
    </div>
  );
};
