import { createAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './constants';
import { ResolveUserHappinessPayload } from '../interfaces/ResolveUserHappinessPayload';

export const getPeopleHappinessData = createAction<string | undefined>(`${SLICE_NAME}/getPeopleHappinessData`);
export const loadNextChunkOfData = createAction<void>(`${SLICE_NAME}/loadNextChunkOfData`);
export const resolveSituationFromPeoplePage = createAction<ResolveUserHappinessPayload>(
  `${SLICE_NAME}/resolveSituationFromPeoplePage`
);
export const loadUsersFromParams = createAction<{
  userIds: string[];
  primaryReportsToIds: string[];
}>(`${SLICE_NAME}/loadUsersFromParams`);
export const getPeopleHappinessDataGrouped = createAction<string | undefined>(
  `${SLICE_NAME}/getPeopleHappinessDataGrouped`
);
