import { apiUrlNode } from '@configs/environment';
import httpNodeClient from '@shared/api/httpNodeClient';
import { UserActionsPermissions } from '../interfaces/UserActionsPermissions';
import { getExternalLinksPermissions } from './helpers/getExternalLinksPermissions';
import { getFlagsPermissions } from '@modules/HappinessModule/api/helpers/getFlagsPermissions';
import { getUserInfoPermissions } from './helpers/getUserInfoPermissions';
import { getSmartGoalsPermissions } from './helpers/getSmartGoalsPermissions';
import { AllAvailableFlagPermissions } from '@modules/HappinessModule/pages/FlagPage/enums/flagPermissionsData';
import { ExternalLinksPermissions } from '@shared/enums/ExternalLinksPermissions';
import { UserInfoPermissions } from '@shared/enums/UserInfoPermissions';
import { getUserPromotionsPermissions } from './helpers/getUserPromotionsPermissions';
import { SmartGoalsPermissions } from '@shared/enums/SmartGoalsPermissions';
import { HappinessPermission } from '@modules/HappinessModule/enums/HappinessPermission';
import { getHappinessPermissions } from './helpers/getHappinessPermissions';

export const getUserPermissions = (id: string): Promise<UserActionsPermissions> =>
  httpNodeClient.get<UserActionsPermissions>(`${apiUrlNode}/users/${id}/permissions`).then(({ data }) => {
    const permissions: UserActionsPermissions = {
      ...data,
      flagPermissions: getFlagsPermissions(data.permissions as AllAvailableFlagPermissions[]),
      externalLinks: getExternalLinksPermissions(data.permissions as ExternalLinksPermissions[]),
      userInfoPermissions: getUserInfoPermissions(data.permissions as UserInfoPermissions[]),
      promotions: getUserPromotionsPermissions(data),
      smartGoals: getSmartGoalsPermissions(data.permissions as SmartGoalsPermissions[]),
      happiness: getHappinessPermissions(data.permissions as HappinessPermission[]),
    };

    return permissions;
  });
