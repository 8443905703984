import React, { FC } from 'react';
import { useStyles } from './ClientEvalPageHeader.styles';
import { ClientEvalData } from '@modules/EvaluationModule/interfaces/ClientEvalData';
import format from 'date-fns/format';

interface Props {
  data: ClientEvalData;
}

export const ClientEvalPageHeader: FC<Props> = ({ data }) => {
  const styles = useStyles();
  const { evaluatee, evaluationDate } = data;

  return (
    <div>
      <div className={styles.title}>
        <span>Your feedback on {evaluatee.name}</span>
        <div className={styles.evalDate}>
          <span className={styles.evalDateText}>{format(new Date(evaluationDate), 'MMMM yyyy')}</span>
        </div>
      </div>
      <div className={styles.divider}></div>
    </div>
  );
};
