import { useField } from 'formik';
import { Select, InputLabel, FormControl, SelectProps, Paper } from '@mui/material';
import React, { FC, useState } from 'react';

interface Props extends SelectProps {
  label: string;
  name: string;
  children: React.ReactNode;
  dropdownContent: React.ReactNode;
}

export const ReasonSelectFormik: FC<Props> = ({ label, name, children, dropdownContent, ...props }) => {
  const [field] = useField(name);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleOpen = () => {
    setIsDropdownOpen(true);
  };

  const handleClose = () => {
    setIsDropdownOpen(false);
  };

  return (
    <div style={{ position: 'relative' }}>
      <FormControl required={props.required} fullWidth disabled={props.disabled}>
        <InputLabel>{label}</InputLabel>
        <Select label={label} onOpen={handleOpen} onClose={handleClose} variant="outlined" {...field} {...props}>
          {children}
        </Select>
      </FormControl>
      {isDropdownOpen && (
        <Paper
          elevation={2}
          variant="elevation"
          sx={{
            position: 'absolute',
            zIndex: 2,
            height: '400px',
            width: '300px',
            right: 0,
            transform: 'translateX(300px)',
            padding: '21px 32px',
            whiteSpace: 'pre-line',
          }}
        >
          {dropdownContent}
        </Paper>
      )}
    </div>
  );
};
