import { fetchMyTeamHappinessData } from '@modules/HappinessModule/api/myTeamHappinessData';
import { put, all, call, takeLatest } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import { getMyTeamHappinessData, setMyTeamHappinessData, setMyTeamHappinessDataLoading } from './actions';
import { UsersHappinessDataItem } from '@modules/HappinessModule/interfaces/usersHappinessDataItem';
import { HappinessData } from '@modules/HappinessModule/interfaces/happinessData';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';

function* getmyTeamHappinessDataAsync({ payload }: ReturnType<typeof getMyTeamHappinessData>) {
  try {
    const setLoadingAction = setMyTeamHappinessDataLoading();
    yield put(setLoadingAction);
    const response: HappinessData<UsersHappinessDataItem> = yield call(fetchMyTeamHappinessData, payload);
    const setUsersHappinessDataAction = setMyTeamHappinessData({
      data: { survey: response.survey, question: response.question },
      state: DataState.Fulfilled,
    });
    yield put(setUsersHappinessDataAction);
  } catch (e) {
    yield put(
      setMyTeamHappinessData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* watchGetMyTeamHappinessData() {
  yield takeLatest(getMyTeamHappinessData.type, getmyTeamHappinessDataAsync);
}

export function* myTeamHappinessPageSaga(): Generator {
  yield all([watchGetMyTeamHappinessData()]);
}
