import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {},
  popup: {
    '&-content': {
      minWidth: 'fit-content',
      padding: '0',

      '[role=tooltip]&': {
        boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
        border: 'none',
      },
    },
    '&-arrow': {
      display: 'none',
    },
  },
  dropdownBtn: {
    display: 'flex',
    columnGap: 5.5,
    position: 'relative',
  },
  notificationBlock: {
    position: 'absolute',
    top: '0',
    right: '0',
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: COLORS.STATUS.CRITICAL,
    transform: 'translate(50%,-50%)',
  },
  dropdown: {
    width: 350,
  },
  dropdownItem: {
    padding: '10px 20px',
    transition: 'all 0.1s ease-out',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
    alignItems: 'center',
    height: 60,
    borderBottom: `1px solid ${COLORS.ACTION.HIGHLIGHT}`,
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,
    outline: 'none',

    '&:hover': {
      background: COLORS.ACTION.HIGHLIGHT,
    },
  },
  lastDropdownItem: {
    display: 'flex',
    justifyContent: 'normal',
    alignItems: 'center',
    color: COLORS.ACTION.PRIMARY,
    borderBottom: 'none',
    fontWeight: '400',
    fontSize: '14px',
    columnGap: 6,
    height: 56,
    textDecoration: 'none',
  },
  lastDropdownItemIcon: {
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  dropdownElementsBlock: {
    borderRadius: 4,
    padding: '2px 4px',
    color: COLORS.WHITE,
    fontSize: 11,
    marginRight: -4,
  },
  link: {
    textDecoration: 'none',
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    borderRadius: 9,
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  lowercase: {
    textTransform: 'lowercase',
  },
});
