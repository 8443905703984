import { all, AllEffect, call, put, takeLatest } from 'redux-saga/effects';

import { getQuestions, setLoadingData, setQuestions } from './actions';
import { Question } from '@modules/HappinessModule/interfaces/question';
import { DataState } from '@shared/enums/DataState';
import { fetchQuestions } from '../../api/questions';

function* getQuestionsAsync() {
  yield put(setLoadingData());
  const questions: Question[] = yield call(fetchQuestions);
  yield put(
    setQuestions({
      questions: {
        data: questions,
        state: DataState.Fulfilled,
      },
      selectedQuestion: questions[0],
    })
  );
}

function* watchGetQuestions() {
  yield takeLatest(getQuestions.type, getQuestionsAsync);
}

type watchGetQuestionsType = ReturnType<typeof watchGetQuestions>;

export function* questionsSaga(): Generator<AllEffect<watchGetQuestionsType>> {
  yield all([watchGetQuestions()]);
}
