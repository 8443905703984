import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    maxHeight: '100%',
  },
  mainBtn: {
    marginTop: 24,
  },
  divider: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    marginLeft: -30,
    marginRight: -30,

    [BREAKPOINTS.MOBILE]: {
      marginLeft: -16,
      marginRight: -16,
    },
  },
  reviewsTitle: {
    margin: 0,
    fontWeight: '500',
  },
  reviews: {
    display: 'flex',
    columnGap: 20,
    marginBottom: 24,
  },
  toggles: {
    display: 'flex',
    columnGap: 21,
    alignItems: 'center',
  },
  toggle: {
    display: 'flex',
    columnGap: 6,
    alignItems: 'end',
    fontSize: '14px',
    fontWeight: '500',
    color: COLORS.TEXT.PRIMARY,
  },
  eopBlock: {
    marginBottom: 48,
  },
});
