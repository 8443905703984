export const emojiVariants = [
  {
    mark: 1,
    emojis: ['🤬', '☠️'],
    fontSize: {
      max: 48,
      min: 16,
    },
  },
  {
    mark: 2,
    emojis: ['🥵', '🩸', '💔', '🧨'],
    fontSize: {
      max: 48,
      min: 16,
    },
  },
  {
    mark: 3,
    emojis: ['🤕', '🧦', '✂️', '❤️‍🩹'],
    fontSize: {
      max: 48,
      min: 16,
    },
  },
  {
    mark: 4,
    emojis: ['😓', '❄️', '⚠️'],
    fontSize: {
      max: 48,
      min: 16,
    },
  },
  {
    mark: 5,
    emojis: ['🌚', '🖤', '🕶'],
    fontSize: {
      max: 48,
      min: 16,
    },
  },
  {
    mark: 6,
    emojis: ['🙉', '☁️', '🔔'],
    fontSize: {
      max: 48,
      min: 16,
    },
  },
  {
    mark: 7,
    emojis: ['🍌', '🍃', '🥲'],
    fontSize: {
      max: 48,
      min: 16,
    },
  },
  {
    mark: 8,
    emojis: ['😘', '🧸', '🧡', '🍕'],
    fontSize: {
      max: 72,
      min: 16,
    },
  },
  {
    mark: 9,
    emojis: ['🥰', '💪', '☀️', '🍦', '🍀'],
    fontSize: {
      max: 72,
      min: 16,
    },
  },
  {
    mark: 10,
    emojis: ['🤩', '👍', '❤️', '🍫'],
    fontSize: {
      max: 72,
      min: 16,
    },
  },
];
