import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'row-reverse',
      gap: 0,
    },
  },
  barHolder: {
    flexGrow: 1,
    height: 8,
    position: 'relative',
  },
  bar: {
    position: 'absolute',
    height: '100%',
    width: '100%',
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    borderRadius: 4,
  },
  valueBar: {
    position: 'absolute',
    height: '100%',
    background: COLORS.STATUS.CRITICAL,
    borderRadius: 4,
  },
  rateBlock: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
  },
  link: {
    marginTop: 5,
  },
  rate: {
    fontSize: 16,
    fontWeight: 500,
    width: 36,
    height: 36,
    borderRadius: '30%',
    color: COLORS.WHITE,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    paddingBottom: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [BREAKPOINTS.MOBILE]: {
      transform: 'scale(0.75) translate3d(-8px, 0px, 0)',
    },
  },
  noResponseLabel: {
    size: 14,
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    margin: 0,
    width: '100%',

    [BREAKPOINTS.MOBILE]: {
      textAlign: 'left',
      paddingLeft: 0,
    },
  },
  warning: {
    color: COLORS.BRAND.PRIMARY,
  },
  danger: {
    color: COLORS.STATUS.CRITICAL,
  },
});
