import { Tooltip } from '@mui/material';
import { TableHeaderData } from '@shared/interfaces/TableHeaderData';
import { CompensationHistoryTableHeaders } from '../../../enums/CompensationHistoryTableHeaders';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { useStyles } from '../CompensationHistory.styles';
import { TOOLTIP_EXPLAINING_TOTAL_COMPENSATION } from '../../../constants/hintsExplainingHowTotalCompensationIsCalculated';

export const getTableHeaders = ({ isShownBonusColumn }: { isShownBonusColumn: boolean }): TableHeaderData[] => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const styles = useStyles();

  let tableHeaders: TableHeaderData[] = [
    { title: CompensationHistoryTableHeaders.ReviewData },
    { title: CompensationHistoryTableHeaders.Base, titleStyles: styles.centeredTitleToRight },
    { title: CompensationHistoryTableHeaders.Bonus, titleStyles: styles.centeredTitleToRight },
    {
      title: CompensationHistoryTableHeaders.Total,
      titleStyles: styles.centeredTitleToRight,
      childFunc: () =>
        isShownBonusColumn && (
          <>
            {' '}
            <Tooltip title={TOOLTIP_EXPLAINING_TOTAL_COMPENSATION} placement="top">
              <CalculateOutlinedIcon className={styles.calculateIcon} />
            </Tooltip>
          </>
        ),
    },
    {
      title: CompensationHistoryTableHeaders.DifferenceCurrentTotalWithPrevious,
    },
  ];
  if (!isShownBonusColumn) {
    tableHeaders = tableHeaders.filter((el) => el.title !== CompensationHistoryTableHeaders.Bonus);
  }
  return tableHeaders;
};
