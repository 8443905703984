import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { QuestionsAsync } from './types';

const initialState: QuestionsAsync = {
  questions: {
    data: null,
    state: DataState.Pending,
  },
  selectedQuestion: null,
};

export const slice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    setLoadingData(state) {
      state.questions.state = DataState.Pending;
      state.questions = {
        data: null,
        state: DataState.Pending,
      };
    },
    setQuestions(state, action) {
      state.questions = action.payload.questions;
    },
    setSelectedQuestion(state, action) {
      state.selectedQuestion = action.payload;
    },
  },
});

export default slice.reducer;
