import { SortInfo } from '@shared/interfaces/sortInfo';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncData } from '@shared/interfaces/asyncData';
import {
  groupBySelector,
  happinessApplyFiltersSelector,
  isPeopleHappinessDataLoadingSelector,
  peopleHappinessDataGroupedExpandedSelector,
  selectPeopleHappinessData,
  sortPeopleHappinessPageInfoSelector,
} from '../redux/selectors';
import { getPeopleHappinessData, getPeopleHappinessDataGrouped, loadNextChunkOfData } from '../redux/actions';
import { useQuestionsHistory } from '@modules/HappinessModule/hooks/useQuestionsHistory';
import { useCallback, useEffect, useState } from 'react';
import { selectedQuestionSelector } from '@modules/HappinessModule/redux/questions/selectors';
import {
  PeopleHappinessPageGroupedData,
  PeopleHappinessPageNotGroupedData,
} from '../interfaces/PeopleHappinessDataItem';
import { PeopleHappinessFilterFormData } from '../interfaces/PeopleHappinessFilterData';
import { PeopleHappinessDataGroupBy } from '../enums/PeopleHappinessDataGroupBy';
import { setSortInfo } from '../redux';
import { DEFAULT_SORTING } from '../constants';
import { isEqual } from 'lodash';
import { useParams } from 'react-router-dom';

export const usePeopleHappinessData = (): {
  peopleHappinessData: AsyncData<PeopleHappinessPageNotGroupedData>;
  sortInfo: SortInfo;
  onScrollThresholdPass: () => void;
  isDataChunkLoading: boolean;
  filtersToAplly: PeopleHappinessFilterFormData | null;
  peopleHappinessGroupedData: AsyncData<PeopleHappinessPageGroupedData>;
  groupBy: PeopleHappinessDataGroupBy;
} => {
  const peopleHappinessData = useSelector(selectPeopleHappinessData);
  const { questionId } = useParams();
  const dispatch = useDispatch();
  const sortInfo = useSelector(sortPeopleHappinessPageInfoSelector);
  const isDataChunkLoading = useSelector(isPeopleHappinessDataLoadingSelector);
  useQuestionsHistory();

  const selectedQuestion = useSelector(selectedQuestionSelector);
  const filtersToAplly = useSelector(happinessApplyFiltersSelector);
  const [prevSelectedQuestionId, setPrevSelectedQuestionId] = useState<string | undefined>('initial');

  const groupBy = useSelector(groupBySelector);
  const peopleHappinessGroupedData = useSelector(peopleHappinessDataGroupedExpandedSelector);

  useEffect(() => {
    dispatch(setSortInfo(DEFAULT_SORTING));
  }, [groupBy]);

  const [prevParams, setPrevParams] = useState<{
    selectedQuestion: ReturnType<typeof selectedQuestionSelector>;
    filtersToAplly: ReturnType<typeof happinessApplyFiltersSelector>;
    groupBy: ReturnType<typeof groupBySelector>;
    sortInfo: ReturnType<typeof sortPeopleHappinessPageInfoSelector>;
  }>();

  useEffect(() => {
    const currentParams = {
      selectedQuestion,
      filtersToAplly,
      groupBy,
      sortInfo,
    };
    if (isEqual(prevParams, currentParams) || !filtersToAplly || !groupBy || !sortInfo) {
      return;
    }
    if (prevParams && prevParams?.groupBy !== groupBy) {
      dispatch(setSortInfo(DEFAULT_SORTING));
    }

    setPrevSelectedQuestionId(selectedQuestion?.id);
    if (!prevSelectedQuestionId) {
      return;
    }
    dispatch(
      groupBy === PeopleHappinessDataGroupBy.None
        ? getPeopleHappinessData(questionId)
        : getPeopleHappinessDataGrouped(questionId)
    );
    setPrevParams(currentParams);
  }, [selectedQuestion?.id, sortInfo, filtersToAplly, groupBy, prevParams, prevSelectedQuestionId]);

  const onScrollThresholdPass = useCallback(() => {
    dispatch(loadNextChunkOfData());
  }, []);

  return {
    peopleHappinessData,
    sortInfo,
    onScrollThresholdPass,
    isDataChunkLoading,
    filtersToAplly,
    peopleHappinessGroupedData,
    groupBy,
  };
};
