import { makeStyles } from '@mui/styles';
import { FLAG_PAGE_BLOCKS_PADDING } from '../../constants/constants';

export const useStyles = makeStyles({
  mainContainer: {
    padding: FLAG_PAGE_BLOCKS_PADDING,
  },
  anotherOfferInvolvedBlockHrView: {
    padding: '29px 65px 84px 59px',
  },
  footer: {
    padding: '26px 59px',
  },
});
