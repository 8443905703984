import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  userBlock: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    columnGap: 8,
    textDecoration: 'none',
    color: 'inherit',
    borderRadius: 8,
  },
  link: {
    padding: 8,
    margin: -8,
    textDecoration: 'none',
    color: 'inherit',

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,

      '& > div:first-child': {
        transform: 'scale(1.05)',
      },
    },
  },
  mediumAvatar: {
    height: 40,
    width: 40,
    borderRadius: '100%',
  },
  smallAvatar: {
    height: 20,
    width: 20,
    borderRadius: '50%',
  },
  personInfoContainer: {
    display: 'flex',
    flex: '1 1 auto',
    columnGap: '0.5rem',
    justifyContent: 'space-between',
  },
  personInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  personName: {
    fontSize: '14px',
    margin: 0,
    textDecoration: 'none',
  },
  mediumTextSkeleton: {
    width: 140,
  },
  smallTextSkeleton: {
    width: 100,
  },
  subTitle: {
    color: COLORS.TEXT.SECONDARY,
    margin: 0,
    fontSize: '13px',
    lineHeight: '120%',
  },
  email: {
    color: COLORS.TEXT.SECONDARY,
  },
  position: {
    color: COLORS.TEXT.SECONDARY,
  },
  project: {
    color: COLORS.TEXT.SECONDARY,
  },
});
