import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  input: {
    width: '100%',
    lineHeight: 1,
    marginLeft: 2,
    padding: 4,
    borderRadius: 6,
  },
  label: {
    width: '100%',
    lineHeight: 1,
    padding: 8,
    borderRadius: 4,
    cursor: 'pointer',
    transition: 'background-color 0.1s linear',
    backgroundColor: 'transparent',

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
  },
});
