import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';

export const sortedGoals = (goals: UserGoalPageData[]): UserGoalPageData[] => {
  const statusPriority = [GoalsStatus.InProgress, GoalsStatus.Achieved, GoalsStatus.Canceled];
  const sortingResponse = goals.sort((a, b) => {
    return (
      statusPriority.indexOf(a.status) - statusPriority.indexOf(b.status) ||
      new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime()
    );
  });
  return sortingResponse;
};
