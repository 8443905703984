import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    paddingBottom: 16,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto 24px',

    [BREAKPOINTS.MOBILE]: {
      overflowX: 'auto',
    },
  },
  myTeamFlagsTable: {
    minWidth: '1000px',

    [BREAKPOINTS.MOBILE]: {
      minHeight: '70vh',
    },
  },
  tableHeader: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    backgroundColor: COLORS.WHITE,

    '& td:nth-child(1)': {
      width: '25%',
    },
    '& td:nth-child(2)': {
      width: '10%',
    },
    '& td:nth-child(3)': {
      width: '30%',
    },
    '& td:nth-child(4)': {
      width: '10%',
    },
    '& td:nth-child(5)': {
      width: '10%',
    },
    '& td:nth-child(6)': {
      width: '10%',
    },
  },
  tableDataRow: {
    height: 75,
  },
  header: {
    padding: '16px 24px',
    minHeight: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
  },
  title: {
    fontWeight: '500',
    fontSize: '24px',
  },
});
