import React, { FC, memo, useCallback } from 'react';
import { Draggable, DragDropContext, Droppable } from 'react-beautiful-dnd';
import { SurveyCategory } from '../../interfaces';
import { QuestionCard } from '../QuestionCard';
import { useStyles } from './CategoryCard.styles';
import ReorderIcon from '@mui/icons-material/Reorder';
import { useDispatch, useSelector } from 'react-redux';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import parseISO from 'date-fns/parseISO';
import addWeeks from 'date-fns/addWeeks';
import { DeleteAction } from '../DeleteAction';
import { EditableLabel } from '../EditableLabel';
import { createQuestion, deleteCategory, setCategoryName, setSurveyQuestionOrder } from '../../redux/actions';
import { nanoid } from '@reduxjs/toolkit';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import format from 'date-fns/format';
import { SURVEY_INTERVAL_WEEKS } from '../../constants';
import { surveyQueueRelector } from '../../redux/selectors';

interface Props {
  index: number;
  category: SurveyCategory;
  isInActiveQueue: boolean;
  totalActiveCategories: number;
}

const CategoryCardComponent: FC<Props> = ({ index, category, isInActiveQueue, totalActiveCategories }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const surveyQueue = useSelector(surveyQueueRelector);

  const lastSurveyStartDate = surveyQueue.data ? parseISO(surveyQueue.data?.lastSurvey.date) : new Date();

  const handleAddQuestionClick = useCallback(
    () =>
      dispatch(
        createQuestion({
          categoryId: category.id,
          questionId: nanoid(),
          text: 'New Question',
        })
      ),
    [dispatch]
  );
  const handleDeleteClick = useCallback(() => dispatch(deleteCategory(category.id)), [dispatch]);

  const handleDragEnd = useCallback(
    (event) => {
      if (!event.destination) {
        return;
      }
      dispatch(
        setSurveyQuestionOrder({
          questionId: event.draggableId,
          order: event.destination.index,
        })
      );
    },
    [dispatch]
  );

  const handleCategoryNameSubmit = useCallback(
    (value) =>
      dispatch(
        setCategoryName({
          categoryId: category.id,
          name: value,
        })
      ),
    [dispatch]
  );

  return (
    <Draggable draggableId={category.id} index={index}>
      {(provided) => (
        <div className={styles.root} ref={provided.innerRef} {...provided.draggableProps}>
          <div className={styles.headerContainer}>
            <div className={styles.dragHolder} {...provided.dragHandleProps}>
              <ReorderIcon color="primary" />
            </div>
            <div className={styles.categoryNameHolder}>
              <EditableLabel
                onSubmit={handleCategoryNameSubmit}
                value={category.name}
                className={styles.categoryName}
              ></EditableLabel>
            </div>
            <DeleteAction onDelete={handleDeleteClick}></DeleteAction>
          </div>
          <div>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId={category.id}>
                {(provided) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {category.questions.map((question, i) => {
                      const weeksToAdd = (totalActiveCategories * i + index + 1) * SURVEY_INTERVAL_WEEKS;
                      const startDate = addWeeks(lastSurveyStartDate, weeksToAdd);
                      const expiredDayCount = startDate ? differenceInCalendarDays(new Date(), startDate) : 0;
                      return (
                        <QuestionCard
                          expiredDayCount={expiredDayCount}
                          isFirstInQueue={isInActiveQueue && index === 0 && i === 0}
                          question={question}
                          order={i}
                          key={question.id}
                          startDate={isInActiveQueue ? format(startDate, 'dd MMM yyyy') : undefined}
                          canBeDeleted={category.questions.length > 1}
                        ></QuestionCard>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <div className={styles.addQuestionContainer}>
              <div className={styles.addQuestionButton} onClick={handleAddQuestionClick}>
                <AddchartOutlinedIcon />
                <span className={styles.addQuestionButtonText}>Add new question</span>
              </div>
            </div>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export const CategoryCard = memo(CategoryCardComponent);
