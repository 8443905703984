import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 0,
    padding: 8,
    borderRadius: 8,
    backgroundColor: COLORS.HIGHLIGHTS.NOTE,
    height: '100%',
  },
  personName: {
    fontSize: 13,
    margin: '2px 0 0',
    textDecoration: 'none',
    fontWeight: '500',
    color: COLORS.TEXT.PRIMARY,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  position: {
    margin: 0,
    color: COLORS.TEXT.SECONDARY,
    fontSize: '13px',
    lineHeight: '110%',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 11,
  },
  popup: {
    '&-content': {
      width: 'fit-content !important',
      height: 'fit-content !important',
      maxWidth: 500,
      maxHeight: 300,
      padding: '12px 24px',
      overflow: 'auto',
      overflowX: 'hidden',
      overflowWrap: 'anywhere',
      marginRight: 50,
      marginBottom: 30,
    },
  },
  popupName: {
    '&-content': {
      padding: '2px 4px',
    },
  },
  additionalFields: {
    maxWidth: 500,

    '& p': {
      margin: 0,
    },
  },
  questionTitle: {
    margin: '0 0 4px',
    fontWeight: 500,
  },
  questionBlock: {
    marginBottom: 32,
  },
});
