import { cloneDeep } from 'lodash';
import { NUMBER_OF_CHILDREN_TO_EXPAND } from '../constants';
import { PeopleHappinessDataItem, PeopleHappinessGroupedData } from '../interfaces/PeopleHappinessDataItem';
import { PeopleHappinessFilterFormData } from '../interfaces/PeopleHappinessFilterData';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { instanceOfPeopleHappinessGroupedData } from './typeChecks';

const updateExpandedStatus = ({
  data,
  userIdsToExpand,
  departmentsToExpand,
  parent,
}: {
  data: (PeopleHappinessDataItem | PeopleHappinessGroupedData)[];
  userIdsToExpand: string[];
  departmentsToExpand: DepartmentData[];
  parent: PeopleHappinessGroupedData | null;
}) => {
  let fistDepartmentWithMoreThanNChildrenFound = false;
  if (data.length >= NUMBER_OF_CHILDREN_TO_EXPAND) {
    fistDepartmentWithMoreThanNChildrenFound = true;
  }

  for (const item of data) {
    if (!instanceOfPeopleHappinessGroupedData(item)) {
      return;
    }

    updateExpandedStatus({
      data: item.children,
      userIdsToExpand,
      departmentsToExpand,
      parent: item,
    });

    const shouldDelete = item.children.length === 0;
    if (shouldDelete && parent) {
      parent.children = parent.children.filter((child) => child.id !== item.id);
      continue;
    }

    let shouldExpand =
      item.expanded ||
      item.children.some((child) =>
        instanceOfPeopleHappinessGroupedData(child) ? false : userIdsToExpand.includes(child.userId)
      ) ||
      departmentsToExpand.some((department) => department.id === item.id);

    if (!fistDepartmentWithMoreThanNChildrenFound) {
      if (item.children.length > NUMBER_OF_CHILDREN_TO_EXPAND) {
        fistDepartmentWithMoreThanNChildrenFound = true;
        shouldExpand = true;
      }
    }

    item.expanded = shouldExpand;
    if (parent) {
      parent.expanded = shouldExpand;
    }
  }
};

export const getPeopleHappinessDataGroupedExpanded = ({
  peopleHappinessDataGrouped,
  filterInfo,
}: {
  peopleHappinessDataGrouped: PeopleHappinessGroupedData[] | null;
  filterInfo: PeopleHappinessFilterFormData | null;
}): PeopleHappinessGroupedData[] | null => {
  if (!peopleHappinessDataGrouped) return null;
  const departmentsToExpand = filterInfo?.departments || [];
  const userIdsToExpand = filterInfo?.users.map((user) => user.id as string) || [];

  const peopleHappinessDataGroupedClone = cloneDeep(peopleHappinessDataGrouped);
  updateExpandedStatus({
    data: peopleHappinessDataGroupedClone,
    userIdsToExpand,
    departmentsToExpand,
    parent: null,
  });
  const dataGroupedWithoutEmptyChildrenInRoots = peopleHappinessDataGroupedClone.filter((item) =>
    instanceOfPeopleHappinessGroupedData(item) ? item.children.length > 0 : true
  );

  return dataGroupedWithoutEmptyChildrenInRoots;
};
