import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      '& $tooltip': {
        display: 'block',
      },
    },
  },
  tooltip: {
    display: 'none',
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    top: -45,
    width: 'max-content',
    boxShadow: '0 4px 12px rgb(180 180 180 / 60%) !important',
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '400',
    fontSize: '14px',
    padding: '5px 10px',
    background: COLORS.WHITE,
    borderRadius: 4,
  },
  arrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderTop: '10px solid white',
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    bottom: -7,
    left: '50%',
    transform: 'translateX(-50%)',
  },
});
