import {
  EvaluationRescheduleHistoryData,
  EvaluationRescheduleHistoryDataRaw,
} from '@modules/EvaluationModule/interfaces/EvaluationRescheduleHistory';

export const evaluationRescheduleHistoryMapFunc = (
  evaluationRescheduling: EvaluationRescheduleHistoryDataRaw[]
): EvaluationRescheduleHistoryData[] =>
  evaluationRescheduling.map((item) => ({
    id: item.id,
    evaluationId: item.evaluation_history_id,
    reason: item.modification_reason,
    from: item.rescheduled_from,
    to: item.rescheduled_to,
    modificationDate: item.modification_date,
  }));
