import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  Grid,
  Stack,
  Typography,
  TextField,
  Alert,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { MuiTelInput } from 'mui-tel-input';
import { Form, Formik, FormikProps } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { DataState } from '@shared/enums/DataState';
import { phone } from 'phone';

import { useStyles } from './EditUserContactModal.styles';
import { validationSchema } from '../../helpers/validationSchema';
import { RockyButton } from '@shared/components/RockyButton';
import { EditContactsData } from '@modules/App/interfaces/EditUserContactsData';
import { setUserContactsUpdateNetworkError } from '../../redux';
import { editUserContacts } from '../../redux/actions';
import { editUserContactsUpdatingStateSelector, userContactsUpdateNetworkErrorSelector } from '../../redux/selectors';
interface Props {
  isOpen: boolean;
  setIsOpen: (arg0: boolean) => void;
  initialValues: EditContactsData;
}

export const EditUserContactModal: FC<Props> = ({ isOpen, setIsOpen, initialValues }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef<FormikProps<EditContactsData>>(null);
  const [isDirty, setIsDirty] = useState(false);
  const [isSomeValueChanged, setIsSomeValueChanged] = useState(false);
  const [isWarnOpen, setIsWarnOpen] = useState(false);
  const editUserContactsUpdatingState = useSelector(editUserContactsUpdatingStateSelector);
  const isLoading = editUserContactsUpdatingState === DataState.Pending;
  const isFailed = editUserContactsUpdatingState === DataState.Rejected;
  const userContactsUpdateNetworkError = useSelector(userContactsUpdateNetworkErrorSelector);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    formRef.current?.handleChange(e);
    setIsSomeValueChanged(true);
  };

  const handleMUITelInputChange = (name: string, value: string) => {
    formRef.current?.setFieldValue(name, value);
    setIsSomeValueChanged(true);
  };

  const handleCloseIfNoChanges = () => {
    if (isSomeValueChanged) {
      setIsWarnOpen(true);
    } else {
      handleClose();
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setIsDirty(false);
    setIsWarnOpen(false);
    setIsSomeValueChanged(false);
    dispatch(setUserContactsUpdateNetworkError(null));
  };

  useEffect(() => {
    if (!isLoading && !isFailed) {
      handleClose();
    }
  }, [isLoading, isFailed]);

  const handleClickSubmitIfValid = () => {
    if (!formRef.current) {
      return;
    }
    formRef.current.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        formRef.current?.handleSubmit();
      } else {
        setIsDirty(true);
      }
    });
  };

  const handleSubmitEditClient = useCallback((values) => {
    const formattedMobilePhone = phone(values.mobilePhone).phoneNumber;
    const formattedEmergencyContactPhone = phone(values.emergencyContactPhone).phoneNumber;

    dispatch(
      editUserContacts({
        ...values,
        id,
        mobilePhone: formattedMobilePhone,
        emergencyContactPhone: formattedEmergencyContactPhone,
      })
    );
  }, []);

  return (
    <Dialog open={isOpen} fullWidth maxWidth="sm" scroll="body" onClose={handleCloseIfNoChanges}>
      <DialogTitle>
        Edit contacts
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={handleCloseIfNoChanges}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitEditClient}
      >
        {(formik) => (
          <Form>
            <DialogContent>
              <Alert
                severity="info"
                variant="standard"
                icon={<LockPersonOutlinedIcon fontSize="inherit" color="secondary" />}
                sx={{ mb: 2 }}
              >
                <Typography variant="body2">Visible only to you, HR, and management</Typography>
              </Alert>
              <Grid item xs={12} pt={1} pb={2}>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Personal contacts
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MuiTelInput
                      name="mobilePhone"
                      value={formik.values.mobilePhone}
                      label="Mobile phone"
                      onChange={(value) => {
                        handleMUITelInputChange('mobilePhone', value);
                      }}
                      placeholder="+1"
                      autoComplete="tel"
                      inputMode="tel"
                      helperText={
                        isDirty &&
                        formik.errors.mobilePhone && (
                          <span className={styles.fieldError}>{formik.errors.mobilePhone}</span>
                        )
                      }
                      error={isDirty && Boolean(formik.errors.mobilePhone)}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      type="email"
                      autoComplete="on"
                      name="personalEmail"
                      label="Personal email"
                      placeholder="username@example.com"
                      value={formik.values.personalEmail}
                      fullWidth
                      variant="outlined"
                      helperText={
                        isDirty &&
                        formik.errors.personalEmail && (
                          <span className={styles.fieldError}>{formik.errors.personalEmail}</span>
                        )
                      }
                      onChange={(e) => handleChange(e)}
                      error={isDirty && Boolean(formik.errors.personalEmail)}
                    />
                  </Grid>
                </Grid>
                <Typography variant="h4" sx={{ mb: 2, mt: 4 }}>
                  Emergency contact
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <MuiTelInput
                      id="phoneEmergency"
                      name="emergencyContactPhone"
                      label="Mobile phone"
                      value={formik.values.emergencyContactPhone}
                      onChange={(value) => {
                        handleMUITelInputChange('emergencyContactPhone', value);
                      }}
                      inputMode="tel"
                      autoComplete="tel"
                      placeholder="+1"
                      helperText={
                        isDirty &&
                        formik.errors.emergencyContactPhone && (
                          <span className={styles.fieldError}>{formik.errors.emergencyContactPhone}</span>
                        )
                      }
                      error={isDirty && Boolean(formik.errors.emergencyContactPhone)}
                      fullWidth
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      label="Name"
                      fullWidth
                      autoComplete="off"
                      variant="outlined"
                      name="emergencyContactName"
                      value={formik.values.emergencyContactName}
                      helperText={
                        isDirty &&
                        formik.errors.emergencyContactName && (
                          <span className={styles.fieldError}>{formik.errors.emergencyContactName}</span>
                        )
                      }
                      onChange={(e) => handleChange(e)}
                      error={isDirty && Boolean(formik.errors.emergencyContactName)}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            {isFailed && <div className={styles.networkError}>{userContactsUpdateNetworkError}</div>}
            <DialogActions>
              <Stack direction="row" spacing={1}>
                <RockyButton inverted onClick={handleCloseIfNoChanges}>
                  Cancel
                </RockyButton>
                <RockyButton action onClick={handleClickSubmitIfValid} isLoading={isLoading}>
                  Save changes
                </RockyButton>
              </Stack>
            </DialogActions>
          </Form>
        )}
      </Formik>
      <Dialog open={isWarnOpen} maxWidth="sm" scroll="body" onClose={() => setIsWarnOpen(false)}>
        <Alert severity="info" variant="standard" sx={{ mb: 2 }} className={styles.warnPopupBody}>
          Are you sure you want to leave without saving changes?
        </Alert>
        <DialogActions>
          <Stack direction="row" spacing={1}>
            <RockyButton inverted onClick={handleClose}>
              Leave the page
            </RockyButton>
            <RockyButton action onClick={() => setIsWarnOpen(false)}>
              Continue editing
            </RockyButton>
          </Stack>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};
