import * as Sentry from '@sentry/react';
import { getCurrentUser } from '@modules/App/redux/user/actions';
import { User } from '@shared/interfaces/user';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { firebaseAuth } from '../../auth/firebase';
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getAccessToken, setAccessToken } from '@shared/helpers/auth';
import { requestCustomToken } from '@modules/App/api/requestCustomToken';
import { getCustomEmail, setCustomAuth } from '@shared/helpers/auth';
import { authUserSelector, authUserState } from '@modules/App/redux/user/selectors';
import { useNavigate } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { DataState } from '@shared/enums/DataState';

export const useAuth = (authWithoutLogin?: boolean): { currentUser: User | null; signIn: () => void } => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector(authUserSelector);
  const currentUserState = useSelector(authUserState);
  const [isUserInitialized, setIsUserInitialized] = useState(false);
  // TODO: rename to isLoginFailed or more obvious variable

  const signIn = () => {
    if (firebaseAuth) {
      signInWithPopup(firebaseAuth, new GoogleAuthProvider()).then(() => {
        location.reload();
      });
    }
  };

  useEffect(() => {
    const customEmail = getCustomEmail();
    if (customEmail) {
      setIsUserInitialized(true);
      return;
    }
    if (!getAccessToken() && !authWithoutLogin && location.pathname !== paths.login) {
      navigate(paths.login);
      return;
    }
    if (!firebaseAuth) return;
    onAuthStateChanged(firebaseAuth, async (userCred) => {
      if (!firebaseAuth) return;
      if (!userCred && !authWithoutLogin) {
        if (location.pathname === paths.login) return;
        // clearing the token if it gets expired or firebase db gets deleted
        localStorage.clear();
        location.reload();
        return;
      }
      if (userCred && currentUserState === DataState.Rejected) {
        navigate(paths.userNotRegistered, { state: { email: userCred.email } });
        return;
      }
      setIsUserInitialized(true);
      setAccessToken((await firebaseAuth?.currentUser?.getIdToken()) ?? '');
    });
  }, [authWithoutLogin, currentUserState]);

  useEffect(() => {
    if (firebaseAuth && !isUserInitialized) {
      return;
    }
    if (currentUser) {
      Sentry.setUser({ email: currentUser?.email });
    } else {
      const customEmail = getCustomEmail();
      if (customEmail) {
        requestCustomToken({ email: customEmail }).then((data) => {
          setCustomAuth(data.idToken, data.refreshToken, +data.expiresIn);
          dispatch(getCurrentUser());
        });
      } else {
        dispatch(getCurrentUser());
      }
    }
  }, [currentUser, dispatch, isUserInitialized]);

  return {
    currentUser,
    signIn,
  };
};
