import { COLORS } from '@styles/colors';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    cursor: 'pointer',
  },
  checkMarker: {
    position: 'absolute',
    width: 25,
    opacity: 0,
    zIndex: 1,
    height: '100%',
  },
  checkmark: {
    height: '100%',
    width: '100%',
    position: 'relative',
    borderRadius: 4,
    border: `2px solid ${COLORS.INPUTBORDER}`,
    backgroundColor: COLORS.WHITE,

    '$checkMarker:checked ~ &': {
      backgroundColor: COLORS.ACTION.PRIMARY,
      border: `2px solid ${COLORS.ACTION.PRIMARY}`,
    },

    '$checkMarker:disabled ~ &': {
      backgroundColor: COLORS.INPUTBORDER,
      border: `2px solid ${COLORS.INPUTBORDER}`,
    },

    '$checkboxContainer:hover $checkMarker:not(:checked) ~ &': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },

    '&::after': {
      content: '""',
      position: 'absolute',
      display: 'none',
      left: 6,
      top: 2,
      width: 3,
      height: 7,
      border: `solid ${COLORS.WHITE}`,
      borderWidth: '0 2px 2px 0',
      transform: 'rotate(45deg)',

      '$checkMarker:checked ~ &': {
        display: 'block',
      },
    },
  },
  checkboxContainer: {
    flexShrink: 0,
    userSelect: 'none',
    position: 'relative',
    height: 20,
    width: 20,
    cursor: 'inherit',
  },
  checkboxLabel: {
    cursor: 'inherit',
    marginLeft: 8,
    fontWeight: '400',
    fontSize: '14px',
  },
  disabled: {
    cursor: 'auto',
  },
});
