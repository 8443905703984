import { FC, useState } from 'react';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { roleIconMap } from '../../../OrgChartPage/constants/roleIconMap';
import { UserProfileData } from '@shared/interfaces/user';
import { RoleMap } from '@shared/consts/RoleMap';
import { ProjectRole } from '@shared/enums/projectRole';

interface Props {
  user: UserProfileData;
}

const ProjectsList: FC<Props> = ({ user }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const sortedProjects = [...user.projects].sort((a) => (a.isPrimaryProject ? 0 : 1));
  const expandableProjects = sortedProjects.slice(5);

  return (
    <>
      {sortedProjects.slice(0, 5).map(({ role, project, isPrimaryProject }, i) => (
        <ProjectItem
          key={i}
          role={role}
          project={project}
          isPrimaryProject={isPrimaryProject && user.projects.length > 1}
        />
      ))}

      <Collapse in={isExpanded}>
        {expandableProjects.map(({ role, project, isPrimaryProject }, i) => (
          <ProjectItem
            key={i}
            role={role}
            project={project}
            isPrimaryProject={isPrimaryProject && user.projects.length > 1}
          />
        ))}
      </Collapse>
      {expandableProjects.length > 0 && (
        <Link
          sx={{ display: 'block', cursor: 'pointer', marginBottom: 1 }}
          onClick={() => {
            setIsExpanded((expandend) => !expandend);
          }}
        >
          {isExpanded ? 'Show less' : `Show ${expandableProjects.length} more`}
        </Link>
      )}
    </>
  );
};

interface ProjectItemProps {
  isPrimaryProject: boolean;
  project: string;
  role: ProjectRole;
}

const ProjectItem: FC<ProjectItemProps> = ({ isPrimaryProject, project, role }) => (
  <Box marginBottom={2}>
    {project}
    <Typography variant="body2">
      {isPrimaryProject ? (
        'Primary project'
      ) : (
        <>
          <span style={{ marginRight: '0.25rem', verticalAlign: 'middle' }}>{roleIconMap[role]}</span>
          {RoleMap[role]}
        </>
      )}
    </Typography>
  </Box>
);

export default ProjectsList;
