import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 72,

    '@media (max-width: 1439px)': {
      flexWrap: 'wrap',
      gap: 24,
    },
  },
  history: {
    minWidth: 400,
  },
  blockTitle: {
    margin: '-12px 0 14px',
    fontSize: 18,
    fontWeight: 500,
  },
  salaryType: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.TEXT.SECONDARY,
  },
  historyList: {
    width: '100%',
    margin: 0,
    padding: 0,
    listStyle: 'none',
  },
  baseTitle: {
    justifySelf: 'end',
  },
  headerText: {
    alignSelf: 'end',
  },
  headerTextRight: {
    textAlign: 'right',
  },
  historyItemValue: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: COLORS.TEXT.PRIMARY,
    textAlign: 'right',
    whiteSpace: 'nowrap',
  },
  historyItemBonus: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '16px',
    color: COLORS.TEXT.PRIMARY,
    alignSelf: 'end',
    textAlign: 'right',
  },
  noBonus: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.TEXT.SECONDARY,
  },
  historyItemTotal: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '17px',
    color: COLORS.TEXT.PRIMARY,
    textAlign: 'right',
  },
  calculateIcon: {
    marginBottom: -3,
    width: 14,
    height: 14,
    cursor: 'pointer',
  },
  difference: {
    position: 'relative',
    fontWeight: 400,
    fontSize: 12,
    lineHeight: '16px',
    color: COLORS.TEXT.SECONDARY,
  },
  historyCurrency: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
  },
  showBtn: {
    cursor: 'pointer',
    border: 'none',
    padding: 0,
    marginTop: 10,
    backgroundColor: 'transparent',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.ACTION.PRIMARY,
  },
  newHistoryItem: {
    animation: '$blink 1s ease-out',
  },

  '@keyframes blink': {
    '0%': { backgroundColor: 'transparent' },
    '50%': { backgroundColor: COLORS.HIGHLIGHTS.PROMINENT },
    '100%': { backgroundColor: 'transparent' },
  },
  skeleton: {
    width: '70%',
    height: 22,
  },
  tableHeaderRow: {
    background: COLORS.WHITE,
    height: 36,
    boxShadow: `inset 0 -1px 0 0 ${COLORS.DIVIDER}`,
    position: 'sticky',
    top: -1,
    fontWeight: 400,
    fontSize: 12,
    color: COLORS.GRAY.SLATE,

    '& td:nth-child(1)': {
      minWidth: 100,
    },
    '& td:nth-child(2)': {
      whiteSpace: 'nowrap',
      textAlign: 'right',
      paddingRight: 40,
    },
    '& td:nth-child(3)': {
      textAlign: 'right',
      paddingRight: 40,
    },
    '& td:nth-child(4)': {
      textAlign: 'right',
      paddingRight: 12,
    },
    '& td:nth-child(5)': {
      maxWidth: 20,
    },
  },
  tableDataRow: {
    boxShadow: `inset 0 -1px 0 0 ${COLORS.DIVIDER}`,
    height: 36,
    backgroundColor: COLORS.WHITE,
    fontWeight: 400,
    fontSize: 14,
    color: COLORS.GRAY.DARK,
    lineHeight: '17px',

    '& td': {
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },
    '& td:nth-child(2)': {
      textAlign: 'right',
      fontVariantNumeric: 'tabular-nums',
      letterSpacing: '-0.01em',
      paddingRight: 40,
    },
    '& td:nth-child(3)': {
      textAlign: 'right',
      fontVariantNumeric: 'tabular-nums',
      letterSpacing: '-0.01em',
      paddingRight: 40,
    },
    '& td:nth-child(4)': {
      textAlign: 'right',
      fontVariantNumeric: 'tabular-nums',
      letterSpacing: '-0.01em',
      paddingRight: 12,
    },
    '& td:nth-child(5)': {
      textAlign: 'left',
      fontVariantNumeric: 'tabular-nums',
      letterSpacing: '-0.01em',
    },

    [BREAKPOINTS.MOBILE]: {
      width: 600,
    },
  },
  centeredTitle: {
    width: '100%',
    textAlign: 'right',
  },
  centeredTitleToRight: {
    width: '100%',
    textAlign: 'right',
  },
  pendingConfirmationIcon: {
    fill: COLORS.GRAY.SLATE,
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: -40,
    cursor: 'pointer',
  },
  hint: {
    display: 'flex',
    padding: '24px 16px 32px 24px',
    gap: 16,
    width: 286,
    background: COLORS.HIGHLIGHTS.NOTE,
    borderRadius: 8,
    height: 'fit-content',
    whiteSpace: 'pre-line',
    color: COLORS.TEXT.REGULAR,

    '@media (max-width: 1439px)': {
      width: 440,
      marginLeft: -24,
    },
  },
  tableDataRowHoverable: {
    cursor: 'pointer',

    '&:hover': {
      background: COLORS.HIGHLIGHTS.ACTIONABLE,
    },
  },
});
