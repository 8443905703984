import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isValid from 'date-fns/isValid';

const ISO_WITHOUT_TZ = "yyyy-MM-dd'T'HH:mm:ss";

export const getIsoDateWithoutTz = (date: Date): string => {
  return format(date, ISO_WITHOUT_TZ);
};

export const parseIsoDateWithoutTz = (date: string): Date => parseISO(date.replace('Z', ''));

export const checkIfStrDate = (str: string): boolean => {
  const parsedDate = parseISO(str);
  return isValid(parsedDate);
};

export const getDateOfJoining = (dateOfJoin: string | null): string =>
  dateOfJoin ? format(parseIsoDateWithoutTz(dateOfJoin), 'dd MMM yyyy') : 'N/A';

export const getBirthDay = (date: string | null): string =>
  date ? format(parseIsoDateWithoutTz(date), 'MMMM d') : 'Not specified';
