import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import differenceInMonths from 'date-fns/differenceInMonths';
import { COLORS } from '@styles/colors';

export interface UserGoalStatusData {
  isOverdue: boolean;
  howLongOverdue: number;
  percentageOfDone: number | null;
  labelColor: string;
  isRangesUsed: boolean;
}

export const getGoalStatusData = (goal: UserGoalPageData): UserGoalStatusData => {
  const { dueDate, actualResult, worstCaseResult, bestCaseResult, isRange } = goal ?? {};

  const percentageOfDone =
    actualResult || (actualResult === 0 && bestCaseResult === 0)
      ? isRange
        ? (worstCaseResult < bestCaseResult && actualResult >= worstCaseResult) ||
          (worstCaseResult > bestCaseResult && actualResult <= worstCaseResult)
          ? Math.round(Math.abs((actualResult - worstCaseResult) / (bestCaseResult - worstCaseResult)) * 100)
          : 0
        : Math.round((actualResult / worstCaseResult) * 100)
      : null;

  const today = new Date();
  const isOverdue = today > new Date(dueDate);
  const howLongOverdue = differenceInMonths(today, new Date(dueDate));
  const labelColor =
    percentageOfDone !== null
      ? percentageOfDone < 11
        ? COLORS.SCALE10.VOTE1
        : percentageOfDone < 21
        ? COLORS.SCALE10.VOTE2
        : percentageOfDone < 31
        ? COLORS.SCALE10.VOTE3
        : percentageOfDone < 41
        ? COLORS.SCALE10.VOTE4
        : percentageOfDone < 51
        ? COLORS.SCALE10.VOTE5
        : percentageOfDone < 61
        ? COLORS.SCALE10.VOTE6
        : percentageOfDone < 71
        ? COLORS.SCALE10.VOTE7
        : percentageOfDone < 81
        ? COLORS.SCALE10.VOTE8
        : percentageOfDone < 91
        ? COLORS.SCALE10.VOTE9
        : COLORS.SCALE10.VOTE10
      : '';

  return {
    isOverdue,
    howLongOverdue,
    labelColor,
    percentageOfDone,
    isRangesUsed: isRange,
  };
};
