import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setPeopleHappinessDataGroupBy, setSortInfo } from './redux';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { usePeopleHappinessData } from './hooks/usePeopleHappinessData';
import { PeopleHappinessTable } from './components/PeopleHappinessTable';
import { DataState } from '@shared/enums/DataState';
import { useStyles } from './PeopleHappinessPage.styles';
import { HappinessSlider } from '@shared/components/HappinessSlider';
import { QuestionSelector } from '@modules/HappinessModule/components/QuestionSelector';
import { PeopleHappinessPageFilter } from './components/PeopleHappinessPageFilter/PeopleHappinessPageFilter';
import { usePeopleHappinessPageFilters } from './hooks/usePeopleHappinessPageFilters';
import { useSearchParams } from 'react-router-dom';
import { PeopleHappinessFilterAnotherFieldsNames } from './enums/PeopleHappinessFilterFieldsNames';
import { TrueFalse } from '@shared/enums/TrueFalse';
import { PeopleHappinessPageNoDataInfoView } from './components/PeopleHappinessPageNoDataInfoView';
import { MenuItem, Select, SelectChangeEvent, Stack, Typography } from '@mui/material';
import { groupBySelector } from './redux/selectors';
import { PeopleHappinessDataGroupBy } from './enums/PeopleHappinessDataGroupBy';
import { PeopleHappinessGroupedTable } from './components/PeopleHappinessGroupedTable';
import Skeleton from 'react-loading-skeleton';

export const PeopleHappinessPage: React.FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const {
    peopleHappinessData,
    sortInfo,
    onScrollThresholdPass,
    isDataChunkLoading,
    filtersToAplly,
    peopleHappinessGroupedData,
    groupBy,
  } = usePeopleHappinessData();
  const onTableSortClick = useCallback((sortInfo) => dispatch(setSortInfo(sortInfo)), [dispatch]);
  const setPageTitle = useTitle();
  const isDataLoading =
    peopleHappinessData.state === DataState.Pending || peopleHappinessGroupedData.state === DataState.Pending;

  useEffect(() => {
    setPageTitle(pageTitles.peopleHappinessPage);
  }, []);

  const { initialValues, defaultValues, debounceReaction } = usePeopleHappinessPageFilters();

  const [reaction, setReaction] = useState(
    filtersToAplly ? [filtersToAplly.reactionGte, filtersToAplly.reactionLte] : []
  );

  useEffect(() => {
    if (filtersToAplly) {
      setReaction([filtersToAplly.reactionGte, filtersToAplly.reactionLte]);
    }
  }, [filtersToAplly]);

  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeGroupOption = useCallback(
    (event: SelectChangeEvent) => {
      const { value } = event.target;
      dispatch(setPeopleHappinessDataGroupBy(value as PeopleHappinessDataGroupBy));
      searchParams.set(PeopleHappinessFilterAnotherFieldsNames.GroupBy, value as string);
      setSearchParams(searchParams);
    },
    [searchParams]
  );

  const groupBySelected = useSelector(groupBySelector);

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i} className={styles.tableDataRow}>
        <td
          style={{
            paddingLeft: '20px',
          }}
        >
          <Skeleton className={styles.cellSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.cellSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.cellSkeleton} />
        </td>
        {groupBySelected !== PeopleHappinessDataGroupBy.None && (
          <td>
            <Skeleton className={styles.cellSkeleton} />
          </td>
        )}
      </tr>
    ));

  const dropDownOptions = [
    {
      value: PeopleHappinessDataGroupBy.Department,
      label: 'Department',
    },

    {
      value: PeopleHappinessDataGroupBy.Location,
      label: 'Legal location',
      divider: true,
    },
    {
      value: PeopleHappinessDataGroupBy.None,
      label: 'No grouping',
    },
  ];

  const totalCount =
    groupBySelected === PeopleHappinessDataGroupBy.None
      ? peopleHappinessData.data?.totalCount
      : peopleHappinessGroupedData.data?.totalCount ?? 0;

  return (
    <div className={styles.tableContainer}>
      <div className={styles.question}>
        <QuestionSelector title="All people"></QuestionSelector>
      </div>
      <div className={styles.tableHeader}>
        <span className={styles.title}>
          People happiness{' '}
          {!isDataLoading && (
            <Typography
              variant="body2"
              sx={{
                marginTop: '5px',
              }}
            >
              {totalCount} {totalCount === 1 ? 'person' : 'people'} found
            </Typography>
          )}
        </span>
        {filtersToAplly && reaction && (
          <div className={styles.sliderContainer}>
            <HappinessSlider
              value={reaction}
              onChange={(_, value) => {
                setReaction(value as number[]);
                debounceReaction(value as number[]);
              }}
              disabled={searchParams.get(PeopleHappinessFilterAnotherFieldsNames.IncludeResponded) === TrueFalse.False}
            />
          </div>
        )}
        <Stack className={styles.filters} flexDirection="row" alignItems="center" columnGap="7px">
          <Select
            size="small"
            renderValue={(value) => `Group by: ${value}`}
            value={groupBySelected}
            onChange={handleChangeGroupOption}
          >
            {dropDownOptions.map((option) => (
              <MenuItem key={option.value} value={option.value} divider={option.divider}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
          <PeopleHappinessPageFilter initialValues={initialValues} defaultValues={defaultValues} />
        </Stack>
      </div>
      {!isDataLoading &&
      (groupBySelected === PeopleHappinessDataGroupBy.None
        ? peopleHappinessData.data?.items.length === 0
        : peopleHappinessGroupedData.data?.items.length === 0) ? (
        <PeopleHappinessPageNoDataInfoView
          defaultValues={defaultValues}
          initialValues={initialValues}
          filtersToAplly={filtersToAplly}
        />
      ) : groupBySelected === PeopleHappinessDataGroupBy.None ? (
        <PeopleHappinessTable
          isDataChunkLoading={isDataChunkLoading}
          data={peopleHappinessData.data?.items ?? []}
          sortInfo={sortInfo}
          onSortClick={onTableSortClick}
          onScrollThresholdPass={onScrollThresholdPass}
          isDataLoading={isDataLoading}
          tableSkeleton={tableSkeleton}
        />
      ) : (
        <PeopleHappinessGroupedTable
          sortInfo={sortInfo}
          onSortClick={onTableSortClick}
          groupedData={peopleHappinessGroupedData.data?.items ?? []}
          isDataLoading={isDataLoading}
          tableSkeleton={tableSkeleton}
          groupBy={groupBy}
        />
      )}
    </div>
  );
};
