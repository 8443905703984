import { HttpStatusCode } from '@shared/enums/HttpStatusCode';
import { paths } from '@shared/enums/paths';
import { ErrorInfo } from '@shared/interfaces/ErrorInfo';
import React, { FC, memo, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { AccessDeniedView } from './components/AccessDeniedView';
import { useStyles } from './DataErrorView.styles';

interface Props {
  error?: ErrorInfo;
  inComponent?: boolean;
}

const DataErrorViewComponent: FC<Props> = ({ error, inComponent }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const goBack = useCallback(() => {
    navigate(-1);
  }, []);

  return (
    <>
      {error?.code === HttpStatusCode.Forbidden ? (
        <div className={styles.container}>
          <AccessDeniedView inComponent={inComponent} />
        </div>
      ) : (
        <div className={styles.root}>
          <h1 className={styles.symbol}>🙊</h1>
          <h1 className={styles.mainHeading}>Could not load data from server</h1>
          <h3 className={styles.explainText}>Please try visiting this page later</h3>

          <div className={styles.buttonsContainer}>
            <button className={styles.link} onClick={goBack}>
              &larr; Back
            </button>
            <Link className={styles.link} to={paths.root}>
              Home
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

export const DataErrorView = memo(DataErrorViewComponent);
