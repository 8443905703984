import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadUsersWhomICanCreateFlag } from '@modules/App/redux/users/actions';
import { selectUsersWhomICanCreateFlag } from '@modules/App/redux/users/selectors';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { DataState } from '@shared/enums/DataState';

export const useUsersWhomICanCreateFlag = (): AsyncData<UserInfo[]> => {
  const dispatch = useDispatch();
  const usersWhomICanCreateFlagAsyncData = useSelector(selectUsersWhomICanCreateFlag);
  useEffect(() => {
    if (usersWhomICanCreateFlagAsyncData.state === DataState.Fulfilled) {
      return;
    }
    dispatch(loadUsersWhomICanCreateFlag());
  }, [usersWhomICanCreateFlagAsyncData.state]);

  return usersWhomICanCreateFlagAsyncData;
};
