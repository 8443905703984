import { put, all, call, takeLatest, select, takeLeading } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import {
  getPeopleHappinessData,
  getPeopleHappinessDataGrouped,
  loadNextChunkOfData,
  loadUsersFromParams,
  resolveSituationFromPeoplePage,
} from './actions';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import {
  appendPeopleHappinessData,
  setFilters,
  setIsMoreDataAvailable,
  setOffset,
  setPeopleHappinessData,
  setPeopleHappinessDataChunkLoading,
  setPeopleHappinessDataGrouped,
  setPeopleHappinessDataLoading,
} from './reducers';
import {
  fetchPeopleHappinessData,
  fetchPeopleHappinessDataGrouped,
} from '@modules/HappinessModule/api/peopleHappinessData';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { Question } from '@modules/HappinessModule/interfaces/question';
import { PEOPLE_HAPPINESS_TABLE_FETCH_ROWS_LIMIT, PEOPLE_HAPPINESS_TABLE_OFFSET_STEP } from '../constants';
import {
  PeopleHappinessPageGroupedData,
  PeopleHappinessPageNotGroupedData,
} from '../interfaces/PeopleHappinessDataItem';
import { postResolveSituation } from '@modules/HappinessModule/api/resolveSituation';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { fetchUserProfilePartialData } from '@modules/App/api/userProfileData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { PeopleHappinessFilterFormData } from '../interfaces/PeopleHappinessFilterData';
import { groupBySelector, happinessApplyFiltersSelector } from './selectors';
import { PeopleHappinessDataGroupBy } from '../enums/PeopleHappinessDataGroupBy';
import { HapinessRequestParams } from '@modules/HappinessModule/api/types';

function* getPeopleHappinessDataAsync({ payload: questionId }: ReturnType<typeof getPeopleHappinessData>) {
  try {
    const selectedQuestion: Question = yield select((state) => state.happiness.questions.selectedQuestion);
    yield put(setPeopleHappinessDataLoading());
    const sortInfo: SortInfo = yield select((state) => state.happiness.peopleHappinessPage.sortInfo);
    yield put(setIsMoreDataAvailable(true));
    yield put(setOffset(0));
    const filtersToAplly: PeopleHappinessFilterFormData | null = yield select(happinessApplyFiltersSelector);
    const requestParams: HapinessRequestParams = {
      sortInfo,
      filtersToAplly,
    };
    if (selectedQuestion) {
      requestParams.questionHistoryId = selectedQuestion.id;
    } else if (questionId) {
      requestParams.questionHistoryId = questionId;
    }
    const response: PeopleHappinessPageNotGroupedData = yield call(fetchPeopleHappinessData, requestParams);
    const setPeopleHappinessDataAction = setPeopleHappinessData({ data: response, state: DataState.Fulfilled });
    yield put(setPeopleHappinessDataAction);
    if (response.items.length < PEOPLE_HAPPINESS_TABLE_FETCH_ROWS_LIMIT) {
      yield put(setIsMoreDataAvailable(false));
    }
  } catch (e) {
    yield put(
      setPeopleHappinessData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* loadNextChunkOfDataAsync() {
  try {
    const isMoreDataAvailable: boolean = yield select(
      (state) => state.happiness.peopleHappinessPage.isMoreDataAvailable
    );
    const isDataLoading: boolean = yield select(
      (state) => state.happiness.peopleHappinessPage.peopleHappinessData.state === DataState.Pending
    );
    if (!isDataLoading && isMoreDataAvailable) {
      const questionHistoryId: string = yield select((state) => state.happiness.questions.selectedQuestion.id);
      const offset: number = yield select((state) => state.happiness.peopleHappinessPage.offset);
      const sortInfo: SortInfo = yield select((state) => state.happiness.peopleHappinessPage.sortInfo);
      const newOffset = offset + PEOPLE_HAPPINESS_TABLE_OFFSET_STEP;
      yield put(setPeopleHappinessDataChunkLoading(true));
      const filtersToAplly: PeopleHappinessFilterFormData | null = yield select(happinessApplyFiltersSelector);
      const response: PeopleHappinessPageNotGroupedData = yield call(fetchPeopleHappinessData, {
        questionHistoryId,
        offset: newOffset,
        sortInfo,
        filtersToAplly,
      });
      if (response.items.length < PEOPLE_HAPPINESS_TABLE_FETCH_ROWS_LIMIT) {
        yield put(setIsMoreDataAvailable(false));
      }
      yield put(appendPeopleHappinessData(response.items));
      yield put(setOffset(newOffset));
      yield put(setPeopleHappinessDataChunkLoading(false));
    }
  } catch (e) {
    yield put(
      setPeopleHappinessData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* resolveSituationFromPeoplePageAsync({ payload }: ReturnType<typeof resolveSituationFromPeoplePage>) {
  try {
    yield call(postResolveSituation, payload);
  } catch {
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* loadUsersFromParamsAsync({ payload }: ReturnType<typeof loadUsersFromParams>) {
  try {
    const users: UserInfo[] = yield all(
      payload.userIds.map((id) => {
        return call(fetchUserProfilePartialData, id);
      })
    );
    const primartReportsTos: UserInfo[] = yield all(
      payload.primaryReportsToIds.map((id) => {
        return call(fetchUserProfilePartialData, id);
      })
    );
    yield put(
      setFilters({
        primartReportsTos: {
          data: primartReportsTos,
          state: DataState.Fulfilled,
        },
        users: {
          data: users,
          state: DataState.Fulfilled,
        },
      })
    );
  } catch (error) {
    yield put(
      setFilters({
        primartReportsTos: {
          data: [],
          state: DataState.Rejected,
        },
        users: {
          data: [],
          state: DataState.Rejected,
        },
      })
    );
  }
}

function* getPeopleHappinessDataGroupedAsync({ payload: questionId }: ReturnType<typeof getPeopleHappinessData>) {
  try {
    yield put(setPeopleHappinessDataGrouped({ data: null, state: DataState.Pending }));
    const selectedQuestion: Question = yield select((state) => state.happiness.questions.selectedQuestion);
    const sortInfo: SortInfo = yield select((state) => state.happiness.peopleHappinessPage.sortInfo);
    const filtersToAplly: PeopleHappinessFilterFormData | null = yield select(happinessApplyFiltersSelector);
    const groupBy: PeopleHappinessDataGroupBy = yield select(groupBySelector);
    const requestParams: HapinessRequestParams = {
      groupBy,
      sortInfo,
      filtersToAplly,
    };
    if (selectedQuestion) {
      requestParams.questionHistoryId = selectedQuestion.id;
    } else if (questionId) {
      requestParams.questionHistoryId = questionId;
    }
    const response: PeopleHappinessPageGroupedData = yield call(fetchPeopleHappinessDataGrouped, requestParams);
    yield put(setPeopleHappinessDataGrouped({ data: response, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(setPeopleHappinessDataGrouped({ data: null, state: DataState.Rejected, error: getErrorInfo(e) }));
  }
}

function* watchGetPeopleHappinessData() {
  yield takeLatest(getPeopleHappinessData.type, getPeopleHappinessDataAsync);
  yield takeLatest(resolveSituationFromPeoplePage.type, resolveSituationFromPeoplePageAsync);
  yield takeLeading(loadNextChunkOfData.type, loadNextChunkOfDataAsync);
  yield takeLatest(loadUsersFromParams.type, loadUsersFromParamsAsync);
  yield takeLatest(getPeopleHappinessDataGrouped.type, getPeopleHappinessDataGroupedAsync);
}

export function* peopleHappinessPageSaga(): Generator {
  yield all([watchGetPeopleHappinessData()]);
}
