import { UserAddressData } from '@shared/interfaces/UserAddressData';
import { GoogleAddressComponent } from '../enums/AddressComponent';
import { ParsedAddressInfoType } from '../interfaces/addressTypesAndInterfaces';
import { GOOGLE_AUTOCOMPLETE_RESULTS_LANGUAGE } from '../../../constants/googleAutocompleteConstants';

export const getDetailedAddressInfo = (place: google.maps.places.PlaceResult): ParsedAddressInfoType => {
  const addressFromGoogleResponse = {
    [GoogleAddressComponent.StreetNumber]: '',
    [GoogleAddressComponent.Street]: '',
    [GoogleAddressComponent.City]: '',
    [GoogleAddressComponent.State]: '',
    [GoogleAddressComponent.Country]: '',
    [GoogleAddressComponent.Zip]: '',
  };
  place.address_components?.forEach((component: google.maps.GeocoderAddressComponent) => {
    const firstComponentType = component.types[0] as GoogleAddressComponent;
    if (!Object.values(GoogleAddressComponent).includes(firstComponentType)) {
      return;
    }
    addressFromGoogleResponse[firstComponentType] = component.long_name;
  });

  return addressFromGoogleResponse;
};

export const getSelectedDetailedAddressInfo = async ({
  placesService,
  placeId,
  notifyErrorFunc,
}: {
  placesService: google.maps.places.PlacesService | null;
  placeId: string;
  notifyErrorFunc: (message: string) => void;
}): Promise<ParsedAddressInfoType | null> => {
  let parsedAddressInfo = null;

  try {
    const placeDetails = await new Promise<google.maps.places.PlaceResult>((resolve) => {
      placesService?.getDetails(
        {
          placeId: placeId,
          language: GOOGLE_AUTOCOMPLETE_RESULTS_LANGUAGE,
        },
        (placeDetails) => {
          if (placeDetails) {
            resolve(placeDetails);
          }
        }
      );
    });
    parsedAddressInfo = getDetailedAddressInfo(placeDetails);
  } catch {
    notifyErrorFunc('Something went wrong while getting address details. Please try again later.');
  }
  return parsedAddressInfo;
};

export const getAddressInfo = (parsedAddressInfo: ParsedAddressInfoType): Required<UserAddressData> => {
  const addressLine1 = `${parsedAddressInfo[GoogleAddressComponent.StreetNumber]} ${
    parsedAddressInfo[GoogleAddressComponent.Street]
  }`;
  const addressInfo = {
    addressLine1: addressLine1.trim() === '' ? '' : addressLine1,
    addressLine2: '',
    city: parsedAddressInfo[GoogleAddressComponent.City],
    state: parsedAddressInfo[GoogleAddressComponent.State],
    country: parsedAddressInfo[GoogleAddressComponent.Country],
    zipCode: parsedAddressInfo[GoogleAddressComponent.Zip],
  };
  return addressInfo;
};
