import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams, matchPath } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, Typography } from '@mui/material';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { useAuth } from '@shared/hooks/useAuth';

import { useStyles } from './UserPage.styles';
import { zohoIdSelector } from './redux/selectors';
import { useUserData } from './hooks/useUserData';
import { PermissionAction } from '@modules/HappinessModule/enums/PermissionAction';
import { useUserCompensation } from '@modules/EvaluationModule/pages/UserCompensationPage/hooks/useUserCompensation';
import { useIsBrowsingOwnData } from '@modules/App/pages/UserPage/hooks/useIsBrowsingOwnData';
import { paths } from '@shared/enums/paths';
import { DataState } from '@shared/enums/DataState';
import { useUserPermissions } from '@shared/hooks/usePermissions';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { BackLink } from '@shared/components/BackLink';
import { RocketLoader } from '@shared/components/RocketLoader';
import { DataErrorView } from '@shared/components/DataErrorView';
import { ProfileComponent } from '@shared/components/ProfileComponent';
import { RockyModulesTabs } from '@shared/components/RockyModulesTabs';
import { UserNotFound } from '@shared/components/UserNotFound';
import { UserStatus } from '@shared/enums/UserStatus';
import { useUserProfileData } from '../UserProfilePage/hooks/useUserProfileData';
import { ExternalLinksPermissionsInterface } from '@modules/App/interfaces/ExternalLinksPermissions';

export const UserPage: React.FC = () => {
  const styles = useStyles();
  const { userData, fromLocation } = useUserData();
  const { data: profileData } = useUserProfileData();
  const { id } = useParams();
  const { data: userPermissions } = useUserPermissions({ id });
  const isBrowsingOwnData = useIsBrowsingOwnData();
  const isUserDataLoading = userData.state === DataState.Pending;
  const isFailed = userData.state === DataState.Rejected;
  const location = useLocation();
  const [isShowTabs, setIsShowTabs] = useState(false);
  const { isCompensationEnabled, isFlagsEnabled, isBonusesEnabled, isSmartGoalsEnabled, isPromotionsEnabled } =
    useUserEnabledFeatures();
  const { currentUser } = useAuth();
  const isOnSabbatical = profileData?.status === UserStatus.Sabbatical;
  const isCompensationDataAvailable =
    !!userPermissions &&
    userPermissions?.compensation &&
    userPermissions?.compensation?.compensation.includes(PermissionAction.Read) &&
    isCompensationEnabled &&
    !isOnSabbatical;
  const isPromotionsPageAvailable = userPermissions?.promotions.isReadable;
  // checks if the current user is primary reporting manager
  const isPrimaryReporter: boolean =
    !!userData.data?.primaryReportsTo?.id && userData.data?.primaryReportsTo.id === currentUser?.id;
  const isFlagsAvailable = (!!userPermissions && userPermissions?.flagPermissions.flag.isReadable) || isPrimaryReporter;
  const isGoalsAvailable =
    !!userPermissions && userPermissions?.smartGoals?.goals.isReadable && isSmartGoalsEnabled && !isOnSabbatical;
  const zohoId = useSelector(zohoIdSelector);
  const { isBonusExist } = useUserCompensation(zohoId ?? null);
  const isLinksTabAvailable =
    userPermissions?.externalLinks &&
    Object.values(userPermissions?.externalLinks).some(
      (link: ExternalLinksPermissionsInterface[keyof ExternalLinksPermissionsInterface]) => link.isReadable
    );

  useEffect(() => {
    const isShowTabs = !matchPath(
      { path: `${paths.users}/${id}/${paths.evaluations}/:evaluationId/*` },
      location.pathname
    );
    setIsShowTabs(isShowTabs);
  }, [location.pathname]);

  return isFailed ? (
    userData.error?.code === 404 ? (
      <UserNotFound />
    ) : (
      <DataErrorView error={userData.error} />
    )
  ) : (
    <>
      <BackLink
        classNameHolder={styles.backLinkContainer}
        name={fromLocation?.name}
        path={fromLocation?.path}
      ></BackLink>
      <div className={styles.container}>
        <div className={styles.profileComponent}>
          <ProfileComponent
            permissions={userPermissions}
            isNoActions={!isShowTabs || isBrowsingOwnData}
            isLoading={isUserDataLoading}
            user={userData.data}
          />
        </div>
        {isOnSabbatical && (
          <Alert
            className={styles.sabbaticalAlert}
            severity="warning"
            variant="standard"
            icon={<WorkHistoryIcon color="disabled" />}
          >
            <Typography component="p" variant="body1">
              On sabbatical
            </Typography>
          </Alert>
        )}
        {isShowTabs && (
          <RockyModulesTabs
            profile
            links={isLinksTabAvailable}
            flags={!isBrowsingOwnData && isFlagsEnabled && isFlagsAvailable}
            goals={isGoalsAvailable}
            compensation={isCompensationDataAvailable}
            peerReviews={!isOnSabbatical && isBrowsingOwnData}
            bonuses={isCompensationDataAvailable && isBonusExist && isBonusesEnabled}
            userZohoId={userData.data?.zohoId}
            happiness={!isOnSabbatical}
            evaluations={!isOnSabbatical}
            promotions={isPromotionsPageAvailable && isPromotionsEnabled}
            path={`${paths.users}/${id}`}
          />
        )}
        <div className={styles.divider}></div>
        <div className={styles.contentHolder}>
          {isUserDataLoading ? (
            <div className={styles.loader}>
              <RocketLoader />
            </div>
          ) : (
            <Outlet />
          )}
        </div>
      </div>
    </>
  );
};
