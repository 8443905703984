import { Button, Dialog, DialogTitle, IconButton, Stack, TextField, Typography } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { useStyles } from './RescheduleModal.styles';
import ClockIcon from '@assets/img/ClockIcon.png';
import CloseIcon from '@mui/icons-material/Close';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { extendEval, rescheduleEval } from '@modules/EvaluationModule/redux/evaluationsManegment/actions';
import { useDispatch, useSelector } from 'react-redux';
import {
  extendEvalStateSelector,
  rescheduleEvalStateSelector,
} from '@modules/EvaluationModule/pages/MyTeamEvalPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { ModalLoader } from '../ModalLoader/ModalLoader';
import { getDateToExtend } from '@shared/components/MonthSelector/helpers/getDateToExtend';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import addMonths from 'date-fns/addMonths';
import { getIsoDateWithoutTz } from '@shared/helpers/date';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import startOfMonth from 'date-fns/startOfMonth';
import { EopResult } from '@modules/EvaluationModule/enums/EopResult';

interface Props {
  evaluationData: {
    evaluationId: MyTeamEvalData['evaluationId'];
    evaluationDate: MyTeamEvalData['evaluationDate'];
    evaluateeName: string;
    peoplePartnerName: string | null;
    status: EvaluationStatus;
  };
  isModalOpen: boolean;
  closeModal: () => void;
  isEop: boolean;
}

export const RescheduleModal: FC<Props> = ({ evaluationData, isModalOpen, closeModal, isEop }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { evaluationId, evaluationDate, evaluateeName, peoplePartnerName } = evaluationData;
  const nextEvalDate = getIsoDateWithoutTz(addMonths(new Date(evaluationDate), 1));
  const [selectedDate, setSelectedDate] = useState<string>(nextEvalDate);
  const [reason, setReason] = useState<string | undefined>();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const rescheduleEvalState = useSelector(rescheduleEvalStateSelector);
  const extendEvalState = useSelector(extendEvalStateSelector);
  const isLoading = isEop ? extendEvalState === DataState.Pending : rescheduleEvalState === DataState.Pending;
  const isEvalOngoing = evaluationData.status === EvaluationStatus.Ongoing;

  const resetData = () => {
    setSelectedDate(nextEvalDate);
    setReason(undefined);
    setIsSubmitted(false);
  };

  useEffect(() => {
    if (!isModalOpen) {
      resetData();
    }
  }, [isModalOpen]);

  useEffect(() => {
    if (!isLoading) {
      closeModal();
    }
  }, [isLoading]);

  const trimmedReason = reason?.trim();

  const handleReschedule = () => {
    setIsSubmitted(true);
    if (!trimmedReason) {
      dispatch(displayNotification('Please provide a reason'));
      return;
    }
    dispatch(
      isEop
        ? extendEval({
            evaluationId,
            newDate: selectedDate,
            reason: trimmedReason,
            eopResult: EopResult.Extended,
          })
        : rescheduleEval({
            evaluationId,
            newDate: selectedDate,
            reason: trimmedReason,
          })
    );
  };

  const onDateChange = (value: Date | null) => {
    if (!value) {
      return;
    }

    const formattedDate = getDateToExtend({
      selectedDate: value.toISOString(),
    });

    setSelectedDate(formattedDate);
  };

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <div className={styles.dialogRoot}>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
        {isLoading ? (
          <ModalLoader />
        ) : (
          <Stack>
            <Stack justifyContent="center" alignItems="center">
              <img src={ClockIcon} />
            </Stack>
            <DialogTitle textAlign="center">
              {isEop ? 'Probation period will be extended to' : 'Reschedule evaluation'}
            </DialogTitle>
            <DatePicker
              label="Month"
              disablePast
              openTo="month"
              views={['year', 'month']}
              inputFormat="MMMM yyyy"
              value={selectedDate}
              disableMaskedInput
              onChange={onDateChange}
              minDate={isEop ? new Date(evaluationDate) : startOfMonth(new Date())}
              renderInput={(params) => (
                <TextField
                  {...params}
                  onKeyDown={(e) => e.preventDefault()}
                  sx={{
                    marginBottom: '16px',
                  }}
                />
              )}
            />
            <TextField
              label="Reason"
              value={reason ?? ''}
              onChange={(e) => setReason(e.target.value)}
              placeholder="Leave a note why the evaluation should be postponed"
              multiline
              rows={5}
              error={!trimmedReason && isSubmitted}
              helperText={'The reason will be saved to evaluation history'}
              FormHelperTextProps={{
                sx: {
                  marginLeft: '0',
                },
              }}
            />

            {isEvalOngoing && (
              <Stack
                sx={{
                  marginTop: '16px',
                }}
              >
                <Typography variant="subtitle1">What happens</Typography>
                <ul className={styles.list}>
                  <li>
                    The email to {evaluateeName},{peoplePartnerName ? ` ${peoplePartnerName},` : ''} and you in copy, is
                    sent notifying about the eval month change with the reason for the delay.
                  </li>
                  <li>The current forms become unavailable.</li>
                  <li>All email reminders stop.</li>
                  <li>The gathered feedbacks remain in the system.</li>
                  <li>Once the eval is started again, you can choose to leave feedbacks or request it again.</li>
                  <li>Your feedback and {evaluateeName} is prefilled, but must be resubmitted with possible edits.</li>
                  <li>You can edit peers and clients.</li>
                </ul>
              </Stack>
            )}

            <Stack
              direction="row"
              columnGap="12px"
              sx={{
                marginTop: '14px',
              }}
            >
              <Button type="submit" variant="contained" onClick={handleReschedule}>
                {isEop ? 'Extend probation' : 'Reschedule'}
              </Button>
              <Button onClick={closeModal}>Cancel</Button>
            </Stack>
          </Stack>
        )}
      </div>
    </Dialog>
  );
};
