import { RootState } from '@modules/App/redux/store';
import { SurveyHistoryPageData } from '@modules/HappinessModule/interfaces/SurveyHistoryPageData';
import { AsyncData } from '@shared/interfaces/asyncData';

export const surveyHistoryDataSelector = (state: RootState): AsyncData<SurveyHistoryPageData[]> =>
  state.happiness.surveyHistoryPage.questionGroupHistoryData;

export const surveyHistorySelectedLocationNameSelector = (state: RootState): string | null =>
  state.happiness.surveyHistoryPage.selectedLocationName;

export const surveyHistorySelectedDepartmentId = (state: RootState): string | null =>
  state.happiness.surveyHistoryPage.selectedDepartmentId;
