import { makeStyles } from '@mui/styles';

import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: COLORS.WHITE,
  },
  title: {
    maxWidth: '19rem',
    textAlign: 'center',
    color: COLORS.GRAY.SLATE,
  },
});
