import React, { useRef, useState } from 'react';
import { useStyles } from './AllFlagsFilters.styles';
import { useSearchParams } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { Button, DialogTitle, Drawer, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FlagFiltersFields } from '../../interfaces/FlagFiltersFields';
import { SearchParamObject } from '../../interfaces/SearchParamObject';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { ReactComponent as FilterIcon } from '@assets/icons/filtersIcon.svg';
import { ReactComponent as ResetFiltersIcon } from '@assets/icons/resetFiltersIcon.svg';
import { FiltersForm } from '../FiltersForm/FiltersForm';
import { escalationObject, projectEndObject, statusObject, userStatusesObject } from '../../constants/constants';
import noop from 'lodash/noop';
import { LoadingButton } from '@mui/lab';

interface Props {
  tomData: UserInfo[];
  prData: UserInfo[];
  allDeparttmentsData: DepartmentData[];
  isLoading: boolean;
  initialValues: FlagFiltersFields;
  isFilterLoading: boolean;
}

export const AllFlagsFilters: React.FC<Props> = ({
  tomData,
  prData,
  allDeparttmentsData,
  isLoading,
  initialValues,
  isFilterLoading,
}) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);
  const formRef = useRef<FormikProps<FlagFiltersFields>>(null);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const searchParamsObject = () => {
    const obj: SearchParamObject = {};
    for (const [key, value] of searchParams.entries()) {
      obj[key] = value;
    }
    return obj;
  };
  const filtersLength = Object.keys(searchParamsObject()).length;
  const isFilterExist = filtersLength > 0;

  const handleResetFilters = () => {
    setSearchParams({});
    formRef.current?.setValues({
      severity: 'all',
      status: [statusObject.all.label],
      escalationDate: escalationObject.anyTime.label,
      isNewHomeNeeded: false,
      projectEnd: projectEndObject.anyTime.value,
      userStatuses: [userStatusesObject.all.label],
      involvedUsers: [],
      flagAuthors: [],
      flagManagers: [],
      assignedTomIds: [],
      assignedPrIds: [],
      legalLocations: [],
      projects: [],
      reasonsSubReasons: [],
    });
  };

  const ResetBtn = (
    <Button startIcon={<ResetFiltersIcon />} onClick={handleResetFilters} size="small">
      Reset all
    </Button>
  );

  return (
    <>
      <div className={styles.btnsContainer}>
        <LoadingButton
          variant="contained"
          startIcon={<FilterIcon />}
          onClick={handleOpen}
          loading={isLoading}
          size="small"
        >
          Filters {isFilterExist && `: ${filtersLength}`}
        </LoadingButton>
        {isFilterExist && ResetBtn}
      </div>
      {!isFilterLoading && (
        <Drawer anchor="right" open={open} onClose={handleClose} keepMounted>
          <DialogTitle className={styles.title}>Filters {isFilterExist && ResetBtn}</DialogTitle>
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
            onClick={handleClose}
          >
            <CloseIcon />
          </IconButton>

          <Formik initialValues={initialValues} innerRef={formRef} onSubmit={noop} validateOnMount>
            {() => <FiltersForm allDeparttmentsData={allDeparttmentsData} tomData={tomData} prData={prData} />}
          </Formik>
        </Drawer>
      )}
    </>
  );
};
