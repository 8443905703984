import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearListOfCategories, setClientSurveysDataLoading } from '../redux';
import { loadCategoriesList, loadClientSurveys } from '../redux/actions';
import {
  selectAllClientSurveysSorted,
  selectCategoriesList,
  selectUpsertState,
  selectUpsertSurvey,
} from '../redux/selectors';
import { DataState } from '@shared/enums/DataState';

export const useClientSurveysPageData = (): {
  clientSurveys: AsyncData<ClientSurveyData[]>;
  upsertSurvey: ClientSurveyData | 'NEW' | undefined;
  upsertState: DataState;
  categoriesList: AsyncData<string[]>;
} => {
  const clientSurveys = useSelector(selectAllClientSurveysSorted);
  const upsertSurvey = useSelector(selectUpsertSurvey);
  const categoriesList = useSelector(selectCategoriesList);
  const upsertState = useSelector(selectUpsertState);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadClientSurveys());
    dispatch(loadCategoriesList());

    return () => {
      dispatch(setClientSurveysDataLoading());
      dispatch(clearListOfCategories());
    };
  }, [dispatch]);

  return { clientSurveys, upsertSurvey, upsertState, categoriesList };
};
