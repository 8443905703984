import { useEffect, useState } from 'react';
import { User } from '@sentry/react';
import { useDispatch, useSelector } from 'react-redux';
import { locationNotMatchingSinceSelector } from '@modules/App/redux/user/selectors';
import isSameDay from 'date-fns/isSameDay';
import { checkCurrentLocation } from '@modules/App/redux/user/actions';
import { Coordinates } from '@shared/interfaces/Coordinates';

const NUMBER_OF_DAYS_DIFF = 60;

export const usePhysicalLocation = (
  currentUser: User | null
): {
  updateLocationAlertOpen: boolean;
  closeUpdateLocationAlert: () => void;
} => {
  const today = new Date();
  const { locationLastCheckedAt } = currentUser ?? {};
  const locationNotMatchingSince = useSelector(locationNotMatchingSinceSelector);
  const daysInMs = NUMBER_OF_DAYS_DIFF * 24 * 60 * 60 * 1000;
  const timeDiffInMs = locationNotMatchingSince ? today.getTime() - new Date(locationNotMatchingSince).getTime() : 0;
  const dispatch = useDispatch();
  const [updateLocationAlertOpen, setUpdateLocationAlertOpen] = useState(false);
  const updateLocationAlertClosedAt = localStorage.getItem('updateLocationAlertClosedAt');
  const shouldShowPopUp = updateLocationAlertClosedAt ? !isSameDay(new Date(updateLocationAlertClosedAt), today) : true;

  const closeUpdateLocationAlert = () => {
    localStorage.setItem('updateLocationAlertClosedAt', today.toLocaleString());
    setUpdateLocationAlertOpen(false);
  };

  useEffect(() => {
    if (timeDiffInMs > daysInMs && shouldShowPopUp) {
      setUpdateLocationAlertOpen(true);
    }
  }, [locationNotMatchingSince]);

  useEffect(() => {
    if (isSameDay(new Date(locationLastCheckedAt), today)) {
      return;
    }

    window.navigator.geolocation.getCurrentPosition(
      async (pos) => {
        const coords: Coordinates = {
          latitude: pos.coords.latitude.toString(),
          longitude: pos.coords.longitude.toString(),
        };
        dispatch(checkCurrentLocation(coords));
      },
      (error) => {
        if (error.code == error.PERMISSION_DENIED) {
          dispatch(checkCurrentLocation());
        }
      }
    );
  }, []);

  return {
    updateLocationAlertOpen,
    closeUpdateLocationAlert,
  };
};
