export enum Role {
  vpManager = 'vp_manager',
  peoplePartner = 'people_partner',
  manager = 'manager',
  member = 'member',
  admin = 'is_admin',
  projectManager = 'project_manager',
  teamLead = 'team_lead',
  clientPartner = 'client_partner',
  deliveryManager = 'delivery_manager',
  portfolioDeliveryManager = 'portfolio_delivery_manager',
  engineeringManager = 'engineering_manager',
  hr = 'hr',
  csatMember = 'csat_member',
  peopleRelation = 'people_relation',
  locationHr = 'location_hr',
}
