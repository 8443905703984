import React, { useCallback, useEffect } from 'react';
import { pageTitles } from '@shared/enums/pageTitles';
import { useDispatch, useSelector } from 'react-redux';
import { useTitle } from '@shared/hooks/useTitle';
import { MyPeerReviewsTable } from './components/MyPeerReviewsTable';
import { getMyPeerReviewsData } from './redux/actions';
import { RootState } from '@modules/App/redux/store';
import { PeerReviewFeedbackTableDataItem } from '@modules/EvaluationModule/interfaces/PeerReviewFeedbackTableDataItem';
import { AsyncData } from '@shared/interfaces/asyncData';
import { DataState } from '@shared/enums/DataState';
import { useNavigate } from 'react-router-dom';
import { setSortInfo } from './redux';
import { getMyPeerReviewsSortedTableData, sortMyPeerReviewsInfoSelector } from './redux/selectors';
import { paths } from '@shared/enums/paths';
import { InfoView } from '@shared/components/InfoView/InfoView';
import EmptyResponse from '@assets/img/EmptyResponse.png';
import { useStyles } from './MyPeerReviewsPage.styles';
import { EvaluationInfoViewTexts } from '@modules/EvaluationModule/enums/EvaluationInfoViewTexts';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';

export const MyPeerReviewsPage: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const myPeerReviewsTableData = useSelector<RootState, AsyncData<PeerReviewFeedbackTableDataItem[]>>(
    getMyPeerReviewsSortedTableData
  );
  const sortInfo = useSelector(sortMyPeerReviewsInfoSelector);
  const onTableSortClick = useCallback((sortInfo) => dispatch(setSortInfo(sortInfo)), [dispatch]);

  const navigate = useNavigate();
  const setPageTitle = useTitle();
  const isDataLoading = myPeerReviewsTableData.state === DataState.Pending;

  useEffect(() => {
    setPageTitle(pageTitles.myPeerReviewsPage);
  }, []);

  useEffect(() => {
    dispatch(getMyPeerReviewsData());
  }, []);

  const onRowClick = useCallback((id, evaluationId, evaluationStatus, feedbackStatus) => {
    if (
      evaluationStatus === EvaluationStatus.Closed &&
      (feedbackStatus === EvalFeedbackStatus.Pending || feedbackStatus == EvalFeedbackStatus.Rejected)
    ) {
      return;
    }
    navigate(`${paths.peerForm}/${evaluationId}/${id}`);
  }, []);

  return (
    <div className={styles.root}>
      {!isDataLoading && myPeerReviewsTableData.data && myPeerReviewsTableData.data.length === 0 ? (
        <InfoView
          inComponent
          text={EvaluationInfoViewTexts.NoPeers}
          icon={EmptyResponse}
          className={styles.emptyView}
        />
      ) : (
        <MyPeerReviewsTable
          onSortClick={onTableSortClick}
          sortInfo={sortInfo}
          isDataLoading={isDataLoading}
          data={myPeerReviewsTableData.data}
          onRowClick={onRowClick}
        />
      )}
    </div>
  );
};
