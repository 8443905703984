import { FC, useEffect } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import { Box, FormControlLabel, Grid, Paper, Stack, Switch, Typography } from '@mui/material';
import { useStyles } from './FlagWhoIsInvolvedBlock.styles';
import { AssignedPR } from './components/AssignedPR/AssignedPR';
import { FlagFormFields } from '../../constants/FlagFormFields';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { ReactComponent as HandshakeIcon } from '@assets/icons/handshake.svg';
import { FlagWatchersSearch } from './components/FlagWatchersSearch/FlagWatchersSearch';
import { AutomaticFlagWatchers, FlagWatcherData } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { FlagWatchersTable } from './components/FlagWatchersTable';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { COLORS } from '@styles/colors';

interface Props {
  isNewFlag: boolean;
  flagData: FlagData;
  userFlagPermissions: FlagPermissionsData;
  engineeringManager: UserInfo | null;
  userAutomaticFlagWatchers: AutomaticFlagWatchers;
}

export const FlagWhoIsInvolvedBlock: FC<Props> = ({
  isNewFlag,
  flagData,
  userFlagPermissions,
  engineeringManager,
  userAutomaticFlagWatchers,
}) => {
  const styles = useStyles();
  const {
    values: { emailRecipients: recipients, flagWatchers, isRecipientsDisabled, isNewHomeNeeded, ...values },
    setFieldValue,
  } = useFormikContext<FlagFields>();

  const { user: userData } = flagData;

  const prTeamInvolvedEditable = isNewFlag
    ? userFlagPermissions.flag.isCreatable
    : userFlagPermissions.flag.isUpdatable;

  const canAssignPRTeamMember = isNewFlag
    ? userFlagPermissions.prTeamSection.isCreatable
    : userFlagPermissions.prTeamSection.isUpdatable;

  const handleDeleteRecipientClick = (user: UserInfo) => {
    setFieldValue(
      FlagFormFields.flagWatchers.name,
      flagWatchers.filter((flagWatcher) => flagWatcher.user?.id !== user.id)
    );
    if (recipients.some((recipient) => recipient.user?.id === user.id)) {
      setFieldValue(
        FlagFormFields.emailRecipients.name,
        recipients.filter((recipient) => recipient.user?.id !== user.id)
      );
    }
  };

  const toggleRecipients = (user: UserInfo) => {
    if (recipients.some((recipient) => recipient.user?.id === user.id)) {
      setFieldValue(
        FlagFormFields.emailRecipients.name,
        recipients.filter((recipient) => recipient.user?.id !== user.id)
      );
    } else {
      setFieldValue(FlagFormFields.emailRecipients.name, [
        ...recipients,
        { user, isCarbonCopy: false, userEmail: user.email },
      ]);
    }
  };

  const isPrTeamSectionReadable =
    userFlagPermissions.prTeamSection.isReadable || userFlagPermissions.prTeamSection.isCreatable;

  const userAutomaticFlagWatchersByCondition: AutomaticFlagWatchers = isNewHomeNeeded
    ? userAutomaticFlagWatchers
    : {
        ...userAutomaticFlagWatchers,
        tomMembers: [],
      };

  const checkIfFlagWatcherInTomMembers = (flagWatcher: FlagWatcherData) =>
    userAutomaticFlagWatchers.tomMembers.some((tomMember) => tomMember.user.id === flagWatcher.user?.id);

  useEffect(() => {
    if (isNewFlag && isNewHomeNeeded && flagWatchers.some(checkIfFlagWatcherInTomMembers)) {
      setFieldValue(
        FlagFormFields.flagWatchers.name,
        flagWatchers.filter((flagWatcher) => !checkIfFlagWatcherInTomMembers(flagWatcher))
      );
    }
  }, [isNewFlag, isNewHomeNeeded, userAutomaticFlagWatchers, flagWatchers]);

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid container rowSpacing={1} columnSpacing={2} className={styles.mainContainer}>
        <Grid item xs={6}>
          <Typography variant="h2">People with access to this flag</Typography>
        </Grid>
        <Grid
          item
          xs={isPrTeamSectionReadable ? 12 : 6}
          marginTop={isPrTeamSectionReadable ? '2px' : '-20px'}
          marginBottom={isPrTeamSectionReadable ? '34px' : '0px'}
        >
          <Grid
            container
            columnSpacing={2}
            alignItems="center"
            sx={{
              background: !values.isPrTeamInvolved ? COLORS.HIGHLIGHTS.NEUTRAL : COLORS.HIGHLIGHTS.NOTE,
              borderRadius: '4px',
              padding: '20px 10px 15px 10px !important',
            }}
          >
            <Grid item xs={isPrTeamSectionReadable ? 6 : 12}>
              <Stack flexDirection="row" columnGap="10px">
                <div className={styles.handshakeIcon}>
                  <HandshakeIcon />
                </div>
                <Stack rowGap="1px">
                  <Field
                    name={FlagFormFields.isPrTeamInvolved.name}
                    type="checkbox"
                    component={({ field }: FieldProps) => (
                      <FormControlLabel
                        disabled={!prTeamInvolvedEditable}
                        control={<Switch {...field} />}
                        label={<Typography variant="body1">{FlagFormFields.isPrTeamInvolved.label}</Typography>}
                      />
                    )}
                  />

                  <Typography variant="body2">Only for HR usage</Typography>
                </Stack>
              </Stack>
            </Grid>

            {values.isPrTeamInvolved && isPrTeamSectionReadable && (
              <Grid item xs={6}>
                <Box>
                  <AssignedPR disabled={!canAssignPRTeamMember} />
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={6} marginTop="-30px">
          <Stack rowGap="10px">
            <Typography variant="subtitle1">Watchers</Typography>
            <FlagWatchersSearch
              flagFor={userData}
              automaticFlagWatchers={userAutomaticFlagWatchersByCondition}
              engineeringManager={engineeringManager}
            />
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack className={styles.block} padding="10px" justifyContent="space-between" alignItems="center">
            {isNewFlag ? (
              <>
                <Typography variant="body2">
                  {isRecipientsDisabled ? (
                    <>
                      To avoid keeping everyone in the loop,
                      <br /> a new email thread will not be initiated.
                    </>
                  ) : (
                    <>
                      This is a mandatory step, if there’s a need to avoid it,
                      <br /> first confirm with HR
                    </>
                  )}
                </Typography>
                <Field
                  name={FlagFormFields.isRecipientsDisabled.name}
                  type="checkbox"
                  component={({ field }: FieldProps) => (
                    <FormControlLabel
                      control={
                        <Switch
                          {...field}
                          checked={!isRecipientsDisabled}
                          onChange={() =>
                            setFieldValue(FlagFormFields.isRecipientsDisabled.name, !isRecipientsDisabled)
                          }
                        />
                      }
                      label={<Typography variant="body1">{FlagFormFields.isRecipientsDisabled.label}</Typography>}
                    />
                  )}
                />
              </>
            ) : (
              <Stack flexDirection="row" padding="4px 16px" columnGap="17px" alignItems="center">
                {isRecipientsDisabled && <Typography fontSize="18px">🤫</Typography>}
                <Typography variant="body2">
                  {isRecipientsDisabled
                    ? 'Notifying people was disabled for this flag'
                    : 'A new email thread was started to keep everyone in the loop'}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Grid>
        <FlagWatchersTable
          userAutomaticFlagWatchers={userAutomaticFlagWatchers}
          isNewFlag={isNewFlag}
          handleDeleteRecipientClick={handleDeleteRecipientClick}
          addWatcherToFlagWatchers={(flagWatcher: FlagWatcherData) => {
            setFieldValue(FlagFormFields.flagWatchers.name, [...flagWatchers, flagWatcher]);
          }}
          toggleRecipients={toggleRecipients}
          emailRecipients={recipients}
          flagWatchers={flagWatchers}
          isRecipientsDisabled={isRecipientsDisabled}
        />
      </Grid>
    </Paper>
  );
};
