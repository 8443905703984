import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  modal: {
    width: 500,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },
  modalHeaderStyles: {},
  modalTitleStyles: {},
  modalBody: {},
  userBlock: {
    padding: '16px 0px',
    display: 'flex',
    gap: 24,
  },
  furtherStepsBlock: {
    marginTop: 24,
  },
  furtherStepsTitle: {
    margin: 0,
    color: COLORS.TEXT.PRIMARY,
  },
  furtherStepsList: {
    color: COLORS.TEXT.PRIMARY,
    paddingLeft: 16,
    margin: '8px 0 24px',

    '& li': {
      marginBottom: 8,
    },
  },
  userBlockItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  userNameLabel: {
    fontSize: '24px',
    fontWeight: '500',
    margin: 0,
  },
  positionLabel: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    minWidth: 100,
    paddingTop: 8,
    margin: '0',
  },
  avatarsBlock: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-end',
    margin: '18px 0 0 64px',
  },
  evaluateeAvatar: {
    borderRadius: 25,
    zIndex: 1,
    border: `4px solid ${COLORS.WHITE}`,
    marginLeft: -32,
  },
  reportsBlock: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    rowGap: 10,
    paddingTop: 10,
  },
  reportsBlockItem: {
    display: 'flex',
  },
  reportsBlockItemUser: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 7,
  },
  ppAvatar: {
    width: 30,
    height: 30,
    borderRadius: 12,
    objectFit: 'cover',
  },
  evaluateeAvatarSmall: {
    minWidth: 60,
    minHeight: 60,
    width: 60,
    height: 60,
    borderRadius: '100%',
    objectFit: 'cover',
  },
  modalBtnsContainer: {
    marginTop: 24,
    display: 'flex',
    gap: 12,
    width: '100%',
    justifyContent: 'flex-end',
  },
  button: {
    fontSize: '14px',
  },
  actionBtn: {
    width: 210,
  },
  title: {
    fontWeight: '500',
  },
  feedbackBlock: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
    background: COLORS.ACTION.HIGHLIGHT,
    padding: '16px 24px 20px',
    margin: '0 -24px',
    borderRadius: 8,

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
      margin: '0 -16px',
      borderRadius: 0,
    },
  },
  radioBtns: {
    display: 'flex',
    columnGap: 24,
  },
  radioBtn: {
    cursor: 'pointer',
    display: 'flex',
    columnGap: 7,
    alignItems: 'center',
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.PRIMARY,

    '& input': {
      width: 20,
      height: 20,
    },
  },
  link: {
    color: COLORS.ACTION.PRIMARY,

    '& svg': {
      marginLeft: 5,
      marginBottom: -2,
      width: 12,
      height: 12,
      fill: COLORS.ACTION.PRIMARY,
    },
  },
  divider: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    marginLeft: -50,
    marginRight: -42,
  },
});
