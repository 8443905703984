export const clientEvalFieldsDescription = [
  {
    questionName: 'functionalExpertise',
    questionTitle: 'Functional expertise',
    questionMark: 'functionalExpertise.mark',
    questionDescription: [
      'Effectively plans and organizes to achieve strategic goals.',
      'Manages multiple priorities.',
      'Identifies, selects and works effectively with internal and external resources.',
      'Demonstrates necessary critical thinking appropriate to position to solve problems and arrive at sound solutions.',
      'Demonstrates technical competence.',
      'Possesses knowledge and skill needed to meet day-to-day demands of position.',
    ],
  },
  {
    questionName: 'communication',
    questionTitle: 'Communication',
    questionMark: 'communication.mark',
    questionDescription: [
      'Able to persuade and influence others to facilitate the achievement of business goals.',
      'Demonstrates effective communication skills.',
      'Selects appropriate communication medium for clearly conveying messages.',
      'Is professional in how they speaks, writes, listens, and shares information.',
      'Responds to others in an effective, timely, clear, concise, logical, and organized manner.',
      'Willing to listen and receive feedback.',
    ],
  },
  {
    questionName: 'initiativeAndOwnership',
    questionTitle: 'Initiative and ownership',
    questionMark: 'initiativeAndOwnership.mark',
    questionDescription: [
      'Is an innovator.',
      'Seeks new, better, creative ways to achieve objectives.',
      'Demonstrates the ability to plan work and initiate a task without requiring explicit direction.',
      'Is a self-starter.',
      'Anticipates needs, proactively solves problems, takes action.',
      'Demonstrates a true, entrepreneurial spirit.',
      'Takes personal pride in being creative and driven to succeed.',
      'Takes intelligent risks, appropriate to position.',
    ],
  },
  {
    questionName: 'projectLeadership',
    questionTitle: 'Leadership and mentorship',
    questionMark: 'projectLeadership.mark',
    questionDescription: [],
  },
];

export const clientEvalMainField = {
  questionName: 'qualityOfWork',
  questionTitle: 'Quality of work',
  questionMark: 'qualityOfWork.mark',
  questionPlaceholder: 'Are you satisfied with the results?',
  questionComments: 'qualityOfWork.notes',
  questionDescription: [
    'Team member delivers great work without the need for a lot of hand-holding from managers and peers.',
  ],
};
