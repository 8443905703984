import React, { FC, memo, ReactNode } from 'react';
import Skeleton from 'react-loading-skeleton';

interface Props {
  isLoading: boolean;
  element: ReactNode;
  className?: string;
  count?: number;
}

const RockySkeletonComponent: FC<Props> = ({ isLoading, element, className, count = 1 }) => {
  return <>{isLoading ? <Skeleton className={className} count={count} /> : element}</>;
};

export const RockySkeleton = memo(RockySkeletonComponent);
