import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import chatBackImg from '@assets/img/chatBackImg.png';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    width: '100%',
  },
  backgroundPattern: {
    position: 'absolute',
    height: '100vh',
    left: 0,
    right: 0,
    zIndex: 2,
    backgroundImage: `url(${chatBackImg})`,
    opacity: 1,

    [BREAKPOINTS.MOBILE]: {
      position: 'fixed',
    },
  },
  backgroundGradient: {
    position: 'absolute',
    height: '100vh',
    left: 0,
    right: 0,
    zIndex: 1,
    background: '#f1f3f4',
    opacity: 1,

    [BREAKPOINTS.MOBILE]: {
      position: 'fixed',
    },
  },
  orgChartHolder: {
    height: '100%',
    width: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    zIndex: 3,
  },
  loaderHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
});
