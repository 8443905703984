import { RootState } from '@modules/App/redux/store';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { AsyncData } from '@shared/interfaces/asyncData';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { sortData } from '@shared/redux/helpers/sortData';
import { NO_MARK_THRESHOLD, NO_CHANGE_THRESHOLD } from '../constants/reportConstants';
import { UsersHappinessDataItem } from '../interfaces/usersHappinessDataItem';
import { UsersHappinessTableDataItem } from '../interfaces/usersHappinessTableDataItem';
import { HappinessData } from '../interfaces/happinessData';

const createGetIndividualTableDataSelector = (
  getMyTeamHappinessData: Selector<RootState, AsyncData<HappinessData<UsersHappinessDataItem>>>
): Selector<RootState, AsyncData<UsersHappinessTableDataItem[]>> =>
  createSelector(
    [getMyTeamHappinessData],
    ({ state, data }): AsyncData<UsersHappinessTableDataItem[]> => ({
      state,
      data: data
        ? data.survey.map(
            ({
              id,
              name,
              position,
              prevMark,
              mark,
              email,
              imageUrl,
              userId,
              reminderCount,
              comments,
              resolvedBy,
              country,
              city,
              isPrimaryReporter,
              flag,
            }) => ({
              id,
              userId,
              name,
              position,
              flag,
              // Threshold constant to make sorting work as intended.
              // People who didn't vote should appear between those who voted
              // above 7 and those who voted below 6
              mark: mark === null ? NO_MARK_THRESHOLD : mark,
              imageUrl,
              // Another threshold constant to make sorting work as intended.
              // People who have no votes delta for one reason or another should
              // appear between delta values 0 and -1
              change: mark !== null && prevMark !== null ? mark - prevMark : NO_CHANGE_THRESHOLD,
              email,
              reminderCount,
              comments,
              resolvedBy,
              country: country,
              city: city,
              isPrimaryReporter,
            })
          )
        : [],
    })
  );

const createGetSortedIndividualTableDataTempSelector = (
  getTableData: Selector<RootState, AsyncData<UsersHappinessTableDataItem[]>>,
  getSortInfo: Selector<RootState, SortInfo>
): Selector<RootState, AsyncData<UsersHappinessTableDataItem[]>> =>
  createSelector([getTableData, getSortInfo], ({ state, data }, sortInfo) => ({
    data: data ? sortData(data, sortInfo) : [],
    state,
  }));

export const createGetSortedIndividualTableDataSelector = (
  getMyTeamHappinessData: Selector<RootState, AsyncData<HappinessData<UsersHappinessDataItem>>>,
  getSortInfo: Selector<RootState, SortInfo>
): Selector<RootState, AsyncData<UsersHappinessTableDataItem[]>> => {
  const getTableData = createGetIndividualTableDataSelector(getMyTeamHappinessData);
  return createGetSortedIndividualTableDataTempSelector(getTableData, getSortInfo);
};
