import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  confirmationModalRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px 24px',
    width: 435,
    backgroundColor: COLORS.WHITE,
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  modalTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    margin: '0 0 18px 0',
  },
  modalDescription: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '150%',
    letterSpacing: '0.15px',
  },
  payRateInDescription: {
    fontWeight: '500',
  },
  attentionText: {
    color: COLORS.STATUS.WARNING,
  },
  recipientTitle: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: COLORS.TEXT.PRIMARY,
    opacity: 0.6,
    marginBottom: 14,
  },
  recipientItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 16px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  recipientCcItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '6px 16px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '150%',
  },
  recipientCcDescription: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: COLORS.TEXT.PRIMARY,
    opacity: 0.6,
  },
  btnContainer: {
    display: 'flex',
    gap: 10,
    marginLeft: 'auto',
    marginTop: 30,
  },
  backBtn: {
    backgroundColor: COLORS.ACTION.HIGHLIGHT,
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
  },
  commentsBlock: {
    background: COLORS.HIGHLIGHTS.NOTE,
    padding: '12px 24px',
    margin: '0 -24px',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '150%',
    letterSpacing: '0.15px',

    '& span': {
      display: 'block',
      marginBottem: 4,
      fontSize: 14,
      lineHeight: '143%',
      letterSpacing: '0.17px',
      color: COLORS.TEXT.SECONDARY,
    },
  },
});
