import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  titleBlock: {
    position: 'relative',
  },
  questionBlockTitleWrapper: {
    display: 'flex',
    gap: 10,
    margin: '0 0 16px 0',
  },
  questionBlockTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '120%',
    margin: 0,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
    },
  },
  infoIcon: {
    position: 'relative',
    alignSelf: 'center',
    cursor: 'pointer',
    color: COLORS.INPUTBORDER,

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
    },

    '& svg': {
      width: 18,
      height: 'auto',
      marginTop: 5,
    },
  },
  infoIconActive: {
    '& svg': {
      stroke: COLORS.ACTION.PRIMARY,
    },
  },
  dialogWindowOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    animation: '$fadeIn 0.1s ease-in',
    zIndex: 2,
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  dialogWindow: {
    position: 'absolute',
    zIndex: 2,
    background: COLORS.WHITE,
    minWidth: 360,
    maxWidth: 360,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: '32px 40px 40px 24px',
    margin: '5px 0 0 -25px',
    cursor: 'default',

    '& b': {
      fontWeight: '500',
    },
  },
  hidden: {
    display: 'none',
  },
  radioGroup: {
    display: 'flex',
    gap: 24,
    margin: '-12px 0 8px -8px',
    borderRadius: 20,
    padding: '4px 12px 2px 8px',
    alignSelf: 'flex-start',

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      gap: 8,
    },
  },
  radioGroupError: {
    backgroundColor: COLORS.HIGHLIGHTS.CRITICAL,
  },
  answerBlockWrapper: {
    marginBottom: 48,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  answerBlockComments: {
    width: '100%',
    minHeight: 80,
    border: 'none',
    boxShadow: `inset 0 0 0 1px ${COLORS.INPUTBORDER}`,
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '400',
    lineHeight: '130%',
    borderRadius: 4,
    padding: '10px 15px',
    resize: 'none',

    '&:focus': {
      boxShadow: `inset 0 0 0 2px ${COLORS.ACTION.PRIMARY}`,
      outline: 'none',
    },
  },
  answerBlockCommentsExtended: {
    minHeight: 210,
  },
  errorMsg: {
    display: 'block',
    position: 'absolute',
    top: '100%',
    left: 0,
    fontSize: '14px',
    color: COLORS.STATUS.CRITICAL,
  },
  errorRadio: {
    '& input:checked ~ span': {
      border: `2px solid ${COLORS.STATUS.CRITICAL}`,
    },
    '& input:checked ~ span::after': {
      backgroundColor: COLORS.STATUS.CRITICAL,
    },
  },
  input: {},
  checkmark: {},
});
