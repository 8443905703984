import { EvalPeerReviewData } from '@modules/EvaluationModule/interfaces/EvalPeerReviewData';
import { EvaluationMarkDetails } from '@modules/EvaluationModule/interfaces/EvaluationMarkDetails';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import classNames from 'classnames';
import { get } from 'lodash';
import { FC, useCallback, useEffect } from 'react';
import { UserEvalBlockWithMark } from '../UserEvalBlockWithMark';
import { useStyles } from './FeedbackOnQuestionBlock.styles';
import { useState } from 'react';

interface Props {
  questionTitle: string | null;
  questionName: string;
  isCurrentUserEvaluator: boolean;
  evaluateeFeedback?: EvaluationOptionsData | null;
  evaluatorFeedback?: EvaluationOptionsData | null;
  evaluateeUser?: UserInfo;
  evaluatorUser?: UserInfo;
  withMark?: boolean;
  peerReviews?: EvalPeerReviewData[] | null;
  clientReviews?: EvalPeerReviewData[] | null;
}

export const FeedbackOnQuestionBlock: FC<Props> = ({
  questionTitle,
  questionName,
  isCurrentUserEvaluator,
  evaluateeFeedback,
  evaluatorFeedback,
  evaluateeUser,
  evaluatorUser,
  withMark,
  peerReviews,
  clientReviews,
}) => {
  const styles = useStyles();

  const [feebacksLength, setFeebacksLength] = useState(0);

  useEffect(() => {
    let countOfAllFeedbacksForThisQuestion = 0;
    if (evaluateeFeedback?.isConfirmed) {
      countOfAllFeedbacksForThisQuestion++;
    }
    if (evaluatorFeedback?.isConfirmed) {
      countOfAllFeedbacksForThisQuestion++;
    }
    if (peerReviews) {
      countOfAllFeedbacksForThisQuestion += peerReviews.length;
    }
    if (clientReviews) {
      countOfAllFeedbacksForThisQuestion += clientReviews.length;
    }
    setFeebacksLength(countOfAllFeedbacksForThisQuestion);
  }, [evaluateeFeedback, evaluatorFeedback, peerReviews, clientReviews]);

  const getFeedback = useCallback(
    (feedback: EvaluationOptionsData | null) =>
      withMark
        ? (get(feedback, questionName) as EvaluationMarkDetails)
        : { mark: null, notes: get(feedback, questionName) },
    [withMark, questionName]
  );

  return (
    <div className={styles.root}>
      {questionTitle && (
        <>
          <div className={styles.title}>
            <div>{questionTitle}</div>
          </div>
          <div
            className={classNames(
              styles.feedbackContainer,
              { [styles.twoInRow]: feebacksLength === 2 },
              { [styles.threeInRow]: feebacksLength >= 3 }
            )}
          >
            {evaluateeFeedback?.isConfirmed && evaluateeUser && (
              <div
                className={classNames(
                  { [styles.oneInGrid]: feebacksLength === 1 },
                  { [styles.fisrtInGrid]: !isCurrentUserEvaluator },
                  { [styles.secondInGrid]: isCurrentUserEvaluator }
                )}
              >
                <UserEvalBlockWithMark user={evaluateeUser} question={getFeedback(evaluateeFeedback)} />
              </div>
            )}
            {evaluatorFeedback?.isConfirmed && evaluatorUser && (
              <div
                className={classNames(
                  { [styles.oneInGrid]: feebacksLength === 1 },
                  { [styles.fisrtInGrid]: isCurrentUserEvaluator },
                  { [styles.secondInGrid]: !isCurrentUserEvaluator }
                )}
              >
                <UserEvalBlockWithMark user={evaluatorUser} question={getFeedback(evaluatorFeedback)} />
              </div>
            )}
            {peerReviews &&
              peerReviews.length > 0 &&
              peerReviews.map((peerReview) => (
                <UserEvalBlockWithMark
                  key={peerReview.id}
                  user={peerReview.user}
                  question={getFeedback(peerReview.feedback)}
                />
              ))}
            {clientReviews &&
              clientReviews.length > 0 &&
              clientReviews.map((clientReview) => (
                <UserEvalBlockWithMark
                  key={clientReview.id}
                  user={clientReview.user}
                  question={getFeedback(clientReview.feedback)}
                />
              ))}
          </div>
        </>
      )}
    </div>
  );
};
