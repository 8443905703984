import React, { FC, ReactNode } from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  label: string;
  value: string | ReactNode;
}

export const FlagFieldBlockPreview: FC<Props> = ({ label, value }) => {
  return (
    <Stack rowGap="4px">
      <Typography variant="body2">{label}</Typography>
      <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
        {value}
      </Typography>
    </Stack>
  );
};
