export enum QuestionTitle {
  AdditionalNotes = 'Additional notes',
}

export const additionalFieldsDescriptions = [
  {
    questionName: 'achievements',
    questionTitle: 'Achievements',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'achievements',
    questionDescription: ['Reaches previously set goals and constantly develops skills and qualities.'],
  },
  {
    questionName: 'professionalGoals',
    questionTitle: 'Further professional goals',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'professionalGoals',
    questionDescription: [
      'Professional development goals are objectives you can set for yourself to help further your career.',
      'Consult with the department career ladder or think about what role or responsibilities and knowledge you want to obtain till the next evaluation period.',
    ],
  },
  {
    questionName: 'projectGoals',
    questionTitle: 'Further department and project goals',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'projectGoals',
    questionDescription: [
      'Department and project goals are objectives you commit to in the scope of planned work by your department or project.',
      'Usually refers to the current roadmap and needs.',
    ],
  },
  {
    questionName: 'notes',
    questionTitle: QuestionTitle.AdditionalNotes,
    questionPlaceholder: 'Additional notes',
    questionComments: 'notes',
  },
];
