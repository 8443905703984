import { UserInfo } from '@shared/interfaces/UserInfo';
import { ChangesInUserTeamData } from '@shared/interfaces/ChangesInUserTeamData';
import { FC, memo } from 'react';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { useStyles } from './ChangesInUserTeam.styles';

interface Props {
  changes: ChangesInUserTeamData;
}

const ChangesInUserTeamComponent: FC<Props> = ({ changes }) => {
  const styles = useStyles();

  const userById = (user: UserInfo | undefined) => (
    <div className={styles.reportsBlockItem}>
      <div className={styles.reportsBlockItemUser}>
        <RockyAvatar extraSmall imageUrl={user?.imageUrl} fullName={user?.name || ''}></RockyAvatar>
        <h4 className={styles.userNameLabel}>{user?.name}</h4>
      </div>
    </div>
  );

  return (
    <div className={styles.root}>
      {(changes.addedTeam || changes.deletedTeam) && (
        <div className={styles.reportsBlock}>
          <h4 className={styles.positionLabel}>Team changed to&nbsp;</h4>
          <div className={styles.reportsBlockItem}>
            <span className={styles.teamLabel}>{changes.addedTeam}</span>&nbsp;instead of {changes.deletedTeam}
          </div>
        </div>
      )}

      {changes.addedPrimaryRT || changes.deletedPrimaryRT ? (
        changes.addedPrimaryRT && changes.deletedPrimaryRT ? (
          <div className={styles.reportsBlock}>
            <h4 className={styles.positionLabel}>No longer reporting to&nbsp;</h4>
            <div className={styles.reportsBlockItem}>
              {userById(changes.deletedPrimaryRT)}
              &nbsp;Now reporting to&nbsp;
              {userById(changes.addedPrimaryRT)}
            </div>
          </div>
        ) : (
          changes.addedPrimaryRT &&
          !changes.deletedPrimaryRT && (
            <div className={styles.reportsBlock}>
              <h4 className={styles.positionLabel}>Now reporting to&nbsp;</h4>
              <div className={styles.reportsBlockItem}>{userById(changes.addedPrimaryRT)}</div>
            </div>
          )
        )
      ) : (
        !changes.addedPrimaryRT &&
        changes.deletedPrimaryRT && (
          <div className={styles.reportsBlock}>
            <h4 className={styles.positionLabel}>No longer reporting to&nbsp;</h4>
            <div className={styles.reportsBlockItem}>{userById(changes.deletedPrimaryRT)}</div>
          </div>
        )
      )}

      {changes.addedSecondaryRT ? (
        changes.addedSecondaryRT && changes.deletedSecondaryRT ? (
          <div className={styles.reportsBlock}>
            <h4 className={styles.positionLabel}>No longer reporting to&nbsp;</h4>
            <div className={styles.reportsBlockItem}>
              {userById(changes.deletedSecondaryRT)}
              &nbsp;Now reporting to&nbsp;
              {userById(changes.addedSecondaryRT)}
            </div>
          </div>
        ) : (
          changes.addedSecondaryRT &&
          !changes.deletedSecondaryRT && (
            <div className={styles.reportsBlock}>
              <h4 className={styles.positionLabel}>Now reporting to&nbsp;</h4>
              <div className={styles.reportsBlockItem}>{userById(changes.addedSecondaryRT)}</div>
            </div>
          )
        )
      ) : (
        !changes.addedSecondaryRT &&
        changes.deletedSecondaryRT && (
          <div className={styles.reportsBlock}>
            <h4 className={styles.positionLabel}>No longer reporting to&nbsp;</h4>
            <div className={styles.reportsBlockItem}>{userById(changes.deletedSecondaryRT)}</div>
          </div>
        )
      )}
    </div>
  );
};

export const ChangesInUserTeam = memo(ChangesInUserTeamComponent);
