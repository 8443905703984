import React from 'react';
import { useStyles } from './ModalLoader.styles';

export const ModalLoader: React.FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.loaderHolder}>
      <div className={styles.loader}></div>
    </div>
  );
};
