import { setTitle } from '@modules/App/redux/pageTitle';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';

const DEFAULT_TITLE = 'Rocky';

export const useTitle = (): ((title: string) => void) => {
  const dispatch = useDispatch();

  const setPageTitle = useCallback((title: string) => {
    dispatch(setTitle(title));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setTitle(DEFAULT_TITLE));
    };
  }, []);

  return setPageTitle;
};
