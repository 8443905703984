import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import {
  getUserEvalPageData,
  setUserEvalPageData,
  closeEval,
  submitEvalForm,
  autoSaveEvalForm,
  autosaveEvalNotesAndGoals,
  setFullEop,
  getUserEvalPermissions,
  getUserEvalPromotion,
} from './actions';
import {
  setEopLoadingState,
  setEvalNote,
  setEvalPermissions,
  setEvalPromotion,
  setUserEvalPageDataLoading,
} from './reducers';
import { DataState } from '@shared/enums/DataState';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import {
  fetchEvalPageData,
  fetchRelatedToEvalPromotion,
  fetchUserEvalPermissions,
  patchCloseEval,
  patchEvalEopStatus,
  patchEvalPageData,
} from '@modules/EvaluationModule/api/evalPageData';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { RootState } from '@modules/App/redux/store';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { User } from '@shared/interfaces/user';
import { HttpStatusCode } from '@shared/enums/HttpStatusCode';
import { EopResult } from '@modules/EvaluationModule/enums/EopResult';
import { PermissionAction } from '@modules/HappinessModule/enums/PermissionAction';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';

function* getUserEvalPageDataAsync({ payload }: ReturnType<typeof getUserEvalPageData>) {
  try {
    const setLoadingAction = setUserEvalPageDataLoading(DataState.Pending);
    yield put(setLoadingAction);
    const response: EvalPageData = yield call(fetchEvalPageData, payload);
    const action = setUserEvalPageData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (error) {
    yield put(
      setUserEvalPageData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(error),
      })
    );
  }
}

function* submitEvalFormAsync({ payload }: ReturnType<typeof submitEvalForm>) {
  try {
    const value = JSON.parse(JSON.stringify(payload));
    value.isConfirmed = true;
    const evalId: string = yield select((state) => state.evaluation.evalPage.evalPageData.data.evaluationId);
    const setLoadingAction = setUserEvalPageDataLoading(DataState.Pending);
    yield put(setLoadingAction);
    const response: EvalPageData = yield call(patchEvalPageData, value, evalId);
    const action = setUserEvalPageData({ data: response, state: DataState.Fulfilled });
    yield put(action);
    const isConfirmedByEvaluatee = response.evaluation.evaluatee?.isConfirmed;
    const isConfirmedByEvaluator = response.evaluation.evaluator?.isConfirmed;
    const user = response.userHistory;
    const userFirstName = user ? (user.name || ' ').split(' ')[0] : '';
    const evaluatorFirstName = user ? (response.owner.name || ' ').split(' ')[0] : '';
    yield put(
      displayNotification(
        isConfirmedByEvaluatee && !isConfirmedByEvaluator
          ? `Thanks, your review is submitted. Let's wait for ${evaluatorFirstName}`
          : isConfirmedByEvaluator && !isConfirmedByEvaluatee
          ? `Thanks, your review is submitted. Let's wait for ${userFirstName}`
          : 'Thanks, your review is submitted'
      )
    );
  } catch (e) {
    const error = getErrorInfo(e);
    yield put(setUserEvalPageDataLoading(DataState.Fulfilled));
    yield put(
      error?.code === HttpStatusCode.BadRequest
        ? displayNotification('Fields validation error 🤔\n Please check if all fields are filled 🤗')
        : displayNotification('Something went wrong while submitting. Please try again or refresh the page 🤧')
    );
  }
}

function* autoSaveEvalFormAsync({ payload }: ReturnType<typeof autoSaveEvalForm>) {
  const currentUser: User | null = yield select((state: RootState) => state.user.currentUser.data);
  const evalPageData: EvalPageData | null = yield select(
    (state: RootState) => state.evaluation.evalPage.evalPageData.data
  );
  if (!evalPageData || !currentUser) {
    return;
  }
  const evalId: string = evalPageData.evaluationId;
  const isCurrentUserEvaluator = currentUser?.id === evalPageData.owner.id;
  const action = setUserEvalPageData({
    data: {
      ...evalPageData,
      evaluation: {
        ...evalPageData.evaluation,
        evaluator: isCurrentUserEvaluator ? payload : evalPageData.evaluation.evaluator,
        evaluatee: !isCurrentUserEvaluator ? payload : evalPageData.evaluation.evaluatee,
      },
    },
    state: DataState.Fulfilled,
  });
  yield put(action);
  yield call(patchEvalPageData, payload, evalId);
}

function* autosaveEvalNotesAndGoalsAsync({ payload }: ReturnType<typeof autosaveEvalNotesAndGoals>) {
  const evalId: string = yield select((state) => state.evaluation.evalPage.evalPageData.data.evaluationId);
  yield call(patchCloseEval, evalId, payload);
}

function* closeEvalAsync({ payload }: ReturnType<typeof closeEval>) {
  try {
    const evalPageData: EvalPageData = yield select((state: RootState) => state.evaluation.evalPage.evalPageData.data);
    const evalId: string = evalPageData.evaluationId;
    yield call(patchCloseEval, evalId, payload);
    const setUserEvalPageDataAction = setUserEvalPageData({
      data: { ...evalPageData, status: EvaluationStatus.Closed },
      state: DataState.Fulfilled,
    });
    yield put(setUserEvalPageDataAction);
    yield put(displayNotification('This evaluation is now closed 🎉'));
    yield put(setEopLoadingState(DataState.Fulfilled));
  } catch {
    yield put(displayNotification("Sorry... This evaluation can't be closed 🤧"));
  }
}

function* setFullEopAsync({ payload }: ReturnType<typeof setFullEop>) {
  try {
    yield put(setEopLoadingState(DataState.Pending));
    const { eopResult, ...closeEvalPayload } = payload;
    const evalPageData: EvalPageData = yield select((state: RootState) => state.evaluation.evalPage.evalPageData.data);
    const evalId: string = evalPageData.evaluationId;
    yield call(patchEvalEopStatus, evalId, {
      eopStatus: eopResult,
      nextEvaluationDate: closeEvalPayload.next_evaluation_date ?? undefined,
    });
    yield put(
      setUserEvalPageData({
        data: {
          ...evalPageData,
          eopResult: eopResult,
          nextEvalDate: closeEvalPayload.next_evaluation_date,
          eopResolvedAt: new Date().toISOString(),
        },
        state: DataState.Fulfilled,
      })
    );
    if (!closeEvalPayload.autosave) {
      yield put(closeEval(closeEvalPayload));
    } else if (eopResult === EopResult.NotPassed) {
      yield call(patchCloseEval, evalId, closeEvalPayload);
      yield put(setEvalNote(closeEvalPayload?.notes ?? evalPageData.notes));
      yield put(setEopLoadingState(DataState.Fulfilled));
    }
    yield put(displayNotification(`Probationary period established. Status: ${eopResult}.`));
  } catch {
    yield put(displayNotification('Unfortunately, the end of the probationary period could not be established.'));
    yield put(setEopLoadingState(DataState.Rejected));
  }
}

function* getUserEvalPermissionsAsync({ payload }: ReturnType<typeof getUserEvalPermissions>) {
  try {
    const response: PermissionAction[] = yield call(fetchUserEvalPermissions, payload);
    const setAllEvalsPermissionsAction = setEvalPermissions({
      data: response,
      response: DataState.Fulfilled,
    });
    yield put(setAllEvalsPermissionsAction);
  } catch (e) {
    setEvalPermissions({
      data: null,
      response: DataState.Rejected,
      error: getErrorInfo(e),
    });
  }
}

function* getUserEvalPromotionAsync({ payload }: ReturnType<typeof getUserEvalPromotion>) {
  try {
    yield put(
      setEvalPromotion({
        data: null,
        state: DataState.Pending,
      })
    );
    const response: UserPromotionsData = yield call(fetchRelatedToEvalPromotion, payload);
    yield put(
      setEvalPromotion({
        data: response,
        state: DataState.Fulfilled,
      })
    );
  } catch (e) {
    yield put(
      setEvalPromotion({
        data: null,
        state: DataState.Fulfilled,
        error: getErrorInfo(e),
      })
    );
  }
}

function* watchEvals() {
  yield takeLatest(getUserEvalPageData.type, getUserEvalPageDataAsync);
  yield takeLatest(closeEval.type, closeEvalAsync);
  yield takeLatest(submitEvalForm.type, submitEvalFormAsync);
  yield takeLatest(autoSaveEvalForm.type, autoSaveEvalFormAsync);
  yield takeLatest(autosaveEvalNotesAndGoals.type, autosaveEvalNotesAndGoalsAsync);
  yield takeLatest(setFullEop.type, setFullEopAsync);
  yield takeLatest(getUserEvalPermissions.type, getUserEvalPermissionsAsync);
  yield takeLatest(getUserEvalPromotion.type, getUserEvalPromotionAsync);
}

export function* evalPageDataSaga(): Generator {
  yield all([watchEvals()]);
}
