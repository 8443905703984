import { FC } from 'react';
import format from 'date-fns/format';
import { Checkbox, FormControlLabel, Grid, Paper, Rating, Stack, Typography } from '@mui/material';

import { useStyles } from './NewHomeNeededBlockPreview.styles';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { FlagFormFields } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagFormFields';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { FlagFieldBlockPreview } from '../FlagFieldBlockPreview';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { checkIfZohoRecruitLinkShown } from '@modules/HappinessModule/pages/FlagPage/helpers/checkIfZohoRecruitLinkShown';
import { ZohoRecruitLink } from '@modules/HappinessModule/pages/FlagPage/components/ZohoRecruitLink';
import { NOT_SETTED_VALUE_MSG } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import LastEvalsTable from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/components/NewHomeNeededBlock/LastEvalsTable/LastEvalsTable';
import { NewHomeAvailableFromTypes } from '@modules/HappinessModule/enums/NewHomeAvailableFromTypes';
import { NewHomeProjectFoundPreview } from './components/NewHomeProjectFoundPreview';

interface Props {
  flagData: FlagData;
  userFlagPermissions: FlagPermissionsData;
}

export const NewHomeNeededBlockPreview: FC<Props> = ({ flagData, userFlagPermissions }) => {
  const styles = useStyles();
  const { flagFields, user } = flagData;

  const isNewHomeNeededReasonOptions = [
    {
      field: FlagFormFields.isPassive,
      description: 'Project change may be needed, but there is no risk of attrition within the next 2 month',
    },
    {
      field: FlagFormFields.isConfidential,
      description: 'NewHome process should be started without notifying the teammate',
    },
    {
      field: FlagFormFields.isReturnedFromSabbatical,
      description: "And there's no possibility to assign team member  to the project where they were before sabbatical",
    },
  ];

  const isNewHomeSectionReadable =
    userFlagPermissions.newHomeSection.isReadable || userFlagPermissions.newHomeSection.isCreatable;
  const isNewHomeTomSectionReadable =
    userFlagPermissions.newHomeTomSection.isReadable || userFlagPermissions.newHomeSection.isCreatable;

  const showManagerFeedback = isNewHomeSectionReadable && (flagFields.pros || flagFields.cons || flagFields.softSkills);
  const showTechnicalFeedback =
    isNewHomeSectionReadable &&
    (flagFields.technicalSoftSkills || flagFields.technicalPros || flagFields.technicalCons);
  const isZohoRecruitLinkShown = checkIfZohoRecruitLinkShown({
    isNewHomeTomSectionReadable,
    isNewHomeNeeded: flagFields.isNewHomeNeeded ?? false,
    zohoRecruitId: user?.zohoRecruitId ?? null,
  });

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid container rowGap="36px" className={styles.mainContainer}>
        {isNewHomeSectionReadable && (
          <Grid item xs={12}>
            <Stack flexDirection="row" columnGap="40px" alignItems="center">
              <Typography variant="h2">
                {flagFields.isNewHomeNeeded ? FlagFormFields.isNewHomeNeeded.label : 'NewHome is not needed'}
              </Typography>
              {isZohoRecruitLinkShown && <ZohoRecruitLink user={user} />}
            </Stack>
          </Grid>
        )}
        {flagFields.isNewHomeNeeded && (
          <>
            {isNewHomeSectionReadable && (
              <Grid item xs={12}>
                <Grid container>
                  {isNewHomeNeededReasonOptions.map(({ field: { name, label }, description }) => (
                    <Grid item xs={4} paddingRight="60px" key={label}>
                      <FormControlLabel
                        control={<Checkbox checked={flagFields[name] ?? false} disabled />}
                        label={label}
                      />
                      <Typography variant="body2">{description}</Typography>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            )}
            {isNewHomeTomSectionReadable && (
              <Grid item xs={3}>
                <Stack flexDirection="column" rowGap="4px">
                  <Typography variant="body2">{FlagFormFields.assignedTom.label}</Typography>
                  {flagFields.assignedTom ? (
                    <UserReporterBlock user={flagFields.assignedTom} isDetailInfo />
                  ) : (
                    <Typography>Not assigned</Typography>
                  )}
                </Stack>
              </Grid>
            )}
            <Grid container columnSpacing={2} rowSpacing={4}>
              {flagFields.lastDayOnTheProject && isNewHomeSectionReadable && (
                <Grid item xs={4}>
                  <FlagFieldBlockPreview
                    label={FlagFormFields.lastDayOnTheProject.label}
                    value={format(new Date(flagFields.lastDayOnTheProject), 'dd MMM yyyy')}
                  />
                </Grid>
              )}
              {isNewHomeTomSectionReadable && (
                <>
                  <Grid item xs={4}>
                    <FlagFieldBlockPreview
                      label={FlagFormFields.newHomeStartDate.label}
                      value={
                        flagFields.newHomeStartDate
                          ? format(new Date(flagFields.newHomeStartDate), 'dd MMM yyyy')
                          : NOT_SETTED_VALUE_MSG
                      }
                    />
                  </Grid>
                  <Grid item xs={5}>
                    <FlagFieldBlockPreview
                      label={FlagFormFields.newHomeEndDate.label}
                      value={
                        flagFields.newHomeEndDate
                          ? format(new Date(flagFields.newHomeEndDate), 'dd MMM yyyy')
                          : NOT_SETTED_VALUE_MSG
                      }
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FlagFieldBlockPreview
                      label={FlagFormFields.newHomeAvailableFromType.label}
                      value={flagFields.newHomeAvailableFromType ?? NOT_SETTED_VALUE_MSG}
                    />
                  </Grid>
                  {flagFields.newHomeAvailableFromType === NewHomeAvailableFromTypes.ExactDate && (
                    <Grid item xs={4}>
                      <FlagFieldBlockPreview
                        label={FlagFormFields.newHomeAvailableFrom.label}
                        value={
                          flagFields.newHomeAvailableFrom
                            ? format(new Date(flagFields.newHomeAvailableFrom), 'dd MMM yyyy')
                            : NOT_SETTED_VALUE_MSG
                        }
                      />
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                      NewHome results
                    </Typography>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <FlagFieldBlockPreview
                          label={FlagFormFields.newHomeResolution.label}
                          value={flagFields.newHomeResolution ? flagFields.newHomeResolution : NOT_SETTED_VALUE_MSG}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <Stack rowGap="6px">
                          <Typography variant="body2">{FlagFormFields.starRate.label}</Typography>
                          <Rating readOnly value={flagFields.starRate} max={10} />
                        </Stack>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2} sx={{ marginTop: '25px' }}>
                      {[
                        FlagFormFields.numOptionsShown,
                        FlagFormFields.numSubmissions,
                        FlagFormFields.numTICIFailed,
                      ].map((field) => {
                        const value = flagFields[field.name];
                        return (
                          <Grid item xs={4} key={field.name}>
                            <FlagFieldBlockPreview label={field.label} value={value ? value : NOT_SETTED_VALUE_MSG} />
                          </Grid>
                        );
                      })}
                      {flagFields.newProjectAssignment && (
                        <Grid item xs={12}>
                          <NewHomeProjectFoundPreview newProjectAssignmentData={flagFields.newProjectAssignment} />
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
              {flagFields.workload && isNewHomeSectionReadable && (
                <Grid item xs={3} md={2}>
                  <FlagFieldBlockPreview label={FlagFormFields.workload.label} value={flagFields.workload} />
                </Grid>
              )}
            </Grid>

            {showManagerFeedback && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                  Manager's feedback
                </Typography>
                <Grid container spacing={2}>
                  {[FlagFormFields.pros, FlagFormFields.cons, FlagFormFields.softSkills].map((field) => {
                    const value = flagFields[field.name];
                    return !value ? null : (
                      <Grid item xs={4} key={field.name}>
                        <FlagFieldBlockPreview label={field.label} value={flagFields[field.name]} />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            )}

            {showTechnicalFeedback && (
              <Grid item xs={12}>
                <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                  Technical feedback
                </Typography>
                <Grid container spacing={2}>
                  {[FlagFormFields.technicalPros, FlagFormFields.technicalCons, FlagFormFields.technicalSoftSkills].map(
                    (field) => {
                      const value = flagFields[field.name];
                      return !value ? null : (
                        <Grid item xs={4} key={field.name}>
                          <FlagFieldBlockPreview label={field.label} value={flagFields[field.name]} />
                        </Grid>
                      );
                    }
                  )}
                </Grid>
              </Grid>
            )}
          </>
        )}

        <Grid item xs={12} md={8}>
          <LastEvalsTable userId={user.id} />
        </Grid>
      </Grid>
    </Paper>
  );
};
