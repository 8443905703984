import { CompensationData, HistoricalCompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { CompensationHistoryTableHeaders } from '../../../enums/CompensationHistoryTableHeaders';
import { useStyles } from '../CompensationHistory.styles';
import { CompensationTypeLabel } from '@modules/EvaluationModule/enums/CompensationTypeLabel';
import classNames from 'classnames';
import { TableHeaderData } from '@shared/interfaces/TableHeaderData';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import Tooltip from '@mui/material/Tooltip';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { ReactNode } from 'react';
import { formatFinancialValue } from '../../../helpers/currencyOptions';

type TableRowFuncType = (compensationHistory: HistoricalCompensationData, itemNumber: number) => ReactNode;

export const getTableRow = ({
  tableHeaders,
  compensationData,
  isAddNewCompensationComplete,
  setCompensationForModal,
  isDetailsVisible,
}: {
  tableHeaders: TableHeaderData[];
  compensationData: CompensationData;
  isAddNewCompensationComplete: boolean;
  setCompensationForModal: (compensation: HistoricalCompensationData) => void;
  isDetailsVisible: boolean;
}): TableRowFuncType => {
  const styles = useStyles();

  const toDollarFormat = (value: string) => (
    <>
      <span className={styles.historyCurrency}>$</span> {value}
    </>
  );
  const tableColumns = {
    [CompensationHistoryTableHeaders.ReviewData]: {
      component: ({ period }: HistoricalCompensationData) => <>{format(parseISO(period), 'MMM ‘yy')}</>,
    },
    [CompensationHistoryTableHeaders.Base]: {
      component: ({ currentCompensation, compensationType }: HistoricalCompensationData) => (
        <>
          {toDollarFormat(formatFinancialValue(currentCompensation ?? 0))}
          <span className={styles.salaryType}>
            {' '}
            {CompensationTypeLabel[compensationType as keyof typeof CompensationTypeLabel]}
          </span>
        </>
      ),
    },
    [CompensationHistoryTableHeaders.Bonus]: {
      component: ({ bonus }: HistoricalCompensationData) => (
        <>{bonus ? toDollarFormat(formatFinancialValue(bonus)) : <span className={styles.noBonus}>–</span>}</>
      ),
    },
    [CompensationHistoryTableHeaders.Total]: {
      component: ({ compensationRateMonthly, bonus }: HistoricalCompensationData) => {
        const total = formatFinancialValue(Math.round(compensationRateMonthly + Number(bonus ?? 0)));
        return <>{toDollarFormat(total ?? 0)}</>;
      },
    },
    [CompensationHistoryTableHeaders.DifferenceCurrentTotalWithPrevious]: {
      component: (
        { compensationRateMonthly, bonus, isProcessing, compensationType }: HistoricalCompensationData,
        i: number
      ) => {
        const processingBlock = (
          <Tooltip title={'Raise is being under Finance team review'} placement="top">
            <AccessTimeIcon className={styles.pendingConfirmationIcon}></AccessTimeIcon>
          </Tooltip>
        );
        const historicalCompensation =
          compensationData?.historicalCompensations[
            i < compensationData?.historicalCompensations.length - 1 ? i + 1 : i
          ];

        if (compensationType !== historicalCompensation?.compensationType)
          return <div className={styles.difference}>{isProcessing && processingBlock}</div>;
        const total = compensationRateMonthly + Number(bonus ?? 0);

        const previousTotal =
          historicalCompensation?.compensationRateMonthly + Number(historicalCompensation?.bonus ?? 0);
        const difference = Math.round((total / previousTotal - 1) * 100);
        return (
          <div className={styles.difference}>
            {!!difference && (
              <span>
                {difference > 0 && '+'}
                {difference}%{' '}
              </span>
            )}
            {isProcessing && processingBlock}
          </div>
        );
      },
    },
  };

  const compensationHistoryRow: TableRowFuncType = (compensationHistory, itemNumber) => {
    return (
      <tr
        className={classNames(styles.tableDataRow, {
          [styles.newHistoryItem]: isAddNewCompensationComplete && itemNumber === 0,
          [styles.tableDataRowHoverable]: isDetailsVisible,
        })}
        key={compensationHistory.period}
        onClick={isDetailsVisible ? () => setCompensationForModal(compensationHistory) : undefined}
      >
        {tableHeaders.map(({ title }) => (
          <td key={title}>
            {tableColumns[title as CompensationHistoryTableHeaders].component(compensationHistory, itemNumber)}
          </td>
        ))}
      </tr>
    );
  };
  return compensationHistoryRow;
};
