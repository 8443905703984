import { RootState } from '@modules/App/redux/store';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';

export const userGoalsDataSelector = (state: RootState): AsyncData<UserGoalPageData[]> =>
  state.evaluation.userGoalsPage.userGoalsData;
export const achievedGoalsDataForEvalSelector = (state: RootState): AsyncData<UserGoalPageData[]> =>
  state.evaluation.userGoalsPage.achievedGoalsDataForEval;
export const userGoalEditStateSelector = (state: RootState): DataState =>
  state.evaluation.userGoalsPage.userGoalEditData.state;
export const userGoalEditNetworkErrorSelector = (state: RootState): string | null =>
  state.evaluation.userGoalsPage.userGoalEditData.error;
export const userIdSelector = (state: RootState): string | undefined => state.userPage.userPersonalData.data?.id;
export const userGoalCreationStateSelector = (state: RootState): DataState =>
  state.evaluation.userGoalsPage.userGoalCreationData.state;
export const userGoalCreationNetworkErrorSelector = (state: RootState): string | null =>
  state.evaluation.userGoalsPage.userGoalCreationData.error;
export const userGoalsInProgressDataSelector = (state: RootState): AsyncData<UserGoalPageData[]> => {
  const userGoalsData = state.evaluation.userGoalsPage.userGoalsData;
  const filteredData = userGoalsData.data
    ? userGoalsData.data.filter((goal) => goal.status === GoalsStatus.InProgress)
    : null;

  return { data: filteredData, state: userGoalsData.state };
};
