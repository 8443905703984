import * as Yup from 'yup';

const EMPTY_FIELD_ERROR_TEXT = 'Please select your response';

const fieldsSchema = ['qualityOfWork'].reduce(
  (res, field) => ({
    ...res,
    [field]: Yup.object().shape({
      mark: Yup.string().nullable().required(EMPTY_FIELD_ERROR_TEXT),
      notes: Yup.string().nullable(),
    }),
  }),
  {}
);

export const validationSchema = Yup.object().shape(fieldsSchema);
