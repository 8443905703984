import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    [BREAKPOINTS.MOBILE]: {
      overflowX: 'auto',
      marginBottom: -24,
    },
  },
  usersFlagsTable: {
    minWidth: '1000px',
  },
  tableHeader: {
    fontWeight: '400',
    fontSize: '14px',
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    color: COLORS.TEXT.SECONDARY,

    '& td:nth-child(1)': {
      width: '8%',
      maxWidth: 100,
    },
    '& td:nth-child(2)': {
      width: '20%',
    },
    '& td:nth-child(3)': {
      width: '30%',
    },
    '& td:nth-child(4)': {
      width: '10%',
    },
    '& td:nth-child(5)': {
      width: '10%',
    },
    '& td:nth-child(6)': {
      width: '15%',
    },
  },
  tableDataRow: {
    height: 75,
  },
  titleContainer: {
    padding: '16px 24px',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '24px',
    color: COLORS.TEXT.SECONDARY,
    borderBottom: `1px solid ${COLORS.DIVIDER}`,

    '& span': {
      fontWeight: '500',
      fontSize: '24px',
      color: COLORS.TEXT.PRIMARY,
      marginRight: 16,
    },
  },
  titleSkeleton: {
    width: 150,
    height: 20,
  },
});
