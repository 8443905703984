import { UsersHappinessDataItem } from '@modules/HappinessModule/interfaces/usersHappinessDataItem';
import { HappinessData } from '@modules/HappinessModule/interfaces/happinessData';
import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from 'shared/interfaces/asyncData';

export const getMyTeamHappinessData = createAction<string>('myTeamHappinessPage/getMyTeamHappinessData');
export const setMyTeamHappinessData = createAction<AsyncData<HappinessData<UsersHappinessDataItem>>>(
  'myTeamHappinessPage/setMyTeamHappinessData'
);
export const setMyTeamHappinessDataLoading = createAction<void>('myTeamHappinessPage/setMyTeamHappinessDataLoading');
export const resolveSituation = createAction<{ userId: string; id: string }>('myTeamHappinessPage/resolveSituation');
