import React, { ChangeEvent, FC, memo, useState } from 'react';
import { useStyles } from './StartSurveyDialogContent.styles';
import classnames from 'classnames';
import format from 'date-fns/format';
import { Alert, Stack, Typography } from '@mui/material';

interface Props {
  questionText: string;
  closeFn: () => void;
  startSurvey: () => void;
}
const StartSurveyDialogContentComponent: FC<Props> = ({ closeFn, startSurvey, questionText }) => {
  const styles = useStyles();
  const [isAcknowledged, setIsAcknowledged] = useState(false);
  const dayOfWeek = format(new Date(), 'eeee');
  const checkDate = new Date().getUTCDay() === 2; // Starts sunday on 0

  const onHandleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsAcknowledged(e.target && e.target.checked);
  };

  return (
    <>
      <Typography variant="body1">You are about to start a survey with the following question:</Typography>

      <Typography variant="h2" marginBottom={2}>
        {questionText}
      </Typography>

      <Stack direction="row" className={styles.acceptContainer}>
        <input
          id="acknowledge"
          type="checkbox"
          className={styles.acceptCheckbox}
          onChange={(e) => onHandleInputChange(e)}
        />
        <label htmlFor="acknowledge">
          I&nbsp;understand that when I&nbsp;press “Start&nbsp;survey” an&nbsp;email with this survey will be&nbsp;sent
          to &nbsp;EVERY person at&nbsp;AgileEngine.
        </label>
      </Stack>
      <div className={styles.controlsContainer}>
        <button onClick={startSurvey} disabled={!isAcknowledged} className={styles.btn}>
          Start survey
        </button>
        <button onClick={closeFn} className={classnames(styles.btn, styles.btnCancel)}>
          Cancel
        </button>
      </div>

      {!checkDate && (
        <Alert
          severity="error"
          icon={'⏰'}
          sx={{
            margin: '24px -29px -29px',
            borderRadius: 0,
            padding: '16px 29px 24px',
            lineHeight: '1.4',
          }}
        >
          It’s {dayOfWeek} today, and surveys should be&nbsp;sent on&nbsp;Tuesdays since people tend to&nbsp;respond
          less on&nbsp;the other weekdays which reduces the effectiveness of&nbsp;Rocky.
        </Alert>
      )}
    </>
  );
};

export const StartSurveyDialogContent = memo(StartSurveyDialogContentComponent);
