import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Field, FieldProps, useFormikContext } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import capitalize from 'lodash/capitalize';

import { useStyles } from './AnotherOfferInvolvedBlock.styles';
import { MONEY_SEPARATOR } from '../../constants/constants';
import { FlagFormFields } from '../../constants/FlagFormFields';
import { EmploymentType } from '@shared/enums/EmploymentType';
import { currencies } from '@shared/enums/currencies';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { AmountType } from '@modules/HappinessModule/enums/AmountType';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';

interface Props {
  userFlagPermissions: FlagPermissionsData;
  isNewFlag?: boolean;
}

export const AnotherOfferInvolvedBlock: FC<Props> = ({ userFlagPermissions, isNewFlag }) => {
  const styles = useStyles();
  const { values, setFieldValue } = useFormikContext<FlagFields>();

  const isAnotherOfferSwticherDisabled = isNewFlag
    ? !userFlagPermissions.flag.isCreatable
    : !userFlagPermissions.flag.isUpdatable;

  const isCompensationDetailsDisabled = isNewFlag
    ? !userFlagPermissions.compensationDetails.isCreatable
    : !userFlagPermissions.compensationDetails.isUpdatable;

  const isCompensationDetailsReadable =
    userFlagPermissions.compensationDetails.isReadable || userFlagPermissions.compensationDetails.isCreatable;

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid
        container
        className={isCompensationDetailsReadable ? styles.anotherOfferInvolvedBlockHrView : styles.mainContainer}
        columnSpacing={1}
        rowGap="23px"
      >
        <Grid item xs={4}>
          <Stack rowGap="35px">
            <Field
              name={FlagFormFields.isAnotherOfferInvolved.name}
              type="checkbox"
              component={({ field }: FieldProps) => (
                <FormControlLabel
                  control={<Switch disabled={isAnotherOfferSwticherDisabled} {...field} />}
                  label={<Typography variant="h2">{FlagFormFields.isAnotherOfferInvolved.label}</Typography>}
                />
              )}
            />
            {isCompensationDetailsReadable && values.isAnotherOfferInvolved && (
              <Stack rowGap="16px">
                <Typography variant="subtitle1">Company and employment type</Typography>
                <Field name={FlagFormFields.companyName.name}>
                  {({ field }: FieldProps) => (
                    <TextField
                      label={FlagFormFields.companyName.label}
                      variant="outlined"
                      fullWidth
                      {...field}
                      disabled={isCompensationDetailsDisabled}
                    />
                  )}
                </Field>
                <Field name={FlagFormFields.hiringType.name} as={RadioGroup}>
                  <Stack columnGap="24px" flexDirection="row">
                    {Object.entries(EmploymentType).map(([, value], i) => (
                      <FormControlLabel
                        key={i}
                        control={<Radio />}
                        label={value}
                        value={value}
                        disabled={isCompensationDetailsDisabled}
                      />
                    ))}
                  </Stack>
                </Field>
              </Stack>
            )}
          </Stack>
        </Grid>
        {values.isAnotherOfferInvolved && isCompensationDetailsReadable && (
          <Grid item xs={8}>
            <Stack rowGap="35px">
              <Field
                name={FlagFormFields.isOfferAccepted.name}
                type="checkbox"
                component={({ field }: FieldProps) => (
                  <FormControlLabel
                    control={<Switch {...field} disabled={isCompensationDetailsDisabled} />}
                    label={<Typography variant="h2">{FlagFormFields.isOfferAccepted.label}</Typography>}
                  />
                )}
              />
              <Stack flexDirection="row" columnGap="10px">
                <Stack rowGap="16px">
                  <Typography variant="subtitle1">Compensation</Typography>
                  <Stack flexDirection="row" columnGap="8px">
                    <Field name={FlagFormFields.compensation.name}>
                      {({ field }: FieldProps) => (
                        <NumericFormat
                          disabled={isCompensationDetailsDisabled}
                          thousandSeparator={MONEY_SEPARATOR}
                          min={0}
                          max={100000}
                          allowNegative={false}
                          customInput={TextField}
                          label={FlagFormFields.compensation.label}
                          variant="outlined"
                          fullWidth
                          onValueChange={(values) => {
                            const { floatValue } = values;
                            setFieldValue(FlagFormFields.compensation.name, floatValue);
                          }}
                          value={field.value}
                        />
                      )}
                    </Field>
                    <Field
                      name={FlagFormFields.currencyType.name}
                      component={({ field }: FieldProps) => (
                        <FormControl fullWidth disabled={isCompensationDetailsDisabled}>
                          <InputLabel>{FlagFormFields.currencyType.label}</InputLabel>
                          <Select label={FlagFormFields.currencyType.label} {...field}>
                            {Object.entries(currencies).map(([, value]) => (
                              <MenuItem value={value} key={value}>
                                {value}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Stack>
                  <Field name={FlagFormFields.amountType.name} as={RadioGroup}>
                    <Stack columnGap="24px" flexDirection="row">
                      {Object.entries(AmountType).map(([, value], i) => (
                        <FormControlLabel
                          key={i}
                          disabled={isCompensationDetailsDisabled}
                          control={<Radio />}
                          label={capitalize(value)}
                          value={value.toLowerCase()}
                        />
                      ))}
                    </Stack>
                  </Field>
                </Stack>
                <Stack rowGap="16px" flex={1}>
                  <Typography variant="subtitle1">Benefits and bonuses</Typography>
                  <Field name={FlagFormFields.benefits.name}>
                    {({ field }: FieldProps) => (
                      <TextField
                        disabled={isCompensationDetailsDisabled}
                        multiline
                        rows={2}
                        label={FlagFormFields.benefits.label}
                        variant="outlined"
                        fullWidth
                        {...field}
                      />
                    )}
                  </Field>
                </Stack>
              </Stack>
            </Stack>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
