import { FC } from 'react';
import classNames from 'classnames';
import { HierarchyNode } from 'd3-hierarchy';
import ReactCountryFlag from 'react-country-flag';
import lookup from 'country-code-lookup';
import { Box, Stack } from '@mui/material';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';

import { useStyles } from './CustomNodeContent.styles';
import { roleIconMap } from '../../constants/roleIconMap';
import { RockyTooltip } from '@shared/components/RockyTooltip';
import { RoleMap } from '@shared/consts/RoleMap';
import { isSafari } from '@shared/helpers/userAgent';
import { UserStatus } from '@shared/enums/UserStatus';
import { normalizeCountry } from '@shared/helpers/normalizeCountry';
import { OrgChartData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import { getBackgroundColorByRate } from '@modules/HappinessModule/helpers/getBackgroundColorByRate';
import DoneAllRoundedIcon from '@mui/icons-material/DoneAllRounded';
interface Props {
  node: HierarchyNode<OrgChartData>;
  isDepartmentChart: boolean;
  isShowHappiness: boolean;
  isShowHappinessFromRange: boolean;
}

export const CustomNodeContent: FC<Props> = ({
  node,
  isDepartmentChart,
  isShowHappiness,
  isShowHappinessFromRange,
}) => {
  const styles = useStyles();

  const isShowTotalMembersCount = !isDepartmentChart && !!node.data._totalSubordinates;
  const isShowHappinessRule = isDepartmentChart && isShowHappiness;
  const countryCode = lookup.byCountry(normalizeCountry(node.data.country || ''));
  const currentProject = node.data.projects?.find((p) => p.projectId === node.data.parentId);
  const isHasProjectRoleInCurrentProject =
    currentProject && Object.keys(RoleMap).some((role) => role === currentProject.role);

  const specialPositionIcon = isHasProjectRoleInCurrentProject ? (
    !isSafari ? (
      <RockyTooltip trigger={roleIconMap[currentProject.role]}>{RoleMap[currentProject.role]}</RockyTooltip>
    ) : (
      roleIconMap[currentProject.role]
    )
  ) : null;

  const responseRate =
    node.data.happiness?.childrenRespondedIdsUniqueCount && node.data.teamMembersCount
      ? ((node.data.happiness?.childrenRespondedIdsUniqueCount / node.data.teamMembersCount) * 100).toFixed(0)
      : null;

  return (
    <div
      className={classNames(styles.noderContainer, {
        [styles.departmentNode]: !node.data.position,
        [styles.ghost]: node.data.originalId,
      })}
    >
      <div className={styles.nodeContent}>
        {node.data.position ? (
          <>
            <div className={styles.nodeUserBlock}>
              <img src={node.data.imageUrl} className={styles.userAvatar} alt="user photo"></img>
              <span className={styles.flag}>
                {countryCode && (
                  <ReactCountryFlag
                    className={styles.flagIcon}
                    svg
                    countryCode={countryCode?.internet}
                    style={{
                      width: '1em',
                      height: 'auto',
                    }}
                    aria-label={countryCode.country}
                  />
                )}
              </span>
              <div className={styles.userTextContainer}>
                <div className={styles.userName}>
                  {node.data.name} {specialPositionIcon}
                </div>
                <span className={styles.userPosition}>
                  {isHasProjectRoleInCurrentProject
                    ? !currentProject.isCurrentOrParentProjectTypeClient
                      ? node.data.position
                      : RoleMap[currentProject.role]
                    : node.data.position}
                </span>
              </div>
              {node.data.status === UserStatus.Sabbatical && (
                <RockyTooltip trigger={<WorkHistoryIcon sx={{ marginLeft: 'auto' }} color="disabled" />}>
                  On sabbatical
                </RockyTooltip>
              )}
            </div>
            {(isShowTotalMembersCount || isShowHappinessRule) && (
              <div className={styles.rightBlock}>
                {isShowTotalMembersCount && <div className={styles.teamCountBtn}>{node.data._totalSubordinates}</div>}
                {isShowHappinessRule && (
                  <div
                    className={styles.happiness}
                    style={{
                      backgroundColor:
                        node.data.happiness && node.data.happiness.reaction
                          ? getBackgroundColorByRate(node.data.happiness.reaction)
                          : 'transparent',
                    }}
                  >
                    {node.data.happiness ? (
                      node.data.happiness.reaction ? (
                        node.data.happiness.reaction
                      ) : (
                        <RockyTooltip trigger={<AccessTimeOutlinedIcon />}>Not voted yet</RockyTooltip>
                      )
                    ) : (
                      <RockyTooltip trigger={<VisibilityOffOutlinedIcon />}>No access to view</RockyTooltip>
                    )}
                  </div>
                )}
              </div>
            )}
          </>
        ) : (
          <>
            <div className={styles.departmentAvatar}>
              <GroupsOutlinedIcon />
            </div>
            <div className={styles.nodeInfo}>
              <div className={styles.nodeName}>
                <span className={styles.departmentName}>{node.data.name}</span>
                <div>
                  <span className={styles.teamCount}>{node.data.teamMembersCount} members</span>
                </div>
              </div>
            </div>
            {isShowHappinessRule && !isShowHappinessFromRange && (
              <div className={styles.rightBlock}>
                <Stack direction="row" alignItems="center" columnGap="8px">
                  {responseRate && (
                    <Box color={getBackgroundColorByRate(+responseRate / 10)}>
                      {+responseRate === 100 ? (
                        <RockyTooltip trigger={<DoneAllRoundedIcon color="info" />}>Everyone responded</RockyTooltip>
                      ) : (
                        `${responseRate}%`
                      )}
                    </Box>
                  )}
                  <div
                    className={styles.happiness}
                    style={{
                      backgroundColor:
                        node.data.happiness && node.data.happiness.reaction
                          ? getBackgroundColorByRate(node.data.happiness.reaction)
                          : 'transparent',
                    }}
                  >
                    {node.data.happiness?.reaction?.toFixed(1)}
                  </div>
                </Stack>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
