import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  selectBlock: {
    position: 'relative',
  },
  searchIcon: {
    '& svg': {
      width: '24px',
      height: '24px',
      color: COLORS.INPUTBORDER,
      position: 'absolute',
      left: '19px',
      top: '11px',
      zIndex: 5,
      pointerEvents: 'none',
      boxSizing: 'border-box',
    },
  },
});
