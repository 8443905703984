import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  profileEdit: {
    margin: '32px 24px 0',

    [BREAKPOINTS.MOBILE]: {
      margin: '16px',
    },
  },
  profileEditHeader: {
    width: '100%',
    padding: '20px 24px 0',

    [BREAKPOINTS.MOBILE]: {
      padding: '8px 16px',
      flexWrap: 'wrap',

      '& h2': {
        width: '100%',
      },
    },
  },
  metroAreaRadioBtnsBlock: {
    marginBottom: '16px',
  },
  loaderHolder: {
    display: 'flex',
    justifyContent: 'center',
    height: 400,
    alignItems: 'center',
  },
  closeLink: {
    textDecoration: 'none',
  },
  disabled: {
    pointerEvents: 'none',
  },
  legalAddress: {
    maxWidth: 250,
    overflowWrap: 'anywhere',
  },
});
