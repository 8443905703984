import { ChangesInUserTeamData } from '@shared/interfaces/ChangesInUserTeamData';
import { UserInfo } from '@shared/interfaces/UserInfo';

export const getTableDataWithTeamChanges = <
  T extends {
    primaryReportsTo?: UserInfo;
    secondaryReportsTo?: UserInfo;
    changes?: ChangesInUserTeamData;
    team: string;
  }
>(
  data: T[]
): T[] => {
  let primaryReportsToTemp = data[data?.length - 1].primaryReportsTo;
  let secondaryReportsToTemp = data[data?.length - 1].secondaryReportsTo;
  let teamTemp = data[data?.length - 1].team;
  for (let i = data?.length - 2; i >= 0; i--) {
    const addedPrimaryRT =
      data[i].primaryReportsTo?.id !== primaryReportsToTemp?.id ? data[i].primaryReportsTo : undefined;
    const deletedPrimaryRT =
      data[i].primaryReportsTo?.id !== primaryReportsToTemp?.id ? primaryReportsToTemp : undefined;

    if (data[i].primaryReportsTo?.id !== primaryReportsToTemp?.id) {
      primaryReportsToTemp = data[i].primaryReportsTo;
    }

    const addedSecondaryRT =
      data[i].secondaryReportsTo?.id !== secondaryReportsToTemp?.id ? data[i].secondaryReportsTo : undefined;
    const deletedSecondaryRT =
      data[i].secondaryReportsTo?.id !== secondaryReportsToTemp?.id ? secondaryReportsToTemp : undefined;

    if (data[i].secondaryReportsTo?.id !== secondaryReportsToTemp?.id) {
      secondaryReportsToTemp = data[i].secondaryReportsTo;
    }

    const addedTeam = data[i].team !== teamTemp ? data[i].team : null;
    const deletedTeam = data[i].team !== teamTemp ? teamTemp : null;

    if (data[i].team !== teamTemp) {
      teamTemp = data[i].team;
    }

    if (addedPrimaryRT || deletedPrimaryRT || deletedSecondaryRT || addedSecondaryRT || addedTeam || deletedTeam) {
      data[i].changes = {
        ...data[i].changes,
        addedPrimaryRT,
        deletedPrimaryRT,
        deletedSecondaryRT,
        addedSecondaryRT,
        addedTeam,
        deletedTeam,
      };
    }
  }
  return data;
};
