import React from 'react';
import { useStyles } from './GoalsTableRow.styles';
import classnames from 'classnames';
import { Stack, Tooltip, Typography } from '@mui/material';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import { getGoalStatusData } from '@modules/EvaluationModule/pages/UserGoalsPage/helpers/getGoalStatusData';
import { UserGoalStatusIcon } from '../../../UserGoalStatusIcon/UserGoalStatusIcon';
import { getFormattedDate } from '@modules/EvaluationModule/pages/UserGoalsPage/helpers/getFormattedDate';

interface Props {
  data: UserGoalPageData;
  onClick: (arg0: UserGoalPageData) => void;
}

export const GoalsTableRow: React.FC<Props> = ({ data, onClick }) => {
  const styles = useStyles();
  const { isOverdue, labelColor, percentageOfDone, howLongOverdue } = getGoalStatusData(data);

  return (
    <tr className={classnames(styles.goalItem, styles.goalItemSmall)} onClick={() => onClick(data)}>
      <td>
        <div>
          <Stack direction="row" spacing={1}>
            <UserGoalStatusIcon status={data.status} isOverdue={isOverdue} />
            <Stack>
              <Typography variant="body1" mb={0.5}>
                {data.objective}
              </Typography>
              <Typography variant="body2" className={styles.goalCategory}>
                {data.category}
              </Typography>
            </Stack>
          </Stack>
        </div>
      </td>
      <td className={styles.goalDateDueSmall}>
        {isOverdue && data.status === GoalsStatus.InProgress ? (
          <Tooltip title={`${howLongOverdue} month overdue`} placement="top">
            <Stack direction="row" spacing={0.5}>
              <span>{getFormattedDate(data.dueDate)}</span>
              <AccessAlarmOutlinedIcon fontSize="small" />
            </Stack>
          </Tooltip>
        ) : (
          <span>{getFormattedDate(data.dueDate)}</span>
        )}
      </td>
      {data.actualResult ? (
        <td className={styles.goalCompletenessSmall}>
          <Typography
            variant="body2"
            className={styles.goalCompletenessBadge}
            sx={{
              background: labelColor,
            }}
          >
            {percentageOfDone}%
          </Typography>
        </td>
      ) : (
        <td className={styles.goalCompletenessSmall}>
          <Tooltip title="Pending results" placement="top">
            <AccessTimeOutlinedIcon fontSize="small" />
          </Tooltip>
        </td>
      )}
    </tr>
  );
};
