import { apiUrlNode } from '@configs/environment';
import httpClient from '@shared/api/httpClient';

interface RequestCustomTokenPayload {
  email: string;
}

interface RequestCustomTokenResponse {
  idToken: string;
  refreshToken: string;
  expiresIn: string;
}

export const requestCustomToken = (payload: RequestCustomTokenPayload): Promise<RequestCustomTokenResponse> => {
  const params = new URLSearchParams();
  if (payload.email) params.append('email', payload.email);
  return httpClient
    .get<RequestCustomTokenResponse>(`${apiUrlNode}/auth/custom-login`, { params })
    .then((res) => res.data);
};
