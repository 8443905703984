import { countries as countriesList } from 'countries-list';

import CountryType from '@shared/interfaces/CountryType';

const countries: readonly CountryType[] = Object.entries(countriesList).map(([code, country]) => ({
  code,
  label: country.name,
  phone: `+${country.phone}`,
}));

export default countries;
