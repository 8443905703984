import { RootState } from '@modules/App/redux/store';
import { ClientSuggestionData } from '@modules/EvaluationModule/interfaces/ClientSuggestionData';
import { PeerSuggestions } from '@modules/EvaluationModule/interfaces/PeerSuggestions';
import { AsyncData } from '@shared/interfaces/asyncData';

export const clientsSuggestionsSelector = (state: RootState): AsyncData<ClientSuggestionData[]> =>
  state.evaluation.evalSettingsPage.clientsSuggestions;

export const peerSuggestionsSelector = (state: RootState): AsyncData<PeerSuggestions> =>
  state.evaluation.evalSettingsPage.peerSuggestions;

export const selectEvalPageData = (state: RootState) => state.evaluation.evalPage.evalPageData.data;
