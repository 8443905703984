import React, { FC, memo, useEffect } from 'react';
import { useStyles } from './UserNotRegistered.styles';
import { useNavigate, useLocation } from 'react-router';
import { setCurrentUser } from '@modules/App/redux/user/actions';
import { useDispatch } from 'react-redux';
import { DataState } from '@shared/enums/DataState';
import { useAuth } from '@shared/hooks/useAuth';
import { paths } from '@shared/enums/paths';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { signOut } from 'firebase/auth';
import { firebaseAuth } from '../../../../auth/firebase';
const UserNotRegisteredComponent: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const setPageTitle = useTitle();
  const { state } = useLocation();

  useEffect(() => {
    setPageTitle(pageTitles.unauthorizedPage);
  }, []);

  useEffect(() => {
    if (currentUser) {
      navigate(paths.root);
    }
  }, [currentUser]);

  const signOutUser = () => {
    if (firebaseAuth) {
      localStorage.clear();
      signOut(firebaseAuth);
      dispatch(setCurrentUser({ data: null, state: DataState.Pending }));
    }
  };

  return (
    <div className={styles.root}>
      <p className={styles.heading}>{state?.email ?? 'This email'} is not registered with Rocky</p>
      <p className={styles.subHeading}>Please, Try logging out and selecting another account.</p>
      <button className={styles.signoutButton} onClick={signOutUser}>
        Log out
      </button>
    </div>
  );
};

export const UserNotRegistered = memo(UserNotRegisteredComponent);
