import React, { FC } from 'react';
import { useStyles } from './WhatsFlagReasonBlockPreview.styles';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import capitalize from 'lodash/capitalize';
import { FlagFieldBlockPreview } from '../FlagFieldBlockPreview';
import { yesOrNoByBoolean } from '@shared/helpers/yesOrNoByBoolean';
import { FlagFormFields } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagFormFields';

interface Props {
  flagData: FlagData;
}

export const WhatsFlagReasonBlockPreview: FC<Props> = ({ flagData }) => {
  const styles = useStyles();
  const { user, flagFields } = flagData;

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2} className={styles.flagReasonBlock}>
            <Grid item xs={12} marginBottom="35px">
              <Stack flexDirection="row" columnGap="10px" alignItems="center">
                <RockyAvatar medium imageUrl={user.imageUrl} fullName={user.name}></RockyAvatar>
                <Typography variant="h2">
                  {capitalize(flagFields.severity ?? '')} flag for {user.name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Stack flexDirection="row" columnGap="40px">
                    {[
                      FlagFormFields.primaryTrigger,
                      FlagFormFields.primarySubReason,
                      FlagFormFields.secondaryTrigger,
                      FlagFormFields.secondarySubReason,
                    ].map(({ label, name }) => (
                      <FlagFieldBlockPreview key={name} label={label} value={flagFields[name] ?? 'None'} />
                    ))}
                    <FlagFieldBlockPreview
                      label="Brought by client"
                      value={yesOrNoByBoolean(flagFields.isEscalatedByClient)}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12}>
                  <FlagFieldBlockPreview
                    label={FlagFormFields.description.label}
                    value={flagFields.description ?? 'None'}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FlagFieldBlockPreview
                    label={FlagFormFields.departments.label}
                    value={
                      flagFields.departments?.length
                        ? flagFields.departments?.map((dep) => dep.name).join(', ')
                        : 'None'
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
