import React, { FC } from 'react';
import { useStyles } from './FlagWatcherItem.styles';
import { FlagWatcherData } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { FormControlLabel, Grid, IconButton, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import EditOffOutlinedIcon from '@mui/icons-material/EditOffOutlined';
import EditIcon from '@mui/icons-material/Edit';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { UserInfo } from '@shared/interfaces/UserInfo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { ReactComponent as NoEmailIcon } from '@assets/icons/noEmail.svg';
import AddIcon from '@mui/icons-material/Add';
import { FlagWatcherNotificationStatus } from '@modules/HappinessModule/pages/FlagPage/enums/flagWatcherNotificationStatus';
import { FlagWatcherRole } from '@modules/HappinessModule/pages/FlagPage/enums/flagWatcherRole';
import { AutomaticFlagWatchersGroupsToFlagWatcherRoleMap } from '@modules/HappinessModule/pages/FlagPage/enums/automaticFlagWatchersGroups';

type FlagWatcherDataForComponent = Omit<FlagWatcherData, 'user'> & {
  user?: FlagWatcherData['user'];
};

export interface FlagWatcherItemSharedProps {
  handleDeleteRecipientClick?: (user: UserInfo) => void;
  addWatcherToFlagWatchers?: (watcher: FlagWatcherData) => void;
  toggleRecipients?: (user: UserInfo) => void;
}
interface Props extends FlagWatcherDataForComponent, FlagWatcherItemSharedProps {
  group?: {
    title: string;
    description: string;
    expander?: {
      isExpanded: boolean;
      setGroupExpanded: (isExpanded: boolean) => void;
    };
    isExpandable?: boolean;
  };
  isNewFlag?: boolean;
  isNotified?: boolean;
  isRecipientsDisabled?: boolean;
  isInFlagWatchers?: boolean;
  isInRecipients?: boolean;
}

export const FlagWatcherItem: FC<Props> = ({
  user,
  isAutoCc,
  permissions,
  flagWatcherRole,
  canBeRemoved,
  automaticFlagWatcherGroupName,
  group,
  isNotBeingNotified,
  isNewFlag,
  isNotified,
  isRecipientsDisabled,
  handleDeleteRecipientClick,
  addWatcherToFlagWatchers,
  isInRecipients,
  toggleRecipients,
  isInFlagWatchers,
}) => {
  const styles = useStyles();
  const isReportingTo = flagWatcherRole === FlagWatcherRole.ReportingTo;

  const notificationStatus =
    isReportingTo && !isNotified
      ? isInFlagWatchers
        ? FlagWatcherNotificationStatus.RTIncludedInTheFlag
        : FlagWatcherNotificationStatus.RTExcludedFromTheFlag
      : isNotBeingNotified
      ? FlagWatcherNotificationStatus.NotBeingNotified
      : isRecipientsDisabled
      ? isNewFlag
        ? FlagWatcherNotificationStatus.WillNotBeNotified
        : FlagWatcherNotificationStatus.NotNotified
      : isAutoCc
      ? isNewFlag
        ? FlagWatcherNotificationStatus.AutoCcRequired
        : FlagWatcherNotificationStatus.NotifiedInCC
      : isNotified
      ? FlagWatcherNotificationStatus.Notified
      : canBeRemoved
      ? FlagWatcherNotificationStatus.WillNotBeNotified
      : FlagWatcherNotificationStatus.NotNotified;

  const showMsgForRtThatAlreadyIncludedInAutomaticFlagWatchers = isReportingTo && !!automaticFlagWatcherGroupName;

  return (
    <Grid container className={styles.recipientItem} alignItems="center" key={user?.id ?? group?.title}>
      <Grid item xs={8} paddingRight="24px">
        <Stack flexDirection="row" justifyContent="space-between" alignItems="center">
          {group ? (
            <Stack flexDirection="row" columnGap="12px">
              {group.expander && (
                <IconButton
                  className={styles.expandBtn}
                  onClick={() => group.expander && group.expander.setGroupExpanded(!group.expander.isExpanded)}
                >
                  {!group.expander.isExpanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
                </IconButton>
              )}
              <Stack flexDirection="column" justifyContent="center">
                <Typography variant="body1">{group.title}</Typography>
                <Typography variant="body2">{group.description}</Typography>
              </Stack>
            </Stack>
          ) : (
            user && (
              <>
                <UserReporterBlock user={user} isDetailInfo />
                {flagWatcherRole && (
                  <Typography whiteSpace="nowrap" variant="body2">
                    {flagWatcherRole}
                  </Typography>
                )}
              </>
            )
          )}
        </Stack>
      </Grid>
      <Grid item xs={1}>
        <Stack flexDirection="row" columnGap="13px" alignItems="center">
          <Tooltip placement="top" title={permissions.flag?.isReadable ? 'Can view' : 'Can not view'}>
            {permissions.flag?.isReadable ? (
              <VisibilityIcon color="disabled" />
            ) : (
              <VisibilityOffOutlinedIcon color="disabled" />
            )}
          </Tooltip>
          <Tooltip placement="top" title={permissions.flag?.isUpdatable ? 'Can edit' : 'Can not edit'}>
            {permissions.flag?.isUpdatable ? <EditIcon color="disabled" /> : <EditOffOutlinedIcon color="disabled" />}
          </Tooltip>
        </Stack>
      </Grid>
      <Grid item xs={3}>
        <Stack flexDirection="row" columnGap="4px">
          {isAutoCc ||
          isNotBeingNotified ||
          !isNewFlag ||
          isRecipientsDisabled ||
          (isReportingTo && !isInFlagWatchers) ? (
            <Stack flexDirection="row" columnGap="8px" alignItems="center">
              {isReportingTo && !isNotified ? null : ((isNotBeingNotified || !isNotified) && !isAutoCc) ||
                isRecipientsDisabled ? (
                <NoEmailIcon />
              ) : (
                <EmailOutlinedIcon color="disabled" />
              )}
              <Typography variant="body2">{notificationStatus}</Typography>
            </Stack>
          ) : (
            user &&
            toggleRecipients && (
              <FormControlLabel
                control={<Switch checked={isInRecipients} onChange={() => toggleRecipients(user)} />}
                label={<Typography variant="body1">Include to the thread</Typography>}
              />
            )
          )}

          {canBeRemoved && (isNewFlag || (!isNotified && !isNewFlag)) && user && (
            <>
              {!isInFlagWatchers && isReportingTo && addWatcherToFlagWatchers ? (
                <IconButton
                  onClick={() =>
                    addWatcherToFlagWatchers({
                      user: user,
                      flagWatcherRole: flagWatcherRole,
                      isAutoCc: false,
                      canBeRemoved: true,
                      permissions: permissions,
                    })
                  }
                >
                  <AddIcon color="primary" />
                </IconButton>
              ) : (
                handleDeleteRecipientClick && (
                  <Tooltip
                    placement="top"
                    title={
                      showMsgForRtThatAlreadyIncludedInAutomaticFlagWatchers
                        ? `Cannot exclude RT from the flag because he is a ${AutomaticFlagWatchersGroupsToFlagWatcherRoleMap[automaticFlagWatcherGroupName]} of this person`
                        : null
                    }
                  >
                    <span>
                      <IconButton
                        onClick={() => user && handleDeleteRecipientClick(user)}
                        disabled={showMsgForRtThatAlreadyIncludedInAutomaticFlagWatchers}
                      >
                        <CloseIcon />
                      </IconButton>
                    </span>
                  </Tooltip>
                )
              )}
            </>
          )}
        </Stack>
      </Grid>
    </Grid>
  );
};
