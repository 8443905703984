import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
    minHeight: '100vh',
    paddingTop: 0,
    transition: 'padding-top 0.35s',
  },
  paddedTop: {
    paddingTop: 50,

    [BREAKPOINTS.MOBILE]: {
      paddingTop: 66,
    },
  },
  activeNavButton: {
    width: 64,
    height: 64,
  },
  body: {
    position: 'relative',
    flex: 1,
    padding: '0 40px',
    minHeight: '100vh',

    [BREAKPOINTS.DESKTOP13]: {
      padding: '0 0 0 8px',
    },

    [BREAKPOINTS.MOBILE]: {
      padding: '50px 4px 8px',
    },
  },
  notificationsHolder: {
    position: 'fixed',
    bottom: 0,
    zIndex: 10000,
    left: 0,
    right: 0,
    pointerEvents: 'none',
  },
  alertLink: {
    color: COLORS.WHITE,
    textUnderlineOffset: '2px',
  },
  alertClose: {
    marginTop: -4,
  },
  labelText: {
    backgroundColor: 'rgba(256, 256, 256, 0.2)',
    fontSize: '13px',
    padding: '2px 6px 3px',
    borderRadius: 4,
    display: 'inline-block',
    marginRight: 4,
    transform: 'translateY(-1px)',
  },

  textButton: {
    padding: 0,
    border: 'none',
    color: 'inherit',
    textDecoration: 'underline',
    background: 'inherit',
    cursor: 'pointer',
  },

  labelEmoji: {
    fontSize: '140%',
    margin: '-4px 0 0 4px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
});

export const customAlertStyles = {
  borderRadius: 0,
  boxShadow: '0 -1px 0 0 rgba(255,255,255,0.1)',
  color: COLORS.WHITE,
  display: 'flex',
  alignItems: 'center',
  position: 'relative',

  '& .MuiAlert-action': {
    margin: '-6px 0 0',
  },

  '& .MuiAlert-message': {
    margin: 'auto',
    padding: '11px 0',
  },

  '&:before': {
    content: '""',
    zIndex: -1,
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: 'translate3d(0px, 10px, 0) scale(0.95)',
    filter: 'blur(20px)',
    opacity: '0.6',
    borderRadius: 'inherit',
  },

  '&.actionAlert': {
    background: COLORS.ACTION.PRIMARY,
  },
  '&.actionAlert:before': {
    background: COLORS.ACTION.PRIMARY,
  },
};

export const customAlertBlue = {
  background: COLORS.ACTION.PRIMARY,

  '&:before': {
    background: COLORS.ACTION.PRIMARY,
  },
};
