import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  buttonsBlock: {
    marginRight: 10,
    display: 'flex',
    justifyContent: 'flex-end',
    position: 'relative',
    columnGap: 10,

    [BREAKPOINTS.DESKTOP13]: {
      flexWrap: 'wrap-reverse',
      gap: 4,
    },
  },
  button: {
    borderRadius: '6px 0 0 6px',
    minWidth: 131,
  },
  extendBtn: {
    borderLeft: `1px solid ${COLORS.ACTION.DARK}`,
    borderRadius: '0 6px 6px 0',
    padding: 0,
    width: 24,
    '& svg': {
      height: 20,
    },
  },
  extendList: {
    position: 'absolute',
    top: 32,
    zIndex: 5,
    width: 146,
    backgroundColor: 'white',
    padding: '16px 14px',
    display: 'flex',
    rowGap: 12,
    flexDirection: 'column',
    boxShadow:
      '0px 3px 14px 2px rgba(0, 0, 0, 0.12), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 5px 5px -3px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    cursor: 'auto',

    '& $listButton:hover': {
      backgroundColor: 'transparent',
      color: COLORS.TEXT.PRIMARY,
    },
  },
  listTitle: {
    fontWeight: 400,
    fontSize: 13,
    lineHeight: '24px',
    letterSpacing: '0.17px',
    color: COLORS.TEXT.SECONDARY,
  },
  listButton: {
    padding: 0,
    height: 'auto',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    color: COLORS.TEXT.PRIMARY,
    backgroundColor: 'transparent',

    '&:disabled': {
      color: COLORS.TEXT.DISABLED,
      backgroundColor: 'inherit',

      '&:hover': {
        color: COLORS.TEXT.DISABLED,
      },
    },
  },
});
