import { EditUserGoalData } from '@modules/EvaluationModule/interfaces/EditUserGoalData';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useFormikContext } from 'formik';
import React from 'react';
import { useStyles } from './GoalSelector.styles';

interface Props {
  value: string;
  id: string;
  name: string;
  label: string;
  disabled?: boolean;
  error: boolean;
  options: string[];
}

export const GoalSelector: React.FC<Props> = ({ value, id, name, label, disabled, error, options }) => {
  const styles = useStyles();
  const form = useFormikContext<Required<EditUserGoalData>>();

  return (
    <FormControl fullWidth>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        labelId={id}
        label={label}
        value={value}
        name={name}
        disabled={disabled}
        onChange={form.handleChange}
        error={error}
      >
        {Object.values(options).map((el, i) => (
          <MenuItem value={el} key={i}>
            {el}
          </MenuItem>
        ))}
      </Select>
      {form.errors[name as keyof EditUserGoalData] && (
        <span className={styles.customFieldError}>{form.errors[name as keyof EditUserGoalData]}</span>
      )}
    </FormControl>
  );
};
