import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';

import { State } from './types';

const initialState: State = {
  flagData: { data: null, state: DataState.Pending },
  snapshotData: { data: null, state: DataState.Idle },
  permissions: { data: null, state: DataState.Pending },
};

export const slice = createSlice({
  name: 'flagPage',
  initialState,
  reducers: {
    setFlagData(state, { payload }) {
      state.flagData = payload;
    },
    setFlagDataLoading(state) {
      state.flagData = { data: null, state: DataState.Pending };
    },
    setSnapshotData(state, { payload }) {
      state.snapshotData = payload;
    },
    setSnapshotDataIdle(state) {
      state.snapshotData = { data: null, state: DataState.Idle };
    },
    setUserPermissionToModifyFlag(state, { payload }) {
      state.permissions = payload;
    },
  },
});

export const { setFlagData, setFlagDataLoading, setSnapshotData, setSnapshotDataIdle, setUserPermissionToModifyFlag } =
  slice.actions;

export default slice.reducer;
