import { AsyncData } from '@shared/interfaces/asyncData';
import { NO_CHANGE_THRESHOLD, NO_MARK_THRESHOLD } from '../constants/reportConstants';

interface HappinessData {
  change: number;
  mark: number;
}

export const getAverageHappinessValues = (
  happinessData: AsyncData<Partial<HappinessData>[]>
): { averageChange: number; averageMark: number } => {
  let averageMark = 0;
  if (happinessData.data) {
    const meaningfulData = happinessData.data.filter(({ mark }) => mark !== NO_MARK_THRESHOLD);
    averageMark = meaningfulData.reduce((sum, { mark }) => sum + (mark || 0), 0) / (meaningfulData.length || 1);
  }

  let averageChange = 0;
  if (happinessData.data) {
    const meaningfulData = happinessData.data.filter(({ change }) => change !== NO_CHANGE_THRESHOLD);
    averageChange = meaningfulData.reduce((sum, { change }) => sum + (change || 0), 0) / (meaningfulData.length || 1);
  }

  return { averageChange, averageMark };
};
