import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormikProps, useFormik } from 'formik';
import format from 'date-fns/format';
import * as yup from 'yup';

import { useUserComopensationRange } from './useUserCompensationRange';
import { User } from '@shared/interfaces/user';
import { editCompensation } from '../redux/actions';
import { CompensationRange } from '@modules/EvaluationModule/interfaces/CompensationRange';
import { AsyncData } from '@shared/interfaces/asyncData';
import { EditCompensationFields } from '@modules/EvaluationModule/interfaces/EditCompensationFields';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { useAuth } from '@shared/hooks/useAuth';
import { EditCompensationData } from '@modules/EvaluationModule/interfaces/EditCompensationData';

interface HookDataReturn {
  formik: FormikProps<EditCompensationFields>;
  user: ReturnType<typeof userSelector>;
  userFirstName: string;
  currentUser: User | null;
  userCompensationRange: AsyncData<CompensationRange>;
  period: Date | null;
  setPeriod: (newValue: Date | null) => void;
}

export const useEditCompensationModalData = (): HookDataReturn => {
  const dispatch = useDispatch();
  const [period, setPeriod] = useState<Date | null>(null);
  const user = useSelector(userSelector);
  const userFirstName = user ? user.name.split(' ')[0] : '';
  const { currentUser } = useAuth();
  const userCompensationRange = useUserComopensationRange(user ? user.zohoId : currentUser?.zohoId);

  const validationSchema = yup.object({
    compensationRate: yup
      .number()
      .min(1, "Pay rate shouldn't below or equal zero")
      .max(1000000, 'Pay rate should below 1 million')
      .required('Pay rate is required'),
    reason: yup.string().required('Reason is required'),
  });

  const formik = useFormik({
    initialValues: {
      reason: '',
      comments: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: EditCompensationFields) => {
      if (!period || values.compensationRate === undefined) {
        return;
      }
      if (user) {
        const payload = {
          ...values,
          period: Number(format(new Date(period), 'yyyyMM')),
          id: user.zohoId,
        } as EditCompensationData;
        dispatch(editCompensation(payload));
      }
      if (!user && currentUser) {
        const payload = {
          ...values,
          period: Number(format(new Date(period), 'yyyyMM')),
          id: currentUser.zohoId,
        } as EditCompensationData;
        dispatch(editCompensation(payload));
      }
    },
  });

  return { formik, userFirstName, currentUser, userCompensationRange, period, setPeriod, user };
};
