import React, { useRef, useLayoutEffect, FC, useEffect, useCallback } from 'react';
import { useStyles } from './OrganizationalChart.styles';
import ReactDOMServer from 'react-dom/server';
import { OrgChart } from 'd3-org-chart';
import { CustomNodeContent } from '../CustomNodeContent';
import { OrgChartData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import { useState } from 'react';
import { EmployeeDetailsCard } from '../EmployeeDetailsCard';
import { onNodeUpdate, replaceNodeIdInUrl } from './helpers';
import { selectFilterInfo, selectIsDepartmentChart } from '../../redux/selectors';
import { Toolbar } from '../Toolbar';
import { useDispatch, useSelector } from 'react-redux';
import { HierarchyNode } from 'd3';
import { resetFilters } from '../../redux/actions';
import { InfoView } from '@shared/components/InfoView/InfoView';
import EmptyEval from '@assets/img/EmptyEval.png';
import classNames from 'classnames';
import { DEPARTMENT_ORG_CHART_ROOT_NODE_ID } from '../../constants/constants';
import { HiddenNodes } from '../../interfaces/HiddenNodes';

const NODE_WIDTH = 300;
const NODE_HEIGHT = 64;
const INITIAL_ZOOM_VALUE = 0.75;

interface Props {
  orgChartData: OrgChartData[];
  initiallySelectedNodeId: string | null;
  initialOrgChartData: OrgChartData[];
  hiddenNodes: HiddenNodes;
}

export const OrganizationalChart: FC<Props> = ({
  orgChartData,
  initiallySelectedNodeId,
  initialOrgChartData,
  hiddenNodes,
}) => {
  const styles = useStyles();
  const chart = useRef<OrgChart<OrgChartData>>(new OrgChart());
  const d3Container = useRef<HTMLDivElement>(null);
  const isDepartmentChart = useSelector(selectIsDepartmentChart);
  const canToggleFilters = initialOrgChartData.reduce((res, d) => (d.happiness !== null ? res + 1 : res), 0) > 1;

  const [isInitialNodeIdSet, setIsInitialNodeIdSet] = useState(false);
  const [selectedNode, setSelectedNode] = useState<OrgChartData | undefined>();
  const [isDetailViewOpen, setIsDetailViewOpen] = useState<boolean>(false);
  const [isShowHappiness, setIsShowHappiness] = useState(false);
  const [isShowHappinessFromRange, setIsShowHappinessFromRange] = useState(false);
  const filterInfo = useSelector(selectFilterInfo);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!initialOrgChartData || isInitialNodeIdSet) {
      return;
    }
    const initiallySelectedNode = initialOrgChartData.find((d) => d.id === initiallySelectedNodeId);
    setSelectedNode(initiallySelectedNode);
    setIsInitialNodeIdSet(true);
  }, [initiallySelectedNodeId, initialOrgChartData, isInitialNodeIdSet]);

  useEffect(() => {
    if (!selectedNode) {
      return;
    }
    const isUserNode = !!selectedNode.position;
    setIsDetailViewOpen(isUserNode);
    replaceNodeIdInUrl(selectedNode.id);

    let nodeId: string | null = selectedNode.id;
    const checkIfHiddenNode = hiddenNodes?.find((item) => item.hiddenNode.id === selectedNode.id);

    if (checkIfHiddenNode) {
      nodeId = checkIfHiddenNode.anotherNode?.id ?? null;
    }

    const childrenForSelectedNode = orgChartData.filter((item) => item.parentId === selectedNode.id);
    const rootNodeChildren = orgChartData.find((item) => item.id === DEPARTMENT_ORG_CHART_ROOT_NODE_ID)?.children;

    const foundChildren =
      !isUserNode || !isDepartmentChart
        ? childrenForSelectedNode.length > 0
          ? childrenForSelectedNode
          : rootNodeChildren
        : !nodeId
        ? rootNodeChildren
        : null;

    if (foundChildren && foundChildren.length > 0) {
      const index = Math.floor(foundChildren.length / 2);
      nodeId = foundChildren[index].id;
    }

    if (!nodeId) {
      return;
    }
    chart.current.clearHighlighting();
    chart.current
      .collapseAll()
      .setUpToTheRootHighlighted(isDepartmentChart ? nodeId : selectedNode.id)
      .setExpanded(nodeId)
      .setCentered(nodeId)
      .render();
  }, [selectedNode, chart.current, orgChartData, isDepartmentChart, hiddenNodes]);

  useLayoutEffect(() => {
    if (orgChartData.length > 0 && d3Container.current) {
      chart.current
        .container(d3Container.current as unknown as string)
        .data(orgChartData)
        .nodeWidth(() => NODE_WIDTH)
        .nodeHeight(() => NODE_HEIGHT)
        .nodeContent((node) => {
          return ReactDOMServer.renderToStaticMarkup(
            <CustomNodeContent
              isShowHappiness={isShowHappiness}
              isShowHappinessFromRange={isShowHappinessFromRange}
              isDepartmentChart={isDepartmentChart}
              node={node}
            />
          );
        })
        .onNodeClick((nodeId) => {
          const nodeIdStr = nodeId as unknown as string;
          setSelectedNode(orgChartData.find((item) => item.id === nodeIdStr));
        })
        .nodeUpdate(() => onNodeUpdate(d3Container))
        .initialZoom(INITIAL_ZOOM_VALUE)
        .layout('left')
        .compact(false)
        .render();
    }
  }, [orgChartData]);

  useEffect(() => {
    chart.current
      .nodeContent((node) => {
        return ReactDOMServer.renderToStaticMarkup(
          <CustomNodeContent
            isShowHappiness={isShowHappiness}
            isShowHappinessFromRange={isShowHappinessFromRange}
            isDepartmentChart={isDepartmentChart}
            node={node}
          />
        );
      })
      .render();
  }, [isShowHappiness, isShowHappinessFromRange]);

  const resetFiltersHandler = useCallback(() => {
    dispatch(resetFilters());
    setIsShowHappiness(false);
    setIsShowHappinessFromRange(false);
  }, [isShowHappiness, isShowHappinessFromRange]);

  const onSearchSelect = useCallback(
    (id) => {
      if (!orgChartData.find((d) => d.id === id)) {
        resetFiltersHandler();
      }
      chart.current.clearHighlighting();
      chart.current.collapseAll().setUpToTheRootHighlighted(id).setCentered(id).render();

      setSelectedNode(initialOrgChartData?.find((d) => d.id === id));
    },
    [initialOrgChartData, orgChartData]
  );

  useEffect(() => {
    const allNodes = chart.current.getChartState().allNodes;
    if (
      !allNodes ||
      allNodes.find((item: HierarchyNode<OrgChartData>) => item.data.id === selectedNode?.id) ||
      !filterInfo.length
    ) {
      return;
    }
    const chartNodes = chart.current
      .getChartState()
      .allNodes.filter((item: HierarchyNode<OrgChartData>) => item.data.position);
    const maxDepth = Math.max(...chartNodes.map((item: HierarchyNode<OrgChartData>) => item.depth));
    const nodesWithMaxDepth: HierarchyNode<OrgChartData>[] = chartNodes.filter(
      (item: HierarchyNode<OrgChartData>) => item.depth === maxDepth
    );
    if (nodesWithMaxDepth && nodesWithMaxDepth.length > 0) {
      const index = Math.floor(nodesWithMaxDepth.length / 2);
      setSelectedNode(nodesWithMaxDepth[index].data);
    }
  }, [filterInfo, selectedNode]);

  return (
    <div className={styles.root}>
      <div className={classNames(styles.orgChart, { [styles.hideOrgChart]: !orgChartData.length })} ref={d3Container}>
        {!orgChartData.length && (
          <div className={styles.noDataContainer}>
            <InfoView
              className={styles.noDataContent}
              icon={EmptyEval}
              text={'No people found with these filter settings'}
            />
          </div>
        )}
      </div>
      <div className={styles.absoluteContainter}>
        <Toolbar
          hiddenNodes={hiddenNodes}
          canToggleFilters={canToggleFilters}
          orgChartData={orgChartData}
          setSelectedNode={setSelectedNode}
          isShowHappiness={isShowHappiness}
          toggleShowHappiness={() => setIsShowHappiness((state) => !state)}
          isShowHappinessFromRange={isShowHappinessFromRange}
          toggleShowHappinessFromRange={() => setIsShowHappinessFromRange((state) => !state)}
          resetFiltersHandler={resetFiltersHandler}
          onSearchSelect={onSearchSelect}
          zoomIn={() => chart.current.zoomIn()}
          zoomOut={() => chart.current.zoomOut()}
        />
        {orgChartData.length > 0 && selectedNode && isDetailViewOpen && selectedNode.email && (
          <EmployeeDetailsCard
            setIsDetailViewOpen={setIsDetailViewOpen}
            selectedNode={selectedNode as Required<OrgChartData>}
          />
        )}
      </div>
    </div>
  );
};
