import {
  PersonalPermissions,
  PublicPermissions,
  LegalLocationPermissions,
  UserInfoPermissions,
} from '@shared/enums/UserInfoPermissions';
import { UserInfoPermissionsInterface } from '@modules/App/interfaces/UserInfoPermissions';

export const getUserInfoPermissions = (permissions: UserInfoPermissions[]): UserInfoPermissionsInterface => {
  const externalLinksPermissions: UserInfoPermissionsInterface = {
    personal: {
      isReadable: permissions.includes(PersonalPermissions.Read),
      isUpdatable: permissions.includes(PersonalPermissions.Update),
    },
    public: {
      isUpdatable: permissions.includes(PublicPermissions.Update),
    },
    legalLocation: {
      isReadable: permissions.includes(LegalLocationPermissions.Read),
    },
  };

  return externalLinksPermissions;
};
