import React, { FC, memo, useState } from 'react';
import { useStyles } from './FormExplanationLabel.styles';
import classNames from 'classnames';

interface Props {
  short?: boolean;
}

const FormExplanationLabelComponent: FC<Props> = ({ short }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const styles = useStyles();

  return (
    <div className={classNames(styles.root, { [styles.shortRoot]: short })}>
      <div className={styles.explanationText}>
        <p className={styles.explanationTitle}>Needs development</p>
        <p>
          Team member does not accomplish results expected. Requires continued improvement to consistently meet
          performance expectations.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          Performance may meet certain requirements of the position but does not consistently meet expectations on the
          key duties, responsibilities, requirements and/or objectives.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          The incumbent frequently fails to meet the required level of performance.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          Requires more than the normal amount of guidance, supervision, and follow-up to assure that work assignments
          are completed adequately and on time.
        </p>
      </div>

      <div className={styles.explanationText}>
        <p className={styles.explanationTitle}>Successful</p>
        <p>
          Performance meets the requirements needed to fulfill most of the principal duties, responsibilities,
          objectives and expectations of the position.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          Demonstrates the ability to handle projects or assignments within the scope of the position.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          Performance is steady, reliable, and competent and is usually maintained with a minimum of supervision.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>Quality of work is average to good.</p>
      </div>
      <div className={styles.explanationText}>
        <p className={styles.explanationTitle}>Exceeds</p>
        <p>
          Team member's performance meets, and often exceeds, the principle duties, responsibilities, objectives and
          expectations of the position.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          Demonstrates the ability to work well with members of the team and consistently takes on the role of
          leadership without prompting.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          Consistently performs all aspects of the role and demonstrates aptitude beyond the breadth of the role.
        </p>
        <p className={classNames({ [styles.hidden]: !isExpanded })}>
          Demonstrates the ability to perform at a higher level.
        </p>
      </div>
      <button
        className={classNames(styles.detailsButton, { [styles.hidden]: isExpanded })}
        onClick={() => setIsExpanded(true)}
      >
        Learn more
      </button>
      <button
        className={classNames(styles.detailsButton, { [styles.hidden]: !isExpanded })}
        onClick={() => setIsExpanded(false)}
      >
        Hide
      </button>
    </div>
  );
};

export const FormExplanationLabel = memo(FormExplanationLabelComponent);
