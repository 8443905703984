import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    padding: '10px 20px',
    transition: 'all 0.1s ease-out',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 12,
    alignItems: 'center',
    height: 60,
    borderBottom: `1px solid ${COLORS.ACTION.HIGHLIGHT}`,

    '&:hover': {
      background: COLORS.HIGHLIGHTS.NEUTRAL,
    },
  },
  isFocused: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
  },

  icon: {
    '& svg': {
      width: 21,
      height: 21,
      fill: COLORS.ACTION.PRIMARY,
    },
  },
});
