import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    minHeight: 200,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  heading: {
    fontSize: '2em',
    textAlign: 'center',
    fontWeight: 'bold',

    [BREAKPOINTS.MOBILE]: {
      fontSize: '1.25em',
      marginInline: 2,
      fontWeight: 'normal',
      lineHeight: 1.2,
    },
  },
  subHeading: {
    fontSize: '1.5em',
    textAlign: 'center',
    fontWeight: 'bold',

    [BREAKPOINTS.MOBILE]: {
      fontSize: '1em',
      marginInline: 2,
      fontWeight: 'normal',
      lineHeight: 1.2,
    },
  },
  signoutButton: {
    backgroundColor: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    border: 'none',
    height: 40,
    width: 120,
    fontWeight: '500',
    marginTop: 20,
    cursor: 'pointer',
    borderRadius: 8,
  },
});
