import { Link } from '@mui/material';
import React from 'react';
import { useStyles } from './SmartHint.styles';

interface Props {
  closeGoalHint: () => void;
}

export const SmartHint: React.FC<Props> = ({ closeGoalHint }) => {
  const styles = useStyles();

  return (
    <div className={styles.goalDialogNote}>
      <p className={styles.goalDialogNoteTitle}>
        <span>🦆</span>
        <strong>Set SMART goals</strong>
      </p>
      <p>
        <b>S</b> Specific. A goal should <span className={styles.nobr}>be linked</span>{' '}
        <span className={styles.nobr}>to one</span> activity, thought, <span className={styles.nobr}>or idea</span>.
      </p>
      <p>
        <b>M</b> Measurable. A goal should <span className={styles.nobr}>be something</span> you can track and measure
        progress toward.
      </p>
      <p>
        <b>A</b> Actionable. There should <span className={styles.nobr}>be clear</span> tasks{' '}
        <span className={styles.nobr}>or actions</span> you can take <span className={styles.nobr}></span>
        to make progress toward <span className={styles.nobr}>a goal</span>.
      </p>
      <p>
        <b>R</b> Realistic. A goal should <span className={styles.nobr}>be possible</span>{' '}
        <span className={styles.nobr}>to achieve</span>.
      </p>
      <p>
        <b>T</b> Timely. A goal should fall within <span className={styles.nobr}>a</span> specific time period.
      </p>
      <p className={styles.goalDialogNoteBig}>
        <span>🤯</span> Whoa!
        <br />
        That looks like a big goal!
      </p>
      <Link onClick={closeGoalHint} className={styles.hideHintBtn}>
        Got it!
      </Link>
    </div>
  );
};
