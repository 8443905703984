import { autoSaveEvalForm } from '@modules/EvaluationModule/pages/EvalPage/redux/actions';
import { FormikProps, useFormikContext } from 'formik';
import throttle from 'lodash/throttle';
import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';

const FORM_SAVE_DELAY_MS = 1500;

const FormObserverComponent: FC = () => {
  const { values, dirty }: FormikProps<EvaluationOptionsData> = useFormikContext();
  const dispatch = useDispatch();

  const autoSave = useCallback(
    throttle((values) => dispatch(autoSaveEvalForm(values)), FORM_SAVE_DELAY_MS),
    []
  );

  useEffect(() => {
    if (!values.isConfirmed && dirty) {
      autoSave(values);
    }
  }, [values]);

  return null;
};

export const FormObserver = memo(FormObserverComponent);
