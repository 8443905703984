import React, { FC, memo, useState } from 'react';
import { useStyles } from './QuestionItem.styles';
import { UserRate } from '@modules/HappinessModule/components/UserRate/UserRate';
import { PieChart } from '@modules/HappinessModule/components/PieChart';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import classnames from 'classnames';

interface Props {
  title: string;
  rate: number;
  votedPeople: number;
  totalPeople: number;
}

const QuestionItemComponent: FC<Props> = ({ title, rate, votedPeople, totalPeople }) => {
  rate = rate || 0;
  votedPeople = votedPeople || 0;
  totalPeople = totalPeople || 0;
  const styles = useStyles();
  const [isOpen, setIsOpen] = useState(true);
  const votedPercent = Number((votedPeople / totalPeople).toFixed(2));

  return (
    <div
      className={classnames(styles.questionBackdrop, { [styles.isClose]: !isOpen })}
      onClick={() => setIsOpen(!isOpen)}
    >
      <span className={styles.questionTitle}>{title}</span>
      <div className={styles.userRate}>
        <UserRate reminderCount={0} rate={rate} />
      </div>
      <div className={styles.rateDonut}>
        <PieChart ratio={votedPercent ? votedPercent : 0} />
      </div>
      <div className={styles.responsesCount}>
        {votedPeople}/{totalPeople}
      </div>
      <div className={styles.openCloseBtn}>
        <ExpandMoreIcon className={classnames({ [styles.isClose]: !isOpen })} />
      </div>
      <div className={styles.mobileRate}>{rate.toFixed(2)}</div>
    </div>
  );
};

export const QuestionItem = memo(QuestionItemComponent);
