import { RootState } from '@modules/App/redux/store';
import { ClientSurveyResponseData } from '@modules/HappinessModule/interfaces/ClientSurveyResponseData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { TrackedStateValuesOfClientResponseFieldsToSurvey } from '../interfaces/TrackedStateValuesOfClientResponseFieldsToSurvey';
import { DataState } from '@shared/enums/DataState';

export const selectClientSurveyResponse = (state: RootState): AsyncData<ClientSurveyResponseData> =>
  state.happiness.clientSurveyResponsePage.clientSurveyResponse;
export const selectClientSurveyResponseData = (state: RootState): ClientSurveyResponseData | null =>
  state.happiness.clientSurveyResponsePage.clientSurveyResponse.data;
export const selectWhatIsEditedInClientSurveyResponse = (
  state: RootState
): TrackedStateValuesOfClientResponseFieldsToSurvey =>
  state.happiness.clientSurveyResponsePage.whatIsEditedInClientSurveyResponse;

export const updatingDmNotesStateSelector = (state: RootState): DataState =>
  state.happiness.clientSurveyResponsePage.updatingDmNotesState;
