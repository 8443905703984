import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import {
  getAllFlagsData,
  getAllLocationsData,
  setFlagAuthorsIds,
  setFlagManagersIds,
  setInvolvedUsersIds,
} from './actions';
import {
  setAllFlagsData,
  setAllFlagsDataLoading,
  setAllLocationsData,
  setAllLocationsDataLoading,
  setFlagAuthorsData,
  setFlagManagersData,
  setInvolvedUsersData,
} from './reducers';
import { fetchAllFlagsData } from '@modules/HappinessModule/api/allFlagsData';
import { fetchAllLocationsData } from '@modules/HappinessModule/api/allLocationsData';
import { getAllFlagsDataSelector } from './selectors';
import { AsyncData } from '@shared/interfaces/asyncData';
import { fetchUserProfilePartialData } from '@modules/App/api/userProfileData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { AllFlagsPageData } from '../interfaces/AllFlagsPageData';
import { locationAreaCountryMapping } from '../constants/constants';
import { LOCATION_AREA_ENUM } from '../enums/LocationAreas';

function* getAllFlagsDataAsync({ payload }: ReturnType<typeof getAllFlagsData>) {
  if (payload.offset === 0) {
    const setLoadingAction = setAllFlagsDataLoading();
    yield put(setLoadingAction);
  }
  try {
    const flagListData: AsyncData<AllFlagsPageData> = yield select(getAllFlagsDataSelector);
    const response: AllFlagsPageData = yield call(fetchAllFlagsData, payload);
    const allFlagsDataItems =
      flagListData.data && response ? [...flagListData.data.items, ...response.items] : response.items;
    const setAllFlagsDataAction = setAllFlagsData({
      data: { totalCount: response.totalCount, items: allFlagsDataItems },
      state: DataState.Fulfilled,
    });
    yield put(setAllFlagsDataAction);
  } catch {
    yield put(
      setAllFlagsData({
        data: null,
        state: DataState.Rejected,
      })
    );
  }
}

function* getInvolvedUser({ payload }: ReturnType<typeof setInvolvedUsersIds>) {
  try {
    const response: UserInfo[] = yield all(
      payload.map((id) => {
        return call(fetchUserProfilePartialData, id); //TODO: refactor to fetch user info bulk when we have such endpoint
      })
    );
    yield put(setInvolvedUsersData({ data: response, state: DataState.Fulfilled }));
  } catch (error) {
    yield put(setInvolvedUsersData({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}
function* getFlagAuthors({ payload }: ReturnType<typeof setFlagAuthorsIds>) {
  try {
    const response: UserInfo[] = yield all(
      payload.map((id) => {
        return call(fetchUserProfilePartialData, id); //TODO: refactor to fetch user info bulk when we have such endpoint
      })
    );
    yield put(setFlagAuthorsData({ data: response, state: DataState.Fulfilled }));
  } catch (error) {
    yield put(setFlagAuthorsData({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}
function* getFlagManagers({ payload }: ReturnType<typeof setFlagManagersIds>) {
  try {
    const response: UserInfo[] = yield all(
      payload.map((id) => {
        return call(fetchUserProfilePartialData, id); //TODO: refactor to fetch user info bulk when we have such endpoint
      })
    );
    yield put(setFlagManagersData({ data: response, state: DataState.Fulfilled }));
  } catch (error) {
    yield put(setFlagManagersData({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}

function findLocationArea(country: string) {
  for (const [area, countries] of Object.entries(locationAreaCountryMapping)) {
    if (countries.includes(country)) {
      return area;
    }
  }

  return LOCATION_AREA_ENUM.EUROPE;
}

function* getAllLocationsDataAsync() {
  const setLoadingAction = setAllLocationsDataLoading();
  yield put(setLoadingAction);
  try {
    const response: string[] = yield call(fetchAllLocationsData);
    const formatCountryWithGroups = response.map((c) => ({
      name: c,
      value: c,
      isLocationArea: false,
      locationArea: findLocationArea(c),
    }));

    // Area filters
    Object.values(LOCATION_AREA_ENUM).forEach((area) =>
      formatCountryWithGroups.push({ name: area, value: area, isLocationArea: true, locationArea: area })
    );

    const action = setAllLocationsData({ data: formatCountryWithGroups, state: DataState.Fulfilled });
    yield put(action);
  } catch {
    yield put(
      setAllLocationsData({
        data: null,
        state: DataState.Rejected,
      })
    );
  }
}

export function* allFlagsPageSaga(): Generator {
  yield all([
    takeLatest(getAllFlagsData.type, getAllFlagsDataAsync),
    takeLatest(getAllLocationsData.type, getAllLocationsDataAsync),
    takeLatest(setInvolvedUsersIds.type, getInvolvedUser),
    takeLatest(setFlagAuthorsIds.type, getFlagAuthors),
    takeLatest(setFlagManagersIds.type, getFlagManagers),
  ]);
}
