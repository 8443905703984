import { ExternalLinksTypes } from '../enums/ExternalLinksTypes';

export const ExternalLinksTypesToNamesMap = {
  [ExternalLinksTypes.oneToOne]: {
    propertyName: 'oneToOneNotesUrl',
    buttonTitle: 'Meeting notes',
    title: 'Meeting notes',
    placeholder: 'Spreadsheet with 1-on-1 meeting notes',
    infoText: 'Meeting notes link',
  },
  [ExternalLinksTypes.pdp]: {
    propertyName: 'pdpUrl',
    buttonTitle: 'PDP document',
    title: 'Professional development plan',
    placeholder: 'Document with PDP',
    infoText: 'PDP document link',
  },
  [ExternalLinksTypes.evaluation]: {
    propertyName: 'nonRockyEvaluationNotesUrl',
    buttonTitle: 'Previous evaluations',
    title: 'Previous evaluations outside of Rocky',
    placeholder: 'Spreadsheet with previous evaluations',
    infoText: 'Previous evaluations link',
  },
  [ExternalLinksTypes.engineeringManagerNotes]: {
    propertyName: 'engineeringMeetingsNotes',
    buttonTitle: "Engineering manager's meeting notes",
    title: "Engineering manager's meeting notes",
    placeholder: "Spreadsheet with engineering manager's meeting notes",
    infoText: "Engineering manager's meeting notes link",
  },
  [ExternalLinksTypes.cv]: {
    propertyName: 'resume',
    buttonTitle: 'AgileEngine resume',
    title: 'AgileEngine resume',
    placeholder: 'Doc with AgileEngine resume',
    infoText: 'AgileEngine resume link',
  },
  [ExternalLinksTypes.pip]: {
    propertyName: 'pipUrl',
    buttonTitle: 'Linked PIP',
    title: 'PIP for this flag',
    placeholder: 'Link to spreadsheet or document',
    infoText: 'PIP link',
  },
  [ExternalLinksTypes.peerInterviewResultsLink]: {
    propertyName: 'peerUrl',
    buttonTitle: 'Linked peer interview result',
    title: 'Peer interview result',
    placeholder: 'Link to spreadsheet or document',
    infoText: 'Peer interview result link',
  },
};
