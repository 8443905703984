import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  assignedTomSelector: {
    '& .MuiInputBase-root': {
      paddingTop: '10px',
      paddingBottom: '12px',
    },
  },
});
