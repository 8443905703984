import { paths } from '@shared/enums/paths';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getFlagDataAction, getUserPermissionToModifyFlagAction } from '../redux/actions';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { setFlagDataLoading } from '../redux/reducers';
import { FlagSnapshotData } from '@modules/HappinessModule/interfaces/FlagSnapshotData';
import { flagDataSelector, snapshotDataSelector, userFlagPermissionsSelector } from '../redux/selectors';
import { FlagPermissionsData } from '../../../interfaces/FlagPermissionsData';

export const useFlagData = (): {
  flagData: AsyncData<FlagData>;
  snapshotData: AsyncData<FlagSnapshotData>;
  userFlagPermissions: AsyncData<FlagPermissionsData>;
} => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { flagId } = useParams();
  const flagData = useSelector(flagDataSelector);
  const snapshotData = useSelector(snapshotDataSelector);
  const userFlagPermissions = useSelector(userFlagPermissionsSelector);

  useEffect(() => {
    if (!flagId) {
      navigate(paths.root);
    } else {
      dispatch(getUserPermissionToModifyFlagAction(flagId));
      dispatch(getFlagDataAction(flagId));
    }
    return () => {
      dispatch(setFlagDataLoading());
    };
  }, [flagId]);

  return { flagData, snapshotData, userFlagPermissions };
};
