import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    marginBottom: 12,
  },
  headerContainer: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    display: 'flex',
    alignItems: 'center',
    minHeight: 60,
    padding: '0 24px',
    gap: 16,
  },
  categoryNameHolder: {
    flexGrow: 1,
    marginLeft: -8,
  },
  categoryName: {
    fontWeight: '500',
    fontSize: '14px',
  },
  dragHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      color: COLORS.TEXT.PRIMARY,
    },
  },
  addQuestionContainer: {
    background: COLORS.WHITE,
    display: 'flex',
    alignItems: 'center',
    padding: '12px 24px 16px',
    gap: 16,
    borderTop: `1px solid ${COLORS.DIVIDER}`,
  },
  addQuestionButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      color: COLORS.ACTION.DARK,
    },
  },
  addQuestionButtonText: {
    fontSize: '14px',
    fontWeight: '500',
  },
});
