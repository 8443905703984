import { UserProfileData } from '@shared/interfaces/user';
import { UserInfo } from '@shared/interfaces/UserInfo';
import httpNodeClient from '@shared/api/httpNodeClient';
import { LegalCountry } from '@shared/interfaces/LegalCountry';
import { UserAddressFormValuesType } from '../pages/UserProfilePage/componenes/EditUserAddressComponent/constants/userAddressFormFields';
import { UserAddressData } from '@shared/interfaces/UserAddressData';
import { UserStatus } from '@shared/enums/UserStatus';
import { DepartmentsIds } from '@shared/enums/departmentsId';
import { DeliveryAddress } from '@shared/interfaces/DeliveryAddress';
import { emailDomain } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/CcInfo';

export const getSearchResults = (params: { text: string; statuses?: UserStatus[] }): Promise<UserInfo[]> => {
  return httpNodeClient
    .get<{ data: UserInfo[] }>('/users/search', { params } as Record<string, unknown>)
    .then((resp) => resp.data.data);
};

export const getLegalCountryOfUser = (userId: string): Promise<LegalCountry> =>
  httpNodeClient.get<LegalCountry>(`/users/${userId}/legal-country`).then((resp) => resp.data);

export const getUsersWhomICanCreateFlag = (): Promise<UserInfo[]> =>
  httpNodeClient.get<UserInfo[]>('/users/search/flaggable').then((resp) => resp.data);

export const getToms = (): Promise<UserInfo[]> =>
  httpNodeClient.get<UserInfo[]>('/users/toms').then((resp) => resp.data);

export const getPrs = (): Promise<UserInfo[]> =>
  httpNodeClient
    .get<UserInfo[]>('/departments/members', {
      params: { ids: [DepartmentsIds.Pr, DepartmentsIds.PeopleOperations], isRecursive: true },
    })
    .then((resp) => resp.data);

export const patchUserPhysicalAddressData = (
  userId: string,
  data: UserAddressFormValuesType
): Promise<UserAddressFormValuesType | null> =>
  httpNodeClient.patch<UserProfileData>(`/users/${userId}/profile/physical-address`, data).then((resp) =>
    resp.data.physicalAddress
      ? {
          ...(resp.data.physicalAddress as Required<UserAddressData>),
          selectedMetroArea: resp.data.selectedMetroArea,
          deliveryAddress: resp.data.deliveryAddress,
        }
      : null
  );

export const patchUserDeliveryAddressData = (userId: string, data: DeliveryAddress): Promise<UserProfileData> =>
  httpNodeClient.patch<UserProfileData>(`/users/${userId}/profile/delivery-address`, data).then((resp) => resp.data);

export const fetchUserIdFromEmail = async (emailUsername: string | undefined): Promise<{ id: string }> => {
  if (!emailUsername) return { id: '' };

  const data = await httpNodeClient.get<{ id: string }>(`/users/${emailUsername}${emailDomain}/find`);

  return data.data;
};
