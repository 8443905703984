import { COLORS } from '@styles/colors';

export const sliderCustomStyles = {
  height: 8,
  padding: 0,
  '&.Mui-disabled': {
    opacity: 0.4,
  },
  '& .MuiSlider-rail': {
    opacity: 1,
    background: `linear-gradient(90deg, ${COLORS.SCALE10.VOTE1} 0%, ${COLORS.SCALE10.VOTE2} 12%, ${COLORS.SCALE10.VOTE3} 24%, ${COLORS.SCALE10.VOTE4} 36%, ${COLORS.SCALE10.VOTE5} 48%, ${COLORS.SCALE10.VOTE6} 60%, ${COLORS.SCALE10.VOTE7} 72%, ${COLORS.SCALE10.VOTE8} 84%, ${COLORS.SCALE10.VOTE9} 96%, ${COLORS.SCALE10.VOTE10} 100%)`,
  },
  '& .MuiSlider-track': {
    border: 'none',
    background: 'transparent',
  },
  '& .MuiSlider-thumb': {
    height: 21,
    width: 21,
    backgroundColor: COLORS.ACTION.PRIMARY,
    border: `5px solid ${COLORS.WHITE}`,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',

    '&:hover': {
      boxShadow: '0px 0px 0px 8px rgb(25 118 210 / 16%)',
    },

    '&.Mui-disabled': {
      display: 'none',
    },
  },
  '& .MuiSlider-mark': {
    backgroundColor: COLORS.WHITE,
  },
};
