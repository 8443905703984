import { EvalPermissions, PeerEvalPermissions } from '@modules/EvaluationModule/interfaces/EvalPermissions';
import { EvaluationPermissions } from '@modules/EvaluationModule/enums/evalPermissions';
import {
  PeerFeedbackPermissions,
  PeerFeedbackStatusPermissions,
  PeerFeedbackInformationPermissions,
} from '@modules/EvaluationModule/pages/PeerEvalPage/enums/peerFeedbackPermissions';

export default function getEvaluationPermissions(permissions: EvaluationPermissions[]): EvalPermissions {
  return {
    evaluation: {
      isReadable: permissions.includes(EvaluationPermissions.Read),
      isUpdatable: permissions.includes(EvaluationPermissions.Update),
    },
  };
}

export function getPeerFeedbackPermissions(permissions: PeerFeedbackPermissions[]): PeerEvalPermissions {
  return {
    peerFeedback: {
      status: {
        isReadable: permissions.includes(PeerFeedbackStatusPermissions.Read),
        isUpdatable: permissions.includes(PeerFeedbackStatusPermissions.Update),
      },
      information: {
        isReadable: permissions.includes(PeerFeedbackInformationPermissions.Read),
        isUpdatable: permissions.includes(PeerFeedbackInformationPermissions.Update),
      },
    },
  };
}
