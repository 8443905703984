import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';
import { getUserPersonalData } from '../redux/actions';
import { LocationData } from '@shared/interfaces/LocationData';
import { userDataSelector } from '../redux/selectors';
import { setUserProfileDataLoading } from '../../UserProfilePage/redux';
import { setUserPersonalDataLoading } from '../redux';

export const useUserData = (
  userId?: string
): {
  userId?: string;
  userData: ReturnType<typeof userDataSelector>;
  fromLocation: LocationData | null;
} => {
  const [fromLocation, setFromLocation] = useState(null);
  const dispatch = useDispatch();
  const { id } = useParams();
  const location = useLocation();
  const userData = useSelector(userDataSelector);
  const innerUserId = userId ?? id;

  useEffect(() => {
    setFromLocation(location?.state);
  }, []);

  useEffect(() => {
    if (!innerUserId) {
      return;
    }

    dispatch(getUserPersonalData(innerUserId));

    return () => {
      dispatch(setUserPersonalDataLoading());
      dispatch(setUserProfileDataLoading());
    };
  }, [innerUserId]);

  return {
    userId: innerUserId,
    userData,
    fromLocation,
  };
};
