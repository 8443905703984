import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './ChartSearch.styles';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Select from 'react-select';
import { ChartSearchOption } from '../ChartSearch/components/ChartSearchOption';
import { searchChartCustomStyles } from '../../styles/searchChartCustomStyles';
import { useSearchFunctions } from '@shared/helpers/useSearchFunctions';
import { DataState } from '@shared/enums/DataState';
import { loadSearchData, resetSearchResult } from '@modules/App/pages/OrgChartPage/redux/actions';
import { selectOrgChartSearchData } from '@modules/App/pages/OrgChartPage/redux/selectors';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { HiddenNodes } from '../../interfaces/HiddenNodes';

interface Props {
  onSelect: (selectedNodeId: string) => void;
  hiddenNodes: HiddenNodes;
}

export const ChartSearch: FC<Props> = ({ onSelect, hiddenNodes }) => {
  const styles = useStyles();
  const { inputValue, handleInputChange } = useSearchFunctions(loadSearchData, resetSearchResult);
  const searchData = useSelector(selectOrgChartSearchData);
  const options =
    searchData.data?.filter(
      (option) =>
        hiddenNodes?.some((node) => node.hiddenNode.id === option.id && node.anotherNode) ||
        !hiddenNodes?.some((node) => node.hiddenNode.id === option.id)
    ) || [];

  return (
    <div className={styles.root} onClick={(e) => e.stopPropagation()}>
      <div className={styles.selectBlock}>
        <div className={styles.searchIcon}>
          <SearchOutlinedIcon />
        </div>
        <Select
          onChange={(value) => onSelect((value as UserInfo).id ?? '')}
          menuPlacement="top"
          styles={searchChartCustomStyles}
          value={[]}
          autoFocus={true}
          placeholder="Search"
          components={{ Option: ChartSearchOption }}
          inputValue={inputValue}
          onInputChange={handleInputChange}
          noOptionsMessage={() => (inputValue === '' ? 'Start typing to search' : 'No results')}
          options={options}
          filterOption={null}
          isLoading={searchData.state === DataState.Pending}
        />
      </div>
    </div>
  );
};
