import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { paths } from '@shared/enums/paths';

export const useMyTeamData = (): void => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === paths.myTeam) {
      navigate(`${paths.myTeam}/${paths.responses}`);
    }
  }, []);
};
