import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    minHeight: '100vh',

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column-reverse',
    },
  },
  loaderHolder: {
    width: '100%',
    display: 'flex',
    paddingTop: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  sidebar: {
    position: 'relative',
    width: 256,
    borderRight: `1px solid ${COLORS.DIVIDER}`,

    [BREAKPOINTS.MOBILE]: {
      border: 'none',
      width: '100%',
    },
  },
  sidebarContent: {
    padding: '50px 27px',

    [BREAKPOINTS.MOBILE]: {
      padding: '32px 16px 40px 20px',
      background: COLORS.WHITE,
      marginTop: 12,
    },
  },
  body: {
    width: '100%',
    backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
    padding: 20,

    [BREAKPOINTS.MOBILE]: {
      padding: 4,
    },
  },
  clietnForm: {
    margin: '0 auto',
    width: 656,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: '35px 56px 28px 60px',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      padding: '16px 16px 24px',
    },
  },
  logoContainer: {
    display: 'flex',
    position: 'relative',
  },
  logo: {
    fill: COLORS.BRAND.PRIMARY,
    width: 41,
    height: 41,
    marginRight: 13,
  },
  logoWord: {
    fill: COLORS.TEXT.PRIMARY,
    width: 125,
    height: 25,
  },
  logoLabel: {
    position: 'absolute',
    top: 27,
    left: 54,
    fontWeight: '400',
    fontSize: 11,
    whiteSpace: 'nowrap',
    color: COLORS.TEXT.SECONDARY,
  },
  participantsContainer: {
    marginTop: 32,

    [BREAKPOINTS.MOBILE]: {
      marginTop: 24,
      borderTop: `1px solid ${COLORS.DIVIDER}`,
      paddingTop: 24,
    },
  },
  participantsTitle: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    marginBottom: 7,
  },
  participantItem: {
    display: 'flex',
    marginBottom: 25,
  },
  participantName: {
    margin: 0,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
  },
  participantPosition: {
    margin: 0,
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },
  tooltipsContainer: {
    position: 'absolute',
    bottom: 0,

    [BREAKPOINTS.MOBILE]: {
      position: 'static',
    },
  },
  notificationContainer: {
    position: 'absolute',
    width: 400,
    bottom: 0,
    left: 'calc(50% - 200px)',
    pointerEvents: 'none',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      left: 0,
    },
  },
  modalHeaderStyles: {
    border: 'none',
  },
  modalBodyStyles: {
    width: 504,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
    },
  },
  modalTitle: {
    fontWeight: '400',
    fontSize: 18,
    margin: '35px 0 9px 0',
  },
  modalText: {
    fontWeight: '400',
    fontSize: '14px',
    margin: '0 0 75px 0',
    textAlign: 'center',
  },
});
