import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  mainInfo: {
    marginTop: 0,
    fontWeight: '500',
  },
  additionalInfo: {
    marginBottom: 0,
  },
});
