import { FC, memo, useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { SurveyQuestion } from '../../interfaces';
import { useStyles } from './QuestionCard.styles';
import ReorderIcon from '@mui/icons-material/Reorder';
import classNames from 'classnames';
import { DeleteAction } from '../DeleteAction';
import { useDispatch } from 'react-redux';
import { EditableLabel } from '../EditableLabel';
import { deleteQuestion, setQuestionText } from '../../redux/actions';

interface Props {
  question: SurveyQuestion;
  isFirstInQueue: boolean;
  expiredDayCount: number;
  startDate?: string;
  canBeDeleted: boolean;
  order: number;
}

const QuestionCardComponent: FC<Props> = ({
  question,
  isFirstInQueue,
  startDate,
  canBeDeleted,
  order,
  expiredDayCount,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const handleDeleteClick = useCallback(() => dispatch(deleteQuestion(question.id)), [dispatch]);
  const handleQuestionNameSubmit = useCallback(
    (value) => {
      dispatch(
        setQuestionText({
          questionId: question.id,
          text: value,
        })
      );
    },
    [dispatch]
  );

  return (
    <Draggable draggableId={question.id} index={order}>
      {(provided) => (
        <div
          className={classNames(styles.root, {
            [styles.nextQuestionRoot]: isFirstInQueue,
            [styles.expiredSurvey]: startDate && expiredDayCount > 0 && isFirstInQueue,
          })}
          ref={provided.innerRef}
          {...provided.draggableProps}
        >
          <div {...provided.dragHandleProps} className={styles.dragHadlerContainer}>
            <ReorderIcon color="disabled" />
          </div>
          <div className={styles.cardTextContainer}>
            <p className={styles.nextQuestionLabel}>Next question in queue</p>
            <div className={styles.questionNameHolder}>
              <EditableLabel
                onSubmit={handleQuestionNameSubmit}
                value={question.text}
                className={styles.questionName}
              ></EditableLabel>
            </div>
          </div>
          {canBeDeleted && (
            <div className={styles.deleteActionHolder}>
              <DeleteAction onDelete={handleDeleteClick}></DeleteAction>
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export const QuestionCard = memo(QuestionCardComponent);
