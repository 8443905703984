import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setClientEvalData } from './actions';

import { State } from './types';

const initialState: State = {
  clientEvalData: { data: null, state: DataState.Pending },
  postingFeedbackState: DataState.Idle,
};

export const slice = createSlice({
  name: 'clientEvalPage',
  initialState,
  reducers: {
    setClientEvalDataLoading(state) {
      state.clientEvalData = { data: null, state: DataState.Pending };
      state.postingFeedbackState = DataState.Idle;
    },
    setPostingClientFeedback(state, action: PayloadAction<DataState>) {
      state.postingFeedbackState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setClientEvalData, (state, { payload }) => {
      state.clientEvalData = payload;
    });
  },
});

export const { setClientEvalDataLoading, setPostingClientFeedback } = slice.actions;

export default slice.reducer;
