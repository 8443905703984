import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  dialogRoot: {
    width: 412,
  },
  title: {
    paddingBottom: 16,
    marginBottom: 24,
  },
  subTitle: {
    color: COLORS.TEXT.SECONDARY,
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: COLORS.HIGHLIGHTS.ACTIONABLE,
    borderRadius: 4,
    height: 32,
    padding: '6px 12px',
    textDecoration: 'none',
    cursor: 'pointer',
    fontSize: 13,
    fontWeight: 500,

    '&:hover': {
      color: COLORS.WHITE,
      backgroundColor: COLORS.ACTION.PRIMARY,
    },
  },
});
