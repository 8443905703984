import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  reportsBlock: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: '-20px 0 30px',
  },
  teamLabel: {
    fontWeight: '500',
  },
  reportsBlockItem: {
    fontSize: '14px',
    fontWeight: '400',
    display: 'flex',
    alignSelf: 'flex-end',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  insteadBlock: {
    fontSize: '14px',
    fontWeight: '400',
    alignSelf: 'center',
  },
  reportsBlockItemUser: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 7,
  },
  userNameLabel: {
    fontSize: '14px',
    fontWeight: '400',
    whiteSpace: 'nowrap',
    margin: 0,

    [BREAKPOINTS.MOBILE]: {
      fontSize: '14px',
    },
  },
  positionLabel: {
    alignSelf: 'center',
    fontSize: '14px',
    fontWeight: '400',
    margin: '0',
  },
});
