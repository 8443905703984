import { QuestionData } from '@modules/HappinessModule/interfaces/questionData';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UsersHappinessTableDataItem } from '../../../interfaces/usersHappinessTableDataItem';
import { getMyTeamHappinessData } from '../redux/actions';
import {
  getSortedTableData,
  myTeamHappinessQuestionDataSelector,
  sortMyTeamHappinessPageInfoSelector,
} from '../redux/selectors';
import { getAverageHappinessValues } from '@modules/HappinessModule/helpers/averageHappinessValues';
import { useLocation } from 'react-router-dom';
import { useResponseByQuestion } from '@modules/HappinessModule/hooks/useResponseByQuestion';

export const useMyTeamHappinessData = (): {
  usersHappinessData: AsyncData<UsersHappinessTableDataItem[]>;
  sortInfo: SortInfo;
  questionData: QuestionData | undefined;
  averageMark: number;
  averageChange: number;
  fromLocation?: string;
} => {
  const usersHappinessData = useSelector(getSortedTableData);
  const questionData = useSelector(myTeamHappinessQuestionDataSelector);
  const sortInfo = useSelector(sortMyTeamHappinessPageInfoSelector);
  const location = useLocation();
  const [fromLocation, setFromLocation] = useState(undefined);
  useResponseByQuestion(getMyTeamHappinessData);

  useEffect(() => {
    setFromLocation(location?.state);
  }, [location]);

  const { averageMark, averageChange } = getAverageHappinessValues(usersHappinessData);

  return { usersHappinessData, sortInfo, questionData, averageMark, averageChange, fromLocation };
};
