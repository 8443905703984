import React, { FC } from 'react';
import { useStyles } from './ClientSurveysTable.styles';
import { AsyncData } from '@shared/interfaces/asyncData';
import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { ClientSurveysTableHeaders } from '../../enum/ClientSurveysTableHeaders';
import { DataState } from '@shared/enums/DataState';
import Skeleton from 'react-loading-skeleton';
import { Table } from '@shared/components/Table';
import { ClientSurveyStatusComponent } from '@modules/HappinessModule/pages/ClientSurveysPage/copmonents/ClientSurveyStatus';
import { Typography } from '@mui/material';
import classNames from 'classnames';
import { format, parseISO } from 'date-fns';
import { SEND_DATE_FORMAT } from '../../constants/constants';

interface Props {
  clientSurveys: AsyncData<ClientSurveyData[]>;
  isReadOnlyMode: boolean;
  openModal: (newUpsertSurvey: ClientSurveyData | 'NEW') => void;
}

export const ClientSurveysTable: FC<Props> = ({ clientSurveys, isReadOnlyMode, openModal }) => {
  const styles = useStyles();
  const clientSurveysData = clientSurveys.data?.map((survey) => ({
    ...survey,
    status: survey.status,
  }));
  const clientSurveysList = [...(clientSurveysData || [])].filter((x): x is ClientSurveyData => !!x);
  const isClientSurveysLoading = clientSurveys.state === DataState.Pending;

  const tableHeaders = [
    { title: ClientSurveysTableHeaders.Questions },
    { title: ClientSurveysTableHeaders.Category },
    { title: ClientSurveysTableHeaders.SentBy },
    { title: ClientSurveysTableHeaders.SendDate },
    { title: ClientSurveysTableHeaders.Progress },
  ];

  const tableColumns = {
    [ClientSurveysTableHeaders.Questions]: {
      component: (clientSurveyData: ClientSurveyData) =>
        clientSurveyData && (
          <div className={classNames(styles.label)}>
            <span>{clientSurveyData.text}</span>
          </div>
        ),
      skeleton: <Skeleton className={styles.skeleton} />,
    },
    [ClientSurveysTableHeaders.Category]: {
      component: (clientSurveyData: ClientSurveyData) => (
        <Typography variant="body2">{clientSurveyData.category}</Typography>
      ),
      skeleton: <Skeleton className={styles.skeleton} />,
    },
    [ClientSurveysTableHeaders.SentBy]: {
      component: ({ confirmedBy }: ClientSurveyData) =>
        confirmedBy?.name ? <div className={styles.highlightedText}>{confirmedBy.name}</div> : null,
      skeleton: <Skeleton className={styles.skeleton} />,
    },
    [ClientSurveysTableHeaders.SendDate]: {
      component: (clientSurveyData: ClientSurveyData) => (
        <div className={styles.highlightedText}>{format(parseISO(clientSurveyData.date), SEND_DATE_FORMAT)}</div>
      ),
      skeleton: <Skeleton className={styles.skeleton} />,
    },
    [ClientSurveysTableHeaders.Progress]: {
      component: (clientSurveyData: ClientSurveyData) => (
        <ClientSurveyStatusComponent
          status={clientSurveyData.status}
          date={clientSurveyData.date}
          id={clientSurveyData.id}
          disabled={isReadOnlyMode}
          onEditClick={() => openModal(clientSurveyData)}
        />
      ),
      skeleton: <Skeleton className={styles.skeleton} />,
    },
  };

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr className={styles.tableDataRow} key={i}>
        {tableHeaders.map(({ title }, i) => (
          <td key={i}>{tableColumns[title].skeleton}</td>
        ))}
      </tr>
    ));

  const clientSurveyRow = (clientSurvey: ClientSurveyData) => {
    return (
      <tr className={styles.tableDataRow} key={clientSurvey.id}>
        {tableHeaders.map(({ title }) => (
          <td key={title}>{tableColumns[title].component(clientSurvey)}</td>
        ))}
      </tr>
    );
  };

  return clientSurveysList?.length || isClientSurveysLoading ? (
    <Table
      customStyles={{
        tableHeaderRow: styles.tableHeaderRow,
      }}
      isDataLoading={isClientSurveysLoading}
      tableHeaders={tableHeaders}
      skeleton={tableSkeleton}
    >
      {clientSurveysList?.map(clientSurveyRow)}
    </Table>
  ) : null;
};
