import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  header: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    padding: '16px 24px',
    display: 'flex',
    alignItems: 'center',
    columnGap: 16,

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      padding: 16,
    },
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 auto 0 0',
  },

  headerTitle: {
    [BREAKPOINTS.MOBILE]: {
      marginTop: 8,
    },
  },
  date: {
    whiteSpace: 'nowrap',
  },
});
