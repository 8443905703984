import React from 'react';
import { useStyles } from './UserFlagsPage.styles';
import { useUsersFlagsData } from './hooks/useUserFlagsData';
import { DataState } from '@shared/enums/DataState';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { FlagsTableHeader } from '@modules/HappinessModule/enums/FlagsTableHeader';
import { FlagsTable } from '@modules/HappinessModule/components/FlagsTable';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { DataErrorView } from '@shared/components/DataErrorView';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { useSelector } from 'react-redux';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { useAuth } from '@shared/hooks/useAuth';
import { useUserData } from '@modules/App/pages/UserPage/hooks/useUserData';

export const UserFlagsPage: React.FC = () => {
  const styles = useStyles();
  const usersFlagsData = useUsersFlagsData();
  const { currentUser } = useAuth();
  const { userData } = useUserData();

  const userPermissionData = useSelector(userPermissionsSelector);

  const isPrimaryReporter: boolean =
    !!userData.data?.primaryReportsTo?.id && userData.data?.primaryReportsTo.id === currentUser?.id;
  const isAllowed = userPermissionData.data?.flagPermissions.flag.isReadable || isPrimaryReporter;
  const userEnabledFeatures = useUserEnabledFeatures();
  const isFlagsEnabled = userEnabledFeatures.isFlagsEnabled;
  const data = usersFlagsData.data;
  const isDataLoading = usersFlagsData.state === DataState.Pending || userPermissionData.state === DataState.Pending;
  const isFailed = usersFlagsData.state === DataState.Rejected || userPermissionData.state === DataState.Rejected;

  const tableHeaders = [
    { title: FlagsTableHeader.FlagDetails },
    { title: FlagsTableHeader.FlagAuthor },
    { title: FlagsTableHeader.Escalated },
    { title: FlagsTableHeader.Resolved },
    { title: FlagsTableHeader.Status },
    { title: FlagsTableHeader.Actions },
  ];

  return isFailed ? (
    <DataErrorView inComponent error={usersFlagsData.error} />
  ) : !isDataLoading && (!isFlagsEnabled || !isAllowed) ? (
    <AccessDeniedView inComponent />
  ) : (
    <div className={styles.root}>
      <div className={styles.titleContainer}>
        {isDataLoading ? (
          <RockySkeleton className={styles.titleSkeleton} isLoading={isDataLoading} element={<div></div>} />
        ) : (
          <div>
            <span>Flags</span> {data?.length} flags
          </div>
        )}
      </div>
      <FlagsTable
        isDataLoading={isDataLoading}
        tableHeaderClassName={styles.tableHeader}
        tableDataRowClassName={styles.tableDataRow}
        data={{ ...usersFlagsData, data }}
        tableHeaders={tableHeaders}
        tableRootClassName={styles.usersFlagsTable}
      />
    </div>
  );
};
