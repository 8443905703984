import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    margin: 0,
    paddingLeft: 24,
    fontWeight: '400',
    lineHeight: '130%',
    color: COLORS.TEXT.PRIMARY,

    '& li:not(:last-child)': {
      marginBottom: 15,
    },
  },
});
