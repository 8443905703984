import { put, all, call, takeEvery, select } from 'redux-saga/effects';
import {
  updateUserPromotion,
  loadRolesSenioritiesMap,
  loadUserPromotions,
  createPromotion,
  setPromotionInStateAfterCreate,
} from './actions';
import {
  getRolesSenioritiesMap,
  getUserPromotions,
  patchUserPromotions,
  postPromotion,
} from '@modules/EvaluationModule/api/userPromotionsApi';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { setAddLinkState, setUpdatePromotionState, setRolesSenioritiesMap, setUserPromotionsData } from './reducers';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { selectUserPromotionsData } from './selectors';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { StreamsRolesSenioritiesMapData } from '@modules/EvaluationModule/interfaces/StreamsRolesSenioritiesMapData';

function* loadUserPromotionsAsync({ payload }: ReturnType<typeof loadUserPromotions>) {
  try {
    yield put(
      setUserPromotionsData({
        data: null,
        state: DataState.Pending,
      })
    );
    const userPromotions: UserPromotionsData[] = yield call(getUserPromotions, payload);
    yield put(
      setUserPromotionsData({
        data: userPromotions,
        state: DataState.Fulfilled,
      })
    );
  } catch (e) {
    yield put(
      setUserPromotionsData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* loadRolesSenioritiesMapAsync() {
  try {
    yield put(
      setRolesSenioritiesMap({
        data: null,
        state: DataState.Pending,
      })
    );
    const rolesSenioritiesMap: StreamsRolesSenioritiesMapData[] = yield call(getRolesSenioritiesMap);
    yield put(
      setRolesSenioritiesMap({
        data: rolesSenioritiesMap,
        state: DataState.Fulfilled,
      })
    );
  } catch (e) {
    yield put(
      setRolesSenioritiesMap({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* updateUserPromotionAsync({ payload }: ReturnType<typeof updateUserPromotion>) {
  try {
    const { payload: updatePromotionPayload } = payload;
    yield put(setAddLinkState(DataState.Pending));
    yield put(setUpdatePromotionState(DataState.Pending));
    const updatedPromotion: UserPromotionsData = yield call(patchUserPromotions, payload);
    const userPromotionsData: ReturnType<typeof selectUserPromotionsData> = yield select(selectUserPromotionsData);
    const data = userPromotionsData
      ? userPromotionsData.map((promotion) => (promotion.id === payload.id ? updatedPromotion : promotion))
      : [];
    yield put(
      setUserPromotionsData({
        data: data,
        state: DataState.Fulfilled,
      })
    );
    yield put(setAddLinkState(DataState.Fulfilled));
    yield put(setUpdatePromotionState(DataState.Fulfilled));

    yield put(
      displayNotification(
        (updatePromotionPayload.pdpLink || updatePromotionPayload.peerInterviewResultsLink) &&
          Object.keys(updatePromotionPayload).length === 1
          ? 'Link successfully added 🎉'
          : 'Change request sent, thanks!'
      )
    );
  } catch {
    yield put(setAddLinkState(DataState.Rejected));
    yield put(setUpdatePromotionState(DataState.Rejected));
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* createPromotionAsync({ payload }: ReturnType<typeof createPromotion>) {
  try {
    yield put(setUpdatePromotionState(DataState.Pending));
    const newPromotion: UserPromotionsData = yield call(postPromotion, payload);
    const userPromotionsData: ReturnType<typeof selectUserPromotionsData> = yield select(selectUserPromotionsData);
    const data = userPromotionsData ? [...userPromotionsData, newPromotion] : [];
    yield put(setPromotionInStateAfterCreate(newPromotion));
    yield put(
      setUserPromotionsData({
        data: data,
        state: DataState.Fulfilled,
      })
    );
    yield put(setUpdatePromotionState(DataState.Fulfilled));
    yield put(displayNotification('Change request sent, thanks!'));
  } catch (e) {
    yield put(setUpdatePromotionState(DataState.Rejected));
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* watchUserPromotionsPageData() {
  yield takeEvery(loadUserPromotions.type, loadUserPromotionsAsync);
  yield takeEvery(updateUserPromotion.type, updateUserPromotionAsync);
  yield takeEvery(loadRolesSenioritiesMap.type, loadRolesSenioritiesMapAsync);
  yield takeEvery(createPromotion.type, createPromotionAsync);
}

export function* userPromotionsPageSaga(): Generator {
  yield all([watchUserPromotionsPageData()]);
}
