import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';

import { State } from './types';

const initialState: State = {
  userCompensation: { data: null, state: DataState.Pending },
  userCompensationRange: { data: null, state: DataState.Pending },
  editCompensationDataState: DataState.Idle,
  isAddNewCompensationComplete: false,
};

export const slice = createSlice({
  name: 'userCompensationPage',
  initialState,
  reducers: {
    setUserCompensationDataLoading(state) {
      state.userCompensation = { data: null, state: DataState.Pending };
    },
    setUserCompensationData(state, { payload }) {
      state.userCompensation = payload;
    },
    setUserCompensationRangeDataLoading(state) {
      state.userCompensationRange = { data: null, state: DataState.Pending };
    },
    setUserCompensationRangeData(state, { payload }) {
      state.userCompensationRange = payload;
    },
    setEditCompensationDataState(state, { payload }) {
      state.editCompensationDataState = payload;
    },
    setIsAddNewCompensationComplete(state, { payload }) {
      state.isAddNewCompensationComplete = payload;
    },
  },
});

export const {
  setUserCompensationData,
  setUserCompensationDataLoading,
  setUserCompensationRangeDataLoading,
  setUserCompensationRangeData,
  setEditCompensationDataState,
  setIsAddNewCompensationComplete,
} = slice.actions;

export default slice.reducer;
