import { FormikProps, useFormikContext } from 'formik';
import throttle from 'lodash/throttle';
import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { savePeeEvalData } from '@modules/EvaluationModule/pages/PeerEvalPage/redux/actions';
import { PeerFormValues } from '@modules/EvaluationModule/pages/PeerEvalPage/interfaces/PeerFormValues';

const FORM_SAVE_DELAY_MS = 1500;
interface Props {
  evalId: string;
  feedbackId: string;
}

const PeerFormObserverComponent: FC<Props> = ({ evalId, feedbackId }) => {
  const { values, dirty }: FormikProps<PeerFormValues> = useFormikContext();
  const dispatch = useDispatch();

  const autoSave = useCallback(
    throttle((values) => {
      const { isAnonymous, ...evaluation } = values;
      dispatch(savePeeEvalData({ evalId, feedbackId, evaluation, isAnonymous }));
    }, FORM_SAVE_DELAY_MS),
    []
  );

  useEffect(() => {
    if (!values.isConfirmed && dirty) {
      autoSave(values);
    }
  }, [values]);

  return null;
};

export const PeerFormObserver = memo(PeerFormObserverComponent);
