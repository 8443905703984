import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.ACTION.PRIMARY,
    textDecoration: 'none',

    '& svg': {
      width: '16px',
    },

    '&:hover': {
      color: COLORS.BRAND.PRIMARY,
    },
  },
});
