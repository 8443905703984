import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
    paddingTop: 38,
    margin: '0 auto',
    height: '100%',
  },
});
