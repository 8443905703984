import { FlagSeverity } from '@modules/HappinessModule/enums/FlagSeverity';
import React, { FC } from 'react';
import { useStyles } from './FlagSeverityComponent.styles';
import { ReactComponent as RedFlagIcon } from '@assets/icons/redFlagIcon.svg';
import { ReactComponent as YellowFlagIcon } from '@assets/icons/yellowFlagIcon.svg';
import { Typography } from '@mui/material';

interface Props {
  severity: FlagSeverity;
  isHelperTextShown?: boolean;
}

export const FlagSeverityComponent: FC<Props> = ({ severity, isHelperTextShown }) => {
  const styles = useStyles();

  return (
    <div className={styles.severityContainer}>
      {severity === FlagSeverity.red ? (
        <>
          <RedFlagIcon /> Red flag&nbsp;
          {isHelperTextShown && <Typography variant="body2">- High risk of attrition</Typography>}
        </>
      ) : (
        <>
          <YellowFlagIcon /> Yellow flag&nbsp;
          {isHelperTextShown && <Typography variant="body2">- Medium or low risk of attrition</Typography>}
        </>
      )}
    </div>
  );
};
