import { FormikProps, useFormikContext } from 'formik';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FLAG_FORM_ID } from '../../constants/constants';
import { useDispatch } from 'react-redux';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { FlagFormFields } from '../../constants/FlagFormFields';
import { CC_RECIPIENTS } from '../../constants/CcInfo';
import { FlagSeverity } from '@modules/HappinessModule/enums/FlagSeverity';
import { flagReasonsOptions } from '../../constants/flagsReasonsObject';
import { FlagReason, FlagTriggerType } from '@modules/HappinessModule/enums/FlagsReasons';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';

const Y_OFFSET = 100;

interface Props {
  formRef: React.RefObject<FormikProps<FlagFields>>;
  isNewFlag: boolean;
  flagFor: UserInfo;
  flagAuthor: UserInfo;
}

const FormObserverComponent: FC<Props> = ({ formRef, isNewFlag, flagFor }) => {
  const { errors, submitCount, values, initialValues }: FormikProps<FlagFields> = useFormikContext();
  const [previousValues, setPreviousValues] = useState(initialValues);
  const formElement = document.getElementById(FLAG_FORM_ID);
  const { primaryTrigger, secondaryTrigger } = values;

  const dispatch = useDispatch();

  const validate = useCallback(() => {
    Object.entries(errors).some(([key, value]) => {
      const field = formElement?.querySelector(`[name="${key}"]`);
      if (!field) return;
      const scrollPosition = field.getBoundingClientRect().top + window.pageYOffset - Y_OFFSET;
      window.scrollTo({ top: scrollPosition, behavior: 'smooth' });
      dispatch(displayNotification(value as string));
      return true;
    });
  }, [errors]);

  useEffect(() => {
    validate();
  }, [submitCount]);

  useEffect(() => {
    if (previousValues.primaryTrigger !== primaryTrigger) {
      formRef.current?.setFieldValue(FlagFormFields.primarySubReason.name, null);
    }
  }, [primaryTrigger, previousValues.primaryTrigger]);

  useEffect(() => {
    if (previousValues.secondaryTrigger !== secondaryTrigger) {
      formRef.current?.setFieldValue(FlagFormFields.secondarySubReason.name, null);
    }
  }, [secondaryTrigger, previousValues.secondaryTrigger]);

  useEffect(() => {
    if (
      initialValues.isNewHomeNeeded === false &&
      (values?.isNewHomeNeeded === previousValues.isNewHomeNeeded || !isNewFlag)
    ) {
      return;
    }

    if (values?.isNewHomeNeeded) {
      const emailRecipients = [CC_RECIPIENTS.tom, ...values.emailRecipients];
      formRef.current?.setFieldValue(FlagFormFields.emailRecipients.name, emailRecipients);
    } else {
      formRef.current?.setFieldValue(
        FlagFormFields.emailRecipients.name,
        values.emailRecipients.filter((cc) => cc.userEmail !== CC_RECIPIENTS.tom.userEmail)
      );
    }
  }, [initialValues.isNewHomeNeeded, values.isNewHomeNeeded, previousValues.isNewHomeNeeded, isNewFlag]);

  useEffect(() => {
    setPreviousValues(values);
  }, [values]);

  useEffect(() => {
    if (values.severity === FlagSeverity.yellow && values.status === FlagStatus.Negative) {
      formRef.current?.setFieldValue(FlagFormFields.status.name, FlagStatus.InProgress);
    }
  }, [values.severity, values.status]);

  const getTriggerTypeByTrigger = (trigger: string) => {
    return flagReasonsOptions.involuntary.options.some((option) => option.value === trigger)
      ? FlagTriggerType.Involuntary
      : FlagTriggerType.Voluntary;
  };

  useEffect(() => {
    if (!values.primaryTrigger) {
      return;
    }
    const triggerType = getTriggerTypeByTrigger(values.primaryTrigger);
    formRef.current?.setFieldValue(FlagFormFields.primaryTriggerType.name, triggerType);
  }, [values.primaryTrigger]);

  useEffect(() => {
    if (!values.secondaryTrigger || values.secondaryTrigger === FlagReason.None) {
      formRef.current?.setFieldValue(FlagFormFields.secondaryTriggerType.name, null);
      return;
    }
    const triggerType = getTriggerTypeByTrigger(values.secondaryTrigger);
    formRef.current?.setFieldValue(FlagFormFields.secondaryTriggerType.name, triggerType);
  }, [values.secondaryTrigger]);

  useEffect(() => {
    if (values.emailRecipients.some((recipient) => recipient.userEmail === flagFor?.email)) {
      formRef.current?.setFieldValue(
        FlagFormFields.emailRecipients.name,
        values.emailRecipients.filter((recipient) => recipient.userEmail !== flagFor?.email)
      );
    }
  }, [values.emailRecipients]);

  return null;
};

export const FlagFormObserver = memo(FormObserverComponent);
