import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setUsersFlagsData } from './actions';

import { State } from './types';

const initialState: State = {
  usersFlagsData: { data: null, state: DataState.Pending },
};

export const slice = createSlice({
  name: 'usersFlags',
  initialState,
  reducers: {
    setUsersFlagsDataLoading(state) {
      state.usersFlagsData = { data: null, state: DataState.Pending };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUsersFlagsData, (state, action) => {
      state.usersFlagsData = action.payload;
    });
  },
});

export const { setUsersFlagsDataLoading } = slice.actions;

export default slice.reducer;
