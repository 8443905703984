import { OrgChartData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import { Role } from '@shared/enums/role';
import { FilterInfoOrgChart } from '@modules/App/pages/OrgChartPage/interfaces/FilterInfoOrgChart';
import { cloneDeep } from 'lodash';
import { OrgChartTree } from '../interfaces/OrgChartTreeNode';
import get from 'lodash/get';
import Range from '@shared/interfaces/Range';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { sortData } from '@shared/redux/helpers/sortData';
import { HappinessReaction } from '../interfaces/HappinessReaction';
import { OrgChartFilter } from '../enums/Filters';
import {
  NO_REPORT_TO_ID,
  DEPARTMENT_ORG_CHART_ROOT_NODE_ID,
  PEOPLE_ORG_CHART_ROOT_NODE_POSITION,
} from '../constants/constants';
import { ProjectRole } from '@shared/enums/projectRole';

const getOrgChartDataWithOneRoot = ({
  rootNodeId,
  orgChartData,
}: {
  rootNodeId: string;
  orgChartData: OrgChartData[];
}): OrgChartData[] => {
  const existingIds = new Set(orgChartData.map((obj) => obj.id));
  const nodesIdsThatLostTheirParent = orgChartData
    .filter((obj) => obj.parentId && !existingIds.has(obj.parentId))
    .map((obj) => obj.id);

  let correctNewOrgChartData = orgChartData;
  if (nodesIdsThatLostTheirParent.length > 0) {
    correctNewOrgChartData = orgChartData.some((node) => node.id === NO_REPORT_TO_ID)
      ? orgChartData.map((node) =>
          nodesIdsThatLostTheirParent.some((id) => id === node.id) ? { ...node, parentId: NO_REPORT_TO_ID } : node
        )
      : orgChartData.map((node) =>
          nodesIdsThatLostTheirParent.some((id) => id === node.id) ? { ...node, parentId: rootNodeId } : node
        );
  }

  const orgChartDataWithOneRoot = correctNewOrgChartData.map((item) =>
    item.parentId === null && rootNodeId !== item.id
      ? {
          ...item,
          parentId: rootNodeId,
        }
      : item
  );
  return orgChartDataWithOneRoot;
};

export const createOrgChartTree = (orgChartData: OrgChartData[]): OrgChartTree => {
  return orgChartData.reduce((tree: OrgChartTree, d) => {
    if (!tree[d.id]) {
      tree[d.id] = {
        children: [],
        teamMembersCount: 0,
        subDepartmentsCount: 0,
        isTeamMember: d.position !== null,
        originalId: d.originalId,
        happiness: d.position ? d.happiness : null,
        id: d.id,
        roles: d.roles,
      };
    }
    if (tree[d.id].isTeamMember === undefined) {
      tree[d.id].isTeamMember = d.position !== null;
    }
    if (d.parentId) {
      if (!tree[d.parentId]) {
        tree[d.parentId] = {
          children: [],
          teamMembersCount: 0,
          subDepartmentsCount: 0,
          happiness: null,
          id: d.id,
          originalId: d.originalId,
          roles: orgChartData.find(({ id }) => id === d.parentId)?.roles || [],
        };
      }
      tree[d.parentId].children.push(d);
    }
    return tree;
  }, {});
};

const countTotalMembersAndSubDepartments = ({
  nodeId,
  tree,
  rootNodeId,
  parentRoles,
}: {
  nodeId: string;
  tree: OrgChartTree;
  rootNodeId?: string;
  parentRoles?: ProjectRole[];
}): {
  uniqueTeamMembersIds: Set<string>;

  directChildDepartmentsCount: number;
  childHappiness?: HappinessReaction | null;
} => {
  const currentNode = tree[nodeId];
  let currentUniqueTeamMembersIds = new Set<string>();

  let departmentsCount = 0;
  let sumHappiness = currentNode.happiness?.reaction || 0;
  let childrenRespondedIds: string[] = [];
  let childWithHappinessCount = 0;

  if (parentRoles) currentNode.roles = [...(currentNode.roles ?? []), ...parentRoles];

  if (currentNode.children.length > 0) {
    currentNode.children.forEach(({ id, originalId }) => {
      const { uniqueTeamMembersIds, directChildDepartmentsCount, childHappiness } = countTotalMembersAndSubDepartments({
        nodeId: id,
        tree,
        parentRoles: currentNode.roles,
      });

      currentUniqueTeamMembersIds = new Set([...currentUniqueTeamMembersIds, ...uniqueTeamMembersIds]);
      departmentsCount += directChildDepartmentsCount;

      if (childHappiness?.sumHappiness && rootNodeId !== nodeId) {
        sumHappiness += childHappiness.sumHappiness;
        childWithHappinessCount += childHappiness.childWithHappinessCount || 1;

        childrenRespondedIds = childHappiness.childrenRespondedIds?.length
          ? [...childrenRespondedIds, ...childHappiness.childrenRespondedIds]
          : [...childrenRespondedIds, originalId ?? id];
      }
    });
  }

  currentNode.subDepartmentsCount = departmentsCount;
  currentNode.teamMembersCount = currentUniqueTeamMembersIds.size;

  const childrenRespondedIdsUniqueCount = new Set(childrenRespondedIds).size;
  currentNode.happiness = sumHappiness
    ? {
        reaction: childWithHappinessCount ? sumHappiness / childWithHappinessCount : sumHappiness,
        sumHappiness: sumHappiness,
        childrenRespondedIds: childrenRespondedIds ?? [],
        childrenRespondedIdsUniqueCount,
        childWithHappinessCount: childWithHappinessCount || 0,
      }
    : currentNode.happiness;

  const isPerson = currentNode.isTeamMember;

  return {
    uniqueTeamMembersIds: isPerson
      ? new Set([...currentUniqueTeamMembersIds, currentNode.originalId ? currentNode.originalId : currentNode.id])
      : new Set([...currentUniqueTeamMembersIds]),
    directChildDepartmentsCount: isPerson ? departmentsCount : departmentsCount + 1,
    childHappiness: currentNode.happiness,
  };
};

export const addCountAggregationsToOrgChartTree = (rootNodeId: string, tree: OrgChartTree): OrgChartTree => {
  const treeCopy = cloneDeep(tree);
  countTotalMembersAndSubDepartments({ nodeId: rootNodeId, tree: treeCopy, rootNodeId });
  return treeCopy;
};

export const getOrgChartTreeData = (
  rawOrgChartData: OrgChartData[] | null
): { chartData: OrgChartData[]; rootNodeId: string } => {
  const noData = { chartData: [], rootNodeId: '' };
  if (!rawOrgChartData || !rawOrgChartData.length) {
    return noData;
  }
  const rootNode =
    rawOrgChartData.find((item) => item.id === DEPARTMENT_ORG_CHART_ROOT_NODE_ID) ??
    rawOrgChartData.find((item) => item.position === PEOPLE_ORG_CHART_ROOT_NODE_POSITION);
  const rootNodeId = rootNode?.id ?? '';
  const orgChartDataWithOneRoot = getOrgChartDataWithOneRoot({
    orgChartData: rawOrgChartData,
    rootNodeId,
  });
  const orgChartTreeWithoutAggregations = createOrgChartTree(orgChartDataWithOneRoot);
  const fullOrgChartTree = addCountAggregationsToOrgChartTree(rootNodeId, orgChartTreeWithoutAggregations);
  let chartData = orgChartDataWithOneRoot.map((d) => ({
    ...d,
    teamMembersCount: fullOrgChartTree[d.id].teamMembersCount,
    subDepartmentsCount: fullOrgChartTree[d.id].subDepartmentsCount,
  }));

  chartData = chartData.filter(
    (d) => d.position !== null || (d.teamMembersCount !== undefined && d.teamMembersCount >= 0)
  );

  // We rebuild the tree without 0 team members departments because it takes less time than filtering the existing tree
  const orgChartTreeClean = createOrgChartTree(chartData);
  const orgChartTree = addCountAggregationsToOrgChartTree(rootNodeId, orgChartTreeClean);
  chartData = chartData.map((d) => ({
    ...d,
    teamMembersCount: orgChartTree[d.id].teamMembersCount,
    subDepartmentsCount: orgChartTree[d.id].subDepartmentsCount,
    children: orgChartTree[d.id].children,
    happiness: orgChartTree[d.id].happiness,
    roles: orgChartTree[d.id].roles,
  }));
  return { chartData, rootNodeId };
};

const checkIfValueInRange = (value: number, range: Range) => {
  return range.from <= value && value <= range.to;
};

export const filterOrgChartTreeData = (chartData: OrgChartData[], filterInfo: FilterInfoOrgChart[]): OrgChartData[] => {
  if (filterInfo.length <= 0) {
    return chartData;
  }
  const chartDataWithoutGhostUsers = chartData.filter((item) => !item.originalId);
  const filteredChartData = chartDataWithoutGhostUsers.filter((item) =>
    filterInfo.every((filter) => {
      const fieldValue = get(item, filter.field);
      const isConditionSatisfied =
        filter.value &&
        filter.field === OrgChartFilter.HappinessReaction &&
        Object.prototype.hasOwnProperty.call(filter.value, 'from')
          ? fieldValue && checkIfValueInRange(fieldValue as number, filter.value as Range)
          : filter.field === OrgChartFilter.Roles
          ? (fieldValue as Role[]).some((role) => (filter.value as Role[]).includes(role))
          : filter.field === OrgChartFilter.Happiness
          ? filter.reverse && fieldValue !== filter.value
          : fieldValue === filter.value;

      return isConditionSatisfied;
    })
  );
  filteredChartData.forEach((item) => {
    if (!filteredChartData.some((itemIn) => itemIn.id === item.id)) {
      filteredChartData.push(item);
    }

    while (item.parentId) {
      const parent = chartData.find((d) => d.id === item.parentId);
      if (parent && !filteredChartData.some((itemIn) => itemIn.id === parent.id)) {
        filteredChartData.push(parent);
        item = parent;
      } else {
        return;
      }
    }
  });
  const { chartData: filteredChartDataTree } = getOrgChartTreeData(filteredChartData);
  return filteredChartDataTree;
};

export const applyFiltersFunc = (chartData: OrgChartData[], filters: FilterInfoOrgChart[]): OrgChartData[] =>
  filterOrgChartTreeData(chartData, filters);

export const getRoles = (chartData: OrgChartData[] | null): ProjectRole[] => {
  if (!chartData) return [];
  const roles = chartData.reduce((acc, item) => {
    if (item.roles) {
      item.roles.forEach((role) => {
        if (!acc.includes(role)) {
          acc.push(role);
        }
      });
    }
    return acc;
  }, [] as ProjectRole[]);
  return roles;
};

export const sortOrgChartData = (chartData: OrgChartData[], sortInfo: SortInfo): OrgChartData[] => {
  const sortedChartData = [
    ...sortData(
      chartData.filter((item) => item.position),
      sortInfo
    ),
    ...sortData(
      chartData.filter((item) => !item.position),
      sortInfo
    ),
  ];
  return sortedChartData;
};

export const getOrgChartDataWithoutHiddenElements = (arr: OrgChartData[], toDelete: string[]): OrgChartData[] => {
  let orgChartDataWithoutHiddenElements = arr.filter((item) => !toDelete.includes(item.id));
  toDelete.forEach((item) => {
    orgChartDataWithoutHiddenElements = removeElementAndDependencies(orgChartDataWithoutHiddenElements, item);
  });
  return orgChartDataWithoutHiddenElements;
};

const removeElementAndDependencies = (arr: OrgChartData[], parentIdToDelete: string): OrgChartData[] => {
  const filteredArray: OrgChartData[] = [];
  for (const obj of arr) {
    if (obj.id === parentIdToDelete || isDescendant(obj, parentIdToDelete, arr)) {
      continue;
    }
    filteredArray.push(obj);
  }
  return filteredArray;
};

const isDescendant = (obj: OrgChartData, parentIdToDelete: string, arr: OrgChartData[]): boolean => {
  if (obj.parentId === parentIdToDelete) {
    return true;
  }
  const parentObj = arr.find((item) => item.id === obj.parentId);
  if (!parentObj) {
    return false;
  }
  return isDescendant(parentObj, parentIdToDelete, arr);
};

export const getTheSameNodeButForAnotherParentIfExist = ({
  isDepartmentChart,
  node,
  orgChartData,
  hiddenDepartments,
}: {
  isDepartmentChart: boolean;
  node: OrgChartData;
  orgChartData: OrgChartData[];
  hiddenDepartments: string[];
}): OrgChartData | null => {
  let nodeToBeShown = null;
  if (isDepartmentChart && node && hiddenDepartments.includes(node?.projectId)) {
    node.projects?.forEach((item) => {
      if (!hiddenDepartments.includes(item.projectId)) {
        const projectWhereUserIsShown = orgChartData.find((d) => d.id === item.projectId);
        nodeToBeShown = projectWhereUserIsShown?.children?.find((d) => d.originalId === node.id);
        return;
      }
    });
  }
  return nodeToBeShown;
};
