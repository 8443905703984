import { SearchParamObject } from '../interfaces/SearchParamObject';

export const filterSearchParamsObject = (obj: SearchParamObject, namesToRemove: string[]): SearchParamObject => {
  return Object.entries(obj).reduce((acc, [key, value]) => {
    if (!namesToRemove.includes(key)) {
      acc[key] = value;
    }
    return acc;
  }, {} as SearchParamObject);
};
