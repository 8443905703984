import { RootState } from '@modules/App/redux/store';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { DataState } from '@shared/enums/DataState';
import { MainPageState } from './types';
import { LocationOptionData } from '../interfaces/OptionData';

export const getAllFlagsDataSelector = (state: RootState): MainPageState['allFlagsData'] =>
  state.happiness.allFlagsPage.allFlagsData;

export const getAllLocationsDataSelector = (state: RootState): AsyncData<LocationOptionData[]> =>
  state.happiness.allFlagsPage.locations;

export const selectInvolvedUsersData = (state: RootState): UserInfo[] | null =>
  state.happiness.allFlagsPage.filter.involvedUsers.data;

export const selectInvolvedUsersState = (state: RootState): DataState =>
  state.happiness.allFlagsPage.filter.involvedUsers.state;

export const selectFlagAuthorsData = (state: RootState): UserInfo[] | null =>
  state.happiness.allFlagsPage.filter.flagAuthors.data;

export const selectFlagAuthorsState = (state: RootState): DataState =>
  state.happiness.allFlagsPage.filter.flagAuthors.state;

export const selecFlagManagersData = (state: RootState): UserInfo[] | null =>
  state.happiness.allFlagsPage.filter.flagManagers.data;

export const selectFlagManagersState = (state: RootState): DataState =>
  state.happiness.allFlagsPage.filter.flagManagers.state;

export const selectLocations = (state: RootState): LocationOptionData[] | null =>
  state.happiness.allFlagsPage.locations.data;
