import { FC } from 'react';
import { useStyles } from './WhatIsNewPage.styles';
import { whatsNewData } from './constants/whatsNewData';
import { ReleaseBlock } from '@modules/App/pages/WhatIsNewPage/components/ReleaseBlock/ReleaseBlock';

export const WhatIsNewPage: FC = () => {
  const styles = useStyles();
  return (
    <div className={styles.container}>
      <div className={styles.block}>
        <h2>What's new?</h2>
      </div>
      <div className={styles.contentHolder}>
        {whatsNewData.map((release) => (
          <ReleaseBlock release={release} key={release.version} />
        ))}
      </div>
    </div>
  );
};
