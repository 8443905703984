import { put, all, call, takeLatest } from 'redux-saga/effects';

import { DataState } from '@shared/enums/DataState';

import { setMyPeerReviewsData, setMyPeerReviewsDataLoading } from './reducers';
import { getMyPeerReviewsData } from './actions';
import { fetchMyPeerReviewsData } from '@modules/EvaluationModule/api/peerReviews';
import { PeerReviewFeedbackTableResponseDataItem } from '@modules/EvaluationModule/api/types';

function* getMyPeerReviewsDataAsync() {
  const setLoadingAction = setMyPeerReviewsDataLoading();
  yield put(setLoadingAction);
  try {
    const response: PeerReviewFeedbackTableResponseDataItem = yield call(fetchMyPeerReviewsData);
    const setMyPeerReviewsDataAction = setMyPeerReviewsData({
      data: response,
      state: DataState.Fulfilled,
    });
    yield put(setMyPeerReviewsDataAction);
  } catch {
    yield put(
      setMyPeerReviewsData({
        data: null,
        state: DataState.Rejected,
      })
    );
  }
}

function* watchGetMyPeerReviewsData() {
  yield takeLatest(getMyPeerReviewsData.type, getMyPeerReviewsDataAsync);
}

export function* myPeerReviewsSaga(): Generator {
  yield all([watchGetMyPeerReviewsData()]);
}
