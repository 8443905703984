import React, { ReactNode, FC, memo, useCallback } from 'react';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { UsersHappinessTable } from './components/UsersHappinessTable/UsersHappinessTable';
import { useStyles } from './UsersHappiness.styles';
import { UsersHappinessTableDataItem } from '@modules/HappinessModule/interfaces/usersHappinessTableDataItem';
import { NO_MARK_THRESHOLD } from '@modules/HappinessModule/constants/reportConstants';
import { DataState } from '@shared/enums/DataState';
import { QuestionBlock } from '../QuestionBlock';
import { AsyncData } from '@shared/interfaces/asyncData';
import { QuestionData } from '@modules/HappinessModule/interfaces/questionData';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { DataErrorView } from '@shared/components/DataErrorView';
import { BackLink } from '@shared/components/BackLink';

interface Props {
  tableTitle: string;
  sortInfo: SortInfo;
  usersHappinessData: AsyncData<UsersHappinessTableDataItem[]>;
  averageMark: number;
  averageChange: number;
  questionData?: QuestionData;
  backLinkName?: string;
  backLinkPath?: string;
  tabs?: ReactNode;
  sideBlock?: ReactNode;
  onSortClick: (sortInfo: SortInfo) => void;
}

const UsersHappinessComponent: FC<Props> = ({
  tableTitle,
  sortInfo,
  usersHappinessData,
  averageMark,
  averageChange,
  backLinkName,
  backLinkPath,
  sideBlock,
  onSortClick,
  tabs,
}) => {
  const styles = useStyles();
  const handleSortClick = useCallback((sortInfo) => onSortClick(sortInfo), [onSortClick]);
  const isDataLoading = usersHappinessData.state === DataState.Pending;
  const isDataExist = usersHappinessData.data && usersHappinessData.data?.length > 0;
  const numberOfPeopleVoted = usersHappinessData.data
    ? usersHappinessData.data.reduce((res, { mark }) => (mark !== NO_MARK_THRESHOLD ? res + 1 : res), 0)
    : 0;
  const totalNumberOfPeople = usersHappinessData.data ? usersHappinessData.data.length : 0;

  return usersHappinessData.state === DataState.Rejected ? (
    <DataErrorView error={usersHappinessData.error} />
  ) : (
    <div className={styles.root}>
      <div className={styles.questionHolder}>
        <QuestionBlock
          title={tableTitle}
          isDataLoading={isDataLoading}
          average={averageMark}
          prevAverage={averageMark - averageChange}
          peopleVoted={numberOfPeopleVoted}
          peopleTotal={totalNumberOfPeople}
          isDataExist={isDataExist}
        ></QuestionBlock>
      </div>
      <BackLink classNameHolder={styles.backLinkHolder} name={backLinkName} path={backLinkPath}></BackLink>
      {tabs}
      <div className={styles.tableContainer}>
        <div className={styles.header}>
          <RockySkeleton
            isLoading={isDataLoading}
            element={
              <>
                <div className={styles.title}>
                  {`${tableTitle} ${
                    usersHappinessData.data && usersHappinessData.data.length
                      ? `(${usersHappinessData?.data?.length})`
                      : ''
                  }`}
                  {sideBlock}
                </div>
              </>
            }
          />
        </div>
        <UsersHappinessTable
          data={usersHappinessData.data}
          sortInfo={sortInfo}
          onSortClick={handleSortClick}
          isDataLoading={isDataLoading}
        ></UsersHappinessTable>
      </div>
    </div>
  );
};

export const UsersHappiness = memo(UsersHappinessComponent);
