import { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import get from 'lodash/get';

import { useStyles } from './EvalSummaryPage.styles';
import { questionBlockFieldsDescriptions } from './../EvalPage/constants/questionBlockFieldsDescriptions';
import { UserInfoBlock } from './components/UserInfoBlock';
import { getColorByFeedbackMark } from '../../helpers/getColorByFeedbackMark';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { DataState } from '@shared/enums/DataState';
import { EvaluationNotes } from './components/EvaluationNotes/EvaluationNotes';
import { closeEval } from '@modules/EvaluationModule/pages/EvalPage/redux/actions';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { PrevEvalData } from '../EvalPage/components/PrevEvalData';
import { EvalSummaryFeedbackData } from '@modules/EvaluationModule/interfaces/EvalSummaryFeedbackData';
import { markValues } from '../EvalPage/constants/markValues';
import { RocketLoader } from '@shared/components/RocketLoader';
import { InfoView } from '@shared/components/InfoView/InfoView';
import EmptyEval from '@assets/img/EmptyEval.png';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { SummaryTableHeader } from './components/SummaryTableHeader';
import { EvaluationInfoViewTexts } from '@modules/EvaluationModule/enums/EvaluationInfoViewTexts';
import { EvaluationCloseData } from '@modules/EvaluationModule/interfaces/EvaluationCloseData';
import { Comment } from './components/Comment';
import { EvalMarkTitleByValue } from '@modules/EvaluationModule/constants/EvalMarkTitleByValue';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { clientsReviewsShowFilter, peersReviewsShowFilter } from '@modules/EvaluationModule/helpers/reviewsShowFilter';
import { evalPageDataSelector, evalPermissionsSelector } from '../EvalPage/redux/selectors';
import { PdpLinkEvaluationButton } from '@modules/EvaluationModule/pages/EvalSummaryPage/components/PdpLinkEvaluationButton/PdpLinkEvaluationButton';
import { hideAnonymousPeerReviews } from '../EvalPage/helpers/hideAnonymousPeerReviews';
import { GoalsDuringEval } from '../UserGoalsPage/components/GoalsDuringEval/GoalsDuringEval';
import { Stack, Typography } from '@mui/material';
import { ManagePromotionButton } from '../UserPromotionsPage/components/ManagePromotionButton/ManagePromotionButton';
import { useUserPromotionsData } from '../UserPromotionsPage/hooks/useUserPromotionsData';
import { userDataSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { useExternalLinks } from '@modules/App/pages/ExternalLinksPage/hooks/useExternalLinks';

export const EvalSummaryPage: FC = () => {
  const styles = useStyles();
  const evalPageData = useSelector(evalPageDataSelector);
  const evalPermissions = useSelector(evalPermissionsSelector);

  const evalData = evalPageData.data;
  const dispatch = useDispatch();
  const isDataLoading = evalPageData.state === DataState.Pending;
  const isEvalClosed = evalData?.status === EvaluationStatus.Closed;

  const userId = evalData?.userId;
  const prevEvalData = evalData?.previousEvaluation;
  const handleEvalClose = (data: EvaluationCloseData) => dispatch(closeEval(data));

  const user = evalData?.userHistory;
  const evaluator = evalData?.owner;
  const hasReadPermissions = evalPermissions.data?.evaluation.isReadable;
  const evaluateeFeedback = evalData?.evaluation.evaluatee;
  const evaluatorFeedback = evalData?.evaluation.evaluator;
  const peerReviews = evalData?.peerReviews;
  const clientReviews = evalData?.clientReviews;
  const isPageShown = evaluateeFeedback?.isConfirmed && evaluatorFeedback?.isConfirmed;
  const [usersFeedbacks, setUsersFeedbacks] = useState<EvalSummaryFeedbackData[]>([]);
  const [graphValues, setGraphValues] = useState<{
    [index: string]: number;
  }>();
  useExternalLinks(userId);

  useEffect(() => {
    if (user) {
      setUsersFeedbacks((state) => [
        ...state,
        {
          user,
          feedback: evaluateeFeedback ? evaluateeFeedback : null,
        },
      ]);
    }
    if (evaluator) {
      setUsersFeedbacks((state) => [
        ...state,
        {
          user: evaluator,
          feedback: evaluatorFeedback ? evaluatorFeedback : null,
        },
      ]);
    }
    if (clientReviews) {
      setUsersFeedbacks((state) => [
        ...state,
        ...clientReviews.filter(clientsReviewsShowFilter).map(({ user, feedback }) => ({
          user,
          feedback,
        })),
      ]);
    }
    if (peerReviews) {
      setUsersFeedbacks((state) => [
        ...state,
        ...hideAnonymousPeerReviews(peerReviews.filter(peersReviewsShowFilter)).map(({ user, feedback }) => ({
          user,
          feedback,
        })),
      ]);
    }
  }, [user, evaluator, peerReviews, clientReviews]);

  useEffect(() => {
    markValues.map(({ value }) => setGraphValues((state) => ({ ...state, [value]: 0 })));
    usersFeedbacks.map(({ feedback }) => {
      questionBlockFieldsDescriptions.map(({ questionMark }) => {
        const markValue = get(feedback, questionMark);
        if (markValues.some(({ value }) => value === markValue))
          setGraphValues((state) => state && { ...state, [markValue]: state[markValue] + 1 });
      });
    });
  }, [usersFeedbacks]);

  const evalId = evalData?.evaluationId;
  const {
    isPromotionReadable,
    isPromotionUpdatable,
    userPromotionRelatedToEval: { data: userPromotionRelatedToEval, state: userPromotionRelatedToEvalState },
  } = useUserPromotionsData({
    evalId,
  });
  const { data: userData } = useSelector(userDataSelector);

  const promotionChanges =
    userPromotionRelatedToEval && userData
      ? {
          role:
            userPromotionRelatedToEval.role && userPromotionRelatedToEval.role.name !== userData.position
              ? {
                  from: userData.position,
                  to: userPromotionRelatedToEval.role,
                }
              : null,
          seniority:
            userPromotionRelatedToEval.seniority && userPromotionRelatedToEval.seniority.name !== userData.seniority
              ? {
                  from: userData.seniority,
                  to: userPromotionRelatedToEval.seniority,
                }
              : null,
        }
      : null;

  return (
    <>
      {isDataLoading ? (
        <div className={styles.loaderHolder}>
          <RocketLoader />
        </div>
      ) : !hasReadPermissions ? (
        <AccessDeniedView inComponent />
      ) : !isPageShown ? (
        <InfoView inComponent icon={EmptyEval} text={EvaluationInfoViewTexts.NoFeedbacksFromBothSides} />
      ) : (
        usersFeedbacks && (
          <div className={styles.root}>
            <div className={styles.table}>
              <div className={styles.column}>
                <div className={styles.headerItem}>
                  <SummaryTableHeader graphValues={graphValues} />
                </div>
                {questionBlockFieldsDescriptions.map(({ questionTitle }) => (
                  <div className={styles.columnItem} key={questionTitle}>
                    <div className={styles.question}>{questionTitle}</div>
                  </div>
                ))}
              </div>
              <div className={styles.tableDataBlock}>
                {usersFeedbacks.map(({ user, feedback }) => (
                  <div className={styles.column} key={user.id}>
                    <div className={styles.headerItem}>
                      <UserInfoBlock key={user.id} user={user} feedback={feedback} />
                    </div>
                    {questionBlockFieldsDescriptions.map(({ questionMark, questionComments }) => (
                      <div className={styles.columnItem} key={questionMark}>
                        <div
                          key={questionMark}
                          className={styles.feedbackMarkIndicator}
                          style={{ backgroundColor: getColorByFeedbackMark(get(feedback, questionMark)) }}
                        >
                          {get(feedback, questionComments) && (
                            <div className={styles.comment}>
                              <Comment
                                title={EvalMarkTitleByValue[get(feedback, questionMark) as EvaluationMark]}
                                comment={get(feedback, questionComments)}
                                user={user}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
            <Grid container columnSpacing={6}>
              <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column' }}>
                <Box marginY={4}>
                  <PdpLinkEvaluationButton />
                </Box>
                {userPromotionRelatedToEvalState === DataState.Fulfilled &&
                  (isPromotionReadable || isPromotionUpdatable) && (
                    <Box marginY={4}>
                      <Stack rowGap="8px">
                        <Typography variant="h2">Promotions and title changes</Typography>
                        {promotionChanges &&
                        Object.values(promotionChanges).some((value) => value !== null) &&
                        userPromotionRelatedToEval &&
                        userData ? (
                          <Stack flexDirection="row" columnGap="6px">
                            <Stack rowGap="4px">
                              {Object.values(promotionChanges).map((value) =>
                                value ? (
                                  <Typography fontSize="14px">
                                    <Typography variant="body2" component="span" fontSize="inherit">
                                      {value.from} →
                                    </Typography>{' '}
                                    {value.to.name}
                                  </Typography>
                                ) : null
                              )}
                            </Stack>
                            {isPromotionUpdatable && (
                              <Box
                                sx={{
                                  transform: 'translateY(-15%)',
                                }}
                              >
                                <ManagePromotionButton
                                  evaluationHistoryId={evalId}
                                  promotionToUpdate={userPromotionRelatedToEval}
                                  editIcon
                                />
                              </Box>
                            )}
                          </Stack>
                        ) : isPromotionUpdatable ? (
                          <ManagePromotionButton evaluationHistoryId={evalId} btnSize="large" />
                        ) : (
                          <Typography variant="body2" fontSize="14px">
                            No promotions or title changes yet
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  )}
                {evalData && (
                  <RockySkeleton
                    className={styles.evalNotesSkeleton}
                    isLoading={isDataLoading}
                    element={
                      <div className={styles.notes}>
                        <EvaluationNotes
                          evalFormPageData={evalPageData}
                          isEvalClosed={isEvalClosed}
                          notes={evalData.notes}
                          goals={evalData.goals}
                          onEvalClose={handleEvalClose}
                        />
                      </div>
                    }
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <PrevEvalData prevEvalData={prevEvalData} userId={userId} />
                <GoalsDuringEval goalCreationAvailable />
              </Grid>
            </Grid>
          </div>
        )
      )}
    </>
  );
};
