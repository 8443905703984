import { UsersHappinessDataItem } from '@modules/HappinessModule/interfaces/usersHappinessDataItem';
import { createSlice } from '@reduxjs/toolkit';
import { SortDirection } from '@shared/enums/sortDirection';
import { DataState } from '@shared/enums/DataState';
import { setMyTeamHappinessData } from './actions';

import { MainPageState } from './types';
import { resolveSituationFromPeoplePage } from '../../PeopleHappinessPage/redux/actions';

const initialState: MainPageState = {
  myTeamHappinessData: {
    data: null,
    state: DataState.Pending,
  },
  sortInfo: { field: 'mark', sortDirection: SortDirection.Asc },
};

export const slice = createSlice({
  name: 'myTeamHappinessPage',
  initialState,
  reducers: {
    setSortInfo(state, action) {
      state.sortInfo = action.payload;
    },
    setMyTeamHappinessDataLoading(state) {
      state.myTeamHappinessData = { data: null, state: DataState.Pending };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setMyTeamHappinessData, (state, action) => {
      state.myTeamHappinessData = action.payload;
    });
    builder.addCase(resolveSituationFromPeoplePage, (state, { payload: { userId, resolvedBy } }) => {
      if (!state.myTeamHappinessData.data) {
        return;
      }
      const survey: UsersHappinessDataItem[] = state.myTeamHappinessData.data!.survey;
      state.myTeamHappinessData.data!.survey = survey.map((entry) =>
        entry.userId === userId
          ? {
              ...entry,
              resolvedBy: resolvedBy,
            }
          : entry
      );
    });
  },
});

export const { setSortInfo } = slice.actions;

export default slice.reducer;
