import { put, all, call, takeLatest } from 'redux-saga/effects';
import { getUserCompensationData, getUserCompensationRangeData, editCompensation } from './actions';
import {
  setEditCompensationDataState,
  setIsAddNewCompensationComplete,
  setUserCompensationData,
  setUserCompensationDataLoading,
  setUserCompensationRangeData,
  setUserCompensationRangeDataLoading,
} from './reducers';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { CompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import {
  fetchCompensationRangeData,
  fetchEditCompensation,
  fetchUserCompensationData,
} from '@modules/EvaluationModule/api/compensationData';
import { CompensationRange } from '@modules/EvaluationModule/interfaces/CompensationRange';
import { displayNotification } from '@modules/App/redux/notifications/actions';

function* getUserCompensationDataAsync({ payload }: ReturnType<typeof getUserCompensationData>) {
  try {
    const setLoadingAction = setUserCompensationDataLoading();
    yield put(setLoadingAction);
    const response: CompensationData = yield call(fetchUserCompensationData, payload);
    const sortedResponse = {
      ...response,
      historicalCompensations: response.historicalCompensations.sort((a, b) => (a.period > b.period ? -1 : 1)),
    };
    const action = setUserCompensationData({ data: sortedResponse, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUserCompensationData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* getUserCompensationRangeDataAsync({ payload }: ReturnType<typeof getUserCompensationRangeData>) {
  try {
    const setLoadingAction = setUserCompensationRangeDataLoading();
    yield put(setLoadingAction);
    const response: CompensationRange = yield call(fetchCompensationRangeData, payload);
    const action = setUserCompensationRangeData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUserCompensationRangeData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* setNewCompensationAsync({ payload }: ReturnType<typeof editCompensation>) {
  try {
    yield put(setEditCompensationDataState(DataState.Pending));
    yield call(fetchEditCompensation, payload);
    yield put(setEditCompensationDataState(DataState.Fulfilled));
    yield put(getUserCompensationData(payload.id));
    yield put(displayNotification('Compensation saved'));
    yield put(setIsAddNewCompensationComplete(true));
  } catch {
    yield put(setEditCompensationDataState(DataState.Rejected));
    yield put(setIsAddNewCompensationComplete(false));
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* watchGetUserCompensationData() {
  yield takeLatest(getUserCompensationData.type, getUserCompensationDataAsync);
  yield takeLatest(getUserCompensationRangeData.type, getUserCompensationRangeDataAsync);
  yield takeLatest(editCompensation.type, setNewCompensationAsync);
}

export function* userCompensationPageSaga(): Generator {
  yield all([watchGetUserCompensationData()]);
}
