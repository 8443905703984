import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    alignItems: 'center',
    padding: '0 24px',
    minHeight: 60,
    gap: 8,
    borderTop: `1px solid ${COLORS.DIVIDER}`,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  questionNameHolder: {
    display: 'flex',
    alignItems: 'center',
  },
  questionName: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '120%',
    margin: 0,
  },
  nextQuestionLabel: {
    display: 'none',
  },
  dateLabel: {
    fontWeight: '400',
    fontSize: '13px',
    margin: 0,
    color: COLORS.TEXT.SECONDARY,
  },
  nextQuestionRoot: {
    padding: '5px 24px',
    minHeight: 80,
    backgroundColor: COLORS.HIGHLIGHTS.PROMINENT,

    '& $questionName': {
      fontWeight: '400',
      fontSize: '14px',
    },

    '& $cardTextContainer': {
      alignSelf: 'center',
    },

    '& $nextQuestionLabel': {
      fontWeight: '500',
      fontSize: '14px',
      margin: '0 0 0 8px',
      display: 'inline-block',
      color: COLORS.STATUS.WARNING,
    },
    '& $dateLabel': {
      color: COLORS.STATUS.WARNING,
      fontSize: '14px',
    },
  },
  expiredSurvey: {
    backgroundColor: COLORS.HIGHLIGHTS.CRITICAL,
  },
  cardTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  dragHadlerContainer: {},
  deleteActionHolder: {},
});
