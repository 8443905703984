import { FirebaseApp, initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

export const firebaseApp = (): FirebaseApp | undefined => {
  const rawconfig = process.env.REACT_APP_FIREBASE_CONFIG;
  const config = rawconfig ? JSON.parse(rawconfig) : {};
  return initializeApp(config);
};

export const firebaseAuth = (() => {
  if (!process.env.REACT_APP_FIREBASE_CONFIG) {
    /* eslint-disable-next-line */
    console.warn("[FIREBASE ERROR] You haven't configured your firebase app. Please, check the README.md file.");
    return;
  }
  return getAuth(firebaseApp());
})();
