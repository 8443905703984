import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { PayloadActionCreator } from '@reduxjs/toolkit';
import { selectedQuestionSelector } from '../redux/questions/selectors';

// hook to fetch new data by specific question when params.questionId(question) changed
export const useResponseByQuestion = (setDataAction: PayloadActionCreator<string>): string | null => {
  const dispatch = useDispatch();
  const selectedQuestion = useSelector(selectedQuestionSelector);
  useEffect(() => {
    if (selectedQuestion && selectedQuestion.id) {
      dispatch(setDataAction(selectedQuestion.id || ''));
    }
  }, [selectedQuestion]);

  return selectedQuestion ? selectedQuestion.id : null;
};
