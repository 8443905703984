export enum GoalsFormField {
  Objective = 'objective',
  BestCaseResult = 'bestCaseResult',
  WorstCaseResult = 'worstCaseResult',
  ActualResult = 'actualResult',
  Category = 'category',
  DueDate = 'dueDate',
  Status = 'status',
  Description = 'description',
  CancellationNote = 'cancellationNote',
  IsRange = 'isRange',
  ExpectationChangeReason = 'expectationChangeReason',
}
