import { CompensationData } from '../interfaces/CompensationData';
import httpNodeClient from '@shared/api/httpNodeClient';
import { CompensationDataRaw } from './types';
import { CompensationRange } from '../interfaces/CompensationRange';
import { EditCompensationData } from '../interfaces/EditCompensationData';
import { CompensationTypeMap } from './helpers/CompensationTypeMap';

export const fetchUserCompensationData = (payload: string): Promise<CompensationData> => {
  const requestBody = {
    historicalCompensation: true,
    userIds: [payload],
  };

  return httpNodeClient.post<CompensationDataRaw[]>('/compensation/compensation', requestBody).then((resp) => {
    const response = resp.data[0]; //we provide always only one id to the payload and get an array with only one element

    const mappedResponse = {
      ...response,
      type: CompensationTypeMap[response.type],
      historicalCompensations: response.historicalCompensations.map((el) => ({
        ...el,
        period: el.period.toString(),
        compensationType: CompensationTypeMap[el.compensationType],
      })),
    };
    return mappedResponse;
  });
};

export const fetchCompensationRangeData = (payload: string): Promise<CompensationRange> =>
  httpNodeClient.get<CompensationRange>(`/compensation/${payload}/compensation-ranges`).then((resp) => resp.data);

export const fetchEditCompensation = (payload: EditCompensationData): Promise<void> =>
  httpNodeClient.put<void>('/compensation/compensation', payload).then(({ data }) => data);
