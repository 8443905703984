import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

const fonts = {
  customFontFamily:
    "-apple-system, BlinkMacSystemFont, 'Inter var', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif",
};

export const useStyles = makeStyles({
  noderContainer: {
    height: '100%',
    width: '100%',
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    justifyContent: 'center',
    boxShadow: '0 2px 8px -4px hsl(228deg 27% 50% / 40%)',
    borderRadius: 21,
    padding: '0 13px',
  },
  ghost: {
    border: 'none',
  },
  nodeContent: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 12,
    width: '100%',
  },
  departmentNode: {
    backgroundColor: COLORS.WHITE,
  },
  nodeInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  nodeName: {
    paddingBottom: '0.3rem',
    fontSize: '14px',
    fontWeight: '400',
    fontFamily: fonts.customFontFamily,
  },
  departmentName: {
    color: COLORS.BRAND.ACCENT,
    fontWeight: '500',
    lineHeight: '110%',
    display: 'block',
  },
  departmentAvatar: {
    width: 40,
    height: 40,
    flexShrink: 0,
    borderRadius: '30%',
    background: COLORS.BRAND.ACCENT,
    color: COLORS.WHITE,
    textAlign: 'center',
  },
  teamCount: {
    fontSize: '12px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
  },
  rightBlock: {
    marginLeft: 'auto',
  },
  happiness: {
    width: 40,
    height: 40,
    borderRadius: '30%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingBottom: 1,
    color: COLORS.WHITE,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    fontWeight: 500,
    fontSize: 18,
  },
  teamCountBtn: {
    color: COLORS.WHITE,
    backgroundColor: COLORS.ACTION.PRIMARY,
    fontWeight: '500',
    fontSize: '14px',
    height: '28px',
    minWidth: '28px',
    borderRadius: 39,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 10,
  },
  userAvatar: {
    width: 40,
    height: 40,
    borderRadius: '30%',
    objectFit: 'cover',
    display: 'block',
    objectPosition: '50% 35%',
  },
  flag: {
    marginLeft: -16,
    marginRight: 10,
    marginBottom: -26,
    width: 18,
  },
  flagIcon: {
    borderRadius: 4,
    cursor: 'pointer',
    fontSize: '16px',
  },
  nodeUserBlock: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  userTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  userName: {
    display: 'flex',
    gap: 4,
    fontWeight: '400',
    fontSize: '14px',
  },
  userPosition: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
  },
  '@global': {
    '.node-foreign-object-div': {
      borderRadius: 9,
    },
  },
});
