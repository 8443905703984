import React, { FC } from 'react';
import { useStyles } from './RescheduledEvalInfoModal.styles';
import { Button, Dialog, DialogTitle, IconButton, Stack, Typography } from '@mui/material';
import ClockIcon from '@assets/img/ClockIcon.png';
import CloseIcon from '@mui/icons-material/Close';
import { EvaluationRescheduleHistoryData } from '@modules/EvaluationModule/interfaces/EvaluationRescheduleHistory';
import { getHistoryChangeDate } from '@shared/helpers/getHistoryChangeDate';

interface Props {
  isEop: boolean;
  isModalOpen: boolean;
  closeModal: () => void;
  lastRescheduleInfo: EvaluationRescheduleHistoryData;
}

export const RescheduledEvalInfoModal: FC<Props> = ({ isEop, isModalOpen, closeModal, lastRescheduleInfo }) => {
  const styles = useStyles();

  return (
    <Dialog open={isModalOpen} onClose={closeModal}>
      <div className={styles.dialogRoot}>
        <IconButton
          aria-label="close"
          onClick={closeModal}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>

        <Stack alignItems="center">
          <Stack justifyContent="center" alignItems="center">
            <img src={ClockIcon} />
          </Stack>
          <DialogTitle>{isEop ? 'Extended probation period' : 'Rescheduled evaluation'}</DialogTitle>
          <Stack rowGap="4px" className={styles.historyBlock}>
            <Typography variant="body2" textAlign="end">
              {getHistoryChangeDate(lastRescheduleInfo.modificationDate)}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                whiteSpace: 'pre-line',
                wordBreak: 'break-word',
                overflowY: 'scroll',
              }}
              maxHeight={200}
            >
              {lastRescheduleInfo.reason}
            </Typography>
          </Stack>
          <Button
            size="small"
            onClick={closeModal}
            sx={{
              width: 'fit-content',
            }}
          >
            Close
          </Button>
        </Stack>
      </div>
    </Dialog>
  );
};
