import { DataState } from '@shared/enums/DataState';
import { RootState } from '../store';
import { User } from '@shared/interfaces/user';

export const authUserSelector = (state: RootState): User | null => state.user.currentUser.data;

export const locationNotMatchingSinceSelector = (state: RootState): string | null =>
  state.user.locationNotMatchingSince;

export const authUserState = (state: RootState): DataState => state.user.currentUser.state;
