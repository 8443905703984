import React, { FC, memo, useEffect, useRef, useState } from 'react';
import { useStyles } from './MonthSelector.styles';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isSameYear from 'date-fns/isSameYear';
import DatePicker from 'react-datepicker';
import { RockyButton } from '@shared/components/RockyButton/RockyButton';
import ArrowDown from '@mui/icons-material/ExpandMore';
import startOfMonth from 'date-fns/startOfMonth';
import { useClickOutside } from '@shared/hooks/useClickOutside';

interface Props {
  date: string;
  onChange: (date: Date) => void;
  disabled?: boolean;
  minDate?: Date;
}

const MonthSelectorComponent: FC<Props> = ({ date, onChange, disabled, minDate = startOfMonth(new Date()) }) => {
  const styles = useStyles();
  const today = new Date();
  const isDateThisYear = isSameYear(new Date(date), today);
  const [selectedDate, setSelectedDate] = useState(parseISO(date));
  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    setSelectedDate(parseISO(date));
  }, [date]);

  const [isOpen, setIsOpen] = useState(false);

  const relatedTarget = useRef(null);

  const onDateChange = (selectedDate: Date) => {
    if (!selectedDate) {
      return;
    }
    setSelectedDate(selectedDate);
    setIsOpen(false);
    onChange(selectedDate);
  };

  return (
    <div className={styles.datePickerWrapper} ref={wrapperRef}>
      <RockyButton
        small
        inverted
        disabled={disabled}
        className={styles.dateSelectorBtn}
        onClick={() => {
          setIsOpen((state) => !state);
        }}
      >
        {format(selectedDate, isDateThisYear ? 'MMMM' : 'MMMM yyyy')}
        {!disabled && (
          <div className={styles.toggleIcon}>
            {!isOpen ? (
              <ArrowDown />
            ) : (
              <div className={styles.rotate}>
                <ArrowDown />
              </div>
            )}
          </div>
        )}
      </RockyButton>
      {isOpen && (
        <div className={styles.datePicker} ref={relatedTarget}>
          <DatePicker
            selected={selectedDate}
            onChange={onDateChange}
            minDate={minDate}
            showMonthYearPicker
            showFullMonthYearPicker
            showTwoColumnMonthYearPicker
            inline
          />
        </div>
      )}
    </div>
  );
};

export const MonthSelector = memo(MonthSelectorComponent);
