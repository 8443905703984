import { SurveyHistoryPageData } from '@modules/HappinessModule/interfaces/SurveyHistoryPageData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { RootState } from '@modules/App/redux/store';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllLocationsData,
  getQuestionGroupsData,
  setAllLocationsDataLoading,
  setQuestionGroupsDataLoading,
} from '../redux/actions';
import { setSelectedDepartmentId, setSelectedLocationName } from '../redux';
import { AllDepartmentsData } from '@modules/HappinessModule/interfaces/AllDepartmentsData';
import { ActionMeta } from 'react-select';
import {
  surveyHistoryDataSelector,
  surveyHistorySelectedDepartmentId,
  surveyHistorySelectedLocationNameSelector,
} from '../redux/selectors';
import { useAllDepartments } from '@shared/hooks/useAllDepartments';

export const useSurveyHistory = (): {
  surveyHistoryData: AsyncData<SurveyHistoryPageData[]>;
  locations: AsyncData<string[]>;
  departments: AsyncData<AllDepartmentsData[]>;
  handleLocationsOptionChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
  handleDepartmentsOptionChange: (newValue: unknown, actionMeta: ActionMeta<unknown>) => void;
} => {
  const dispatch = useDispatch();

  const surveyHistoryData = useSelector(surveyHistoryDataSelector);
  const selectedLocationName = useSelector(surveyHistorySelectedLocationNameSelector);
  const selectedDepartmentId = useSelector(surveyHistorySelectedDepartmentId);

  const departments = useAllDepartments();
  const handleDepartmentsOptionChange = useCallback(
    (option) => {
      dispatch(setSelectedDepartmentId(departments.data?.find((department) => department.id === option.value)?.id));
    },
    [departments]
  );

  useEffect(() => {
    dispatch(getAllLocationsData());
    return () => {
      dispatch(setAllLocationsDataLoading());
    };
  }, []);

  const locations = useSelector<RootState, AsyncData<string[]>>((state) => state.happiness.surveyHistoryPage.locations);
  const handleLocationsOptionChange = useCallback(
    (option) => {
      dispatch(setSelectedLocationName(locations.data?.find((region) => region === option.value)));
    },
    [locations]
  );

  useEffect(() => {
    dispatch(getQuestionGroupsData({ locationName: selectedLocationName, departmentId: selectedDepartmentId }));
    return () => {
      dispatch(setQuestionGroupsDataLoading());
    };
  }, [selectedLocationName, selectedDepartmentId]);

  return {
    surveyHistoryData,
    locations,
    departments,
    handleLocationsOptionChange,
    handleDepartmentsOptionChange,
  };
};
