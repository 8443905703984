import {
  CompensationDetails,
  FlagFields,
  FlagFieldsNewHomeNeededOptions,
  FlagMainFields,
  FlagResolution,
  HrNotes,
  NewHomeSection,
  NewHomeTomSection,
} from '@modules/HappinessModule/interfaces/FlagFields';
import { RaiseFlagData } from '@modules/HappinessModule/interfaces/RaiseFlagData';
import { Role } from '@shared/enums/role';
import { User } from '@shared/interfaces/user';
import { FlagReason } from '@modules/HappinessModule/enums/FlagsReasons';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';
import { FlagPermissionsData } from '../../../interfaces/FlagPermissionsData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { NewHomeEmailSentStatus } from '@modules/HappinessModule/enums/NewHomeEmailSentStatus';
import { mapNewProjectAssignmentDataToPayload } from '../../../helpers/mapNewProjectAssignmentDataToPayload';

const dependentFieldsFromAnotherOfferResetValues: CompensationDetails = {
  isOfferAccepted: null,
  companyName: null,
  hiringType: null,
  amountType: null,
  benefits: null,
  compensation: null,
  currencyType: null,
};

const dependentFieldsFromIsNewHomeNeededResetValues: FlagFieldsNewHomeNeededOptions = {
  isConfidential: null,
  isReturnedFromSabbatical: null,
  isPassive: null,
  lastDayOnTheProject: null,
  newHomeStartDate: null,
  newHomeAvailableFrom: null,
  newHomeAvailableFromType: null,
  workload: null,
  pros: null,
  cons: null,
  softSkills: null,
  newHomeResolution: null,
  newHomeEndDate: null,
  starRate: null,
  isNewHomeNeeded: false,
  technicalPros: null,
  technicalCons: null,
  technicalSoftSkills: null,
  nhEmailSentStatus: NewHomeEmailSentStatus.NotRequested,
  numOptionsShown: null,
  numSubmissions: null,
  numTICIFailed: null,
  newProjectAssignment: null,
};

interface Props {
  values: FlagFields;
  isNewFlag: boolean;
  userId: string;
  currentUser: User | null;
  userFlagPermissions: FlagPermissionsData | null;
}

interface PrTeamSectionToDevided {
  assignedPR: UserInfo | null;
  assignedPRId: string;
}

interface FlagFieldsDividedByPermissions {
  flag: Omit<FlagMainFields, 'assignedTom' | 'departments'> & Pick<RaiseFlagData, 'departmentIds'>;
  flagResolution: FlagResolution;
  hrNotes: HrNotes;
  compensationDetails: CompensationDetails;
  newHomeSection: NewHomeSection;
  newHomeTomSection: Omit<NewHomeTomSection, 'nhEmailSentStatus'> & Pick<RaiseFlagData, 'assignedTomId'>;
  prTeamSection: PrTeamSectionToDevided;
}

type FlagFieldsDividedByPermissionsRequired = {
  [outerKey in keyof FlagFieldsDividedByPermissions]: {
    [innerKey in keyof Required<FlagFieldsDividedByPermissions[outerKey]>]: string;
  };
};

const flagFieldsDividedByPermissions: FlagFieldsDividedByPermissionsRequired = {
  flag: {
    emailRecipients: '',
    isRecipientsDisabled: '',
    description: '',
    whatWasDone: '',
    severity: '',
    primaryTriggerType: '',
    primaryTrigger: '',
    primarySubReason: '',
    secondaryTriggerType: '',
    secondaryTrigger: '',
    secondarySubReason: '',
    suggestedActionItems: '',
    isEscalatedByClient: '',
    isAnotherOfferInvolved: '',
    isPrTeamInvolved: '',
    pipLink: '',
    departmentIds: '',
    resolvedAt: '',
    flagWatchers: '',
  },
  flagResolution: {
    status: '',
    resolutionNotes: '',
    isSetNewResolutionDate: '',
  },
  hrNotes: {
    hrNotes: '',
  },
  compensationDetails: {
    isOfferAccepted: '',
    hiringType: '',
    amountType: '',
    currencyType: '',
    compensation: '',
    benefits: '',
    companyName: '',
  },
  newHomeSection: {
    isNewHomeNeeded: '',
    isConfidential: '',
    isPassive: '',
    isReturnedFromSabbatical: '',
    lastDayOnTheProject: '',
    workload: '',
    pros: '',
    cons: '',
    softSkills: '',
    technicalPros: '',
    technicalCons: '',
    technicalSoftSkills: '',
  },
  newHomeTomSection: {
    assignedTomId: '',
    newHomeStartDate: '',
    newHomeAvailableFrom: '',
    newHomeResolution: '',
    newHomeEndDate: '',
    starRate: '',
    numOptionsShown: '',
    numSubmissions: '',
    numTICIFailed: '',
    newHomeAvailableFromType: '',
    newProjectAssignment: '',
  },
  prTeamSection: {
    assignedPRId: '',
    assignedPR: '',
  },
};

export const getFlagPayloadData = ({
  isNewFlag,
  values,
  userId,
  currentUser,
  userFlagPermissions,
}: Props): RaiseFlagData => {
  const isCurrentUserHasRoleHr = currentUser?.rockyRoles.includes(Role.hr);
  const assignedPR = values.assignedPR?.id || null;

  let payload: RaiseFlagData = {
    ...values,
    user: userId,
    emailRecipients:
      values.emailRecipients
        .map(({ userEmail, isCarbonCopy }) => ({
          email: userEmail,
          isCarbonCopy,
        }))
        .filter(
          ({ email }, idx, recipients) => recipients.findIndex((recipient) => recipient.email === email) === idx
        ) || [],
    status: isNewFlag ? FlagStatus.InProgress : values.status,
    secondaryTrigger: values.secondaryTrigger === FlagReason.None ? null : values.secondaryTrigger,
    assignedTomId: values.assignedTom?.id || null,
    assignedPRId: values.isPrTeamInvolved ? assignedPR : null,
    flagWatchers: [...new Set(values.flagWatchers?.map(({ user }) => user.id as string))] ?? [],
    departmentIds: values.departments.map(({ id }) => id),
    newProjectAssignment: mapNewProjectAssignmentDataToPayload(values.newProjectAssignment),
  };

  const { newProjectAssignment, ...restDependentFieldsFromIsNewHomeNeededResetValues } =
    dependentFieldsFromIsNewHomeNeededResetValues;
  if (!payload.isNewHomeNeeded) {
    payload = {
      ...payload,
      ...restDependentFieldsFromIsNewHomeNeededResetValues,
      newProjectAssignment: newProjectAssignment as RaiseFlagData['newProjectAssignment'],
    };
  }

  if (isCurrentUserHasRoleHr && !payload.isAnotherOfferInvolved) {
    payload = {
      ...payload,
      ...dependentFieldsFromAnotherOfferResetValues,
    };
  }

  if (userFlagPermissions) {
    Object.entries(userFlagPermissions).map(
      ([key, value]: [string, FlagPermissionsData[keyof FlagFieldsDividedByPermissions]]) => {
        if (isNewFlag ? !value.isCreatable : !value.isUpdatable) {
          Object.keys(flagFieldsDividedByPermissions[key as keyof FlagFieldsDividedByPermissions]).forEach((f) => {
            delete payload[f as keyof RaiseFlagData];
          });
        }
      }
    );
  }

  return payload;
};
