export enum FilterFields {
  IsNewHomeNeeded = 'isNewHomeNeeded',
  Severity = 'severity',
  Status = 'status',
  LastDayOnTheProject = 'lastDayOnTheProject',
  EscalationDate = 'escalationDate',
  ProjectEnd = 'projectEnd',
  UserStatuses = 'userStatuses',
  InvolvedUsers = 'involvedUsers',
  FlagAuthors = 'flagAuthors',
  FlagManagers = 'flagManagers',
  AssignedTomIds = 'assignedTomIds',
  AssignedPrIds = 'assignedPrIds',
  LegalLocations = 'legalLocations',
  Projects = 'projects',
  ReasonsSubReasons = 'reasonsSubReasons',
}
