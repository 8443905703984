import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    columnGap: '10%',

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      rowGap: 40,
    },
  },
  block: {
    width: '50%',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },
  blockTitle: {
    fontWeight: '500',
    fontSize: 24,
    color: COLORS.TEXT.PRIMARY,
    marginBottom: 11,
  },
  evalClientsList: {
    [BREAKPOINTS.MOBILE]: {
      margin: '0 -16px',
    },
  },
  clientBlockTitle: {
    fontWeight: '500',
    fontSize: 24,
    color: COLORS.TEXT.PRIMARY,
    marginBottom: 11,
    position: 'sticky',
    top: 0,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: COLORS.WHITE,
    padding: '10px 0',
    zIndex: 1,
  },
  leftBlock: {},
  rightBlock: {
    positon: 'relative',
  },
  addClientBtn: {
    width: '100%',
    fontWeight: '500',
    fontSize: '14px',
    display: 'flex',
    columnGap: 4,

    '& svg': {
      width: 20,
      height: 20,
    },
  },
  search: {
    margin: '24px 0',
  },
  infoText: {
    marginTop: 24,
    marginBottom: 24,
    padding: '8px 16px',
    color: COLORS.TEXT.SECONDARY,
    background: 'linear-gradient(0deg, #F8F8F8, #F8F8F8), #E9E9E9',
    borderRadius: 8,
    fontWeight: '400',
    fontSize: '14px',

    [BREAKPOINTS.MOBILE]: {
      margin: '8px -16px 16px',
    },
  },
  projectLabel: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },
  infoBlock: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    padding: '14px 16px 16px',
    color: COLORS.TEXT.SECONDARY,
    fontSize: '14px',
    fontWeight: '400',
    borderRadius: 8,
    margin: '0.5rem 0 1rem',
  },
  infoBlockNote: {
    background: COLORS.HIGHLIGHTS.PROMINENT,
  },
  subtitle: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '14px',
    fontWeight: '500',
    marginBottom: 10,
    marginTop: 20,

    [BREAKPOINTS.MOBILE]: {
      margin: '16px 16px 10px',
    },
  },
  userBlockSkeleton: {
    height: 40,
    margin: '5px 0px',
  },
  peersList: {
    listStyle: 'none',
    margin: '0 -16px 32px',
    padding: 0,

    '& li': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '13px 16px',
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },

    '& li:hover, & li:focus': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
      cursor: 'pointer',
    },

    [BREAKPOINTS.MOBILE]: {
      margin: '0 -16px 32px',
    },
  },
  icon: {
    '& svg': {
      width: 21,
      height: 21,
      fill: COLORS.ACTION.PRIMARY,
    },
  },
  noClientBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    padding: '16px 0',
  },
  noClientsLabel: {
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    fontWeight: '400',
    borderRadius: 8,

    [BREAKPOINTS.MOBILE]: {
      padding: '0 16px',
    },
  },
  avatarGroup: {
    '& .MuiAvatar-root': {
      border: 'none',
      color: COLORS.GRAY.SLATE,
      fontSize: 'inherit',
      fontWeight: '500',
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
    },
  },
});
