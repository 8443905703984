import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { HistoricalCompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { format, parseISO } from 'date-fns';
import { CompensationDetailsModalField } from './components/CompensationDetailsModalField';
import { CompensationDetailsModalMoney } from './components/CompensationDetailsModalMoney';

interface Props {
  handleClose: () => void;
  compensationForModal: HistoricalCompensationData;
  previousCompensationForModal: HistoricalCompensationData | null;
}

export const CompensationDetailsModal: FC<Props> = ({
  handleClose,
  compensationForModal,
  previousCompensationForModal,
}) => {
  return (
    <Dialog fullWidth maxWidth="xs" open onClose={handleClose}>
      <DialogTitle>Compensation change</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 12,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent>
        <Stack sx={{ rowGap: '24px' }}>
          <Stack direction="row" columnGap="32px">
            {[
              {
                label: 'Base compensation',
                value: (
                  <>
                    {previousCompensationForModal ? (
                      <>
                        <CompensationDetailsModalMoney compensation={previousCompensationForModal} /> →{' '}
                        <CompensationDetailsModalMoney compensation={compensationForModal} />
                      </>
                    ) : (
                      <CompensationDetailsModalMoney compensation={compensationForModal} />
                    )}
                  </>
                ),
              },
              {
                label: 'Effective from',
                value: format(parseISO(compensationForModal.period), 'MMMM yyyy'),
              },
            ].map((field) => (
              <CompensationDetailsModalField field={field} />
            ))}
          </Stack>
          <CompensationDetailsModalField
            field={{
              label: 'Reason',
              value: compensationForModal.reason,
            }}
          />
          <CompensationDetailsModalField
            field={{
              label: 'Notes',
              value: compensationForModal.comments,
            }}
          />
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
