import React, { useEffect } from 'react';
import { useStyles } from './UserCompensationPage.styles';
import { useTitle } from '@shared/hooks/useTitle';
import { CompensationHistory } from './components/CompensationHistory/CompensationHistory';
import { CompensationDetails } from '@modules/EvaluationModule/pages/UserCompensationPage/components/CompensationDetails/CompensationDetails';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionAction } from '@modules/HappinessModule/enums/PermissionAction';
import { setIsAddNewCompensationComplete } from './redux/reducers';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { compensationPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { userCompensationSelector } from './redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { RocketLoader } from '@shared/components/RocketLoader';
import { CompensationErrorInfoComponent } from './components/CompensationErrorInfoComponent';
import { pageTitles } from '@shared/enums/pageTitles';

export const UserCompensationPage: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const compensationPermissions = useSelector(compensationPermissionsSelector);
  const { data, state } = useSelector(userCompensationSelector);
  const user = useSelector(userSelector);
  const { isCompensationEnabled } = useUserEnabledFeatures();
  const setPageTitle = useTitle();

  const isRevisible = compensationPermissions?.includes(PermissionAction.Update);
  const isDetailsVisible =
    compensationPermissions?.includes(PermissionAction.Update) ||
    compensationPermissions?.includes(PermissionAction.Create);

  useEffect((): (() => void) => {
    setPageTitle(`${user?.name} ${pageTitles.compensation}`);
    return () => dispatch(setIsAddNewCompensationComplete(false));
  }, [user]);

  const isRejected = state === DataState.Rejected;
  const isLoading = state === DataState.Pending;

  return isCompensationEnabled ? (
    isRejected ? (
      <CompensationErrorInfoComponent />
    ) : isLoading ? (
      <div className={styles.loaderHolder}>
        <RocketLoader />
      </div>
    ) : data ? (
      <div className={styles.root}>
        <CompensationDetails data={data} isRevisible={isRevisible} />
        <CompensationHistory data={data} isDetailsVisible={!!isDetailsVisible} />
      </div>
    ) : (
      <CompensationErrorInfoComponent />
    )
  ) : (
    <AccessDeniedView />
  );
};
