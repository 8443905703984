import React, { FC, useCallback } from 'react';
import { Question } from '@modules/HappinessModule/interfaces/question';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useDispatch, useSelector } from 'react-redux';
import { QuestionOption } from './components/QuestionOption';
import { customStyles } from './customStyles';
import { useStyles } from './QuestionSelector.styles';
import { setSelectedQuestion } from '@modules/HappinessModule/redux/questions/actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuestionsHistory } from '@modules/HappinessModule/hooks/useQuestionsHistory';
import Select from 'react-select';
import { questionsHistorySelector, questionsSelector } from '@modules/HappinessModule/redux/questions/selectors';

interface Props {
  title?: string;
  isUnresolvedCount?: boolean;
}

export const QuestionSelector: FC<Props> = ({ title, isUnresolvedCount }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  useQuestionsHistory(); // get survey history
  const questions = useSelector(questionsSelector);
  const questionsHistory: AsyncData<Question[]> = questions.questions;
  const selectedQuestion: Question | null | undefined = questions.selectedQuestion;
  const isQuestionsLoading = questionsHistory.state !== DataState.Fulfilled || !selectedQuestion;

  const questionData = useSelector(questionsHistorySelector);
  const isRedDotShown = questionData?.some((question) => question.unresolvedCount > 0);

  const questionsOptions = questionsHistory.data?.map(({ questionText, id, date, unresolvedCount }) => ({
    label: questionText,
    value: id,
    unresolvedCount,
    date,
    isUnresolvedCount: isUnresolvedCount,
  }));

  const selectedQuestionOption = {
    label: selectedQuestion ? selectedQuestion.questionText : '',
    value: selectedQuestion ? selectedQuestion.id : '',
    date: selectedQuestion ? selectedQuestion.date : '',
  };

  const handleInputChange = useCallback(
    (option) => {
      dispatch(setSelectedQuestion(questionsHistory.data?.find((q) => q.id === option.value)));
      navigate(option.value);
    },
    [questionsHistory, params]
  );

  return (
    <div className={styles.root}>
      <RockySkeleton
        isLoading={isQuestionsLoading}
        element={<h2 className={styles.title}>{title ? `${title}:` : ''}</h2>}
      ></RockySkeleton>
      <RockySkeleton
        isLoading={isQuestionsLoading}
        element={
          <div className={styles.selectorContainer}>
            <Select
              styles={customStyles}
              defaultValue={selectedQuestionOption}
              components={{ Option: QuestionOption }}
              options={questionsOptions}
              isSearchable={false}
              className={styles.dropMenuQuestions}
              onChange={handleInputChange}
              isLoading={isQuestionsLoading}
            />
            {isUnresolvedCount && isRedDotShown && <div className={styles.redDot}></div>}
          </div>
        }
      ></RockySkeleton>
      <RockySkeleton
        isLoading={isQuestionsLoading}
        element={
          <div className={styles.datesContainer}>
            <p className={styles.dateText}>
              {format(parseISO(selectedQuestion ? selectedQuestion.date : new Date().toISOString()), 'dd MMM yyyy')}
            </p>
          </div>
        }
      ></RockySkeleton>
      <RockySkeleton
        isLoading={isQuestionsLoading}
        element={
          questionsHistory.data &&
          questionsHistory.data[0].id === selectedQuestion?.id && <p className={styles.latestTitle}>Latest</p>
        }
      ></RockySkeleton>
    </div>
  );
};
