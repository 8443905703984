import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  goalCompletenessBadge: {
    display: 'inline-block',
    borderRadius: 20,
    padding: '4px 12px 5px',
    margin: '-4px 0 -5px',
    fontWeight: 500,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    color: COLORS.WHITE,
  },
  goalCompletenessBadgeOff: {
    color: COLORS.TEXT.DISABLED,
    background: COLORS.TEXT.DISABLEDBG,
    textShadow: 'none',
  },
  goalCompletenessBadgeBig: {
    padding: '4px 16px 5px 18px',
  },
  goalActions: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
    gap: 8,
    [BREAKPOINTS.MOBILE]: {
      '& button': {
        width: '100%',
      },
    },
  },
  fieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
  },
  customFieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
    padding: '0 14px',
  },
  goalBtnAchieved: {
    '& svg': {
      margin: '0 -4px 0 -8px',
    },

    [BREAKPOINTS.MOBILE]: {
      margin: 0,
      width: '100%',
    },
  },
  goalAchievedBtnContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
  },
  goalAchievedContentTooltip: {
    textAlign: 'center',
  },
  networkError: {
    textAlign: 'right',
    color: COLORS.STATUS.WARNING,
    paddingRight: 24,
  },
});
