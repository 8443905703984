import React, { memo } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { useStyles } from './ChatHeader.styles';
import { useNavigate } from 'react-router';
import { paths } from '@shared/enums/paths';
import { ChatMessage } from '@modules/HappinessModule/interfaces/ChatMessage';
import { requestRevoteAction } from '../../redux/actions';
import { useDispatch } from 'react-redux';
import PollOutlinedIcon from '@mui/icons-material/PollOutlined';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { ReactComponent as Logo } from '@assets/icons/logo-small.svg';
import { User } from '@shared/interfaces/user';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { getBackgroundColorByRate } from '@modules/HappinessModule/helpers/getBackgroundColorByRate';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

interface Props {
  isRevote?: boolean;
  latestSurvey?: ChatMessage;
  user: User | null;
  reaction?: number | null;
  scrollToBottom: () => void;
  isLoading: boolean;
}

const ChatHeaderComponent: React.FC<Props> = ({
  isRevote,
  latestSurvey,
  user,
  reaction,
  scrollToBottom,
  isLoading,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const surveyExpirationDate =
    latestSurvey && latestSurvey.expirationDate ? parseISO(latestSurvey.expirationDate) : null;
  const isSurveyExpired = surveyExpirationDate ? new Date() > surveyExpirationDate : true;

  return (
    <div className={styles.chatHeader}>
      <div className={styles.mobileHeader}>
        <div className={styles.logoContainer}>
          <div className={styles.avatar}>
            <Logo />
          </div>
          <span className={styles.rockyTitle}>Rocky</span>
        </div>
        <RockyAvatar fullName={user?.name || ''} imageUrl={user?.imageUrl} medium />
      </div>
      <button className={styles.backButton} onClick={() => navigate(`${paths.users}/${user?.id}/${paths.responses}`)}>
        <ArrowBackRoundedIcon />
      </button>
      <div className={styles.desktopHeader}>
        <div className={styles.latestSurveyBlock}>
          <div>
            <PollOutlinedIcon className={styles.pollIcon} />
          </div>
          <div>
            <div className={styles.latestSurveyTitle}>Latest survey</div>
            <RockySkeleton
              className={styles.latestSurveyText}
              isLoading={isLoading}
              element={
                <div className={styles.latestSurveyText}>
                  {latestSurvey ? latestSurvey.text : 'No surveys received yet'}
                </div>
              }
            />
          </div>
        </div>
        <RockySkeleton
          className={styles.headerTailSkeleton}
          isLoading={isLoading}
          element={
            <div className={styles.headerTail}>
              {!isRevote && surveyExpirationDate && isSurveyExpired && (
                <div className={styles.latestSurveyCloseDate}>
                  Closed on
                  <br /> {format(surveyExpirationDate, 'dd MMMM')}
                </div>
              )}

              {reaction && (
                <div
                  className={styles.latestSurveyMark}
                  style={{ backgroundColor: getBackgroundColorByRate(reaction) }}
                >
                  {reaction}
                </div>
              )}

              {isRevote && latestSurvey && (
                <div
                  className={styles.latestSurveyRevote}
                  onClick={() => {
                    dispatch(requestRevoteAction());
                    scrollToBottom();
                  }}
                >
                  Revote
                </div>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export const ChatHeader = memo(ChatHeaderComponent);
