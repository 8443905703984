import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './EvaluationNotes.module';
import { useModalState } from '@shared/hooks/useModalState';
import { useAuth } from '@shared/hooks/useAuth';
import { RockyButton } from '@shared/components/RockyButton';
import { Formik, Form, Field, FormikProps } from 'formik';
import classNames from 'classnames';
import { AsyncData } from '@shared/interfaces/asyncData';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { CloseEvalModal } from './components/CloseEvalModal/CloseEvalModal';
import { EvalSuccessCloseModal } from './components/BackToMyTeamModal/EvalSuccessCloseModal';
import { Textarea } from '@shared/components/Textarea';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { EvalNotesFormObserver } from './components/EvalNotesFormObserver/EvalNotesFormObserver';
import { EvaluationCloseData } from '@modules/EvaluationModule/interfaces/EvaluationCloseData';
import { ExtendPeriodDatePicker } from '@modules/EvaluationModule/pages/EvalSummaryPage/components/EvaluationNotes/components/ExtendPeriodDatePicker/ExtendPeriodDatePicker';
import { useSelector } from 'react-redux';
import { CloseEvalEopModal } from './components/CloseEvalEopModal';
import {
  eopLoadingStateSelector,
  evalPermissionsSelector,
} from '@modules/EvaluationModule/pages/EvalPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';

interface Props {
  isEvalClosed: boolean;
  notes: string | null;
  goals: string | null;
  onEvalClose: (data: EvaluationCloseData) => void;
  evalFormPageData?: AsyncData<EvalPageData>;
}

const EvaluationNotesComponent: React.FC<Props> = ({ isEvalClosed, notes, goals, onEvalClose, evalFormPageData }) => {
  const styles = useStyles();
  const { openModal, closeModal, isModalOpen } = useModalState();
  const { openModal: openEopModal, closeModal: closeEopModal, isModalOpen: isEopModalOpen } = useModalState();

  const [isBackToMyTeamModalVisible, setBackToMyTeamModalVisible] = useState(false);
  const { currentUser } = useAuth();
  const nextEvalDate = evalFormPageData?.data?.nextEvalDate;
  const currentEvalDate = evalFormPageData?.data?.evaluationDate;
  const eopResult = evalFormPageData?.data?.eopResult;

  const [isUserConfirmed, setIsUserConfirmed] = useState(false);
  const formRef = useRef<FormikProps<EvaluationCloseData>>(null);
  const data = evalFormPageData?.data;
  const isEop = data?.typeOfInteraction === EvaluationType.EndOfProbation;
  const eopLoadingState = useSelector(eopLoadingStateSelector);
  const evalPermissions = useSelector(evalPermissionsSelector);
  const hasUpdatePermissions = evalPermissions.data?.evaluation.isUpdatable;

  const confirmFormSubmit = () => {
    setIsUserConfirmed(true);
  };

  const changeModal = () => {
    setBackToMyTeamModalVisible(true);
  };

  useEffect(() => {
    if (!formRef.current) {
      return;
    }
    if (isUserConfirmed) {
      formRef.current.handleSubmit();
    }
  }, [isUserConfirmed]);

  useEffect(() => {
    if (eopLoadingState === DataState.Fulfilled) {
      closeEopModal();
    }
  }, [eopLoadingState]);

  const handleFormSubmit = useCallback(
    (formData) => {
      onEvalClose({ ...formData, next_evaluation_date: nextEvalDate, autosave: false });
    },
    [isUserConfirmed, nextEvalDate]
  );

  return (
    <div>
      <h2 className={styles.title}>Evaluation meeting notes</h2>
      <div className={styles.line}></div>
      <Formik
        initialValues={{
          notes: notes,
          goals: goals,
        }}
        innerRef={formRef}
        onSubmit={handleFormSubmit}
      >
        {({ isSubmitting }) => (
          <Form className={styles.notesForm}>
            <fieldset className={styles.fieldset} disabled={!hasUpdatePermissions}>
              <EvalNotesFormObserver />
              {isEvalClosed && (
                <>
                  <label className={classNames(styles.notesLabel, styles.closedNotesDesc)}>
                    New goals and action items
                  </label>
                  <p className={styles.notesDescription}>
                    {goals ? goals : <span className={styles.noNotes}>No additional goals</span>}
                  </p>
                </>
              )}
              {isEvalClosed ? (
                <>
                  <label className={styles.notesLabel}>Notes</label>
                  <p className={styles.notesDescription}>
                    {notes ? notes : <span className={styles.noNotes}>No additional notes</span>}
                  </p>
                </>
              ) : (
                <Field
                  component={Textarea}
                  isAutosize
                  className={styles.notesTextarea}
                  placeholder="Is there something discussed and not covered in reviews?"
                  maxLength={4096}
                  disabled={isSubmitting}
                  name="notes"
                  rows={8}
                />
              )}
              <CloseEvalModal
                isModalOpen={isModalOpen}
                closeModal={closeModal}
                changeModal={changeModal}
                confirmFormSubmit={confirmFormSubmit}
              />
              {isBackToMyTeamModalVisible && (
                <EvalSuccessCloseModal
                  eopResult={eopResult}
                  isModalOpen={isModalOpen}
                  closeModal={closeModal}
                  currentUser={currentUser}
                />
              )}
              {isEvalClosed
                ? null
                : hasUpdatePermissions && (
                    <div className={styles.extendedDateContainer}>
                      <RockyButton
                        onClick={() => {
                          if (isEop && !eopResult) {
                            openEopModal();
                            return;
                          }
                          openModal();
                        }}
                        action
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <div className={styles.spinner}></div>
                        ) : isEop ? (
                          'Close probation'
                        ) : (
                          'Close evaluation'
                        )}
                      </RockyButton>
                      {nextEvalDate && !isEop && (
                        <ExtendPeriodDatePicker nextEvalDate={nextEvalDate} currentEvalDate={currentEvalDate ?? ''} />
                      )}
                    </div>
                  )}
            </fieldset>
          </Form>
        )}
      </Formik>
      {evalFormPageData && (
        <CloseEvalEopModal evalData={evalFormPageData} isModalOpen={isEopModalOpen} closeModal={closeEopModal} />
      )}
    </div>
  );
};

export const EvaluationNotes = memo(EvaluationNotesComponent);
