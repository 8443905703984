import { FlagResolution } from '../enums/FlagResolution';
import {
  PeopleHappinessFilterFormData,
  PeopleHappinessFilterPayloadData,
} from '../interfaces/PeopleHappinessFilterData';

export const mapPeopleHappinessFilterDataToPayload = (
  filters: PeopleHappinessFilterFormData | null
): Partial<PeopleHappinessFilterPayloadData> => {
  if (!filters) return {} as Partial<PeopleHappinessFilterPayloadData>;
  const { resolution, users, primaryReportsTos, departments, ...payloadPart } = filters;

  const payload: Partial<PeopleHappinessFilterPayloadData> = {
    ...payloadPart,
    hasFlag:
      resolution === FlagResolution.FlagRaised ? true : resolution === FlagResolution.Resolved ? false : undefined,
    isResolved:
      resolution === FlagResolution.Resolved ? true : resolution === FlagResolution.NotResolved ? false : undefined,
    reactionGte: filters.includeResponded ? filters.reactionGte : undefined,
    reactionLte: filters.includeResponded ? filters.reactionLte : undefined,
    userIds: users.filter((user) => user.id !== undefined).map((user) => user.id as string),
    primaryReportsToIds: primaryReportsTos.filter((user) => user.id !== undefined).map((user) => user.id as string),
    departments: departments.map((department) => department.id),
  };
  return payload;
};
