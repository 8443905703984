import { COLORS } from '@styles/colors';
import { FlagStatus } from '../enums/FlagStatus';

export const getColorByFlagStatus = (flagStatus: FlagStatus): string => {
  return flagStatus === FlagStatus.Positive
    ? COLORS.STATUS.SUCCESS
    : flagStatus === FlagStatus.Negative
    ? COLORS.STATUS.CRITICAL
    : flagStatus === FlagStatus.InProgress
    ? COLORS.ACTION.PRIMARY
    : COLORS.TEXT.PRIMARY;
};
