import React, { FC } from 'react';
import { Link as ReactRouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import { Box, Stack, Typography } from '@mui/material';

interface Props {
  path: string;
  linkTitle: string;
  icon: React.ReactNode;
}

export const SeeAllReportersLink: FC<Props> = ({ path, linkTitle, icon }) => {
  return (
    <Typography variant="body2">
      <Stack flexDirection="row" columnGap="4px">
        <Box marginTop="2px">{icon}</Box>
        <Stack>
          <Link component={ReactRouterLink} to={path} fontSize="14px">
            {linkTitle}
          </Link>
          <Typography variant="caption">Including secondary reporters</Typography>
        </Stack>
      </Stack>
    </Typography>
  );
};
