import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modalHeaderStyles: {
    borderBottom: 'none',
    padding: 30,
  },
  modalBodyStyles: {
    width: 504,
    padding: '0 0 36px',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
    },
  },
  svgContainer: {
    width: 190,
    margin: '0 auto',
  },
  modalMainText: {
    color: COLORS.TEXT.SECONDARY,
    fontWeight: '400',
    fontSize: 24,
    lineHeight: '130%',
    textAlign: 'center',
    margin: '35px 0 24px',
  },
  modalAddText: {
    color: COLORS.TEXT.SECONDARY,
    fontWeight: '400',
    lineHeight: '130%',
    textAlign: 'center',
    margin: '0 0 50px',
  },
  modalBtnContainer: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    width: 210,
    fontSize: '14px',
    fontWeight: '500',
    padding: '14px 28px',
  },
});
