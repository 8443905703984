import { apiUrl } from '@configs/environment';
import axios from 'axios';
import { httpInterceptorsRequestUse, httpInterceptorsResponseUse } from '@shared/helpers/httpHelpers';

const httpClient = axios.create({
  baseURL: apiUrl,
  headers: { Accept: 'Application/Json' },
});

httpInterceptorsRequestUse(httpClient);

httpInterceptorsResponseUse(httpClient);

export default httpClient;
