import React, { FC, useContext, useState } from 'react';
import { useStyles } from './FlagsTable.styles';
import { Table } from '@shared/components/Table';
import Skeleton from 'react-loading-skeleton';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import format from 'date-fns/format';
import { FlagDetailBlock } from '@modules/HappinessModule/components/FlagDetailBlock';
import { getColorByFlagStatus } from '@modules/HappinessModule/helpers/getColorByFlagStatus';
import { useNavigate } from 'react-router-dom';
import { InfoView } from '@shared/components/InfoView/InfoView';
import { HappinessPagesInfoViewTexts } from '@modules/HappinessModule/enums/HappinessPagesInfoViewTexts';
import EmptyResponse from '@assets/img/EmptyResponse.png';
import { FlagsTableHeaderData } from '@modules/HappinessModule/interfaces/FlagsTableHeaderData';
import classNames from 'classnames';
import { FlagsTableHeader } from '@modules/HappinessModule/enums/FlagsTableHeader';
import { FlagListData } from '@modules/HappinessModule/interfaces/FlagListData';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { VirtualTableContext } from '../VirtualTable/context/VirtualTableContext';
import { VirtualTable } from '../VirtualTable';
import InfiniteLoader from 'react-window-infinite-loader';
import { FlagStatusMapToName } from '../../pages/FlagPage/components/FlagForm/constants/FlagStatusMapToName';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { IconButton, MenuItem, Stack } from '@mui/material';
import { RockyPopup } from '@shared/components/RockyPopup';
import { DuplicateFlagModal } from '../DuplicateFlagModal';
interface Props {
  data: AsyncData<FlagListData[]>;
  tableHeaders: FlagsTableHeaderData[];
  tableHeaderClassName?: string;
  tableDataRowClassName?: string;
  tableRootClassName?: string;
  isVirtualized?: boolean;
  loadMoreItems?: (startIndex: number, stopIndex: number) => void | Promise<void>;
  limit?: number;
  isDataLoading: boolean;
}

const ITEM_HEIGHT = 70;
export const FlagsTable: FC<Props> = ({
  data,
  tableHeaders,
  tableHeaderClassName,
  tableDataRowClassName,
  tableRootClassName,
  isVirtualized,
  loadMoreItems,
  limit,
  isDataLoading,
}) => {
  const styles = useStyles();
  const flagsTable = data.data;
  const navigate = useNavigate();

  const [duplicateFlagId, setDuplicateFlagId] = useState<string | null>(null);

  const tableHeaderRowInnerClassName = classNames(styles.tableHeaderRow, tableHeaderClassName);
  const tableDataRowInnerClassName = classNames(styles.tableDataRow, tableDataRowClassName);

  const tableColumns = {
    [FlagsTableHeader.Member]: {
      component: (flag: FlagListData) => (
        <UserReporterBlock isLink user={flag.user} isDetailInfo statusDisplayPosition="near name" />
      ),
      skeleton: <UserReporterBlock isLoading={isDataLoading} user={{ name: '', position: '' }} isDetailInfo />,
    },
    [FlagsTableHeader.FlagDetails]: {
      component: (flag: FlagListData) => <FlagDetailBlock flagData={flag} />,
      skeleton: <Skeleton className={styles.meterSkeleton} />,
    },
    [FlagsTableHeader.FlagAuthor]: {
      component: (flag: FlagListData) => <UserReporterBlock isLink isLoading={isDataLoading} user={flag.author} />,
      skeleton: <UserReporterBlock isLoading={isDataLoading} user={{ name: '', position: '' }} />,
    },
    [FlagsTableHeader.Escalated]: {
      component: (flag: FlagListData) => (
        <div className={styles.date}>
          {flag.createdAt ? (
            format(new Date(flag.createdAt), 'dd MMM yyyy')
          ) : (
            <span className={styles.datePlaceholder}></span>
          )}
        </div>
      ),
      skeleton: <Skeleton className={styles.meterSkeleton} />,
    },
    [FlagsTableHeader.Resolved]: {
      component: (flag: FlagListData) => (
        <div className={styles.date}>
          {flag.resolvedAt ? (
            format(new Date(flag.resolvedAt), 'dd MMM yyyy')
          ) : (
            <span className={styles.datePlaceholder}></span>
          )}
        </div>
      ),
      skeleton: <Skeleton className={styles.meterSkeleton} />,
    },
    [FlagsTableHeader.Status]: {
      component: (flag: FlagListData) => (
        <div
          style={{
            color: getColorByFlagStatus(flag.status),
          }}
          className={styles.status}
        >
          {FlagStatusMapToName[flag.status]}
        </div>
      ),
      skeleton: <Skeleton className={styles.meterSkeleton} />,
    },
    [FlagsTableHeader.Actions]: {
      component: (flag: FlagListData) => (
        <Stack alignItems="center" justifyContent="center">
          <div>
            <RockyPopup
              className={styles.popup}
              arrow={false}
              position="bottom right"
              trigger={
                <IconButton onClick={(e) => e.stopPropagation()}>
                  <MoreVertIcon color="disabled" />
                </IconButton>
              }
              on={['click']}
            >
              <MenuItem onClick={() => setDuplicateFlagId(flag.id)}>Duplicate flag...</MenuItem>
            </RockyPopup>
          </div>
        </Stack>
      ),
      skeleton: <Skeleton className={styles.meterSkeleton} />,
    },
  };

  interface RowProps {
    flag: FlagListData;
  }
  const flagRow = ({ flag }: RowProps) => {
    return (
      <tr key={flag.id} className={tableDataRowInnerClassName} onClick={() => navigate(`/flags/${flag.id}`)}>
        {tableHeaders.map(({ title }, i) => (
          <td key={i}>{tableColumns[title].component(flag)}</td>
        ))}
      </tr>
    );
  };

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i} className={tableDataRowInnerClassName}>
        {tableHeaders.map(({ title }, i) => (
          <td key={i}>{tableColumns[title].skeleton}</td>
        ))}
      </tr>
    ));

  const table = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <Table
      isDataLoading={isDataLoading}
      tableHeaders={tableHeaders}
      skeleton={tableSkeleton}
      customStyles={{
        root: classNames(tableRootClassName),
        tableHeaderRow: tableHeaderRowInnerClassName,
        tableDataRow: tableDataRowInnerClassName,
      }}
      style={style}
    >
      {children}
    </Table>
  );

  const Inner = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function Inner(
    { children, ...rest },
    ref
  ) {
    const { top } = useContext(VirtualTableContext);
    return (
      <div {...rest} ref={ref}>
        {table({
          children: children,
          style: { top, position: 'absolute', width: '100%' },
        })}
      </div>
    );
  });

  const isItemLoaded = (index: number) => flagsTable !== null && !!flagsTable[index];

  return (
    <>
      {!isDataLoading && flagsTable?.length === 0 ? (
        <InfoView
          inComponent
          text={HappinessPagesInfoViewTexts.NoFlags}
          icon={EmptyResponse}
          className={styles.emptyView}
        />
      ) : (
        <div className={classNames(styles.root, { [styles.rootVirtualized]: isVirtualized })}>
          {isVirtualized
            ? loadMoreItems && (
                <ReactVirtualizedAutoSizer>
                  {({ height, width }) => (
                    <InfiniteLoader
                      isItemLoaded={isItemLoaded}
                      itemCount={100000}
                      loadMoreItems={loadMoreItems}
                      minimumBatchSize={limit}
                      threshold={20}
                    >
                      {({ onItemsRendered, ref }) => (
                        <VirtualTable
                          overscanCount={10}
                          innerElementType={Inner}
                          height={height}
                          width={width}
                          itemCount={flagsTable ? flagsTable.length : 0}
                          itemSize={ITEM_HEIGHT}
                          children={({ index }) => flagsTable && flagRow({ flag: flagsTable[index] })}
                          onItemsRendered={onItemsRendered}
                          ref={ref}
                        />
                      )}
                    </InfiniteLoader>
                  )}
                </ReactVirtualizedAutoSizer>
              )
            : table({ children: flagsTable && flagsTable.map((flag) => flagRow({ flag })) })}
        </div>
      )}
      {duplicateFlagId && (
        <DuplicateFlagModal duplicateFlagId={duplicateFlagId} handleClose={() => setDuplicateFlagId(null)} />
      )}
    </>
  );
};
