import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: 'fit-content',
  },
  overlayMenu: {
    position: 'absolute',
    backgroundColor: 'rgba(255, 255, 255, 0.92)',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    zIndex: 15,
    backdropFilter: 'blur(4px)',
    animation: '$showUp 0.1s linear',
  },
  selectBlock: {
    position: 'relative',
    width: 327,
    marginTop: 10,
    marginLeft: 12,
    animation: '$extension 0.1s linear',
  },
  searchIcon: {
    '& svg': {
      width: '24px',
      height: '24px',
      color: COLORS.INPUTBORDER,
      position: 'absolute',
      left: '19px',
      top: '11px',
      zIndex: 5,
      pointerEvents: 'none',
      boxSizing: 'border-box',
    },
  },
  logo: {
    marginTop: 18,
    marginLeft: 20,
  },
  hidden: {
    display: 'none',
  },
  close: {
    width: 26,
    height: 26,
    margin: '0 0 20px auto',
    position: 'relative',
    cursor: 'pointer',

    '&:before, &:after': {
      position: 'absolute',
      left: 12,
      content: '""',
      height: 26,
      width: 2,
      backgroundColor: COLORS.WHITE,
    },
    '&:before': {
      transform: 'rotate(45deg)',
    },
    '&:after': {
      transform: 'rotate(-45deg)',
    },
  },
  '@keyframes showUp': {
    '0%': { backgroundColor: 'rgba(255,255,255, 0.4)' },
    '100%': { backgroundColor: 'rgba(255, 255, 255, 0.92)' },
  },
  '@keyframes extension': {
    '0%': { opacity: 0, width: 20 },
    '100%': { opacity: 1, width: 327 },
  },
});
