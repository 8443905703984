import React, { FC, memo, useState } from 'react';

import { RockyButton } from '@shared/components/RockyButton';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ReactComponent as SpreadsheetIcon } from '@assets/icons/spreadsheet.svg';
import { useStyles } from './LinkManageModal.styles';
import { isUrlValid } from '@modules/App/pages/ExternalLinksPage/helpers/validateUrl';
import { ExternalLinksTypesToNamesMap } from '@modules/App/pages/ExternalLinksPage/redux/constants';
import { ExternalLinksTypes } from '../../enums/ExternalLinksTypes';

interface Props {
  isModalOpen: boolean;
  canDelete?: boolean;
  closeModal: () => void;
  manageLink: (link: string) => void;
  removeLink?: () => void;
  pendingLinkUpdate: boolean;
  type: ExternalLinksTypes;
  link: string | null;
  isLoading?: boolean;
}

const LinkManageModalComponent: FC<Props> = ({
  closeModal,
  isModalOpen,
  manageLink,
  type,
  link,
  removeLink,
  isLoading,
}) => {
  const [linkText, setLinkText] = useState(link || '');
  const [linkError, setLinkError] = useState<null | string>(null);
  const styles = useStyles();
  const onManageLink = (): void => {
    if (isUrlValid(linkText)) {
      manageLink(linkText);
      setLinkError(null);
      return;
    }
  };

  const onCloseModal = (): void => {
    closeModal();
    setLinkText(link || '');
    setLinkError(null);
  };

  const onLinkChange = (value: string) => {
    setLinkError(!isUrlValid(value) ? 'Must be a link to Google spreadsheet or document' : null);
    setLinkText(value);
  };

  const onLinkRemove = () => {
    if (!removeLink) {
      return;
    }
    setLinkText('');
    setLinkError(null);
    removeLink();
  };

  return (
    <Dialog open={isModalOpen}>
      <DialogTitle>{ExternalLinksTypesToNamesMap[type].buttonTitle}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="linkEvals"
          variant="outlined"
          placeholder={ExternalLinksTypesToNamesMap[type].placeholder}
          error={Boolean(linkError)}
          type="text"
          sx={{ width: '50ch' }}
          InputProps={{
            value: linkText,
            onChange: (e) => {
              onLinkChange(e.target.value);
            },
            disabled: !!isLoading,
            startAdornment: (
              <InputAdornment position="start">
                <SpreadsheetIcon />
              </InputAdornment>
            ),
          }}
        />
        {linkError && <div className={styles.error}>{linkError}</div>}
      </DialogContent>
      <DialogActions>
        {removeLink && (
          <RockyButton dangerInverted className={styles.linkManageBtnDelete} onClick={onLinkRemove}>
            Delete
          </RockyButton>
        )}
        <RockyButton inverted onClick={onCloseModal}>
          Cancel
        </RockyButton>
        <RockyButton action onClick={onManageLink} isLoading={isLoading} disabled={Boolean(!isUrlValid(linkText))}>
          Save
        </RockyButton>
      </DialogActions>
    </Dialog>
  );
};

export const LinkManageModal = memo(LinkManageModalComponent);
