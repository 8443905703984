import React, { useEffect, useState } from 'react';
import { DataState } from '@shared/enums/DataState';
import { useMyEvalPageData } from './hooks/useMyEvalPageData';
import { DataErrorView } from '@shared/components/DataErrorView';
import { useStyles } from './EvalPage.styles';
import { EvalPageHeader } from './components/EvalPageHeader/EvalPageHeader';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { resetEvalForm } from './redux';
import { setClientsSuggestionsLoading, setPeerSuggestionsLoading } from '../EvalSettingsPage/redux';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { useAuth } from '@shared/hooks/useAuth';
import { paths } from '@shared/enums/paths';

export const EvalPage: React.FC = () => {
  const { evalPageData } = useMyEvalPageData();
  const isFailed = evalPageData.state === DataState.Rejected;
  const styles = useStyles();

  const evaluateeName = evalPageData.data?.userHistory.name;
  const setPageTitle = useTitle();

  useEffect(() => {
    if (evaluateeName) {
      setPageTitle(`${evaluateeName} ${pageTitles.userEvalPage}`);
    }
  }, [evaluateeName]);
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(resetEvalForm());
      dispatch(setClientsSuggestionsLoading());
      dispatch(setPeerSuggestionsLoading());
    };
  }, []);

  const { currentUser } = useAuth();
  const userPersonalData = useSelector(userSelector);
  const isCurrentUserEvaluator = currentUser?.id === userPersonalData?.primaryReportsTo.id;
  const navigate = useNavigate();
  const [isRedirectedToEndOfProbPage, setIsRedirectedToEndOfProbPage] = useState(false);

  useEffect(() => {
    if (
      evalPageData.data?.status === EvaluationStatus.Ongoing &&
      evalPageData.data?.typeOfInteraction === EvaluationType.EndOfProbation &&
      !evalPageData.data?.eopResult &&
      isCurrentUserEvaluator &&
      !isRedirectedToEndOfProbPage
    ) {
      navigate(paths.endOfProbation);
      setIsRedirectedToEndOfProbPage(true);
    }
  }, [evalPageData]);

  return isFailed ? (
    <DataErrorView error={evalPageData.error} inComponent />
  ) : (
    <div className={styles.root}>
      <EvalPageHeader evalPageData={evalPageData} />
      <div className={styles.container}>
        <Outlet />
      </div>
    </div>
  );
};
