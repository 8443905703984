import React, { useEffect } from 'react';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { useUserPromotionsData } from './hooks/useUserPromotionsData';
import { PromotionsTable } from './components/PromotionsTable/PromotionsTable';
import { useSelector } from 'react-redux';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { useExternalLinks } from '@modules/App/pages/ExternalLinksPage/hooks/useExternalLinks';
import { useParams } from 'react-router-dom';
import { pageTitles } from '@shared/enums/pageTitles';
import { useTitle } from '@shared/hooks/useTitle';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';

export const UserPromotionsPage: React.FC = () => {
  const { userPromotions } = useUserPromotionsData();
  const user = useSelector(userSelector);
  const userEnabledFeatures = useUserEnabledFeatures();
  const isFailed = userPromotions.state === DataState.Rejected;
  const userActionsPermissions = useSelector(userPermissionsSelector);
  const isDataLoading =
    userPromotions.state === DataState.Pending || userActionsPermissions.state === DataState.Pending;
  const isPromotionsReadable = userActionsPermissions.data?.promotions.isReadable;
  const isPromotionsUpdatable = userActionsPermissions.data?.promotions.isUpdatable;
  const { id } = useParams();

  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(`${user?.name} ${pageTitles.carreer}`);
  }, [user]);

  useExternalLinks(id);

  return isFailed ? (
    <DataErrorView error={userPromotions.error} />
  ) : !isDataLoading && (!isPromotionsReadable || !userEnabledFeatures.isPromotionsEnabled) ? (
    <AccessDeniedView />
  ) : (
    <PromotionsTable
      data={userPromotions.data}
      isDataLoading={isDataLoading}
      isPromotionsUpdatable={isPromotionsUpdatable}
    />
  );
};
