import { CreateUserGoalData } from '@modules/EvaluationModule/interfaces/CreateUserGoalData';
import { EditUserGoalData } from '@modules/EvaluationModule/interfaces/EditUserGoalData';
import { GetUserGoalsPayload } from '@modules/EvaluationModule/interfaces/GetUserGoalsPayload';
import { createAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from '../constants/sliceName';

export const getUserGoalsData = createAction<GetUserGoalsPayload>(`${SLICE_NAME}/getUserGoalsData`);
export const getAchievedGoalsDataForEval = createAction<string>(`${SLICE_NAME}/getAchievedGoalsDataForEval`);
export const updateUserGoal = createAction<EditUserGoalData>(`${SLICE_NAME}/updateUserGoal`);
export const createUserGoal = createAction<CreateUserGoalData>(`${SLICE_NAME}/createUserGoal`);
