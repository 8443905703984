import { displayNotification } from '@modules/App/redux/notifications/actions';
import {
  getClientSurveyResponseData,
  patchClientSurveyResponseData,
  patchDmNotes,
  postRevokeClientSurveyResponse,
} from '@modules/HappinessModule/api/clientSurveyResponseData';
import { ClientSurveyResponseData } from '@modules/HappinessModule/interfaces/ClientSurveyResponseData';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { all, put, select, takeEvery, takeLatest, takeLeading, call } from 'redux-saga/effects';
import {
  loadClientSurveyResponse,
  revokeClientSurveyResponse,
  updateClientSurveyResponse,
  updateDmNotes,
} from './actions';
import {
  removeScoreAndCommentFromClientSurveyResponse,
  setClientSurveyResponseData,
  setTrackedStateValuesOfClientResponseFieldsToSurvey,
  setUpdatingDmNotesState,
  updateClientSurveyResponseInStore,
} from './reducers';
import { selectClientSurveyResponseData } from './selectors';

function* loadClientSurveyResponseAsync({ payload }: ReturnType<typeof loadClientSurveyResponse>) {
  try {
    const clientSurveyResponseData: ClientSurveyResponseData | null = yield call(getClientSurveyResponseData, payload);
    yield put(
      setClientSurveyResponseData({
        data: clientSurveyResponseData,
        state: DataState.Fulfilled,
      })
    );
  } catch (e) {
    yield put(
      setClientSurveyResponseData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* updateClientSurveyResponseAsync({ payload }: ReturnType<typeof updateClientSurveyResponse>) {
  const whatIsEditedData = {
    commentState: payload.comment ? DataState.Pending : DataState.Idle,
    scoreState: payload.score ? DataState.Pending : DataState.Idle,
  };
  const isEditingComment = whatIsEditedData.commentState !== DataState.Idle;
  const isEditingScore = whatIsEditedData.scoreState !== DataState.Idle;
  const previousValue: ClientSurveyResponseData = yield select(selectClientSurveyResponseData);
  try {
    yield put(setTrackedStateValuesOfClientResponseFieldsToSurvey(whatIsEditedData));
    yield put(updateClientSurveyResponseInStore(payload));
    yield call(patchClientSurveyResponseData, {
      clientSurveyResponseId: previousValue.id,
      payload: payload,
    });
    yield put(
      setTrackedStateValuesOfClientResponseFieldsToSurvey({
        commentState: isEditingComment ? DataState.Fulfilled : DataState.Idle,
        scoreState: isEditingScore ? DataState.Fulfilled : DataState.Idle,
      })
    );
  } catch {
    yield put(
      updateClientSurveyResponseInStore({
        comment: isEditingComment ? previousValue?.comment : undefined,
        score: isEditingScore ? previousValue?.score : undefined,
      })
    );
    yield put(displayNotification(isEditingScore ? "Can't update score" : "Can't update comment"));
    yield put(
      setTrackedStateValuesOfClientResponseFieldsToSurvey({
        commentState: isEditingComment ? DataState.Rejected : DataState.Idle,
        scoreState: isEditingScore ? DataState.Rejected : DataState.Idle,
      })
    );
  }
}

function* revokeClientSurveyResponseAsync() {
  try {
    const clientSurveyResponseData: ReturnType<typeof selectClientSurveyResponseData> = yield select(
      selectClientSurveyResponseData
    );
    if (!clientSurveyResponseData) {
      throw new Error('Can not revoke survey response');
    }
    yield put(
      setTrackedStateValuesOfClientResponseFieldsToSurvey({
        revokeState: DataState.Pending,
      })
    );
    yield call(postRevokeClientSurveyResponse, {
      clientSurveyResponseId: clientSurveyResponseData.id,
    });
    yield put(
      setTrackedStateValuesOfClientResponseFieldsToSurvey({
        revokeState: DataState.Fulfilled,
      })
    );
    yield put(removeScoreAndCommentFromClientSurveyResponse());
  } catch (e) {
    yield put(
      setTrackedStateValuesOfClientResponseFieldsToSurvey({
        revokeState: DataState.Rejected,
      })
    );
    yield put(displayNotification('Can not revoke survey response'));
  }
}

function* updateDmNotesAsync({ payload }: ReturnType<typeof updateDmNotes>) {
  try {
    yield put(setUpdatingDmNotesState(DataState.Pending));
    const clientSurveyResponseData: ReturnType<typeof selectClientSurveyResponseData> = yield select(
      selectClientSurveyResponseData
    );
    if (!clientSurveyResponseData) {
      throw new Error();
    }
    const newClientSurveyResponseData: ClientSurveyResponseData = yield call(patchDmNotes, {
      clientSurveyResponseId: clientSurveyResponseData.id,
      payload,
    });
    yield put(setClientSurveyResponseData({ data: newClientSurveyResponseData, state: DataState.Fulfilled }));
    yield put(setUpdatingDmNotesState(DataState.Fulfilled));
  } catch (e) {
    yield put(setUpdatingDmNotesState(DataState.Rejected));
    yield put(displayNotification('Something went wrong while updating notes'));
  }
}

function* watchClientSurveyResponsePageActions() {
  yield takeLatest(loadClientSurveyResponse.type, loadClientSurveyResponseAsync);
  yield takeEvery(updateClientSurveyResponse.type, updateClientSurveyResponseAsync);
  yield takeLeading(revokeClientSurveyResponse.type, revokeClientSurveyResponseAsync);
  yield takeLatest(updateDmNotes.type, updateDmNotesAsync);
}

export function* clientSurveyResponsePageSaga(): Generator {
  yield all([watchClientSurveyResponsePageActions()]);
}
