import { ResponsesActivityDataItem } from '@modules/HappinessModule/interfaces/ResponsesActivityDataItem';
import { paths } from '@shared/enums/paths';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserHappinessData, setUserHappinessDataLoading } from '../redux/actions';
import { userHappinessDataSelector } from '../redux/selectors';

export const useUserHappinessData = (): AsyncData<ResponsesActivityDataItem[]> => {
  const userHappinessData = useSelector(userHappinessDataSelector);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      navigate(paths.root);

      return;
    }
    dispatch(getUserHappinessData(id));
    return () => {
      dispatch(setUserHappinessDataLoading());
    };
  }, [id]);

  return userHappinessData;
};
