import { rescheduleEvaluation } from '@modules/EvaluationModule/helpers/rescheduleEvaluation';
import { rescheduleEval } from '@modules/EvaluationModule/redux/evaluationsManegment/actions';
import { updateGoogleCalendarEvalAction } from '@modules/EvaluationModule/redux/googleCalendarEval/actions';
import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { MainPageState } from './types';

const initialState: MainPageState = {
  myPeopleEvalData: {
    data: null,
    state: DataState.Pending,
  },
  startingEvalId: '',
};

export const slice = createSlice({
  name: 'myPeopleEvalPage',
  initialState,
  reducers: {
    setMyPeopleEvalDataLoading(state) {
      state.myPeopleEvalData = { data: null, state: DataState.Pending };
    },
    setMyPeopleEvalData(state, action) {
      state.myPeopleEvalData = action.payload;
    },
    setStartingEvalId(state, action) {
      state.startingEvalId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rescheduleEval, (state, { payload }) => {
      if (!state.myPeopleEvalData.data) {
        return;
      }
      state.myPeopleEvalData.data = state.myPeopleEvalData.data.map((item) =>
        item.evaluationId === payload.evaluationId
          ? rescheduleEvaluation({ rescheduledEval: item, rescheduleData: payload })
          : item
      );
    });

    builder.addCase(updateGoogleCalendarEvalAction, (state, { payload }) => {
      if (!state.myPeopleEvalData.data) {
        return;
      }
      const evalToUpdate = state.myPeopleEvalData.data.find((d) => d.evaluationId === payload.evaluationId);
      if (!evalToUpdate) {
        return;
      }
      evalToUpdate.googleCalendarEvent = payload.googleCalendarEvent;
    });
  },
});

export const { setMyPeopleEvalData } = slice.actions;

export default slice.reducer;
