import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  goalStatusProgress: {
    color: COLORS.ACTION.PRIMARY,
  },
  goalStatusAchieved: {
    color: COLORS.STATUS.SUCCESS,
  },
  goalStatusCanceled: {
    color: COLORS.TEXT.DISABLED,
  },
  goalStatusOverdue: {
    color: COLORS.STATUS.CRITICAL,
  },
});
