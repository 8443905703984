import { apiUrl } from '@configs/environment';
import httpClient from '@shared/api/httpClient';
import { ResponsesActivityDataItem } from '../interfaces/ResponsesActivityDataItem';
import { UserHappinessData } from './types';
import { resolvedByTypeToUserInfo } from './helpers/resolvedByTypeToUserInfo';

export const fetchUserHappinessData = (id: string): Promise<ResponsesActivityDataItem[]> =>
  httpClient.get<UserHappinessData>(`${apiUrl}/user-responses/${id}/`).then((resp) => {
    const data = resp.data.data.map((r) => {
      const {
        date,
        mark,
        question,
        reminder_count,
        comments,
        primary_reports_to,
        secondary_reports_to,
        team,
        resolved_by,
        flag,
        message_id,
      } = r;

      return {
        date,
        mark,
        question,
        reminderCount: reminder_count,
        comments,
        primaryReportsTo: primary_reports_to
          ? {
              ...primary_reports_to,
              name: primary_reports_to.full_name,
              imageUrl: primary_reports_to.imageUrl,
            }
          : undefined,
        secondaryReportsTo: secondary_reports_to
          ? {
              ...secondary_reports_to,
              name: secondary_reports_to.full_name,
              imageUrl: secondary_reports_to.imageUrl,
            }
          : undefined,
        team,
        resolvedBy: resolvedByTypeToUserInfo(resolved_by),
        flag,
        id: message_id,
      };
    });

    return data;
  });
