import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    [BREAKPOINTS.MOBILE]: {
      overflowX: 'auto',
      minHeight: '100vh',
    },
  },
  card: {
    margin: '24px 0px',
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    [BREAKPOINTS.MOBILE]: {
      width: 700,
    },
  },
  header: {
    padding: 24,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: 500,
    fontSize: 24,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
    },
  },
  footer: {
    padding: '16px 24px 24px 24px',
  },
  addQuestionButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    color: COLORS.ACTION.PRIMARY,
    width: 'fit-content',

    '&:hover': {
      color: COLORS.ACTION.DARK,
    },
  },
  addQuestionButtonText: {
    fontSize: 14,
    fontWeight: 500,
  },
});
