import httpNodeClient from '@shared/api/httpNodeClient';
import { AllFlagsDataFilters } from '../interfaces/AllFlagsDataFilters';
import { flagsListDataMapFunc } from './helpers/flagsListDataMapFunc';
import { ResponseListFlagData } from './types';
import set from 'lodash/set';
import { AllFlagsPageData } from '../pages/AllFlagsPage/interfaces/AllFlagsPageData';
import { DataWithTotalCount } from '@shared/interfaces/DataWithTotalCount';

export const fetchAllFlagsData = (params: AllFlagsDataFilters): Promise<AllFlagsPageData> => {
  const filteredParams = Object.entries(params).reduce((res, [k, v]) => {
    if (v !== undefined && v !== null) {
      set(res, k, v);
    }
    return res;
  }, {});
  return httpNodeClient
    .get<DataWithTotalCount<ResponseListFlagData>>('flags', {
      params: {
        ...filteredParams,
        limit: params.limit,
        offset: params.offset,
      },
    })
    .then(({ data: { items, totalCount } }) => ({ totalCount, items: flagsListDataMapFunc(items) }));
};
