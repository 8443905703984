import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';

import { State } from './types';
import { PeopleHappinessDataGroupBy } from '../enums/PeopleHappinessDataGroupBy';
import { DEFAULT_SORTING } from '../constants';
import { resolveSituationFromPeoplePage } from './actions';
import { PeopleHappinessDataItem } from '../interfaces/PeopleHappinessDataItem';

const initialState: State = {
  peopleHappinessData: {
    data: null,
    state: DataState.Idle,
  },
  sortInfo: DEFAULT_SORTING,
  filters: {
    users: {
      data: [],
      state: DataState.Idle,
    },
    primartReportsTos: {
      data: [],
      state: DataState.Idle,
    },
  },
  isMoreDataAvailable: true,
  dataChunkLoading: false,
  offset: 0,
  filtersToAplly: null,
  peopleHappinessGroupedData: {
    data: null,
    state: DataState.Idle,
  },
  groupBy: PeopleHappinessDataGroupBy.None,
};

export const slice = createSlice({
  name: 'peopleHappinessPage',
  initialState,
  reducers: {
    setSortInfo(state, action) {
      state.sortInfo = action.payload;
    },
    setPeopleHappinessDataLoading(state) {
      state.peopleHappinessData = { data: null, state: DataState.Pending };
    },
    setPeopleHappinessDataChunkLoading(state, { payload }) {
      state.dataChunkLoading = payload;
    },
    appendPeopleHappinessData(state, { payload }: PayloadAction<PeopleHappinessDataItem[]>) {
      if (state.peopleHappinessData.data) {
        state.peopleHappinessData.data.items = [...(state.peopleHappinessData.data?.items || []), ...payload];
      }
    },
    setPeopleHappinessData(state, { payload }: PayloadAction<State['peopleHappinessData']>) {
      state.peopleHappinessData = payload;
    },
    setOffset(state, action) {
      state.offset = action.payload;
    },
    setIsMoreDataAvailable(state, action) {
      state.isMoreDataAvailable = action.payload;
    },
    setFilters(state, { payload }: { payload: Pick<State['filters'], 'primartReportsTos' | 'users'> }) {
      state.filters = { ...state.filters, ...payload };
    },
    setFiltersToAplly(state, { payload }: { payload: State['filtersToAplly'] }) {
      state.filtersToAplly = payload;
    },
    setPeopleHappinessDataGrouped(state, { payload }: PayloadAction<State['peopleHappinessGroupedData']>) {
      state.peopleHappinessGroupedData = payload;
    },
    setPeopleHappinessDataGroupBy(state, { payload }: PayloadAction<State['groupBy']>) {
      state.groupBy = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(resolveSituationFromPeoplePage, (state, { payload: { userId, resolvedBy } }) => {
      if (!state.peopleHappinessData.data) {
        return;
      }

      state.peopleHappinessData.data.items = state.peopleHappinessData.data.items.map(
        (entry: PeopleHappinessDataItem) =>
          entry.userId === userId
            ? {
                ...entry,
                resolvedBy: {
                  fullName: resolvedBy.user.name,
                  imageUrl: resolvedBy.user.imageUrl ?? null,
                  notes: resolvedBy.notes,
                  resolvedAt: resolvedBy.resolvedAt,
                },
              }
            : entry
      );
    });
  },
});

export const {
  setSortInfo,
  setPeopleHappinessData,
  setOffset,
  setPeopleHappinessDataLoading,
  setPeopleHappinessDataChunkLoading,
  appendPeopleHappinessData,
  setIsMoreDataAvailable,
  setFilters,
  setFiltersToAplly,
  setPeopleHappinessDataGrouped,
  setPeopleHappinessDataGroupBy,
} = slice.actions;

export default slice.reducer;
