import { EvaluationRescheduleHistoryData } from '../interfaces/EvaluationRescheduleHistory';
import { RescheduleEvalPayload } from '../redux/evaluationsManegment';
import { EvaluationStatus } from '../enums/EvaluationStatus';
import cloneDeep from 'lodash/cloneDeep';

export const rescheduleEvaluation = <
  T extends {
    evaluationDate: string;
    status: string | null;
    evaluationRescheduleHistory: EvaluationRescheduleHistoryData[];
  }
>({
  rescheduledEval,
  rescheduleData,
}: {
  rescheduledEval: T;
  rescheduleData: RescheduleEvalPayload;
}): T => {
  if (!rescheduledEval) {
    return rescheduledEval;
  }
  const rescheduledEvalCopy = cloneDeep(rescheduledEval);
  if (rescheduledEvalCopy.status === EvaluationStatus.Ongoing) {
    rescheduledEvalCopy.status = EvaluationStatus.Upcoming;
    if (rescheduleData.reason) {
      rescheduledEvalCopy.evaluationRescheduleHistory.push({
        id: rescheduleData.newDate + rescheduleData.evaluationId,
        evaluationId: rescheduleData.evaluationId,
        reason: rescheduleData.reason,
        from: rescheduledEvalCopy.evaluationDate,
        to: rescheduleData.newDate,
        modificationDate: new Date().toISOString(),
      });
    }
  }
  rescheduledEvalCopy.evaluationDate = rescheduleData.newDate;
  return rescheduledEvalCopy;
};
