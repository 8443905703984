import React, { HTMLAttributes, SyntheticEvent } from 'react';
import { useStyles } from './PeopleSelector.styles';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Avatar,
  Chip,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { Field, FormikProps, useFormikContext } from 'formik';
import { FlagFiltersFields } from '../../interfaces/FlagFiltersFields';

interface Props {
  title: string;
  name: string;
  onChange: (fieldName: string, newValue: UserInfo[]) => void;
  value?: UserInfo[];
  data: UserInfo[];
  tom?: boolean;
}

export const PeopleSelector: React.FC<Props> = ({ title, name, onChange, value, data, tom }) => {
  const styles = useStyles();
  const formik: FormikProps<FlagFiltersFields> = useFormikContext();

  return (
    <Field
      name={name}
      component={Autocomplete}
      multiple
      value={value}
      onChange={(_: SyntheticEvent, newValue: UserInfo[]) => onChange(name, newValue)}
      options={tom ? [{ name: 'No TOM Assigned', imageUrl: '', id: 'no_tom_assigned' }, ...data] : data}
      disableClearable
      limitTags={1}
      getOptionLabel={(option: UserInfo) => option.name}
      isOptionEqualToValue={(option: UserInfo, value: UserInfo) => option.id === value.id}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: UserInfo) => (
        <li {...props} key={option.id}>
          {option.imageUrl === '' ? <span>{option.name}</span> : <UserReporterBlock user={option} />}
        </li>
      )}
      filterOptions={(options: UserInfo[], state: FilterOptionsState<UserInfo>) =>
        options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      }
      renderTags={(values: UserInfo[]) =>
        values.map((value, index) => (
          <Chip
            key={index}
            variant="filled"
            avatar={
              value.imageUrl ? (
                <Avatar
                  sx={{
                    borderRadius: '50%',
                  }}
                  variant="circular"
                  src={value.imageUrl ?? undefined}
                >
                  {value.name}
                </Avatar>
              ) : undefined
            }
            label={value.name}
            onDelete={() => {
              const newValue = (formik.values[name as keyof FlagFiltersFields] as UserInfo[]) ?? [];
              onChange(
                name,
                newValue.filter((user) => user.id !== value.id)
              );
            }}
            deleteIcon={<CancelIcon />}
          />
        ))
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          className={styles.selectorInput}
          label={title}
          placeholder={value && value.length > 0 ? '' : 'Anyone'}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};
