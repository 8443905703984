export const getUserSeniority = ({
  position,
  seniority,
}: {
  position?: string | null;
  seniority?: string | null;
}): string => {
  return ['vp', 'director', 'head', 'lead'].some((p) => position?.toLocaleLowerCase().includes(p))
    ? ''
    : seniority || '';
};
