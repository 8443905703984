import { EvalAndFeedbackId } from '@modules/EvaluationModule/interfaces/EvalAndFeedbackId';
import { PeerFormData } from '@modules/EvaluationModule/interfaces/PeerEvalData';
import { SubmitEvalPayloadPeer } from '@modules/EvaluationModule/interfaces/SubmitEvalPayload';
import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from '@shared/interfaces/asyncData';

interface ReviewPeerFeedbackPayload {
  evalId: string;
  feedbackId: string;
  status: string;
  reviewerNotes: string;
}

export const getPeerEvalData = createAction<EvalAndFeedbackId>('peerEvalPage/getPeerEvalData');
export const setPeerEvalData = createAction<AsyncData<PeerFormData>>('peerEvalPage/setPeerEvalData');
export const savePeeEvalData = createAction<SubmitEvalPayloadPeer>('peerEvalPage/savePeeEvalData');
export const setReviewPeerFeedback = createAction<ReviewPeerFeedbackPayload>('peerEvalPage/reviewPeerFeedback');
export const getPeerEvalPermissions = createAction<string>('peerEvalPage/getPeerEvalPermissions');
