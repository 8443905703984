import { RootState } from '@modules/App/redux/store';
import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { createSelector } from '@reduxjs/toolkit';
import { SortDirection } from '@shared/enums/sortDirection';
import { AsyncData } from '@shared/interfaces/asyncData';
import { sortData } from '@shared/redux/helpers/sortData';
import { SORTING_FIELD } from './constants';
import { DataState } from '@shared/enums/DataState';

export const selectAllClientSurveys = (state: RootState): AsyncData<ClientSurveyData[]> =>
  state.happiness.clientSurveysPage.clientSurveys;

export const selectAllClientSurveysSorted = createSelector([selectAllClientSurveys], ({ state, data }) => ({
  state,
  data: data ? sortData(data, { field: SORTING_FIELD, sortDirection: SortDirection.Asc }) : [],
}));

export const selectIsClientSurveyConfirmModalOpen = (state: RootState): boolean =>
  state.happiness.clientSurveysPage.confirmModalOpen;

export const selectIsClientSurveyConfirmPending = (state: RootState): boolean =>
  state.happiness.clientSurveysPage.confirmActionPending;

export const selectUpsertSurvey = (state: RootState): ClientSurveyData | 'NEW' | undefined =>
  state.happiness.clientSurveysPage.upsertSurvey;

export const selectUpsertState = (state: RootState): DataState => state.happiness.clientSurveysPage.upsertState;

export const selectCategoriesList = (state: RootState): AsyncData<string[]> =>
  state.happiness.clientSurveysPage.categoriesList;
