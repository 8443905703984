import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  loader: {
    width: 50,
    height: 50,
    borderRadius: '50%',
    borderTop: '5px solid transparent',
    borderRight: `5px solid ${COLORS.LOADER}`,
    borderBottom: `5px solid ${COLORS.LOADER}`,
    borderLeft: `5px solid ${COLORS.LOADER}`,
    animation: '$spin 0.5s infinite linear',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  loaderHolder: {
    height: 500,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
