import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

const buttonColors = {
  primary: {
    bgColorDefault: COLORS.ACTION.HIGHLIGHT,
    bgColorHover: COLORS.ACTION.PRIMARY,
    bgColorDisabled: COLORS.TEXT.DISABLEDBG,
    fontColorDefault: COLORS.ACTION.PRIMARY,
    fontColorHover: COLORS.WHITE,
    fontColorDisabled: COLORS.TEXT.DISABLED,
  },
  danger: {
    bgColorDefault: COLORS.STATUS.CRITICAL,
    bgColorHover: COLORS.STATUS.WARNING,
    bgColorDisabled: COLORS.DIVIDER,
    fontColorDefault: COLORS.WHITE,
    fontColorHover: COLORS.WHITE,
    fontColorDisabled: COLORS.WHITE,
  },
  warning: {
    bgColorDefault: COLORS.STATUS.WARNING,
    bgColorHover: COLORS.ACTION.DARK,
    bgColorDisabled: COLORS.DIVIDER,
    fontColorDefault: COLORS.WHITE,
    fontColorHover: COLORS.WHITE,
    fontColorDisabled: COLORS.WHITE,
  },
  success: {
    bgColorDefault: COLORS.STATUS.SUCCESS,
    bgColorHover: COLORS.ACTION.DARK,
    bgColorDisabled: COLORS.DIVIDER,
    fontColorDefault: COLORS.WHITE,
    fontColorHover: COLORS.WHITE,
    fontColorDisabled: COLORS.WHITE,
  },
  inverted: {
    bgColorDefault: COLORS.ACTION.HIGHLIGHT,
    bgColorHover: COLORS.ACTION.PRIMARY,
    bgColorDisabled: COLORS.TEXT.DISABLEDBG,
    fontColorDefault: COLORS.ACTION.PRIMARY,
    fontColorHover: COLORS.WHITE,
    fontColorDisabled: COLORS.TEXT.DISABLED,
  },
  action: {
    bgColorDefault: COLORS.ACTION.PRIMARY,
    bgColorHover: COLORS.ACTION.DARK,
    bgColorDisabled: COLORS.TEXT.SECONDARY,
    fontColorDefault: COLORS.WHITE,
    fontColorHover: COLORS.WHITE,
    fontColorDisabled: COLORS.WHITE,
  },
  dangerInverted: {
    bgColorDefault: COLORS.HIGHLIGHTS.CRITICAL,
    bgColorHover: COLORS.STATUS.CRITICAL,
    bgColorDisabled: COLORS.TEXT.DISABLEDBG,
    fontColorDefault: COLORS.STATUS.CRITICAL,
    fontColorHover: COLORS.WHITE,
    fontColorDisabled: COLORS.TEXT.DISABLED,
  },
  cancel: {
    bgColorDefault: COLORS.WHITE,
    bgColorHover: COLORS.WHITE,
    bgColorDisabled: COLORS.TEXT.DISABLEDBG,
    fontColorDefault: COLORS.ACTION.PRIMARY,
    fontColorHover: COLORS.ACTION.PRIMARY,
    fontColorDisabled: COLORS.TEXT.DISABLED,
  },
};

const buttonSizes = {
  large: {
    fontSize: '18px',
    fontWeight: '500',
    height: 54,
    padding: '18 36px',
  },
  medium: {
    fontSize: '14px',
    fontWeight: '500',
    height: 46,
    padding: '14px 24px',
  },
  small: {
    fontSize: '13px',
    fontWeight: '500',
    height: 32,
    padding: '6px 12px',
    width: 'fit-content',
  },
};

const getButtonColorsStyles = (
  type: 'primary' | 'action' | 'inverted' | 'warning' | 'success' | 'danger' | 'dangerInverted' | 'cancel'
) => ({
  background: buttonColors[type].bgColorDefault,
  color: buttonColors[type].fontColorDefault,

  '& svg': {
    fill: 'currentColor',
  },

  '&:hover': {
    background: buttonColors[type].bgColorHover,
    color: buttonColors[type].fontColorHover,

    '& svg': {
      fill: buttonColors[type].fontColorHover,
    },
  },

  '&:disabled': {
    background: buttonColors[type].bgColorDisabled,
    color: buttonColors[type].fontColorDisabled,
    cursor: 'default',
    border: 'none',

    '& svg': {
      fill: 'currentColor',
    },
  },
});

const getButtonSizesStyles = (size: 'large' | 'medium' | 'small') => ({
  ...buttonSizes[size],
});

export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    whiteSpace: 'nowrap',
    textDecoration: 'none',
    fontWeight: '500',
    width: 'fit-content',
    borderRadius: 6,
    cursor: 'pointer',
    border: 'none',
    gap: 8,
    transition: 'color 0.2s, background 0.2s',
  },
  large: {
    ...getButtonSizesStyles('large'),
  },
  medium: {
    ...getButtonSizesStyles('medium'),
  },
  small: {
    ...getButtonSizesStyles('small'),
  },
  primary: {
    ...getButtonColorsStyles('primary'),
  },
  inverted: {
    ...getButtonColorsStyles('inverted'),
  },
  action: {
    ...getButtonColorsStyles('action'),
  },
  warning: {
    ...getButtonColorsStyles('warning'),
  },
  success: {
    ...getButtonColorsStyles('success'),
  },
  danger: {
    ...getButtonColorsStyles('danger'),
  },
  dangerInverted: {
    ...getButtonColorsStyles('dangerInverted'),
  },
  cancel: {
    border: `1px solid ${COLORS.ACTION.PRIMARY}`,
    ...getButtonColorsStyles('cancel'),
  },
});
