export enum ClientRmContactStatus {
  currentEmployee = 'Current employee',
  formerEmployee = 'Former employee',
  currentPoc = 'Current POC',
  formerPoc = 'Former POC',
  mainCurrentPoc = 'Main current POC',
  currentPocsConnection = "Current POC's connection",
  formerPocsConnection = "Former POC's connection",
  investor = 'Investor',
  alexKFirstConnections = "AlexK' s 1st connections",
  alexKPotentialNetwork = "AlexK's potential network",
  interestedToBePoc = 'interested to be POC',
}
