import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useStyles } from './RockyNotifications.styles';
import uniqBy from 'lodash/uniqBy';
import { RockyNotification } from './components/RockyNotification';
import { notificationsSelector } from '@modules/App/redux/notifications/selectors';

interface NotificationRenderData {
  message: string;
  id: string;
}

const RockyNotificationsComponent: FC = () => {
  const styles = useStyles();
  const [displayedNotifications, setDisplayedNotifications] = useState<NotificationRenderData[]>([]);
  const notifications = useSelector(notificationsSelector);

  useEffect(() => {
    setDisplayedNotifications((displayedNotifications) => uniqBy([...displayedNotifications, ...notifications], 'id'));
  }, [notifications]);

  const deleteNotification = useCallback((id) => {
    setDisplayedNotifications((displayedNotifications) => displayedNotifications.filter((dn) => dn.id !== id));
  }, []);

  return (
    <div className={styles.root}>
      {displayedNotifications.map(({ message, id }) => (
        <RockyNotification
          message={message}
          id={id}
          key={id}
          isMarkedForDeletion={notifications.every((n) => n.id !== id)}
          afterFadeOutComplete={(id) => deleteNotification(id)}
        />
      ))}
    </div>
  );
};

export const RockyNotifications = memo(RockyNotificationsComponent);
