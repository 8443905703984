import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';
import chatBackImg from '@assets/img/chatBackImg.png';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    maxHeight: '100%',
  },
  content: {
    backgroundRepeat: 'repeat',
    width: '100%',
    height: '100%',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: 3,

    [BREAKPOINTS.MOBILE]: {
      padding: 0,
    },
  },
  backgroundPattern: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    opacity: 0.5,
    zIndex: 2,
    backgroundImage: `url(${chatBackImg})`,

    [BREAKPOINTS.MOBILE]: {
      position: 'fixed',
    },
  },
  backgroundGradient: {
    position: 'absolute',
    bottom: 0,
    top: 0,
    left: 0,
    right: 0,
    zIndex: 1,
    background:
      'linear-gradient(243.8deg, #E1BAFF -28.6%, #D1FDFF 110.21%), linear-gradient(243.8deg, #FDDB92 -28.6%, #D1FDFF 110.21%)',

    [BREAKPOINTS.MOBILE]: {
      position: 'fixed',
    },
  },
  chat: {
    height: '100%',
    padding: '52px 0 10px',
    display: 'flex',
    flexDirection: 'column',

    [BREAKPOINTS.MOBILE]: {
      paddingTop: 100,
    },
  },
  messagesContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: 10,
    overflowY: 'scroll',
    overflowX: 'hidden',
    padding: '50px 0 10px',
  },
  messagesHolder: {
    width: 800,
    margin: '0 auto',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      padding: '0 10px',
    },
  },
  spinner: {
    width: 40,
    height: 40,
    margin: '0 auto',
    borderRadius: '50%',
    borderTop: '5px solid transparent',
    borderRight: '5px solid transparent',
    borderBottom: `5px solid ${COLORS.BRAND.PRIMARY}`,
    borderLeft: `5px solid ${COLORS.BRAND.PRIMARY}`,
    animation: '$spin 0.5s infinite linear',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  dateContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: 10,
  },
  dateLabel: {
    margin: '30px auto 0',
    fontWeight: '500',
    fontSize: '11px',
    padding: '4px 16px',
    borderRadius: 8,
    backgroundColor: COLORS.INPUTBORDER,
    color: COLORS.WHITE,
  },

  buttonsContainer: {
    margin: '2px auto 10px',
    width: 690,

    '& .button': {
      background: COLORS.WHITE,
    },

    [BREAKPOINTS.MOBILE]: {
      width: 375,
    },
  },

  inputField: {
    width: 690,
    display: 'flex',
    margin: '0 auto',
    flexDirection: 'column',
    rowGap: 10,

    [BREAKPOINTS.MOBILE]: {
      width: 375,
    },
  },
});
