import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
  },
  iconHolder: {
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.STATUS.CRITICAL,
    },
  },
  popup: {
    height: 60,
    borderRadius: 8,
    position: 'absolute',
    top: '100%',
    right: '50%',
    padding: '0 20px',
    border: `1px solid ${COLORS.DIVIDER}`,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    gap: 20,
    zIndex: 1,
  },
  popupLabel: {
    whiteSpace: 'nowrap',
    color: COLORS.TEXT.PRIMARY,
  },
  actionButton: {
    height: 40,
    padding: '0 20px',
    borderRadius: 8,
    backgroundColor: COLORS.WHITE,
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '500',
    outline: 'none',
    border: `1px solid ${COLORS.DIVIDER}`,
    cursor: 'pointer',
  },
  dangerButton: {
    backgroundColor: COLORS.STATUS.CRITICAL,
    color: COLORS.WHITE,
    border: 'none',
  },
});
