import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    padding: '10px 20px',
    transition: 'all 0.1s ease-out',
    cursor: 'pointer',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      alignItems: 'start',
      justifyContent: 'normal',
      fontSize: '14px',
    },
    '&:hover': {
      background: COLORS.BRAND.HIGHLIGHT,
    },
  },
  label: {
    flex: 1,
    alignItems: 'center',
    display: 'flex',
  },
  labelContainer: {},
  isFocused: {
    background: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    '&:hover': {
      background: COLORS.BRAND.PRIMARY,
    },
  },
  dateText: {
    fontSize: '12px',
    fontWeight: '400',
    margin: 0,
    opacity: 0.5,
  },
  unresolvedLabel: {
    padding: '1px 8px',
    display: 'inline-block',
    marginRight: 8,
    marginTop: 5,
    borderRadius: 15,
    backgroundColor: COLORS.WHITE,
    color: COLORS.HIGHLIGHTS.DANGER,
  },
});
