import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  container: {
    borderRadius: 8,
    marginTop: '40px',
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',

    '& > section': {
      padding: '2rem',
      paddingBottom: '2.75rem',

      [BREAKPOINTS.MOBILE]: {
        padding: '1rem',
      },

      '&:not(:last-child)': {
        borderBottom: `1px solid ${COLORS.DIVIDER}`,
      },
    },

    [BREAKPOINTS.MOBILE]: {
      marginTop: '0',
    },

    '& footer': {
      padding: '3rem',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '0.5rem',
      background: 'linear-gradient(270deg, #9C27B0 0%, #FF164E 50%, #F88720 100%)',

      '& h2': {
        color: COLORS.WHITE,
      },
    },
  },
  avatarContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.25rem',
  },
  hrHelp: {
    '& a': {
      textDecoration: 'none',
    },
  },
  policyLinksContainer: {
    '& > a:not(:last-child)': {
      marginBottom: '1rem',
    },
  },
});
