import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserCompensationData } from '../redux/actions';
import { setUserCompensationDataLoading } from '../redux/reducers';
import { CompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { userCompensationSelector } from '../redux/selectors';

export const useUserCompensation = (
  zohoId: string | null
): { userCompensation: AsyncData<CompensationData>; isBonusExist?: boolean } => {
  const userCompensation = useSelector(userCompensationSelector);
  const isBonusExist = userCompensation.data?.historicalCompensations.some((el) => el.bonus !== null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!zohoId) {
      return;
    }
    dispatch(getUserCompensationData(zohoId));

    return () => {
      dispatch(setUserCompensationDataLoading());
    };
  }, [zohoId]);

  return { userCompensation, isBonusExist };
};
