import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {},
  buttonContainer: {
    border: `1px solid ${COLORS.DIVIDER}`,
    color: COLORS.TEXT.SECONDARY,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    margin: '0 12px 12px',
    padding: '10px 12px 10px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'all 0.2s linear',

    '&:hover': {
      border: `1px solid ${COLORS.INPUTBORDER}`,

      '& svg': {
        transform: 'scale(1.1)',
      },
    },

    '& svg': {
      color: COLORS.TEXT.DISABLED,
      transition: 'all 0.2s linear',
    },

    [BREAKPOINTS.TABLET]: {
      justifyContent: 'center',
    },
    [BREAKPOINTS.MOBILE]: {
      margin: '19px 24px 0',
      height: 48,
    },
  },
  buttonText: {
    marginLeft: 16,
    fontSize: '14px',
    flexGrow: 1,
    lineHeight: '20px',
    display: 'flex',
    justifyContent: 'space-between',

    '& small': {
      opacity: 0.5,
    },

    [BREAKPOINTS.TABLET]: {
      display: 'none',
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'flex',
    },
  },
  shown: {
    display: 'flex',
  },
});
