import { FC, memo } from 'react';
import { Box, Button, Grid, Link, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useStyles } from './SupportInfo.styles';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { initialValuesContactPR } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';

const SupportInfoComponent: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const openFeedback = () => {
    dispatch(setIsFeedbackOpen({ isOpen: true, initialValues: initialValuesContactPR }));
  };

  return (
    <Box className={styles.container}>
      <Box component="section">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={7}>
            <Typography variant="h2">HR Support</Typography>

            <Typography variant="body1" marginTop={2} marginBottom={4}>
              Reach out to HR team if you have any concerns that you want to raise to someone else besides your manager
              or you need another point of escalation on a specific matter.
            </Typography>

            <Button variant="contained" onClick={openFeedback}>
              Contact HR team
            </Button>
          </Grid>

          <Grid item xs={12} lg={5}>
            <Grid container justifyContent="space-evenly" spacing={2}>
              <Grid item className={styles.avatarContainer}>
                <RockyAvatar
                  imageUrl="https://ae-happines-zoho-images-storage-staging.s3.us-east-2.amazonaws.com/images/avatars/416726000004774001.jpg"
                  country="Argentina"
                  city="Ciudad Autónoma de Buenos Aires"
                  fullName="Maria Paz Adanez"
                  large
                />
                <Typography variant="h3">Maria Paz Adanez</Typography>
                <Typography variant="body2">HR Lead</Typography>
              </Grid>

              <Grid item className={styles.avatarContainer}>
                <RockyAvatar
                  imageUrl="https://ae-happines-zoho-images-storage-staging.s3.us-east-2.amazonaws.com/images/avatars/416726000006197263.jpg"
                  country="Poland"
                  city="Warsaw"
                  fullName="Iryna Yurchuk"
                  large
                />
                <Typography variant="h3">Iryna Yurchuk</Typography>
                <Typography variant="body2">HR Specialist</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box component="section">
        <Grid container columnSpacing={2} rowSpacing={3} className={styles.hrHelp}>
          <Grid item xs={12}>
            <Typography variant="h2">HR help</Typography>

            <Typography variant="body1" marginTop={2}>
              The HR Team has several teams conforming to it, see next who to reach on each situation.
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Link variant="h2" href="mailto:people.success@agileengine.com">
              🌟 people.sucess@agileengine.com
            </Link>

            <Typography variant="body1" marginTop={2}>
              Reach out if you need educational support, want to know more about our Leadership House, or have feedback
              on our performance management system. Also if you want to get or share information regarding an AE event
              or have any ideas to share within our monthly Digest.
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Link variant="h2" href="mailto:hr.support@agileengine.com">
              🛟 hr.support@agileengine.com
            </Link>

            <Typography variant="body1" marginTop={2}>
              Reach out if you need support with any administrative task, documentation, or budget approvals.
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Link variant="h2" href="mailto:hr_latam@agileengine.com" display="block">
              🌎 hr_latam@agileengine.com
            </Link>
            <Link variant="h2" href="mailto:hr_europe@agileengine.com" display="block" marginTop={1}>
              🌍 hr_europe@agileengine.com
            </Link>

            <Typography variant="body1" marginTop={2}>
              Reach when you need some location-specific information that you are unable to get from your manager.
            </Typography>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Link variant="h2" href="zoho.people.team@agileengine.com">
              🪪 zoho.people.team@agileengine.com
            </Link>

            <Typography variant="body1" marginTop={2}>
              Contact them if you have questions or requests on our HR tools: Rocky or Zoho People. Also you can request
              a report or/and support with analysis HR data in your teams.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box component="section">
        <Grid container spacing={[2, 2, 3]}>
          <Grid item xs={12}>
            <Typography variant="h2">AgileEngine policies</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} className={styles.policyLinksContainer}>
            <Link
              display="block"
              href="https://drive.google.com/file/d/1-4E1u5vcBwgTOPTAYW-C5GFIlzn5A44F/view"
              target="_blank"
            >
              Information Security Card
            </Link>

            <Link
              display="block"
              href="https://docs.google.com/document/d/1OowKyxLpVk7leDSpgeAnv4onVsXpTZhP2sGAPNau3ZM/edit?usp=sharing"
              target="_blank"
            >
              Information Security Policy
            </Link>

            <Link
              display="block"
              href="https://docs.google.com/document/d/13UQiWiROVMzzE4DJHx2j1nDU-dAbG1zpcfgfW5oYkk4/edit?usp=sharing"
              target="_blank"
            >
              Information Classification Policy
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Box component="footer">
        <Typography variant="h2">📖</Typography>
        <Typography variant="h2">Bookmark all our guides stored in one place</Typography>
        <Link href="https://sites.google.com/agileengine.com/agileengine-ua" target="_blank">
          sites.google.com/agileengine.com/agileengine-ua
        </Link>
      </Box>
    </Box>
  );
};

export const SupportInfo = memo(SupportInfoComponent);
