import { all, call, put, takeLatest } from 'redux-saga/effects';

import {
  getOrgChartDepartmentData,
  getOrgChartReportingData,
  loadSearchData,
  setOrgChartData,
  setSearchData,
  setSearchDataState,
} from './actions';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import {
  fetchOrgChartDepartmentData,
  fetchOrgChartReportingData,
  fetchOrgChartSearchData,
} from '@modules/App/api/orgChartData';
import { OrgChartData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import { OrgChartSearchData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartSearchData';

function* getOrgChartDepartmentDataAsync() {
  try {
    const response: OrgChartData[] = yield call(fetchOrgChartDepartmentData);
    const action = setOrgChartData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setOrgChartData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* getOrgChartReportingDataAsync() {
  try {
    const response: OrgChartData[] = yield call(fetchOrgChartReportingData);
    const action = setOrgChartData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setOrgChartData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* orgChartSearch({ payload }: ReturnType<typeof loadSearchData>) {
  yield put(setSearchDataState(DataState.Pending));
  const data: OrgChartSearchData[] = yield call(fetchOrgChartSearchData, payload);
  yield put(setSearchData(data));
}

export function* orgChartPageSaga(): Generator {
  yield all([
    takeLatest(getOrgChartDepartmentData.type, getOrgChartDepartmentDataAsync),
    takeLatest(getOrgChartReportingData.type, getOrgChartReportingDataAsync),
    takeLatest(loadSearchData.type, orgChartSearch),
  ]);
}
