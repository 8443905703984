import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    paddingBottom: 32,
    minWidth: 300,
    borderRadius: 8,
    maxHeight: '100%',
    backgroundColor: COLORS.WHITE,

    [BREAKPOINTS.MOBILE]: {
      height: 'auto',
    },
  },
  container: {
    padding: '24px 30px',
    width: '100%',
    height: '100%',

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
    },
  },
});
