import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';
import { useStyles } from './RockyTooltip.styles';

interface Props {
  trigger: ReactElement;
  className?: string;
  children?: ReactNode;
}

export const RockyTooltip: FC<Props> = ({ trigger, children, className }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={classNames(styles.tooltip, className)}>
        {children}
        <div className={styles.arrow}></div>
      </div>
      {trigger}
    </div>
  );
};
