import { makeStyles } from '@mui/styles';
import { MAXWIDTH } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    maxWidth: MAXWIDTH.CONTAINER,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    marginTop: 25,
    marginBottom: 25,
    paddingBottom: 25,
    backgroundColor: COLORS.WHITE,
  },
  loaderHolder: {
    width: '100%',
    display: 'flex',
    paddingTop: 24,
    alignItems: 'center',
    justifyContent: 'center',
  },
  accessDenied: {
    marginTop: 25,
    marginBottom: 25,
    maxWidth: 1023,
    height: '90%',
  },
  notFoundHolder: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 400,
    padding: 40,
  },
});
