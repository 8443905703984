import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  title: {
    display: 'flex',
    columnGap: 16,
    justifyContent: 'space-between',
    fontWeight: '500',
    fontSize: 24,
    color: COLORS.TEXT.PRIMARY,
    paddingBottom: 28,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
      flexDirection: 'column',
      paddingBottom: 16,
    },
  },
  evalDate: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      marginTop: 4,
      fontWeight: 400,
    },
  },
  evalDateText: {
    whiteSpace: 'nowrap',
  },
  evaluateeBlock: {
    padding: '20px 60px 12px 60px',
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    fontWeight: '500',
    fontSize: 24,
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
      padding: 16,
      gap: 8,
    },
  },
  divider: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    marginLeft: -60,
    marginRight: -56,

    [BREAKPOINTS.MOBILE]: {
      marginLeft: -16,
      marginRight: -16,
    },
  },
});
