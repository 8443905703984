import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { useStyles } from './DeleteAction.styles';

interface Props {
  onDelete: () => void;
}

const DeleteActionComponent: FC<Props> = ({ onDelete }) => {
  const styles = useStyles();
  const popupBody = useRef<HTMLDivElement>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleIconClick = useCallback(() => {
    setIsPopupOpen(true);
  }, [setIsPopupOpen]);

  const handleCancelClick = useCallback(() => {
    setIsPopupOpen(false);
  }, [setIsPopupOpen]);

  const handleDeleteClick = useCallback(() => {
    setIsPopupOpen(false);
    onDelete();
  }, [setIsPopupOpen, onDelete]);

  useEffect(() => {
    const closePopup = (event: MouseEvent) => {
      if (!popupBody.current || !popupBody.current.contains(event.target as Node)) {
        setIsPopupOpen(false);
      }
    };
    if (isPopupOpen) {
      document.addEventListener('mousedown', closePopup);
    }
    return () => document.removeEventListener('mousedown', closePopup);
  }, [setIsPopupOpen, isPopupOpen]);

  return (
    <div className={styles.root} ref={popupBody}>
      <div className={styles.iconHolder} onClick={handleIconClick}>
        <DeleteOutlineOutlinedIcon />
      </div>
      {isPopupOpen && (
        <div className={styles.popup}>
          <span className={styles.popupLabel}>Delete item?</span>
          <button className={classNames(styles.actionButton, styles.dangerButton)} onClick={handleDeleteClick}>
            Delete
          </button>
          <button className={styles.actionButton} onClick={handleCancelClick}>
            Cancel
          </button>
        </div>
      )}
    </div>
  );
};

export const DeleteAction = memo(DeleteActionComponent);
