import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from '../CommunicationModal.styles';
import { Props as CommunicationModalProps } from '../CommunicationModal';
import { ImageUrls } from '@shared/enums/ImageUrls';

const SendEmailLaterMessage: FC<
  Omit<
    CommunicationModalProps,
    'step' | 'isOpen' | 'userFlagPermissions' | 'saveFlagWithSettedNhEmailStatus' | 'setStep' | 'isNewFlag'
  >
> = ({ userInfo, onClose }) => {
  const styles = useStyles();

  return (
    <Box textAlign="center">
      <img src={ImageUrls.Tick} />
      <Typography variant="subtitle1">
        NewHome process was communicated with {userInfo.name} and automated NH email was not sent. You can send it
        later.
      </Typography>
      <Box marginTop={5} className={styles.buttonsContainer}>
        <Button type="button" variant="contained" onClick={onClose}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default SendEmailLaterMessage;
