import { FlagSeverity } from '@modules/HappinessModule/enums/FlagSeverity';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';
import { FlagStatusLabel } from '@modules/HappinessModule/enums/FlagStatusLabel';
import { useEffect, useState } from 'react';

interface Option {
  value: FlagStatus;
  label: FlagStatusLabel;
}

enum StatusSeverityKey {
  InProgressRed = 'InProgressRed',
  InProgressYellow = 'InProgressYellow',
  OnHoldYellow = 'OnHoldYellow',
  OnHoldRed = 'OnHoldRed',
}

const generateOptionsMap: Record<StatusSeverityKey, Option[]> = {
  [StatusSeverityKey.InProgressRed]: [
    { value: FlagStatus.Negative, label: FlagStatusLabel.Negative },
    { value: FlagStatus.Positive, label: FlagStatusLabel.Positive },
    { value: FlagStatus.OnHold, label: FlagStatusLabel.OnHold },
    { value: FlagStatus.InProgress, label: FlagStatusLabel.InProgress },
  ],
  [StatusSeverityKey.InProgressYellow]: [
    { value: FlagStatus.Positive, label: FlagStatusLabel.Positive },
    { value: FlagStatus.OnHold, label: FlagStatusLabel.OnHold },
    { value: FlagStatus.InProgress, label: FlagStatusLabel.InProgress },
  ],
  [StatusSeverityKey.OnHoldYellow]: [
    { value: FlagStatus.Positive, label: FlagStatusLabel.Positive },
    { value: FlagStatus.InProgress, label: FlagStatusLabel.InProgress },
    { value: FlagStatus.OnHold, label: FlagStatusLabel.OnHold },
  ],
  [StatusSeverityKey.OnHoldRed]: [
    { value: FlagStatus.Negative, label: FlagStatusLabel.Negative },
    { value: FlagStatus.Positive, label: FlagStatusLabel.Positive },
    { value: FlagStatus.InProgress, label: FlagStatusLabel.InProgress },
    { value: FlagStatus.OnHold, label: FlagStatusLabel.OnHold },
  ],
};
export const useStatusOptions = ({
  initialStatus,
  severity,
}: {
  initialStatus: FlagStatus | null;
  severity: FlagSeverity | null;
}): {
  statusOptions: Option[] | null;
} => {
  const [options, setOptions] = useState<Option[] | null>(null);

  useEffect(() => {
    const key =
      initialStatus === FlagStatus.InProgress
        ? severity === FlagSeverity.red
          ? StatusSeverityKey.InProgressRed
          : StatusSeverityKey.InProgressYellow
        : severity === FlagSeverity.red
        ? StatusSeverityKey.OnHoldRed
        : StatusSeverityKey.OnHoldYellow;

    setOptions(generateOptionsMap[key]);
  }, [initialStatus, severity]);

  return { statusOptions: options };
};
