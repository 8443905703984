import { RockyPopup } from '@shared/components/RockyPopup';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrgChartData } from '../../interfaces/OrgChartData';
import {
  getRolesSelector,
  selectFilterInfo,
  selectIsDepartmentChart,
  selectRootId,
  selectSortInfo,
} from '../../redux/selectors';
import { ChartSearch } from '../ChartSearch';
import { useStyles } from './Toolbar.styles';
import RemoveOutlinedIcon from '@mui/icons-material/RemoveOutlined';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FullscreenExitOutlinedIcon from '@mui/icons-material/FullscreenExitOutlined';
import { OrgChartFilter } from '../../enums/Filters';
import { FilterInfoOrgChart } from '../../interfaces/FilterInfoOrgChart';
import classNames from 'classnames';
import { Role } from '@shared/enums/role';
import { RockyCheckbox } from '@shared/components/RockyCheckbox';
import { RoleMap } from '@shared/consts/RoleMap';
import { removeFilterInfo, setFilterInfo, setIsDepartmentChart, setSortInfo } from '../../redux/actions';
import { SortDirection } from '@shared/enums/sortDirection';
import Range from '@shared/interfaces/Range';
import debounce from 'lodash/debounce';
import { HappinessSlider } from '@shared/components/HappinessSlider';
import { HiddenNodes } from '../../interfaces/HiddenNodes';

interface Props {
  orgChartData: OrgChartData[];
  setSelectedNode: (node: OrgChartData | undefined) => void;
  isShowHappiness: boolean;
  toggleShowHappiness: () => void;
  isShowHappinessFromRange: boolean;
  canToggleFilters: boolean;
  toggleShowHappinessFromRange: () => void;
  onSearchSelect: (selectedNodeId: string) => void;
  resetFiltersHandler: () => void;
  zoomOut: () => void;
  zoomIn: () => void;
  hiddenNodes: HiddenNodes;
}

const TIMEOUT_FOR_HAPPINESS_CHANGE = 500;
export const Toolbar: FC<Props> = ({
  orgChartData,
  setSelectedNode,
  isShowHappiness,
  toggleShowHappiness,
  isShowHappinessFromRange,
  canToggleFilters,
  toggleShowHappinessFromRange,
  onSearchSelect,
  resetFiltersHandler,
  zoomOut,
  zoomIn,
  hiddenNodes,
}) => {
  const isSettingsClosedOnce = localStorage.getItem('settingsClosedOnce');
  const styles = useStyles();
  const rootId = useSelector(selectRootId);
  const dispatch = useDispatch();
  const [isSettingsOpen, setIsSettingsOpen] = useState(canToggleFilters && !isSettingsClosedOnce);
  const filterInfo = useSelector(selectFilterInfo);
  const sortInfo = useSelector(selectSortInfo);
  const roles = useSelector(getRolesSelector);
  const isDepartmentChart = useSelector(selectIsDepartmentChart);
  const [happinessRange, setHappinessRange] = useState<Range>({ from: 1, to: 10 });

  const [filterRoles, setFilterRoles] = useState<FilterInfoOrgChart>({
    field: OrgChartFilter.Roles,
    value: [],
  });
  const filterRolesValue = filterRoles.value as Role[];

  const changeFilterRoles = useCallback((filterRoles: Role[]) => {
    const newFilterRoles = {
      field: OrgChartFilter.Roles,
      value: filterRoles,
    };
    filterRoles?.length ? dispatch(setFilterInfo(newFilterRoles)) : dispatch(removeFilterInfo(newFilterRoles.field));
  }, []);

  useEffect(() => {
    setFilterRoles((state) => ({
      ...state,
      value: filterInfo.find((item) => OrgChartFilter.Roles === item.field)?.value || [],
    }));
  }, [filterInfo]);

  const filterWhomICanSee = { field: OrgChartFilter.Happiness, value: null, reverse: true };

  const collapseAllNodes = useCallback(() => {
    setSelectedNode(orgChartData.find((item) => item.id === rootId));
  }, [orgChartData, rootId]);

  const debounceHappinessRangeChange = useCallback(
    debounce((range) => {
      dispatch(setFilterInfo({ field: OrgChartFilter.HappinessReaction, value: range }));
    }, TIMEOUT_FOR_HAPPINESS_CHANGE),
    []
  );

  useEffect(() => {
    if (!isShowHappinessFromRange) {
      dispatch(removeFilterInfo(OrgChartFilter.HappinessReaction));
      return;
    }
    debounceHappinessRangeChange(happinessRange);
  }, [isShowHappinessFromRange, happinessRange]);

  const onHappinessRangeChange = (_: Event, value: number[] | number) => {
    const [from, to] = value as number[];
    setHappinessRange({ from, to });
  };

  useEffect(() => {
    !orgChartData.length && setIsSettingsOpen(false);
  }, [orgChartData]);

  useEffect(() => {
    if (!isShowHappiness) {
      resetFiltersHandler();
      return;
    }
    dispatch(setFilterInfo(filterWhomICanSee));
  }, [isShowHappiness]);

  return (
    <div className={styles.toolbar}>
      <div className={styles.toolbarHeader}>
        <ChartSearch onSelect={onSearchSelect} hiddenNodes={hiddenNodes} />
        <div className={classNames(styles.tools, styles.structures)}>
          <div
            className={classNames(styles.tool, styles.toolText, {
              [styles.active]: isDepartmentChart,
            })}
            onClick={() => dispatch(setIsDepartmentChart(true))}
          >
            Departments
          </div>
          <div
            className={classNames(styles.tool, styles.toolText, {
              [styles.active]: !isDepartmentChart,
            })}
            onClick={() => dispatch(setIsDepartmentChart(false))}
          >
            People
          </div>
        </div>
        <div className={styles.flex}>
          <div className={classNames(styles.tools, styles.zoom)}>
            <div className={classNames(styles.tool, styles.tool)} onClick={zoomOut}>
              <RemoveOutlinedIcon />
            </div>
            <div className={classNames(styles.tool, styles.tool)} onClick={zoomIn}>
              <AddOutlinedIcon />
            </div>
          </div>
          <RockyPopup
            trigger={
              <div className={styles.tool} onClick={collapseAllNodes}>
                <FullscreenExitOutlinedIcon />
              </div>
            }
            on={['hover']}
          >
            <span>Collapse All</span>
          </RockyPopup>
          {isDepartmentChart && canToggleFilters && (
            <RockyPopup
              on={['hover']}
              trigger={
                <div
                  className={styles.tool}
                  onClick={() => {
                    setIsSettingsOpen((state) => !state);
                    localStorage.setItem('settingsClosedOnce', 'true');
                  }}
                >
                  {isSettingsOpen ? <CloseOutlinedIcon /> : <TuneOutlinedIcon />}
                </div>
              }
            >
              <span>{isSettingsOpen ? 'Close settings' : 'Settings'}</span>
            </RockyPopup>
          )}
        </div>
      </div>
      {isSettingsOpen && (
        <div className={styles.toolbarBody}>
          <div className={styles.toolbarBodyItem}>
            <div className={styles.toolbarTitle}>Happiness</div>
            <RockyCheckbox checked={isShowHappiness} onChange={toggleShowHappiness}>
              <span className={styles.bodyItemTitle}>Show happiness</span>
            </RockyCheckbox>
            {isShowHappiness && (
              <>
                <RockyCheckbox
                  checked={filterInfo.some((item) => filterWhomICanSee.field === item.field)}
                  onChange={() => dispatch(setFilterInfo(filterWhomICanSee))}
                >
                  <span className={styles.bodyItemTitle}>Show only people whose happiness is visible to me</span>
                </RockyCheckbox>
                <RockyCheckbox checked={isShowHappinessFromRange} onChange={() => toggleShowHappinessFromRange()}>
                  <span className={styles.bodyItemTitle}>Show happiness from range</span>
                </RockyCheckbox>
                {isShowHappinessFromRange && (
                  <HappinessSlider value={[happinessRange.from, happinessRange.to]} onChange={onHappinessRangeChange} />
                )}
              </>
            )}
          </div>
          {roles.length > 0 && (
            <div className={styles.toolbarBodyItem}>
              <div className={styles.toolbarTitle}>Show teams and people where I am:</div>
              {roles &&
                roles.map((role) => (
                  <RockyCheckbox
                    checked={filterRolesValue.includes(role)}
                    onChange={() => {
                      changeFilterRoles(
                        filterRolesValue.includes(role)
                          ? filterRolesValue.filter((item) => item !== role)
                          : [...filterRolesValue, role]
                      );
                    }}
                  >
                    <span className={styles.bodyItemTitle}>{RoleMap[role]}</span>
                  </RockyCheckbox>
                ))}
            </div>
          )}
          {isShowHappiness && (
            <div className={styles.toolbarBodyItem}>
              <div className={styles.toolbarTitle}>Sort by</div>
              <div className={styles.radioBtn}>
                <input
                  type="radio"
                  value={OrgChartFilter.Name}
                  checked={sortInfo.field === OrgChartFilter.Name}
                  name="sort"
                  onChange={() =>
                    dispatch(setSortInfo({ field: OrgChartFilter.Name, sortDirection: SortDirection.Asc }))
                  }
                />
                <span>Alphabetical</span>
              </div>
              <div className={styles.radioBtn}>
                <input
                  type="radio"
                  value={OrgChartFilter.HappinessReaction}
                  checked={sortInfo.field === OrgChartFilter.HappinessReaction}
                  name="sort"
                  onChange={() =>
                    dispatch(setSortInfo({ field: OrgChartFilter.HappinessReaction, sortDirection: SortDirection.Asc }))
                  }
                />
                <span>Happiness</span>
              </div>
              <div className={classNames(styles.tool, styles.toolText, styles.btn)} onClick={resetFiltersHandler}>
                Reset filters
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
