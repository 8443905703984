import { DataState } from '@shared/enums/DataState';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadMetroAreas } from '../redux/actions';
import { metroAreasSelector } from '../redux/selectors';
import { CityTime } from '../componenes/EditUserAddressComponent/components/AddressAutocompleteTextField/interfaces/metroAreasInterfaces';
import format from 'date-fns/format';
import {
  CET_10_AM_TIME,
  METRO_AREA_REMOTE,
  TWO_HOURS_IN_SECONDS,
} from '../componenes/EditUserAddressComponent/constants/metroAreasConstants';
import { isGoogleApiLoadedSelector } from '@modules/App/redux/googleApi/selectors';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import addDays from 'date-fns/addDays';

export const useMetroAreas = ({
  country,
  city,
  setClosestMetroAreaToForm,
}: {
  country: string | null;
  city: string | null;
  setClosestMetroAreaToForm: (metroArea: string | undefined) => void;
}): {
  isMetroAreasLoading: boolean;
  availableMetroAreas: string[] | null;
  selectAvailableMetroAreasByCountry: (country: string) => string[];
  closestMetroArea: string | undefined;
} => {
  const dispatch = useDispatch();
  const metroAreas = useSelector(metroAreasSelector);
  const [availableMetroAreas, setAvailableMetroAreas] = useState<string[] | null>(null);

  const selectAvailableMetroAreasByCountry = useCallback(
    (country: string): string[] => {
      if (metroAreas.data) {
        return metroAreas.data[country];
      }
      return [];
    },
    [metroAreas.data]
  );

  useEffect(() => {
    dispatch(loadMetroAreas());
  }, []);

  const isGoogleApiLoaded = useSelector(isGoogleApiLoadedSelector);
  const directionsService = useRef<google.maps.DirectionsService | null>(
    isGoogleApiLoaded ? new google.maps.DirectionsService() : null
  );
  const [closestMetroArea, setClosestMetroArea] = useState<string>();
  const [previousCountry, setPreviousCountry] = useState<string | null>(null);

  const setClosestMetroAreaToFormByCondition = useCallback(
    (closestMetroArea: string | undefined) => {
      setClosestMetroArea(closestMetroArea);
      if (previousCountry === null) {
        setPreviousCountry(country);
        return;
      }
      if (country === previousCountry) {
        return;
      }
      setClosestMetroAreaToForm(closestMetroArea);
      setPreviousCountry(country);
    },
    [country, previousCountry]
  );

  // TODO: refactor this hook to use maybe redux-saga
  // TODO: refactor this hook into smaller hooks

  useEffect(() => {
    let closestMetroArea = undefined;
    let availableMetroAreas = null;
    if (metroAreas.state === DataState.Fulfilled && country) {
      availableMetroAreas = selectAvailableMetroAreasByCountry(country);
      setAvailableMetroAreas(availableMetroAreas);
    }
    if (!availableMetroAreas || availableMetroAreas.length === 0 || !isGoogleApiLoaded || !city || !country) {
      setClosestMetroAreaToFormByCondition(METRO_AREA_REMOTE);
      return;
    }
    if (availableMetroAreas.some((metroArea) => metroArea === city)) {
      closestMetroArea = city;
      setClosestMetroAreaToFormByCondition(closestMetroArea);
      return;
    }
    const promises = availableMetroAreas.map((metroArea) => {
      return new Promise<CityTime | null>((resolve, reject) => {
        const tomorrow = addDays(new Date(), 1);
        const tomorrowDate = format(tomorrow, 'yyyy-MM-dd');
        const directionsServiceOptions = {
          destination: metroArea,
          origin: city,
          travelMode: google.maps.TravelMode.DRIVING,
          drivingOptions: {
            departureTime: new Date(`${tomorrowDate}${CET_10_AM_TIME}`),
          },
        };
        directionsService?.current?.route(directionsServiceOptions, (res) => {
          if (res !== null && res?.routes[0]?.legs[0]?.duration !== undefined) {
            const durationTimeToMetroArea = res.routes[0].legs[0].duration.value;
            resolve({
              city: metroArea,
              time: durationTimeToMetroArea,
            });
          } else {
            reject(null);
          }
        });
      });
    });
    Promise.all(promises)
      .then((metroAreas) => {
        const filteredMetroAreasByTravelTimeToThem = metroAreas
          .filter((x): x is CityTime => x !== null)
          .filter((metroArea) => metroArea.time < TWO_HOURS_IN_SECONDS);
        if (filteredMetroAreasByTravelTimeToThem.length > 0) {
          const minDistanceMetroArea = filteredMetroAreasByTravelTimeToThem.reduce((prev, current) =>
            prev.time < current.time ? prev : current
          );
          closestMetroArea = minDistanceMetroArea.city;
        } else {
          closestMetroArea = METRO_AREA_REMOTE;
        }
        setClosestMetroAreaToFormByCondition(closestMetroArea);
      })
      .catch(() => {
        dispatch(displayNotification(`Could not find directions from ${city} to metro areas`));
      });
  }, [metroAreas.state, isGoogleApiLoaded, country, city, previousCountry]);

  return {
    availableMetroAreas,
    isMetroAreasLoading: metroAreas.state === DataState.Pending,
    selectAvailableMetroAreasByCountry,
    closestMetroArea,
  };
};
