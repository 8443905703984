import React, { memo, useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useStyles } from './ResponsesActivityTable.styles';
import { UserRate } from '@modules/HappinessModule/components/UserRate/UserRate';
import { ReactComponent as CircleIcon } from '@assets/icons/circleIcon.svg';
import { ReactComponent as CircleFilledIcon } from '@assets/icons/circleFilledIcon.svg';
import { ResponsesActivityDataItem } from '../../../../interfaces/ResponsesActivityDataItem';
import { ChangesInUserTeam } from '../../../ChangesInUserTeam/ChangesInUserTeam';
import { getTableDataWithTeamChanges } from '@shared/helpers/tableDataWithTeamChanges';
import { ChatAuthor } from '@modules/HappinessModule/pages/ChatPage/enums/ChatAuthor';
import { ChatCommands } from '@modules/HappinessModule/pages/ChatPage/enums/ChatCommands';
import { useDispatch, useSelector } from 'react-redux';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import {
  initialValuesProject,
  initialValuesStructure,
} from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { FeedbackReasonValues } from '@shared/components/Sidebar/components/Feedback/enums/FeedbackReasonsValues';
import { UserHappinessTableActions } from '@modules/HappinessModule/components/UsersHappiness/components/UserHappinessTableActions';
import { NO_MARK_THRESHOLD } from '@modules/HappinessModule/constants/reportConstants';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { useAuth } from '@shared/hooks/useAuth';

interface Props {
  data?: ResponsesActivityDataItem[] | null;
  isDataLoading: boolean;
  user: ReturnType<typeof userSelector>;
}

const ResponsesActivityTable: React.FC<Props> = ({ data, isDataLoading, user }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const userPermissions = useSelector(userPermissionsSelector);
  const isResolveAvailable = userPermissions?.data?.happiness.resolveHappiness;

  const openFeedback = (initialValues: { feedbackText: string; feedbackReason: FeedbackReasonValues }) => {
    dispatch(setIsFeedbackOpen({ isOpen: true, initialValues }));
  };

  const [dataWithChanges, setDataWithChanges] = useState<ResponsesActivityDataItem[] | null | undefined>(null);
  useEffect(() => {
    if (data && data?.length >= 1) {
      setDataWithChanges(getTableDataWithTeamChanges(data));
    }
  }, [data]);

  const tableContent = dataWithChanges?.map((dataItem, i) => {
    const { question, mark, date, reminderCount, changes, comments } = dataItem;
    const filteredComments = comments
      ?.filter(
        ({ author, text }) =>
          !author || (author.toLocaleLowerCase() !== ChatAuthor.Rocky && text !== ChatCommands.Revote)
      )
      .reverse();

    return (
      <tr key={i} className={styles.tableDataRow}>
        <td className={styles.circleSection}>
          {i === 0 ? <CircleFilledIcon /> : <CircleIcon />} <div className={styles.verticalLine}></div>
        </td>
        <td className={styles.mainSection}>
          <p className={styles.questionTitle}>{question}</p>
          <p className={styles.answeredTime}>{format(parseISO(date), 'dd MMM yyyy')}</p>
          <div className={styles.rateContainer}>
            <div className={styles.rateLine}>
              <UserRate
                reminderCount={reminderCount}
                rate={mark}
                userHappinessActions={
                  user &&
                  user.id !== currentUser?.id && (
                    <UserHappinessTableActions
                      hideActions={{
                        schedule: true,
                        email: true,
                        comments: true,
                      }}
                      isResolveAvailable={isResolveAvailable ?? false}
                      userHappinessTableDataItem={{
                        name: user.name,
                        mark: dataItem.mark ?? NO_MARK_THRESHOLD,
                        email: user.email,
                        userId: user.id,
                        comments: dataItem.comments,
                        resolvedBy: dataItem.resolvedBy,
                        flag: dataItem.flag,
                        id: dataItem.id,
                      }}
                      currentQuestion={{
                        id: dataItem.id,
                        questionText: dataItem.question,
                        date: dataItem.date,
                      }}
                    />
                  )
                }
              />
            </div>
          </div>

          {filteredComments?.length === 0 || !filteredComments?.length ? null : (
            <>
              <p className={styles.commentsTitle}>Comments:</p>
              {filteredComments?.map((comment, i) => {
                return (
                  <div key={i}>
                    <p className={styles.commentTime}>
                      {format(parseISO(`${comment.ts}Z`), 'dd MMM yyyy hh:mm aaa')} :
                    </p>
                    <p className={styles.commentText}>{comment.text}</p>
                  </div>
                );
              })}
            </>
          )}
          {(changes?.addedPrimaryRT ||
            changes?.deletedPrimaryRT ||
            changes?.addedSecondaryRT ||
            changes?.deletedSecondaryRT ||
            changes?.addedTeam ||
            changes?.deletedTeam) && (
            <>
              <ChangesInUserTeam changes={changes} />
              {changes?.addedTeam || changes?.deletedTeam ? (
                <span className={styles.reportIssue} onClick={() => openFeedback(initialValuesProject)}>
                  Report incorrect project
                </span>
              ) : (
                <span className={styles.reportIssue} onClick={() => openFeedback(initialValuesStructure)}>
                  Report incorrect "Reporting to"
                </span>
              )}
            </>
          )}
        </td>
      </tr>
    );
  });

  const skeleton = (
    <tr>
      <td>
        <Skeleton className={styles.rowSkeleton} count={5} />
      </td>
    </tr>
  );

  return (
    <div className={styles.root}>
      <table className={styles.usersHappinessTable} cellSpacing={0}>
        <tbody>{isDataLoading ? skeleton : tableContent}</tbody>
      </table>
    </div>
  );
};

export default memo(ResponsesActivityTable);
