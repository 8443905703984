import { FC, useState } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { useStyles } from './WhatsFlagReasonBlock.styles';
import { FlagFormFields } from '../../constants/FlagFormFields';
import { MainReasonSelectFormik } from './components/ReasonSelectFormik/components/MainReasonSelectFormik';
import { SubReasonSelectFormik } from './components/ReasonSelectFormik/components/SubReasonSelectFormik';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagReason } from '@modules/HappinessModule/enums/FlagsReasons';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { UserInfo } from '@shared/interfaces/UserInfo';
import AffectedProjectsSelectFormik from './components/ReasonSelectFormik/components/AffectedProjectsSelectFormik/AffectedProjectsSelectFormik';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { Button } from '@mui/material';
import { DuplicateFlagModal } from '@modules/HappinessModule/components/DuplicateFlagModal';

interface Props {
  userData: UserInfo;
  isNewFlag: boolean;
  userFlagPermissions: FlagPermissionsData;
  flagId: string;
}

export const WhatsFlagReasonBlock: FC<Props> = ({ userData, isNewFlag, userFlagPermissions, flagId }) => {
  const styles = useStyles();
  const { values, errors, submitCount } = useFormikContext<FlagFields>();
  const isMainFlagInformationEditable =
    (userFlagPermissions.flag.isUpdatable && !isNewFlag) || (userFlagPermissions.flag.isCreatable && isNewFlag);
  const [duplicateFlagId, setDuplicateFlagId] = useState<string | null>(null);

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Grid container spacing={2} className={styles.flagReasonBlock}>
            <Grid item xs={9} marginBottom="35px">
              <Stack flexDirection="row" columnGap="10px" alignItems="center">
                <RockyAvatar medium imageUrl={userData.imageUrl} fullName={userData.name}></RockyAvatar>
                <Stack flexDirection="column">
                  <Typography variant="body2">Flag for {userData.name}</Typography>
                  <Typography variant="h2">What's the flag reason?</Typography>
                </Stack>
              </Stack>
            </Grid>
            {!isNewFlag && isMainFlagInformationEditable && (
              <Grid item xs={3}>
                <Stack>
                  <Button
                    onClick={() => setDuplicateFlagId(flagId)}
                    sx={{
                      alignSelf: 'flex-end',
                    }}
                  >
                    Duplicate flag
                  </Button>
                </Stack>
              </Grid>
            )}
            <Grid item xs={12}>
              <Grid container spacing={4}>
                <Grid item xs={6}>
                  <MainReasonSelectFormik
                    name={FlagFormFields.primaryTrigger.name}
                    label={FlagFormFields.primaryTrigger.label}
                    error={!!(errors.primaryTrigger && submitCount)}
                    disabled={!isMainFlagInformationEditable}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <SubReasonSelectFormik
                    name={FlagFormFields.primarySubReason.name}
                    label={FlagFormFields.primarySubReason.label}
                    disabled={
                      !isMainFlagInformationEditable ||
                      !values.primaryTrigger ||
                      values.primaryTrigger === FlagReason.PassedAway
                    }
                    required
                    reason={values.primaryTrigger}
                    error={!!(errors.primarySubReason && submitCount)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <MainReasonSelectFormik
                    disabled={!isMainFlagInformationEditable}
                    name={FlagFormFields.secondaryTrigger.name}
                    label={FlagFormFields.secondaryTrigger.label}
                    placeholder="Please select"
                    error={!!(errors.secondaryTrigger && submitCount)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <SubReasonSelectFormik
                    name={FlagFormFields.secondarySubReason.name}
                    label={FlagFormFields.secondarySubReason.label}
                    placeholder="Please select"
                    disabled={
                      !isMainFlagInformationEditable ||
                      !values.secondaryTrigger ||
                      values.secondaryTrigger === FlagReason.PassedAway ||
                      values.secondaryTrigger === FlagReason.None
                    }
                    reason={values.secondaryTrigger}
                    error={!!(errors.secondarySubReason && submitCount)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field name={FlagFormFields.description.name}>
                    {({ field }: FieldProps) => (
                      <TextField
                        size="medium"
                        disabled={!isMainFlagInformationEditable}
                        label={FlagFormFields.description.label}
                        multiline
                        minRows={4}
                        variant="outlined"
                        required
                        fullWidth
                        error={!!(errors.description && submitCount)}
                        {...field}
                        value={field.value ?? ''}
                      />
                    )}
                  </Field>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12} lg={6}>
              <AffectedProjectsSelectFormik
                isNewFlag={isNewFlag}
                userData={userData}
                disabled={!isMainFlagInformationEditable}
              />
            </Grid>

            <Grid item xs={12} lg={6} display="flex" alignItems="start">
              <Field
                name={FlagFormFields.isEscalatedByClient.name}
                type="checkbox"
                component={({ field }: FieldProps) => (
                  <FormControlLabel
                    disabled={!isMainFlagInformationEditable}
                    control={<Checkbox {...field} />}
                    label={FlagFormFields.isEscalatedByClient.label}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid className={styles.flagInfoBlock} item xs={4}>
          <Stack rowGap="10px">
            <Stack flexDirection="row" columnGap="10px" alignItems="center">
              <Typography variant="subtitle1">
                Use the{' '}
                <Link
                  target="_blank"
                  href="https://docs.google.com/spreadsheets/d/1iZdcNuSHJ68T6F1ekwtrrA4M9x_QEBZ4_KI8kxOBzHI/edit#gid=0"
                >
                  flag script ↗<br />
                </Link>
                to obtain more information
              </Typography>
              <div className={styles.flagInfoBlockIcon}>🕵️</div>
            </Stack>
            <Typography variant="body2">Details about person's concern.</Typography>
            <Typography variant="body2"> What can we offer as a company for them to stay.</Typography>
            <Typography variant="body2">
              Focus this person on the positive sides of our company and benefits of staying.
            </Typography>
            <Typography variant="body2">Make it clear that they are a valuable member for us.</Typography>
            <Typography variant="body2">
              Make it clear that we need time to fulfil arrangements regarding the counter-offer.
            </Typography>
            <Typography variant="body2">Set deadlines.</Typography>
            <Stack flexDirection="row" columnGap="16px">
              {[
                {
                  label: 'How to',
                  value: 'https://docs.google.com/document/d/1uGDWXd5uCmutBruREYg4Ev6c8a5_AHTzrPO5GMXvYbk/edit',
                },

                {
                  label: 'Escalation process',
                  value: 'https://docs.google.com/document/d/1HB_mV23r4iE5WeKWxQrai1A4MWCCF7uAfQDXJzjxOKE/edit',
                },
                {
                  label: 'Flag reasons',
                  value:
                    'https://docs.google.com/spreadsheets/d/1P_fyTvqFWQaU21DmPGlL-y9sA-kUcndg2kpSqq518f4/edit#gid=0',
                },
              ].map((link) => (
                <Link target="_blank" href={link.value} key={link.label}>
                  {link.label}
                </Link>
              ))}
              <Typography variant="subtitle1"></Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      {duplicateFlagId && (
        <DuplicateFlagModal duplicateFlagId={duplicateFlagId} handleClose={() => setDuplicateFlagId(null)} />
      )}
    </Paper>
  );
};
