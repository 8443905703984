import { put, all, call, takeLatest, select } from 'redux-saga/effects';

import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import {
  setPeerEvalData,
  getPeerEvalData,
  savePeeEvalData,
  setReviewPeerFeedback,
  getPeerEvalPermissions,
} from './actions';
import { setPeerEvalDataLoading, setPeerEvalFeedbackPermissions, setPeerEvalSubmitted } from './reducers';
import { DataState } from '@shared/enums/DataState';
import {
  fetchPeerEvalData,
  fetchPeerFeedbackPermissions,
  postPeerEvalData,
  reviewPeerFeedback,
} from '@modules/EvaluationModule/api/peerEvalData';
import { PeerFormData } from '@modules/EvaluationModule/interfaces/PeerEvalData';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { RootState } from '@modules/App/redux/store';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { PermissionAction } from '@modules/HappinessModule/enums/PermissionAction';

function* getPeerEvalDataAsync({ payload }: ReturnType<typeof getPeerEvalData>) {
  try {
    const setLoadingAction = setPeerEvalDataLoading();
    yield put(setLoadingAction);

    const response: PeerFormData = yield call(fetchPeerEvalData, payload);
    const action = setPeerEvalData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setPeerEvalData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* savePeeEvalDataAsync({ payload }: ReturnType<typeof savePeeEvalData>) {
  try {
    const { evaluation, isAnonymous } = payload;
    if (evaluation.isConfirmed) {
      const peerEvalData: PeerFormData = yield select(
        (state: RootState) => state.evaluation.peerEvalPage.peerEvalData.data
      );
      const mappedData = {
        ...peerEvalData,
        evaluation: {
          ...evaluation,
        },
        isAnonymous,
      };
      yield put(setPeerEvalData({ data: mappedData, state: DataState.Fulfilled }));
    }
    yield call(postPeerEvalData, payload);
    yield put(setPeerEvalSubmitted());
  } catch {
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* setReviewPeerFeedbackAsync({ payload }: ReturnType<typeof setReviewPeerFeedback>) {
  try {
    yield call(reviewPeerFeedback, payload);
    const peerEvalData: PeerFormData = yield select(
      (state: RootState) => state.evaluation.peerEvalPage.peerEvalData.data
    );
    const mappedData = {
      ...peerEvalData,
      status: null,
      reviewerNotes: payload.reviewerNotes,
    };
    const action = setPeerEvalData({ data: mappedData, state: DataState.Fulfilled });
    yield put(action);
    if (payload.status === EvalFeedbackStatus.Approved) {
      yield put(displayNotification('Review approved.'));
    } else if (payload.status === EvalFeedbackStatus.Rejected) {
      yield put(displayNotification('Review rejected.'));
    }
  } catch {
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* getPeerEvalPermissionsAsync({ payload }: ReturnType<typeof getPeerEvalPermissions>) {
  try {
    const response: PermissionAction[] = yield call(fetchPeerFeedbackPermissions, payload);
    const setAllEvalsPermissionsAction = setPeerEvalFeedbackPermissions({
      data: response,
      response: DataState.Fulfilled,
    });
    yield put(setAllEvalsPermissionsAction);
  } catch (e) {
    const setPermissionsErrorAction = setPeerEvalFeedbackPermissions({
      data: null,
      response: DataState.Rejected,
      error: getErrorInfo(e),
    });
    yield put(setPermissionsErrorAction);
  }
}

function* watchGetUserEvalData() {
  yield takeLatest(getPeerEvalData.type, getPeerEvalDataAsync);
  yield takeLatest(savePeeEvalData.type, savePeeEvalDataAsync);
  yield takeLatest(setReviewPeerFeedback.type, setReviewPeerFeedbackAsync);
  yield takeLatest(getPeerEvalPermissions.type, getPeerEvalPermissionsAsync);
}

export function* peerEvalPageSaga(): Generator {
  yield all([watchGetUserEvalData()]);
}
