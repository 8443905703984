import React, { FC, memo, useState } from 'react';
import { CustomRadioInput } from '@shared/components/CustomRadioInput/CustomRadioInput';
import { Field } from 'formik';
import { useStyles } from './EvalQuestionBlock.styles';
import classNames from 'classnames';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { EvalQuestionDescription } from './components/EvalQuestionDescription';
import { Textarea } from '@shared/components/Textarea';
import { markValues } from '@modules/EvaluationModule/pages/EvalPage/constants/markValues';

interface Props {
  questionTitle: string;
  questionMark?: string;
  questionPlaceholder: string;
  questionComments?: string;
  questionDescription?: string[];
  questionName?: string | null;
  error?: string;
  emptyCommentError?: string;
  disabled: boolean;
}

const EvalQuestionBlockComponent: FC<Props> = ({
  questionTitle,
  questionMark,
  questionPlaceholder,
  questionComments,
  questionDescription,
  questionName,
  error,
  emptyCommentError,
  disabled,
}) => {
  const [isDialogWindowOpen, setIsDialogWindowOpen] = useState(false);
  const styles = useStyles();

  return (
    <>
      {questionName && (
        <div>
          <div
            className={classNames(styles.dialogWindowOverlay, { [styles.hidden]: !isDialogWindowOpen })}
            onClick={() => setIsDialogWindowOpen(false)}
          ></div>
          <div className={styles.titleBlock}>
            <div className={styles.questionBlockTitleWrapper}>
              <p className={styles.questionBlockTitle} onClick={() => setIsDialogWindowOpen(false)}>
                {questionTitle}
              </p>
              {questionDescription && (
                <div
                  className={classNames(styles.infoIcon, { [styles.infoIconActive]: isDialogWindowOpen })}
                  onClick={() => setIsDialogWindowOpen(true)}
                >
                  <HelpOutlineIcon />
                  <div className={classNames(styles.dialogWindow, { [styles.hidden]: !isDialogWindowOpen })}>
                    <EvalQuestionDescription questionName={questionName} questionDescription={questionDescription} />
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className={styles.answerBlockWrapper}>
            <div className={classNames(styles.radioGroup, { [styles.radioGroupError]: error })}>
              {questionMark &&
                markValues.map(({ title, value }, index) => (
                  <Field
                    key={index}
                    component={CustomRadioInput}
                    title={title}
                    name={questionMark}
                    value={value}
                    disabled={disabled}
                    className={classNames({ [styles.errorRadio]: emptyCommentError })}
                  />
                ))}
            </div>
            <div>
              <Field
                component={Textarea}
                className={classNames(styles.answerBlockComments, {
                  [styles.answerBlockCommentsExtended]: questionTitle === 'Achievements',
                })}
                isAutosize={true}
                placeholder={questionPlaceholder}
                maxLength={4096}
                name={questionComments}
                disabled={disabled}
              />
              <div className={styles.errorMsg}>{error && <div>{error}</div>}</div>
              <div className={styles.errorMsg}>{emptyCommentError && <div>{emptyCommentError}</div>}</div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const EvalQuestionBlock = memo(EvalQuestionBlockComponent);
