import React, { useCallback, useEffect, useState } from 'react';
import { useStyles } from './Sidebar.styles';
import { User } from '@shared/interfaces/user';
import { SidebarContent } from './components/SidebarContent';
import classNames from 'classnames';
import { SCREEN_WIDTH } from '@styles/breakpoints';
import throttle from 'lodash/throttle';
export interface Props {
  currentUser: User;
}

export const Sidebar: React.FC<Props> = ({ currentUser }) => {
  const styles = useStyles();
  const [isTabletMenuOpen, setIsTabletMenuOpen] = useState(false);

  const handleWindowResize = useCallback(
    throttle(() => {
      if (window.innerWidth < SCREEN_WIDTH.MOBILE || window.innerWidth > SCREEN_WIDTH.DESKTOP) {
        setIsTabletMenuOpen(false);
      }
    }, 10),
    [setIsTabletMenuOpen]
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  const handleCloseClick = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      if ((e.target as HTMLDivElement).id === 'tablet-overlay') {
        setIsTabletMenuOpen(false);
      }
    },
    [setIsTabletMenuOpen]
  );

  return (
    <>
      <div className={classNames(styles.root, { [styles.rootOpen]: isTabletMenuOpen })}>
        <div className={classNames(styles.container, { [styles.containerOpen]: isTabletMenuOpen })}>
          <SidebarContent
            currentUser={currentUser}
            setIsTabletMenuOpen={setIsTabletMenuOpen}
            isTabletMenuOpen={isTabletMenuOpen}
          />
        </div>
      </div>
      {isTabletMenuOpen && (
        <>
          <div id="tablet-overlay" className={styles.tabletOverley} onClick={handleCloseClick}></div>
          <div className={styles.plug}></div>
        </>
      )}
    </>
  );
};
