import React, { ChangeEvent } from 'react';
import { useStyles } from './FiltersRadioButtons.styles';
import { Field } from 'formik';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { ValueLabelObject } from '../../interfaces/ValueLabelObject';
import { FlagSeverity } from '@modules/HappinessModule/enums/FlagSeverity';
import { ReactComponent as RedFlagIcon } from '@assets/icons/redFlagIcon.svg';
import { ReactComponent as YellowFlagIcon } from '@assets/icons/yellowFlagIcon.svg';
import classnames from 'classnames';

interface Props {
  handleChange: (e: ChangeEvent<HTMLInputElement>, fieldName: string) => void;
  labelValueObject?: ValueLabelObject;
  name: string;
  row?: boolean;
  severity?: boolean;
}

const RadioBtn = (
  <Radio
    sx={{
      '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
    }}
  />
);

export const FiltersRadioButtons: React.FC<Props> = ({ handleChange, labelValueObject, name, row, severity }) => {
  const styles = useStyles();

  return (
    <Field
      name={name}
      row={row}
      as={RadioGroup}
      className={classnames(styles.radioGroup, { [styles.rowRadioBtn]: row })}
      onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e, name)}
    >
      {!severity && labelValueObject ? (
        Object.values(labelValueObject).map((value, i) => {
          return <FormControlLabel key={i} value={value.value} defaultChecked control={RadioBtn} label={value.label} />;
        })
      ) : (
        <>
          <FormControlLabel value="all" control={RadioBtn} label="All" />
          <FormControlLabel
            value={FlagSeverity.red}
            control={RadioBtn}
            label={
              <div className={styles.radioLabel}>
                <RedFlagIcon /> Red
              </div>
            }
          />
          <FormControlLabel
            value={FlagSeverity.yellow}
            control={RadioBtn}
            label={
              <div className={styles.radioLabel}>
                <YellowFlagIcon /> Yellow
              </div>
            }
          />
        </>
      )}
    </Field>
  );
};
