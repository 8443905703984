import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { COLORS } from '@styles/colors';

interface Props {
  currentReaction: number | null;
  previousReaction: number | null;
}

export const PeopleHappinessVsPreviousReaction: FC<Props> = ({ currentReaction, previousReaction }) => {
  const reactionChange = currentReaction && previousReaction ? currentReaction - previousReaction : null;
  const signElement = (condition: boolean) => <span>{condition ? '+' : '-'}</span>;

  return (
    <>
      {reactionChange ? (
        <Typography
          style={{
            color:
              reactionChange > 0
                ? COLORS.STATUS.SUCCESS
                : reactionChange === 0
                ? COLORS.TEXT.SECONDARY
                : COLORS.STATUS.CRITICAL,
          }}
        >
          {signElement(reactionChange > 0)}
          {Math.abs(reactionChange).toFixed(2)}
        </Typography>
      ) : (
        <Typography fontSize="14px" paddingLeft="12px">
          —
        </Typography>
      )}
    </>
  );
};
