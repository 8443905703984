import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useMyPeopleEvalData } from './hooks/useMyPeopleEvalData';
import { MyTeamEvalReport } from '@modules/EvaluationModule/components/MyTeamEvalReport/MyTeamEvalReport';
import { MyPeopleEvalReportTable } from '@modules/EvaluationModule/components/MyTeamEvalReport/components/MyPeopleEvalReportTable/MyPeopleEvalReportTable';
import { DataState } from '@shared/enums/DataState';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { getMyPeopleEvalData } from './redux/actions';
import { MY_PEOPLE_EVALS_FETCH_LIMIT } from './constants/pagination';
import { Autocomplete, TextField } from '@mui/material';
import { EvalFilters } from './enums/EvalFilters';

export const MyPeopleEvalPage: React.FC = () => {
  const dispatch = useDispatch();
  const { myPeopleEvalData } = useMyPeopleEvalData();
  const isDataLoading = myPeopleEvalData.state === DataState.Pending;
  const setPageTitle = useTitle();
  const dataLength = myPeopleEvalData.data ? myPeopleEvalData.data.length : 0;
  const [statusFilter, setStatusFilter] = useState<EvalFilters | null>(null);

  useEffect(() => {
    setPageTitle(pageTitles.myPeopleEvalPage);
  }, []);

  useEffect(() => {
    dispatch(
      getMyPeopleEvalData({
        offset: 0,
        limit: MY_PEOPLE_EVALS_FETCH_LIMIT,
        status: statusFilter ? statusFilter.toLowerCase() : undefined,
      })
    );
  }, [statusFilter, getMyPeopleEvalData]);

  const getMoreData = useCallback(
    (offset = 0) => {
      dispatch(
        getMyPeopleEvalData({
          offset,
          limit: MY_PEOPLE_EVALS_FETCH_LIMIT,
          status: statusFilter ? statusFilter.toLowerCase() : undefined,
        })
      );
    },
    [statusFilter, getMyPeopleEvalData]
  );

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      if (myPeopleEvalData && stopIndex > dataLength) {
        getMoreData && getMoreData(startIndex);
      }
    },
    [myPeopleEvalData, getMoreData]
  );

  const filter = (
    <Autocomplete
      disablePortal
      size="small"
      options={[EvalFilters.Upcoming, EvalFilters.Ongoing, EvalFilters.Overdue]}
      value={statusFilter}
      onChange={(_, value) => setStatusFilter(value)}
      sx={{ width: 150 }}
      renderInput={(params) => (
        <TextField {...params} label="Filter" placeholder="Status" InputLabelProps={{ shrink: true }} />
      )}
    />
  );

  return (
    <MyTeamEvalReport
      statusFilter={statusFilter ?? undefined}
      tableTitle="My people evaluations"
      myTeamEvalData={myPeopleEvalData}
      sideBlock={filter}
    >
      <MyPeopleEvalReportTable
        data={myPeopleEvalData.data}
        isDataLoading={isDataLoading}
        loadMoreItems={loadMoreItems}
      ></MyPeopleEvalReportTable>
    </MyTeamEvalReport>
  );
};
