import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 12,
    margin: '24px 8px 0 14px',
    alignItems: 'start',
    boxSizing: 'border-box',
    paddingBottom: 12,
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    minHeight: 40,

    [BREAKPOINTS.TABLET]: {
      borderBottom: 'none',
    },

    [BREAKPOINTS.MOBILE]: {
      margin: '12px 16px 0 0',
    },
  },
  userInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,

    [BREAKPOINTS.TABLET]: {
      display: 'none',
    },
  },
  name: {
    fontSize: '14px',
    fontWeight: '400',
    margin: 0,
    color: COLORS.TEXT.PRIMARY,
  },
  role: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
    margin: 0,
  },
  shown: {
    display: 'flex',
  },
  link: {
    textDecoration: 'none',

    '& img, & p': {
      transition: 'all 0.2s linear',
    },

    '&:hover': {
      '& p:first-child': {
        color: COLORS.ACTION.PRIMARY,
      },
      '& img': {
        transform: 'scale(1.05)',
      },
    },
  },
});
