import React, { memo } from 'react';
import { useStyles } from './EvalSuccessCloseModal.module';
import { RockyModal } from '@shared/components/RockyModal';
import { RockyButton } from '@shared/components/RockyButton';
import { ReactComponent as CaringHeart } from '@assets/icons/CaringHeart.svg';
import { useNavigate } from 'react-router';
import { paths } from '@shared/enums/paths';
import { Role } from '@shared/enums/role';
import { User } from '@shared/interfaces/user';
import { EopResult } from '@modules/EvaluationModule/enums/EopResult';

interface Props {
  closeModal: () => void;
  isModalOpen: boolean;
  currentUser: User | null;
  eopResult: EopResult | null | undefined;
}

const EvalSuccessCloseModalComponent: React.FC<Props> = ({ closeModal, eopResult, isModalOpen, currentUser }) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const navigatePath =
    currentUser?.rockyRoles.includes(Role.peoplePartner) || currentUser?.rockyRoles.includes(Role.hr)
      ? `${paths.myPeopleEvals}`
      : `${paths.myTeam}/${paths.evaluations}`;

  const redirectToMyTeam = () => navigate(navigatePath);

  return (
    <RockyModal
      onClose={closeModal}
      isOpen={isModalOpen}
      title=""
      modalHeaderStyles={styles.modalHeaderStyles}
      modalBodyStyles={styles.modalBodyStyles}
      crossIcon={true}
    >
      <div>
        <div className={styles.svgContainer}>
          <CaringHeart />
        </div>
        <p className={styles.modalMainText}>
          {!eopResult && (
            <>
              Cool cool cool! <br />
              This evaluation is now closed
            </>
          )}
          {eopResult === EopResult.Extended && <>This evaluation has been extended</>}
          {eopResult === EopResult.NotPassed && <>This evaluation is now closed</>}
          {eopResult === EopResult.Passed && (
            <>
              Cool cool cool! <br />
              End of probation is passed successfully!
            </>
          )}
        </p>
        {!eopResult && <p className={styles.modalAddText}>See you all in 6 months!</p>}
      </div>
      <div className={styles.modalBtnContainer}>
        <RockyButton action className={styles.button} onClick={redirectToMyTeam}>
          Back to My team
        </RockyButton>
      </div>
    </RockyModal>
  );
};

export const EvalSuccessCloseModal = memo(EvalSuccessCloseModalComponent);
