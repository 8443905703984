import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    borderRadius: 8,
    display: 'flex',
    flexGrow: 1,
    minWidth: 300,
    width: '100%',
    maxHeight: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
  },
  header: {
    width: '100%',
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    [BREAKPOINTS.MOBILE]: {
      padding: '0 16px',
    },
  },
  titleContainer: {
    minHeight: 64,
    margin: 0,
    display: 'flex',
    alignItems: 'center',
    padding: '16px 0',
    justifyContent: 'space-between',
    width: '100%',
  },
  headerTail: {
    display: 'flex',
    alignItems: 'start',
    columnGap: 5,
    fontWeight: '500',
    background: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    height: '32px',
    padding: '5px 12px 6px',
    borderRadius: 4,
    textDecoration: 'none',

    '& svg': {
      marginTop: '1px',
    },

    '&:hover': {
      background: COLORS.ACTION.DARK,
    },
  },
  title: {
    fontSize: '24px',
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '500',
    marginRight: 16,
  },
  titleSkeleton: {
    width: 250,
  },
  responsesCount: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    marginLeft: 6,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  headerTailSkeleton: {
    width: 100,
  },
  headerTailText: {
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  popup: {
    '&-content': {
      width: 'auto !important',
      boxShadow: '0 4px 12px rgb(180 180 180 / 60%) !important',
      color: COLORS.TEXT.PRIMARY,
      fontWeight: '400',
      fontSize: '14px',
      padding: '5px 10px',
      background: COLORS.WHITE,

      [BREAKPOINTS.DESKTOP]: {
        display: 'none',
      },
    },
    '&-arrow': {
      color: COLORS.WHITE,
    },
  },
});
