import { RootState } from '@modules/App/redux/store';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { AsyncData } from '@shared/interfaces/asyncData';
import { HappinessData } from '@modules/HappinessModule/interfaces/happinessData';
import { UsersHappinessDataItem } from '@modules/HappinessModule/interfaces/usersHappinessDataItem';
import { createGetSortedIndividualTableDataSelector } from '@modules/HappinessModule/redux/selectors';
import { QuestionData } from '@modules/HappinessModule/interfaces/questionData';

const getSortInfo = (state: RootState): SortInfo => state.happiness.myTeamHappinessPage.sortInfo;
const getMyTeamHappinessData = (state: RootState): AsyncData<HappinessData<UsersHappinessDataItem>> =>
  state.happiness.myTeamHappinessPage.myTeamHappinessData;

export const getSortedTableData = createGetSortedIndividualTableDataSelector(getMyTeamHappinessData, getSortInfo);

export const myTeamHappinessQuestionDataSelector = (state: RootState): QuestionData | undefined =>
  state.happiness.myTeamHappinessPage.myTeamHappinessData.data?.question;

export const sortMyTeamHappinessPageInfoSelector = (state: RootState): SortInfo =>
  state.happiness.myTeamHappinessPage.sortInfo;
