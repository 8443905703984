import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from '@modules/App/redux/permissions/actions';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserActionsPermissions } from '@modules/App/interfaces/UserActionsPermissions';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';

export const useUserPermissions = ({ id }: { id?: string }): AsyncData<UserActionsPermissions> => {
  const dispatch = useDispatch();
  const userActionsPermissions = useSelector(userPermissionsSelector);

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getPermissions(id));
  }, [id]);

  return userActionsPermissions;
};
