import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    color: COLORS.TEXT.PRIMARY,
    fontSize: '14px',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    backgroundColor: COLORS.WHITE,
    width: 250,
  },
  trigger: {
    cursor: 'pointer',
    fill: COLORS.TEXT.DISABLED,

    '& svg': {
      width: 16,
    },
  },
  header: {
    display: 'flex',
    columnGap: 24,
    padding: '9px 10px 9px 16px',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 500,
  },
  title: {
    fontWeight: '500',
  },
  divider: {
    width: '100%',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  body: {
    padding: '12px 16px',
  },
  popup: {
    '&-content': {
      padding: 0,
      width: 'fit-content !important',
      height: 'fit-content !important',
      maxWidth: 500,
      maxHeight: 300,
      overflow: 'auto',
      overflowX: 'hidden',
      overflowWrap: 'break-word',
      marginRight: 50,
      marginBottom: 30,
    },
  },
});
