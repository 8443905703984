import { FlagReason, FlagSubreason } from '@modules/HappinessModule/enums/FlagsReasons';

export interface FlagReasonOptionsData {
  label: string;
  description: string;
  options: {
    value: FlagReason;
    description: string;
    subReasons: { value: FlagSubreason; description: string }[] | null;
  }[];
}
export interface FlagReasonsOptionsData {
  voluntary: FlagReasonOptionsData;
  involuntary: FlagReasonOptionsData;
}

export const flagReasonsOptions: FlagReasonsOptionsData = {
  voluntary: {
    label: 'Teammate initiated',
    description:
      'Attrition risk case generated when an AgileEngineer willingly raises a concern that jeopardizes the continuity of their collaboration with AE.\n\nIt can be caused both by dissatisfaction with internal practices/conditions while working with AE or its clients, or external circumstances that are more attractive or prevent them from continuing to collaborate with AE.',
    options: [
      {
        value: FlagReason.JobOfferPackage,
        description:
          'Motives related to a job offer specifics (current AE offer/new offer), which includes compensation, benefits and additional perks that a company provides their collaborators with.',
        subReasons: [
          {
            value: FlagSubreason.Compensation,
            description: 'Base and variable monetary compensation.',
          },
          {
            value: FlagSubreason.ContractConditions,
            description:
              "Perks, benefits, policies, legal/tax compliance (e.g.: LLC)\n\n*LLC: those cases where teammate voluntary decides to leave AE because they don't agree with change of tax policy\n*Relocation: those cases where teammate decides to accept eternal offer to relocate, mainly for higher compensation",
          },
        ],
      },
      {
        value: FlagReason.Relocation,
        description: 'Desire/Opportunity of travelling or relocating to another country',
        subReasons: [
          {
            value: FlagSubreason.Personal,
            description:
              'Desire/Oportunity of travelling or relocating to another country for personal reasons, not associated to a job opportunity.',
          },
          {
            value: FlagSubreason.Professional,
            description:
              'Desire/Opportunity of formally/legally work from another country.\n\n*Those cases where teammate decides to accept external offer to relocate, mainly for relocation itself, besides receiving better contract condictions (e.g.: higher compensation - this can be use as secondary reason)',
          },
        ],
      },
      {
        value: FlagReason.ProjectDepartmentV,
        description:
          'Motives related to the professional environment and development opportunities a company offers to its collaborators.',
        subReasons: [
          {
            value: FlagSubreason.TechnologyStack,
            description:
              'Combination of technologies, programming languages, frameworks, databases, tools and/or applications the company/project uses.',
          },
          {
            value: FlagSubreason.ContentTasks,
            description: 'Responsibilities under the scope of an specific role/person.',
          },
          {
            value: FlagSubreason.LeadTeam,
            description:
              'Group of individuals the collaborator works directly/has continuous interaction with. This includes both teammates/coworkers and leaders/superiors. ',
          },
          {
            value: FlagSubreason.ProfessionalDevelopment,
            description:
              'Opportunities/support provided to the collaborator by AE/client to promote their professional growth. This includes coaching, mentoring, skills development, networking and career pathing.',
          },
          {
            value: FlagSubreason.ClientDepartment,
            description: "AE's/client's environment, culture, practices, processes, values and internal dynamics.",
          },
        ],
      },
      {
        value: FlagReason.NewHomeV,
        description: "Unsuccessful placement on a new project due to collaborator's decision to quit the NH process.",
        subReasons: [
          {
            value: FlagSubreason.LeftBeforeAssignment,
            description:
              "Dissatisfaction with AE's NH process that could be caused by:\n- Discomfort linked to the uncertainty of not being assigned to any project / receiving regular compensation.\n- Lack of positions/opportunities that match the collaborator's profile or interests.",
          },
        ],
      },
      {
        value: FlagReason.Personal,
        description:
          "Motives related to a collaborator's individual experiences/circumstances that impact their possibility/willingness to work for the company.",
        subReasons: [
          {
            value: FlagSubreason.OwnBusinessStudies,
            description: "Collaborator's individual professional or educational projects.",
          },
          {
            value: FlagSubreason.Health,
            description:
              "Physical, mental, or emotional condition that adversely affect the collaborator's safety, wellness or possibility of providing working services as usual. This may include both personal or relatives' cases.",
          },
          {
            value: FlagSubreason.MilitaryService,
            description: 'The person is going to serve in the army.',
          },
        ],
      },
    ],
  },
  involuntary: {
    label: 'Manager or client initiated',
    description:
      "Attrition risk case generated when a concern is raised by AgileEngine or one of our clients that jeopardizes the continuity of an AgileEngineer's collaboration with AE.",
    options: [
      {
        value: FlagReason.PassedAway,
        description: "Collaborator's death",
        subReasons: null,
      },
      {
        value: FlagReason.CulturalMismatch,
        description:
          "The collaborator shows lack of adaptability towards AE's/the client's practices, values and internal dynamics.",
        subReasons: [
          {
            value: FlagSubreason.JobAbandonment,
            description:
              "The collaborator doesn't report to work as scheduled, has no intention of returning to the job and doesn't notify AE/the client of their intention to quit.",
          },
          {
            value: FlagSubreason.LegalCompliance,
            description:
              'Not compliance with legal/contractual approach, internal policies, etc.\n\n*LLC: those cases where teammate wants to stay in AE but we need to move forward with termination due to them not being disposed to become tax compliant',
          },
        ],
      },
      {
        value: FlagReason.ProjectDepartmentI,
        description:
          "Motives related to circumstances beyond the collaborator's scope of action, that imply the inability of the company to continue the working relationship.",
        subReasons: [
          {
            value: FlagSubreason.BudgetIssues,
            description: 'Lack or insufficient budget from the company or client to compensate the employee.',
          },
          {
            value: FlagSubreason.EndProject,
            description: 'Project closure based on a previously defined timeline/plan.',
          },
        ],
      },
      {
        value: FlagReason.NewHomeI,
        description:
          'Unsuccessful placement of the collaborator on a new project due to lack of opportunities or skills.',
        subReasons: [
          {
            value: FlagSubreason.NoMatchingPositions,
            description: 'Lack of opportunities to reassign a collaborator in a new project by the NewHome process.',
          },

          {
            value: FlagSubreason.FailedInterviews,
            description:
              'Client or technical interviews failed by the AgileEngineer after starting a NewHome process that jeopardizes their possibility of being assigned to a new project.',
          },
        ],
      },
      {
        value: FlagReason.Performance,
        description:
          "The collaborator's performance is not aligned with the expectations of the client/lead/team for their role/seniority. The mismatch might be related to the quality, quantity or speed of the work the person is doing.",
        subReasons: [
          {
            value: FlagSubreason.HardSkills,
            description: "Performance issues in skills related to the role's technical responsibilities.",
          },
          {
            value: FlagSubreason.SoftSkills,
            description:
              'Performance issues in skills related to attitude, communication, commitment, people management, etc.',
          },
          {
            value: FlagSubreason.HardAndSoftSkills,
            description:
              "Performance issues in skills related to both the role's techinical responsibilities and the attitude, communication, commitment, people management, etc.",
          },
        ],
      },
    ],
  },
};
