import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    marginTop: 48,
  },
  tableRoot: {
    height: '100%',
    overflow: 'auto',
    borderSpacing: '0',
    borderCollapse: 'collapse',
  },
  title: {
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '120%',
    letterSpacing: '-0.04em',
  },
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, 1fr)',
    alignItems: 'center',
    gridTemplateRows: '1fr',
    padding: '16px 24px',
    fontWeight: '400',
    fontSize: '14px',
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    color: COLORS.TEXT.SECONDARY,
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalsTableHeader: {
    display: 'table-row',
    '& td': {
      padding: '16px',
    },
    '& td:first-child': {
      paddingLeft: '24px',
    },
    '& td:last-child': {
      paddingRight: '24px',
    },
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalItemSubheader: {
    padding: '16px 12px 4px',
  },
  goalsTableSmall: {
    maxWidth: 480,
    '& table': {
      width: '100%',
    },
  },
  goalsTableHeaderSmall: {
    background: 'none',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    '& td:first-child': {
      paddingLeft: 12,
    },
    '& td:last-child': {
      paddingRight: 12,
      paddingLeft: 8,
    },
  },
  goalStatusProgress: {
    color: COLORS.ACTION.PRIMARY,
  },
  goalStatusAchieved: {
    color: COLORS.STATUS.SUCCESS,
  },
  goalsLoadMore: {
    display: 'block',
    padding: '16px 12px 18px',
    margin: -1,
    '&:hover': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
      cursor: 'pointer',
    },
  },
  noGoals: {
    padding: '16px 12px 4px',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '150%',
    color: COLORS.SCALEEVAL.CANT,
  },
  emptyView: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    padding: '30px 12px 60px 54px',
    marginTop: 26,
    display: 'flex',
    columnGap: 26,
    alignItems: 'center',
  },
  emptyIcon: {
    width: 80,
    height: 85,
  },
  emptyViewTitle: {
    fontWeight: 500,
    fontSize: 14,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    color: COLORS.SCALEEVAL.CANT,
    marginBottom: 13,
  },
  loaderHolder: {
    maxWidth: 480,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 150,
  },
});
