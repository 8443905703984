import { FLAG_PAGE_BLOCKS_PADDING } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  mainContainer: {
    padding: FLAG_PAGE_BLOCKS_PADDING,
    margin: '0px !important',
  },
  block: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    display: 'flex',
    flexDirection: 'row',
    columnGap: '14px',
    borderRadius: '4px',
  },
  handshakeIcon: {
    width: '37px',
    height: '37px',
    marginTop: '8px',
  },
});
