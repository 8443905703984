import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';
import { StylesConfig } from 'react-select';

const commonControlStyles = {
  cursor: 'pointer',
  transitionDuration: '0.1s',
  transitionProperty: 'all',
  border: `1px solid ${COLORS.INPUTBORDER}`,
  boxShadow: 'none',
  '&:hover': {
    borderColor: COLORS.ACTION.PRIMARY,
  },
};

const commonOptionStyles = {
  cursor: 'pointer',
  backgroundColor: COLORS.WHITE,
  color: COLORS.TEXT.PRIMARY,
  '&:hover': {
    background: COLORS.ACTION.HIGHLIGHT,
  },
};

const selectControlStyles = {
  width: 240,

  [BREAKPOINTS.MOBILE]: {
    width: '100%',
  },
};

export const selectCustomStyles: StylesConfig = {
  control: (base) => ({
    ...base,
    ...commonControlStyles,
    ...selectControlStyles,
  }),
  option: (styles, state) => {
    return {
      ...styles,
      ...commonOptionStyles,
      backgroundColor: state.isSelected ? COLORS.BRAND.HIGHLIGHT : COLORS.WHITE,
      color: COLORS.TEXT.PRIMARY,
    };
  },
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '2px 2px 2px 8px',
    marginRight: '-8px',
  }),
};
