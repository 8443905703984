import React, { FC, memo, useCallback, useState } from 'react';
import { useStyles } from './StartSurvey.styles';
import { StartSurveyDialogContent } from '../StartSurveyDialogContent';
import { useDispatch, useSelector } from 'react-redux';
import { runSurvey } from '../../redux/actions';
import { DataState } from '@shared/enums/DataState';
import { RocketLoader } from '@shared/components/RocketLoader';
import classnames from 'classnames';
import { runSurveyStatusSelector } from '../../redux/selectors';

interface Props {
  questionText: string;
  closeFn: () => void;
}

const StartSurveyComponent: FC<Props> = ({ closeFn, questionText }) => {
  const styles = useStyles();
  const [isRunInitiated, setIsRunInitiated] = useState(false);
  const runSurveyStatus = useSelector(runSurveyStatusSelector);
  const dispatch = useDispatch();

  const startSurvey = useCallback(() => {
    setIsRunInitiated(true);
    dispatch(runSurvey());
  }, []);

  const handleSuccessClose = useCallback(() => {
    closeFn();
    window.location.reload();
  }, [closeFn]);

  const fulfulledContent = (
    <>
      <h2>Survey has been run successfully!</h2>
      <button onClick={handleSuccessClose} className={classnames(styles.btn, styles.btnCancel)}>
        Close
      </button>
    </>
  );

  const failedContent = (
    <>
      <h2>Cannot start a survey while another one is active</h2>
      <button onClick={closeFn} className={classnames(styles.btn, styles.btnCancel)}>
        Close
      </button>
    </>
  );

  return (
    <div className={styles.popupContainer}>
      {!isRunInitiated ? (
        <StartSurveyDialogContent
          startSurvey={startSurvey}
          closeFn={closeFn}
          questionText={questionText}
        ></StartSurveyDialogContent>
      ) : (
        <div className={styles.infoContainer}>
          {runSurveyStatus === DataState.Pending && <RocketLoader></RocketLoader>}
          {runSurveyStatus === DataState.Fulfilled && fulfulledContent}
          {runSurveyStatus === DataState.Rejected && failedContent}
        </div>
      )}
    </div>
  );
};

export const StartSurvey = memo(StartSurveyComponent);
