import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
      justifyContent: 'space-between',
      padding: '8px 26px 0',
      borderTop: `1px solid ${COLORS.DIVIDER}`,
      margin: '8px -16px -8px',
    },
  },
  openPopupBtn: {
    position: 'relative',
    border: 'none',
    padding: 0,
    display: 'flex',
    backgroundColor: COLORS.WHITE,
  },
  comemntsPopup: {
    '&-content': {
      width: 'auto !important',
      overflowWrap: 'break-word',
      maxWidth: 325,
      boxShadow: '0 4px 12px rgb(180 180 180 / 60%) !important',
      color: COLORS.TEXT.PRIMARY,
      fontWeight: '400',
      fontSize: '14px',
      padding: 10,
      background: COLORS.WHITE,
      display: 'flex',
      flexDirection: 'column',

      '& span:not(:last-child)': {
        marginBottom: 10,
      },
    },
    '&-arrow': {
      color: COLORS.WHITE,
    },
  },
  confirmationPopup: {
    '&-content': {
      width: '170px !important',
      color: COLORS.TEXT.PRIMARY,
      boxShadow: '0 4px 12px rgb(180 180 180 / 60%) !important',
      fontWeight: '400',
      fontSize: '14px',
      padding: '5px 10px',
      background: COLORS.WHITE,
    },
    '&-arrow': {
      color: COLORS.WHITE,
    },
  },
  confirmationPopupContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  confirmationPopupTitle: {
    display: 'block',
    width: '100%',
    textAlign: 'center',
    marginBottom: '10px',
  },
  confirmBtn: {
    color: COLORS.WHITE,
    border: `1px solid ${COLORS.ACTION.PRIMARY}`,
    borderRadius: 8,
    padding: '5px 10px',
    backgroundColor: COLORS.ACTION.PRIMARY,
    fontSize: '14px',
    cursor: 'pointer',
    marginRight: '5px',
    transition: 'all 0.1s linear',
  },
  cancelBtn: {
    color: COLORS.ACTION.PRIMARY,
    border: `1px solid ${COLORS.ACTION.PRIMARY}`,
    borderRadius: 8,
    padding: '5px 10px',
    backgroundColor: COLORS.WHITE,
    fontSize: '14px',
    cursor: 'pointer',
    transition: 'all 0.1s linear',

    '&:hover': {
      border: `1px solid ${COLORS.ACTION.PRIMARY}`,
      color: COLORS.ACTION.PRIMARY,
    },
  },
  resolvedPersonePopup: {
    '&-content': {
      width: 'auto !important',
      boxShadow: '0 4px 12px rgb(180 180 180 / 60%) !important',
      color: COLORS.TEXT.PRIMARY,
      fontWeight: '400',
      fontSize: '14px',
      padding: '5px 10px',
      background: COLORS.WHITE,
    },
    '&-arrow': {
      color: COLORS.WHITE,
    },
  },
  checkIcon: {
    width: 16,
    height: 'auto',
    fill: COLORS.STATUS.SUCCESS,
    background: COLORS.WHITE,
    borderRadius: 2,
    position: 'absolute',
    bottom: -4,
    right: -8,
  },
  scheduleButton: {
    color: COLORS.ACTION.PRIMARY,
    padding: 0,
    display: 'flex',
    alignItems: 'center',

    '&:hover': {
      color: COLORS.BRAND.PRIMARY,
      transform: 'scale(1.1)',
    },
  },
  resolvedPersonePopupWithNotes: {
    cursor: 'pointer',
    borderRadius: 8,

    '&:hover': {
      background: COLORS.ACTION.HIGHLIGHT,
      boxShadow: '0 6px 16px rgb(180 180 180 / 60%) !important',
    },
  },
});
