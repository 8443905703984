import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  tableRow: {
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
    },
  },
  prevPosition: {
    color: COLORS.TEXT.SECONDARY,
  },
});
