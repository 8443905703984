import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const contentStyle = {
  display: 'block',
  width: 'fit-content',
  height: 'auto',
  borderRadius: 4,
  padding: '10px, 5px',
  fontWeight: '400',
  fontSize: '14px',
};

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    height: 'fit-content ',
    transition: 'all 0.2s',
  },
  avatar: {
    objectFit: 'cover',
    display: 'flex',
    objectPosition: '50% 35%',
    background: COLORS.BRAND.HIGHLIGHT,
  },
  extraSmallAvatar: {
    width: 20,
    height: 20,
    borderRadius: 6,
  },
  smallAvatar: {
    width: 30,
    height: 30,
    borderRadius: 12,
  },
  mediumAvatar: {
    borderRadius: 14,
    width: 40,
    height: 40,
  },
  largeAvatar: {
    minWidth: 85,
    minHeight: 85,
    width: 85,
    height: 85,
    borderRadius: 25,

    [BREAKPOINTS.MOBILE]: {
      borderRadius: 12,
      width: 50,
      height: 50,
      marginRight: 12,
      marginBottom: 12,
      flexDirection: 'row',
    },
  },
  skeleton: {
    transform: 'translateY(25%)',
  },
  flag: {
    position: 'absolute',
    right: 0,

    [BREAKPOINTS.MOBILE]: {
      right: 12,
    },
  },
  largeFlag: {
    bottom: 0,
    width: 24,

    [BREAKPOINTS.MOBILE]: {
      bottom: 12,
    },
  },
  smallFlag: {
    bottom: -2,
    width: 18,

    [BREAKPOINTS.MOBILE]: {
      right: 0,
    },
  },
});
