import { FlagListData } from '@modules/HappinessModule/interfaces/FlagListData';
import { ResponseListFlagData } from '../types';
import { flagUserMapFunc } from './flagUserMapFunc';

export const flagsListDataMapFunc = (flagListData: ResponseListFlagData[]): FlagListData[] => {
  const mappedFlagData = flagListData.map((el) => {
    const { user, author } = el;
    return {
      ...el,
      user: flagUserMapFunc(user),
      author: flagUserMapFunc(author),
    };
  });
  return mappedFlagData;
};
