import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  goalDialogNoteBig: {
    position: 'absolute',
    top: '900px',
    '& span': {
      fontSize: '80px',
      lineHeight: '1',
      display: 'block',
      margin: '0 0 8px -16px',
    },
  },
  goalDialogTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  goalDialogClose: {
    position: 'absolute',
    right: 12,
    top: 16,
    color: COLORS.TEXT.DISABLED,
  },
  goalDialogShowNotes: {
    color: COLORS.TEXT.DISABLED,
    margin: '0 0 0 4px',
  },
});
