import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',

    [BREAKPOINTS.MOBILE]: {
      height: 'auto',
    },
  },
  tableContainer: {
    display: 'flex',
    borderRadius: 8,
    flexDirection: 'column',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    backgroundColor: COLORS.WHITE,
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',

    [BREAKPOINTS.MOBILE]: {
      marginTop: 16,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      maxHeight: 'inherit',
    },

    '&:after': {
      content: '""',
      display: 'block',
      height: 20,
    },
  },
  header: {
    width: '100%',
    minHeight: 68,
    fontSize: '24px',
    fontWeight: '500',
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: '24px',
    fontWeight: '500',
    margin: 0,
  },
  titleSkeleton: {
    height: 24,
    width: 300,
  },
  questionHolder: {
    margin: '0 0 16px 0',

    [BREAKPOINTS.MOBILE]: {
      margin: 0,
    },
  },
  backLinkHolder: {
    marginBottom: 12,
  },
});
