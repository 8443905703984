import { FormikProps, useFormikContext } from 'formik';
import { useDispatch } from 'react-redux';
import { FlagFiltersFields } from '../interfaces/FlagFiltersFields';
import { useSearchParams } from 'react-router-dom';
import { SearchParamObject } from '../interfaces/SearchParamObject';
import { filterSearchParamsObject } from '../helpers/filterSearchParamsObject';
import { ChangeEvent } from 'react';
import {
  REASON_SUBREASON_SEPARATOR,
  escalationObject,
  projectEndObject,
  statusObject,
  userStatusesObject,
} from '../constants/constants';
import { FilterFields } from '../enums/FilterFields';
import { valuesFromLabels, valuesFromLabelsArr } from '../helpers/valuesFromLabels';
import { resetSearchResult } from '@modules/App/redux/users';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { labelsFromValuesArr } from '../helpers/labelsFromValues';
import { LocationOptionData, ReasonsSubReasonsOptionData } from '../interfaces/OptionData';
import {
  FlagReasonOptionsData,
  flagReasonsOptions,
} from '../../FlagPage/components/FlagForm/constants/flagsReasonsObject';
import { useAllFlagsData } from './useAllFlagsData';

export const useHandlers = (allDeparttmentsData: DepartmentData[]) => {
  const dispatch = useDispatch();
  const formik: FormikProps<FlagFiltersFields> = useFormikContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const { locationOptions } = useAllFlagsData();
  const searchParamsObject = () => {
    const obj: SearchParamObject = {};
    for (const [key, value] of searchParams.entries()) {
      obj[key] = value;
    }
    return obj;
  };

  const handleChangeRadioBtn = (e: ChangeEvent<HTMLInputElement>, fieldName: string) => {
    if (e.target.value === 'all' || e.target.value === projectEndObject.anyTime.value) {
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    } else {
      setSearchParams({ ...searchParamsObject(), [fieldName]: e.target.value });
    }
    formik.handleChange(e);
  };

  const handleNewHomeChange = () => {
    const { setFieldValue, values } = formik;
    if (values.isNewHomeNeeded) {
      const obj = filterSearchParamsObject(searchParamsObject(), [
        FilterFields.IsNewHomeNeeded,
        FilterFields.ProjectEnd,
        FilterFields.AssignedTomIds,
      ]);
      setSearchParams(obj);
    } else {
      setSearchParams({
        ...searchParamsObject(),
        [FilterFields.IsNewHomeNeeded]: (!values.isNewHomeNeeded).toString(),
      });
      setFieldValue(FilterFields.ProjectEnd, projectEndObject.anyTime.value);
      setFieldValue(FilterFields.AssignedTomIds, []);
    }
    setFieldValue(FilterFields.IsNewHomeNeeded, !values.isNewHomeNeeded);
  };

  const handleStatusChange = (fieldName: string, newValue: string[]) => {
    //TODO: investigate to use MUI Grouped autocomplete https://mui.com/material-ui/react-autocomplete/#grouped
    if (newValue.indexOf(statusObject.all.label) <= 0) {
      const values = valuesFromLabelsArr(newValue, statusObject);
      const labels = labelsFromValuesArr(values.toString(), statusObject);
      if (newValue.length < 1) {
        const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
        setSearchParams(obj);
      } else {
        setSearchParams({ ...searchParamsObject(), [fieldName]: values.toString() });
      }
      formik.setFieldValue(fieldName, labels);
    } else {
      formik.setFieldValue(fieldName, [statusObject.all.label]);
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    }
  };

  const handleUserStatusesChange = (fieldName: string, newValue: string[]) => {
    if (newValue.indexOf(userStatusesObject.all.label) <= 0) {
      const values = valuesFromLabelsArr(newValue, userStatusesObject);
      const labels = labelsFromValuesArr(values.toString(), userStatusesObject);
      if (newValue.length < 1) {
        const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
        setSearchParams(obj);
      } else {
        setSearchParams({ ...searchParamsObject(), [fieldName]: values.toString() });
      }
      formik.setFieldValue(fieldName, labels);
    } else {
      formik.setFieldValue(fieldName, [userStatusesObject.all.label]);
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    }
  };

  const handleEscalationDateChange = (fieldName: string, newValue: string) => {
    if (newValue === escalationObject.anyTime.label) {
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    } else {
      const value = valuesFromLabels(newValue, escalationObject);
      setSearchParams({ ...searchParamsObject(), [fieldName]: value });
    }
    formik.setFieldValue(fieldName, newValue);
  };

  const handleLocationChange = (fieldName: string, newValue: LocationOptionData[]) => {
    const selectedCountries: string[] = [];

    if (newValue.length < 1) {
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    } else {
      for (const selectedVal of newValue) {
        if (selectedVal.isLocationArea) {
          selectedCountries.push(
            ...locationOptions
              .filter((area) => !area.isLocationArea && area.locationArea === selectedVal.locationArea)
              .map((area) => area.value)
          );
        } else {
          selectedCountries.push(selectedVal.value);
        }
      }
      setSearchParams({ ...searchParamsObject(), [fieldName]: selectedCountries.join(',') });
    }
    formik.setFieldValue(
      fieldName,
      newValue.map((v) => v.value)
    );
    dispatch(resetSearchResult());
  };

  const handleProjectsChange = (fieldName: string, newValue: string[]) => {
    if (newValue.length < 1) {
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    } else {
      const projectIds = newValue.reduce((acc, projectName) => {
        allDeparttmentsData.map((project) => {
          if (projectName === project.name) acc.push(project.id);
        });
        return acc;
      }, [] as string[]);
      setSearchParams({ ...searchParamsObject(), [fieldName]: projectIds.toString() });
    }
    formik.setFieldValue(fieldName, newValue);
  };

  const handlePeopleSelectorChange = (fieldName: string, newValue: UserInfo[]) => {
    const arrOfIds = newValue.reduce((acc, user) => {
      acc.push(user.id ?? '');
      return acc;
    }, [] as string[]);
    if (newValue.length < 1) {
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    } else {
      setSearchParams({ ...searchParamsObject(), [fieldName]: arrOfIds.toString() });
    }
    formik.setFieldValue(fieldName, newValue);
    dispatch(resetSearchResult());
  };

  const handleReasonsSubReasonsChange = (fieldName: string, newValue: ReasonsSubReasonsOptionData[]) => {
    const arrOfIds = newValue.map((option) => option.value);
    if (newValue.length < 1) {
      const obj = filterSearchParamsObject(searchParamsObject(), [fieldName]);
      setSearchParams(obj);
    } else {
      setSearchParams({ ...searchParamsObject(), [fieldName]: arrOfIds.toString() });
    }
    formik.setFieldValue(fieldName, arrOfIds);
    dispatch(resetSearchResult());
  };

  const reasonsSubReasonsOptions: ReasonsSubReasonsOptionData[] = [];

  Object.values(flagReasonsOptions).forEach((mainFlagReason: FlagReasonOptionsData) => {
    mainFlagReason.options.forEach((reason) => {
      reasonsSubReasonsOptions.push({
        isPrimaryReason: true,
        value: reason.value,
        mainFlagReason: mainFlagReason.label,
        label: reason.value,
      });
      reason.subReasons?.forEach((subReason) => {
        reasonsSubReasonsOptions.push({
          isPrimaryReason: false,
          value: `${reason.value}${REASON_SUBREASON_SEPARATOR}${subReason.value}`,
          mainFlagReason: mainFlagReason.label,
          label: reason.value,
        });
      });
    });
  });

  return {
    handleNewHomeChange,
    handleChangeRadioBtn,
    handleStatusChange,
    handleEscalationDateChange,
    handleLocationChange,
    handleProjectsChange,
    handlePeopleSelectorChange,
    handleUserStatusesChange,
    handleReasonsSubReasonsChange,
    reasonsSubReasonsOptions,
  };
};
