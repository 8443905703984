import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';

import { useStyles } from '../CommunicationModal.styles';
import { Props as CommunicationModalProps } from '../CommunicationModal';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { NH_REQUESTED_WITHOUT_TOM_MESSAGE } from '../../../../constants/constants';

const EmailSentMessage: FC<
  Omit<
    CommunicationModalProps,
    'step' | 'isOpen' | 'userFlagPermissions' | 'saveFlagWithSettedNhEmailStatus' | 'setStep' | 'isNewFlag'
  >
> = ({ userInfo, isNHRequestedWithoutTom, onClose }) => {
  const styles = useStyles();

  return (
    <Box textAlign="center">
      <img src={ImageUrls.Mail} />
      <Typography variant="subtitle1">
        {isNHRequestedWithoutTom
          ? NH_REQUESTED_WITHOUT_TOM_MESSAGE
          : `NewHome process was communicated with ${userInfo.name} and automated NH email was sent.`}
      </Typography>
      <Box marginTop={5} className={styles.buttonsContainer}>
        <Button type="button" variant="contained" onClick={onClose}>
          Done
        </Button>
      </Box>
    </Box>
  );
};

export default EmailSentMessage;
