import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { LOCATION_AREA_ENUM } from '../enums/LocationAreas';

export const ALL_LOCATIONS_OPTION = {
  name: 'All locations',
  value: '',
  isLocationArea: false,
  locationArea: '',
};
export const ALL_FLAGS_FETCH_LIMIT = 50;
export const DEFAULT_PAGINATION_DATA = {
  limit: ALL_FLAGS_FETCH_LIMIT,
  offset: 0,
};

type FlagFormFieldsLabels = {
  [key in keyof FlagFields]: string;
};

export const FlagSnapshotFieldsLabels: FlagFormFieldsLabels & { [key: string]: string } = {
  primaryTriggerType: 'primary tigger',
  primaryTrigger: 'primary reason',
  primarySubReason: 'primary subreason',
  secondaryTriggerType: 'secondary trigger',
  secondaryTrigger: 'secondary reason',
  secondarySubReason: 'secondary subreason',
  departments: 'affected projects',
  isNewHomeNeeded: 'NewHome',
  status: 'status',
  createdAt: 'created date',
  resolvedAt: 'resolved date',
  emailRecipients: 'email recipients',
  flagWatchers: 'flag watchers',
  isRecipientsDisabled: 'is recipients enabled',
  description: 'flag description',
  whatWasDone: 'what was done',
  severity: 'severity',
  isAnotherOfferInvolved: 'another offer involved',
  isOfferAccepted: 'offer accepted',
  hiringType: 'hiring type',
  companyName: 'company',
  compensation: 'compensation',
  amountType: 'compensation amount',
  currencyType: 'currency',
  benefits: 'benefits',
  suggestedActionItems: 'actions',
  hrNotes: 'hr notes',
  resolutionNotes: 'resolution notes',
  isEscalatedByClient: 'is escalated by client',
  isConfidential: 'is confidential',
  isReturnedFromSabbatical: 'is returned from sabbatical',
  isPassive: 'is passive',
  lastDayOnTheProject: 'Last day on the project',
  newHomeStartDate: 'NewHome starting from',
  newHomeAvailableFrom: 'Date',
  newHomeAvailableFromType: 'Person is available from',
  workload: 'NH workload',
  pros: 'Pros',
  cons: 'Cons',
  softSkills: 'Soft skills',
  isPrTeamInvolved: 'is pr team involved',
  assignedTom: 'assigned TOM',
  assignedTomId: 'assigned TOM',
  assignedPR: 'assigned HR',
  newHomeResolution: 'NH resolution',
  newHomeEndDate: 'NewHome ending on',
  starRate: 'star rate',
  pipLink: 'PIP link',
  technicalSoftSkills: 'Technical soft skills',
  technicalPros: 'Technical pros',
  technicalCons: 'Technical cons',
  nhEmailSentStatus: 'NewHome email sent status',
  numOptionsShown: '# of options shown',
  numSubmissions: '# of submissions',
  numTICIFailed: '# TI/CI failed',
  newProjectAssignment: 'New project assignment',
};

export const statusObject = {
  all: { label: 'All statuses', value: 'all' },
  inProgress: { label: 'In progress', value: 'in_progress' },
  resolvedPositive: { label: 'Resolved: positive', value: 'positive' },
  resolvedNegative: { label: 'Resolved: negative', value: 'negative' },
  onHold: { label: 'On hold', value: 'on_hold' },
};

export const escalationObject = {
  anyTime: { label: 'Any time', value: 'any_time' },
  last7Days: { label: 'Last 7 days', value: 'last_7_days' },
  last2Weeks: { label: 'Last 2 weeks', value: 'last_2_weeks' },
  lastMonth: { label: 'Last month', value: 'last_month' },
  lastQuarter: { label: 'Last quarter', value: 'last_quarter' },
  lastYear: { label: 'Last year', value: 'last_year' },
};

export const projectEndObject = {
  anyTime: { label: 'Any time', value: 'any_time' },
  next2Weeks: { label: 'In the next 2 weeks', value: 'next_2_weeks' },
  alreadyEnded: { label: 'Already ended', value: 'already_ended' },
};

export const userStatusesObject = {
  active: { label: 'Active', value: 'Active' },
  inactive: { label: 'Inactive', value: 'Inactive' },
  sabbatical: { label: 'Sabbatical', value: 'Sabbatical' },
  all: { label: 'All members', value: 'AllMembers' },
};

export const REASON_SUBREASON_SEPARATOR = '::';

export const locationAreaCountryMapping = {
  [LOCATION_AREA_ENUM.OTHERS]: ['Canada', 'India', 'USA'],
  [LOCATION_AREA_ENUM.LATAM]: ['Argentina', 'Brazil', 'Colombia', 'Guatemala', 'Mexico'],
};
