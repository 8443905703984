import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  nameContainer: {
    display: 'flex',
    gap: 8,

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
    },
  },
  fieldContainer: {
    display: 'flex',
    gap: 8,
    marginBottom: 8,
    flexDirection: 'column',
  },
  fieldFlex: {
    display: 'flex',
  },
  fieldTitle: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
  },
  fieldsText: {
    padding: '13px 16px',
    fontWeight: '400',
    fontSize: '14px',
    border: `1px solid ${COLORS.INPUTBORDER}`,
    borderRadius: 4,
  },
  checkboxField: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px',
  },
  btnContainer: {
    display: 'flex',
    gap: 10,
    justifyContent: 'flex-end',
    marginTop: 24,
  },
  modalBodyStyles: {
    width: 500,
  },
  hidden: {
    display: 'none',
  },
  selectContainer: {
    position: 'relative',
  },
  select: {
    appearance: 'none',
    width: '100%',
  },
  arrow: {
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translate3d(-50%, -50%, 0)',
    width: 20,
    pointerEvents: 'none',
    color: COLORS.TEXT.DISABLED,
  },
  addBtn: {
    margin: '12px 0 48px',
  },
  editBtn: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    cursor: 'pointer',
    border: 'none',
    backgroundColor: 'transparent',
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.ACTION.PRIMARY,
    '& svg': {
      width: 16,
      marginRight: 5,
    },
  },
  emailNotificationContainer: {
    display: 'flex',
    columnGap: 24,
    marginTop: 16,

    '& label': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  disclaimer: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    maxWidth: 406,
    marginTop: 7,
  },
  emailRadio: {
    width: 20,
    height: 20,
    marginRight: 8,
  },
  error: {
    marginBottom: 16,
    marginLeft: 'auto',
    maxWidth: 406,
    textAlign: 'right',
    fontSize: '14px',
    color: COLORS.STATUS.CRITICAL,
    fontWeight: '500',
  },
  fieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
  },
  spinner: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    borderTop: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderBottom: `3px solid ${COLORS.DIVIDER}`,
    borderLeft: `3px solid ${COLORS.DIVIDER}`,
    animation: '$spin 0.5s infinite linear',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
});
