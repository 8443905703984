import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { State } from './types';
import { ClientSurveyResponseData } from '@modules/HappinessModule/interfaces/ClientSurveyResponseData';
import { SLICE_NAME } from './constants';
import { TrackedStateValuesOfClientResponseFieldsToSurvey } from '../interfaces/TrackedStateValuesOfClientResponseFieldsToSurvey';
import { EditClientSurveyResponseData } from '@modules/HappinessModule/interfaces/EditClientSurveyResponseData';

const initialState: State = {
  clientSurveyResponse: {
    data: null,
    state: DataState.Pending,
  },
  whatIsEditedInClientSurveyResponse: {
    scoreState: DataState.Idle,
    commentState: DataState.Idle,
    revokeState: DataState.Idle,
  },
  updatingDmNotesState: DataState.Idle,
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setClientSurveyResponseData: (state, action: PayloadAction<AsyncData<ClientSurveyResponseData>>) => {
      state.clientSurveyResponse = action.payload;
    },
    setTrackedStateValuesOfClientResponseFieldsToSurvey: (
      state,
      action: PayloadAction<TrackedStateValuesOfClientResponseFieldsToSurvey>
    ) => {
      state.whatIsEditedInClientSurveyResponse = {
        commentState:
          action.payload.commentState !== DataState.Idle
            ? action.payload.commentState
            : state.whatIsEditedInClientSurveyResponse.commentState,
        scoreState:
          action.payload.scoreState !== DataState.Idle
            ? action.payload.scoreState
            : state.whatIsEditedInClientSurveyResponse.scoreState,
        revokeState:
          action.payload.revokeState !== DataState.Idle
            ? action.payload.revokeState
            : state.whatIsEditedInClientSurveyResponse.revokeState,
      };
    },
    updateClientSurveyResponseInStore: (state, action: PayloadAction<EditClientSurveyResponseData>) => {
      if (!state.clientSurveyResponse.data) {
        return;
      }
      state.clientSurveyResponse.data = {
        ...state.clientSurveyResponse.data,
        comment:
          action.payload.comment === undefined ? state.clientSurveyResponse.data.comment : action.payload.comment,
        score: action.payload.score === undefined ? state.clientSurveyResponse.data.score : action.payload.score,
      };
    },
    removeScoreAndCommentFromClientSurveyResponse: (state) => {
      if (!state.clientSurveyResponse.data) {
        return;
      }
      state.clientSurveyResponse.data = {
        ...state.clientSurveyResponse.data,
        comment: null,
        score: null,
      };
    },
    setUpdatingDmNotesState: (state, action: PayloadAction<DataState>) => {
      state.updatingDmNotesState = action.payload;
    },
  },
});

export const {
  setClientSurveyResponseData,
  setTrackedStateValuesOfClientResponseFieldsToSurvey,
  updateClientSurveyResponseInStore,
  removeScoreAndCommentFromClientSurveyResponse,
  setUpdatingDmNotesState,
} = slice.actions;

export default slice.reducer;
