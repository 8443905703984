import { createAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './constants';
import {
  CreateUserPromotionData,
  UpdateUserPromotionData,
  UserPromotionsData,
} from '@modules/EvaluationModule/interfaces/UserPromotionsData';

export const loadUserPromotions = createAction<string>(`${SLICE_NAME}/loadUserPromotions`);
export const loadRolesSenioritiesMap = createAction(`${SLICE_NAME}/loadRolesSenioritiesMap`);
export const updateUserPromotion = createAction<{ id: string; payload: UpdateUserPromotionData }>(
  `${SLICE_NAME}/updateUserPromotion`
);
export const createPromotion = createAction<CreateUserPromotionData>(`${SLICE_NAME}/createPromotion`);
export const setPromotionInStateAfterCreate = createAction<UserPromotionsData>(
  `${SLICE_NAME}/setPromotionInStateAfterCreate`
);
