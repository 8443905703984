export enum pageTitles {
  errorViewPage = 'Error',
  loginPage = 'Login',
  pageNotFound = 'Page not found',
  unauthorizedPage = 'Access denied',
  myEvalHistoryEvaluationsPage = 'My evaluation history | Evaluations',
  myPeopleEvalPage = 'My people evaluations',
  myTeamEvalPage = 'My team evaluations',
  userEvalPage = ' | Evaluation',
  peopleHappinessPage = 'People | Happiness',
  userReportHappinessPage = ' responses | Happiness',
  myTeamReportHappinessPage = 'My team responses | Happiness',
  surveyHistoryHappinessPage = 'Survey history | Happiness',
  surveyManagementHappinessPage = 'Survey management | Happiness',
  chatHappinessPage = 'Chat',
  allFlags = 'All flags',
  myPeerReviewsPage = 'My peer reviews',
  MyCompensationPage = 'My compensation',
  goals = '| Goals',
  carreer = '| Carreer',
  profile = '| Profile',
  bonuses = '| Bonuses',
  compensation = '| Compensation',
  links = '| Links',
}
