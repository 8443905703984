import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  statusContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  statusScheduledDate: {
    color: COLORS.STATUS.WARNING,
  },
  button: {
    margin: 0,
    display: 'flex',
    textAlign: 'center',
    background: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    cursor: 'pointer',
    textDecoration: 'none',
    borderRadius: 8,
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    fontWeight: 700,
    width: 'fit-content',
    padding: '0px 16px',
    height: 27,
  },
  scheduledIcon: {
    color: COLORS.SCALEEVAL.CANT,
  },
  penIcon: {
    width: 20,
    height: 20,
    fill: COLORS.ACTION.PRIMARY,
    marginLeft: 4,
    marginBottom: -2,
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
