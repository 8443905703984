import { FC, memo, useCallback, useEffect } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { MuiTelInput } from 'mui-tel-input';
import classNames from 'classnames';
import { countries as countriesList } from 'countries-list';

import { useStyles } from './DeliveryAddress.styles';
import { AddressAutocompleteTextField } from '../AddressAutocompleteTextField';
import { UserAddressFormFields, UserAddressFormValuesType } from '../../constants/userAddressFormFields';
import countries from '@shared/consts/countries';
import DeliveryAddressType from '@shared/enums/DeliveryAddressType';
import { UserAddressData } from '@shared/interfaces/UserAddressData';
import CountrySelect from '@shared/components/CountrySelect/CountrySelect';
import ClothSize from '@shared/enums/ClothSize';

const DeliveryAddress: FC = () => {
  const styles = useStyles();
  const { dirty, errors, handleChange, setFieldValue, setValues, values } =
    useFormikContext<UserAddressFormValuesType>();

  const setValuesForDeliveryAutofillFields = useCallback(
    (value: Required<UserAddressData>) => {
      const newDeliveryAddressValues = {
        addressLine1: value.addressLine1 || values.deliveryAddress.addressLine1 || '',
        addressLine2: values.deliveryAddress.addressLine2 || '',
        city: value.city,
        state: value.state,
        country: value.country,
        zipCode: value.zipCode,
      };

      setValues({
        ...values,
        deliveryAddress: {
          ...values.deliveryAddress,
          ...newDeliveryAddressValues,
        },
      });
    },
    [values.deliveryAddress?.type]
  );

  const shippingOptions = Object.entries(DeliveryAddressType).filter(([, value]) => {
    if (values.deliveryAddress?.country === countriesList.PL.name) return true;
    else if (values.deliveryAddress?.country === countriesList.UA.name)
      return value === DeliveryAddressType['Nova Poshta'];
    else return value !== DeliveryAddressType['Nova Poshta'];
  });

  const clothesSizeOptions = Object.entries(ClothSize);

  const missingAddressField = [
    values.addressLine1,
    values.addressLine2,
    values.city,
    values.country,
    values.state,
    values.zipCode,
  ].some((field) => Boolean(!field));

  const areaCode = countries.find((country) => country.label === values.deliveryAddress?.country)?.phone ?? '';

  useEffect(() => {
    if (shippingOptions.some(([, value]) => value === DeliveryAddressType['Nova Poshta']))
      setFieldValue(UserAddressFormFields.deliveryAddress.type.name, DeliveryAddressType['Nova Poshta']);
    else setFieldValue(UserAddressFormFields.deliveryAddress.type.name, DeliveryAddressType['Physical address']);
  }, [values.deliveryAddress?.country]);

  useEffect(() => {
    if (values.deliveryAddress?.type === DeliveryAddressType['Physical address'])
      setFieldValue(UserAddressFormFields.deliveryAddress.country.name, values.country);
  }, [values.deliveryAddress?.type]);

  return (
    <div>
      <Typography variant="h3" marginBottom={4}>
        Delivery preferences
      </Typography>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CountrySelect
            value={values.deliveryAddress?.country}
            label={UserAddressFormFields.deliveryAddress.country.label}
            onChange={(_, value) => {
              setFieldValue(UserAddressFormFields.deliveryAddress.country.name, value?.label);
            }}
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} sm={6}>
          <Field
            type="select"
            name={UserAddressFormFields.deliveryAddress.type.name}
            component={({ field }: FieldProps) => (
              <FormControl fullWidth>
                <InputLabel id="shipping">{UserAddressFormFields.deliveryAddress.type.label}</InputLabel>
                <Select
                  labelId="shipping"
                  label={UserAddressFormFields.deliveryAddress.type.label}
                  placeholder="Please select"
                  disabled={shippingOptions.length === 1 && Boolean(values.deliveryAddress.type)}
                  {...field}
                >
                  {shippingOptions.map(([label, value]) => {
                    const incompletePhysicalAddress =
                      value === DeliveryAddressType['Physical address'] && missingAddressField;

                    return (
                      <MenuItem
                        key={value}
                        value={value}
                        className={classNames({ [styles.novaPoshta]: value === DeliveryAddressType['Nova Poshta'] })}
                        disabled={incompletePhysicalAddress}
                      >
                        {label}
                        {incompletePhysicalAddress && (
                          <Typography
                            component="span"
                            marginLeft="auto"
                            variant="caption"
                            fontSize="10px"
                            lineHeight={1}
                          >
                            Incomplete
                          </Typography>
                        )}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {values.deliveryAddress?.type === DeliveryAddressType['Physical address'] && (
            <Stack justifyContent="center">
              <Typography variant="body2" fontSize={14}>
                {values.addressLine1}
              </Typography>
              <Typography variant="body2" fontSize={14}>
                {[values.city, values.zipCode, values.country].filter(Boolean).join(', ')}
              </Typography>
            </Stack>
          )}
        </Grid>

        {values.deliveryAddress?.type === DeliveryAddressType['Different mailing address'] && (
          <>
            <Grid item xs={12} marginTop={4}>
              <AddressAutocompleteTextField
                type="text"
                variant="outlined"
                name={UserAddressFormFields.deliveryAddress.addressLine1.name}
                label={UserAddressFormFields.deliveryAddress.addressLine1.label}
                value={values.deliveryAddress.addressLine1}
                setValuesForAutofillFields={setValuesForDeliveryAutofillFields}
                fieldName={UserAddressFormFields.addressLine1.name}
                placeholder='Street number, street name, e.g. "123 Main St."'
                autoComplete="shipping address-line1 street-address"
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                variant="outlined"
                name={UserAddressFormFields.deliveryAddress.addressLine2.name}
                label={UserAddressFormFields.deliveryAddress.addressLine2.label}
                value={values.deliveryAddress.addressLine2}
                onChange={handleChange}
                inputProps={{ maxLength: 100 }}
                placeholder="Apartment, suite, unit number, etc."
                autoComplete="shipping address-line2"
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={8}>
              <AddressAutocompleteTextField
                name={UserAddressFormFields.deliveryAddress.city.name}
                label={UserAddressFormFields.deliveryAddress.city.label}
                value={values.deliveryAddress.city}
                setValuesForAutofillFields={setValuesForDeliveryAutofillFields}
                fieldName={UserAddressFormFields.city.name}
                autoComplete="shipping address-level2"
                variant="outlined"
                required
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <TextField
                variant="outlined"
                name={UserAddressFormFields.deliveryAddress.zipCode.name}
                label={UserAddressFormFields.deliveryAddress.zipCode.label}
                value={values.deliveryAddress.zipCode ?? ''}
                onChange={handleChange}
                inputProps={{ maxLength: 10 }}
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AddressAutocompleteTextField
                variant="outlined"
                name={UserAddressFormFields.deliveryAddress.state.name}
                label={UserAddressFormFields.deliveryAddress.state.label}
                value={values.deliveryAddress.state}
                setValuesForAutofillFields={setValuesForDeliveryAutofillFields}
                fieldName={UserAddressFormFields.state.name}
                placeholder="State/Province/Region"
                fullWidth
                required
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <AddressAutocompleteTextField
                variant="outlined"
                name={UserAddressFormFields.deliveryAddress.country.name}
                label={UserAddressFormFields.deliveryAddress.country.label}
                value={values.deliveryAddress.country}
                setValuesForAutofillFields={setValuesForDeliveryAutofillFields}
                fieldName={UserAddressFormFields.country.name}
                autoComplete="shipping country"
                fullWidth
                required
                disabled
              />
            </Grid>
          </>
        )}

        {values.deliveryAddress?.type === DeliveryAddressType['Nova Poshta'] && (
          <Grid item xs={12} sm={6} marginTop={4}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name={UserAddressFormFields.deliveryAddress.cityAndWarehouseNumber.name}
                  label={UserAddressFormFields.deliveryAddress.cityAndWarehouseNumber.label}
                  value={values.deliveryAddress.cityAndWarehouseNumber}
                  onChange={handleChange}
                  placeholder="e.g. “Kyiv NP №123”"
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  name={UserAddressFormFields.deliveryAddress.fullName.name}
                  label={UserAddressFormFields.deliveryAddress.fullName.label}
                  value={values.deliveryAddress.fullName}
                  placeholder="As in your passport"
                  onChange={handleChange}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12}>
                <MuiTelInput
                  name={UserAddressFormFields.deliveryAddress.phoneNumber.name}
                  label={UserAddressFormFields.deliveryAddress.phoneNumber.label}
                  value={values.deliveryAddress.phoneNumber ?? areaCode}
                  onChange={(value) => {
                    setFieldValue(UserAddressFormFields.deliveryAddress.phoneNumber.name, value);
                  }}
                  inputMode="tel"
                  autoComplete="tel"
                  placeholder="+1"
                  error={dirty && Boolean(errors.deliveryAddress?.phoneNumber)}
                  helperText={
                    dirty && errors.deliveryAddress?.phoneNumber && <span>{errors.deliveryAddress?.phoneNumber}</span>
                  }
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>

      <Grid container spacing={2} marginTop={1}>
        <Grid item xs={12} sm={6}>
          <Field
            type="select"
            name="clothesSize"
            component={({ field }: FieldProps) => (
              <FormControl fullWidth>
                <InputLabel id="clothesSize">Clothes size</InputLabel>
                <Select labelId="clothesSize" label="Clothes size" placeholder="Please select" {...field}>
                  {clothesSizeOptions.map(([label, value]) => {
                    return (
                      <MenuItem key={value} value={value}>
                        {label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default memo(DeliveryAddress);
