import { RockyButton } from '@shared/components/RockyButton';
import React, { FC } from 'react';
import Popup from 'reactjs-popup';
import { useStyles } from './UsersDropdown.styles';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PenIcon from '@mui/icons-material/Edit';
import PlusCircle from '@mui/icons-material/AddCircleOutline';
import { paths } from '@shared/enums/paths';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { EvalPeerReviewData } from '@modules/EvaluationModule/interfaces/EvalPeerReviewData';
import { COLORS } from '@styles/colors';
import { useAuth } from '@shared/hooks/useAuth';
import { ReviewerBlock } from '@modules/EvaluationModule/pages/EvalSettingsPage/components/ReviewerBlock';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';

interface Props {
  name: 'Clients' | 'Peers';
  userReviews: EvalPeerReviewData[] | null;
  linkPath: string;
  evaluationInfo: MyTeamEvalData;
}

export const UsersDropdown: FC<Props> = ({ userReviews, linkPath, name, evaluationInfo }) => {
  const styles = useStyles();
  const approvedPeersFeedbacksNumber = userReviews?.filter(
    (userReview) => userReview.status === EvalFeedbackStatus.Approved
  ).length;
  const { currentUser } = useAuth();
  const {
    evaluationId,
    status: evaluationStatus,
    primaryReportsTo: { id: evaluatorId },
  } = evaluationInfo;
  const isManager = evaluatorId === currentUser?.id;
  const linkState = isManager ? { path: `${paths.myTeam}/${paths.evaluations}`, name: 'Team Evaluations' } : null;

  const addUsersBtn = (
    <RockyButton small inverted className={styles.dropdownBtn}>
      {userReviews &&
        userReviews.filter((userReviews) => userReviews.status === EvalFeedbackStatus.Review).length > 0 && (
          <div className={styles.notificationBlock}></div>
        )}
      <span className={styles.capitalize}>{name}</span>
      {userReviews && userReviews.length > 0 ? (
        <div
          className={styles.dropdownElementsBlock}
          style={{
            backgroundColor: approvedPeersFeedbacksNumber
              ? COLORS.ACTION.PRIMARY
              : approvedPeersFeedbacksNumber === userReviews.length
              ? COLORS.STATUS.SUCCESS
              : COLORS.TEXT.SECONDARY,
          }}
        >
          {approvedPeersFeedbacksNumber}/{userReviews.length}
        </div>
      ) : (
        <PlusCircle></PlusCircle>
      )}
    </RockyButton>
  );

  return (
    <div className={styles.root} onClick={(e) => e.stopPropagation()}>
      {!userReviews || userReviews?.length === 0 ? (
        <Link state={linkState} to={linkPath} className={styles.link}>
          {addUsersBtn}
        </Link>
      ) : (
        <Popup trigger={<div>{addUsersBtn}</div>} on={['click']} position="bottom right" className={styles.popup}>
          <div className={styles.dropdown}>
            {userReviews?.map((userReview) => (
              <ReviewerBlock
                evaluationStatus={evaluationStatus as EvaluationStatus}
                evalId={evaluationId}
                key={userReview.id}
                user={userReview.user}
                feedbackId={userReview.id}
                isAskWithEmail={userReview.isAskWithEmail}
                feedbackStatus={userReview.status}
                isClient={name === 'Clients'}
              />
            ))}
            <Link state={linkState} to={linkPath} className={classNames(styles.dropdownItem, styles.lastDropdownItem)}>
              <div className={styles.lastDropdownItemIcon}>
                <PenIcon />
              </div>
              Edit
              <span className={styles.lowercase}>{name}</span>
            </Link>
          </div>
        </Popup>
      )}
    </div>
  );
};
