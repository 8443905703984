import { FC, ReactNode, memo } from 'react';
import { useStyles } from './UserRate.styles';
import { NO_MARK_THRESHOLD } from '@modules/HappinessModule/constants/reportConstants';
import classNames from 'classnames';
import { getBackgroundColorByRate } from '@modules/HappinessModule/helpers/getBackgroundColorByRate';
import { Stack } from '@mui/material';

interface Props {
  rate: number | null;
  reminderCount?: number | null;
  isDepartament?: boolean;
  userHappinessActions?: ReactNode;
}

const UserRateComponent: FC<Props> = ({ rate = 0, reminderCount, isDepartament, userHappinessActions }) => {
  const styles = useStyles();

  const backgroundColor = rate ? getBackgroundColorByRate(rate) : '';

  return (
    <div className={styles.root}>
      {rate !== NO_MARK_THRESHOLD && rate ? (
        <>
          <div className={styles.barHolder}>
            <div className={styles.bar}></div>
            <div className={styles.valueBar} style={{ width: `${10 * rate}%`, backgroundColor }}></div>
          </div>
          {userHappinessActions && (
            <Stack
              sx={{
                padding: '0 12px',
              }}
            >
              {userHappinessActions}
            </Stack>
          )}
          <div className={styles.rateBlock}>
            <p className={styles.rate} style={{ backgroundColor }}>
              {+(rate || 0).toFixed(1)}
            </p>
          </div>
        </>
      ) : (
        <p
          className={classNames(styles.noResponseLabel, {
            [styles.warning]: reminderCount === 1,
            [styles.danger]: reminderCount && reminderCount > 1,
          })}
        >
          {isDepartament ? (
            <>No responses</>
          ) : (
            <>
              Did not respond&nbsp;
              {!reminderCount && <>(to the initial email)</>}
              {reminderCount === 1 && <>(was reminded once)</>}
              {reminderCount === 2 && <>(was reminded twice)</>}
            </>
          )}
        </p>
      )}
    </div>
  );
};

export const UserRate = memo(UserRateComponent);
