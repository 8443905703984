import { postFeedback } from '@modules/App/api/feedback';
import { DataState } from '@shared/enums/DataState';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { displayNotification } from '../notifications/actions';
import { sendFeedback } from './actions';
import { setFeedbackDataState } from './reducers';

function* sendFeedbackAsync({ payload }: ReturnType<typeof sendFeedback>) {
  try {
    yield put(setFeedbackDataState(DataState.Pending));
    yield call(postFeedback, payload);
    yield put(setFeedbackDataState(DataState.Fulfilled));
    yield put(displayNotification('Thank you! Your feedback submitted!'));
  } catch (error) {
    yield put(setFeedbackDataState(DataState.Rejected));
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* watchSendFeedback() {
  yield takeLeading(sendFeedback.type, sendFeedbackAsync);
}

export function* feedbackSaga(): Generator {
  yield all([watchSendFeedback()]);
}
