import { RockyButton } from '@shared/components/RockyButton';
import { RockyModal } from '@shared/components/RockyModal';
import React, { FC } from 'react';
import { useStyles } from './DeleteReviewerWarningModal.styles';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  onDelete: () => void;
}

export const DeleteReviewerWarningModal: FC<Props> = ({ isModalOpen, closeModal, onDelete }) => {
  const styles = useStyles();

  return (
    <RockyModal
      title="Delete reviewer"
      isOpen={isModalOpen}
      onClose={closeModal}
      modalBodyStyles={styles.modalBody}
      crossIcon
    >
      <div>
        <div className={styles.modalBodyTitle}>Peer will not receive an email once you remove them.</div>
        <div className={styles.modalBodySubitle}>Also the feedback form will be unavailable.</div>
        <div className={styles.modalBtnContainer}>
          <RockyButton onClick={() => closeModal()}>Cancel</RockyButton>
          <RockyButton
            action
            onClick={() => {
              onDelete();
              closeModal();
            }}
          >
            Continue
          </RockyButton>
        </div>
      </div>
    </RockyModal>
  );
};
