import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  container: {
    maxWidth: 1440,
    minWidth: 300,
    height: 'calc(100vh - 250px)',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 24,
    height: '100%',
    minHeight: 400,

    [BREAKPOINTS.MOBILE]: {
      gap: 0,
    },
  },
  mainHeading: {
    fontSize: 24,
    fontWeight: '500',
    textAlign: 'center',
    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
    },
  },
  symbol: {
    fontSize: 64,
    lineHeight: '1',
    margin: '0 0 16px',
  },
  explainText: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: 14,
    fontWeight: '400',
    margin: '-8px 0 0',
  },
  buttonsContainer: {
    display: 'flex',
    gap: 16,
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    border: 'none',
    fontSize: '14px',
    padding: '6px 16px 7px',
    fontWeight: '500',
    cursor: 'pointer',
    borderRadius: 4,

    '&:hover': {
      backgroundColor: COLORS.ACTION.DARK,
    },
  },
});
