import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DataState } from '@shared/enums/DataState';
import { useUserEvalData } from './hooks/useUserEvalData';
import { EvaluationHistory } from '@modules/EvaluationModule/components/EvaluationHistory/EvaluationHistory';
import { DataErrorView } from '@shared/components/DataErrorView';
import { startEvalOnUserEvalPage } from './redux/actions';
import { useAuth } from '@shared/hooks/useAuth';
import { pageTitles } from '@shared/enums/pageTitles';
import { useTitle } from '@shared/hooks/useTitle';
import { useSelectEvals } from '@modules/EvaluationModule/components/MyTeamEvalReport/hooks/useSelectEvals';
import { setSelectedEvalsAction } from '../MyTeamEvalPage/redux/actions';
import { userProfileOfficeCountrySelector } from '@modules/App/pages/UserProfilePage/redux/selectors';
import { useExternalLinks } from '@modules/App/pages/ExternalLinksPage/hooks/useExternalLinks';

export const UserEvalPage: React.FC = () => {
  const userEvalData = useUserEvalData();
  const isFailed = userEvalData.state === DataState.Rejected;
  const isDataLoading = userEvalData.state === DataState.Pending;
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const setPageTitle = useTitle();
  useExternalLinks(userEvalData.data?.user.id);

  const onEvalStart = (id: string) => {
    dispatch(startEvalOnUserEvalPage(id));
  };
  const evaluateeName = userEvalData.data?.user.name;
  const { handleFailEopButtonClick, failingEopId, startingEvalId } = useSelectEvals(setSelectedEvalsAction);

  useEffect(() => {
    if (evaluateeName) {
      setPageTitle(`${evaluateeName} ${pageTitles.userEvalPage}`);
    }
  }, [evaluateeName]);

  const officeCountry = useSelector(userProfileOfficeCountrySelector);

  return isFailed ? (
    <DataErrorView error={userEvalData.error} />
  ) : (
    <EvaluationHistory
      data={userEvalData.data}
      isDataLoading={isDataLoading}
      currentUser={currentUser}
      onEvalStart={onEvalStart}
      onEopFail={handleFailEopButtonClick}
      currentlyStartedEvalId={startingEvalId}
      failingEopId={failingEopId}
      officeCountry={officeCountry}
    />
  );
};
