import { FC, memo, ReactNode } from 'react';
import { useIsBrowsingOwnData } from '@modules/App/pages/UserPage/hooks/useIsBrowsingOwnData';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';

const PersonalRouteWrapperComponent: FC<{ children: ReactNode }> = ({ children }) => {
  const isBrowsingOwnData = useIsBrowsingOwnData();

  return isBrowsingOwnData ? <>{children}</> : <AccessDeniedView inComponent />;
};

export const PersonalRouteWrapper = memo(PersonalRouteWrapperComponent);
