import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    minWidth: 300,
    width: '100%',
    maxHeight: '100%',
    textDecoration: 'none',
  },
  flagStatus: {
    color: COLORS.TEXT.DISABLED,
  },
  flagLink: {
    color: COLORS.TEXT.DISABLED,
  },
  inProgressStatus: {
    color: COLORS.ACTION.PRIMARY,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    color: COLORS.TEXT.DISABLED,
    pointerEvents: 'none',
  },
  linkButton: {
    marginLeft: 'auto',
    pointerEvents: 'none',
  },
  titleLink: {
    color: COLORS.TEXT.PRIMARY,
  },
  clipIcon: {
    fill: COLORS.TEXT.DISABLED,
  },
  clipIconLink: {
    fill: COLORS.TEXT.SECONDARY,
  },
  tableDataRow: {
    padding: '0 22px',
    height: 62,

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
  },
  tableDataRowLink: {
    cursor: 'pointer',
    '&:hover': {
      '& p': {
        color: COLORS.ACTION.PRIMARY,
      },
      '& svg': {
        fill: COLORS.ACTION.PRIMARY,
      },
    },
  },
});
