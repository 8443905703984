import { initialValuesOther } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { SLICE_NAME } from '../users/constants';

import { State } from './types';
import { UserInfo } from '@shared/interfaces/UserInfo';

const initialState: State = {
  feedbackDataState: DataState.Fulfilled,
  isFeedbackOpen: false,
  initialValues: initialValuesOther,
  selectedUser: null,
  selectedManager: null,
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setIsFeedbackOpen(state, { payload }) {
      state.isFeedbackOpen = payload.isOpen;
      state.initialValues = payload.initialValues;
    },
    setFeedbackDataState(state, { payload }) {
      state.feedbackDataState = payload;
    },
    setSelectedManager(state, { payload }) {
      state.selectedManager = payload;
    },
    setSelectedUser(state, { payload }: PayloadAction<UserInfo | null>) {
      state.selectedUser = payload;
    },
  },
});

export const { setIsFeedbackOpen, setFeedbackDataState, setSelectedManager, setSelectedUser } = slice.actions;

export default slice.reducer;
