import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  spinner: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    borderTop: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderBottom: `3px solid ${COLORS.DIVIDER}`,
    borderLeft: `3px solid ${COLORS.DIVIDER}`,
    animation: '$spin 0.5s infinite linear',
  },
  linkContainer: {
    padding: 16,
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
});
