import React, { FC } from 'react';
import { useParams } from 'react-router';
import { DataState } from '@shared/enums/DataState';
import { LinkManageButton } from '@modules/App/pages/ExternalLinksPage/components/LinkManageButton/LinkManageButton';
import { useStyles } from './PdpLinkEvaluationButton.styles';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';
import { useSelector } from 'react-redux';
import { selectExternalLinks } from '@modules/App/pages/ExternalLinksPage/redux/selectors';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';

export const PdpLinkEvaluationButton: FC = () => {
  const styles = useStyles();
  const { id } = useParams();
  const { data, state } = useSelector(userPermissionsSelector);
  const isPdpLinkEditable = !!data && data?.externalLinks?.pdp?.isUpdatable;
  const canView = Boolean(data?.externalLinks?.evaluation?.isReadable);
  const { data: externalLinks, state: externalLinksDataState } = useSelector(selectExternalLinks);
  const isDataLoading = state === DataState.Pending || externalLinksDataState === DataState.Pending;

  return isDataLoading ? (
    <div className={styles.spinner} />
  ) : (
    <>
      {canView && (
        <>
          <h2 className={styles.title}>Professional development plan</h2>
          <LinkManageButton
            isOutside
            link={externalLinks?.pdpLink ?? null}
            id={id!}
            canEdit={isPdpLinkEditable}
            type={ExternalLinksTypes.pdp}
          />
        </>
      )}
    </>
  );
};
