import { RootState } from '@modules/App/redux/store';
import { ExternalLinks } from '../interfaces/ExternalLinks';
import { PipLinkBody } from '../interfaces/PipLinkBody';
import { AsyncData } from '@shared/interfaces/asyncData';

export const selectExternalLinks = (state: RootState): AsyncData<ExternalLinks> => state.externalLinks.externalLinks;
export const selectPipLinks = (state: RootState): AsyncData<PipLinkBody[]> => ({
  ...state.externalLinks.pipLinks,
  data: [...(state.externalLinks.pipLinks.data ?? [])].sort((a, b) => (a.escalationDate > b.escalationDate ? -1 : 1)),
});
