import { GetUserGoalsPayload } from '@modules/EvaluationModule/interfaces/GetUserGoalsPayload';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setUserGoalsDataLoading } from '../redux';
import { getUserGoalsData } from '../redux/actions';
import { userGoalsDataSelector } from '../redux/selectors';

export const useUserGoalsData = (filter?: string): AsyncData<UserGoalPageData[]> => {
  const userGoalsData = useSelector(userGoalsDataSelector);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }
    let payload: GetUserGoalsPayload = { userId: id };
    payload = filter ? { ...payload, filter } : payload;
    dispatch(getUserGoalsData(payload));

    return () => {
      dispatch(setUserGoalsDataLoading());
    };
  }, [id]);

  return userGoalsData;
};
