import React, { useEffect } from 'react';
import { useStyles } from './PromotionPreviewModal.styles';
import { Dialog, DialogContent, IconButton, Link, Stack, Typography } from '@mui/material';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import format from 'date-fns/format';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as SpreadsheetIcon } from '@assets/icons/spreadsheet.svg';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';
import { AddLinkButton } from '../AddLinkButton/AddLinkButton';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserPromotion } from '../../redux/actions';
import { selectAddLinkState } from '../../redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { selectExternalLinks } from '@modules/App/pages/ExternalLinksPage/redux/selectors';

interface Props {
  data: UserPromotionsData;
  isNewRoleShown: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const PromotionPreviewModal: React.FC<Props> = ({ data, isOpen, onClose, isNewRoleShown }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const addLinkState = useSelector(selectAddLinkState);
  const { data: externalLinks } = useSelector(selectExternalLinks);

  const isAddLinkLoading = addLinkState === DataState.Pending;
  const { reason, seniority, effectiveDate, notes, pdpLink, peerInterviewResultsLink, role } = data;

  const handleClose = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    onClose();
  };

  useEffect(() => {
    if (!isAddLinkLoading) {
      onClose();
    }
  }, [addLinkState]);

  const handleAddPdpLink = (value: string) => {
    dispatch(
      updateUserPromotion({
        id: data.id,
        payload: { pdpLink: value },
      })
    );
    onClose();
  };

  const handleAddPeerLink = (value: string) => {
    dispatch(
      updateUserPromotion({
        id: data.id,
        payload: { peerInterviewResultsLink: value },
      })
    );
    onClose();
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogContent className={styles.dialogRoot}>
        <Typography variant="h2" className={styles.title}>
          {format(new Date(effectiveDate), 'MMMM yyyy')}
        </Typography>
        <IconButton
          aria-label="close"
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
          }}
          onClick={(e) => handleClose(e)}
        >
          <CloseIcon />
        </IconButton>
        <Stack gap="24px">
          {isNewRoleShown && (
            <Stack>
              <Typography variant="body2" className={styles.subTitle}>
                New role
              </Typography>
              <Typography variant="body1">{role.name}</Typography>
            </Stack>
          )}
          <Stack direction="row" gap="32px">
            {seniority && (
              <Stack direction="column" gap="4px">
                <Typography variant="body2" className={styles.subTitle}>
                  Seniority
                </Typography>
                <Typography variant="body1">{seniority.name}</Typography>
              </Stack>
            )}
            <Stack direction="column" gap="4px">
              <Typography variant="body2" className={styles.subTitle}>
                Reason
              </Typography>
              <Typography variant="body1">{reason}</Typography>
            </Stack>
          </Stack>
          {notes && (
            <Stack direction="column" gap="4px">
              <Typography variant="body2" className={styles.subTitle}>
                Notes
              </Typography>
              <Typography>{notes}</Typography>
            </Stack>
          )}
          <Stack direction="row" gap="16px">
            {pdpLink || externalLinks?.pdpLink?.link ? (
              <Link
                target="_blank"
                rel="noopener"
                className={styles.link}
                href={pdpLink ?? externalLinks?.pdpLink?.link}
              >
                <SpreadsheetIcon />
                &nbsp; PDP
              </Link>
            ) : (
              <AddLinkButton type={ExternalLinksTypes.pdp} addLink={handleAddPdpLink} />
            )}
            {peerInterviewResultsLink ? (
              <Link target="_blank" rel="noopener" className={styles.link} href={peerInterviewResultsLink}>
                <SpreadsheetIcon />
                &nbsp; Peer interview results
              </Link>
            ) : (
              <AddLinkButton type={ExternalLinksTypes.peerInterviewResultsLink} addLink={handleAddPeerLink} />
            )}
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
