import React, { FC } from 'react';
import { InfoView } from '@shared/components/InfoView/InfoView';
import LockerDynamicColorImg from '@assets/img/locker-dynamic-color.png';
import { Link, Stack, Typography } from '@mui/material';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { initialValuesOther } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { useDispatch } from 'react-redux';

export const CompensationErrorInfoComponent: FC = () => {
  const dispatch = useDispatch();
  const openFeedbackForm = () => dispatch(setIsFeedbackOpen({ isOpen: true, initialValues: initialValuesOther }));

  return (
    <InfoView inComponent>
      <Stack margin="100px 0px" alignItems="center" rowGap="10px">
        <img src={LockerDynamicColorImg} />
        <Typography
          variant="h2"
          sx={{
            color: 'text.secondary',
          }}
          align="center"
        >
          Could not load compensation data
        </Typography>
        <Typography variant="body2" textAlign="center" fontSize="14px">
          Something went wrong,
          <br /> please{' '}
          <Link component="button" onClick={openFeedbackForm}>
            report the issue
          </Link>{' '}
          to Rocky team
        </Typography>
      </Stack>
    </InfoView>
  );
};
