import React, { useEffect, useState } from 'react';
import { useStyles } from './UserGoalsPage.styles';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import classnames from 'classnames';
import { useUserGoalsData } from './hooks/useUserGoals';
import Skeleton from 'react-loading-skeleton';
import { DataState } from '@shared/enums/DataState';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import { DataErrorView } from '@shared/components/DataErrorView';
import EmptyGoalsViewIcon from '@assets/img/emptyGoalsIcon.png';
import { useSelector } from 'react-redux';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { UserGoalPreviewDialog } from '@modules/EvaluationModule/pages/UserGoalsPage/components/UserGoalPreview/UserGoalPreviewDialog';
import { getGoalStatusData } from '@modules/EvaluationModule/pages/UserGoalsPage/helpers/getGoalStatusData';
import { UserGoalStatusIcon } from '@modules/EvaluationModule/pages/UserGoalsPage/components/UserGoalStatusIcon/UserGoalStatusIcon';
import { Table } from '@shared/components/Table';
import { EditGoalsModal } from './components/EditGoalsModal/EditGoalsModal';
import { getFormattedDate } from '@modules/EvaluationModule/pages/UserGoalsPage/helpers/getFormattedDate';
import { NewGoalModal } from './components/NewGoalModal/NewGoalModal';
import { useSearchParams } from 'react-router-dom';
import { GOALS_SEACH_PARAMS, OPEN_NEW_GOAL_MODAL } from './constants/searchParamsNames';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { pageTitles } from '@shared/enums/pageTitles';
import { useTitle } from '@shared/hooks/useTitle';

export const UserGoalsPage: React.FC = () => {
  const styles = useStyles();
  const { data, state, error } = useUserGoalsData();
  const user = useSelector(userSelector);
  const usersFirstName = user && user.name.split(' ')[0];
  const isLoading = state === DataState.Pending;
  const isFailed = state === DataState.Rejected;
  const isGoalsEmpty = data?.length === 0;
  const allGoalsCount = data && data.length;
  const achievedGoalsCount = data?.reduce((acc, el) => {
    if (el.status === GoalsStatus.Achieved) {
      acc += 1;
    }
    return acc;
  }, 0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewGoalOpen, setIsNewGoalOpen] = useState(false);
  const [chosenGoal, setChosenGoal] = useState<UserGoalPageData | null>(null);
  const userActionsPermissions = useSelector(userPermissionsSelector);
  const smartGoalsPermissions = userActionsPermissions.data?.smartGoals;
  const [searchParams, setSearchParams] = useSearchParams();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(`${user?.name} ${pageTitles.goals}`);
  }, [user]);

  useEffect(() => {
    const openParamFromUrl = searchParams.get(GOALS_SEACH_PARAMS);
    if (openParamFromUrl !== OPEN_NEW_GOAL_MODAL) {
      const goalToOpen = data?.find((goal) => goal.id.toString() === openParamFromUrl);
      if (goalToOpen) {
        openModal(goalToOpen);
      }
    }
    if (openParamFromUrl === OPEN_NEW_GOAL_MODAL) {
      openNewGoalModal();
    }
  }, [data]);

  const singleSkeleton = <Skeleton className={styles.singleRowSkeleton} count={1} />;
  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i}>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
      </tr>
    ));

  const openModal = (goal: UserGoalPageData) => {
    setIsModalOpen(true);
    setChosenGoal(goal);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setChosenGoal(null);
    setSearchParams('');
  };

  const openNewGoalModal = () => {
    setIsNewGoalOpen(true);
  };

  const closeNewGoalModal = () => {
    setIsNewGoalOpen(false);
    setSearchParams('');
  };

  const tableContent = data?.map((goal, i) => {
    const { status, objective, category, createdAt, dueDate } = goal;
    const { isOverdue, howLongOverdue, labelColor, percentageOfDone } = getGoalStatusData(goal);

    return (
      <tr className={styles.goalItem} key={i} onClick={() => openModal(goal)}>
        <td>
          <Stack direction="row" spacing={1}>
            <UserGoalStatusIcon status={status} isOverdue={isOverdue} />
            <Stack>
              <Typography variant="body1">{objective}</Typography>
              <Typography variant="body2" className={styles.goalCategory}>
                {category}
              </Typography>
            </Stack>
          </Stack>
        </td>
        <td className={styles.goalDateCreated}>{getFormattedDate(createdAt)}</td>
        <td
          className={classnames(styles.goalDateDue, {
            [styles.goalStatusOverdue]: isOverdue && status === GoalsStatus.InProgress,
          })}
        >
          {isOverdue && status === GoalsStatus.InProgress ? (
            <Tooltip title={`${howLongOverdue} month overdue`} placement="top">
              <Stack direction="row" spacing={0.5}>
                <span>{getFormattedDate(dueDate)}</span>
                <AccessAlarmOutlinedIcon fontSize="small" />
              </Stack>
            </Tooltip>
          ) : (
            <span>{getFormattedDate(dueDate)}</span>
          )}
        </td>
        <td
          className={classnames(styles.goalStatus, {
            [styles.goalStatusProgress]: status === GoalsStatus.InProgress,
            [styles.goalStatusAchieved]: status === GoalsStatus.Achieved,
            [styles.goalStatusCanceled]: status === GoalsStatus.Canceled,
          })}
        >
          {status}
        </td>
        <td className={styles.goalCompleteness}>
          {percentageOfDone !== null ? (
            <Typography
              variant="body2"
              className={classnames(styles.goalCompletenessBadge, {
                [styles.goalCompletenessBadgeOff]: status === GoalsStatus.Canceled,
              })}
              sx={{
                background: labelColor,
              }}
            >
              {percentageOfDone}%
            </Typography>
          ) : (
            <Tooltip title="Pending results" placement="top">
              <AccessTimeOutlinedIcon fontSize="small" />
            </Tooltip>
          )}
        </td>
      </tr>
    );
  });

  const tableHeaders = [
    { title: 'Goal' },
    { title: 'Created' },
    { title: 'Due' },
    { title: 'Status' },
    { title: 'Results' },
  ];

  return isFailed ? (
    <DataErrorView error={error} />
  ) : (
    <>
      <div className={styles.header}>
        <span className={styles.headerTitle}>Goals</span>
        {isLoading
          ? singleSkeleton
          : !isGoalsEmpty && (
              <span className={styles.achievedCount}>
                {achievedGoalsCount} out of {allGoalsCount} achieved
              </span>
            )}
        {!isLoading && !isGoalsEmpty && smartGoalsPermissions?.goals.isCreatable && (
          <span className={styles.newGoalBtnHolder}>
            <NewGoalModal isOpen={isNewGoalOpen} openModal={openNewGoalModal} closeModal={closeNewGoalModal} />
          </span>
        )}
      </div>
      {isGoalsEmpty ? (
        <div className={styles.emptyView}>
          <img src={EmptyGoalsViewIcon} />
          {usersFirstName && <span className={styles.emptyViewTitle}>{usersFirstName} doesn't have any goals yet</span>}
          {smartGoalsPermissions?.goals.isCreatable && (
            <NewGoalModal isOpen={isNewGoalOpen} openModal={openNewGoalModal} closeModal={closeNewGoalModal} />
          )}
        </div>
      ) : (
        <>
          <Table
            isDataLoading={isLoading}
            tableHeaders={tableHeaders}
            skeleton={tableSkeleton}
            customStyles={{
              tableHeaderRow: classnames(styles.tableHeader, styles.goalsTableHeader),
              tableDataRow: styles.goalItem,
            }}
          >
            {tableContent}
          </Table>
          {smartGoalsPermissions?.goals.isUpdatable && chosenGoal?.status !== GoalsStatus.Achieved ? (
            <EditGoalsModal data={chosenGoal as UserGoalPageData} close={closeModal} isModalOpen={isModalOpen} />
          ) : (
            <UserGoalPreviewDialog goal={chosenGoal as UserGoalPageData} close={closeModal} isModalOpen={isModalOpen} />
          )}
        </>
      )}
    </>
  );
};
