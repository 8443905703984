import { createSlice } from '@reduxjs/toolkit';
import { State } from './types';

const initialState: State = {
  title: 'Rocky',
};

export const slice = createSlice({
  name: 'pageTitle',
  initialState,
  reducers: {
    setTitle(state, { payload }) {
      state.title = payload;
    },
  },
});

export const { setTitle } = slice.actions;

export default slice.reducer;
