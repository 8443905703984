export enum paths {
  root = '/',
  auth = '/auth',
  login = '/login',
  unauthorized = '/unauthorized',
  myTeam = '/my-team',
  peerReviews = 'peer-reviews',
  users = '/users',
  surveyManagement = '/survey-management',
  surveyHistory = '/survey-history',
  evaluations = 'evaluations',
  responses = 'responses',
  myPeopleEvals = '/my-people-evals',
  chat = '/chat',
  clientEval = '/client-form',
  peerForm = '/peer-form',
  settings = 'settings',
  summary = 'summary',
  endOfProbation = 'end-of-probation',
  orgChart = '/org-chart',
  clientContacts = '/client-contacts',
  profile = '',
  flags = 'flags',
  allPeople = '/all-people',
  allFlags = '/all-flags',
  bonuses = 'bonuses',
  transition = 'transition',
  compensation = 'compensation',
  clientSurveyResponse = 'client-survey-response',
  clientSurveys = 'client-surveys',
  whatIsNew = 'what-is-new',
  links = 'links',
  goals = 'goals',
  edit = 'edit',
  supportInfo = 'support-info',
  promotions = 'career',
  termsOfUse = 'terms-of-use',
  usersAlias = '/users/alias',
  userNotRegistered = '/unregistered',
}
