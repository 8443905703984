export enum PersonalPermissions {
  Read = 'USER:INFO:PERSONAL:READ',
  Update = 'USER:INFO:PERSONAL:UPDATE',
}

export enum PublicPermissions {
  Update = 'USER:INFO:PUBLIC:UPDATE',
}

export enum LegalLocationPermissions {
  Read = 'USER:INFO:LEGAL_LOCATION:READ',
}

export type UserInfoPermissions = PersonalPermissions | PublicPermissions | LegalLocationPermissions;
