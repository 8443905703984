import { FC, memo } from 'react';
import { FlagChangeEvent } from '@modules/HappinessModule/interfaces/FlagHistory';
import { useDispatch } from 'react-redux';
import { useStyles } from './FlagHistory.styles';
import { FlagSnapshotFieldsLabels } from '@modules/HappinessModule/pages/AllFlagsPage/constants/constants';
import { Typography } from '@mui/material';
import { getFlagSnapshotAction } from '../../pages/EditFlagFormPage/redux/actions';
import { getHistoryChangeDate } from '@shared/helpers/getHistoryChangeDate';

interface Props {
  flagChangeEvents: FlagChangeEvent[];
}

const FlagHistoryComponent: FC<Props> = ({ flagChangeEvents }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const flagCreatedHistoryObj = [...flagChangeEvents].sort((a, b) => (new Date(a.date) > new Date(b.date) ? 1 : -1))[0];
  const flagHistoryFiltered = flagChangeEvents.filter((flagHistory) =>
    flagHistory.id !== flagCreatedHistoryObj.id ? Object.keys(flagHistory.changes.newValues).length > 0 : true
  );

  return (
    <div className={styles.root}>
      <Typography variant="subtitle1" mb="7px" ml="13px">
        Flag history
      </Typography>
      <div className={styles.flagHistory}>
        {flagHistoryFiltered?.map((flagHistory) => (
          <div
            key={flagHistory.id}
            className={styles.flagHistoryItem}
            onClick={() =>
              dispatch(
                getFlagSnapshotAction({
                  snapshotId: flagHistory.id,
                })
              )
            }
          >
            <Typography variant="body2">
              {flagHistory.author.name}{' '}
              <Typography variant="caption" component="span">
                • {getHistoryChangeDate(flagHistory.date)}
              </Typography>
            </Typography>
            <div>
              {flagCreatedHistoryObj.id === flagHistory.id ? (
                <Typography variant="body1">Flag created</Typography>
              ) : (
                <>
                  <Typography fontWeight={500}>Flag updates:</Typography>
                  {Object.entries(flagHistory.changes.newValues).map(([key, value]) => (
                    <Typography key={key} variant="body1" className={styles.flagChanges}>
                      {`${Object.entries(flagHistory.changes.newValues).length > 1 ? '- ' : ''}`}
                      <span>{FlagSnapshotFieldsLabels[key]}</span>
                      {key === FlagSnapshotFieldsLabels.severity && ` changed to ${value}`}
                    </Typography>
                  ))}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export const FlagHistory = memo(FlagHistoryComponent);
export default FlagHistory;
