import React, { FC, memo } from 'react';
import classNames from 'classnames';
import { useStyles } from './SubmitEvalFormModalContent.styles';
import { RockyButton } from '@shared/components/RockyButton';

interface Props {
  onConfirm: () => void;
  onCancel: () => void;
}

const SubmitEvalFormModalContentComponent: FC<Props> = ({ onConfirm, onCancel }) => {
  const styles = useStyles();

  return (
    <div className={styles.root}>
      <div className={styles.content}>
        <p className={classNames(styles.text, styles.warningText)}>
          After submitting you will not be able to edit your response.
        </p>
        <p className={styles.text}>Make sure you've written down everything you wanted to say.</p>
      </div>
      <div className={styles.modalFooter}>
        <RockyButton className={styles.button} onClick={onCancel}>
          Cancel
        </RockyButton>
        <RockyButton
          type="submit"
          action
          className={classNames(styles.button, styles.buttonSubmit)}
          onClick={() => {
            onConfirm();
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
        >
          Yes, submit response
        </RockyButton>
      </div>
    </div>
  );
};

export const SubmitEvalFormModalContent = memo(SubmitEvalFormModalContentComponent);
