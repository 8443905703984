import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  severityContainer: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      marginRight: 10,
    },
  },
});
