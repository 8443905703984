import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { MetroAreaData } from '@shared/interfaces/MetroAreaData';
import { UserProfileData } from '@shared/interfaces/user';
import { State } from './types';
import { UserAddressFormValuesType } from '../componenes/EditUserAddressComponent/constants/userAddressFormFields';

const initialState: State = {
  userProfileData: { data: null, state: DataState.Idle },
  physicalAddressUpdatingState: DataState.Idle,
  userContactsUpdatingState: DataState.Idle,
  userContactsUpdateNetworkError: null,
  metroAreas: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: 'userProfilePage',
  initialState,
  reducers: {
    setUserProfileDataLoading(state) {
      state.userProfileData = { data: null, state: DataState.Pending };
    },
    setUserProfileData(state, action: PayloadAction<AsyncData<UserProfileData>>) {
      state.userProfileData = action.payload;
    },
    setUserAddressUpdatingState(state, action: PayloadAction<DataState>) {
      state.physicalAddressUpdatingState = action.payload;
    },
    setEditUserContactsUpdatingState(state, action: PayloadAction<DataState>) {
      state.userContactsUpdatingState = action.payload;
    },
    setUserContactsUpdateNetworkError(state, { payload }) {
      state.userContactsUpdateNetworkError = payload;
    },
    addToUserProfileDataUpdatedPhysicalAddress(state, action: PayloadAction<UserAddressFormValuesType>) {
      if (!state.userProfileData.data) {
        return;
      }
      state.userProfileData.data = {
        ...state.userProfileData.data,
        physicalAddress: action.payload,
        selectedMetroArea: action.payload.selectedMetroArea,
      };
    },
    setMetroAreasData(state, action: PayloadAction<AsyncData<MetroAreaData>>) {
      state.metroAreas = action.payload;
    },
  },
});

export const {
  setUserProfileDataLoading,
  setUserProfileData,
  setUserAddressUpdatingState,
  addToUserProfileDataUpdatedPhysicalAddress,
  setEditUserContactsUpdatingState,
  setUserContactsUpdateNetworkError,
  setMetroAreasData,
} = slice.actions;

export default slice.reducer;
