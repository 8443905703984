import { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import StarOutlineRoundedIcon from '@mui/icons-material/StarOutlineRounded';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import TheaterComedyOutlinedIcon from '@mui/icons-material/TheaterComedyOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import HistoryOutlinedIcon from '@mui/icons-material/HistoryOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined';
import SecurityIcon from '@mui/icons-material/Security';
import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
import CollectionsBookmarkOutlinedIcon from '@mui/icons-material/CollectionsBookmarkOutlined';
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { useStyles } from './SidebarContentItems.styles';
import { SidebarLink } from '../SidebarLink';
import { paths } from '@shared/enums/paths';
import { User } from '@shared/interfaces/user';
import { SearchButton } from '@shared/components/SearchButton';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { pageAccessibilityByRoles } from '@shared/consts/pageAccessibilityByRoles';
import { setIsGlobalSearchOverlayOpen } from '@modules/App/redux/users';
import { selectIsGlobalUsersSearchOpened } from '@modules/App/redux/users/selectors';
import { ExpandableGroup } from '../ExpandableGroup/ExpandableGroup';
import { Feedback } from '../Feedback';
import { FeedbackFormData } from '@modules/App/interfaces/FeedbackFormData';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { initialValuesOther } from '../Feedback/constants/feedbackInitialValues';

interface Props {
  currentUser: User;
  isTabletMenuOpen: boolean;
  setIsTabletMenuOpen: (bool: boolean | ((state: boolean) => boolean)) => void;
}

const SidebarContentItemsComponent: FC<Props> = ({ currentUser, isTabletMenuOpen, setIsTabletMenuOpen }) => {
  const styles = useStyles();
  const tabletProps = { setIsTabletMenuOpen, isTabletMenuOpen };
  const { isFlagsEnabled, isCsatEnabled } = useUserEnabledFeatures();

  const SidebarLinkSpanStyle = classNames(styles.navText, { [styles.shown]: isTabletMenuOpen });

  const dispatch = useDispatch();

  const isGlobalSearchOverlayOpen = useSelector(selectIsGlobalUsersSearchOpened);

  const handleGlobalSearchOverlayOpen = useCallback(() => {
    dispatch(setIsGlobalSearchOverlayOpen(true));
  }, [isGlobalSearchOverlayOpen]);

  const openFeedbackForm = (initialValues: FeedbackFormData) =>
    dispatch(setIsFeedbackOpen({ isOpen: true, initialValues }));

  return (
    <>
      <SidebarLink
        {...tabletProps}
        otherRoutes={[paths.chat, paths.peerReviews]}
        to={`${paths.users}/${currentUser?.id}/`}
        currentUser={currentUser}
      >
        <StarOutlineRoundedIcon />
        <span className={SidebarLinkSpanStyle}>My profile</span>
      </SidebarLink>
      <SidebarLink
        {...tabletProps}
        to={`${paths.myTeam}/${paths.responses}`}
        isShown={currentUser.hasReporters}
        currentUser={currentUser}
        otherRoutes={[`${paths.myTeam}/${paths.evaluations}`, `${paths.myTeam}/${paths.flags}`]}
      >
        <GroupsOutlinedIcon />
        <span className={SidebarLinkSpanStyle}>My team</span>
      </SidebarLink>
      <SidebarLink
        {...tabletProps}
        to={paths.allPeople}
        isShown={currentUser.hasAvailableHappinessExceptOwn}
        currentUser={currentUser}
      >
        <TheaterComedyOutlinedIcon />
        <span className={SidebarLinkSpanStyle}>People happiness</span>
      </SidebarLink>
      {isFlagsEnabled && (
        <SidebarLink
          {...tabletProps}
          to={paths.allFlags}
          currentUser={currentUser}
          isShown={
            // refactor using the existing interfaces
            currentUser.permissions && currentUser.permissions.some((permission) => permission.includes('FLAGS:'))
          }
        >
          <FlagOutlinedIcon />
          <span className={SidebarLinkSpanStyle}>All flags</span>
        </SidebarLink>
      )}
      <SidebarLink
        {...tabletProps}
        to={paths.myPeopleEvals}
        currentUser={currentUser}
        isShown={currentUser.hasAvailableEvaluationsExceptOwn}
      >
        <WorkspacePremiumOutlinedIcon />
        <span className={SidebarLinkSpanStyle}>Evaluations</span>
      </SidebarLink>
      <SidebarLink
        {...tabletProps}
        to={`${paths.clientContacts}`}
        otherRoutes={[paths.clientContacts]}
        roles={pageAccessibilityByRoles[paths.clientContacts]}
        currentUser={currentUser}
      >
        <BusinessCenterOutlinedIcon />
        <span className={SidebarLinkSpanStyle}>Clients</span>
      </SidebarLink>
      {isCsatEnabled && (
        <SidebarLink
          {...tabletProps}
          to={paths.clientSurveys}
          roles={pageAccessibilityByRoles[paths.clientSurveys]}
          currentUser={currentUser}
        >
          <WorkspacePremiumOutlinedIcon />
          <span className={SidebarLinkSpanStyle}>Client surveys</span>
        </SidebarLink>
      )}
      <SidebarLink
        {...tabletProps}
        to={paths.surveyManagement}
        roles={pageAccessibilityByRoles[paths.surveyManagement]}
        currentUser={currentUser}
      >
        <CalendarMonthOutlinedIcon />
        <span className={SidebarLinkSpanStyle}>Survey schedule</span>
      </SidebarLink>
      <SidebarLink
        {...tabletProps}
        to={paths.surveyHistory}
        roles={pageAccessibilityByRoles[paths.surveyHistory]}
        currentUser={currentUser}
      >
        <HistoryOutlinedIcon />
        <span className={SidebarLinkSpanStyle}>Survey history</span>
      </SidebarLink>
      <SidebarLink {...tabletProps} to={paths.orgChart} currentUser={currentUser}>
        <AccountTreeOutlinedIcon />
        <span className={SidebarLinkSpanStyle}>Teams and people</span>
      </SidebarLink>
      <a
        className={classNames(styles.navItem)}
        href="https://sites.google.com/agileengine.com/agileengine-ua/home"
        target="_blank"
      >
        <CollectionsBookmarkOutlinedIcon />
        <p className={styles.navText}>AE internal site ↗</p>
      </a>
      <ExpandableGroup>
        <a
          className={classNames(styles.navItem)}
          href="https://sites.google.com/agileengine.com/agileengine-ua/home/ae-policies"
          target="_blank"
        >
          <HandshakeOutlinedIcon />
          <p className={styles.navText}>AE policies ↗</p>
        </a>
        <SidebarLink currentUser={currentUser} {...tabletProps} to={paths.termsOfUse}>
          <SecurityIcon />
          <span className={SidebarLinkSpanStyle}>Terms Of Use</span>
        </SidebarLink>
        <div className={classNames(styles.navItem)} onClick={() => openFeedbackForm(initialValuesOther)}>
          <BugReportOutlinedIcon />
          <div>
            <p className={styles.navText}>Report issue</p>
          </div>
        </div>
        <SidebarLink to={paths.whatIsNew}>
          <ErrorOutlineIcon />
          <div>
            <p className={styles.navText}>What's new</p>
          </div>
        </SidebarLink>
      </ExpandableGroup>
      <Feedback />
      <div className={styles.searchButton}>
        <SearchButton onClick={handleGlobalSearchOverlayOpen} />
      </div>
    </>
  );
};

export const SidebarContentItems = memo(SidebarContentItemsComponent);
