export const normalizeCountry = (country: string): string => {
  const exceptionalCountries = new Map([
    ['USA', 'United States'],
    ['United states', 'United States'],
    ['Czechia', 'Czech Republic'],
    ['United kingdom', 'United Kingdom'],
  ]);
  const result = exceptionalCountries.get(country);
  return result ? result : country;
};
