import React, { FC } from 'react';
import { PeerReviewFeedbackTableDataItem } from '@modules/EvaluationModule/interfaces/PeerReviewFeedbackTableDataItem';
import { useStyles } from './MyPeerReviewsTable.styles';
import { Table } from '@shared/components/Table';
import { SortInfo } from '@shared/interfaces/sortInfo';
import Skeleton from 'react-loading-skeleton';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { RockyButton } from '@shared/components/RockyButton';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { FeedbackStatus } from '@modules/EvaluationModule/components/FeedbackStatus';
import isSameYear from 'date-fns/isSameYear';
import { MyPeerReviewDataFields } from '../../constants/FlagFormFields';
import classnames from 'classnames';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';

interface Props {
  data: PeerReviewFeedbackTableDataItem[] | null;
  sortInfo: SortInfo;
  isDataLoading: boolean;
  onSortClick: (sortInfo: SortInfo) => void;
  onRowClick: (
    id: string,
    evaluationId: string,
    evaluationStatus: EvaluationStatus,
    status: EvalFeedbackStatus
  ) => void;
}

export const MyPeerReviewsTable: FC<Props> = ({ data, sortInfo, isDataLoading, onSortClick, onRowClick }) => {
  const styles = useStyles();

  const tableHeaders = [
    { title: 'Peer' },
    { title: MyPeerReviewDataFields.evaluationDate.label, fieldName: MyPeerReviewDataFields.evaluationDate.name },
    { title: 'Manager' },
    { title: '' },
  ];

  const status: { [index: string]: number } = {};

  Object.values(EvalFeedbackStatus).forEach((value: string) => {
    const sortLevel =
      value === EvalFeedbackStatus.Rejected
        ? 1
        : value === EvalFeedbackStatus.Pending
        ? 2
        : value === EvalFeedbackStatus.Review
        ? 3
        : 4;
    status[value] = sortLevel;
  });

  const tableContent =
    data &&
    data
      .sort((a, b) => status[a.status] - status[b.status])
      .map((peerReviewItem) => {
        const { evaluator, evaluatee, evaluationDate, evaluationStatus, peerFeedbackId, evaluationId, status } =
          peerReviewItem;

        const actionButton =
          status === EvalFeedbackStatus.Pending && evaluationStatus !== EvaluationStatus.Closed ? (
            <RockyButton small action>
              Provide feedback
            </RockyButton>
          ) : status === EvalFeedbackStatus.Rejected && evaluationStatus !== EvaluationStatus.Closed ? (
            <RockyButton small action>
              Update feedback
            </RockyButton>
          ) : (
            <></>
          );

        return (
          <tr
            key={peerFeedbackId}
            className={classnames(styles.tableDataRow, {
              [styles.disabledTableDataRow]:
                evaluationStatus === EvaluationStatus.Closed &&
                (status === EvalFeedbackStatus.Pending || status === EvalFeedbackStatus.Rejected),
            })}
            onClick={() => {
              onRowClick(peerFeedbackId, evaluationId, evaluationStatus, status);
            }}
          >
            <td className={styles.peer}>
              <div className={styles.memberBlock}>
                <UserReporterBlock isDetailInfo={true} user={evaluatee} />
              </div>
            </td>
            <td className={styles.peerReviewDate}>
              <div className={styles.memberBlock}>
                <span className={styles.evaluationDate}>
                  {isSameYear(parseISO(evaluationDate), new Date())
                    ? format(parseISO(evaluationDate), 'MMMM')
                    : format(parseISO(evaluationDate), 'MMM yyyy')}
                </span>
              </div>
            </td>
            <td className={styles.peerManager}>
              <div className={styles.memberBlock}>
                <UserReporterBlock isDetailInfo={false} user={evaluator} />
              </div>
            </td>
            <td className={styles.peerReviewStatus}>
              <div className={styles.statusBlock}>
                <FeedbackStatus status={status} />
                {actionButton}
              </div>
            </td>
          </tr>
        );
      });

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i} className={styles.tableDataRow}>
        <td>
          <div className={styles.personBlockSkeleton}>
            <Skeleton className={styles.personAvatarSkeleton} />
            <div className={styles.personInfoBlockSkeleton}>
              <Skeleton className={styles.personNameSkeleton} />
              <Skeleton className={styles.positionSkeleton} />
            </div>
          </div>
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <div className={styles.personBlockSkeleton}>
            <Skeleton className={styles.personAvatarSkeleton} />
            <div className={styles.personInfoBlockSkeleton}>
              <Skeleton className={styles.personNameSkeleton} />
            </div>
          </div>
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
      </tr>
    ));

  return (
    <div className={styles.root}>
      <Table
        sortInfo={sortInfo}
        isDataLoading={isDataLoading}
        onSortClick={onSortClick}
        tableHeaders={tableHeaders}
        skeleton={tableSkeleton}
        customStyles={{ tableHeaderRow: styles.tableHeaderRow, tableDataRow: styles.tableDataRow }}
      >
        {tableContent}
      </Table>
    </div>
  );
};
