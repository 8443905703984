import { put, all, call, takeLeading } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import { getPermissions } from './actions';
import { setPermissions, setPermissionsLoading } from './reducers';
import { getUserPermissions } from '@modules/App/api/permissions';
import { UserActionsPermissions } from '@modules/App/interfaces/UserActionsPermissions';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';

function* getPermissionsAsync({ payload }: ReturnType<typeof getPermissions>) {
  yield put(setPermissionsLoading());
  try {
    const response: UserActionsPermissions = yield call(getUserPermissions, payload);
    const setAllFlagsDataAction = setPermissions({
      data: response,
      state: DataState.Fulfilled,
    });
    yield put(setAllFlagsDataAction);
  } catch (e) {
    yield put(
      setPermissions({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* watchPermissionData() {
  yield takeLeading(getPermissions.type, getPermissionsAsync);
}

export function* permissionsSaga(): Generator {
  yield all([watchPermissionData()]);
}
