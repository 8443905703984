import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  pageTitle: {
    paddingLeft: 23,
    paddingRight: 23,
    marginTop: 22,
    marginBottom: 15,
  },
  loaderContainer: {
    padding: '0 22px',
  },
  tableHeaderRow: {
    display: 'none',
  },
  loader: {
    height: 62,
    width: '100%',
  },
  emptyScreenContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '68px 0',
  },
  emptyScreemImg: {
    width: 127,
    display: 'block',
    marginBottom: 32,
  },
  emptyScreenTitle: {
    textAlign: 'center',
    color: COLORS.TEXT.SECONDARY,
  },
});
