import React, { FC } from 'react';
import { LinkManageButton } from '@modules/App/pages/ExternalLinksPage/components/LinkManageButton/LinkManageButton';
import { DataState } from '@shared/enums/DataState';
import { useStyles } from './EvaluationLinkButton.styles';
import { useParams } from 'react-router';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';
import { selectExternalLinks } from '@modules/App/pages/ExternalLinksPage/redux/selectors';
import { useSelector } from 'react-redux';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';

export const EvaluationLinkButton: FC = () => {
  const styles = useStyles();
  const { id } = useParams();
  const { data, state } = useSelector(userPermissionsSelector);
  const isEvaluationEditable = !!data && data?.externalLinks?.evaluation?.isUpdatable;
  const canView = Boolean(data?.externalLinks?.evaluation?.isReadable);
  const { data: externalLinks, state: externalLinksDataState } = useSelector(selectExternalLinks);
  const isDataLoading = state === DataState.Pending || externalLinksDataState === DataState.Pending;

  return isDataLoading ? (
    <div className={styles.spinner} />
  ) : (
    <>
      {canView && (
        <div className={styles.linkContainer}>
          <LinkManageButton
            isOutside
            link={externalLinks?.evaluationLink ?? null}
            id={id!}
            canEdit={isEvaluationEditable}
            type={ExternalLinksTypes.evaluation}
          />
        </div>
      )}
    </>
  );
};
