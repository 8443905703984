import React, { FC, useState } from 'react';
import { useStyles } from './RockyDatePicker.styles';
import { DatePicker, DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';
import ArrowDown from '@mui/icons-material/ExpandMore';
import ArrowUp from '@mui/icons-material/ExpandLess';
import classNames from 'classnames';
import { RockySpinner } from '@shared/components/RockySpinner';
import { ReactComponent as Spinner } from '@assets/icons/Spinner.svg';
import { SEND_DATE_FORMAT } from '../../../modules/HappinessModule/pages/ClientSurveysPage/constants/constants';

export const RockyDatePicker: FC<DatePickerProps<string, string>> = (props) => {
  const { disabled, loading } = props;
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <DatePicker
      open={open}
      disabled={disabled}
      components={{
        OpenPickerIcon: loading ? Spinner : open ? ArrowUp : ArrowDown,
      }}
      disableMaskedInput
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      InputProps={{ onFocus: (e) => e.target.blur() }}
      inputFormat={SEND_DATE_FORMAT}
      className={classNames(styles.datePicker, {
        [styles.datePickerDisabled]: disabled,
        [styles.datePickerLoading]: loading,
      })}
      renderLoading={() => <RockySpinner />}
      {...props}
      renderInput={(params) => <TextField onClick={() => setOpen(true)} {...params} variant="filled" />}
    />
  );
};
