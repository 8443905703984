import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  fieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
  },
  warnPopupBody: {
    margin: '30px 10px 0 10px',
  },
  networkError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    textAlign: 'right',
    marginRight: 24,
  },
});
