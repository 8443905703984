import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
  },
  messageBody: {
    padding: '20px 20px 22px 20px',
    maxWidth: 325,
    minWidth: 100,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    backgroundColor: COLORS.WHITE,
    position: 'relative',
    margin: '0 60px',

    [BREAKPOINTS.MOBILE]: {
      maxWidth: 285,
      margin: 0,
    },
  },

  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },
  '@keyframes slideLeft': {
    '0%': {
      transform: 'translate(30px)',
    },
    '100%': {
      transform: 'translate(0)',
    },
  },
  '@keyframes slideRight': {
    '0%': {
      transform: 'translate(-30px)',
    },
    '100%': {
      transform: 'translate(0)',
    },
  },
  userMessage: {
    marginLeft: 'auto',
    backgroundColor: COLORS.HIGHLIGHTS.NOTE,
    animation: '$fadeIn 0.3s, $slideLeft 0.3s',
  },
  rockyMessage: {
    animation: '$fadeIn 0.3s, $slideRight 0.3s',
  },
  rockyMessageTail: {
    position: 'absolute',
    top: 20,
    left: -15,
    width: 29,
    height: 29,
    fill: COLORS.WHITE,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  userMessageTail: {
    left: 'auto',
    fill: COLORS.HIGHLIGHTS.NOTE,
    top: 20,
    right: -15,
    transform: 'scaleX(-1)',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  date: {
    position: 'absolute',
    right: 5,
    bottom: 1,
    fontSize: '10px',
    color: COLORS.TEXT.DISABLED,
  },
  gifImage: {
    minWidth: 200,
    maxWidth: 275,
    height: 'auto',
  },
  messageText: {
    margin: 0,
    overflowWrap: 'break-word',
  },
  revote: {
    textDecoration: 'underline',
    cursor: 'pointer',
    color: COLORS.ACTION.PRIMARY,
  },
});
