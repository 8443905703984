import { FC, MouseEvent, useEffect, useRef, useCallback, useState } from 'react';
import { emojisplosion, EmojisplosionSettings } from 'emojisplosion';
import classNames from 'classnames';
import { getBackgroundColorByRate } from '@modules/HappinessModule/helpers/getBackgroundColorByRate';
import { RockyButton } from '@shared/components/RockyButton';
import { useStyles } from './RockyVoteBlock.styles';
import { emojiVariants } from '@shared/consts/emojiVariants';
import { RockySkeleton } from '../RockySkeleton';

interface Props {
  survey: string | null;
  description?: string;
  score: number | null;
  isWithEmojiExplosion?: boolean;
  isDisabled?: boolean;
  onClick: (score: number) => void;
  isScoreChanging?: boolean;
  isScoreFromUrl?: boolean;
  isLoading?: boolean;
  isScoreSuccessfullyChanged?: boolean;
}

const LAUNCHEMOJISPLOSION_DELAY_MS = 300;

export const RockyVoteBlock: FC<Props> = ({
  survey = 'No survey',
  description = '',
  score,
  isWithEmojiExplosion,
  isDisabled,
  onClick,
  isScoreChanging,
  isScoreFromUrl,
  isLoading,
  isScoreSuccessfullyChanged,
}) => {
  const styles = useStyles();
  const scoreBtns = useRef<(HTMLDivElement | null)[]>([]);
  const [emojisplosionSettings, setEmojisplosionSettings] = useState<Partial<EmojisplosionSettings> | null>(null);

  useEffect(() => {
    if (emojisplosionSettings && isScoreSuccessfullyChanged) {
      emojisplosion(emojisplosionSettings);
      setEmojisplosionSettings(null);
    }
  }, [emojisplosionSettings, isScoreFromUrl]);

  useEffect(() => {
    const markExplosion = emojiVariants.find(({ mark }) => mark === score);
    if (!score || !markExplosion || !isWithEmojiExplosion) {
      return;
    }
    const currentscoreBtn = scoreBtns.current[score - 1];
    if (!currentscoreBtn) {
      return;
    }
    setTimeout(() => {
      setEmojisplosionSettings({
        emojis: markExplosion.emojis,
        position: {
          x: currentscoreBtn.getBoundingClientRect().right,
          y: currentscoreBtn.getBoundingClientRect().bottom,
        },
        physics: {
          fontSize: markExplosion.fontSize,
        },
      });
    }, LAUNCHEMOJISPLOSION_DELAY_MS);
  }, [isWithEmojiExplosion, score, isDisabled]);

  const handleBtnClick = useCallback(
    (emojisplosionSettings: Partial<EmojisplosionSettings>, mark: number) => {
      if (isWithEmojiExplosion) {
        setEmojisplosionSettings(emojisplosionSettings);
      }
      onClick(mark);
    },
    [isWithEmojiExplosion]
  );

  return (
    <div className={styles.root}>
      <div className={styles.messageBody}>
        <RockySkeleton
          className={styles.question}
          element={
            <>
              <p className={styles.question}>{survey}</p>
              <p className={styles.description}>{description}</p>
            </>
          }
          isLoading={!!isLoading}
        />
        <div className={styles.buttonContainer}>
          <>
            {emojiVariants.map(({ mark, emojis, fontSize }, i) => {
              const isCurrentScore = mark === score;

              return (
                <div key={i} ref={(el) => (scoreBtns.current[i] = el)}>
                  <RockySkeleton
                    className={styles.skeletonBtn}
                    element={
                      <RockyButton
                        className={classNames(styles.button, {
                          [styles.activeButton]: isCurrentScore,
                          [styles.selectedBtnScore]: score && !isCurrentScore,
                          [styles.selectedBtnDisabled]: isDisabled && !isCurrentScore,
                        })}
                        style={{
                          backgroundColor: isCurrentScore
                            ? getBackgroundColorByRate(score)
                            : isDisabled
                            ? ''
                            : score
                            ? 'rgba(0, 124, 255, 0.08)'
                            : getBackgroundColorByRate(i + 1),
                        }}
                        disabled={isDisabled && !isCurrentScore}
                        onClick={(e: MouseEvent<HTMLElement>) => {
                          handleBtnClick(
                            {
                              emojis,
                              position: { x: e.clientX, y: e.clientY },
                              physics: {
                                fontSize,
                              },
                            },
                            mark
                          );
                        }}
                        isLoading={isScoreChanging && isCurrentScore}
                      >
                        {mark}
                      </RockyButton>
                    }
                    isLoading={!!isLoading}
                  />
                </div>
              );
            })}
          </>
        </div>
      </div>
    </div>
  );
};
