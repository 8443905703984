import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  nextEvalLabel: {
    fontWeight: 700,
    fontSize: 13,
    color: COLORS.TEXT.SECONDARY,
    marginLeft: 20,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      marginRight: 10,
    },
  },
});
