import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  evaluateeBlock: {
    width: 420,
    marginTop: 30,
    marginBottom: 10,
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    fontWeight: '400',
    fontSize: 18,
    lineHeight: '130%',
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      gap: 16,
      marginTop: 16,

      '& div img': {
        margin: 0,
      },
    },
  },
  markContainer: {
    marginBottom: 52,
    display: 'flex',

    [BREAKPOINTS.MOBILE]: {
      marginBottom: 24,
    },
  },
  markLabel: {
    fontSize: '14px',
  },
  thanksBlock: {
    marginLeft: -20,
    marginRight: -20,
    marginBottom: 15,
    padding: '15px 20px',
    width: 564,
    background: COLORS.HIGHLIGHTS.PROMINENT,
    borderRadius: 8,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
    lineHeight: '135%',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
      padding: 16,
      margin: '0 -16px 16px',
      fontWeight: 400,
      borderRadius: 0,
    },
  },
  marksBlock: {
    display: 'flex',
    flexDirection: 'column',
    gap: 15,
  },
  textFieldBlock: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',

    '& p': {
      margin: '0 0 8px 0',
      fontWeight: '500',
      fontSize: '14px',
      color: COLORS.TEXT.PRIMARY,
    },

    '& textarea': {
      marginBottom: 20,
      resize: 'none',
      height: 75,
      borderRadius: 4,
      padding: '12px 18px',
    },

    '& button': {
      width: 180,

      [BREAKPOINTS.MOBILE]: {
        width: '100%',
      },
    },
  },
});
