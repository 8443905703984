export enum PeerFeedbackStatusPermissions {
  Read = 'EVALUATIONS:FEEDBACK:STATUS:READ',
  Update = 'EVALUATIONS:FEEDBACK:STATUS:UPDATE',
}

export enum PeerFeedbackInformationPermissions {
  Read = 'EVALUATIONS:FEEDBACK:INFORMATION:READ',
  Update = 'EVALUATIONS:FEEDBACK:INFORMATION:UPDATE',
}

export type PeerFeedbackPermissions = PeerFeedbackStatusPermissions | PeerFeedbackInformationPermissions;
