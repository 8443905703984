import {
  addClientToReviewers,
  addPeerFeedback,
  deleteClientFeedback,
  deletePeerFeedback,
  getClientsSuggestions,
  getPeerSuggestions,
} from '@modules/EvaluationModule/api/evalSettingsData';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { DataState } from '@shared/enums/DataState';
import { all, call, put, takeLatest, select, take, takeLeading, takeEvery } from 'redux-saga/effects';
import {
  addClientToReviewersAction,
  addPeerFeedbackAction,
  deleteClientFeedbackAction,
  deletePeerFeedbackAction,
  getClientsSuggestionsAction,
  getPeerSuggestionsAction,
} from './actions';
import { setClientsSuggestionsDataState, setPeerSuggestionsDataState } from './reducers';
import { RootState } from '@modules/App/redux/store';
import { setUserEvalPageData } from '../../EvalPage/redux/actions';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { v4 as uuidv4 } from 'uuid';
import { ImageUrls } from '@shared/enums/ImageUrls';
import cloneDeep from 'lodash/cloneDeep';
import { selectEvalPageData } from './selectors';
import { PeerSuggestions } from '@modules/EvaluationModule/interfaces/PeerSuggestions';

function* addPeerFeedbackActionAsync({ payload }: ReturnType<typeof addPeerFeedbackAction>) {
  try {
    const evalData: EvalPageData | null = yield select(
      (state: RootState) => state.evaluation.evalPage.evalPageData.data
    );

    if (!evalData) return;

    const peersReview = payload.map(({ user }) => ({
      id: '',
      user,
      status: EvalFeedbackStatus.Pending,
      feedback: null,
    }));

    const updatedEvalData = {
      ...evalData,
      peerReviews: evalData.peerReviews ? [...evalData.peerReviews, ...peersReview] : [...peersReview],
    };
    const setEvalDataWithPeerReviewsAction = setUserEvalPageData({
      data: updatedEvalData,
      state: DataState.Fulfilled,
    });
    yield put(setEvalDataWithPeerReviewsAction);

    const peerSuggestion: PeerSuggestions = yield select(
      (state: RootState) => state.evaluation.evalSettingsPage.peerSuggestions.data
    );
    const updatedPeerSuggestion = {
      ...peerSuggestion,
      suggestedPeers: peerSuggestion.suggestedPeers.filter((peer) => payload.every(({ user }) => peer.id !== user.id)),
    };
    const action = setPeerSuggestionsDataState({
      data: updatedPeerSuggestion,
      state: DataState.Fulfilled,
    });
    yield put(action);

    const feedbacksId: Array<{ [key: string]: string }> = yield call(() =>
      Promise.all(
        payload.map((peer) =>
          addPeerFeedback(peer).then(
            (id) =>
              peer.user.id && {
                [peer.user.id]: id,
              }
          )
        )
      )
    );

    const setEvalDataWithUpdatedPeerReviewsAction = setUserEvalPageData({
      data: {
        ...updatedEvalData,
        peerReviews:
          updatedEvalData.peerReviews?.map((peerReview) => {
            if (!peerReview.user.id) return peerReview;

            const feedback = feedbacksId.find((feedback) => feedback[peerReview.user.id as string]) || {};

            const feedbackId = feedback[peerReview.user.id];

            return feedbackId ? { ...peerReview, id: feedbackId } : peerReview;
          }) || null,
      },
      state: DataState.Fulfilled,
    });
    yield put(setEvalDataWithUpdatedPeerReviewsAction);
  } catch (e) {
    yield put(displayNotification("Couldn't add peer feedback"));
  }
}

function* deletePeerFeedbackActionAsync({ payload }: ReturnType<typeof deletePeerFeedbackAction>) {
  try {
    const peerSuggestion: PeerSuggestions = yield select(
      (state: RootState) => state.evaluation.evalSettingsPage.peerSuggestions.data
    );
    if (!peerSuggestion) {
      return;
    }
    const updatedPeerSuggestion = {
      ...peerSuggestion,
      suggestedPeers: [...peerSuggestion.suggestedPeers, payload.user],
    };

    const action = setPeerSuggestionsDataState({
      data: updatedPeerSuggestion,
      state: DataState.Fulfilled,
    });
    yield put(action);
    yield call(deletePeerFeedback, { evalId: payload.evalId, feedbackId: payload.feedbackId });
  } catch (e) {
    yield put(displayNotification("Couldn't delete peer feedback"));
  }
}

function* getClientsSuggestionsActionAsync({ payload }: ReturnType<typeof getClientsSuggestionsAction>) {
  try {
    const clientsSuggestions: UserInfo[] = yield call(getClientsSuggestions, payload);
    const evalPageDataSelector = (state: RootState) => state.evaluation.evalPage.evalPageData.data;
    let evalData: EvalPageData = yield select(evalPageDataSelector);
    while (!evalData) {
      yield take();
      evalData = yield select(evalPageDataSelector);
    }
    const filteredClientSuggestions = clientsSuggestions.filter((client) =>
      evalData.clientReviews?.every((cr) => cr.user.name.toLocaleLowerCase() !== client.name.toLocaleLowerCase())
    );
    yield put(setClientsSuggestionsDataState({ data: filteredClientSuggestions, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(setClientsSuggestionsDataState({ data: null, state: DataState.Fulfilled, error: getErrorInfo(e) }));
  }
}

function* getPeerSuggestionsActionAsync({ payload }: ReturnType<typeof getPeerSuggestionsAction>) {
  try {
    const peerSuggestions: PeerSuggestions = yield call(getPeerSuggestions, payload);
    let evalData: EvalPageData = yield select(selectEvalPageData);
    while (!evalData) {
      yield take();
      evalData = yield select(selectEvalPageData);
    }
    const filteredPeerSuggestions = {
      ...peerSuggestions,
      suggestedPeers: peerSuggestions.suggestedPeers.filter(
        (peer) =>
          evalData.peerReviews?.every((pr) => pr.user.name.toLocaleLowerCase() !== peer.name.toLocaleLowerCase()) &&
          peer.id !== evalData.userHistory.id
      ),
    };

    yield put(setPeerSuggestionsDataState({ data: filteredPeerSuggestions, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(
      setPeerSuggestionsDataState({
        data: { suggestedPeers: [], prevEvalPeers: [] },
        state: DataState.Fulfilled,
        error: getErrorInfo(e),
      })
    );
  }
}

function* addClientToReviewersActionAsync({ payload }: ReturnType<typeof addClientToReviewersAction>) {
  try {
    const clientsSuggestionsData: UserInfo[] = yield select(
      (state: RootState) => state.evaluation.evalSettingsPage.clientsSuggestions.data
    );
    yield put(
      setClientsSuggestionsDataState({
        data: clientsSuggestionsData.filter(
          (client) => payload.client.email !== client.email && payload.client.name !== client.name
        ),
        state: DataState.Fulfilled,
      })
    );
    const clientReview = {
      id: '',
      user: {
        id: uuidv4(),
        ...payload.client,
        imageUrl: ImageUrls.Client,
      },
      feedback: null,
      status: EvalFeedbackStatus.Pending,
    };
    const evalData: EvalPageData | null = yield select(
      (state: RootState) => state.evaluation.evalPage.evalPageData.data
    );
    if (!evalData) {
      return;
    }
    const updatedEvalDataLoading = {
      ...evalData,
      clientReviews: evalData.clientReviews ? [...evalData.clientReviews, clientReview] : [clientReview],
    };
    const setEvalDataWithClientReviewsAction = setUserEvalPageData({
      data: updatedEvalDataLoading,
      state: DataState.Fulfilled,
    });
    yield put(setEvalDataWithClientReviewsAction);

    const clientAddedId: string = yield call(addClientToReviewers, payload);
    const clienReviewLoaded = cloneDeep(clientReview);
    clienReviewLoaded.id = clientAddedId;
    const updatedEvalData = {
      ...evalData,
      clientReviews: evalData.clientReviews ? [...evalData.clientReviews, clienReviewLoaded] : [clienReviewLoaded],
    };
    yield put(
      setUserEvalPageData({
        data: updatedEvalData,
        state: DataState.Fulfilled,
      })
    );
  } catch {
    yield put(displayNotification("Couldn't add client to reviewers"));
  }
}

function* deleteClientFeedbackActionAsync({ payload }: ReturnType<typeof deleteClientFeedbackAction>) {
  try {
    yield call(deleteClientFeedback, payload);
  } catch {
    yield put(displayNotification("Couldn't delete client feedback"));
  }
}
function* watchQuerySearch() {
  yield takeLatest(addPeerFeedbackAction.type, addPeerFeedbackActionAsync);
  yield takeEvery(deletePeerFeedbackAction.type, deletePeerFeedbackActionAsync);
  yield takeLatest(getClientsSuggestionsAction.type, getClientsSuggestionsActionAsync);
  yield takeLatest(getPeerSuggestionsAction.type, getPeerSuggestionsActionAsync);
  yield takeLeading(addClientToReviewersAction.type, addClientToReviewersActionAsync);
  yield takeLatest(deleteClientFeedbackAction.type, deleteClientFeedbackActionAsync);
}

export function* evalSettingsPageSaga(): Generator {
  yield all([watchQuerySearch()]);
}
