import { createSlice } from '@reduxjs/toolkit';

import { DataState } from '@shared/enums/DataState';
import { State } from './types';

const initialState: State = {
  clientsSuggestions: { data: [], state: DataState.Pending },
  peerSuggestions: {
    data: {
      suggestedPeers: [],
      prevEvalPeers: [],
    },
    state: DataState.Pending,
  },
};

export const slice = createSlice({
  name: 'evalSettingsPage',
  initialState,
  reducers: {
    setClientsSuggestionsDataState(state, { payload }) {
      state.clientsSuggestions = payload;
    },
    setClientsSuggestionsLoading(state) {
      state.clientsSuggestions = { data: null, state: DataState.Pending };
    },
    setPeerSuggestionsDataState(state, { payload }) {
      state.peerSuggestions = payload;
    },
    setPeerSuggestionsLoading(state) {
      state.peerSuggestions = { data: null, state: DataState.Pending };
    },
  },
});

export const {
  setClientsSuggestionsDataState,
  setClientsSuggestionsLoading,
  setPeerSuggestionsDataState,
  setPeerSuggestionsLoading,
} = slice.actions;

export default slice.reducer;
