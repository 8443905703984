import React, { useEffect } from 'react';
import { RockyNotifications } from '@modules/App/components/RockyNotifications';
import { useClientEvalData } from './hooks/useClientEvalData';
import { useStyles } from './ClientEvalPage.styles';
import { ReactComponent as Logo } from '@assets/icons/logo-small.svg';
import { ReactComponent as LogoWord } from '@assets/icons/Rocky-word.svg';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { ClientEvalForm } from './components/ClientEvalForm/ClientEvalForm';
import { FormExplanationLabel } from '../EvalFormAndResultsPage/components/EvaluationForm/components/FormExplanationLabel';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { RocketLoader } from '@shared/components/RocketLoader';
import { ClientEvalFormClosed } from './components/ClientEvalFormClosed/ClientEvalFormClosed';
import { ClientEvalPageHeader } from './components/ClientEvalPageHeader';
import { RockyModal } from '@shared/components/RockyModal';
import { RockyButton } from '@shared/components/RockyButton';
import { ReactComponent as Heart } from '@assets/icons/CaringHeart.svg';
import { useModalState } from '@shared/hooks/useModalState';
import { useSelector } from 'react-redux';
import { selectPostingFeedbackState } from './redux/selectors';

export const ClientEvalPage: React.FC = () => {
  const clientEvalData = useClientEvalData();
  const styles = useStyles();
  const isFailed = clientEvalData.state === DataState.Rejected;
  const isLoading = clientEvalData.state === DataState.Pending;
  const setPageTitle = useTitle();

  const clientData = clientEvalData.data;
  const evaluatee = clientData?.evaluatee;
  const evaluator = clientData?.evaluator;
  const { openModal, closeModal, isModalOpen } = useModalState();
  const postingFeedbackState = useSelector(selectPostingFeedbackState);

  useEffect(() => {
    if (evaluatee?.name) {
      setPageTitle(`${evaluatee?.name} ${pageTitles.userEvalPage}`);
    }
  }, [evaluatee?.name]);

  useEffect(() => {
    if (postingFeedbackState === DataState.Fulfilled) {
      openModal();
    }
  }, [postingFeedbackState]);

  return isLoading || !clientData ? (
    <div className={styles.loaderHolder}>
      <RocketLoader />
    </div>
  ) : isFailed ? (
    <DataErrorView error={clientEvalData.error} />
  ) : (
    <div className={styles.root} id="layout-root">
      <div className={styles.sidebar}>
        <div className={styles.sidebarContent}>
          <div className={styles.logoContainer}>
            <Logo className={styles.logo} />
            <LogoWord className={styles.logoWord} />
            <span className={styles.logoLabel}>AgileEngine</span>
          </div>
          <div className={styles.participantsContainer}>
            <div className={styles.participantsTitle}>Reviewer</div>
            <div className={styles.participantItem}>
              {evaluator && <UserReporterBlock user={evaluator} isDetailInfo />}
            </div>
            <div className={styles.participantsTitle}>Evaluation for</div>
            <div className={styles.participantItem}>
              {evaluatee && <UserReporterBlock user={evaluatee} isDetailInfo />}
            </div>
            <div className={styles.participantsTitle}>Manager</div>
            <div className={styles.participantItem}>
              {evaluatee && <UserReporterBlock user={evaluatee.primaryReportsTo} isDetailInfo />}
            </div>
          </div>
        </div>
        <div className={styles.tooltipsContainer}>
          <FormExplanationLabel short />
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.clietnForm}>
          <ClientEvalPageHeader data={clientData} />
          {clientData?.evaluation?.isConfirmed ? (
            <ClientEvalFormClosed data={clientData} />
          ) : (
            <ClientEvalForm data={clientData} />
          )}
        </div>
      </div>
      <div className={styles.notificationContainer}>
        <RockyNotifications />
      </div>
      <RockyModal
        title={''}
        isOpen={isModalOpen}
        onClose={closeModal}
        crossIcon
        modalHeaderStyles={styles.modalHeaderStyles}
        modalBodyStyles={styles.modalBodyStyles}
      >
        <Heart />
        <h2 className={styles.modalTitle}>Thank you for your response!</h2>
        <p className={styles.modalText}>
          If you will have more thoughts, please write to {clientData?.evaluatee.primaryReportsTo.email}
        </p>
        <RockyButton action onClick={() => closeModal()}>
          Dismiss
        </RockyButton>
      </RockyModal>
    </div>
  );
};
