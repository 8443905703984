import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useStyles } from '../CommunicationModal.styles';
import { UserInfo } from '@shared/interfaces/UserInfo';

interface Props {
  onClose: () => void;
  userInfo: UserInfo;
}

const EmailWillBeSentWhenUserIsAssignedToTom: FC<Props> = ({ userInfo, onClose }) => {
  const styles = useStyles();

  return (
    <Box>
      <Typography variant="body1">
        Thank you for communicating NewHome process with {userInfo.name}.
        <br />
        <br />
        An automated “Welcome to NewHome” email will be sent
        <Typography variant="body1" color="purple">
          only once Talent Operations team assign a responsible TOM
        </Typography>{' '}
        to guide {userInfo.name} through further NH activities.
      </Typography>
      <Box marginTop={5} className={styles.buttonsContainer}>
        <Button type="button" variant="contained" onClick={onClose}>
          Got it
        </Button>
      </Box>
    </Box>
  );
};

export default EmailWillBeSentWhenUserIsAssignedToTom;
