import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    minWidth: 220,
    minHeight: '100%',
    maxHeight: '100%',
    backgroundColor: COLORS.WHITE,
    zIndex: 12,

    [BREAKPOINTS.TABLET]: {
      maxWidth: 80,
      minWidth: 80,
    },
    [BREAKPOINTS.MOBILE]: {
      position: 'fixed',
      minWidth: '100vw',
      minHeight: 56,
    },
  },
  container: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    position: 'fixed',
    width: 220,
    background: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    overflowY: 'auto',

    [BREAKPOINTS.TABLET]: {
      width: 80,
    },

    [BREAKPOINTS.MOBILE]: {
      height: 56,
      width: '100%',
      position: 'static',
      display: 'flex',
      flexDirection: 'row-reverse',
      alignItems: 'center',
      justifyContent: 'space-between',
      overflow: 'hidden',
    },
  },
  '@keyframes open': {
    from: { minWidth: 200 },
    to: { minWidth: 220 },
  },
  rootOpen: {
    position: 'fixed',
    animation: '$open 0.5s ease-in-out forwards',
  },
  containerOpen: {
    position: 'fixed',
    animation: '$open 0.5s ease-in-out forwards',
  },
  tabletOverley: {
    position: 'absolute',
    content: '""',
    display: 'block',
    height: '100%',
    width: '100%',
    background: 'rgba(0,0,0,0.5)',
    zIndex: 10,
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
    [BREAKPOINTS.DESKTOP]: {
      display: 'none',
    },
  },
  plug: {
    minWidth: 80,
  },
});
