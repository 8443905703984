import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getExternalLinks, getPipLinks } from '@modules/App/pages/ExternalLinksPage/redux/actions';
import { selectExternalLinks, selectPipLinks } from '../redux/selectors';
import { ExternalLinks } from '../interfaces/ExternalLinks';
import { PipLinkBody } from '../interfaces/PipLinkBody';
import { AsyncData } from '@shared/interfaces/asyncData';

export const useExternalLinks = (
  id?: string
): {
  externalLinks: AsyncData<ExternalLinks>;
  pipLinks: AsyncData<PipLinkBody[]>;
} => {
  const dispatch = useDispatch();
  const externalLinks = useSelector(selectExternalLinks);
  const pipLinks = useSelector(selectPipLinks);

  useEffect(() => {
    if (id) {
      dispatch(getExternalLinks(id));
      dispatch(getPipLinks(id));
    }
  }, [id]);

  return {
    externalLinks,
    pipLinks,
  };
};
