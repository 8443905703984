import httpNodeClient from '@shared/api/httpNodeClient';
import { apiUrlNode } from '@configs/environment';
import { ChatMessage } from '../interfaces/ChatMessage';
import { PaginationData } from '../interfaces/PaginationData';
import { SendMessageData } from '../interfaces/SendMessageData';

export const fetchChatData = (params: PaginationData): Promise<ChatMessage[]> =>
  httpNodeClient
    .get<ChatMessage[]>(`${apiUrlNode}/chat`, {
      params,
    })
    .then((resp) => {
      return resp.data;
    });

export const postVote = (reaction: number): Promise<void> =>
  httpNodeClient.post<void>(`${apiUrlNode}/chat/vote`, { reaction }).then(({ data }) => data);

export const postMessage = (sendMessageData: SendMessageData): Promise<void> =>
  httpNodeClient.post<void>(`${apiUrlNode}/chat/send`, sendMessageData).then(({ data }) => data);

export const requestRevote = (): Promise<void> =>
  httpNodeClient.post<void>(`${apiUrlNode}/chat/request-revote`).then(({ data }) => data);
