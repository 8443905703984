import { EvalGoogleCalendarMeetingLinkData } from './../interfaces/EvalGoogleCalendarMeetingLinkData';
import { paths } from '@shared/enums/paths';

export const getGoogleCalendarEvalEventsMeetingLink = ({
  evaluateeName = '',
  evaluateeEmail = '',
  userId = '',
  evaluationId = '',
  evaluatorEmail = '',
}: EvalGoogleCalendarMeetingLinkData): string => {
  const evalPageLink = `${paths.users}/${userId}/${paths.evaluations}/${evaluationId}`;
  return `https://calendar.google.com/calendar/render?action=TEMPLATE&text=Evaluation+meeting+for+${evaluateeName}&dates=""&details=Agenda:<p>1. <a href="${process.env.REACT_APP_BASE_WEBSITE_URL}${evalPageLink}">Feedback</a></p><p>2. RoadMap</p><p>3. Future plans</p>&add=${evaluatorEmail},${evaluateeEmail},rocky-calendar@happycheckybot.iam.gserviceaccount.com`;
};
