import { FC, Suspense, lazy } from 'react';
import { Field, FieldProps, useFormikContext } from 'formik';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material';

import { useStyles } from './FlagUrgencyAndResolutionsBlock.styles';
import { useStatusOptions } from './hooks/useStatusOptions';
import { FlagFormFields } from '../../constants/FlagFormFields';
import { FlagSeverityComponent } from '../../../FlagSeverityComponent';
import { FlagSeverity } from '@modules/HappinessModule/enums/FlagSeverity';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagChangeEvent } from '@modules/HappinessModule/interfaces/FlagHistory';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { RocketLoader } from '@shared/components/RocketLoader';
import { checkIfFlagResolved } from '@modules/HappinessModule/pages/FlagPage/helpers/checkIfFlagResolved';
import { LinkManageButton } from '@modules/App/pages/ExternalLinksPage/components/LinkManageButton/LinkManageButton';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';

const FlagHistory = lazy(() => import('../../../FlagHistory/FlagHistory'));

interface Props {
  isNewFlag: boolean;
  initialStatus: FlagStatus | null;
  flagChangeEvents: FlagChangeEvent[];
  userFlagPermissions: FlagPermissionsData;
}

export const FlagUrgencyAndResolutionsBlock: FC<Props> = ({
  isNewFlag,
  initialStatus,
  flagChangeEvents,
  userFlagPermissions,
}) => {
  const styles = useStyles();
  const { values, submitCount, errors, setFieldValue } = useFormikContext<FlagFields>();

  const { statusOptions } = useStatusOptions({
    severity: values.severity,
    initialStatus,
  });

  const isResolvingFlag = values.status ? checkIfFlagResolved(values.status) : false;

  const isBasicFlagFieldDisabled = isNewFlag
    ? !userFlagPermissions.flag.isCreatable
    : !userFlagPermissions.flag.isUpdatable;

  const isHrNotesReadable = userFlagPermissions.hrNotes.isReadable || userFlagPermissions.hrNotes.isCreatable;

  const isHrNotesDisabled = isNewFlag
    ? !userFlagPermissions.hrNotes.isCreatable
    : !userFlagPermissions.hrNotes.isUpdatable;

  const isFlagResolutionReadable =
    userFlagPermissions.flagResolution.isReadable || userFlagPermissions.flagResolution.isCreatable;
  const isFlagResolutionDisabled = !userFlagPermissions.flagResolution.isUpdatable;

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid container rowGap="36px" className={styles.mainContainer}>
        <Grid item xs={12}>
          <Typography variant="h2">Urgency and resolutions</Typography>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1">{FlagFormFields.severity.label}</Typography>
          <Field name={FlagFormFields.severity.name} as={RadioGroup}>
            <FormControl error={Boolean(errors.severity && submitCount)}>
              {Object.entries(FlagSeverity).map(([, value]) => (
                <FormControlLabel
                  key={value}
                  control={<Radio disabled={isBasicFlagFieldDisabled} required />}
                  label={<FlagSeverityComponent isHelperTextShown severity={value} />}
                  value={value}
                />
              ))}
              <Stack flexDirection="row" alignItems="center">
                <Link
                  target="_blank"
                  href="https://docs.google.com/document/d/1HB_mV23r4iE5WeKWxQrai1A4MWCCF7uAfQDXJzjxOKE/edit#bookmark=kix.2c28dw21atcz"
                >
                  Severity of flags↗
                </Link>
                {Boolean(errors.severity && submitCount) && <FormHelperText>{errors.severity}</FormHelperText>}
              </Stack>
            </FormControl>
          </Field>
        </Grid>

        <Grid item xs={12}>
          <Typography variant="subtitle1">{FlagFormFields.pipLink.label}</Typography>
          <LinkManageButton
            isOutside
            removeLink={() => {
              setFieldValue(FlagFormFields.pipLink.name, null);
            }}
            manageLink={(link) => {
              setFieldValue(FlagFormFields.pipLink.name, link);
            }}
            type={ExternalLinksTypes.pip}
            link={{
              link: values.pipLink ?? '',
              type: ExternalLinksTypes.pip,
            }}
            canEdit={!isBasicFlagFieldDisabled}
          />
        </Grid>
        <Grid
          item
          xs={6}
          sx={{
            paddingRight: '24px',
          }}
        >
          <Field name={FlagFormFields.whatWasDone.name}>
            {({ field }: FieldProps) => (
              <TextField
                placeholder='If no action has been taken yet, please reflect it in this chart "no action taken yet"'
                size="medium"
                label={FlagFormFields.whatWasDone.label}
                multiline
                minRows={4}
                variant="outlined"
                fullWidth
                helperText="What was done so far to try to solve the issue?"
                disabled={isBasicFlagFieldDisabled}
                required
                error={!!(errors.whatWasDone && submitCount)}
                {...field}
              />
            )}
          </Field>
        </Grid>
        <Grid item xs={6}>
          <Field name={FlagFormFields.suggestedActionItems.name}>
            {({ field }: FieldProps) => (
              <TextField
                placeholder='If you need assistance from another team/person to define the next steps,
                please reflect it in this chart "need support from (specify who) to align on the next action items"'
                size="medium"
                label={FlagFormFields.suggestedActionItems.label}
                multiline
                minRows={4}
                variant="outlined"
                fullWidth
                helperText="What else can be done to solve the situation? Specify next steps and action items, as well as owners and timeline to execute each one of them."
                disabled={isBasicFlagFieldDisabled}
                required
                error={!!(errors.suggestedActionItems && submitCount)}
                {...field}
              />
            )}
          </Field>
        </Grid>
        {!isNewFlag && (
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={8} mt={4}>
                <Grid container spacing={4}>
                  {isHrNotesReadable && (
                    <Grid item xs={6}>
                      <Field name={FlagFormFields.hrNotes.name}>
                        {({ field }: FieldProps) => (
                          <TextField
                            size="medium"
                            label={FlagFormFields.hrNotes.label}
                            multiline
                            rows={8}
                            variant="outlined"
                            fullWidth
                            disabled={isHrNotesDisabled}
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                  )}
                  <Grid item xs={6}>
                    {statusOptions && (
                      <Field
                        name={FlagFormFields.status.name}
                        component={({ field }: FieldProps) => (
                          <FormControl fullWidth>
                            <InputLabel>{FlagFormFields.status.label}</InputLabel>
                            <Select disabled={isFlagResolutionDisabled} label={FlagFormFields.status.label} {...field}>
                              {statusOptions.map(({ value, label }) => (
                                <MenuItem value={value} key={value}>
                                  {label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      />
                    )}
                  </Grid>
                  {!isFlagResolutionDisabled && isResolvingFlag && values.resolvedAt && (
                    <Grid item xs={6}>
                      <Field
                        name={FlagFormFields.isSetNewResolutionDate.name}
                        type="checkbox"
                        component={({ field }: FieldProps) => (
                          <FormControlLabel
                            control={<Checkbox {...field} value={field.value ?? ''} />}
                            label={FlagFormFields.isSetNewResolutionDate.label}
                          />
                        )}
                      />
                    </Grid>
                  )}
                  {!isNewFlag && isResolvingFlag && isFlagResolutionReadable && (
                    <Grid item xs={12}>
                      <Field name={FlagFormFields.resolutionNotes.name}>
                        {({ field }: FieldProps) => (
                          <TextField
                            size="medium"
                            label={FlagFormFields.resolutionNotes.label}
                            multiline
                            rows={8}
                            variant="outlined"
                            required
                            fullWidth
                            error={!!(errors.resolutionNotes && submitCount)}
                            disabled={isFlagResolutionDisabled}
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              <Grid item xs={4}>
                {flagChangeEvents.length > 0 && (
                  <Suspense fallback={<RocketLoader />}>
                    <FlagHistory flagChangeEvents={flagChangeEvents} />
                  </Suspense>
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};
