import httpNodeClient from '@shared/api/httpNodeClient';
import {
  PeopleHappinessDataItem,
  PeopleHappinessPageGroupedData,
  PeopleHappinessPageNotGroupedData,
} from '../pages/PeopleHappinessPage/interfaces/PeopleHappinessDataItem';
import { mapPeopleHappinessFilterDataToPayload } from '../pages/PeopleHappinessPage/helpers/mapPeopleHappinessFilterDataToPayload';
import { NO_MARK_THRESHOLD } from '../constants/reportConstants';
import { HapinessRequestParams } from './types';
import { PeopleHappinessDataGroupBy } from '../pages/PeopleHappinessPage/enums/PeopleHappinessDataGroupBy';

export const fetchPeopleHappinessData = ({
  questionHistoryId,
  offset = 0,
  sortInfo,
  filtersToAplly,
}: HapinessRequestParams): Promise<PeopleHappinessPageNotGroupedData> =>
  httpNodeClient
    .get<PeopleHappinessPageNotGroupedData>('/happiness/get-happiness', {
      params: {
        sortField: sortInfo.field,
        sortDir: sortInfo.sortDirection,
        questionHistoryId,
        limit: 100,
        offset,
        ...mapPeopleHappinessFilterDataToPayload(filtersToAplly),
      },
    })
    .then(({ data: { totalCount, items } }) => ({
      totalCount,
      items: items.map((item: PeopleHappinessDataItem) => ({
        ...item,
        // Threshold constant to make sorting work as intended.
        // People who didn't vote should appear between those who voted
        // above 7 and those who voted below 6
        reaction: item.reaction ?? NO_MARK_THRESHOLD,
      })),
    }));

const GROUP_BY_LOCATION_FOR_BE = 'legal-country';

export const fetchPeopleHappinessDataGrouped = ({
  questionHistoryId,
  sortInfo,
  filtersToAplly,
  groupBy,
}: HapinessRequestParams): Promise<PeopleHappinessPageGroupedData> =>
  httpNodeClient
    .get<PeopleHappinessPageGroupedData>('/happiness/get-happiness', {
      params: {
        sortField: sortInfo.field,
        sortDir: sortInfo.sortDirection,
        questionHistoryId,
        ...mapPeopleHappinessFilterDataToPayload(filtersToAplly),
        groupBy: groupBy === PeopleHappinessDataGroupBy.Location ? GROUP_BY_LOCATION_FOR_BE : groupBy,
      },
    })
    .then((resp) => resp.data);
