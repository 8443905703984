import React, { FC, memo, useEffect } from 'react';
import { useStyles } from './SurveyManagementPage.styles';
import { SurveyQueuesBlock } from './components/SurveyQueuesBlock';
import { RocketLoader } from '@shared/components/RocketLoader';
import { useSurveyQueue } from './hooks/useSurveyQueue';
import { LastSurveyCard } from './components/LastSurveyCard';
import { UpcomingSurveyCard } from './components/UpcomingSurveyCard';
import { SurveyInfo, SurveyQueue } from './interfaces';
import parseISO from 'date-fns/parseISO';
import formatISO from 'date-fns/formatISO';
import addWeeks from 'date-fns/addWeeks';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { SURVEY_INTERVAL_WEEKS } from './constants';

const getUpcomingSurvey = (surveyQueue: SurveyQueue): SurveyInfo | undefined => {
  const activeQueue = surveyQueue.categories.filter(({ isActive }) => isActive);
  const lastAksedDate = parseISO(surveyQueue.lastSurvey.date);
  const upcomingCategory = activeQueue[0];
  if (!upcomingCategory) {
    return;
  }
  const upcomingQuestion = upcomingCategory.questions[0];
  return {
    date: formatISO(addWeeks(lastAksedDate, SURVEY_INTERVAL_WEEKS)),
    questionText: upcomingQuestion.text,
    categoryName: upcomingCategory.name,
  };
};

const SurveyManagementPageComponent: FC = () => {
  const styles = useStyles();
  const surveyQueue = useSurveyQueue();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(pageTitles.surveyManagementHappinessPage);
  }, []);

  return (
    <div className={styles.root}>
      {surveyQueue.data ? (
        <>
          <div className={styles.infoCardsHolder}>
            <LastSurveyCard lastSurvey={surveyQueue.data.lastSurvey}></LastSurveyCard>
            <UpcomingSurveyCard upcomingSurvey={getUpcomingSurvey(surveyQueue.data)}></UpcomingSurveyCard>
          </div>
          <div className={styles.surveyQueueBlockHolder}>
            <SurveyQueuesBlock surveyQueue={surveyQueue.data}></SurveyQueuesBlock>
          </div>
        </>
      ) : (
        <div className={styles.loaderHolder}>
          <RocketLoader></RocketLoader>
          <h3 className={styles.loadingText}>Loading schedule</h3>
        </div>
      )}
    </div>
  );
};

export const SurveyManagementPage = memo(SurveyManagementPageComponent);
