import { RootState } from '@modules/App/redux/store';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { EvalPeerReviewData } from '@modules/EvaluationModule/interfaces/EvalPeerReviewData';
import { EvalPermissions } from '@modules/EvaluationModule/interfaces/EvalPermissions';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';

export const peerReviewsSelector = (state: RootState): EvalPeerReviewData[] | null | undefined =>
  state.evaluation.evalPage.evalPageData.data?.peerReviews;

export const evalPageDataSelector = (state: RootState): AsyncData<EvalPageData> =>
  state.evaluation.evalPage.evalPageData;

export const eopLoadingStateSelector = (state: RootState): DataState => state.evaluation.evalPage.eopLoadingState;

export const evalPermissionsSelector = (state: RootState): AsyncData<EvalPermissions> =>
  state.evaluation.evalPage.permissions;

export const evalPromotionSelector = (state: RootState): AsyncData<UserPromotionsData> =>
  state.evaluation.evalPage.promotion;
