import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getUserFlagCcDetails } from '../redux/actions';
import { setUserFlagCcDetailsLoading } from '../redux/reducers';
import { UserFlagCcDetails } from '@modules/HappinessModule/interfaces/UserFlagCcDetails';
import { userFlagCcDetailsSelector } from '../redux/selectors';

export const useUserFlagCcDetails = (userId?: string): AsyncData<UserFlagCcDetails> => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const innerUserId = userId ?? id;

  const userFlagCcDetails = useSelector(userFlagCcDetailsSelector);

  useEffect(() => {
    if (!innerUserId) {
      return;
    }
    dispatch(getUserFlagCcDetails(innerUserId));
    return () => {
      dispatch(setUserFlagCcDetailsLoading());
    };
  }, [innerUserId]);

  return userFlagCcDetails;
};
