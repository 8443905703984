export const questionBlockFieldsDescriptions = [
  {
    questionName: 'quantityOfWork',
    questionTitle: 'Quantity of work',
    questionMark: 'quantityOfWork.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'quantityOfWork.notes',
    questionDescription: [
      'The extent to which the team member accomplishes assigned work of a specified quality within a specified time period.',
    ],
  },
  {
    questionName: 'qualityOfWork',
    questionTitle: 'Quality of work',
    questionMark: 'qualityOfWork.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'qualityOfWork.notes',
    questionDescription: [
      'Team Member delivers great work without the need for a lot of hand-holding from managers and peers.',
    ],
  },
  {
    questionName: 'functionalExpertise',
    questionTitle: 'Functional expertise',
    questionMark: 'functionalExpertise.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'functionalExpertise.notes',
    questionDescription: [
      'Effectively plans and organizes to achieve strategic goals.',
      'Manages multiple priorities.',
      'Identifies, selects and works effectively with internal and external resources.',
      'Demonstrates necessary critical thinking appropriate to position to solve problems and arrive at sound solutions.',
      'Demonstrates technical competence.',
      'Possesses knowledge and skill needed to meet day-to-day demands of position.',
    ],
  },
  {
    questionName: 'communication',
    questionTitle: 'Communication',
    questionMark: 'communication.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'communication.notes',
    questionDescription: [
      'Able to persuade and influence others to facilitate the achievement of business goals.',
      'Demonstrates effective communication skills.',
      'Selects appropriate communication medium for clearly conveying messages.',
      'Is professional in how they speaks, writes, listens, and shares information.',
      'Responds to others in an effective, timely, clear, concise, logical, and organized manner.',
      'Willing to listen and receive feedback.',
    ],
  },
  {
    questionName: 'teamworkAndAttitude',
    questionTitle: 'Teamwork and attitude',
    questionMark: 'teamworkAndAttitude.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'teamworkAndAttitude.notes',
    questionDescription: [
      'Cooperates with team members, displaying trust and open communication to achieve common goals.',
      'Builds or supports a collaborative environment; solves problems by working across boundaries.',
      'Shares necessary information and knowledge.',
      'Acts in the best interest of the team, department and company.',
      'Demonstrates flexibility and adaptability.',
      'Can effectively respond to changing circumstances, conditions or environment as needed to advance team efforts.',
    ],
  },
  {
    questionName: 'initiativeAndOwnership',
    questionTitle: 'Initiative and ownership',
    questionMark: 'initiativeAndOwnership.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'initiativeAndOwnership.notes',
    questionDescription: [
      'Is an innovator.',
      'Seeks new, better, creative ways to achieve objectives.',
      'Demonstrates the ability to plan work and initiate a task without requiring explicit direction.',
      'Is a self-starter.',
      'Anticipates needs, proactively solves problems, takes action.',
      'Demonstrates a true, entrepreneurial spirit.',
      'Takes personal pride in being creative and driven to succeed.',
      'Takes intelligent risks, appropriate to position.',
    ],
  },
  {
    questionName: 'projectLeadership',
    questionTitle: 'Leadership and mentorship',
    questionMark: 'projectLeadership.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'projectLeadership.notes',
    questionDescription: [],
  },
  {
    questionName: 'english',
    questionTitle: 'English',
    questionMark: 'english.mark',
    questionPlaceholder: 'Your notes and comments',
    questionComments: 'english.notes',
    questionDescription: [
      'Communicates confidently about many topics.',
      'Speaks with natives without difficulty and with spontaneity.',
      'Understands the main ideas of texts about topics they are familiar with.',
      'Expresses themselves fluently in almost any situation, without the need to search for expressions.',
      'Performs complex tasks in English related to work and study.',
    ],
  },
];
