import React, { MouseEvent, useCallback, useRef, useState } from 'react';
import { useStyles } from './EditGoalsForm.styles';
import { Stack, Typography, DialogContent, Grid, TextField, DialogActions, Tooltip } from '@mui/material';
import { RockyButton } from '@shared/components/RockyButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AccessAlarmOutlinedIcon from '@mui/icons-material/AccessAlarmOutlined';
import { Form, Formik, FormikProps } from 'formik';
import isEmpty from 'lodash/isEmpty';
import { GoalsCategory } from '@modules/EvaluationModule/enums/GoalsCategory';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { getGoalStatusData } from '../../helpers/getGoalStatusData';
import { useDispatch, useSelector } from 'react-redux';
import { userGoalEditNetworkErrorSelector } from '../../redux/selectors';
import { launchEmojisplosion } from '@shared/helpers/launchEmojisplosion';
import { EditUserGoalData } from '@modules/EvaluationModule/interfaces/EditUserGoalData';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { GoalsFormField } from '../../enums/GoalsFormField';
import { editValidationSchema, editValidationSchemaWithRanges } from '../../constants/valdationSchema';
import { updateUserGoal } from '../../redux/actions';
import { customValidationEditGoals } from '../../helpers/customGoalValidation';
import { GoalSelector } from '../GoalSelector/GoalSelector';
import { NumericGoalsField } from '../NumericGoalsField/NumericGoalsField';
import { editGoalPayloadGenerate } from '../../helpers/editGoalPayloadGenerate';
import { isFieldDisabledByStatus } from '../../constants/valdationSchema';
import { COLORS } from '@styles/colors';

interface Props {
  data: UserGoalPageData;
  handleModalClose: () => void;
  isLoading: boolean;
  customDueDate: string | null;
  setCustomDueDate: (arg0: string | null) => void;
}

export const EditGoalsForm: React.FC<Props> = ({
  handleModalClose,
  isLoading,
  customDueDate,
  setCustomDueDate,
  data,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const formRef = useRef<FormikProps<EditUserGoalData>>(null);
  const [isValidateOnChange, setIsValidateOnChange] = useState(false);
  const userGoalEditNetworkError = useSelector(userGoalEditNetworkErrorSelector);

  const handleClickSubmitIfValid = () => {
    if (!formRef.current) {
      return;
    }
    formRef.current.setTouched({});
    setIsValidateOnChange(true);
    const isCustomValidationPass = customValidationEditGoals(formRef.current, data);
    if (!isCustomValidationPass) {
      return;
    }
    formRef.current.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        formRef.current?.handleSubmit();
      } else {
        return;
      }
    });
  };

  const handleSubmitEditGoal = useCallback(
    (values) => {
      const payload = editGoalPayloadGenerate(values, customDueDate, data);
      dispatch(updateUserGoal(payload));
    },
    [customDueDate]
  );

  const setGoalAchievedClick = (e: MouseEvent<HTMLElement>) => {
    if (!formRef.current) {
      return;
    }
    const isCustomValidationPass = customValidationEditGoals(formRef.current, data);
    if (!isCustomValidationPass) {
      return;
    }
    formRef.current.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        launchEmojisplosion(e.clientX, e.clientY);
        formRef.current?.setFieldValue(GoalsFormField.Status, GoalsStatus.Achieved);
        formRef.current?.handleSubmit();
      }
    });
  };

  const expectationChangeReasonLabel = 'Reason of changing expectations';

  return (
    <Formik
      enableReinitialize
      innerRef={formRef}
      initialValues={data}
      validationSchema={data.isRange ? editValidationSchemaWithRanges : editValidationSchema}
      onSubmit={handleSubmitEditGoal}
      validateOnChange={isValidateOnChange}
      validateOnBlur={false}
    >
      {(formik) => {
        const { actualResult, worstCaseResult, bestCaseResult } = formik.values;
        const isExpectedResultChanged = data.worstCaseResult !== formik.values.worstCaseResult;
        const isBestCaseChanged = data.bestCaseResult !== formik.values.bestCaseResult;
        const { isOverdue, labelColor, percentageOfDone, isRangesUsed } = getGoalStatusData({
          ...data,
          actualResult: formik.values.actualResult,
          worstCaseResult: formik.values.worstCaseResult,
          bestCaseResult: formik.values.bestCaseResult,
        });
        const isGoalAchievement =
          (actualResult !== null && actualResult >= worstCaseResult) ||
          (percentageOfDone !== null && percentageOfDone >= 80);
        const isFieldDisabled = isFieldDisabledByStatus(formik.values.status);

        return (
          <Form>
            <DialogContent>
              <Grid container spacing={3} sx={{ pt: 1 }}>
                <Grid item xs={12}>
                  <TextField
                    placeholder='e.g. "Pass 7 steps of Google certification"'
                    label="Goal objective"
                    value={formik.values.objective}
                    variant="outlined"
                    type="text"
                    fullWidth
                    autoComplete="off"
                    name={GoalsFormField.Objective}
                    disabled={isFieldDisabled}
                    helperText={
                      formik.errors.objective && <span className={styles.fieldError}>{formik.errors.objective}</span>
                    }
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.objective)}
                  />
                </Grid>
                {!isRangesUsed && (
                  <>
                    <Grid item xs={6}>
                      <NumericGoalsField
                        placeholder=""
                        label="Expected result"
                        value={worstCaseResult}
                        name={GoalsFormField.WorstCaseResult}
                        disabled={isFieldDisabled}
                        error={Boolean(formik.errors.worstCaseResult)}
                        inputProps
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NumericGoalsField
                        placeholder=""
                        label="Actual result"
                        value={actualResult}
                        name={GoalsFormField.ActualResult}
                        disabled={isFieldDisabled}
                        error={Boolean(formik.errors.actualResult)}
                        inputProps
                        percentageOfDone={percentageOfDone}
                        labelColor={labelColor}
                      />
                    </Grid>
                  </>
                )}
                {isRangesUsed && (
                  <>
                    <Grid item xs={6} sm={4}>
                      <NumericGoalsField
                        placeholder=""
                        label="Result"
                        value={actualResult}
                        name={GoalsFormField.ActualResult}
                        disabled={isFieldDisabled}
                        error={Boolean(formik.errors.actualResult)}
                        inputProps
                        percentageOfDone={percentageOfDone}
                        labelColor={labelColor}
                      />
                    </Grid>
                    <Grid item xs={12} sm={8}>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <NumericGoalsField
                            placeholder=""
                            label="Worst"
                            value={worstCaseResult}
                            name={GoalsFormField.WorstCaseResult}
                            disabled={isFieldDisabled}
                            error={Boolean(formik.errors.worstCaseResult)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <NumericGoalsField
                            placeholder=""
                            label="Best"
                            value={bestCaseResult}
                            name={GoalsFormField.BestCaseResult}
                            disabled={isFieldDisabled}
                            error={Boolean(formik.errors.bestCaseResult)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </>
                )}
                {isExpectedResultChanged || isBestCaseChanged ? (
                  <Grid item xs={12}>
                    <TextField
                      label={`${expectationChangeReasonLabel}*`}
                      value={formik.values.expectationChangeReason}
                      name={GoalsFormField.ExpectationChangeReason}
                      variant="outlined"
                      disabled={isFieldDisabled}
                      fullWidth
                      multiline
                      minRows={3}
                      helperText={
                        formik.errors.expectationChangeReason && (
                          <span className={styles.fieldError}>{formik.errors.expectationChangeReason}</span>
                        )
                      }
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.expectationChangeReason)}
                    />
                  </Grid>
                ) : formik.initialValues.expectationChangeReason ? (
                  <Grid item xs={12}>
                    <Stack
                      sx={{
                        borderRadius: '4px',
                        background: COLORS.HIGHLIGHTS.NEUTRAL,
                        padding: '16px 24px',
                        width: '100%',
                        rowGap: '2px',
                      }}
                    >
                      <Typography variant="body2" color="text.secondary">
                        {expectationChangeReasonLabel}
                      </Typography>
                      <Typography
                        variant="body1"
                        color="text.primary"
                        sx={{
                          whiteSpace: 'pre-wrap',
                          wordBreak: 'break-word',
                        }}
                      >
                        {formik.initialValues.expectationChangeReason}
                      </Typography>
                    </Stack>
                  </Grid>
                ) : null}
                <Grid item xs={12}>
                  <TextField
                    label="Description of steps and activities"
                    placeholder="Describe tasks, actions, and steps to measure and achieve the goal"
                    value={formik.values.description}
                    name={GoalsFormField.Description}
                    variant="outlined"
                    disabled={isFieldDisabled}
                    fullWidth
                    multiline
                    minRows={3}
                    helperText={
                      formik.errors.description && (
                        <span className={styles.fieldError}>{formik.errors.description}</span>
                      )
                    }
                    onChange={formik.handleChange}
                    error={Boolean(formik.errors.description)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <GoalSelector
                    id="goal-edit-category"
                    label="Category"
                    value={formik.values.category}
                    name={GoalsFormField.Category}
                    disabled={isFieldDisabled}
                    error={Boolean(formik.errors.category)}
                    options={Object.values(GoalsCategory)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item xs={6} md={4}>
                      <GoalSelector
                        id="goal-edit-status"
                        label="Status"
                        value={formik.values.status}
                        name={GoalsFormField.Status}
                        error={Boolean(formik.errors.status)}
                        options={Object.values(GoalsStatus).filter((status) => status !== GoalsStatus.Achieved)}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <DatePicker
                        label="Due date"
                        openTo="month"
                        views={['year', 'month', 'day']}
                        inputFormat="dd MMM yyyy"
                        disabled={isFieldDisabled}
                        value={customDueDate}
                        disableMaskedInput
                        onChange={(newValue) => {
                          setCustomDueDate(newValue);
                        }}
                        renderInput={(params) =>
                          isOverdue ? (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={Boolean(formik.errors.dueDate)}
                              helperText={
                                formik.errors.dueDate ? (
                                  <span className={styles.fieldError}>{formik.errors.dueDate}</span>
                                ) : (
                                  'Overdue'
                                )
                              }
                            />
                          ) : (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={Boolean(formik.errors.dueDate)}
                              helperText={
                                formik.errors.dueDate && (
                                  <span className={styles.fieldError}>{formik.errors.dueDate}</span>
                                )
                              }
                            />
                          )
                        }
                        components={
                          isOverdue
                            ? {
                                OpenPickerIcon: AccessAlarmOutlinedIcon,
                              }
                            : {}
                        }
                      />
                    </Grid>
                    <Grid item xs={12} md={4}>
                      <Stack>
                        <Typography
                          variant="body1"
                          color="text.secondary"
                          sx={{
                            transform: 'scale(0.75)',
                            transformOrigin: '0 0',
                            margin: '-10px 0 0 0',
                          }}
                        >
                          Goal set by
                        </Typography>
                        <Typography variant="body1">{data.author.fullName}</Typography>
                        <Typography variant="body2" color="text.secondary">
                          {format(parseISO(data.createdAt), 'dd MMM yyyy')}
                        </Typography>
                      </Stack>
                    </Grid>
                  </Grid>
                </Grid>
                {isFieldDisabled && (
                  <Grid item xs={12}>
                    <TextField
                      label="Cancellation note"
                      placeholder="Describe why the goal has to be cancelled"
                      variant="outlined"
                      autoFocus
                      fullWidth
                      multiline
                      minRows={3}
                      value={formik.values.cancellationNote}
                      name={GoalsFormField.CancellationNote}
                      helperText={
                        formik.errors.cancellationNote && (
                          <span className={styles.fieldError}>{formik.errors.cancellationNote}</span>
                        )
                      }
                      onChange={formik.handleChange}
                      error={Boolean(formik.errors.cancellationNote)}
                      disabled={formik.initialValues.status === GoalsStatus.Canceled}
                    />
                  </Grid>
                )}
              </Grid>
            </DialogContent>
            <div className={styles.networkError}>{userGoalEditNetworkError}</div>
            <DialogActions>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <RockyButton
                    success
                    className={styles.goalBtnAchieved}
                    onClick={(e) => setGoalAchievedClick(e)}
                    isLoading={isLoading}
                    disabled={!isGoalAchievement}
                  >
                    <Tooltip
                      disableHoverListener={isGoalAchievement}
                      title={
                        <div className={styles.goalAchievedContentTooltip}>
                          {data.isRange ? (
                            <>
                              Goal actual result must be
                              <br /> greater than the worst-case target
                            </>
                          ) : (
                            <>
                              Goal performace must
                              <br /> be more than 80% on target
                            </>
                          )}
                        </div>
                      }
                      placement="top"
                    >
                      <span className={styles.goalAchievedBtnContent}>
                        <CheckBoxIcon />
                        Set as achieved
                      </span>
                    </Tooltip>
                  </RockyButton>
                </Grid>
                <Grid item xs={12} md={6} className={styles.goalActions}>
                  <RockyButton inverted onClick={handleModalClose}>
                    Cancel
                  </RockyButton>
                  <RockyButton action onClick={handleClickSubmitIfValid} isLoading={isLoading}>
                    Save changes
                  </RockyButton>
                </Grid>
              </Grid>
            </DialogActions>
          </Form>
        );
      }}
    </Formik>
  );
};
