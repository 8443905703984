import classNames from 'classnames';
import { FC, memo } from 'react';
import { OptionProps } from 'react-select';
import { useStyles } from './GlobalSearchOption.styles';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { UserInfo } from '@shared/interfaces/UserInfo';

const GlobalSearchOptionComponent: FC<OptionProps> = ({ innerProps, isDisabled, isFocused, data }) => {
  const userData = data as UserInfo;
  const styles = useStyles();

  return !isDisabled ? (
    <div {...innerProps} className={classNames(styles.root, { [styles.isFocused]: isFocused })}>
      <UserReporterBlock isDetailInfo user={userData} statusDisplayPosition="right side" />
    </div>
  ) : null;
};

export const GlobalSearchOption = memo(GlobalSearchOptionComponent);
