import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'inline-block',
  },
  container: {
    display: 'block',
    position: 'relative',
    paddingLeft: '24px',
    cursor: 'pointer',
    fontWeight: '400',
    lineHeight: '24px',
    userSelect: 'none',
  },
  input: {
    top: 0,
    position: 'absolute',
    opacity: 0,
    cursor: 'pointer',

    '&:checked ~ span': {
      backgroundColor: `${COLORS.WHITE}`,
      border: `2px solid ${COLORS.ACTION.PRIMARY}`,
    },

    '&:checked ~ span:after': {
      display: 'block',
    },
  },
  checkmark: {
    backgroundColor: `${COLORS.WHITE}`,
    position: 'absolute',
    top: 1,
    left: 0,
    height: '20px',
    width: '20px',
    border: `2px solid ${COLORS.INPUTBORDER}`,
    borderRadius: '50%',

    '$container &:after': {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '10px',
      height: '10px',
      borderRadius: '50%',
      background: `${COLORS.ACTION.PRIMARY}`,
    },

    '&:after': {
      content: '""',
      position: 'absolute',
      display: 'none',
    },
  },
  checkmarkDisabled: {
    border: `2px solid ${COLORS.INPUTBORDER}`,

    '$container &:after': {
      background: `${COLORS.INPUTBORDER}`,
    },
  },
});
