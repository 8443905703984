import { FC } from 'react';
import { Box } from '@mui/system';
import { TermsOfUse } from '@shared/components/TermsOfUse/TermsOfUse';

export const TermsOfUsePage: FC = () => {
  return (
    <Box
      sx={(theme) => ({
        display: 'grid',
        gridTemplate: 'repeat(3, auto) / repeat(2, auto)',
        background: theme.palette.background.backgroundPrimary,

        [theme.breakpoints.down('md')]: {
          gridTemplate: 'repeat(3, min-content) / repeat(2, auto)',
          justifyItems: 'center',
          gap: '1.25rem 0',
        },

        [theme.breakpoints.down('sm')]: {
          overflowY: 'auto',
          background: 'white',
          px: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'revert',
          gap: 0,
        },
      })}
    >
      <Box
        sx={(theme) => ({
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          maxWidth: '59.5rem',
          borderRadius: '12px',
          background: 'white',
          gridRow: '1/3',
          gridColumn: '1/-1',
          alignSelf: 'center',
          justifySelf: 'center',
          px: 12,
          my: 4,
          [theme.breakpoints.down('md')]: {
            px: 8,
            gridRow: 2,
            mt: 1,
            width: '90%',
          },
          [theme.breakpoints.down('sm')]: {
            display: 'contents',
            px: 0,
          },
        })}
      >
        <TermsOfUse></TermsOfUse>
      </Box>
    </Box>
  );
};
