import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: 'fit-content',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
    },
  },
  selectBlock: {
    position: 'relative',
    width: 240,
    animation: '$extension 0.1s linear',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },
  searchIcon: {
    position: 'absolute',
    left: 8,
    top: '50%',
    transform: 'translateY(-50%)',
    zIndex: 1,

    '& svg': {
      width: 20,
      height: 20,
      verticalAlign: 'middle',
      color: COLORS.TEXT.DISABLED,
      pointerEvents: 'none',
      boxSizing: 'border-box',
      stroke: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
  '@keyframes extension': {
    '0%': { opacity: 0, width: 20 },
    '100%': { opacity: 1, width: 327 },
  },
});
