import { FeatureState } from '@shared/enums/featureState';

export const enabledEnvFeatures = {
  compensation: process.env.REACT_APP_COMPENSATIONS_FEATURE === FeatureState.Enabled,
  flags: process.env.REACT_APP_FLAGS_FEATURE === FeatureState.Enabled,
  csat: process.env.REACT_APP_CSAT_FEATURE === FeatureState.Enabled,
  bonuses: process.env.REACT_APP_BONUSES_FEATURE === FeatureState.Enabled,
  smartGoals: process.env.REACT_APP_SMART_GOALS_FEATURE === FeatureState.Enabled,
  promotions: process.env.REACT_APP_PROMOTIONS_FEATURE === FeatureState.Enabled,
};
