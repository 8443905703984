import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    maxWidth: 286,
    height: 'fit-content',
    padding: '24px 24px 32px 24px',
    background: COLORS.HIGHLIGHTS.NOTE,
    borderRadius: 8,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  shortRoot: {
    maxHeight: 360,
    overflowY: 'scroll',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      height: 'auto',
      maxHeight: 'none',
      maxWidth: 'none',
    },
  },
  explanationText: {
    fontSize: '14px',
    lineHeight: '130%',
    color: COLORS.TEXT.SECONDARY,
    marginBottom: 16,
  },
  explanationTitle: {
    fontWeight: '500',
    marginTop: 0,
  },
  detailsButton: {
    cursor: 'pointer',
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '130%',
    border: 'none',
    background: 'none',
    padding: 0,
    minWidth: 'fit-content',
    height: 'fit-content',
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      background: 'none',
    },
  },
  hidden: {
    display: 'none',
  },
});
