import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 50,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: 24,
  },
  text: {
    color: COLORS.TEXT.PRIMARY,
    margin: 0,
    fontSize: '14px',
    lineHeight: 1.25,
    padding: '0 50px',
  },
  warningText: {
    color: COLORS.WHITE,
    background: COLORS.STATUS.WARNING,
    padding: '24px 0 24px 50px',
  },
  modalFooter: {
    padding: '0 24px',
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 12,
  },
  button: {
    fontSize: '14px',
  },
  buttonSubmit: {
    padding: '14px 28px',
  },
});
