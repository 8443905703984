import React from 'react';
import { useStyles } from './PromotionTableRow.styles';
import { TableCell, TableRow } from '@mui/material';
import format from 'date-fns/format';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { PromotionPreviewModal } from '../PromotionPreviewModal/PromotionPreviewModal';
import { useModalState } from '@shared/hooks/useModalState';
import { NO_SENIORITY } from '@shared/consts/constants';

interface Props {
  promotion: UserPromotionsData;
  prevPromotion: UserPromotionsData | null;
  isPromotionsUpdatable?: boolean;
}

const convertSeniorityName = (seniorityName: string | undefined) =>
  seniorityName ? (seniorityName.toLowerCase() === NO_SENIORITY ? '' : seniorityName) : '';

export const PromotionTableRow: React.FC<Props> = ({ promotion, prevPromotion, isPromotionsUpdatable }) => {
  const styles = useStyles();
  const { id, effectiveDate, role, seniority, reason, manager } = promotion;
  const { isModalOpen, openModal, closeModal } = useModalState();

  const prevRole = prevPromotion && prevPromotion.role;
  const prevSeniority = prevPromotion && prevPromotion.seniority;

  const handleRowClick = () => {
    if (isPromotionsUpdatable) openModal();
  };

  return (
    <TableRow key={id} className={styles.tableRow} onClick={handleRowClick}>
      <TableCell>{format(new Date(effectiveDate), 'MMMM yyyy')}</TableCell>
      <TableCell>
        {prevRole && promotion.role.id !== prevRole.id ? (
          <>
            <span className={styles.prevPosition}>
              {convertSeniorityName(prevSeniority?.name)} {prevRole.name} →
            </span>{' '}
            {convertSeniorityName(seniority?.name)} {role.name}
          </>
        ) : (
          `${convertSeniorityName(seniority?.name)} ${role.name}`
        )}
      </TableCell>
      <TableCell>{reason}</TableCell>
      <TableCell>
        <UserReporterBlock user={manager} />
        <PromotionPreviewModal
          data={promotion}
          isNewRoleShown={prevRole ? promotion.role.id !== prevRole.id : false}
          isOpen={isModalOpen}
          onClose={closeModal}
        />
      </TableCell>
    </TableRow>
  );
};
