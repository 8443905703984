import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useLocation } from 'react-router-dom';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { getMyPeopleEvalData, setMyPeopleEvalDataLoading } from '../redux/actions';
import { DEFAULT_PAGINATION_DATA } from '../constants/pagination';
import { selectMyPeopleEvalData } from '../redux/selectors';

export const useMyPeopleEvalData = (): {
  myPeopleEvalData: AsyncData<MyTeamEvalData[]>;
  fromLocation?: string;
} => {
  const myPeopleEvalData = useSelector(selectMyPeopleEvalData);
  const location = useLocation();
  const dispatch = useDispatch();
  const [fromLocation, setFromLocation] = useState(undefined);

  useEffect(() => {
    setFromLocation(location?.state);
  }, [location]);

  useEffect(() => {
    dispatch(getMyPeopleEvalData(DEFAULT_PAGINATION_DATA));

    return () => {
      dispatch(setMyPeopleEvalDataLoading());
    };
  }, []);

  return { myPeopleEvalData, fromLocation };
};
