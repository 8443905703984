import { UserInfo } from '@shared/interfaces/UserInfo';
import { UserInfoRaw } from '@shared/interfaces/UserInfoRaw';

export const mapUserFromBePythonToFeUser = (user: UserInfoRaw): UserInfo => ({
  id: user.id,
  email: user.email,
  name: user.full_name,
  imageUrl: user.imageUrl,
  position: user.position,
});
