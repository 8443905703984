import { ClientEvalData } from '@modules/EvaluationModule/interfaces/ClientEvalData';
import React, { useCallback, useRef, useState } from 'react';
import { useStyles } from './ClientEvalForm.styles';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { clientEvalFieldsDescription, clientEvalMainField } from '../../constants/clientEvalFielsDescription';
import { RockyButton } from '@shared/components/RockyButton';
import { QuestionRadioInput } from '@shared/components/QuestionRadioInput/QuestionRadioInput';
import { markValues } from '@modules/EvaluationModule/pages/EvalPage/constants/markValues';
import { Field, Form, Formik, FormikErrors, FormikProps } from 'formik';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { useDispatch, useSelector } from 'react-redux';
import { validationSchema } from '../../constants/validationSchema';
import { postClientEvalDataAction } from '../../redux/actions';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { ClientFormObserver } from './components/ClientFormObserver/ClientFormObserver';
import { DataState } from '@shared/enums/DataState';
import { selectPostingFeedbackState } from '../../redux/selectors';

interface Props {
  data: ClientEvalData | null;
}

export const ClientEvalForm: React.FC<Props> = ({ data }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const evaluatee = data?.evaluatee;
  const evaluation = data?.evaluation;
  const { feedbackId = '' } = useParams();
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const formRef = useRef<FormikProps<EvaluationOptionsData>>(null);
  const postingFeedbackState = useSelector(selectPostingFeedbackState);
  const isPostingFeedback = postingFeedbackState === DataState.Pending;

  const handleSubmitFeedback = useCallback(
    (values: EvaluationOptionsData) => {
      dispatch(postClientEvalDataAction({ feedbackId, evaluation: { ...values, isConfirmed: true } }));
    },
    [feedbackId]
  );

  const submitIfValid = (
    validateForm: (values?: EvaluationOptionsData) => Promise<FormikErrors<EvaluationOptionsData>>
  ) => {
    setIsSubmitAttempted(true);
    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        if (!formRef.current) {
          return;
        }
        formRef.current.handleSubmit();
      } else {
        dispatch(
          displayNotification('Quality of work is the mandatory question, please select one of the options above.')
        );
      }
    });
  };

  return (
    <>
      {evaluatee && evaluation && (
        <div>
          <div className={styles.evaluateeBlock}>
            <RockyAvatar fullName={evaluatee.name} imageUrl={evaluatee.imageUrl} large />
            How would you rate quality of work of {evaluatee.name}?
          </div>
          <Formik
            enableReinitialize
            initialValues={evaluation}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmitFeedback}
          >
            {({
              validateForm,
              errors,
            }: {
              validateForm: (values?: EvaluationOptionsData) => Promise<FormikErrors<EvaluationOptionsData>>;
              errors: FormikErrors<EvaluationOptionsData>;
            }) => (
              <Form>
                <ClientFormObserver />
                <div className={styles.markContainer}>
                  <QuestionRadioInput
                    markDetail={get(evaluation, clientEvalMainField.questionName)}
                    markValues={markValues}
                    questionName={clientEvalMainField.questionName}
                    error={isSubmitAttempted ? get(errors, clientEvalMainField.questionMark) : undefined}
                    questionMark={clientEvalMainField.questionMark}
                    classNameMarkLabel={styles.markLabel}
                  />
                </div>
                <div className={styles.thanksBlock}>
                  Thank you for your response!
                  <br />
                  Knowing more will help {evaluatee.name.split(' ')[0]} to understand what skills and qualities to
                  improve.
                </div>
                <div className={styles.marksBlock}>
                  {clientEvalFieldsDescription.map((fieldProps, i) => (
                    <QuestionRadioInput
                      markDetail={get(data.evaluation, fieldProps.questionName)}
                      markValues={markValues}
                      {...fieldProps}
                      key={i}
                      classNameMarkLabel={styles.markLabel}
                    />
                  ))}
                </div>
                <div className={styles.textFieldBlock}>
                  <p>What improvement points or achievements would you like to highlight?</p>
                  <Field component="textarea" name="notes" placeholder="Tell us more"></Field>
                  <RockyButton
                    disabled={isPostingFeedback}
                    isLoading={isPostingFeedback}
                    action
                    onClick={() => submitIfValid(validateForm)}
                  >
                    Send feedback
                  </RockyButton>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  );
};
