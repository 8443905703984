import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    textDecoration: 'none',
    color: 'inherit',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '12px 16px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    columnGap: '5%',
    cursor: 'pointer',
    overflowWrap: 'anywhere',
    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
  },
  noClick: {
    cursor: 'default',

    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  link: {
    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
  },
  userItem: {},
  icons: {
    display: 'flex',
    alignItems: 'baseline',
    columnGap: 10,
    minHeight: '100%',
  },
  icon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 20,
    height: 20,
  },
  removeIcon: {
    color: COLORS.TEXT.SECONDARY,
    cursor: 'pointer',
  },
  addIcon: {
    color: COLORS.ACTION.PRIMARY,
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.STATUS.CRITICAL,
    },
  },
  iconMail: {
    color: COLORS.TEXT.SECONDARY,
  },
  iconCopyLink: {
    color: COLORS.ACTION.PRIMARY,
    border: 'none',
    background: 'none',
    cursor: 'pointer',
  },
  loading: {
    opacity: 0.7,
    animation: '$loading 1s linear infinite alternate',
    background: `linear-gradient(90deg, ${COLORS.DIVIDER} 0%, ${COLORS.WHITE} 100%)`,
    backgroundSize: '400% 100%',
  },
  '@keyframes loading': {
    '0%': {
      backgroundPosition: '0% 0%',
    },
    '100%': {
      backgroundPosition: '100% 0%',
    },
  },
  copyLinkDisabled: {
    cursor: 'default',
    color: COLORS.TEXT.SECONDARY,
  },
  copyLinkPopup: {
    '&-content': {
      textAlign: 'center',
      maxWidth: 200,
    },
  },
});
