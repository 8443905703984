import { RockyAnalyticsEvent } from '@shared/enums/rockyAnalyticsEvent';
import { User } from '@shared/interfaces/user';
import amplitude from 'amplitude-js';

const amplitudeInstance = amplitude.getInstance();
const AMPLITUDE_API_KEY = '4beed7baac7dd99785c0ade25968bfec';

export const initAnalytics = () => {
  amplitudeInstance.init(AMPLITUDE_API_KEY);
  amplitudeInstance.setUserProperties({
    environment: process.env.REACT_APP_ENV,
  });
};

export const registerUser = (user: User) => {
  amplitudeInstance.setUserId(user.email);
  amplitudeInstance.setUserProperties({
    isManager: user.hasReporters,
    team: user.primaryDepartment?.name,
  });
};

export const logEvent = (eventName: RockyAnalyticsEvent, data?: unknown) => {
  amplitudeInstance.logEvent(eventName, data);
};
