import { SurveyHistoryPageData } from '@modules/HappinessModule/interfaces/SurveyHistoryPageData';
import { SurveyHistoryPageDataParams } from '@modules/HappinessModule/interfaces/SurveyHistoryPageDataParams';
import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from 'shared/interfaces/asyncData';

export const getQuestionGroupsData = createAction<SurveyHistoryPageDataParams>(
  'surveyHistoryPage/getQuestionGroupsData'
);
export const setQuestionGroupsData = createAction<AsyncData<SurveyHistoryPageData[]>>(
  'surveyHistoryPage/setQuestionGroupsData'
);
export const setQuestionGroupsDataLoading = createAction<void>('surveyHistoryPage/setQuestionGroupsDataLoading');

export const getAllLocationsData = createAction<void>('surveyHistoryPage/getAllLocationsData');
export const setAllLocationsData = createAction<AsyncData<string[]>>('surveyHistoryPage/setAllLocationsData');
export const setAllLocationsDataLoading = createAction<void>('surveyHistoryPage/setAllLocationsDataLoading');
