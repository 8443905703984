export const checkIfZohoRecruitLinkShown = ({
  zohoRecruitId,
  isNewHomeTomSectionReadable,
  isNewHomeNeeded,
}: {
  zohoRecruitId: string | null;
  isNewHomeTomSectionReadable: boolean;
  isNewHomeNeeded: boolean;
}) => {
  return zohoRecruitId && isNewHomeTomSectionReadable && isNewHomeNeeded;
};
