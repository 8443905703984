import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    paddingBottom: 0,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    width: '100%',
    maxWidth: '1400px',
    margin: '24px auto',

    [BREAKPOINTS.DESKTOP13]: {
      margin: 0,
      borderRadius: 0,
    },

    [BREAKPOINTS.MOBILE]: {
      height: 'auto',
      margin: '0 auto',
    },
  },
  profileComponent: {
    width: '100%',
    padding: '16px 24px',

    [BREAKPOINTS.MOBILE]: {
      padding: '16px',
    },
  },
  backLinkContainer: {
    marginTop: '12px',
    marginBottom: '-8px',
  },
  contentHolder: {
    width: '100%',
  },
  loader: {
    borderRadius: 8,
    width: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5% 0px',
  },
  divider: {
    width: '100%',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  sabbaticalAlert: {
    margin: '1rem 1.5rem',
    alignSelf: 'stretch',
    backgroundColor: COLORS.HIGHLIGHTS.PROMINENT,
    '& p': {
      color: COLORS.TEXT.SECONDARY,
    },
  },
});
