import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  compPlan: {
    minWidth: 230,

    '@media (max-width: 1100px)': {
      minWidth: 150,
    },
  },
  blockTitle: {
    margin: '-12px 0 28px',
    fontSize: 18,
    fontWeight: 500,
  },
  compensationItem: {
    display: 'flex',
    gap: 20,
    '&:not(:last-child)': {
      marginBottom: 30,
    },
  },
  compensationItemNameContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
  },
  compensationItemName: {
    fontWeight: '400',
    fontSize: '12px',
    lineHeight: '16px',
    color: COLORS.TEXT.SECONDARY,
  },
  compensationValue: {
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '120%',
    color: COLORS.TEXT.PRIMARY,
  },
  currency: {
    fontWeight: '400',
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
  },
  itemValue: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.TEXT.PRIMARY,
  },
});
