import httpClient from '@shared/api/httpClient';
import { PeerReviewFeedbackTableDataItem } from '../interfaces/PeerReviewFeedbackTableDataItem';
import { PeerReviewFeedbackTableResponseDataItem } from './types';

export const fetchMyPeerReviewsData = (): Promise<PeerReviewFeedbackTableDataItem[]> =>
  httpClient
    .get<{ data: PeerReviewFeedbackTableResponseDataItem[] }>('/evaluations/my-pending-feedbacks/')
    .then(({ data: { data } }) =>
      data.map(
        ({
          evaluatee,
          evaluator,
          evaluation_date,
          evaluation_status,
          id,
          status,
          reviewer_note,
          evaluation_history_id,
        }) => ({
          evaluatee: {
            name: evaluatee.full_name,
            ...evaluatee,
          },
          evaluator: {
            name: evaluator.full_name,
            ...evaluator,
          },
          evaluationDate: evaluation_date,
          evaluationStatus: evaluation_status,
          peerFeedbackId: id,
          evaluationId: evaluation_history_id,
          status,
          reviewerNote: reviewer_note,
        })
      )
    );
