import { put, all, call, takeLatest } from 'redux-saga/effects';
import { setUserHappinessData, getUserHappinessData, setUserHappinessDataLoading } from './actions';
import { DataState } from '@shared/enums/DataState';
import { fetchUserHappinessData } from '@modules/HappinessModule/api/userHappinessData';
import { ResponsesActivityDataItem } from '@modules/HappinessModule/interfaces/ResponsesActivityDataItem';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';

function* getUserHappinessDataAsync({ payload }: ReturnType<typeof getUserHappinessData>) {
  try {
    const setLoadingAction = setUserHappinessDataLoading();
    yield put(setLoadingAction);
    let response: ResponsesActivityDataItem[] = yield call(fetchUserHappinessData, payload);
    response = [...response].sort((a, b) => (a.date > b.date ? -1 : 1));
    const action = setUserHappinessData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUserHappinessData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* watchGetUserHappinessData() {
  yield takeLatest(getUserHappinessData.type, getUserHappinessDataAsync);
}

export function* userHappinessPageSaga(): Generator {
  yield all([watchGetUserHappinessData()]);
}
