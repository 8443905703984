import { all, call, put, takeLatest } from 'redux-saga/effects';
import { loadAllDepartments, loadHiddenDepartments } from './actions';
import { setAllDepartments, setHiddenDepartments } from './reducers';
import { DataState } from '@shared/enums/DataState';
import { AllDepartmentsData } from '@modules/HappinessModule/interfaces/AllDepartmentsData';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { fetchAllDepartments, fetchHiddenDepartments } from '@modules/App/api/departments';

function* loadAllDepartmentsAsync({ payload }: ReturnType<typeof loadAllDepartments>) {
  try {
    yield put(setAllDepartments({ data: null, state: DataState.Pending }));
    const response: AllDepartmentsData[] = yield call(fetchAllDepartments, payload);
    yield put(setAllDepartments({ data: response, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(
      setAllDepartments({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* loadHiddenDepartmentsAsync() {
  try {
    yield put(setHiddenDepartments({ data: null, state: DataState.Pending }));
    const response: string[] = yield call(fetchHiddenDepartments);
    yield put(setHiddenDepartments({ data: response, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(
      setHiddenDepartments({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

export function* departmentsSaga(): Generator {
  yield all([
    takeLatest(loadAllDepartments.type, loadAllDepartmentsAsync),
    takeLatest(loadHiddenDepartments.type, loadHiddenDepartmentsAsync),
  ]);
}
