import { HappinessPermission } from '@modules/HappinessModule/enums/HappinessPermission';

export const getHappinessPermissions = (permissions: HappinessPermission[]) => {
  const happinessPermissions = {
    resolveHappiness: permissions.includes(HappinessPermission.ResolveHappiness),
    readMessage: permissions.includes(HappinessPermission.ReadMessage),
  };

  return happinessPermissions;
};
