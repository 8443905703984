import React, { FC, useRef, useState, forwardRef, useCallback, ForwardedRef } from 'react';
import { FixedSizeList, FixedSizeListProps } from 'react-window';
import { VirtualTableContext } from './context/VirtualTableContext';

interface FixedSizeListExtended extends FixedSizeList {
  _getItemStyle: (item: number) => React.CSSProperties;
}

export const VirtualTable: FC<FixedSizeListProps & { ref: ForwardedRef<unknown> }> = forwardRef(
  ({ innerElementType, children, ...rest }: FixedSizeListProps, ref) => {
    const listRef = useRef<FixedSizeList | null>();
    const [top, setTop] = useState(0);

    const setRefs = useCallback(
      (node) => {
        if (typeof ref === 'function') {
          ref(node);
        }
        listRef.current = node;
      },
      [ref]
    );

    return (
      <VirtualTableContext.Provider value={{ top, setTop }}>
        <FixedSizeList
          {...rest}
          innerElementType={innerElementType}
          onItemsRendered={(props) => {
            const style =
              listRef.current &&
              (listRef.current as unknown as FixedSizeListExtended)._getItemStyle(props.overscanStartIndex);
            setTop((style && style.top && +style.top) || 0);
            rest.onItemsRendered && rest.onItemsRendered(props);
          }}
          ref={setRefs}
        >
          {children}
        </FixedSizeList>
      </VirtualTableContext.Provider>
    );
  }
);
