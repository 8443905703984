import { RootState } from '@modules/App/redux/store';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { FlagSnapshotData } from '@modules/HappinessModule/interfaces/FlagSnapshotData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { FlagPermissionsData } from '../../../interfaces/FlagPermissionsData';

export const flagDataSelector = (state: RootState): AsyncData<FlagData> => state.happiness.flagPage.flagData;

export const snapshotDataSelector = (state: RootState): AsyncData<FlagSnapshotData> =>
  state.happiness.flagPage.snapshotData;

export const userFlagPermissionsSelector = (state: RootState): AsyncData<FlagPermissionsData> =>
  state.happiness.flagPage.permissions;
