import { put, all, call, takeLeading, takeLatest } from 'redux-saga/effects';

import { DataState } from '@shared/enums/DataState';
import { acceptTermsOfUse, getCurrentUser, checkCurrentLocation, setCurrentUser } from './actions';
import { fetchCurrentUser, postAcceptTermsOfUse, postCheckCurrentLocation } from '@modules/App/api/currentUser';
import { setLocationNotMatchingSince, setTermsOfUseAcceptedVersion } from './reducers';
import { User } from '@shared/interfaces/user';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { HttpStatusCode } from '@shared/enums/HttpStatusCode';

function* getCurrentUserAsync() {
  try {
    const response: User = yield call(fetchCurrentUser);
    const setTeamsHappinessDataAction = setCurrentUser({ data: response, state: DataState.Fulfilled });
    yield put(setTeamsHappinessDataAction);
  } catch (error) {
    const errorInfo = getErrorInfo(error);
    if (errorInfo?.code === HttpStatusCode.Forbidden) {
      const setUserInvalid = setCurrentUser({ data: null, state: DataState.Rejected });
      yield put(setUserInvalid);
    }
  }
}

function* checkCurrentLocationAsync({ payload }: ReturnType<typeof checkCurrentLocation>) {
  const locationNotMatchingSince: string = yield call(postCheckCurrentLocation, payload);
  yield put(setLocationNotMatchingSince(locationNotMatchingSince));
}

function* acceptTermsOfUseAsync({ payload }: ReturnType<typeof acceptTermsOfUse>) {
  yield call(postAcceptTermsOfUse, payload);
  yield put(setTermsOfUseAcceptedVersion(payload));
}

function* watchGetCurrentUser() {
  yield takeLatest(checkCurrentLocation.type, checkCurrentLocationAsync);
  yield takeLeading(getCurrentUser.type, getCurrentUserAsync);
  yield takeLatest(acceptTermsOfUse.type, acceptTermsOfUseAsync);
}

export function* userSaga(): Generator {
  yield all([watchGetCurrentUser()]);
}
