import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import { getUserProfileData, updateUserAddressData, editUserContacts, loadMetroAreas } from './actions';
import { DataState } from '@shared/enums/DataState';
import { editUserProfileData, fetchUserProfileData } from '@modules/App/api/userProfileData';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { patchUserPhysicalAddressData, patchUserDeliveryAddressData } from '@modules/App/api/users';
import {
  setEditUserContactsUpdatingState,
  setMetroAreasData,
  setUserAddressUpdatingState,
  setUserContactsUpdateNetworkError,
  setUserProfileData,
} from './reducers';
import { UserProfileData } from '@shared/interfaces/user';
import { MetroAreaData } from '@shared/interfaces/MetroAreaData';
import { getMetroAreas } from '@modules/App/api/metroAreas';

function* getUserProfileDataAsync({ payload }: ReturnType<typeof getUserProfileData>) {
  try {
    yield put(setUserProfileData({ data: null, state: DataState.Pending }));
    const response: UserProfileData = yield call(fetchUserProfileData, payload);
    const action = setUserProfileData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUserProfileData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* editUserContactsAsync({ payload }: ReturnType<typeof editUserContacts>) {
  let hasErorr = false;
  try {
    yield put(setEditUserContactsUpdatingState(DataState.Pending));
    yield put(setUserContactsUpdateNetworkError(null));
    yield call(editUserProfileData, payload);
    yield put(getUserProfileData(payload.id));
  } catch (error) {
    yield put(setEditUserContactsUpdatingState(DataState.Rejected));
    hasErorr = true;
    const e = getErrorInfo(error);
    yield put(setUserContactsUpdateNetworkError(e?.message ?? null));
    yield put(displayNotification('Something went wrong 🥺'));
  } finally {
    if (!hasErorr) {
      yield put(setEditUserContactsUpdatingState(DataState.Fulfilled));
      yield put(displayNotification('Changes successfully saved!'));
    }
  }
}

function* updateUserAddressDataAsync({ payload }: ReturnType<typeof updateUserAddressData>) {
  try {
    const user: ReturnType<typeof userSelector> = yield select(userSelector);
    const userId = user?.id;
    if (!userId) {
      return;
    }
    yield put(setUserAddressUpdatingState(DataState.Pending));
    yield call(patchUserPhysicalAddressData, userId, payload);
    const userAddressData: UserProfileData = yield call(patchUserDeliveryAddressData, userId, payload.deliveryAddress);
    yield put(setUserAddressUpdatingState(DataState.Fulfilled));
    yield put(
      setUserProfileData({
        data: userAddressData,
        state: DataState.Fulfilled,
      })
    );
    yield put(displayNotification('Your address and clothes size have been successfully updated'));
  } catch (e) {
    yield put(setUserAddressUpdatingState(DataState.Rejected));
    yield put(displayNotification('Something went wrong while updating your address and clothes size'));
  }
}

function* loadMetroAreasAsync() {
  try {
    yield put(setMetroAreasData({ data: null, state: DataState.Pending }));
    const metroAreas: MetroAreaData = yield call(getMetroAreas);
    yield put(setMetroAreasData({ data: metroAreas, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(setMetroAreasData({ data: null, state: DataState.Rejected }));
  }
}

function* watchGetUserProfileData() {
  yield takeLatest(getUserProfileData.type, getUserProfileDataAsync);
  yield takeLatest(editUserContacts.type, editUserContactsAsync);
  yield takeLatest(updateUserAddressData.type, updateUserAddressDataAsync);
  yield takeLatest(loadMetroAreas.type, loadMetroAreasAsync);
}

export function* userProfilePageSaga(): Generator {
  yield all([watchGetUserProfileData()]);
}
