import React, { FC, memo } from 'react';
import { useStyles } from './PrevEvalData.styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import classNames from 'classnames';
import { paths } from '@shared/enums/paths';
import { EvaluationRescheduleHistoryData } from '@modules/EvaluationModule/interfaces/EvaluationRescheduleHistory';
import { RescheduledBlock } from '@modules/EvaluationModule/components/RescheduledBlock';

interface Props {
  prevEvalData?: {
    evaluationId: string;
    evaluationDate: string;
    notes: string;
    goals: string;
    isNotRocky: boolean;
  };
  evaluationRescheduleHistory?: EvaluationRescheduleHistoryData[];
  userId?: string;
}

const PrevEvalDataComponent: FC<Props> = ({ prevEvalData, userId, evaluationRescheduleHistory }) => {
  const styles = useStyles();

  const evalDate = prevEvalData?.evaluationDate ? format(parseISO(prevEvalData.evaluationDate), 'MMMM yyyy') : '';

  return (
    <div className={styles.root}>
      <div>
        <p className={styles.blockTitle}>Previous goals and action items</p>

        {prevEvalData?.isNotRocky ? (
          <span className={styles.dateText}>{evalDate}</span>
        ) : (
          <a
            href={`${paths.users}/${userId}/${paths.evaluations}/${prevEvalData?.evaluationId}`}
            className={styles.dateLink}
          >
            {evalDate}
          </a>
        )}

        <p className={classNames(styles.evalInfo, { [styles.noEvalInfo]: !prevEvalData?.goals })}>
          {prevEvalData?.goals ? prevEvalData?.goals : 'No goals have been set'}
        </p>
        <p className={classNames(styles.evalInfo, { [styles.noEvalInfo]: !prevEvalData?.notes })}>
          {prevEvalData?.notes ? prevEvalData?.notes : 'No additional notes'}
        </p>
      </div>
      {evaluationRescheduleHistory && (
        <div className={styles.halfPart}>
          <RescheduledBlock rescheduledEvaluations={evaluationRescheduleHistory} />
        </div>
      )}
    </div>
  );
};

export const PrevEvalData = memo(PrevEvalDataComponent);
