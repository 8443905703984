import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modalHeaderTitle: {
    marginLeft: 8,
  },
  modalHeaderStyles: {
    display: 'flex',
    alignItems: 'center',
  },
  modalBody: {
    padding: '32px 26px',
  },
  modalContent: {
    backgroundColor: COLORS.CONTENT.MESSAGE,
    borderRadius: 4,
    padding: 18,
  },
  descriptionWrapper: {
    padding: 18,
  },
  goalCategory: {
    color: COLORS.TEXT.SECONDARY,
  },
  goalData: {
    color: COLORS.TEXT.PRIMARY,
  },
  goalContentWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  goalResult: {
    marginRight: 18,
    minWidth: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  goalCompletenessBadge: {
    display: 'inline-block',
    borderRadius: 20,
    padding: '4px 12px 5px',
    margin: '-4px 0 -5px',
    fontWeight: 500,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    color: COLORS.WHITE,
  },
  goalCompletenessBadgeOff: {
    color: COLORS.TEXT.DISABLED,
    background: COLORS.TEXT.DISABLEDBG,
    textShadow: 'none',
  },
});
