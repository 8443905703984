import React, { memo } from 'react';
import { useStyles } from './EvalPageHeader.styles';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { AsyncData } from '@shared/interfaces/asyncData';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { DataState } from '@shared/enums/DataState';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { useSelector } from 'react-redux';
import { useAuth } from '@shared/hooks/useAuth';
import Pending from '@mui/icons-material/AccessTimeOutlined';
import { ScheduleEvalMetting } from '@modules/EvaluationModule/components/ScheduleEvalMetting/ScheduleEvalMetting';
import TabSwitcher from '@shared/components/TabSwitcher/TabSwitcher';
import { useParams } from 'react-router-dom';
import { getEvalTabs } from '../../helpers/getEvalTabs';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { evalPermissionsSelector } from '../../redux/selectors';
import { Button } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { RescheduleModal } from '@modules/EvaluationModule/components/MyTeamEvalReport/shared/RescheduleModal/RescheduleModal';
import { useModalState } from '@shared/hooks/useModalState';
interface Props {
  evalPageData: AsyncData<EvalPageData>;
}

const EvalPageHeaderComponent: React.FC<Props> = ({ evalPageData }) => {
  const { data, state } = evalPageData;
  const styles = useStyles();
  const evalStatus = data?.status;
  const isEvalClosed = evalStatus === EvaluationStatus.Closed;
  const isEvalOngoing = evalStatus === EvaluationStatus.Ongoing;
  const isEvalUpcoming = evalStatus === EvaluationStatus.Upcoming;
  const isEop = data?.typeOfInteraction === EvaluationType.EndOfProbation;
  const evalPermissions = useSelector(evalPermissionsSelector);

  const isDataLoading = state === DataState.Pending;
  const { currentUser } = useAuth();
  const userPersonalData = useSelector(userSelector);

  const isConfirmedByEvaluatee = data?.evaluation.evaluatee?.isConfirmed;
  const isConfirmedByEvaluator = data?.evaluation.evaluator?.isConfirmed;

  const user = data?.userHistory;
  const evaluatorFirstName = user ? (data.owner.name || ' ').split(' ')[0] : '';
  const userFirstName = user ? (user.name || ' ').split(' ')[0] : '';

  const isCurrentUserEvaluator = currentUser?.id === userPersonalData?.primaryReportsTo.id;

  const evaluateePP = user?.peoplePartner;
  const isCurrentUserEvaluateesPeoplePartner = currentUser?.id === evaluateePP?.id;
  const isCurrentUserEvaluatee = currentUser?.id === data?.userId;

  const { id: userId, evaluationId } = useParams();
  const evalTabs =
    userId && evaluationId
      ? getEvalTabs({
          userId,
          evaluationId,
          permissions: evalPermissions.data?.evaluation,
          isEvalClosed,
          isEop,
          isEvalOngoing,
        })
      : null;

  const parsedDateStr = data ? format(parseISO(data.evaluationDate), 'MMMM yyyy') : '...';
  const isRescheduleBtnDisabled =
    evalStatus === EvaluationStatus.Closed ||
    (evalStatus === EvaluationStatus.Ongoing && isCurrentUserEvaluateesPeoplePartner);
  const {
    openModal: openRescheduleModal,
    closeModal: closeRescheduleModal,
    isModalOpen: isRescheduleModalOpen,
  } = useModalState();

  return (
    <div className={styles.header}>
      <div className={styles.leftHeaderBlock}>
        <RockySkeleton
          className={styles.dateTextSkeleton}
          isLoading={isDataLoading}
          element={
            (isCurrentUserEvaluator || isCurrentUserEvaluateesPeoplePartner) && !isEop ? (
              data?.evaluationDate &&
              evalStatus &&
              userPersonalData?.primaryReportsTo.name && (
                <>
                  <Button
                    sx={{ whiteSpace: 'nowrap' }}
                    endIcon={isRescheduleBtnDisabled ? undefined : <EditIcon />}
                    disabled={isRescheduleBtnDisabled}
                    onClick={openRescheduleModal}
                  >
                    {parsedDateStr}
                  </Button>
                  <RescheduleModal
                    isEop={isEop}
                    evaluationData={{
                      evaluationDate: data.evaluationDate,
                      evaluationId: data.evaluationId,
                      evaluateeName: userPersonalData?.name,
                      peoplePartnerName: data?.userHistory?.peoplePartner?.name ?? null,
                      status: evalStatus as EvaluationStatus,
                    }}
                    isModalOpen={isRescheduleModalOpen}
                    closeModal={closeRescheduleModal}
                  />
                </>
              )
            ) : (
              <span className={styles.evalMonthSimple}>{parsedDateStr}</span>
            )
          }
        />
        {!isEvalUpcoming && !isDataLoading && <TabSwitcher button tabs={evalTabs} />}
      </div>
      <span className={styles.rightHeaderBlock}>
        <RockySkeleton
          className={styles.evalInfoSkeleton}
          isLoading={isDataLoading}
          element={
            isEvalClosed ? (
              <span>Closed evaluation</span>
            ) : (
              isEvalOngoing && (
                <>
                  {isConfirmedByEvaluatee && !isConfirmedByEvaluator && !isCurrentUserEvaluator ? (
                    <div className={styles.evalStatusInfo}>
                      <div className={styles.pendingIcon}>
                        <Pending />
                      </div>
                      <span>Waiting for {evaluatorFirstName}'s feedback</span>
                    </div>
                  ) : isConfirmedByEvaluator && !isConfirmedByEvaluatee && !isCurrentUserEvaluatee ? (
                    <div className={styles.evalStatusInfo}>
                      <div className={styles.pendingIcon}>
                        <Pending />
                      </div>
                      <span>Waiting for {userFirstName}'s feedback</span>
                    </div>
                  ) : (
                    (isCurrentUserEvaluator || isCurrentUserEvaluateesPeoplePartner) && (
                      <ScheduleEvalMetting
                        googleCalendarEvalEventsMeetingLinkData={{
                          evaluateeName: user?.name || '',
                          evaluateeEmail: user?.email || '',
                          userId: user?.id || '',
                          evaluationId: data?.evaluationId || '',
                          evaluatorEmail: data?.owner?.email || '',
                        }}
                        googleCalendarEvent={data?.googleCalendarEvent || null}
                        isScheduleBtnShowen
                        isLoading={isDataLoading}
                      />
                    )
                  )}
                </>
              )
            )
          }
        />
      </span>
    </div>
  );
};

export const EvalPageHeader = memo(EvalPageHeaderComponent);
