import React, { FC, memo } from 'react';
import { LeadershipQuestionDescription } from './components/LeadershipQuestionDescription';
import { useStyles } from './EvalQuestionDescription.styles';

interface Props {
  questionName?: string | null;
  questionDescription: string[];
}

const EvalQuestionDescriptionComponent: FC<Props> = ({ questionName, questionDescription }) => {
  const styles = useStyles();

  return (
    <>
      {questionName === 'projectLeadership' ? (
        <LeadershipQuestionDescription />
      ) : (
        <ul className={styles.root}>
          {questionDescription?.map((el, index) => (
            <li key={index}>{el}</li>
          ))}
        </ul>
      )}
    </>
  );
};

export const EvalQuestionDescription = memo(EvalQuestionDescriptionComponent);
