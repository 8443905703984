import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  popup: {
    '&-content': {
      width: 'auto !important',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
      color: COLORS.TEXT.PRIMARY,
      fontWeight: '400',
      fontSize: '14px',
      padding: '5px 10px',
      background: COLORS.WHITE,
    },
    '&-arrow': {
      color: COLORS.WHITE,
    },
  },
});
