import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setUserHappinessData } from './actions';
import { State } from './types';
import { resolveSituationFromPeoplePage } from '../../PeopleHappinessPage/redux/actions';

const initialState: State = {
  userHappinessData: { data: null, state: DataState.Pending },
};

export const slice = createSlice({
  name: 'userHappinessPage',
  initialState,
  reducers: {
    setUserHappinessDataLoading(state) {
      state.userHappinessData = { data: null, state: DataState.Pending };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserHappinessData, (state, action) => {
      state.userHappinessData = action.payload;
    });
    builder.addCase(resolveSituationFromPeoplePage, (state, { payload: { questionId, resolvedBy } }) => {
      if (!state.userHappinessData.data) {
        return;
      }
      state.userHappinessData.data = state.userHappinessData.data.map((entry) =>
        entry.id === questionId
          ? {
              ...entry,
              resolvedBy: resolvedBy,
            }
          : entry
      );
    });
  },
});

export default slice.reducer;
