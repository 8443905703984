import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {},
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    color: COLORS.TEXT.PRIMARY,
    fontSize: 24,
    fontWeight: '500',
    marginTop: 48,
    marginBottom: 24,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
      marginBottom: 16,
    },
  },
  feedbackContainer: {
    [BREAKPOINTS.DESKTOP]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: 16,
    },

    [BREAKPOINTS.TABLET]: {
      display: 'grid',
      gridTemplateColumns: '1fr',
      gap: 16,
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'flex',
      flexDirection: 'column',
      rowGap: 16,
    },
  },
  twoInRow: {
    gridTemplateColumns: '1fr 1fr',
  },
  threeInRow: {
    gridTemplateColumns: '1fr 1fr 1fr',
  },
  fisrtInGrid: {
    gridRowStart: '1',
    gridColumnStart: '1',
  },
  secondInGrid: {
    gridRowStart: '1',
    gridColumnStart: '2',
  },
  oneInGrid: {
    gridRowStart: '1',
    gridColumnStart: '1',
  },
});
