import { CompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { EditCompensationFields } from '@modules/EvaluationModule/interfaces/EditCompensationFields';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';
import Modal from '@mui/material/Modal';
import { DataState } from '@shared/enums/DataState';
import classNames from 'classnames';
import { FormikProps } from 'formik';
import { formatFinancialValue } from '../../helpers/currencyOptions';
import { FC, useEffect } from 'react';
import { useConfirmationModalData } from '../../hooks/useConfirmationModalData';
import { useStyles } from './ConfirmationModal.styles';
import { useSelector } from 'react-redux';
import { isAddNewCompensationCompleteSelector } from '../../redux/selectors';
import format from 'date-fns/format';

interface Props {
  seniority?: string;
  country?: string;
  userPosition?: string;
  confirmationOpen: boolean;
  handleModalClose: () => void;
  handleBackToEdit: () => void;
  isExceeded: boolean | null;
  currentCompensation: CompensationData;
  formik: FormikProps<EditCompensationFields>;
  period: Date | null;
}

export const ConfirmationModal: FC<Props> = ({
  confirmationOpen,
  handleModalClose,
  isExceeded,
  country,
  currentCompensation,
  formik,
  period,
  seniority,
  userPosition,
  handleBackToEdit,
}) => {
  const { userFirstName, editCompensationDataState } = useConfirmationModalData();
  const styles = useStyles();
  const isAddNewCompensationComplete = useSelector(isAddNewCompensationCompleteSelector);
  const currentBaseSalary = currentCompensation.historicalCompensations?.[0]?.currentCompensation;

  useEffect(() => {
    if (isAddNewCompensationComplete) {
      handleModalClose();
    }
  }, [isAddNewCompensationComplete]);

  const onClose = (event: MouseEvent, reason: string) => {
    if (reason === 'backdropClick') {
      return;
    }
    handleModalClose();
  };

  const isRangeDataMissing = !seniority || !country || !userPosition;

  return (
    <Modal open={confirmationOpen} onClose={onClose}>
      <div className={styles.confirmationModalRoot}>
        <h2 className={styles.modalTitle}>Confirm base compensation update</h2>
        <div className={styles.modalDescription}>
          You're about to change {userFirstName}'s base compensation from&nbsp;
          <span className={styles.payRateInDescription}>
            ${formatFinancialValue(currentBaseSalary)} to&nbsp;
            <span
              className={classNames(styles.payRateInDescription, {
                [styles.attentionText]: !isExceeded && !isRangeDataMissing,
              })}
            >
              ${formatFinancialValue(formik.values.compensationRate)}
            </span>{' '}
            {period && <>starting from {format(period, 'MMMM yyyy')}</>}
          </span>
          {!isRangeDataMissing ? (
            <>
              ,&nbsp;
              {isExceeded ? (
                <span>
                  which fits company ranges for {seniority} {userPosition} in {country}.
                </span>
              ) : (
                <span className={styles.attentionText}>
                  which exceeds company ranges for {seniority} {userPosition} in {country}.
                </span>
              )}
            </>
          ) : (
            '.'
          )}
        </div>
        {formik.values.comments && (
          <div className={styles.commentsBlock}>
            <span>Notes</span>
            {formik.values.comments}
          </div>
        )}
        <div className={styles.btnContainer}>
          <Button variant="contained" className={styles.backBtn} onClick={handleBackToEdit}>
            Back
          </Button>
          <LoadingButton
            variant="contained"
            loading={editCompensationDataState === DataState.Pending}
            onClick={formik.submitForm}
          >
            Confirm
          </LoadingButton>
        </div>
      </div>
    </Modal>
  );
};
