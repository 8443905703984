import { RootState } from '@modules/App/redux/store';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { AsyncData } from '@shared/interfaces/asyncData';
import { createSelector } from '@reduxjs/toolkit';
import { sortData } from '@shared/redux/helpers/sortData';
import { PeerReviewFeedbackTableDataItem } from '@modules/EvaluationModule/interfaces/PeerReviewFeedbackTableDataItem';

const getSortInfo = (state: RootState): SortInfo => state.evaluation.myPeerReviewsPage.sortInfo;
const getTableData = (state: RootState): AsyncData<PeerReviewFeedbackTableDataItem[]> =>
  state.evaluation.myPeerReviewsPage.myPeerReviewsData;

export const getMyPeerReviewsSortedTableData = createSelector(
  [getTableData, getSortInfo],
  ({ state, data }, sortInfo) => ({
    data: data ? sortData(data, sortInfo) : [],
    state,
  })
);

export const sortMyPeerReviewsInfoSelector = (state: RootState): SortInfo =>
  state.evaluation.myPeerReviewsPage.sortInfo;
