import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  dialogRoot: {
    padding: '66px 90px',
  },
  historyBlock: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    width: '100%',
    marginBottom: '32px',
    padding: '16px 24px',
    borderRadius: '4px',
  },
});
