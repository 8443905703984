import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    background: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: '0 0 8px 8px',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',

    [BREAKPOINTS.MOBILE]: {
      height: 'auto',
      paddingBottom: 8,
    },
  },
  content: {
    padding: '0 24px',
    display: 'flex',
    flexDirection: 'row',
    gap: 24,

    [BREAKPOINTS.MOBILE]: {
      padding: '8px 16px',
      gap: 12,
    },
  },
  rateNumber: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },
  questionText: {
    fontSize: '24px',
    fontWeight: '500',
    margin: 0,
  },
  datesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    [BREAKPOINTS.MOBILE]: {
      justifySelf: 'end',
    },
  },
  contentContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      flexDirection: 'column',
    },
  },
  rateContent: {
    display: 'flex',
    width: '100%',
    gap: 8,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
    },
  },
  rateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flexGrow: 1,

    [BREAKPOINTS.MOBILE]: {
      margin: '-24px 0',
    },
  },
  rateHolder: {
    flexGrow: 1,
  },
  engagementPercentage: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '14px',
    marginRight: 32,

    [BREAKPOINTS.MOBILE]: {
      marginRight: 0,
    },
  },
  indicator: {
    fontWeight: '500',
    color: COLORS.STATUS.SUCCESS,
    marginLeft: 4,
  },
  labelsBlock: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      marginRight: 0,
      display: 'block',
    },
  },
  avgRateLabel: {
    fontWeight: '400',
    color: COLORS.TEXT.PRIMARY,
  },
  meterSkeleton: {
    height: '36px',
    margin: '16px 0',
  },
  votedLabel: {
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    fontWeight: '400',
    [BREAKPOINTS.DESKTOP]: {
      whiteSpace: 'nowrap',
    },
  },
  votedDifferenceLabelPositive: {
    color: COLORS.STATUS.SUCCESS,
  },
  votedDifferenceLabelNegative: {
    color: COLORS.STATUS.CRITICAL,
  },
  votedDifferenceLabel: {
    color: COLORS.TEXT.SECONDARY,
  },
  pieChartHolder: {
    [BREAKPOINTS.MOBILE]: {
      position: 'absolute',
      top: -14,
      right: -6,
    },
  },
  engagementBlock: {
    [BREAKPOINTS.MOBILE]: {
      paddingTop: 0,
      gap: 0,
      position: 'relative',
    },
  },
  labelMobile: {
    display: 'none',
    [BREAKPOINTS.MOBILE]: {
      display: 'inline',
    },
  },
  labelDesktop: {
    display: 'inline',
    marginLeft: 4,
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  votedBlockContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  noContentblock: {
    padding: '24px 0',
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '140%',
    textAlign: 'center',
    color: COLORS.SCALEEVAL.CANT,
  },
});
