import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  infoRow: {
    display: 'flex',
    columnGap: 16,
    fontSize: '14px',
  },
  infoRowIcon: {
    height: 20,
    width: 20,

    '& svg': {
      fill: COLORS.TEXT.DISABLED,
    },
  },
  infoRowDetail: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  secondaryText: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '13px',
    fontWeight: '400',
  },
  mediumTextSkeleton: {
    width: 160,
    height: 20,
  },
  smallTextSkeleton: {
    width: 100,
    height: 20,
  },
  iconSkeleton: {
    width: 20,
    height: 20,
  },
  reportIssue: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: 13,
    textDecoration: 'underline',

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
      cursor: 'pointer',
    },
  },
  mainText: {
    wordWrap: 'break-word',
  },
});
