import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setMyTeamFlagsData } from './actions';

import { State } from './types';

const initialState: State = {
  myTeamFlagsData: { data: null, state: DataState.Pending },
};

export const slice = createSlice({
  name: 'myTeamFlags',
  initialState,
  reducers: {
    setMyTeamFlagsDataLoading(state) {
      state.myTeamFlagsData = { data: null, state: DataState.Pending };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setMyTeamFlagsData, (state, action) => {
      state.myTeamFlagsData = action.payload;
    });
  },
});

export const { setMyTeamFlagsDataLoading } = slice.actions;

export default slice.reducer;
