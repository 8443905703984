import { makeStyles } from '@mui/styles';

import novaPoshta from '@assets/icons/NovaPoshtaLogo.svg';

export const useStyles = makeStyles({
  novaPoshta: {
    '&::before': {
      content: `url(${novaPoshta})`,
      widht: '18px',
      height: '18px',
      marginRight: '0.5rem',
    },
  },
});
