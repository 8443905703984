import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';

import happinessReducers from '../../HappinessModule/redux/reducers';
import userReducers from './user/reducers';
import feedbackReducers from './feedback/reducers';
import notificationsReducers from './notifications/reducers';
import evaluationReducers from '../../EvaluationModule/redux/reducers';
import userPageReducer from '../pages/UserPage/redux/reducers';
import pageTitleReducers from './pageTitle/reducers';
import orgChartDataReducer from '../pages/OrgChartPage/redux/reducers';
import permissionsRedusers from './permissions/reducers';
import userProfilePageReducer from '../pages/UserProfilePage/redux/reducers';
import externalLinksReducer from '../pages/ExternalLinksPage/redux/reducers';
import featuresReducer from '../redux/features/reducers';
import usersReducer from './users/reducers';
import googleApiReducer from './googleApi/reducers';
import departmentReducer from './departments/reducers';

import { rootSaga } from './rootSaga';

const sagaMiddleware = createSagaMiddleware();

export const store = configureStore({
  reducer: {
    happiness: happinessReducers,
    evaluation: evaluationReducers,
    user: userReducers,
    permissions: permissionsRedusers,
    feedback: feedbackReducers,
    notifications: notificationsReducers,
    externalLinks: externalLinksReducer,
    userPage: userPageReducer,
    pageTitle: pageTitleReducers,
    orgChartPage: orgChartDataReducer,
    userProfilePage: userProfilePageReducer,
    features: featuresReducer,
    users: usersReducer,
    googleApi: googleApiReducer,
    departments: departmentReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(sagaMiddleware),
});

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;
