import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { SortDirection } from '@shared/enums/sortDirection';
import { OrgChartFilter } from '../enums/Filters';
import { State } from './types';

const initialState: State = {
  orgChartData: { data: null, state: DataState.Pending },
  searchData: { data: [], state: DataState.Fulfilled },
  sortInfo: { field: 'name', sortDirection: SortDirection.Asc },
  filterInfo: [],
  isDepartmentChart: true,
  rootId: '',
};

export const slice = createSlice({
  name: 'orgChartPage',
  initialState,
  reducers: {
    resetSearchResult(state) {
      state.searchData = { data: [], state: DataState.Fulfilled };
    },
    setSearchDataState(state, { payload }) {
      state.searchData.state = payload;
    },
    setSearchData(state, { payload }) {
      state.searchData.data = payload;
      state.searchData.state = DataState.Fulfilled;
    },
    setOrgChartDataLoading(state) {
      state.orgChartData = { data: null, state: DataState.Pending };
    },
    setOrgChartData(state, action) {
      state.orgChartData = action.payload;
    },
    setSortInfo(state, action) {
      state.sortInfo = action.payload;
    },
    removeFilterInfo(state, action) {
      state.filterInfo = state.filterInfo.filter((item) => item.field !== action.payload);
    },
    resetFilters(state) {
      state.filterInfo = [];
    },
    setFilterInfo(state, action) {
      if (!state.filterInfo.some((item) => action.payload.field === item.field)) {
        state.filterInfo.push(action.payload);
      } else if (
        (action.payload.field === OrgChartFilter.Roles && action.payload.value.length > 0) ||
        action.payload.field === OrgChartFilter.HappinessReaction
      ) {
        state.filterInfo = state.filterInfo.map((item) =>
          item.field === action.payload.field ? action.payload : item
        );
      } else {
        state.filterInfo = state.filterInfo.filter((item) => item.field !== action.payload.field);
      }
    },
    setIsDepartmentChart(state, action) {
      state.isDepartmentChart = action.payload;
    },
    setRootId(state, action) {
      state.rootId = action.payload;
    },
  },
});

export default slice.reducer;
