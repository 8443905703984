import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    columnGap: 4,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
    },
  },
  marksAndCommentContainer: {
    display: 'flex',
    columnGap: 4,

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
    },
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
    },
  },
  iconsContainer: {
    marginTop: 7,
    display: 'flex',
    columnGap: 4,
  },
  questionTitle: {
    paddingTop: 7,
    width: 110,
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      fontSize: 18,
    },
  },
  markBtn: {
    padding: '8px 16px 9px',
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.WHITE,
    background: COLORS.ACTION.HIGHLIGHT,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    borderRadius: 8,
    cursor: 'pointer',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      marginTop: 8,
    },
  },
  notActiveBtn: {
    color: COLORS.ACTION.PRIMARY,
    textShadow: 'none',
  },
  icon: {
    display: 'flex',
    alignItems: 'baseline',
    cursor: 'pointer',
    height: 'min-content',
  },
  commentIcon: {
    color: COLORS.ACTION.PRIMARY,
  },
  infoIcon: {
    color: COLORS.TEXT.DISABLED,
    '&:hover, &:focus': {
      color: COLORS.ACTION.PRIMARY,
    },
  },
  activeInfoIcon: {
    color: COLORS.ACTION.PRIMARY,
  },
  popup: {
    '&-content': {
      fontSize: '14px',
      borderRadius: '8px !important',
      maxWidth: 360,
      padding: '32px 40px 40px 24px',
    },
  },
  hidden: {
    display: 'none',
  },
  questionTitleError: {
    color: COLORS.STATUS.CRITICAL,
  },
  comment: {
    marginTop: 10,
    minHeight: 100,
    width: '100%',
    height: 100,
    border: 'none',
    boxShadow: `inset 0 0 0 1px ${COLORS.TEXT.SECONDARY}`,
    borderRadius: 4,
    padding: 15,

    '&:focus': {
      boxShadow: `inset 0 0 0 2px ${COLORS.ACTION.PRIMARY}`,
      outline: 'none',
    },
  },
});
