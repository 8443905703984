import httpClient from '@shared/api/httpClient';
import { Question } from '@modules/HappinessModule/interfaces/question';
import { apiUrl } from '@configs/environment';
import { FetchQuestions } from './types';

export const fetchQuestions = (): Promise<Question[]> =>
  httpClient.get<FetchQuestions>(`${apiUrl}/question-history`).then(({ data }) =>
    data.data.map(({ id, date, question_text, question_group_name, unresolved_count }) => ({
      id: id,
      date: date,
      questionText: question_text,
      questionGroupName: question_group_name,
      unresolvedCount: unresolved_count,
    }))
  );
