import React, { FC, useEffect } from 'react';
import { RockyButton } from '@shared/components/RockyButton';
import EditIcon from '@mui/icons-material/Edit';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { IconButton, Tooltip } from '@mui/material';
import { useStyles } from './LinkManageButton.styles';
import { LinkManageModal } from '../LinkManageModal/LinkManageModal';
import { useDispatch } from 'react-redux';
import {
  createExternalLink,
  removeExternalLink,
  updateExternalLink,
} from '@modules/App/pages/ExternalLinksPage/redux/actions';
import { useModalState } from '@shared/hooks/useModalState';
import { ExternalLinkBody } from '@modules/App/pages/ExternalLinksPage/interfaces/ExternalLinkBody';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';
import { ReactComponent as SpreadsheetIcon } from '@assets/icons/spreadsheet.svg';
import { ExternalLinksTypesToNamesMap } from '../../redux/constants';

interface Props {
  link: ExternalLinkBody | null;
  type: ExternalLinksTypes;
  manageLink?: (link: string) => void;
  id?: string;
  canEdit?: boolean;
  removeLink?: () => void;
  isOutside?: boolean;
}
export const LinkManageButton: FC<Props> = ({ link, id, canEdit, type, manageLink, removeLink, isOutside }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const emptyViewLink = !canEdit && !link;
  const { openModal, closeModal, isModalOpen } = useModalState();

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, []);

  const createOrUpdateLink = (value: string) => {
    if (manageLink) {
      manageLink(value);
      closeModal();
      return;
    }
    if (!id || !type) return;
    const payload = { link: value, userId: id, type };
    const action = link?.id ? updateExternalLink({ ...payload, id: link?.id }) : createExternalLink(payload);
    dispatch(action);
  };

  const deleteLink = () => {
    if (removeLink) {
      removeLink();
      closeModal();
      return;
    }
    if (!link) {
      return;
    }
    dispatch(removeExternalLink(link));
  };
  return !emptyViewLink ? (
    <div className={styles.linkManageBtnContainer}>
      <>
        {isOutside && link?.link && (
          <RockyButton
            to={link?.link}
            download
            target={'_blank'}
            inverted
            className={styles.linkManageBtn}
            disabled={!canEdit}
          >
            <SpreadsheetIcon /> {ExternalLinksTypesToNamesMap[type].buttonTitle}
          </RockyButton>
        )}
        {link?.link ? (
          <>
            {canEdit && (
              <Tooltip title="Edit link" placement="top">
                <IconButton aria-label="Edit" color="primary" size="small" onClick={openModal}>
                  <EditIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          <RockyButton action className={styles.linkManageBtn} onClick={openModal} disabled={!canEdit}>
            <PostAddIcon />{' '}
            {isOutside && type === ExternalLinksTypes.evaluation ? 'Add link to previous evaluations' : 'Add link'}
          </RockyButton>
        )}
      </>
      <LinkManageModal
        link={link?.link || null}
        pendingLinkUpdate={false}
        isModalOpen={isModalOpen}
        type={type}
        manageLink={createOrUpdateLink}
        removeLink={deleteLink}
        closeModal={closeModal}
      />
    </div>
  ) : null;
};
