import React, { FC, memo, useEffect } from 'react';
import { setCustomToken } from '@shared/helpers/auth';
import { Navigate, useLocation } from 'react-router-dom';

const TokenHandlerComponent: FC = () => {
  const { search } = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(search);
    const token = params.get('token') || '';
    setCustomToken(token);
  }, [search]);

  return <Navigate replace to="/" />;
};

export const TokenHandler = memo(TokenHandlerComponent);
