import React, { FC } from 'react';
import { useStyles } from './PeopleHappinessPageNoDataInfoView.styles';
import { InfoView } from '@shared/components/InfoView/InfoView';
import { Link, Stack, Typography } from '@mui/material';
import { HappinessPagesInfoViewTexts } from '@modules/HappinessModule/enums/HappinessPagesInfoViewTexts';
import { isEqual, omit } from 'lodash';
import SunIcon from '@assets/img/Sun.png';
import BalloonIcon from '@assets/img/Balloon.png';
import { PeopleHappinessFilterFormData } from '../../interfaces/PeopleHappinessFilterData';
import { PeopleHappinessFilterNumberFieldsNames } from '../../enums/PeopleHappinessFilterFieldsNames';
import { REACTION_MAX_VALUE, REACTION_MIN_VALUE } from '../../constants';
import { setFiltersToAplly } from '../../redux';
import { useDispatch } from 'react-redux';

interface Props {
  defaultValues: PeopleHappinessFilterFormData;
  initialValues: PeopleHappinessFilterFormData;
  filtersToAplly: PeopleHappinessFilterFormData | null;
}

export const PeopleHappinessPageNoDataInfoView: FC<Props> = ({ defaultValues, initialValues, filtersToAplly }) => {
  const styles = useStyles();
  const isNoUnhappyResponses = isEqual(defaultValues, initialValues);
  const restDefaultFilterValues = omit(defaultValues, [
    PeopleHappinessFilterNumberFieldsNames.ScoreFrom,
    PeopleHappinessFilterNumberFieldsNames.ScoreTo,
  ]);

  const {
    reactionGte: reactionGteApplied,
    reactionLte: reactionLteApplied,
    ...restAppliedFilterValues
  } = filtersToAplly ?? {};

  const isNoResponsesAtAll =
    reactionGteApplied === REACTION_MIN_VALUE &&
    reactionLteApplied === REACTION_MAX_VALUE &&
    isEqual(restDefaultFilterValues, restAppliedFilterValues);

  const dispatch = useDispatch();

  return (
    <InfoView inComponent className={styles.emptyView}>
      <Stack alignItems="center" rowGap="10px">
        <img src={isNoUnhappyResponses ? SunIcon : BalloonIcon} />
        <Typography
          variant="h2"
          sx={{
            color: 'text.secondary',
          }}
          align="center"
        >
          {isNoResponsesAtAll
            ? HappinessPagesInfoViewTexts.NoResponsesAtAll
            : isNoUnhappyResponses
            ? HappinessPagesInfoViewTexts.NoUnhappyResponses
            : HappinessPagesInfoViewTexts.NoResponsesWithSuchFilters}
        </Typography>
        {!isNoResponsesAtAll && (
          <Link
            component="button"
            onClick={() =>
              dispatch(
                setFiltersToAplly(
                  isNoUnhappyResponses ? { ...defaultValues, reactionLte: REACTION_MAX_VALUE } : defaultValues
                )
              )
            }
          >
            {isNoUnhappyResponses ? 'View all responses' : 'Reset filters'}
          </Link>
        )}
      </Stack>
    </InfoView>
  );
};
