import { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link as ReactRouterLink } from 'react-router-dom';
import classNames from 'classnames';
import { Alert, Link, Typography } from '@mui/material';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import RecordVoiceOverOutlinedIcon from '@mui/icons-material/RecordVoiceOverOutlined';

import { useStyles } from './EmployeeDetailsCard.styles';
import { roleIconMap } from '../../constants/roleIconMap';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { OrgChartData, UserOrgChartProjectData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import { initialValuesStructure } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { UserInfoRow } from '@modules/App/pages/UserProfilePage/componenes/UserInfoRow';
import { UserEmailComponent } from '@modules/App/pages/UserProfilePage/componenes/UserEmailComponent/UserEmailComponent';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { paths } from '@shared/enums/paths';
import { UserStatus } from '@shared/enums/UserStatus';
import { ProjectRole } from '@shared/enums/projectRole';
import { RoleMap } from '@shared/consts/RoleMap';
import { getUserSeniority } from '@shared/helpers/getUserSeniority';
import { getUserProjectName } from '@shared/helpers/getUserProjectName';
import { getBirthDay, getDateOfJoining } from '@shared/helpers/date';
import { FeedbackFormData } from '@modules/App/interfaces/FeedbackFormData';

const MAX_PROJECTS_DISPLAYED = 5;
interface Props {
  selectedNode: Required<OrgChartData>;
  setIsDetailViewOpen: (isDetailViewOpen: boolean) => void;
}

interface RoleByProject {
  projectFullName: string;
  account: string | null;
  project: string;
  roles: ProjectRole[];
}

export const EmployeeDetailsCard: FC<Props> = ({ selectedNode, setIsDetailViewOpen }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [isProjectsCollapsed, setIsProjectsCollapsed] = useState(true);
  const userSeniority = getUserSeniority(selectedNode);

  const openFeedback = () => {
    const initialValues: FeedbackFormData = {
      ...initialValuesStructure,
      teamMemberWithInccorectReportTo: {
        name: selectedNode.name,
        id: selectedNode.originalId,
        imageUrl: selectedNode.imageUrl,
        position: selectedNode.position ?? undefined,
        primaryReportsTo: {
          name: '',
          id: selectedNode.reportsToId ?? undefined,
        },
      },
    };

    dispatch(
      setIsFeedbackOpen({
        isOpen: true,
        initialValues,
      })
    );
  };

  useEffect(() => {
    setIsProjectsCollapsed(true);
  }, [selectedNode]);

  const rolesGroupedByProject = selectedNode.projects
    .reduce((grp, p) => {
      if (!grp.some((pp) => pp.project === p.project)) {
        grp.push({
          project: p.project,
          projectFullName: getUserProjectName({
            project: p.project,
            account: p.account,
          }),
          account: p.account,
          roles: [],
        });
      }
      const proj = grp.find((pp) => pp.project === p.project);
      proj?.roles.push(p.role);
      return grp;
    }, [] as RoleByProject[])
    .reduce((acc, p) => {
      if (!acc.find((el: RoleByProject) => el.project == p.project)) {
        acc.push(p);
      }
      return acc;
    }, [] as RoleByProject[]);

  const uniqueProjects = selectedNode.projects.reduce((acc, project) => {
    if (!acc.find((el: UserOrgChartProjectData) => el.projectId == project.projectId)) {
      acc.push(project);
    }
    return acc;
  }, [] as UserOrgChartProjectData[]);

  const projectsToDisplay = isProjectsCollapsed
    ? rolesGroupedByProject.splice(0, MAX_PROJECTS_DISPLAYED)
    : rolesGroupedByProject;
  const isShowMoreProjectsButtonShown = isProjectsCollapsed && uniqueProjects.length > MAX_PROJECTS_DISPLAYED;
  const numberOfHiddenProjects = uniqueProjects.length - MAX_PROJECTS_DISPLAYED;

  const employeeDetailInfoMap = [
    {
      title: 'Joined AgileEngine',
      icon: <StarBorderRoundedIcon />,
      value: getDateOfJoining(selectedNode.dateOfJoin ?? null),
    },
    {
      title: 'Main technology',
      icon: <AutoFixHighOutlinedIcon />,
      value: selectedNode.mainTech,
    },
    {
      title: 'Projects',
      icon: <GroupsOutlinedIcon />,
      value: (
        <div>
          {selectedNode.projects.every((p) => p.project !== selectedNode.project) && (
            <div className={styles.projectName}>
              {getUserProjectName({
                project: selectedNode.project ?? '',
                account: selectedNode.account,
              })}
            </div>
          )}
          {projectsToDisplay.map((proj, i) => (
            <div key={i}>
              <div className={styles.projectName}>{proj.projectFullName}</div>
              {proj.roles.map((r, k) => (
                <div className={styles.roleBlock} key={k}>
                  <div className={styles.roleIconHolder}>{roleIconMap[r]}</div>
                  {RoleMap[r]}
                </div>
              ))}
            </div>
          ))}
          {isShowMoreProjectsButtonShown && (
            <button className={styles.showMoreButton} onClick={() => setIsProjectsCollapsed(false)}>
              Show {numberOfHiddenProjects} more
            </button>
          )}
        </div>
      ),
    },

    {
      title: 'Reporting to',
      icon: <RecordVoiceOverOutlinedIcon />,
      value:
        selectedNode.reportingToName && selectedNode.reportsToId ? (
          <Link href={`/users/${selectedNode.reportsToId}/`} className={styles.reportingToLink}>
            {selectedNode.reportingToName}
          </Link>
        ) : (
          'No direct supervisor'
        ),
    },
    {
      title: 'Current city',
      icon: <PlaceOutlinedIcon />,
      value: selectedNode.city,
    },
    {
      title: 'Birthday',
      icon: <CakeOutlinedIcon />,
      value: selectedNode.dateOfBirth ? getBirthDay(selectedNode.dateOfBirth) : 'Not specified',
    },
    {
      title: 'Email',
      icon: <EmailOutlinedIcon />,
      value: <UserEmailComponent email={selectedNode.email} />,
    },
  ];

  return (
    <div className={styles.detailView}>
      <div className={styles.closeBtn} onClick={() => setIsDetailViewOpen(false)}>
        <CloseOutlinedIcon />
      </div>
      <div className={styles.mainInfo}>
        <Link
          component={ReactRouterLink}
          to={`${paths.users}/${selectedNode.originalId ?? selectedNode.id}`}
          className={styles.linkBlock}
        >
          <RockyAvatar
            large
            fullName={selectedNode.name}
            imageUrl={selectedNode.imageUrl}
            className={styles.avatar}
            country={selectedNode.country ?? undefined}
            city={selectedNode.city ?? undefined}
          />
          <div className={styles.mainText}>{selectedNode.name}</div>
        </Link>
        <div className={classNames(styles.secondaryText, styles.textAlignCenter)}>{`${userSeniority} ${
          selectedNode?.position ?? ''
        }`}</div>
        <div className={classNames(styles.secondaryText, styles.textAlignCenter)}>{`${selectedNode.country ?? ''}${
          selectedNode.city ? `, ${selectedNode.city}` : ''
        }`}</div>
      </div>
      {selectedNode.status === UserStatus.Sabbatical ? (
        <Alert
          className={styles.sabbaticalAlert}
          severity="warning"
          variant="standard"
          icon={<WorkHistoryIcon color="disabled" />}
        >
          <Typography component="p" variant="body1">
            On sabbatical
          </Typography>
        </Alert>
      ) : (
        <div className={styles.divider}></div>
      )}
      <div className={styles.detailInfo}>
        {employeeDetailInfoMap.map(
          ({ title, icon, value }, i) => value && <UserInfoRow title={title} icon={icon} value={value} key={i} />
        )}
        <span className={styles.reportIssue} onClick={openFeedback}>
          Report incorrect "Reporting to"
        </span>
      </div>
    </div>
  );
};
