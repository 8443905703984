import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { State } from './types';

const initialState: State = {
  isGoogleApiLoaded: false,
};

export const slice = createSlice({
  name: 'googleApi',
  initialState,
  reducers: {
    setIsGoogleApiLoaded: (state, action: PayloadAction<boolean>) => {
      state.isGoogleApiLoaded = action.payload;
    },
  },
});

export const { setIsGoogleApiLoaded } = slice.actions;

export default slice.reducer;
