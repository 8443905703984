import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { FeedbackForm } from './components/FeedbackForm';
import { useDispatch, useSelector } from 'react-redux';
import { sendFeedback } from '@modules/App/redux/feedback/actions';
import { useLocation, useParams } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import {
  feedbackFormInitialValuesSelector,
  isFeedbackOpenSelector,
  selectedManagerSelector,
  selectedUserSelector,
} from '@modules/App/redux/feedback/selectors';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { initialValuesOther } from './constants/feedbackInitialValues';
import { FeedbackReasonValues } from './enums/FeedbackReasonsValues';
import { useAuth } from '@shared/hooks/useAuth';
import { FeedbackData } from '@modules/App/interfaces/feedback';

const FeedbackComponent: FC = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(isFeedbackOpenSelector);
  const initialValues = useSelector(feedbackFormInitialValuesSelector);
  const { currentUser } = useAuth();
  const { pathname } = useLocation();
  const { id, flagId } = useParams();
  const [isUrlHide, setIsUrlHide] = useState(true);
  const selectedUser = useSelector(selectedUserSelector);
  const selectedManager = useSelector(selectedManagerSelector);

  const closeFeedbackForm = () => {
    dispatch(setIsFeedbackOpen({ isOpen: false, initialValues: initialValuesOther }));
  };

  useEffect(() => {
    setIsUrlHide(pathname.toLocaleLowerCase().includes(paths.flags) && (Boolean(id) || Boolean(flagId)));
  }, [pathname]);

  const handleFormSubmit = useCallback(
    (data: Omit<FeedbackData, 'teamMember'>) => {
      const { feedbackReason, feedbackText, attachmentFiles } = data;
      const generatedText =
        feedbackReason === FeedbackReasonValues.IncorectStructure
          ? `Requested by: ${currentUser?.name}; \nTeammate: ${selectedUser?.name}(${selectedUser?.email}); \nChange RT to: ${selectedManager?.name}; \nComment: ${feedbackText}.`
          : feedbackReason === FeedbackReasonValues.IncorectProject
          ? `Requested by: ${currentUser?.name}; \nTeammate: ${selectedUser?.name}(${selectedUser?.email}); \nComment: ${feedbackText}.`
          : feedbackReason === FeedbackReasonValues.ContactPRTeam
          ? `${currentUser?.name}: ${feedbackText}`
          : isUrlHide
          ? `Comment:${feedbackText};.`
          : `Comment:${feedbackText}; \nURL:${pathname}.`;
      dispatch(
        sendFeedback({
          feedbackText: generatedText,
          feedbackReason: feedbackReason as FeedbackReasonValues,
          teamMember: selectedUser?.name ?? '',
          attachmentFiles,
        })
      );
    },
    [dispatch, selectedUser, selectedManager, pathname, isUrlHide]
  );

  return (
    <>
      <FeedbackForm
        onSubmit={handleFormSubmit}
        isOpen={isOpen}
        closeFeedbackForm={closeFeedbackForm}
        initialValues={initialValues}
      />
    </>
  );
};

export const Feedback = memo(FeedbackComponent);
