import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  recipientItem: {
    padding: '13px 16px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  deleteBtn: {
    border: 'none',
    padding: 0,
    backgroundColor: 'transparent',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fill: COLORS.TEXT.SECONDARY,

    '& svg': {
      width: 18,
    },
  },
  expandBtn: {
    width: 40,
    height: 40,
    borderRadius: 12,
    backgroundColor: COLORS.HIGHLIGHTS.ACTIONABLE,
  },
});
