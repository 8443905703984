import React, { FC } from 'react';
import { useStyles } from './FeedbackStatus.styles';
import Approved from '@mui/icons-material/MarkChatRead';
import Pending from '@mui/icons-material/AccessTimeOutlined';
import Rejected from '@mui/icons-material/SpeakerNotesOff';
import Review from '@mui/icons-material/Preview';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { Popup } from 'reactjs-popup';

interface Props {
  status: EvalFeedbackStatus;
}

export const FeedbackStatus: FC<Props> = ({ status }) => {
  const styles = useStyles();
  return (
    <Popup
      className={styles.popup}
      position="top center"
      on={['hover', 'focus', 'click']}
      trigger={
        <div className={styles.iconContainer}>
          {[EvalFeedbackStatus.Approved, EvalFeedbackStatus.Submitted].includes(status) ? (
            <span className={styles.approveBtn}>
              <Approved />
            </span>
          ) : status === EvalFeedbackStatus.Rejected ? (
            <span className={styles.rejectBtn}>
              <Rejected />
            </span>
          ) : status === EvalFeedbackStatus.Review ? (
            <span className={styles.reviewBtn}>
              <Review />
            </span>
          ) : (
            <span className={styles.pendingBtn}>
              <Pending />
            </span>
          )}
        </div>
      }
    >
      <span className={styles.capitalize}>{status}</span>
    </Popup>
  );
};
