import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setAchievedGoalsDataForEvalLoading } from '../redux';
import { getAchievedGoalsDataForEval } from '../redux/actions';
import { achievedGoalsDataForEvalSelector } from '../redux/selectors';

export const useAchievedGoalsForEval = (): AsyncData<UserGoalPageData[]> => {
  const achievedGoalsDataForEval = useSelector(achievedGoalsDataForEvalSelector);
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      return;
    }
    dispatch(getAchievedGoalsDataForEval(id));
    return () => {
      dispatch(setAchievedGoalsDataForEvalLoading());
    };
  }, [id]);

  return achievedGoalsDataForEval;
};
