import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    padding: '16px 24px',
    borderRadius: '0 0 8px 8px',
    backgroundColor: COLORS.BRAND.HIGHLIGHT,
    display: 'flex',
    flexDirection: 'column',
  },
  cardHeader: {
    fontSize: 18,
    fontWeight: '500',
    margin: '0 0 16px',
  },
  categoryText: {
    fontSize: '14px',
    margin: '0 0 4px',
    color: COLORS.TEXT.SECONDARY,
    fontWeight: '400',
  },
  questionText: {
    fontSize: '14px',
    fontWeight: '500',
    margin: '0 0 8px',
  },
  dateText: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    margin: 0,
  },
  dateHolder: {
    marginTop: 'auto',
  },
});
