import React, { FC, useCallback } from 'react';
import { ClientSurveyStatus } from '@modules/HappinessModule/enums/ClientSurveyStatus';
import { useStyles } from './ClientSurveyStatus.styles';
import { ClientSurveyStatusLabel } from '@modules/HappinessModule/pages/ClientSurveysPage/constants/constants';
import { RockyButton } from '@shared/components/RockyButton';
import { ClientSurveyConfirmModal } from '@modules/HappinessModule/pages/ClientSurveysPage/copmonents/ClientSurveyConfirmModal';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import AccessTime from '@mui/icons-material/AccessTime';
import { useDispatch, useSelector } from 'react-redux';
import { setClientSurveyConfirmModalOpen } from '../../redux';
import { confirmClientSurvey } from '@modules/HappinessModule/pages/ClientSurveysPage/redux/actions';
import { selectIsClientSurveyConfirmModalOpen, selectIsClientSurveyConfirmPending } from '../../redux/selectors';
import PenIcon from '@mui/icons-material/Edit';
import { isClientSurveyEditable } from '../../helpers/helpers';

interface Props {
  status: ClientSurveyStatus | null;
  date: string;
  id: string;
  disabled?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onEditClick: (...args: any[]) => void;
}

export const ClientSurveyStatusComponent: FC<Props> = ({ status, date, id, disabled, onEditClick }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const clientSurveyConfirmModalOpen = useSelector(selectIsClientSurveyConfirmModalOpen);
  const clientSurveyConfirmPending = useSelector(selectIsClientSurveyConfirmPending);
  const isSurveyEditable = !disabled && isClientSurveyEditable(status);

  const openModal = useCallback((): void => {
    dispatch(setClientSurveyConfirmModalOpen({ open: true, pending: false }));
  }, [dispatch]);

  const closeModal = useCallback((): void => {
    dispatch(setClientSurveyConfirmModalOpen({ open: false, pending: false }));
  }, [dispatch]);

  const confirmScheduleSurvey = useCallback((): void => {
    dispatch(
      confirmClientSurvey({
        id,
      })
    );
  }, [dispatch, id]);

  if (!status) return null;

  const formattedDate = format(parseISO(date), 'dd MMM');
  const isScheduled = status === ClientSurveyStatus.Scheduled;

  return (
    <>
      {status === ClientSurveyStatus.PendingConfirmation ? (
        <div className={styles.statusContainer}>
          <div className={styles.statusScheduledDate}>{formattedDate}</div>
          {!disabled && (
            <div>
              <RockyButton className={styles.button} action small onClick={() => openModal()}>
                Confirm
              </RockyButton>
              <ClientSurveyConfirmModal
                pendingConfirmation={clientSurveyConfirmPending}
                confirmScheduleSurvey={confirmScheduleSurvey}
                closeModal={closeModal}
                isModalOpen={clientSurveyConfirmModalOpen}
                date={formattedDate}
              />
            </div>
          )}
        </div>
      ) : (
        <div className={styles.statusContainer}>
          <div className={isScheduled ? styles.statusScheduledDate : ''}>{ClientSurveyStatusLabel[status]}</div>
          {isScheduled && (
            <div>
              <AccessTime className={styles.scheduledIcon} />
            </div>
          )}
          {isSurveyEditable && (
            <span onClick={onEditClick}>
              <PenIcon className={styles.penIcon} />
            </span>
          )}
        </div>
      )}
    </>
  );
};
