import { FC, useEffect } from 'react';
import { useStyles } from './ExternalLinksPage.styles';
import { useExternalLinks } from '@modules/App/pages/ExternalLinksPage/hooks/useExternalLinks';
import { useParams } from 'react-router';
import { DataState } from '@shared/enums/DataState';
import { Table } from '@shared/components/Table';
import Skeleton from 'react-loading-skeleton';
import { Typography } from '@mui/material';
import { useUserProfileData } from '@modules/App/pages/UserProfilePage/hooks/useUserProfileData';
import { UserStatus } from '@shared/enums/UserStatus';
import { ExternalLinksTypes } from './enums/ExternalLinksTypes';
import { LinkItem } from './components/LinkItem/LinkItem';
import { DataErrorView } from '@shared/components/DataErrorView';
import { PipLinkItem } from './components/PipLinkItem/PipLinkItem';
import { isSomeLinkExist } from './helpers/isSomeLinkExist';
import emptyLinksIcon from '@assets/img/emptyLinksIcon.png';
import { useSelector } from 'react-redux';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { useTitle } from '@shared/hooks/useTitle';
import { userSelector } from '../UserPage/redux/selectors';
import { pageTitles } from '@shared/enums/pageTitles';

export const ExternalLinksPage: FC = () => {
  const styles = useStyles();
  const { data: profileData } = useUserProfileData();
  const user = useSelector(userSelector);
  const isOnSabbatical = profileData?.status === UserStatus.Sabbatical;
  const { id } = useParams();
  const { externalLinks, pipLinks } = useExternalLinks(id!);
  const { data, state } = useSelector(userPermissionsSelector);
  const isOneToOneEditable = !!data && data?.externalLinks?.oneToOne?.isUpdatable;
  const isPdpNotesEditable = !!data && data?.externalLinks?.pdp?.isUpdatable;
  const isEvaluationsNotesEditable = !!data && data?.externalLinks?.evaluation?.isUpdatable;
  const isEngineeringManagerNotesEditable = !!data && data?.externalLinks?.engineeringManagerNotes?.isUpdatable;
  const isCvEditable = !!data && data?.externalLinks?.cv?.isUpdatable;
  const isDataLoading = state === DataState.Pending || externalLinks.state === DataState.Pending;
  const isOneToOneAvailable = !!data && data?.externalLinks?.oneToOne?.isReadable && !isOnSabbatical;
  const isPdpAvailable = !!data && data?.externalLinks?.pdp?.isReadable && !isOnSabbatical;
  const isEvaluationAvailable = !!data && data?.externalLinks?.evaluation?.isReadable && !isOnSabbatical;
  const isEngineeringManagerNotesAvailable =
    !!data && data?.externalLinks?.engineeringManagerNotes?.isReadable && !isOnSabbatical;
  const isCvAvailable = !!data && data?.externalLinks?.cv?.isReadable && !isOnSabbatical;
  const isRejected = state === DataState.Rejected || externalLinks.state === DataState.Rejected;
  const isFlagsDetailsVisible = !!data && data.flagPermissions.flag.isReadable;
  const isDataExist = isSomeLinkExist({
    externalLinks: externalLinks.data,
    pipLinks: pipLinks.data,
    isOneToOneAvailable,
    isPdpAvailable,
    isEvaluationAvailable,
    isEngineeringManagerNotesAvailable,
    isCvAvailable,
  });
  const canModifyLinks =
    data?.externalLinks?.oneToOne?.isUpdatable ||
    data?.externalLinks?.pdp?.isUpdatable ||
    data?.externalLinks?.evaluation?.isUpdatable ||
    data?.externalLinks?.engineeringManagerNotes?.isUpdatable ||
    data?.externalLinks?.cv?.isUpdatable;

  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(`${user?.name} ${pageTitles.links}`);
  }, [user]);

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i}>
        <td>
          <div className={styles.loaderContainer}>
            <Skeleton className={styles.loader} />
          </div>
        </td>
      </tr>
    ));

  const tableContent = externalLinks.data && (
    <>
      <LinkItem
        link={externalLinks.data.oneToOneNotesLink}
        canEdit={isOneToOneEditable}
        type={ExternalLinksTypes.oneToOne}
        id={id!}
        isVisible={isOneToOneAvailable}
      />
      <LinkItem
        link={externalLinks.data.pdpLink}
        canEdit={isPdpNotesEditable}
        type={ExternalLinksTypes.pdp}
        id={id!}
        isVisible={isPdpAvailable}
      />
      <LinkItem
        link={externalLinks.data.evaluationLink}
        canEdit={isEvaluationsNotesEditable}
        type={ExternalLinksTypes.evaluation}
        id={id!}
        isVisible={isEvaluationAvailable}
      />
      <LinkItem
        link={externalLinks.data.engineeringManagerNotesLink}
        canEdit={isEngineeringManagerNotesEditable}
        type={ExternalLinksTypes.engineeringManagerNotes}
        id={id!}
        isVisible={isEngineeringManagerNotesAvailable}
      />
      <LinkItem
        link={externalLinks.data.cvLink}
        canEdit={isCvEditable}
        type={ExternalLinksTypes.cv}
        id={id!}
        isVisible={isCvAvailable}
      />
      {pipLinks.data &&
        pipLinks.data?.map((pipLink) => (
          <PipLinkItem link={pipLink} isVisible isFlagDetailsVisible={isFlagsDetailsVisible} key={pipLink.id} />
        ))}
    </>
  );

  return (
    <>
      <Typography className={styles.pageTitle} variant="h2">
        Linked documents
      </Typography>
      {isRejected ? (
        <DataErrorView error={externalLinks.error || pipLinks.error} />
      ) : !isDataLoading && !isDataExist && !canModifyLinks ? (
        <div className={styles.emptyScreenContainer}>
          <img src={emptyLinksIcon} className={styles.emptyScreemImg} />
          <Typography variant="h2" className={styles.emptyScreenTitle}>
            Links to some Drive files related <br />
            to you will appear here
          </Typography>
        </div>
      ) : (
        <Table
          isDataLoading={isDataLoading}
          tableHeaders={[]}
          skeleton={tableSkeleton}
          customStyles={{ tableHeaderRow: styles.tableHeaderRow }}
        >
          {tableContent}
        </Table>
      )}
    </>
  );
};
