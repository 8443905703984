import { put, all, call, takeLatest } from 'redux-saga/effects';
import { getUsersFlagsData, setUsersFlagsData } from './actions';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { setUsersFlagsDataLoading } from './reducers';
import { FlagListData } from '@modules/HappinessModule/interfaces/FlagListData';
import { fetchUserFlagsData } from '@modules/HappinessModule/api/flagData';

function* getUsersFlagsDataAsync({ payload }: ReturnType<typeof getUsersFlagsData>) {
  try {
    const setLoadingAction = setUsersFlagsDataLoading();
    yield put(setLoadingAction);
    const response: FlagListData[] = yield call(fetchUserFlagsData, payload);
    const action = setUsersFlagsData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUsersFlagsData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* watchGetMyHappinessData() {
  yield takeLatest(getUsersFlagsData.type, getUsersFlagsDataAsync);
}

export function* usersFlagsPageSaga(): Generator {
  yield all([watchGetMyHappinessData()]);
}
