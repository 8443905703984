import { rescheduleEvaluation } from '@modules/EvaluationModule/helpers/rescheduleEvaluation';
import { rescheduleEval } from '@modules/EvaluationModule/redux/evaluationsManegment/actions';
import { updateGoogleCalendarEvalAction } from '@modules/EvaluationModule/redux/googleCalendarEval/actions';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { SortDirection } from '@shared/enums/sortDirection';
import { SLICE_NAME } from '../constants/SliceName';
import { MainPageState } from './types';

const initialState: MainPageState = {
  myTeamEvalData: {
    data: null,
    state: DataState.Pending,
  },
  sortInfo: { field: 'evaluationDate', sortDirection: SortDirection.Asc },
  startingEvalId: '',
  failingEopId: '',
  extendEvalState: DataState.Idle,
  rescheduleEvalState: DataState.Idle,
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSortInfo(state, action) {
      state.sortInfo = action.payload;
    },
    setMyTeamEvalDataLoading(state) {
      state.myTeamEvalData = { data: null, state: DataState.Pending };
    },
    setMyTeamEvalData(state, action) {
      state.myTeamEvalData = action.payload;
    },
    setStartingEvalId(state, action) {
      state.startingEvalId = action.payload;
    },
    setFailingEopId(state, action) {
      state.failingEopId = action.payload;
    },
    setExtendEvalState(state, action: PayloadAction<DataState>) {
      state.extendEvalState = action.payload;
    },
    setRescheduleEvalState(state, action: PayloadAction<DataState>) {
      state.rescheduleEvalState = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(rescheduleEval, (state, { payload }) => {
      if (!state.myTeamEvalData.data) {
        return;
      }

      state.myTeamEvalData.data = state.myTeamEvalData.data.map((item) =>
        item.evaluationId === payload.evaluationId
          ? rescheduleEvaluation({ rescheduledEval: item, rescheduleData: payload })
          : item
      );
    });

    builder.addCase(updateGoogleCalendarEvalAction, (state, { payload }) => {
      if (!state.myTeamEvalData.data) {
        return;
      }
      const evalToUpdate = state.myTeamEvalData.data.find((d) => d.evaluationId === payload.evaluationId);
      if (!evalToUpdate) {
        return;
      }
      evalToUpdate.googleCalendarEvent = payload.googleCalendarEvent;
    });
  },
});

export const { setSortInfo, setMyTeamEvalData, setExtendEvalState, setRescheduleEvalState } = slice.actions;

export default slice.reducer;
