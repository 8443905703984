import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  aeOpportunitiesLink: {
    paddingTop: 8,
    color: COLORS.ACTION.PRIMARY,
    display: 'inline-block',
  },
  noInitiatives: {
    color: COLORS.TEXT.DISABLED,
  },
  initiativeRow: {
    margin: '4px 0',
  },
});
