import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  root: {
    marginTop: 10,
    paddingLeft: 8,

    [BREAKPOINTS.MOBILE]: {
      overflow: 'hidden',
      overflowX: 'auto',
      maxWidth: 'calc(100vw - 8px)',
      paddingLeft: 0,

      MsOverflowStyle: 'none',
      scrollbarWidth: 'none',

      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
  },
});
