export enum UserStatus {
  Active = 'Active',
  Disabled = 'Disabled',
  Deactivated = 'Deactivated',
  Reactive = 'Reactive',
  Sabbatical = 'Sabbatical',
  Voluntary = 'Voluntary',
  Involuntary = 'Involuntary',
  Army = 'Army',
}
