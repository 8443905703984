import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { State } from './types';

const initialState: State = {
  userActionsPermissions: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions(state, { payload }) {
      state.userActionsPermissions = payload;
    },
    setPermissionsLoading(state) {
      state.userActionsPermissions = { data: null, state: DataState.Pending };
    },
  },
});

export const { setPermissions, setPermissionsLoading } = slice.actions;

export default slice.reducer;
