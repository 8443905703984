import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
    overflow: 'hidden',
  },
  finalText: {
    flex: '1 0 auto',
    color: COLORS.BRAND.PRIMARY,
  },
  logo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 0,
    fontSize: 10,
    color: 'rgba(0, 0, 0, 0.5)',
    lineHeight: 1,
    letterSpacing: '0.01em',
    fontWeight: 400,
    paddingLeft: 48,
    paddingTop: 32,
    width: 'fit-content',

    [BREAKPOINTS.MOBILE]: {
      padding: '16px 16px 12px 20px',
    },
  },
  body: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 32,
  },
  card: {
    maxWidth: 570,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 8px 16px rgba(143, 149, 178, 0.15);',
    borderRadius: 16,
    overflow: 'hidden',

    [BREAKPOINTS.MOBILE]: {
      margin: 4,
      borderRadius: 8,
    },
  },
  cardHeader: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: '32px 64px',
    gap: 32,

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
      gap: 16,
    },
  },
  cardTitle: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '130%',

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
    },
  },
  dateContainer: {
    fontWeight: 400,
    fontSize: 12,
    color: COLORS.TEXT.SECONDARY,
    display: 'flex',
    alignItems: 'center',
  },
  date: {
    whiteSpace: 'nowrap',
  },
  cardBody: {
    padding: '4rem',

    [BREAKPOINTS.MOBILE]: {
      padding: '1.75rem',
    },
  },
  commentContainer: {
    marginTop: '2.5rem',
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  commentTitle: {
    color: COLORS.TEXT.PRIMARY,
  },
  textarea: {
    width: '100%',
    height: 80,
    resize: 'none',
    border: `1px solid ${COLORS.INPUTBORDER}`,
    borderRadius: 4,
    padding: '12px 16px',
    color: COLORS.TEXT.PRIMARY,

    '&:disabled': {
      border: 'none',
      backgroundColor: COLORS.HIGHLIGHTS.PROMINENT,
    },
  },
  addComentBtn: {
    marginTop: '1rem',
    marginRight: 10,
  },
  titleSkeleton: {
    height: 30,
    width: 200,
  },
  dateSkeleton: {
    height: 20,
    width: 100,
  },
  commentSkeleton: {
    marginTop: 24,
    height: 80,
    width: '100%',
  },
  notesTitle: {
    fontWeight: 500,
  },
  revokeBlock: {
    marginTop: 24,
    padding: '15px 16px',
    backgroundColor: COLORS.HIGHLIGHTS.NOTE,
    whiteSpace: 'pre-wrap',
    borderRadius: 8,
  },
  revokeLink: {
    display: 'flex',
    color: COLORS.SCALEEVAL.CANT,
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: 12,
  },
  bottomGradient: {
    width: '100%',
    height: '0.25rem',
    background: `linear-gradient(270deg,
      ${COLORS.SCALE10.VOTE1} 10%,
      ${COLORS.SCALE10.VOTE2} 20%,
      ${COLORS.SCALE10.VOTE3} 30%,
      ${COLORS.SCALE10.VOTE4} 40%,
      ${COLORS.SCALE10.VOTE5} 50%,
      ${COLORS.SCALE10.VOTE6} 60%,
      ${COLORS.SCALE10.VOTE7} 70%,
      ${COLORS.SCALE10.VOTE8} 80%,
      ${COLORS.SCALE10.VOTE9} 90%,
      ${COLORS.SCALE10.VOTE10} 100%
    )`,
  },
});
