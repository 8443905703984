import { FC, ReactNode, memo } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import WorkOffRoundedIcon from '@mui/icons-material/WorkOffRounded';
import { useStyles } from './UserReporterBlock.styles';
import { paths } from '@shared/enums/paths';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { UserStatus } from '@shared/enums/UserStatus';

enum StatusDisplayPosition {
  NearName = 'near name',
  RightSide = 'right side',
}
type StatusDisplayPositionType = `${StatusDisplayPosition}`;

interface Props {
  user?: UserInfo;
  isDetailInfo?: boolean;
  isLink?: boolean;
  isLoading?: boolean;
  isShowEmail?: boolean;
  isShowProject?: boolean;
  statusDisplayPosition?: StatusDisplayPositionType;
}

const UserReporterBlockComponent: FC<Props> = ({
  user,
  isDetailInfo,
  isLink,
  isLoading,
  isShowEmail,
  isShowProject,
  statusDisplayPosition,
}) => {
  const styles = useStyles();

  const wrapInLink = (block: ReactNode) =>
    isLink ? (
      <Link
        onClick={(e) => e.stopPropagation()}
        to={`${paths.users}/${user?.id}/${paths.profile}`}
        className={styles.link}
      >
        {block}
      </Link>
    ) : (
      block
    );

  const isUserOnSabbatical = user?.status === UserStatus.Sabbatical;
  const isStatusDisplayPositionNearName = statusDisplayPosition === StatusDisplayPosition.NearName;
  const stylesForUserStatus = isStatusDisplayPositionNearName
    ? { width: '0.8em', height: '0.8em', marginLeft: '0.5rem', verticalAlign: 'text-bottom' }
    : null;
  const userStatus = (isUserOnSabbatical || user?.isActive === false) && (
    <Tooltip title={isUserOnSabbatical ? 'On sabbatical' : 'Deactivated'}>
      {isUserOnSabbatical ? (
        <WorkHistoryIcon color="disabled" sx={stylesForUserStatus} />
      ) : (
        <WorkOffRoundedIcon color="disabled" sx={stylesForUserStatus} />
      )}
    </Tooltip>
  );

  const userBlock = isLoading ? (
    <>
      <RockyAvatar
        isLoading={isLoading}
        imageUrl={''}
        country={''}
        city={''}
        fullName={''}
        medium={isDetailInfo}
        small={!isDetailInfo}
      />
      <div className={styles.personInfoBlock}>
        <RockySkeleton className={styles.mediumTextSkeleton} element={<p></p>} isLoading={isLoading} />
        {isDetailInfo && <RockySkeleton className={styles.smallTextSkeleton} element={<p></p>} isLoading={isLoading} />}
      </div>
    </>
  ) : (
    <>
      {wrapInLink(
        <RockyAvatar
          imageUrl={user?.imageUrl}
          country={user?.country || ''}
          city={user?.city || ''}
          fullName={user?.name || ''}
          medium={isDetailInfo}
          small={!isDetailInfo}
        />
      )}

      <div className={styles.personInfoContainer}>
        <div className={styles.personInfoBlock}>
          <p className={styles.personName}>
            {wrapInLink(user?.name)}

            {isStatusDisplayPositionNearName && userStatus}
          </p>
          {isDetailInfo && <p className={classNames(styles.subTitle, styles.position)}>{user?.position}</p>}
          {isShowEmail && user?.email && <p className={classNames(styles.subTitle, styles.email)}>{user.email}</p>}
          {isShowProject && user?.project && (
            <p className={classNames(styles.subTitle, styles.project)}>{user.project}</p>
          )}
        </div>

        {statusDisplayPosition === StatusDisplayPosition.RightSide && userStatus}
      </div>
    </>
  );

  return <div className={styles.userBlock}>{userBlock}</div>;
};

export const UserReporterBlock = memo(UserReporterBlockComponent);
