import { NewHomeEmailSentStatus } from '@modules/HappinessModule/enums/NewHomeEmailSentStatus';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagWatcherData } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { RecipientData } from '@modules/HappinessModule/interfaces/RecipientData';

export const getInitValues = ({
  ccRecipients,
  flagWatchers,
  duplicatedFlagFields,
}: {
  ccRecipients?: RecipientData[];
  flagWatchers?: FlagWatcherData[];
  duplicatedFlagFields?: FlagFields | null;
}): FlagFields => {
  let initialValues: FlagFields = {
    description: duplicatedFlagFields?.description ?? null,
    whatWasDone: duplicatedFlagFields?.whatWasDone ?? '',
    severity: duplicatedFlagFields?.severity ?? null,
    primaryTriggerType: duplicatedFlagFields?.primaryTriggerType ?? null,
    primaryTrigger: duplicatedFlagFields?.primaryTrigger ?? null,
    primarySubReason: duplicatedFlagFields?.primarySubReason ?? null,
    secondaryTriggerType: duplicatedFlagFields?.secondaryTriggerType ?? null,
    secondaryTrigger: duplicatedFlagFields?.secondaryTrigger ?? null,
    secondarySubReason: duplicatedFlagFields?.secondarySubReason ?? null,
    suggestedActionItems: duplicatedFlagFields?.suggestedActionItems ?? '',
    isNewHomeNeeded: duplicatedFlagFields?.isNewHomeNeeded ?? false,
    departments: [],
    isAnotherOfferInvolved: false,
    status: null,
    resolutionNotes: null,
    isEscalatedByClient: false,
    isConfidential: true,
    isReturnedFromSabbatical: false,
    isPassive: false,
    lastDayOnTheProject: null,
    newHomeStartDate: new Date().toISOString(),
    newHomeAvailableFrom: null,
    newHomeAvailableFromType: null,
    workload: null,
    pros: null,
    cons: null,
    softSkills: null,
    emailRecipients: [],
    flagWatchers: flagWatchers ?? [],
    isRecipientsDisabled: false,
    isPrTeamInvolved: false,
    assignedPR: null,
    assignedTom: null,
    newHomeResolution: null,
    newHomeEndDate: null,
    starRate: null,
    pipLink: null,
    technicalPros: null,
    technicalCons: null,
    technicalSoftSkills: null,
    nhEmailSentStatus: NewHomeEmailSentStatus.NotRequested,
    numOptionsShown: null,
    numSubmissions: null,
    numTICIFailed: null,
    newProjectAssignment: null,
    isOfferAccepted: false,
    companyName: null,
    hiringType: null,
    amountType: null,
    benefits: null,
    compensation: null,
    hrNotes: null,
    currencyType: null,
  };
  if (ccRecipients) {
    initialValues = {
      ...initialValues,
      emailRecipients: ccRecipients,
    };
  }
  return initialValues;
};
