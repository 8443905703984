import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useStyles } from './TabSwitcher.styles';
import { matchPath, useLocation } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Tab } from '@shared/interfaces/Tab';

interface Props {
  tabs: Tab[] | null;
  button?: boolean;
}
const TabSwitcher: React.FC<Props> = ({ tabs, button }) => {
  const styles = useStyles();
  const location = useLocation();

  const getActiveStyle = useCallback(
    (linkTo: string) => {
      const isActive = matchPath({ path: linkTo }, location.pathname);
      const activeStyle = isActive && (button ? styles.activeButton : styles.activeLink);
      return activeStyle;
    },
    [location]
  );

  return (
    <>
      {tabs && (
        <div className={styles.tabSwitcher}>
          {tabs
            .filter(({ linkTo, linkName }) => linkName && linkTo)
            .map(({ linkTo, linkName }, i) => {
              return (
                <Link
                  className={classNames(styles.link, getActiveStyle(linkTo), {
                    [styles.button]: button,
                  })}
                  to={`../${linkTo}`}
                  key={i}
                >
                  {linkName}
                </Link>
              );
            })}
        </div>
      )}
    </>
  );
};

export default TabSwitcher;
