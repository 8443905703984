import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { SLICE_NAME } from '../constants/sliceName';

import { State } from './types';

const initialState: State = {
  userGoalsData: { data: null, state: DataState.Pending },
  achievedGoalsDataForEval: { data: null, state: DataState.Pending },
  userGoalEditData: { state: DataState.Idle, error: null },
  userGoalCreationData: { state: DataState.Idle, error: null },
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setUserGoalsDataLoading(state) {
      state.userGoalsData = { data: null, state: DataState.Pending };
    },
    setUserGoalsData(state, { payload }) {
      state.userGoalsData = payload;
    },
    setAchievedGoalsDataForEvalLoading(state) {
      state.achievedGoalsDataForEval = { data: null, state: DataState.Pending };
    },
    setAchievedGoalsDataForEval(state, { payload }) {
      state.achievedGoalsDataForEval = payload;
    },
    setEditUserGoalEditState(state, action: PayloadAction<DataState>) {
      state.userGoalEditData.state = action.payload;
    },
    setUserGoalEditNetworkError(state, { payload }) {
      state.userGoalEditData.error = payload;
    },
    setEditUserGoalCreationState(state, action: PayloadAction<DataState>) {
      state.userGoalCreationData.state = action.payload;
    },
    setUserGoalCreationNetworkError(state, { payload }) {
      state.userGoalCreationData.error = payload;
    },
  },
});

export const {
  setUserGoalsDataLoading,
  setUserGoalsData,
  setEditUserGoalEditState,
  setUserGoalEditNetworkError,
  setEditUserGoalCreationState,
  setUserGoalCreationNetworkError,
  setAchievedGoalsDataForEvalLoading,
  setAchievedGoalsDataForEval,
} = slice.actions;

export default slice.reducer;
