import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawlerRoot: {
    width: 465,
    padding: 32,
    paddingTop: 5,
  },
  radioGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'nowrap',
  },
  textField: {
    '& .MuiInputBase-root': {
      paddingTop: '10px',
      paddingBottom: '12px',
    },
  },
  switcherContainer: {
    marginTop: 20,
  },
  chapterTitle: {
    marginTop: 15,
  },
  radioBtnTitle: {
    marginBottom: 5,
  },
  helperText: {
    marginBottom: 0,
    marginTop: 0,
    padding: 0,
    textAlign: 'justify',
  },
});
