import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    animation: '$fadeIn 0.3s',
  },
  messageBody: {
    position: 'relative',
    boxSizing: 'border-box',
    borderRadius: 8,
    maxWidth: 484,

    [BREAKPOINTS.MOBILE]: {
      maxWidth: 358,
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translate(60px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0)',
    },
  },
  question: {
    margin: '0 0 10px',
    fontWeight: 500,
    fontSize: 24,
    lineHeight: '130%',
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 14,
    },
  },
  description: {
    margin: '0 0 10px',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '130%',
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 14,
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
    marginBottom: 10,
    position: 'relative',
  },
  checkIcon: {
    color: COLORS.ACTION.PRIMARY,
    fontSize: 18,
  },
  button: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textDecoration: 'none',
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1,
    minWidth: 40,
    width: 40,
    height: 40,
    border: 'none',
    borderRadius: '30%',
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: '8.5px 0',
    background: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    transition: 'all .5s',

    '&:hover': {
      transform: 'scale(1.05)',
      color: COLORS.WHITE,
    },

    '&:disabled': {
      background: COLORS.TEXT.DISABLEDBG,
      color: COLORS.TEXT.DISABLED,
      textShadow: 'none',

      '&:hover': {
        transform: 'scale(1)',
      },
    },

    [BREAKPOINTS.MOBILE]: {
      fontWeight: 500,
      fontSize: 14,
      width: 29,
      minWidth: 29,
      height: 29,
      padding: '4px 0',
    },
  },
  activeButton: {
    color: COLORS.WHITE,
  },
  selectedBtnDisabled: {
    pointerEvents: 'none',
  },
  selectedBtnScore: {
    color: COLORS.ACTION.PRIMARY,
  },
  skeletonBtn: {
    width: 40,
    height: 40,
    borderRadius: 12,
  },
});
