import React, { FC, memo, useCallback } from 'react';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import noop from 'lodash/noop';

import { SortInfo } from '@shared/interfaces/sortInfo';
import { useStyles } from './TableHeader.styles';
import { SortDirection } from '@shared/enums/sortDirection';
import classNames from 'classnames';
import { TableHeaderData } from '@shared/interfaces/TableHeaderData';

interface Props extends TableHeaderData {
  sortInfo?: SortInfo;
  onSortClick?: (sortInfo: SortInfo) => void;
}

const TableHeaderComponent: FC<Props> = ({ title, sortInfo, fieldName, onSortClick, childFunc, titleStyles }) => {
  const styles = useStyles();
  const isArrowUpHighlighted = sortInfo && sortInfo.field === fieldName && sortInfo.sortDirection === SortDirection.Asc;
  const isArrowDownHighlighted =
    sortInfo && sortInfo.field === fieldName && sortInfo.sortDirection === SortDirection.Desc;

  const handleSortClick =
    fieldName &&
    sortInfo &&
    onSortClick &&
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useCallback(() => {
      onSortClick({
        field: fieldName,
        sortDirection:
          sortInfo.field === fieldName
            ? sortInfo.sortDirection === SortDirection.Desc
              ? SortDirection.Asc
              : SortDirection.Desc
            : SortDirection.Asc,
      });
    }, [fieldName, sortInfo, onSortClick]);

  return (
    <div className={styles.root}>
      <p
        className={classNames(styles.title, titleStyles, { [styles.sortableTitle]: !!handleSortClick })}
        onClick={handleSortClick || noop}
      >
        {title}
        {childFunc?.()}
      </p>
      {fieldName && title && (
        <div className={styles.sortHolder} onClick={handleSortClick || noop}>
          <KeyboardArrowUpRoundedIcon color={isArrowUpHighlighted ? 'primary' : 'disabled'} />
          <KeyboardArrowDownRoundedIcon color={isArrowDownHighlighted ? 'primary' : 'disabled'} />
        </div>
      )}
    </div>
  );
};

export const TableHeader = memo(TableHeaderComponent);
