import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { NewProjectAssignmentData } from '@modules/HappinessModule/interfaces/NewProjectAssignmentData';

type FlagFormFieldsTypeMap = {
  [key in keyof Required<Omit<FlagFields, 'resolvedAt'>>]: {
    name: key;
    label: string;
    helperText?: string;
  };
};

export const NEW_PROJECT_ASSIGNMENT_FIELD_NAME: keyof FlagFields = 'newProjectAssignment';

type FlagFormFieldsNewProjectAssignmentTypeMap = {
  [key in keyof Required<NewProjectAssignmentData>]: {
    name: `${typeof NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.${key}`;
    label: string;
  };
};

export const FlagFormFieldsNewProjectAssignment: FlagFormFieldsNewProjectAssignmentTypeMap = {
  project: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.project`,
    label: 'New project',
  },
  startDate: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.startDate`,
    label: 'Start date on a new project',
  },
  workload: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.workload`,
    label: 'Workload',
  },
  isSowSigned: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.isSowSigned`,
    label: 'SOW signed',
  },
  suggestedRaise: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.suggestedRaise`,
    label: 'Suggested raise on the next eval',
  },
  currentSeniority: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.currentSeniority`,
    label: 'Current seniority',
  },
  nearestEval: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.nearestEval`,
    label: 'Next evaluation',
  },
  evaluationNotes: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.evaluationNotes`,
    label: 'Notes about evaluation',
  },

  timeOffNotes: {
    name: `${NEW_PROJECT_ASSIGNMENT_FIELD_NAME}.timeOffNotes`,
    label: 'Planned upcoming time off',
  },
};

export const FlagFormFields: FlagFormFieldsTypeMap = {
  severity: {
    name: 'severity',
    label: 'Severity *',
  },
  pipLink: {
    name: 'pipLink',
    label: 'Performance improvement plan',
  },
  isEscalatedByClient: {
    name: 'isEscalatedByClient',
    label: 'The issue has been brought by client',
  },
  description: {
    name: 'description',
    label: 'Flag description',
  },
  whatWasDone: {
    name: 'whatWasDone',
    label: 'What was done?',
  },
  primaryTriggerType: {
    name: 'primaryTriggerType',
    label: 'Primary trigger',
  },
  primaryTrigger: {
    name: 'primaryTrigger',
    label: 'Primary reason',
  },
  primarySubReason: {
    name: 'primarySubReason',
    label: 'Primary sub-reason',
  },
  secondaryTriggerType: {
    name: 'secondaryTriggerType',
    label: 'Secondary trigger',
  },
  secondaryTrigger: {
    name: 'secondaryTrigger',
    label: 'Secondary reason',
  },
  secondarySubReason: {
    name: 'secondarySubReason',
    label: 'Secondary sub-reason',
  },
  departments: {
    name: 'departments',
    label: 'Affected projects',
  },
  suggestedActionItems: {
    name: 'suggestedActionItems',
    label: 'Suggested actions',
  },
  isNewHomeNeeded: {
    name: 'isNewHomeNeeded',
    label: 'NewHome needed',
  },
  isConfidential: {
    name: 'isConfidential',
    label: 'Confidential',
  },
  isReturnedFromSabbatical: {
    name: 'isReturnedFromSabbatical',
    label: 'Returned from sabbatical',
  },
  lastDayOnTheProject: {
    name: 'lastDayOnTheProject',
    label: 'Last day on the project',
  },
  newHomeStartDate: {
    name: 'newHomeStartDate',
    label: 'NewHome starting from',
  },
  newHomeAvailableFrom: {
    name: 'newHomeAvailableFrom',
    label: 'Date',
  },
  newHomeAvailableFromType: {
    name: 'newHomeAvailableFromType',
    label: 'Person is available from',
  },
  workload: {
    name: 'workload',
    label: 'NH workload',
  },
  pros: {
    name: 'pros',
    label: 'Pros (Hard and soft skills)',
    helperText: 'Please indicate the strongest person’s aspects, in what they are good at, at least 3 sentences',
  },
  cons: {
    name: 'cons',
    label: 'Cons (Hard and soft skills)',
    helperText:
      'Highlight person’s weaknesses and areas for improvement. Mention issues or accidents that influenced the project, client, or team',
  },
  softSkills: {
    name: 'softSkills',
    label: 'Accomplishments on a project',
    helperText: 'Add something that had a large impact on a project, and is worth noticing',
  },
  isAnotherOfferInvolved: {
    name: 'isAnotherOfferInvolved',
    label: 'Another offer involved',
  },
  resolutionNotes: {
    name: 'resolutionNotes',
    label: 'Resolution notes',
  },
  hiringType: {
    name: 'hiringType',
    label: 'Employment',
  },
  amountType: {
    name: 'amountType',
    label: 'Compensation amount',
  },
  status: {
    name: 'status',
    label: 'Status',
  },
  hrNotes: {
    name: 'hrNotes',
    label: 'HR notes',
  },
  isOfferAccepted: {
    name: 'isOfferAccepted',
    label: 'Offer accepted',
  },
  companyName: {
    name: 'companyName',
    label: 'Company',
  },
  compensation: {
    name: 'compensation',
    label: 'Compensation',
  },
  currencyType: {
    name: 'currencyType',
    label: 'Currency',
  },
  benefits: {
    name: 'benefits',
    label: 'Offer benefits description',
  },
  emailRecipients: {
    name: 'emailRecipients',
    label: 'Recipients',
  },
  isRecipientsDisabled: {
    name: 'isRecipientsDisabled',
    label: 'Start email thread',
  },
  isPassive: {
    name: 'isPassive',
    label: 'Passive',
  },
  isPrTeamInvolved: {
    name: 'isPrTeamInvolved',
    label: 'HR team involved',
  },
  assignedPR: {
    name: 'assignedPR',
    label: 'Assigned HR team member',
  },
  assignedTom: {
    name: 'assignedTom',
    label: 'Assigned Talent Operation manager',
  },
  newHomeEndDate: {
    name: 'newHomeEndDate',
    label: 'NewHome end date',
  },
  newHomeResolution: {
    name: 'newHomeResolution',
    label: 'Resolution',
  },
  starRate: {
    name: 'starRate',
    label: 'Star rate',
  },
  technicalSoftSkills: {
    name: 'technicalSoftSkills',
    label: 'Accomplishments on a project',
  },
  technicalPros: {
    name: 'technicalPros',
    label: 'Pros (Hard and soft skills)',
  },
  technicalCons: {
    name: 'technicalCons',
    label: 'Cons (Hard and soft skills)',
  },
  isSetNewResolutionDate: {
    name: 'isSetNewResolutionDate',
    label: 'Set new resolution date',
  },
  flagWatchers: {
    name: 'flagWatchers',
    label: 'Flag watchers',
  },
  nhEmailSentStatus: {
    name: 'nhEmailSentStatus',
    label: 'NewHome email sent status',
  },
  numOptionsShown: {
    name: 'numOptionsShown',
    label: '# of options shown',
  },
  numSubmissions: {
    name: 'numSubmissions',
    label: '# of submissions',
  },
  numTICIFailed: {
    name: 'numTICIFailed',
    label: '# TI/CI failed',
  },
  newProjectAssignment: {
    name: 'newProjectAssignment',
    label: 'New project found',
  },
};
