import React, { memo, useState } from 'react';
import { useStyles } from './FailEopModal.styles';
import { RockyButton } from '@shared/components/RockyButton';
import { Dialog, DialogTitle, IconButton, TextField } from '@mui/material';
import ufo from '@assets/img/ufo.png';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
  evaluationId: string;
  closeEopModal: () => void;
  isEopModalOpen: boolean;
  handleSubmit: (evaluationId: string, failReason: string) => void;
}

const FailEopModalComponent: React.FC<Props> = ({ evaluationId, closeEopModal, isEopModalOpen, handleSubmit }) => {
  const styles = useStyles();
  const [notes, setNotes] = useState('');
  const [validationError, setValidationError] = useState<null | string>(null);

  const submitIfValid = () => {
    if (notes === '') {
      setValidationError('Failing reason must be filled');
      return;
    }
    handleSubmit(evaluationId, notes);
    closeEopModal();
  };

  return (
    <Dialog fullWidth open={isEopModalOpen}>
      <IconButton aria-label="close" onClick={closeEopModal} className={styles.goalDialogClose}>
        <CloseIcon />
      </IconButton>
      <div className={styles.dialogRoot}>
        <img src={ufo} className={styles.ufoImg} />
        <DialogTitle className={styles.dialogTitle}>Probation period failed</DialogTitle>
        <div className={styles.dialogDescription}>
          You can now skip starting evaluation and requesting further feedback.
        </div>
        <TextField
          label="Failing reason"
          variant="outlined"
          className={styles.textarea}
          multiline
          fullWidth
          minRows={3}
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          error={Boolean(validationError)}
          helperText={validationError && <span className={styles.error}>{validationError}</span>}
        />
        <RockyButton action type="submit" className={styles.submitButton} onClick={submitIfValid}>
          Save
        </RockyButton>
        <RockyButton className={styles.button} onClick={closeEopModal}>
          Cancel
        </RockyButton>
      </div>
    </Dialog>
  );
};

export const FailEopModal = memo(FailEopModalComponent);
