import React, { FC, memo, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { useStyles } from './MyPeopleEvalReportTable.styles';
import { paths } from '@shared/enums/paths';
import { Table } from '@shared/components/Table';
import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { ActionButtons } from '../../shared/ActionButtons';
import { useSelectEvals } from '../../hooks/useSelectEvals';
import { EvalStatus } from '../../shared/EvalStatus/EvalStatus';
import classnames from 'classnames';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import format from 'date-fns/format';
import { UsersDropdown } from '../MyTeamEvalReportTable/componets/UsersDropdown';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import InfiniteLoader from 'react-window-infinite-loader';
import { VirtualTable } from '@modules/HappinessModule/components/VirtualTable';
import { MY_PEOPLE_EVALS_FETCH_LIMIT } from '@modules/EvaluationModule/pages/MyPeopleEvalPage/constants/pagination';
import { VirtualTableContext } from '@modules/HappinessModule/components/VirtualTable/context/VirtualTableContext';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { setSelectedEvalsAction } from '@modules/EvaluationModule/pages/MyPeopleEvalPage/redux/actions';

interface Props {
  data: MyTeamEvalData[] | null;
  isDataLoading: boolean;
  loadMoreItems?: (startIndex: number, stopIndex: number) => void | Promise<void>;
}

const MyPeopleEvalReportTableComponent: FC<Props> = ({ data, isDataLoading, loadMoreItems }) => {
  const styles = useStyles();
  const ITEM_HEIGHT = 80;
  const location = useLocation();
  const { handleSendTheFormButtonClick, handleFailEopButtonClick, handleClickOnRow, startingEvalId, failingEopId } =
    useSelectEvals(setSelectedEvalsAction);
  const tableHeaders = [
    { title: 'Member' },
    { title: 'Month' },
    { title: 'Status' },
    { title: 'Manager' },
    { title: '' },
    { title: '' },
    { title: '' },
  ];

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i} className={styles.tableDataRow}>
        <td>
          <div className={styles.memberBlock}>
            <div className={styles.personBlock}>
              <Skeleton className={styles.personAvatar} />
              <div className={styles.personInfoBlock}>
                <Skeleton className={styles.personNameSkeleton} />
                <Skeleton className={styles.positionSkeleton} />
              </div>
            </div>
          </div>
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
      </tr>
    ));

  const isItemLoaded = (index: number) => data !== null && !!data[index];

  const table = ({ children, style }: { children: React.ReactNode; style?: React.CSSProperties }) => (
    <Table
      isDataLoading={isDataLoading}
      tableHeaders={tableHeaders}
      skeleton={tableSkeleton}
      customStyles={{ tableHeaderRow: styles.tableHeaderRow, tableDataRow: styles.tableDataRow }}
      style={style}
    >
      {children}
    </Table>
  );

  const Inner = React.forwardRef<HTMLDivElement, React.HTMLProps<HTMLDivElement>>(function Inner(
    { children, ...rest },
    ref
  ) {
    const { top } = useContext(VirtualTableContext);
    return (
      <div {...rest} ref={ref}>
        {table({
          children: children,
          style: { top, position: 'absolute', width: '100%' },
        })}
      </div>
    );
  });

  interface RowProps {
    evaluation: MyTeamEvalData;
  }

  const evalRow = ({ evaluation }: RowProps) => {
    const {
      name,
      imageUrl,
      email,
      position,
      project,
      userId,
      status,
      evaluationId,
      evaluationDate,
      country,
      city,
      peerReviews,
      clientReviews,
      primaryReportsTo,
      typeOfInteraction,
      permissions: { isUpdatable },
    } = evaluation;
    const isProcessingEval = startingEvalId !== evaluationId && failingEopId !== evaluationId;
    const isOngoing = status === EvaluationStatus.Ongoing;

    return (
      <tr
        key={email}
        onClick={() => isOngoing && handleClickOnRow(status, userId, evaluationId)}
        className={classnames(styles.tableDataRow, {
          [styles.starting]: startingEvalId === evaluationId,
          [styles.noClickable]: !isOngoing,
          [styles.canceling]: failingEopId === evaluationId,
        })}
      >
        <td className={styles.cellMember} onClick={(e) => e.stopPropagation()}>
          <div className={styles.memberBlock}>
            <Link
              className={styles.personBlock}
              to={`${paths.users}/${userId}/${paths.evaluations}`}
              state={{ path: location.pathname, name: 'team evaluations' }}
            >
              <UserReporterBlock
                isShowProject
                isDetailInfo
                user={{ id: userId, email, name, position, project, imageUrl, country, city }}
              />
            </Link>
          </div>
        </td>
        <td className={styles.cellMonth}>{format(new Date(evaluationDate), 'LLLL')}</td>
        <td className={styles.cellStatus}>
          {evaluationDate && status && (
            <EvalStatus status={status} evaluationDate={evaluationDate} typeOfInteraction={typeOfInteraction} />
          )}
        </td>
        {isUpdatable ? (
          <td className={styles.cellReviewers}>
            <UsersDropdown
              evaluationInfo={evaluation}
              name="Clients"
              linkPath={`${paths.users}/${userId}/${paths.evaluations}/${evaluationId}/${paths.settings}`}
              userReviews={clientReviews}
            />
          </td>
        ) : (
          <td colSpan={3} className={styles.cellManager}>
            <UserReporterBlock user={{ ...primaryReportsTo, position: '' }} />
          </td>
        )}
        {isUpdatable && (
          <td colSpan={isUpdatable ? 2 : 1} className={styles.cellReviewers}>
            <UsersDropdown
              evaluationInfo={evaluation}
              name="Peers"
              linkPath={`${paths.users}/${userId}/${paths.evaluations}/${evaluationId}/${paths.settings}`}
              userReviews={peerReviews}
            />
          </td>
        )}
        {status && (
          <td className={styles.cellActions}>
            {isProcessingEval ? (
              isUpdatable && (
                <ActionButtons
                  evaluation={evaluation}
                  handleSendTheFormButtonClick={handleSendTheFormButtonClick}
                  handleFailEopButtonClick={handleFailEopButtonClick}
                />
              )
            ) : (
              <div className={styles.backdrop} onClick={(e) => e.stopPropagation()}></div>
            )}
          </td>
        )}
      </tr>
    );
  };

  return (
    <div className={styles.rootVirtualized}>
      {loadMoreItems && (
        <ReactVirtualizedAutoSizer>
          {({ height, width }) => (
            <InfiniteLoader
              isItemLoaded={isItemLoaded}
              itemCount={100000}
              loadMoreItems={loadMoreItems}
              minimumBatchSize={MY_PEOPLE_EVALS_FETCH_LIMIT}
              threshold={20}
            >
              {({ onItemsRendered, ref }) => (
                <VirtualTable
                  overscanCount={10}
                  innerElementType={Inner}
                  height={height}
                  width={width}
                  itemCount={data ? data.length : 0}
                  itemSize={ITEM_HEIGHT}
                  children={({ index }) => data && evalRow({ evaluation: data[index] })}
                  onItemsRendered={onItemsRendered}
                  ref={ref}
                />
              )}
            </InfiniteLoader>
          )}
        </ReactVirtualizedAutoSizer>
      )}
    </div>
  );
};

export const MyPeopleEvalReportTable = memo(MyPeopleEvalReportTableComponent);
