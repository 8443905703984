import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  mainBlock: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    flexWrap: 'wrap',
    padding: 24,

    [BREAKPOINTS.MOBILE]: {
      gridTemplateColumns: '1fr',
      gap: 48,
      padding: 16,
    },
  },

  questionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },

  questionContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 8,
  },

  questionTitle: {
    width: 109,
    fontSize: '14px',
    lineHeight: '120%',
    color: COLORS.TEXT.PRIMARY,
    margin: '0 8px 0 0',

    [BREAKPOINTS.MOBILE]: {
      width: '50%',
    },
  },

  markLabel: {
    padding: '10px 15px',
    height: 40,
    borderRadius: 8,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.WHITE,
    textTransform: 'capitalize',

    [BREAKPOINTS.MOBILE]: {
      width: '50%',
      fontSize: '13px',
    },
  },

  notesTitle: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
    margin: '0 0 8px 0',
  },

  notesText: {
    margin: '0 0 26px 0',
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',
  },

  summary: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 20px',
    background: COLORS.HIGHLIGHTS.NOTE,
    borderRadius: 8,
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
    margin: '0 0 0 27px',

    '& span': {
      fontWeight: '500',
    },
  },

  reviewBtns: {
    padding: '24px 24px 0 24px',
    borderTop: `1px solid ${COLORS.DIVIDER}`,
    display: 'flex',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      padding: 16,
    },
  },

  approveBtn: {
    fontWeight: '500',
    fontSize: '14px',
    marginRight: 20,
    display: 'flex',
    alignItems: 'center',
    alignContent: 'center',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      marginBottom: 8,
    },
  },

  rejectBtn: {
    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },

  notesLabel: {
    width: '100%',
    margin: '0 0 8px 0',
    fontWeight: '500',
    fontSize: '14px',
  },

  notesField: {
    minHeight: 100,
    maxHeight: '40vh',
    width: '100%',
    resize: 'none',
    borderRadius: 4,
    padding: '10px 15px',
  },

  notesAttention: {
    margin: '8px 0 50px 0',
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },

  btnContainer: {
    textAlign: 'right',
  },

  cancelBtn: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.ACTION.PRIMARY,
    marginRight: 10,
  },

  notifyBtn: {
    fontWeight: '500',
    fontSize: '14px',
  },
  spinner: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    borderTop: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderBottom: `3px solid ${COLORS.ACTION.PRIMARY}`,
    borderLeft: `3px solid ${COLORS.ACTION.PRIMARY}`,
    animation: '$spin 0.5s infinite linear',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  commentTrigger: {
    transform: 'scaleX(-1)',
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      width: 20,
      height: 20,
      fill: COLORS.TEXT.PRIMARY,
    },
  },
  anonymusBlock: {
    margin: '0px 24px',
    marginTop: 11,
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: '12px 16px',
    gap: 4,
    background: COLORS.HIGHLIGHTS.PROMINENT,
    borderRadius: 8,
    maxWidth: 800,

    [BREAKPOINTS.MOBILE]: {
      margin: '0px 16px',
      marginTop: 11,
    },
  },
});
