import { firebaseAuth } from '../../auth/firebase';
import addSeconds from 'date-fns/addSeconds';
import { Enviroment } from '@shared/enums/EnvironmentNames';
import { requestCustomToken } from '@modules/App/api/requestCustomToken';

const AUTH_TOKEN_KEY = 'authToken';
const ACCESS_TOKEN_KEY = 'accessToken';
const AUTH_REFRESH_TOKEN_KEY = 'authRefresh';
const AUTH_EXPIRE_KEY = 'authExpire';
const AUTH_EMAIL_KEY = 'authEmail';

export const getCustomToken = (): string | null => localStorage.getItem(AUTH_TOKEN_KEY);

export const setCustomToken = (token: string): void => localStorage.setItem(AUTH_TOKEN_KEY, token);

export const clearCustomToken = (): void => localStorage.removeItem(AUTH_TOKEN_KEY);

export const setAccessToken = (token: string): void => localStorage.setItem(ACCESS_TOKEN_KEY, token);

export const getAccessToken = (): string | null => localStorage.getItem(ACCESS_TOKEN_KEY);

export const getCurrentToken = async (refreshToken?: boolean): Promise<string | null> => {
  const isProdEnv = process.env.REACT_APP_ENV === Enviroment.Production;
  const customAuth = getCustomAuth();

  let customToken;
  if ((refreshToken || isCustomTokenExpired(new Date(customAuth.expiresIn || ''))) && customAuth.email) {
    const updatedCustomAuth = await refreshCustomAuth();
    customToken = updatedCustomAuth.idToken;
  } else {
    customToken = getCustomToken();
  }

  const fbToken = (await firebaseAuth?.currentUser?.getIdToken(refreshToken)) ?? null;

  return isProdEnv ? fbToken : customToken ?? fbToken;
};

export const getCustomEmail = (): string | null => localStorage.getItem(AUTH_EMAIL_KEY);

export const setCustomAuth = (accessToken: string, refreshToken: string, expiresIn: number): void => {
  const now = new Date();
  const expireDate = addSeconds(now, +expiresIn);
  localStorage.setItem(AUTH_TOKEN_KEY, accessToken);
  localStorage.setItem(AUTH_REFRESH_TOKEN_KEY, refreshToken);
  localStorage.setItem(AUTH_EXPIRE_KEY, expireDate.toISOString());
};

export const getCustomAuth = (): { email: string | null; token: string | null; expiresIn: number | null } => {
  const expires = localStorage.getItem(AUTH_EXPIRE_KEY);
  return {
    email: localStorage.getItem(AUTH_EMAIL_KEY),
    token: localStorage.getItem(AUTH_TOKEN_KEY),
    expiresIn: expires ? +expires : null,
  };
};

export const refreshCustomAuth = async (): Promise<{
  idToken: string;
  refreshToken: string;
  expiresIn: string;
}> => {
  const email = localStorage.getItem(AUTH_EMAIL_KEY);
  const refreshedCustomAuth = await requestCustomToken({ email: email ?? '' });
  setCustomAuth(refreshedCustomAuth.idToken, refreshedCustomAuth.refreshToken, +refreshedCustomAuth.expiresIn);

  return refreshedCustomAuth;
};

export const isCustomTokenExpired = (tokenExpireDate: Date): boolean => {
  return tokenExpireDate <= new Date();
};
