import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  title: {
    marginTop: 0,
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '120%',
    color: COLORS.TEXT.PRIMARY,
    marginBottom: 24,
  },
  line: {
    marginBottom: 32,
    fontWeight: '500',
    fontSize: 24,
  },

  notesForm: {
    maxWidth: 600,
    display: 'flex',
    flexDirection: 'column',
  },
  notesLabel: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 0,
    fontWeight: '500',
    fontSize: '14px',
  },
  notesTextarea: {
    minHeight: '8rem',
    padding: '10px 15px',
    fontWeight: '400',
    fontSize: '14px',
    marginBottom: 31,
    marginTop: 10,
    resize: 'none',
    border: `1px solid ${COLORS.INPUTBORDER}`,
    borderRadius: 4,
    overflow: 'auto',
    width: '100%',

    '&::placeholder': {
      color: COLORS.TEXT.DISABLED,
      fontWeight: '400',
      fontSize: '14px',
    },
  },
  notesDescription: {
    fontWeight: '400',
    fontSize: '14px',
    whiteSpace: 'pre-line',
    margin: '0 0 60px',
    overflow: 'auto',
  },
  closedNotesDesc: {
    marginBottom: 16,
  },
  noNotes: {
    width: 600,
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
  },
  modalBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    marginRight: 42,
  },
  spinner: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    borderTop: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderBottom: `3px solid ${COLORS.DIVIDER}`,
    borderLeft: `3px solid ${COLORS.DIVIDER}`,
    animation: '$spin 0.5s infinite linear',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  goalsContainer: {
    display: 'flex',
    alignItems: 'flex-start',
    position: 'relative',
  },
  extendedDateContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  nextEvalLabel: {
    fontWeight: '500',
    fontSize: '13px',
    color: COLORS.TEXT.SECONDARY,
    marginLeft: 20,
    marginRight: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '& span': {
      marginRight: 10,
    },
  },
  nextEvalDate: {
    display: 'inline-flex',
  },
  evalDateLabel: {
    fontWeight: '500',
    fontSize: '13px',
    color: COLORS.TEXT.PRIMARY,
  },
  endOfProbation: {
    marginBottom: 40,
  },
  fieldset: {
    margin: 0,
    border: 'none',
    padding: 0,
  },
});
