import { FC, memo } from 'react';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import addMonths from 'date-fns/addMonths';
import isSameMonth from 'date-fns/isSameMonth';
import formatDistanceStrict from 'date-fns/formatDistanceStrict';
import differenceInSeconds from 'date-fns/differenceInSeconds';

import { useStyles } from './EvalStatus.styles';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { format } from 'date-fns';

interface Props {
  evaluationDate: string;
  status: string;
  typeOfInteraction: EvaluationType;
}

const EvalStatusComponent: FC<Props> = ({ evaluationDate, status, typeOfInteraction }) => {
  const styles = useStyles();
  const today = new Date();
  const evalDate = new Date(evaluationDate);
  const timeLeft = differenceInSeconds(evalDate, today);
  const isEOP = typeOfInteraction === EvaluationType.EndOfProbation;

  const isOverdue = timeLeft < 0 && status === EvaluationStatus.Upcoming;
  const isEvalClosed = status === EvaluationStatus.Closed;

  const isOngoing = status === EvaluationStatus.Ongoing;
  const isThisMonth = timeLeft > 0 && isSameMonth(evalDate, today);
  const isNextMonth = isSameMonth(evalDate, addMonths(today, 1));
  const timeLeftText = formatDistanceStrict(new Date(evaluationDate), today, {
    roundingMethod: 'floor',
  });

  const evalStatusText = isEvalClosed
    ? 'Closed'
    : isOverdue
    ? 'Overdue'
    : isOngoing
    ? 'Ongoing'
    : isThisMonth
    ? 'This month'
    : isNextMonth
    ? 'Next month'
    : isEOP
    ? `EOP in ${timeLeftText}`
    : `Upcoming evaluation in ${timeLeftText}`;

  const eopDate = format(evalDate, 'MMM d');
  const eopDateText = isOverdue || isEvalClosed ? 'Probation ended on\xA0' : 'Probation ends on\xA0';

  const evalStatusStyle = isEvalClosed
    ? styles.evalStatus
    : isOverdue
    ? styles.overdue
    : isOngoing
    ? styles.ongoing
    : isThisMonth
    ? styles.thisMonth
    : '';

  return (
    <>
      <Typography className={classNames(styles.evalStatus, evalStatusStyle)}>{evalStatusText}</Typography>
      {isEOP && <Typography variant="body2">{`${eopDateText}${eopDate}`}</Typography>}
    </>
  );
};

export const EvalStatus = memo(EvalStatusComponent);
