import { useCallback, useEffect, useState } from 'react';
import throttle from 'lodash/throttle';
import { SCREEN_WIDTH } from '@styles/breakpoints';

export const useBackLinkPosition = (): boolean => {
  const [isBackLinkInHeader, setisBackLinkInHeader] = useState(false);

  const handleWindowResize = useCallback(
    throttle(() => {
      if (!isBackLinkInHeader && window.innerWidth < SCREEN_WIDTH.MOBILE) {
        setisBackLinkInHeader(true);
      } else if (isBackLinkInHeader && window.innerWidth >= SCREEN_WIDTH.MOBILE) {
        setisBackLinkInHeader(false);
      }
    }, 200),
    [isBackLinkInHeader, setisBackLinkInHeader]
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [handleWindowResize]);

  return isBackLinkInHeader;
};
