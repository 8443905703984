export const COLORS = {
  BRAND: {
    PRIMARY: '#FF7A00',
    DARK: '#bd4a00',
    HIGHLIGHT: '#fff2de',
    SECONDARY: '#000000',
    ACCENT: '#9c27b0',
  },
  TEXT: {
    PRIMARY: 'rgba(0, 0, 0, 0.87)',
    SECONDARY: 'rgba(0, 0, 0, 0.6)',
    DISABLED: 'rgba(0, 0, 0, 0.4)',
    DISABLEDBG: 'rgba(0, 0, 0, 0.05)',
    REGULAR: '#081735',
  },
  ACTION: {
    PRIMARY: '#3F8CFF',
    DARK: '#2f60b2',
    HIGHLIGHT: 'rgba(0, 124, 255, 0.08)',
  },
  HIGHLIGHTS: {
    NEUTRAL: '#F6F8FC',
    PROMINENT: '#ffecb3',
    CRITICAL: '#FFEDED',
    NOTE: '#FFF9EF',
    MINT: '#6DCDB6',
    DANGER: '#FF696C',
    ACTIONABLE: '#007CFF14',
  },
  STATUS: {
    SUCCESS: '#4BA601',
    WARNING: '#bd4a00',
    CRITICAL: '#FF1502',
    ACTIONABLE: '#3F8CFF',
  },
  WHITE: '#FFFFFF',
  LOADER: '#f38419',
  DIVIDER: 'rgba(0, 0, 0, 0.08)',
  INPUTBORDER: '#AFB5D2',
  CONTENT: {
    MESSAGE: '#EEF7FF',
  },
  SCALE10: {
    VOTE1: '#FF1502',
    VOTE2: '#FF4802',
    VOTE3: '#FE7D00',
    VOTE4: '#FFAF00',
    VOTE5: '#ecd200',
    VOTE6: '#c7d001',
    VOTE7: '#B4DC02',
    VOTE8: '#83C001',
    VOTE9: '#4BA601',
    VOTE10: '#198C00',
  },
  SCALEEVAL: {
    CANT: '#70748B',
    NEEDSDEV: '#FFAF00',
    SUCCESSFUL: '#83C001',
    EXCEEDS: '#198C00',
  },
  GRAY: {
    SLATE: '#70748B',
    DARK: '#333333',
  },
  BLUE: {
    PRIMARY: '#0066FF',
    LIGHT: '#619DF5',
    LIGHTER: '#E5EFFC',
    PRUSSIAN: '#014361',
  },
};
