import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';
import { Z_INDEXES } from '@styles/zIndexes';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  header: {
    display: 'flex',
    columnGap: 20,
    alignItems: 'start',
    position: 'relative',
    justifyContent: 'space-between',

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
    },
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 20,
    minWidth: 290,

    [BREAKPOINTS.MOBILE]: {
      columnGap: 4,
    },
  },
  mainInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
  },
  infoBlock: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 8,
  },
  mainText: {
    color: COLORS.TEXT.PRIMARY,
    fontSize: '24px',
    lineHeight: '120%',
    fontWeight: '500',
    marginBottom: '4px',

    [BREAKPOINTS.MOBILE]: {
      fontSize: '18px',
    },
  },
  secondaryText: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '13px',
    fontWeight: '400',
  },
  avatar: {
    width: 96,
    height: 96,
    borderRadius: 28,
  },
  icon: {
    height: 22,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    '& svg': {
      fill: COLORS.ACTION.PRIMARY,
      transition: 'all 0.2s',
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    rowGap: 4,
    flexDirection: 'column',
    justifyContent: 'center',
    width: 'fit-content',
    textDecoration: 'none',
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      color: COLORS.BRAND.PRIMARY,
    },
    '&:hover svg': {
      fill: COLORS.BRAND.PRIMARY,
      transform: 'scale(1.1)',
    },
  },
  linkTitle: {
    textAlign: 'center',
    whiteSpace: 'nowrap',
    fontSize: 13,

    [BREAKPOINTS.TABLET]: {
      display: 'none',
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
    },
  },
  actions: {
    display: 'flex',
    columnGap: 20,
    marginTop: 16,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      margin: '0 0 12px',
    },
  },
  mediumTextSkeleton: {
    width: 160,
    height: 20,
  },
  mediumTextSkeleton2: {
    width: 180,
    height: 20,
  },
  smallTextSkeleton: {
    width: 100,
    height: 20,
  },
  iconSkeleton: {
    width: 20,
    height: 20,
  },
  reportsBlock: {
    zIndex: Z_INDEXES.DROPDOWN,
    padding: '11px 16px 19px 16px',
    backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
    borderRadius: 8,
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'start',
    columnGap: 16,
    position: 'relative',
    border: 0,
    textAlign: 'start',

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      padding: '4px 16px 11px 16px',
    },
  },
  reportsToContainter: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    width: 190,
    rowGap: 24,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      marginTop: 8,
    },
  },
  reportsBlockActive: {
    position: 'absolute',
    top: 0,
    right: 0,
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    backgroundColor: COLORS.WHITE,
    animation: '$fadeIn 0.3s ease-in-out',

    '&:hover': {
      backgroundColor: COLORS.WHITE,
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0.85,
    },
    '100%': {
      opacity: 1,
    },
  },
  rotate: {
    transform: 'rotate(180deg) translateY(4px)',
  },
  toggleIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 25,
    color: COLORS.TEXT.SECONDARY,

    '& svg': {
      width: 20,
      height: 20,
    },
  },
  reportIssue: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: 13,
    textDecoration: 'underline',

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
    },
  },
});
