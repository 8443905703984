import { hintsExplainingHowTotalCompensationIsCalculated } from './../constants/hintsExplainingHowTotalCompensationIsCalculated';
import { CompensationType } from '@modules/EvaluationModule/enums/CompensationType';

export const getHintExplainingHowTotalCompensationIsCalculated = ({
  isCurrentUser,
  isBonusAssigned,
  compensationType,
}: {
  isCurrentUser: boolean;
  isBonusAssigned: boolean;
  compensationType: CompensationType;
}): string => {
  return isCurrentUser
    ? isBonusAssigned
      ? hintsExplainingHowTotalCompensationIsCalculated[compensationType].currentUser.withBonus
      : hintsExplainingHowTotalCompensationIsCalculated[compensationType].currentUser.withoutBonus
    : isBonusAssigned
    ? hintsExplainingHowTotalCompensationIsCalculated[compensationType].thirdUser.withBonus
    : hintsExplainingHowTotalCompensationIsCalculated[compensationType].thirdUser.withoutBonus;
};
