import { RootState } from '@modules/App/redux/store';
import { Question } from '@modules/HappinessModule/interfaces/question';
import { QuestionsAsync } from './types';

export const questionsSelector = (state: RootState): QuestionsAsync => state.happiness.questions;

export const questionsHistorySelector = (state: RootState): Question[] | null =>
  state.happiness.questions.questions.data;

export const selectedQuestionSelector = (state: RootState): Question | undefined | null =>
  state.happiness.questions.selectedQuestion;
