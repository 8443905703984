import { put, all, call, select, takeLatest } from 'redux-saga/effects';
import { updateUserGoal, getUserGoalsData, createUserGoal, getAchievedGoalsDataForEval } from './actions';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import {
  setAchievedGoalsDataForEval,
  setAchievedGoalsDataForEvalLoading,
  setEditUserGoalCreationState,
  setEditUserGoalEditState,
  setUserGoalCreationNetworkError,
  setUserGoalEditNetworkError,
  setUserGoalsData,
  setUserGoalsDataLoading,
} from './reducers';
import {
  patchUserGoal,
  fetchUserGoalsPageData,
  postUserGoal,
  fetchUserAchievedGoalsDataForEval,
} from '@modules/EvaluationModule/api/userGoalsData';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { userIdSelector } from './selectors';
import { sortedGoals } from '../helpers/sortedGoals';

function* getUserGoalsDataAsync({ payload }: ReturnType<typeof getUserGoalsData>) {
  try {
    const setLoadingAction = setUserGoalsDataLoading();
    yield put(setLoadingAction);
    const response: UserGoalPageData[] = yield call(fetchUserGoalsPageData, payload);
    const action = setUserGoalsData({ data: sortedGoals(response), state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUserGoalsData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* getAchievedGoalsDataForEvalAsync({ payload }: ReturnType<typeof getAchievedGoalsDataForEval>) {
  try {
    const setLoadingAction = setAchievedGoalsDataForEvalLoading();
    yield put(setLoadingAction);
    const response: UserGoalPageData[] = yield call(fetchUserAchievedGoalsDataForEval, payload);
    const action = setAchievedGoalsDataForEval({ data: sortedGoals(response), state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setAchievedGoalsDataForEval({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* editUserGoalAsync({ payload }: ReturnType<typeof updateUserGoal>) {
  let hasErorr = false;
  try {
    const userId: string = yield select(userIdSelector);
    yield put(setEditUserGoalEditState(DataState.Pending));
    yield put(setUserGoalEditNetworkError(null));
    yield call(patchUserGoal, payload);
    yield put(getUserGoalsData({ userId }));
  } catch (error) {
    yield put(setEditUserGoalEditState(DataState.Rejected));
    hasErorr = true;
    const e = getErrorInfo(error);
    yield put(setUserGoalEditNetworkError(e?.message ?? null));
    yield put(displayNotification('Something went wrong 🥺'));
  } finally {
    if (!hasErorr) {
      yield put(setEditUserGoalEditState(DataState.Fulfilled));
      yield put(displayNotification('Changes successfully saved!'));
    }
  }
}

function* createUserGoalAsync({ payload }: ReturnType<typeof createUserGoal>) {
  let hasErorr = false;
  try {
    const userId: string = yield select(userIdSelector);
    yield put(setEditUserGoalCreationState(DataState.Pending));
    yield put(setUserGoalCreationNetworkError(null));
    yield call(postUserGoal, payload);
    yield put(getUserGoalsData({ userId }));
  } catch (error) {
    yield put(setEditUserGoalCreationState(DataState.Rejected));
    hasErorr = true;
    const e = getErrorInfo(error);
    yield put(setUserGoalCreationNetworkError(e?.message ?? null));
    yield put(displayNotification('Something went wrong 🥺'));
  } finally {
    if (!hasErorr) {
      yield put(setEditUserGoalCreationState(DataState.Fulfilled));
      yield put(displayNotification('Changes successfully saved!'));
    }
  }
}

function* watchGetUserGoalsData() {
  yield takeLatest(getUserGoalsData.type, getUserGoalsDataAsync);
  yield takeLatest(updateUserGoal.type, editUserGoalAsync);
  yield takeLatest(createUserGoal.type, createUserGoalAsync);
  yield takeLatest(getAchievedGoalsDataForEval.type, getAchievedGoalsDataForEvalAsync);
}

export function* userGoalsPageSaga(): Generator {
  yield all([watchGetUserGoalsData()]);
}
