import { displayNotification } from '@modules/App/redux/notifications/actions';
import { DataState } from '@shared/enums/DataState';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { setClientSurveyResponseData } from '../redux';
import { loadClientSurveyResponse } from '../redux/actions';
import { selectClientSurveyResponse, selectWhatIsEditedInClientSurveyResponse } from '../redux/selectors';

export const useClientSurveyResponsePageData = (): {
  clientSurveyResponse: ReturnType<typeof selectClientSurveyResponse>;
  whatIsEditedInClientSurveyResponse: ReturnType<typeof selectWhatIsEditedInClientSurveyResponse>;
} => {
  const dispatch = useDispatch();
  const clientSurveyResponse = useSelector(selectClientSurveyResponse);
  const whatIsEditedInClientSurveyResponse = useSelector(selectWhatIsEditedInClientSurveyResponse);
  const { clientSurveyResponseId } = useParams();

  useEffect(() => {
    if (!clientSurveyResponseId) {
      dispatch(
        setClientSurveyResponseData({
          data: null,
          state: DataState.Rejected,
        })
      );
      dispatch(displayNotification('Client survey response id is missing'));
      return;
    }
    dispatch(loadClientSurveyResponse({ clientSurveyResponseId }));
  }, [clientSurveyResponseId]);

  return {
    clientSurveyResponse,
    whatIsEditedInClientSurveyResponse,
  };
};
