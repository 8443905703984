import React, { FC, memo, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router';
import { useStyles } from './Login.styles';
import { useAuth } from '@shared/hooks/useAuth';
import { paths } from '@shared/enums/paths';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { RockyButton } from '@shared/components/RockyButton';
import { Typography } from '@mui/material';
import { ReactComponent as GoogleIcon } from '@assets/icons/googleIcon.svg';
const LoginComponent: FC = () => {
  const styles = useStyles();
  const { currentUser, signIn } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(pageTitles.loginPage);
  }, []);

  useEffect(() => {
    if (currentUser) {
      if (location.key !== 'default') navigate(-1);
      else navigate(paths.root);
    }
  }, [currentUser]);

  return (
    <div className={styles.root}>
      <Typography variant="h4" component="h1" gutterBottom>
        Login with your AgileEngine email
      </Typography>
      <RockyButton className={styles.loginButton} onClick={signIn} action>
        <GoogleIcon className={styles.googleLogo} />
        Sign in with Google
      </RockyButton>
    </div>
  );
};

export const Login = memo(LoginComponent);
