import { FC } from 'react';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import { useDispatch } from 'react-redux';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { LockPersonOutlined } from '@mui/icons-material';

import { useStyles } from '@modules/HappinessModule/pages/FlagPage/pages/EditFlagFormPage/components/FlagPreview/FlagPreview.styles';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { flagBackLinkPathByUserIdEditPage } from '../../helpers/flagBackLinkPathByUserIdEditPage';
import { WhatsFlagReasonBlockPreview } from './components/WhatsFlagReasonBlockPreview';
import { AnotherOfferInvolvedBlockPreview } from './components/AnotherOfferInvolvedBlockPreview';
import { NewHomeNeededBlockPreview } from './components/NewHomeNeededBlockPreview';
import { FlagUrgencyAndResolutionsBlockPreview } from './components/FlagUrgencyAndResolutionsBlockPreview';
import { FlagWhoIsInvolvedBlockPreview } from './components/FlagWhoIsInvolvedBlockPreview';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { reopenFlagAction } from '../../redux/actions';
import { AutomaticFlagWatchers } from '@modules/HappinessModule/interfaces/FlagWatcherData';

interface Props {
  flagData: FlagData;
  userFlagPermissions: FlagPermissionsData;
  userAutomaticFlagWatchers: AutomaticFlagWatchers;
}

export const FlagPreview: FC<Props> = ({ flagData, userFlagPermissions, userAutomaticFlagWatchers }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const reopen = () => {
    dispatch(reopenFlagAction(flagData.id));
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <WhatsFlagReasonBlockPreview flagData={flagData} />
      </Grid>
      <Grid item xs={12}>
        <AnotherOfferInvolvedBlockPreview flagData={flagData} userFlagPermissions={userFlagPermissions} />
      </Grid>
      {userFlagPermissions.newHomeSection.isReadable && (
        <Grid item xs={12}>
          <NewHomeNeededBlockPreview flagData={flagData} userFlagPermissions={userFlagPermissions} />
        </Grid>
      )}
      <Grid item xs={12}>
        <FlagUrgencyAndResolutionsBlockPreview flagData={flagData} userFlagPermissions={userFlagPermissions} />
      </Grid>
      <Grid item xs={12}>
        <FlagWhoIsInvolvedBlockPreview
          userAutomaticFlagWatchers={userAutomaticFlagWatchers}
          flagData={flagData}
          userFlagPermissions={userFlagPermissions}
        />
      </Grid>
      <Grid item xs={12}>
        <Paper variant="elevation" elevation={2}>
          <Grid container rowGap="36px" alignItems="center" className={styles.footer}>
            <Grid item xs={6}>
              <Stack flexDirection="row" columnGap="27px" alignItems="center">
                <Button component={Link} to={flagBackLinkPathByUserIdEditPage(flagData.user.id ?? '')}>
                  Back
                </Button>
                {flagData.resolvedAt && (
                  <Typography variant="body2">
                    Flag resolved {flagData.flagFields.status} on{' '}
                    {format(new Date(flagData.resolvedAt), 'dd MMM, yyyy')}
                  </Typography>
                )}
              </Stack>
            </Grid>

            <Grid item xs={6} className={styles.reopen}>
              <Stack flexDirection="row" alignItems="center" justifyContent="space-between">
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <LockPersonOutlined fontSize="small" />
                  <Typography variant="body2">HR only</Typography>
                </Stack>
                <Button
                  variant="contained"
                  onClick={reopen}
                  disabled={!userFlagPermissions.flagResolution.isReopenable}
                >
                  Reopen
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};
