import { FC, useCallback } from 'react';
import { Role } from '@shared/enums/role';
import { useStyles } from './ClientSurveysPage.styles';
import { useClientSurveysPageData } from './hooks/useClientSurveysPageData';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { RockyButton } from '@shared/components/RockyButton';
import { useAuth } from '@shared/hooks/useAuth';
import { Box } from '@mui/material';
import { ClientSurveysTable } from './copmonents/ClientSurveysTable';
import { ClientSurveyModal } from './copmonents/ClientSurveyModal/ClientSurveyModal';
import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { setUpsertSurvey } from './redux/actions';
import { useDispatch } from 'react-redux';

export const ClientSurveysPage: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { clientSurveys, categoriesList, upsertSurvey, upsertState } = useClientSurveysPageData();
  const { currentUser } = useAuth();

  const openModal = useCallback(
    (newUpsertSurvey: ClientSurveyData | 'NEW') => {
      dispatch(setUpsertSurvey(newUpsertSurvey));
    },
    [dispatch]
  );

  const closeModal = useCallback(() => {
    dispatch(setUpsertSurvey(undefined));
  }, [dispatch]);

  const isLoading = clientSurveys.state === DataState.Pending;
  const isRejected = clientSurveys.state === DataState.Rejected;
  const isReadOnlyMode = !currentUser?.rockyRoles.includes(Role.csatMember);

  return (
    <div className={styles.root}>
      {isRejected ? (
        <DataErrorView error={clientSurveys.error} />
      ) : (
        <div className={styles.card}>
          <div className={styles.header}>
            <div className={styles.title}>Client satisfaction surveys</div>
            <Box sx={{ display: 'grid', gridGap: '10px', gridTemplateColumns: 'auto auto' }}>
              <RockyButton
                to="https://lookerstudio.google.com/u/0/reporting/5ed4a040-2d48-4d8f-b0ab-6aad8bc478cc/page/p_99g93nfr5c"
                target="_blank"
                download
              >
                View results on dashboard
              </RockyButton>
              {!isLoading && !isReadOnlyMode && (
                <RockyButton action onClick={() => openModal('NEW')}>
                  Add question
                </RockyButton>
              )}
            </Box>
          </div>
          <ClientSurveysTable clientSurveys={clientSurveys} isReadOnlyMode={isReadOnlyMode} openModal={openModal} />
        </div>
      )}
      {upsertSurvey && (
        <ClientSurveyModal
          upsertState={upsertState}
          clientSurvey={upsertSurvey}
          closeModal={closeModal}
          categoriesList={categoriesList}
          clientSurveysList={clientSurveys.data ?? []}
        />
      )}
    </div>
  );
};
