import React, { FC, memo, useCallback, useEffect, useRef } from 'react';
import classNames from 'classnames';
import { useStyles } from './GlobalSearch.styles';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { resetSearchResult, setIsGlobalSearchOverlayOpen } from '@modules/App/redux/users';
import { loadUsersList } from '@modules/App/redux/users/actions';
import { DataState } from '@shared/enums/DataState';
import { GlobalSearchOption } from './components/GlobalSearchOption';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Logo } from '@assets/icons/RockyLogoFull.svg';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { searchCustomStyles } from '@styles/SearchCustomStyles';
import { useSearchFunctions } from '@shared/helpers/useSearchFunctions';
import { paths } from '@shared/enums/paths';
import { selectIsGlobalUsersSearchOpened, selectUsersSearchData } from '@modules/App/redux/users/selectors';

const GlobalSearchComponent: FC = () => {
  const { inputValue, handleInputChange } = useSearchFunctions(loadUsersList, resetSearchResult);
  const isGlobalSearchOverlayOpen = useSelector(selectIsGlobalUsersSearchOpened);
  const searchData = useSelector(selectUsersSearchData);
  const selectRef = useRef(null);
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (!selectRef.current || !isGlobalSearchOverlayOpen) {
      return;
    }
    const refCurrent = selectRef.current as HTMLSelectElement;
    refCurrent.focus();
  }, [isGlobalSearchOverlayOpen]);

  useEffect(() => {
    document.body.style.overflow = isGlobalSearchOverlayOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isGlobalSearchOverlayOpen]);

  const handleGlobalSearchOverlayClose = useCallback(() => {
    dispatch(setIsGlobalSearchOverlayOpen(false));
  }, []);

  const handleValueSelect = useCallback(
    ({ id }) => {
      navigate(`${paths.users}/${id}/${paths.profile}`);
      dispatch(resetSearchResult());
      handleGlobalSearchOverlayClose();
    },
    [searchData]
  );

  useEffect(() => {
    const handleEscapeButtonPressed = (e: KeyboardEvent) => {
      if (e.code === 'Escape') {
        handleGlobalSearchOverlayClose();
      }
    };
    document.addEventListener('keydown', handleEscapeButtonPressed);
    return () => document.removeEventListener('keydown', handleEscapeButtonPressed);
  }, [handleGlobalSearchOverlayClose]);

  const options = searchData.data ? searchData.data : [];

  return (
    <>
      <div
        className={classNames(styles.overlayMenu, { [styles.hidden]: !isGlobalSearchOverlayOpen })}
        onClick={handleGlobalSearchOverlayClose}
      >
        <div className={styles.root} onClick={(e) => e.stopPropagation()}>
          <Logo className={styles.logo} />
          <div className={styles.selectBlock}>
            <div className={styles.searchIcon}>
              <SearchRoundedIcon />
            </div>
            <Select
              styles={searchCustomStyles}
              value={[]}
              ref={selectRef}
              autoFocus={true}
              placeholder="Search people..."
              components={{ Option: GlobalSearchOption }}
              onChange={handleValueSelect}
              isLoading={searchData.state === DataState.Pending}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              noOptionsMessage={() => (inputValue === '' ? 'Start typing to search' : 'No results')}
              options={options}
              filterOption={null}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export const GlobalSearch = memo(GlobalSearchComponent);
