import { ExternalLinksTypes } from '../enums/ExternalLinksTypes';
import { ExternalLinkBody } from '../interfaces/ExternalLinkBody';
import { ExternalLinks } from '../interfaces/ExternalLinks';

export const mapExternalLinks = (data: ExternalLinkBody[]): ExternalLinks => {
  return {
    oneToOneNotesLink: data.find((link) => link.type === ExternalLinksTypes.oneToOne) || null,
    pdpLink: data.find((link) => link.type === ExternalLinksTypes.pdp) || null,
    evaluationLink: data.find((link) => link.type === ExternalLinksTypes.evaluation) || null,
    engineeringManagerNotesLink: data.find((link) => link.type === ExternalLinksTypes.engineeringManagerNotes) || null,
    cvLink: data.find((link) => link.type === ExternalLinksTypes.cv) || null,
  };
};
