import { RootState } from '@modules/App/redux/store';
import { ClientEvalData } from '@modules/EvaluationModule/interfaces/ClientEvalData';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';

export const selectPostingFeedbackState = (state: RootState): DataState =>
  state.evaluation.clientEvalPage.postingFeedbackState;

export const selectClientEvalData = (state: RootState): AsyncData<ClientEvalData> =>
  state.evaluation.clientEvalPage.clientEvalData;
