import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  root: {
    position: 'fixed',
    height: '100vh',
    width: '100vw',
    padding: '40px 0',
    overflow: 'hidden auto',
    zIndex: 13,
    display: 'flex',
    top: 0,
    left: 0,

    '&::after': {
      content: '',
      display: 'inline-block',
      verticalAlign: 'middle',
      height: '100%',
      width: '0',
    },

    [BREAKPOINTS.MOBILE]: {
      padding: 4,
    },
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.6)',
    animation: '$fadeIn 0.1s ease-in',
    zIndex: 12,
  },
  '@keyframes fadeIn': {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
  modal: {
    minWidth: 400,
    minHeight: 200,
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    animation: '$slideUp 0.2s ease-out',
    margin: 'auto',
    zIndex: 13,

    [BREAKPOINTS.MOBILE]: {
      minWidth: 'auto',
      width: '100%',
    },
  },
  '@keyframes slideUp': {
    from: { transform: 'translateY(25%)' },
    to: { transform: 'translateY(0)' },
  },
  header: {
    padding: '30px 50px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    justifyContent: 'space-between',

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
    },
  },
  title: {
    fontSize: 22,
    fontWeight: '500',
    margin: 0,
  },
  crossIcon: {
    justifySelf: 'flex-end',

    '& svg': {
      marginTop: 4,
      cursor: 'pointer',
      fill: COLORS.INPUTBORDER,
    },
  },
  modalBody: {
    overflowY: 'auto',
    padding: '36px 50px',

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
    },
  },
});
