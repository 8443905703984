import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    paddingTop: 16,
  },
  usersHappinessTable: {
    height: '100%',
    width: '100%',

    '& td:first-child': {
      paddingLeft: 24,
      paddingTop: 2,
      verticalAlign: 'top',
    },
  },
  tableDataRow: {
    height: 64,
  },
  personName: {
    fontSize: '14px',
    fontWeight: '400',
    margin: 0,
  },
  team: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
    margin: '2px 0 0',
  },
  questionTitle: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: 1.25,
    color: COLORS.TEXT.PRIMARY,
    margin: '4px 0 2px',
  },
  answeredTime: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '13px',
    margin: 0,
  },
  rateContainer: {
    display: 'flex',
    alignItems: 'center',
    height: 40,
    marginBottom: '20px',
  },
  rateLine: {
    flex: 1,
  },
  commentsTitle: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: 1.25,
    color: COLORS.TEXT.PRIMARY,
    margin: '-10px 0 2px',
  },
  commentText: {
    color: COLORS.TEXT.PRIMARY,
    fontSize: '14px',
    margin: '0 0 32px',
  },
  commentTime: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '13px',
    margin: '0 0 2px',
  },
  mainSection: {
    paddingRight: 24,

    [BREAKPOINTS.MOBILE]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
  },
  circleSection: {
    width: 30,
    paddingRight: 10,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  verticalLine: {
    borderRightWidth: 2,
    borderRightStyle: 'solid',
    borderRightColor: COLORS.DIVIDER,
    height: '100%',
    margin: '-4px 0',
    width: 13,

    'tbody tr:last-child &': {
      display: 'none',
    },
  },
  rowSkeleton: {
    boxSizing: 'border-box',
    width: 'calc(100% - 40px)',
    height: 80,
    margin: '10px 24px',
  },
  reportIssue: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: 13,
    textDecoration: 'underline',
    display: 'block',
    marginTop: -20,

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
    },
  },
});
