import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';

import { MainPageState } from './types';

const initialState: MainPageState = {
  allFlagsData: {
    data: null,
    state: DataState.Pending,
  },
  locations: {
    data: null,
    state: DataState.Pending,
  },
  filter: {
    involvedUsers: { data: null, state: DataState.Pending },
    flagAuthors: { data: null, state: DataState.Pending },
    flagManagers: { data: null, state: DataState.Pending },
  },
};

export const slice = createSlice({
  name: 'allFlagsPage',
  initialState,
  reducers: {
    setAllFlagsDataLoading(state) {
      state.allFlagsData = { data: null, state: DataState.Pending };
    },
    setAllFlagsData(state, { payload }: { payload: MainPageState['allFlagsData'] }) {
      state.allFlagsData = payload;
    },
    setInvolvedUsersLoading(state) {
      state.filter.involvedUsers = { data: null, state: DataState.Pending };
    },
    setInvolvedUsersData(state, action) {
      state.filter.involvedUsers = action.payload;
    },
    setFlagAuthorsLoading(state) {
      state.filter.flagAuthors = { data: null, state: DataState.Pending };
    },
    setFlagAuthorsData(state, action) {
      state.filter.flagAuthors = action.payload;
    },
    setFlagManagersLoading(state) {
      state.filter.flagManagers = { data: null, state: DataState.Pending };
    },
    setFlagManagersData(state, action) {
      state.filter.flagManagers = action.payload;
    },
    setAllLocationsDataLoading(state) {
      state.locations = { data: null, state: DataState.Pending };
    },
    setAllLocationsData(state, action) {
      state.locations = action.payload;
    },
  },
});

export const {
  setAllFlagsDataLoading,
  setAllFlagsData,
  setAllLocationsDataLoading,
  setAllLocationsData,
  setInvolvedUsersLoading,
  setInvolvedUsersData,
  setFlagAuthorsLoading,
  setFlagAuthorsData,
  setFlagManagersData,
  setFlagManagersLoading,
} = slice.actions;

export default slice.reducer;
