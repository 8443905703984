import { put, all, call, takeLatest } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import {
  createExternalLink,
  getExternalLinks,
  getPipLinks,
  removeExternalLink,
  updateExternalLink,
} from '@modules/App/pages/ExternalLinksPage/redux/actions';
import {
  fetchExternalLinks,
  postExternalLink,
  patchExternalLink,
  deleteExternalLink,
  fetchPipLinks,
} from '@modules/App/api/manageExternalLinks';
import { setExternalLinkDataState, setExternalLinks, setPipLinks, setPipLinksDataState } from './reducers';
import { ExternalLinksTypesToNamesMap } from '@modules/App/pages/ExternalLinksPage/redux/constants';
import { PipLinkBody } from '../interfaces/PipLinkBody';
import { ExternalLinks } from '../interfaces/ExternalLinks';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { HttpStatusCode } from '@shared/enums/HttpStatusCode';

function* fetchExternalLinksAsync({ payload }: ReturnType<typeof getExternalLinks>) {
  try {
    yield put(setExternalLinkDataState(DataState.Pending));
    const response: ExternalLinks = yield call(fetchExternalLinks, payload);
    yield put(setExternalLinks({ data: response, state: DataState.Fulfilled }));
  } catch (e) {
    const errorInfo = getErrorInfo(e);
    yield put(setExternalLinks({ data: null, state: DataState.Rejected, error: errorInfo }));
    if (errorInfo?.code !== HttpStatusCode.Forbidden) {
      yield put(displayNotification("Couldn't get the data, please try again"));
    }
  }
}

function* fetchPipLinksAsync({ payload }: ReturnType<typeof getPipLinks>) {
  try {
    yield put(setPipLinksDataState(DataState.Pending));
    const response: { data: PipLinkBody[] } = yield call(fetchPipLinks, payload);
    yield put(setPipLinks({ data: response, state: DataState.Fulfilled }));
  } catch (e) {
    const errorInfo = getErrorInfo(e);
    yield put(setPipLinks({ data: null, state: DataState.Rejected, error: errorInfo }));
    if (errorInfo?.code !== HttpStatusCode.Forbidden) {
      yield put(displayNotification(getErrorInfo(e)?.message || "Couldn't get the data, please try again"));
    }
  }
}

function* updateExternalLinkAsync({ payload }: ReturnType<typeof updateExternalLink>) {
  try {
    yield put(setExternalLinkDataState(DataState.Pending));
    yield call(patchExternalLink, payload);
    const response: ExternalLinks = yield call(fetchExternalLinks, payload.userId ?? '');
    yield put(setExternalLinks({ data: response, state: DataState.Fulfilled }));
    yield put(displayNotification(`${ExternalLinksTypesToNamesMap[payload.type].infoText} was updated`));
  } catch {
    yield put(setExternalLinkDataState(DataState.Rejected));
    yield put(displayNotification("Can't update the link, please try again"));
  }
}

function* deleteExternalLinkAsync({ payload }: ReturnType<typeof removeExternalLink>) {
  try {
    yield put(setExternalLinkDataState(DataState.Pending));
    yield call(deleteExternalLink, payload);
    const response: ExternalLinks = yield call(fetchExternalLinks, payload.userId ?? '');
    yield put(setExternalLinks({ data: response, state: DataState.Fulfilled }));
    yield put(displayNotification(`${ExternalLinksTypesToNamesMap[payload.type].infoText} was deleted`));
  } catch {
    yield put(setExternalLinkDataState(DataState.Rejected));
    yield put(displayNotification("Can't delete the link, please try again"));
  }
}

function* postExternalLinkAsync({ payload }: ReturnType<typeof createExternalLink>) {
  try {
    yield put(setExternalLinkDataState(DataState.Pending));
    yield call(postExternalLink, payload);
    const response: ExternalLinks = yield call(fetchExternalLinks, payload.userId ?? '');
    yield put(setExternalLinks({ data: response, state: DataState.Fulfilled }));
    yield put(displayNotification(`${ExternalLinksTypesToNamesMap[payload.type].infoText} was created`));
  } catch {
    yield put(setExternalLinkDataState(DataState.Rejected));
    yield put(displayNotification("Can't add the link, please try again"));
  }
}

function* watchExternalLinksActions() {
  yield takeLatest(updateExternalLink.type, updateExternalLinkAsync);
  yield takeLatest(getPipLinks.type, fetchPipLinksAsync);
  yield takeLatest(createExternalLink.type, postExternalLinkAsync);
  yield takeLatest(getExternalLinks.type, fetchExternalLinksAsync);
  yield takeLatest(removeExternalLink.type, deleteExternalLinkAsync);
}

export function* externalLinksSaga(): Generator {
  yield all([watchExternalLinksActions()]);
}
