import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { Role } from '@shared/enums/role';
import { User } from '@shared/interfaces/user';
import { useStyles } from './SidebarLink.styles';

interface Props {
  children: ReactNode;
  to: string;
  currentUser?: User;
  otherRoutes?: string[];
  roles?: Role[];
  adminRoute?: boolean;
  isShown?: boolean;
  isTabletMenuOpen?: boolean;
  setIsTabletMenuOpen?: (bool: boolean | ((state: boolean) => boolean)) => void;
}

const SidebarLinkComponent: FC<Props> = ({
  children,
  to,
  roles,
  currentUser,
  adminRoute,
  otherRoutes,
  isShown = true,
  isTabletMenuOpen = false,
  setIsTabletMenuOpen,
}) => {
  const styles = useStyles();
  const location = useLocation();
  const [isItemActive, setIsItemActive] = useState(false);
  const isRoleRestrictionPassed =
    !roles || (currentUser && currentUser.rockyRoles && currentUser.rockyRoles.some((r) => roles.includes(r)));
  const isAdminRestrictionPassed = !adminRoute || (currentUser && currentUser.rockyRoles.includes(Role.vpManager));

  useEffect(() => {
    setIsItemActive(
      !!location.pathname.includes(to) ||
        !!otherRoutes?.some((r) => location.pathname.includes(r)) ||
        !!to.startsWith(location.pathname)
    );
  }, [location]);

  return isShown && isRoleRestrictionPassed && isAdminRestrictionPassed ? (
    <Link
      to={to}
      className={classNames(
        styles.navItem,
        { [styles.activeNavItem]: isItemActive },
        { [styles.tablet]: isTabletMenuOpen }
      )}
      onClick={() => setIsTabletMenuOpen && setIsTabletMenuOpen(false)}
    >
      {children}
    </Link>
  ) : (
    <></>
  );
};

export const SidebarLink = memo(SidebarLinkComponent);
