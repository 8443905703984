export enum ClientsTypeOfContacts {
  client = 'Client',
  closedLead = 'Closed Lead',
  dcCtoClub = 'DC CTO Club',
  exClient = 'Ex-Client',
  exPotential = 'Ex-Potential',
  mindshare = 'Mindshare',
  mindshareRm = 'Mindshare RM',
  pocOnVendorSide = 'POC on vendor side',
}
