import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '@keyframes blink': {
    '0%': { opacity: 0.3 },
    '50%': { opacity: 0.8 },
    '100%': { opacity: 0.3 },
  },
  loadingText: {
    color: COLORS.BRAND.PRIMARY,
    animation: '$blink 1.5s linear infinite',
  },
  homeLink: {
    backgroundColor: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    height: 40,
    width: 120,
    fontWeight: '400',
    marginTop: 20,
    cursor: 'pointer',
    borderRadius: 8,
  },
});
