import { pageTitles } from '@shared/enums/pageTitles';
import { useTitle } from '@shared/hooks/useTitle';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LocationOptionData } from '../interfaces/OptionData';
import { getAllLocationsData } from '../redux/actions';
import { getAllFlagsDataSelector, getAllLocationsDataSelector, selectLocations } from '../redux/selectors';
import { AllFlagsPageData } from '../interfaces/AllFlagsPageData';

export const useAllFlagsData = (): {
  allFlags: AsyncData<AllFlagsPageData>;
  locationOptions: LocationOptionData[];
} => {
  const dispatch = useDispatch();
  const allFlags = useSelector(getAllFlagsDataSelector);
  const locations = useSelector(getAllLocationsDataSelector);
  const [locationOptions, setLocationOptions] = useState<LocationOptionData[]>(useSelector(selectLocations) ?? []);
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(pageTitles.allFlags);
  }, []);

  useEffect(() => {
    dispatch(getAllLocationsData());
  }, []);

  useEffect(() => {
    locations.data &&
      setLocationOptions([
        ...locations.data
          .slice()
          .sort(
            (
              { name: country1, locationArea: locationArea1, isLocationArea: isArea1 },
              { name: country2, locationArea: locationArea2, isLocationArea: isArea2 }
            ) =>
              locationArea1 === locationArea2
                ? isArea1 === isArea2
                  ? country1 > country2
                    ? 1
                    : -1
                  : isArea1 > isArea2
                  ? -1
                  : 1
                : locationArea1 > locationArea2
                ? 1
                : -1
          ),
      ]);
  }, [locations.data]);

  return { allFlags, locationOptions };
};
