import { AllDepartmentsData } from '@modules/HappinessModule/interfaces/AllDepartmentsData';
import httpNodeClient from '@shared/api/httpNodeClient';
import { DepartmentsQueryI } from '../interfaces/DepartmentsQueryI';

const CONTROLLER_NAME = '/departments';

export const fetchAllDepartments = (query: DepartmentsQueryI): Promise<AllDepartmentsData[]> =>
  httpNodeClient.get<AllDepartmentsData[]>(`${CONTROLLER_NAME}?limit=500`, { params: query }).then(({ data }) => data);

export const fetchHiddenDepartments = (): Promise<string[]> =>
  httpNodeClient.get<string[]>(`${CONTROLLER_NAME}/hidden-on-org-chart`).then(({ data }) => data);
