import { FC, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { Button, Grid, Paper, Stack, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import isEmpty from 'lodash/isEmpty';

import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { DataState } from '@shared/enums/DataState';
import { useStyles } from './FlagForm.styles';
import { paths } from '@shared/enums/paths';
import { setFlagDataState } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/reducers';
import {
  editFlagAction,
  raiseFlagAction,
} from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/actions';
import { getValidationSchema } from './constants/validationSchema';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { useAuth } from '@shared/hooks/useAuth';
import { FlagFormObserver } from './components/FlagFormObserver/FlagFormObserver';
import { FLAG_FORM_ID } from './constants/constants';
import { getFlagPayloadData } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/helpers/flagPayloadData';
import { PageState } from '@shared/enums/PageState';
import { useModalState } from '@shared/hooks/useModalState';
import { ConfirmationModalForRaisingFlag } from './components/ConfirmationModalForRaisingFlag';
import { addFlagDataStateSelector } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/selectors';
import { WhatsFlagReasonBlock } from './components/WhatsFlagReasonBlock';
import { AnotherOfferInvolvedBlock } from './components/AnotherOfferInvolvedBlock';
import { NewHomeNeededBlock } from './components/NewHomeNeededBlock';
import { FlagUrgencyAndResolutionsBlock } from './components/FlagUrgencyAndResolutionsBlock';
import { FlagWhoIsInvolvedBlock } from './components/FlagWhoIsInvolvedBlock';
import { flagBackLinkPathByUserIdCreatePage } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/helpers/flagBackLinkPathByUserIdCreatePage';
import { flagBackLinkPathByUserIdEditPage } from '../../pages/EditFlagFormPage/helpers/flagBackLinkPathByUserIdEditPage';
import { FlagPermissionsData } from '../../interfaces/FlagPermissionsData';
import { FlagFormFields } from './constants/FlagFormFields';
import format from 'date-fns/format';
import { AutomaticFlagWatchers } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import isEqual from 'lodash/isEqual';

interface Props {
  flagData: FlagData;
  flagFormState: PageState;
  userFlagPermissions: FlagPermissionsData;
  userAutomaticFlagWatchers: AutomaticFlagWatchers;
}

export const FlagForm: FC<Props> = ({ flagFormState, flagData, userFlagPermissions, userAutomaticFlagWatchers }) => {
  const initialValues = flagData.flagFields;
  const { currentUser } = useAuth();
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNewFlag = flagFormState === PageState.CreateView;
  const userData = flagData.user;
  const userId = userData?.id;
  const formRef = useRef<FormikProps<FlagFields>>(null);
  const addFlagDataState = useSelector(addFlagDataStateSelector);
  const { flagId } = useParams();
  const initialStatus = flagData.flagFields.status;
  const { openModal, isModalOpen, closeModal } = useModalState();
  const [searchParams] = useSearchParams();
  const isGeneralFlagInformationEditable = isNewFlag
    ? userFlagPermissions.flag.isCreatable
    : userFlagPermissions.flag.isUpdatable;
  const validationSchema = getValidationSchema(isGeneralFlagInformationEditable);

  const communicationModalFunctions = useModalState(false);

  useEffect(() => {
    const markFromParams = searchParams.get('mark');
    const dateFromParams = searchParams.get('date');
    const questionFromParams = searchParams.get('question');
    const descriptionText = `Flag was triggered by ${markFromParams} out of 10 voting on the ${
      dateFromParams && format(new Date(dateFromParams || ''), 'MMMM yy')
    } survey "${questionFromParams}?"`;
    if (markFromParams && dateFromParams && questionFromParams) {
      formRef?.current?.setFieldValue(FlagFormFields.description.name, descriptionText);
    }
  }, [searchParams]);

  const { hash } = useLocation();
  const offerRef = useRef<null | HTMLDivElement>(null);

  useEffect(() => {
    if (hash !== '#offer') return;

    offerRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [hash]);

  useEffect(() => {
    if (addFlagDataState.loadingState === DataState.Fulfilled && !addFlagDataState.isNoNavigation) {
      navigate(`${paths.users}/${userId}/${paths.flags}`);
      dispatch(setFlagDataState({ loadingState: DataState.Idle }));
      const notificaitonToDisplay = isNewFlag
        ? `Flag raised, ${
            formRef.current?.values.isRecipientsDisabled
              ? '0'
              : formRef.current?.values.emailRecipients.filter(
                  (r, idx, recipients) =>
                    recipients.findIndex((recipient) => recipient.userEmail === r.userEmail) === idx
                ).length
          } people notified via email`
        : 'Flag edited successfully!';
      dispatch(displayNotification(notificaitonToDisplay));
    }
  }, [addFlagDataState]);

  const handleSubmitFlagForm = useCallback(
    (values: FlagFields) => {
      if (!userId) {
        return;
      }
      if (isNewFlag && !isModalOpen && !communicationModalFunctions.isModalOpen && isEmpty(formRef.current?.errors)) {
        if (values.isConfidential) {
          openModal();
        } else {
          communicationModalFunctions.openModal();
        }
        return;
      }

      const idFromParams = searchParams.get('id');
      const payload = getFlagPayloadData({
        values,
        isNewFlag,
        userId,
        currentUser,
        userFlagPermissions: userFlagPermissions ?? null,
      });
      const actionToDispatch = isNewFlag
        ? raiseFlagAction(idFromParams ? { ...payload, surveyMessageId: idFromParams } : payload)
        : flagId
        ? editFlagAction({ fields: payload, flagId })
        : null;
      if (actionToDispatch) {
        dispatch(actionToDispatch);
      }
    },
    [isNewFlag, isModalOpen, userId, currentUser, flagId, communicationModalFunctions.isModalOpen]
  );

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
      onSubmit={handleSubmitFlagForm}
      validateOnMount
    >
      {({ values }: { values: FlagFields }) => (
        <Form id={FLAG_FORM_ID} noValidate>
          <FlagFormObserver flagFor={userData} isNewFlag={isNewFlag} formRef={formRef} flagAuthor={flagData.author} />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <WhatsFlagReasonBlock
                flagId={flagId ?? ''}
                userData={userData}
                isNewFlag={isNewFlag}
                userFlagPermissions={userFlagPermissions}
              />
            </Grid>
            <Grid ref={offerRef} item xs={12}>
              <AnotherOfferInvolvedBlock isNewFlag={isNewFlag} userFlagPermissions={userFlagPermissions} />
            </Grid>
            <Grid item xs={12}>
              <NewHomeNeededBlock
                handleRaisingFlag={handleSubmitFlagForm}
                isNewFlag={isNewFlag}
                userData={userData}
                userFlagPermissions={userFlagPermissions}
                communicationModalFunctions={communicationModalFunctions}
              />
            </Grid>
            <Grid item xs={12}>
              <FlagUrgencyAndResolutionsBlock
                flagChangeEvents={flagData.flagChangeEvents ?? []}
                initialStatus={initialStatus ?? null}
                isNewFlag={isNewFlag}
                userFlagPermissions={userFlagPermissions}
              />
            </Grid>
            <Grid item xs={12}>
              <FlagWhoIsInvolvedBlock
                isNewFlag={isNewFlag}
                flagData={flagData}
                userFlagPermissions={userFlagPermissions}
                engineeringManager={flagData.user.engineeringManager ?? null}
                userAutomaticFlagWatchers={userAutomaticFlagWatchers}
              />
            </Grid>
            <Grid item xs={12}>
              <Paper variant="elevation" elevation={2}>
                <Grid container rowGap="36px" className={styles.footer}>
                  <Grid item xs={6}>
                    <Stack flexDirection="row" columnGap="27px" alignItems="center">
                      <Stack flexDirection="row" columnGap="10px">
                        <LoadingButton
                          loading={addFlagDataState.loadingState === DataState.Pending}
                          type="submit"
                          variant="contained"
                          disabled={isEqual(values, initialValues)}
                        >
                          {!isNewFlag ? 'Save changes' : 'Continue'}
                        </LoadingButton>
                        <Button
                          component={Link}
                          to={
                            isNewFlag
                              ? flagBackLinkPathByUserIdCreatePage(flagData.user.id ?? '')
                              : flagBackLinkPathByUserIdEditPage(flagData.user.id ?? '')
                          }
                        >
                          {!isNewFlag ? 'Discard' : 'Cancel'}
                        </Button>
                      </Stack>
                      <Typography variant="body2">✻ fill-in required fields</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
          <ConfirmationModalForRaisingFlag
            handleRaisingFlag={() => handleSubmitFlagForm(values)}
            onClose={closeModal}
            title="Raising a flag"
            isOpen={isModalOpen}
            flagRaisingState={addFlagDataState.loadingState}
          />
        </Form>
      )}
    </Formik>
  );
};
