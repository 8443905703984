import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modalStyles: {
    color: COLORS.TEXT.REGULAR,
  },
  modalTitleStyles: {
    fontSize: 22,
    fontWeight: 700,
  },
  modalBodyStyles: {
    padding: 0,
  },
  importantNote: {
    color: COLORS.WHITE,
    background: COLORS.HIGHLIGHTS.DANGER,
    padding: '24px 51px',

    [BREAKPOINTS.MOBILE]: {
      padding: '24px 16px',
    },
  },
  notImportantNote: {
    padding: '24px 51px 0px 51px',

    [BREAKPOINTS.MOBILE]: {
      padding: '24px 16px 0px 16px',
    },
  },
  btns: {
    display: 'flex',
    justifyContent: 'end',
    gap: 10,
    marginTop: 53,
    marginBottom: 38,
    marginRight: 42,
  },
});
