import { ReactComponent as TlIcon } from '@assets/icons/tlIcon.svg';
import { ReactComponent as PmIcon } from '@assets/icons/pmIcon.svg';
import { ReactComponent as CpIcon } from '@assets/icons/cpIcon.svg';
import { ReactComponent as DmIcon } from '@assets/icons/dmIcon.svg';
import { ReactComponent as PdmIcon } from '@assets/icons/pdmIcon.svg';
import { ReactComponent as EmIcon } from '@assets/icons/engineeringManagerIcon.svg';

import { Role } from '@shared/enums/role';
import { ProjectRole } from '@shared/enums/projectRole';
import { ReactElement } from 'react';

export const roleIconMap: {
  [key in ProjectRole]: ReactElement;
} = {
  [Role.teamLead]: <TlIcon />,
  [Role.clientPartner]: <CpIcon />,
  [Role.projectManager]: <PmIcon />,
  [Role.deliveryManager]: <DmIcon />,
  [Role.portfolioDeliveryManager]: <PdmIcon />,
  [Role.engineeringManager]: <EmIcon />,
};
