import {
  DialogContent,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './NewGoalForm.styles';
import PinIcon from '@mui/icons-material/Pin';
import { DatePicker } from '@mui/x-date-pickers';
import { RockyButton } from '@shared/components/RockyButton';
import { CreateUserGoalData } from '@modules/EvaluationModule/interfaces/CreateUserGoalData';
import { Formik, FormikProps } from 'formik';
import { GoalsCategory } from '@modules/EvaluationModule/enums/GoalsCategory';
import { creationValidationSchema } from '../../constants/valdationSchema';
import isEmpty from 'lodash/isEmpty';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { createUserGoal } from '../../redux/actions';
import { userGoalCreationNetworkErrorSelector } from '../../redux/selectors';
import { SmartHint } from '../SmartHint/SmartHint';
import { GoalsFormField } from '../../enums/GoalsFormField';
import { IS_HINT_SHOWN } from '../../constants/isSmartGoalsShown';

interface Props {
  handleModalClose: () => void;
  isHintShown: boolean;
  setIsHintShow: (arg0: boolean) => void;
  customDueDate: string | null;
  setCustomDueDate: (arg0: string | null) => void;
  isLoading: boolean;
  clearSearcParam: () => void;
}

export const NewGoalForm: React.FC<Props> = ({
  handleModalClose,
  isHintShown,
  setIsHintShow,
  customDueDate,
  setCustomDueDate,
  isLoading,
  clearSearcParam,
}) => {
  const styles = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const userGoalCreationNetworkError = useSelector(userGoalCreationNetworkErrorSelector);
  const formRef = useRef<FormikProps<CreateUserGoalData>>(null);
  const [isValidateOnChange, setIsValidateOnChange] = useState(false);
  const handleClickSubmitIfValid = () => {
    if (!formRef.current) {
      return;
    }
    formRef.current.setTouched({});
    setIsValidateOnChange(true);
    formRef.current.validateForm().then((errors) => {
      if (isEmpty(errors)) {
        formRef.current?.handleSubmit();
        clearSearcParam();
      }
    });
  };

  const handleSubmitCreateGoal = useCallback(
    (values: CreateUserGoalData) => {
      if (id) {
        const payload = values.isRange
          ? {
              ...values,
              userId: id,
              dueDate: customDueDate?.toString() ?? null,
            }
          : {
              userId: id,
              objective: values.objective,
              isRange: values.isRange,
              worstCaseResult: values.worstCaseResult,
              bestCaseResult: values.worstCaseResult,
              category: values.category,
              dueDate: customDueDate?.toString() ?? null,
              description: values.description,
            };
        dispatch(createUserGoal(payload));
      }
    },
    [customDueDate]
  );

  const closeGoalHint = () => {
    setIsHintShow(false);
    localStorage.setItem(IS_HINT_SHOWN, 'false');
  };

  useEffect(() => {
    localStorage.getItem(IS_HINT_SHOWN) === 'false' ? setIsHintShow(false) : setIsHintShow(true);
  }, []);

  const initialValues = {
    userId: '',
    objective: '',
    isRange: true,
    bestCaseResult: null,
    worstCaseResult: null,
    category: GoalsCategory.Professional,
    dueDate: null,
    description: '',
  };

  return (
    <>
      <Formik
        enableReinitialize
        innerRef={formRef}
        initialValues={initialValues}
        validationSchema={creationValidationSchema}
        onSubmit={handleSubmitCreateGoal}
        validateOnChange={isValidateOnChange}
        validateOnBlur={false}
      >
        {(formik) => (
          <DialogContent sx={{ py: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={isHintShown ? 8 : 12}>
                <Grid container spacing={3} sx={{ paddingLeft: 1 }}>
                  <Grid item xs={12} sx={{ mt: 1 }}>
                    <TextField
                      placeholder='e.g. "Pass Google GCP certification"'
                      label="Goal objective"
                      value={formik.values.objective}
                      name={GoalsFormField.Objective}
                      variant="outlined"
                      type="text"
                      fullWidth
                      autoFocus
                      autoComplete="off"
                      helperText={
                        formik.errors.objective && <span className={styles.fieldError}>{formik.errors.objective}</span>
                      }
                      error={Boolean(formik.errors.objective)}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  {formik.values.isRange ? (
                    <>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          placeholder=""
                          label="Worst case"
                          variant="outlined"
                          type="number"
                          fullWidth
                          autoComplete="off"
                          value={formik.values.worstCaseResult ?? ''}
                          name={GoalsFormField.WorstCaseResult}
                          helperText={
                            formik.errors.worstCaseResult && (
                              <span className={styles.fieldError}>{formik.errors.worstCaseResult}</span>
                            )
                          }
                          InputProps={{ inputProps: { min: 0 } }}
                          error={Boolean(formik.errors.worstCaseResult)}
                          onChange={formik.handleChange}
                        />
                      </Grid>
                      <Grid item xs={6} sm={4}>
                        <TextField
                          placeholder=""
                          label="Best case"
                          variant="outlined"
                          type="number"
                          fullWidth
                          autoComplete="off"
                          value={formik.values.bestCaseResult ?? ''}
                          name={GoalsFormField.BestCaseResult}
                          helperText={
                            formik.errors.bestCaseResult && (
                              <span className={styles.fieldError}>{formik.errors.bestCaseResult}</span>
                            )
                          }
                          error={Boolean(formik.errors.bestCaseResult)}
                          onChange={formik.handleChange}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title="Range of measurable criteria to determine the outcome and keep it on schedule"
                                  placement="top"
                                >
                                  <PinIcon />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3} mt={{ xs: 0, sm: 2 }}>
                        <Link
                          onClick={() => {
                            formik.setFieldValue('isRange', false);
                          }}
                          className={styles.hideHintBtn}
                        >
                          Use exact number
                        </Link>
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={8} sm={4}>
                        <TextField
                          placeholder=""
                          label="Expected result"
                          variant="outlined"
                          type="number"
                          fullWidth
                          autoComplete="off"
                          value={formik.values.worstCaseResult ?? ''}
                          name={GoalsFormField.WorstCaseResult}
                          helperText={
                            formik.errors.worstCaseResult && (
                              <span className={styles.fieldError}>{formik.errors.worstCaseResult}</span>
                            )
                          }
                          error={Boolean(formik.errors.worstCaseResult)}
                          onChange={formik.handleChange}
                          InputProps={{
                            inputProps: { min: 0 },
                            endAdornment: (
                              <InputAdornment position="end">
                                <Tooltip
                                  title="Some measurable criteria to determine the outcome and keep it on schedule"
                                  placement="top"
                                >
                                  <PinIcon />
                                </Tooltip>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Grid>
                      <Grid item xs={4} sm={7} mt={{ xs: 0, sm: 2 }}>
                        <Link
                          onClick={() => {
                            formik.setFieldValue('isRange', true);
                          }}
                          className={styles.hideHintBtn}
                        >
                          Use range instead
                        </Link>
                      </Grid>
                    </>
                  )}
                  <Grid item xs={12}>
                    <TextField
                      label="Description of steps and activities"
                      placeholder="Describe tasks, actions, and steps to measure and achieve the goal"
                      variant="outlined"
                      fullWidth
                      multiline
                      minRows={6}
                      value={formik.values.description}
                      name={GoalsFormField.Description}
                      helperText={
                        formik.errors.description && (
                          <span className={styles.fieldError}>{formik.errors.description}</span>
                        )
                      }
                      error={Boolean(formik.errors.description)}
                      onChange={formik.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="goal-create-category">Category</InputLabel>
                      <Select
                        labelId="goal-create-category"
                        label="Category"
                        value={formik.values.category}
                        name={GoalsFormField.Category}
                        error={Boolean(formik.errors.category)}
                        onChange={formik.handleChange}
                      >
                        {Object.values(GoalsCategory).map((el, i) => (
                          <MenuItem value={el} key={i}>
                            {el}
                          </MenuItem>
                        ))}
                      </Select>
                      {formik.errors.category && (
                        <span className={styles.customFieldError}>{formik.errors.category}</span>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <DatePicker
                          label="Due date"
                          openTo="month"
                          views={['year', 'month', 'day']}
                          inputFormat="dd MMM yyyy"
                          value={customDueDate}
                          disableMaskedInput
                          onChange={(newValue) => {
                            setCustomDueDate(newValue);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              helperText={
                                formik.errors.dueDate && (
                                  <span className={styles.fieldError}>{formik.errors.dueDate}</span>
                                )
                              }
                              error={Boolean(formik.errors.dueDate)}
                              className={styles.goalDue}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} md={6} className={styles.goalActions}>
                        <RockyButton inverted onClick={handleModalClose}>
                          Cancel
                        </RockyButton>
                        <RockyButton action onClick={handleClickSubmitIfValid} isLoading={isLoading}>
                          Create
                        </RockyButton>
                      </Grid>
                    </Grid>
                    <div className={styles.networkError}>{userGoalCreationNetworkError}</div>
                  </Grid>
                </Grid>
              </Grid>
              {isHintShown && (
                <Grid item xs={12} md={4}>
                  <SmartHint closeGoalHint={closeGoalHint} />
                </Grid>
              )}
            </Grid>
          </DialogContent>
        )}
      </Formik>
    </>
  );
};
