import { User } from '../user';
import { UserRaw } from '../UserRaw';

export const mapUserData = (userForMap: UserRaw): User => {
  const {
    full_name,
    email,
    position,
    imageUrl,
    id,
    has_reporters,
    rocky_role,
    primary_reports_to,
    country,
    city,
    zoho_id,
  } = userForMap;

  const user: User = {
    id,
    email,
    name: full_name,
    position,
    imageUrl,
    rockyRoles: rocky_role,
    hasReporters: has_reporters,
    primaryReportsTo: primary_reports_to && {
      ...primary_reports_to,
      name: primary_reports_to.full_name,
    },
    country: country,
    city: city,
    zohoId: zoho_id,
  };
  return user;
};
