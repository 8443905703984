import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    padding: '16px 24px',
    backgroundColor: COLORS.HIGHLIGHTS.PROMINENT,
    display: 'flex',
    flexWrap: 'wrap',
    borderRadius: '0 0 8px 8px',
  },
  expiredRoot: {
    backgroundColor: COLORS.HIGHLIGHTS.CRITICAL,
  },
  cardHeader: {
    fontSize: 18,
    fontWeight: '500',
    margin: '0 0 16px',
    width: '100%',
  },
  categoryText: {
    fontSize: '14px',
    margin: '0 0 4px',
    fontWeight: '400',
    width: '100%',
  },
  questionText: {
    fontSize: '14px',
    fontWeight: '500',
    margin: '0 0 8px',
    width: '100%',
  },
  dateText: {
    fontSize: '14px',
    color: COLORS.STATUS.WARNING,
    fontWeight: '500',
    margin: 0,
  },
  dateHolder: {
    marginTop: 'auto',
  },
  runSurveyBtn: {
    backgroundColor: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    border: '1px solid transparent',
    borderRadius: 8,
    fontWeight: '500',
    padding: '10px 20px',
    transition: 'all 0.1s',
    marginLeft: 'auto',

    '&:hover:not([disabled])': {
      opacity: 0.9,
      cursor: 'pointer',
    },
  },
  startSurveyPopup: {
    '&-content': {
      width: '520px !important',
      minHeight: 243,
      color: COLORS.TEXT.PRIMARY,
      fontWeight: '400',
      fontSize: '14px',
      border: 'none',
      background: COLORS.WHITE,
    },
    '&-arrow': {
      color: COLORS.WHITE,
    },
  },
});
