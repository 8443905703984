import { put, all, call, takeLatest, select, delay, takeLeading } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import {
  getChatData,
  setChatData,
  setChatDataLoading,
  voteAction,
  sendMessageAction,
  sendRockyMessageAction,
  requestRevoteAction,
  alreadyVotedMessageAction,
} from './actions';
import { fetchChatData, postVote, postMessage, requestRevote } from '@modules/HappinessModule/api/chatData';
import { ChatMessage } from '@modules/HappinessModule/interfaces/ChatMessage';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { RockyChatAnswers } from '@modules/HappinessModule/pages/ChatPage/enums/RockyChatAnswers';
import { RootState } from '@modules/App/redux/store';
import { v4 as uuidv4 } from 'uuid';
import parseISO from 'date-fns/parseISO';
import { rockyAnswers } from '../constants/rockyAnswers';
import { ChatAuthor } from '../enums/ChatAuthor';
import { getMessageByMark } from './../helpers/getMessageByMark';
import { ChatErrorMessages } from '../enums/ChatErrorMessages';
import { ChatMessagesDelay } from '../enums/ChatMessagesDelay';
import { ChatCommands } from '../enums/ChatCommands';
import last from 'lodash/last';
import { getIsSurveyExpired } from '../helpers/helpers';

function* sendAndSetMessageInChat({ id, text, author, ts, reaction, attachmentUrl, expirationDate }: ChatMessage) {
  const chatMessages: ChatMessage[] | null = yield select((state: RootState) => state.happiness.chatPage.chatData.data);
  if (!chatMessages) {
    return;
  }
  const newChatMessages = [...chatMessages];
  newChatMessages.push({ id, text, author, ts, reaction, attachmentUrl, expirationDate });
  yield put(
    setChatData({
      data: newChatMessages,
      state: DataState.Fulfilled,
    })
  );
  if (!expirationDate) {
    yield call(postMessage, { message: text, author });
  }
}

function* setMessageInChat({ id, text, author, ts, reaction, attachmentUrl, expirationDate }: ChatMessage) {
  const chatMessages: ChatMessage[] | null = yield select((state: RootState) => state.happiness.chatPage.chatData.data);
  if (!chatMessages) {
    return;
  }
  const newChatMessages = [...chatMessages];
  newChatMessages.push({ id, text, author, ts, reaction, attachmentUrl, expirationDate });
  yield put(
    setChatData({
      data: newChatMessages,
      state: DataState.Fulfilled,
    })
  );
}

function* checkIfSurveyExpired() {
  const chatMessages: ChatMessage[] | null = yield select((state: RootState) => state.happiness.chatPage.chatData.data);
  const revoteMessage = chatMessages?.filter(({ reaction }) => reaction || reaction === null).pop();
  const surveyExpirationDate =
    revoteMessage && revoteMessage.expirationDate ? parseISO(revoteMessage.expirationDate) : null;
  const isSurveyExpired = getIsSurveyExpired(surveyExpirationDate);

  if (isSurveyExpired) {
    yield call(sendAndSetMessageInChat, {
      id: uuidv4(),
      ts: new Date().toISOString(),
      text: RockyChatAnswers.ExpiredRevote,
      author: ChatAuthor.Rocky,
    });
    return true;
  }
  return false;
}

function* checkIfRevoteAvailable() {
  const chatMessages: ChatMessage[] | null = yield select((state: RootState) => state.happiness.chatPage.chatData.data);
  const revoteMessage = chatMessages
    ?.slice()
    .reverse()
    .find((m: ChatMessage) => m.isRevotable !== undefined);
  const isRevoteAvailable = !!revoteMessage?.isRevotable;

  if (!isRevoteAvailable) {
    yield call(sendAndSetMessageInChat, {
      id: uuidv4(),
      ts: new Date().toISOString(),
      text: RockyChatAnswers.ExpiredRevote,
      author: ChatAuthor.Rocky,
    });
  }
  return isRevoteAvailable;
}

function* getChatDataAsync({ payload }: ReturnType<typeof getChatData>) {
  const chatMessages: ChatMessage[] = yield select((state: RootState) => state.happiness.chatPage.chatData.data);
  if (!chatMessages) {
    const setLoadingAction = setChatDataLoading();
    yield put(setLoadingAction);
  }

  try {
    const response: ChatMessage[] = yield call(fetchChatData, payload);
    const newChatMessages = chatMessages ? [...response, ...chatMessages] : response;
    const setChatDataAction = setChatData({ data: newChatMessages, state: DataState.Fulfilled });
    yield put(setChatDataAction);
  } catch {
    yield put(
      setChatData({
        data: null,
        state: DataState.Rejected,
      })
    );
  }
}

function* voteActionAsync({ payload }: ReturnType<typeof voteAction>) {
  try {
    const isSurveyExpired: boolean = yield call(checkIfSurveyExpired);
    if (isSurveyExpired) {
      return;
    }
    const chatMessages: ChatMessage[] | null = yield select(
      (state: RootState) => state.happiness.chatPage.chatData.data
    );
    if (!chatMessages) {
      return;
    }
    const newChatMessages = [...chatMessages];
    const revoteMessage = newChatMessages?.filter(({ reaction }) => reaction || reaction === null).pop();

    if (newChatMessages && revoteMessage) {
      newChatMessages[newChatMessages.indexOf(revoteMessage)] = {
        ...revoteMessage,
        reaction: payload,
        isRevotable: true,
      };
    }

    yield put(
      setChatData({
        data: newChatMessages,
        state: DataState.Fulfilled,
      })
    );
    yield call(postVote, payload);
    const text = getMessageByMark(payload);
    yield call(sendAndSetMessageInChat, { id: uuidv4(), ts: new Date().toISOString(), text, author: ChatAuthor.Rocky });
  } catch {
    yield put(displayNotification(ChatErrorMessages.VotingError));
  }
}

function* sendRockyMessageActionAsync() {
  try {
    const text = rockyAnswers[Math.floor(Math.random() * rockyAnswers.length)];
    yield call(sendAndSetMessageInChat, { id: uuidv4(), ts: new Date().toISOString(), text, author: ChatAuthor.Rocky });
  } catch (error) {
    yield put(displayNotification(ChatErrorMessages.SendingMessageError));
  }
}

function* sendMessageActionAsync({ payload }: ReturnType<typeof sendMessageAction>) {
  try {
    const { message, author, attachmentUrl } = payload;
    yield call(sendAndSetMessageInChat, {
      id: uuidv4(),
      ts: new Date().toISOString(),
      text: message,
      author,
      attachmentUrl,
    });
  } catch {
    yield put(displayNotification(ChatErrorMessages.SendingMessageError));
  }
}

function* requestRevoteActionAsync() {
  try {
    yield delay(ChatMessagesDelay.ChatMessagesDelayMs);
    const chatMessages: ChatMessage[] | null = yield select(
      (state: RootState) => state.happiness.chatPage.chatData.data
    );
    const revoteMessage = chatMessages?.filter(({ reaction }) => reaction || reaction === null).pop();

    if (!revoteMessage) {
      return;
    }

    const currentUserId: string = yield select((state: RootState) => state.user.currentUser.data?.id);
    const lastMessage = last(chatMessages);
    if (lastMessage && lastMessage.text && lastMessage.text.trim() !== ChatCommands.Revote) {
      yield call(sendAndSetMessageInChat, {
        id: uuidv4(),
        ts: new Date().toISOString(),
        text: ChatCommands.Revote,
        author: currentUserId,
      });
    }

    const isRevoteAvailable: boolean = yield call(checkIfRevoteAvailable);
    if (!isRevoteAvailable) {
      return;
    }

    if (revoteMessage.reaction === null) {
      yield call(sendAndSetMessageInChat, {
        id: uuidv4(),
        ts: new Date().toISOString(),
        text: RockyChatAnswers.TwiceRevote,
        author: ChatAuthor.Rocky,
        isRevotable: true,
      });
      return;
    }

    yield call(sendAndSetMessageInChat, {
      id: uuidv4(),
      ts: new Date().toISOString(),
      text: RockyChatAnswers.SuccessfulRevote,
      author: ChatAuthor.Rocky,
    });

    const newRevoteMessage = {
      ...revoteMessage,
      id: uuidv4(),
      ts: new Date().toISOString(),
      reaction: null,
    };

    yield call(requestRevote);
    yield call(setMessageInChat, newRevoteMessage);
  } catch {
    yield put(displayNotification(ChatErrorMessages.RevoteRequestError));
  }
}

function* alreadyVotedMessageActionAsync({ payload }: ReturnType<typeof alreadyVotedMessageAction>) {
  try {
    yield delay(ChatMessagesDelay.ChatMessagesDelayMs);
    yield call(sendAndSetMessageInChat, {
      id: uuidv4(),
      ts: new Date().toISOString(),
      text: `${RockyChatAnswers.AlreadyVoted} ${payload} vote.\nDo you want to revote?`,
      author: ChatAuthor.Rocky,
    });
  } catch (error) {
    yield put(displayNotification(ChatErrorMessages.RevoteRequestError));
  }
}

function* watchGetChatData() {
  yield takeLeading(getChatData.type, getChatDataAsync);
  yield takeLatest(voteAction.type, voteActionAsync);
  yield takeLatest(sendMessageAction.type, sendMessageActionAsync);
  yield takeLatest(sendRockyMessageAction.type, sendRockyMessageActionAsync);
  yield takeLatest(requestRevoteAction.type, requestRevoteActionAsync);
  yield takeLatest(alreadyVotedMessageAction.type, alreadyVotedMessageActionAsync);
}

export function* chatPageSaga(): Generator {
  yield all([watchGetChatData()]);
}
