import {
  ExternalLinksPermissions,
  PDPPermissions,
  OneToOnePermissions,
  EvalsPermissions,
  CVPermissions,
  EMOneToOnePermissions,
} from '@shared/enums/ExternalLinksPermissions';
import { ExternalLinksPermissionsInterface } from '@modules/App/interfaces/ExternalLinksPermissions';

export const getExternalLinksPermissions = (
  permissions: ExternalLinksPermissions[]
): ExternalLinksPermissionsInterface => {
  const externalLinksPermissions: ExternalLinksPermissionsInterface = {
    pdp: {
      isReadable: permissions.includes(PDPPermissions.Read),
      isUpdatable: permissions.includes(PDPPermissions.Update),
    },
    oneToOne: {
      isReadable: permissions.includes(OneToOnePermissions.Read),
      isUpdatable: permissions.includes(OneToOnePermissions.Update),
    },
    evaluation: {
      isReadable: permissions.includes(EvalsPermissions.Read),
      isUpdatable: permissions.includes(EvalsPermissions.Update),
    },
    engineeringManagerNotes: {
      isReadable: permissions.includes(EMOneToOnePermissions.Read),
      isUpdatable: permissions.includes(EMOneToOnePermissions.Update),
    },
    cv: {
      isReadable: permissions.includes(CVPermissions.Read),
      isUpdatable: permissions.includes(CVPermissions.Update),
    },
  };

  return externalLinksPermissions;
};
