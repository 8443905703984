import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';

import { State } from './types';

const initialState: State = {
  enabledApiFeatures: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: 'features',
  initialState,
  reducers: {
    setFeatures(state, { payload }) {
      state.enabledApiFeatures = payload;
    },
  },
});

export const { setFeatures } = slice.actions;

export default slice.reducer;
