import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  header: {
    width: '100%',
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    height: 64,
    gap: 10,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      alignItems: 'start',
      height: 'auto',
      padding: 16,
      position: 'relative',
    },
  },
  dateTextSkeleton: {
    width: 100,
  },
  leftHeaderBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    width: '100%',

    '& button': {
      height: 42,
    },

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      alignItems: 'start',
    },
  },
  rightHeaderBlock: {
    fontWeight: '500',

    [BREAKPOINTS.MOBILE]: {
      position: 'absolute',
      top: 16,
      right: 16,
    },
  },
  evalInfoSkeleton: {
    width: 200,
  },
  evalStatusInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: 5,
  },
  pendingIcon: {
    color: COLORS.TEXT.DISABLED,
  },
  datesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    border: '1px solid white',
    borderRadius: 8,
    height: 43,
    cursor: 'pointer',
    backgroundColor: COLORS.WHITE,
    color: COLORS.ACTION.PRIMARY,
  },
  calendarIconContainer: {
    '& svg': {
      width: 22,
      height: 'auto',
      fill: COLORS.WHITE,
    },
  },
  dateText: {
    display: 'flex',
    width: 151,
    alignItems: 'center',
    '& .react-datepicker-popper': {
      zIndex: 10,
    },
  },
  evalMonth: {
    fontWeight: '500',
    border: 'none',
    width: '100%',
    backgroundColor: 'transparent',
    outline: 'none',
    color: COLORS.ACTION.PRIMARY,
    cursor: 'pointer',
  },
  closeEvalMonth: {
    color: COLORS.WHITE,
  },
  evalMonthSimple: {
    fontWeight: '500',
    color: COLORS.TEXT.PRIMARY,
  },
});
