import { UserByEvalIdData } from '@modules/EvaluationModule/interfaces/UserByEvalIdData';
import { EvalAndFeedbackId } from '@modules/EvaluationModule/interfaces/EvalAndFeedbackId';
import { createAction } from '@reduxjs/toolkit';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { ClientByEvalIdData } from '@modules/EvaluationModule/interfaces/ClientByEvalIdData';

export const addPeerFeedbackAction = createAction<UserByEvalIdData[]>('evalSettingsPage/addPeerFeedbackAction');
export const deletePeerFeedbackAction = createAction<{ feedbackId: string; evalId: string; user: UserInfo }>(
  'evalSettingsPage/deletePeerFeedbackAction'
);
export const getClientsSuggestionsAction = createAction<string>('evalSettingsPage/getClientsSuggestionsAction');
export const getPeerSuggestionsAction = createAction<string>('evalSettingsPage/getPeerSuggestionsAction');
export const addClientToReviewersAction = createAction<ClientByEvalIdData>(
  'evalSettingsPage/addClientToReviewersAction'
);
export const deleteClientFeedbackAction = createAction<EvalAndFeedbackId>(
  'evalSettingsPage/deleteClientFeedbackAction'
);
