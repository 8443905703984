import React, { FC, memo } from 'react';
import { User } from '@shared/interfaces/user';
import { useStyles } from './CurrentUserMenu.styles';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { paths } from '@shared/enums/paths';

interface Props {
  user: User;
  isTabletMenuOpen?: boolean;
}

const CurrentUserMenuComponent: FC<Props> = ({ user, isTabletMenuOpen }) => {
  const styles = useStyles();

  const avatarSrc =
    user.imageUrl ||
    `https://eu.ui-avatars.com/api/?background=ffd9a3&color=000&name=${user.name.split(' ').join('+')}`;

  return (
    <Link className={styles.link} to={`${paths.users}/${user?.id}`}>
      <div className={styles.root}>
        <RockyAvatar medium imageUrl={avatarSrc} fullName={user.name || ''}></RockyAvatar>
        <div className={classNames(styles.userInfoContainer, { [styles.shown]: isTabletMenuOpen })}>
          <p className={styles.name}>{user.name}</p>
          <p className={styles.role}>{user.position}</p>
        </div>
      </div>
    </Link>
  );
};

export const CurrentUserMenu = memo(CurrentUserMenuComponent);
