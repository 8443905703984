import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '1400px',
    paddingBottom: 24,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    margin: '24px auto',

    [BREAKPOINTS.DESKTOP13]: {
      margin: 0,
      borderRadius: 0,
    },

    [BREAKPOINTS.MOBILE]: {
      height: 'auto',
    },
  },
  contentHolder: {
    width: '100%',
  },
  block: {
    padding: '16px 24px',
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',

    '& h2': {
      fontSize: 24,
      fontWeight: 500,
      margin: 0,
    },
  },
  loader: {
    borderRadius: 15,
    maxWidth: 1024,
    width: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '5% 0px',
  },
});
