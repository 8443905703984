import httpClient from '@shared/api/httpClient';
import { apiUrl } from '@configs/environment';
import { MyTeamEvalData } from '../interfaces/MyTeamEvalData';
import { FetchedMyTeamEvalData } from './types';
import { evaluationRescheduleHistoryMapFunc } from '../helpers/mapFunctions/evaluationRescheduleHistoryMapFunc';
import getEvaluationPermissions from './helpers/getEvaluationPermissions';
import { AllFlagsDataFilters } from '../pages/MyPeopleEvalPage/interfaces/MyPeopleEvalsDataFilters';
import { clientReviewerParser, peerReviewerParser } from './helpers/responseParsers';

export const fetchMyPeopleEvalData = (payload: AllFlagsDataFilters): Promise<MyTeamEvalData[]> =>
  httpClient
    .get<FetchedMyTeamEvalData>(`${apiUrl}/evaluations/my-team`, {
      params: { limit: payload.limit, offset: payload.offset, status: payload.status },
    })
    .then((resp) => {
      return resp.data.data
        .filter((user) => user.primary_reports_to)
        .map(
          ({
            imageUrl,
            email,
            full_name,
            status,
            evaluation_id,
            evaluation_date,
            position,
            id,
            is_submitted_by_evaluator,
            is_submitted_by_evaluatee,
            google_calendar_event,
            primary_reports_to,
            country,
            city,
            is_primary_reporter,
            evaluation_reschedulings,
            type_of_interaction,
            peer_reviewers,
            client_reviewers,
            meta: { permissions },
            has_feedbacks,
            is_extendable,
          }) => ({
            email,
            name: full_name,
            userId: id,
            evaluationId: evaluation_id,
            imageUrl,
            position,
            status,
            evaluationDate: evaluation_date,
            isSubmittedByEvaluator: is_submitted_by_evaluator,
            isSubmittedByEvaluatee: is_submitted_by_evaluatee,
            googleCalendarEvent: google_calendar_event
              ? {
                  ...google_calendar_event,
                  attendees: google_calendar_event.attendees.map((attendee) => {
                    return {
                      name: attendee.full_name,
                      email: attendee.email,
                      id: attendee.id,
                      imageUrl: attendee.imageUrl,
                      position: attendee.position,
                      city: attendee.city,
                      country: attendee.country,
                    };
                  }),
                }
              : null,
            primaryReportsTo: {
              id: primary_reports_to.id,
              email: primary_reports_to.email,
              name: primary_reports_to.full_name,
              imageUrl: primary_reports_to.imageUrl,
            },
            country: country,
            city: city,
            peerReviews: peer_reviewers?.map(peerReviewerParser) || null,
            clientReviews: client_reviewers?.map(clientReviewerParser) || null,
            isPrimaryReporter: is_primary_reporter,
            permissions: getEvaluationPermissions(permissions).evaluation,
            evaluationRescheduleHistory: evaluation_reschedulings
              ? evaluationRescheduleHistoryMapFunc(evaluation_reschedulings)
              : [],
            typeOfInteraction: type_of_interaction,
            hasFeedbacks: has_feedbacks,
            isExtendable: is_extendable,
          })
        );
    });
