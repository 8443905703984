import { RecipientData } from '@modules/HappinessModule/interfaces/RecipientData';
import { UserFlagCcDetails } from '@modules/HappinessModule/interfaces/UserFlagCcDetails';
import { CC_RECIPIENTS, CC_BY_GEOGRAPHY } from '../constants/CcInfo';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { UserMainData } from '@shared/interfaces/user';
import { AutomaticFlagWatchers } from '@modules/HappinessModule/interfaces/FlagWatcherData';

export const ccEmailsGenerate = ({
  userFlagCcDetails,
  reportsTo,
  flagAuthor,
  flagFor,
  userAutomaticFlagWatchers,
}: {
  userFlagCcDetails: UserFlagCcDetails;
  reportsTo: UserInfo | null;
  flagAuthor: UserInfo;
  flagFor: UserMainData;
  userAutomaticFlagWatchers: AutomaticFlagWatchers;
}): RecipientData[] => {
  const ccRecipients: RecipientData[] = [CC_RECIPIENTS.peopleRelations];
  const { geography } = flagFor;

  if (userFlagCcDetails?.isEngineering) {
    ccRecipients.push(CC_RECIPIENTS.flags);
  }

  if (geography) {
    const hrCcByGeography = CC_BY_GEOGRAPHY[geography.toUpperCase() as keyof typeof CC_BY_GEOGRAPHY];
    if (hrCcByGeography) {
      ccRecipients.push(hrCcByGeography);
    }
  }

  if (
    userFlagCcDetails.creativeDirectorDesignStudio1?.email &&
    userFlagCcDetails.creativeDirectorDesignStudio2?.email
  ) {
    [userFlagCcDetails.creativeDirectorDesignStudio2, userFlagCcDetails.creativeDirectorDesignStudio1].forEach(
      (creativeDirector) => {
        ccRecipients.push({
          userEmail: creativeDirector.email ?? '',
          isCarbonCopy: true,
          user: creativeDirector,
        });
      }
    );
  }

  if (reportsTo?.email && flagAuthor.id !== reportsTo?.id) {
    ccRecipients.push({
      userEmail: reportsTo.email,
      description: 'Reporting to',
      isCarbonCopy: false,
      user: reportsTo,
    });
  }

  if (flagAuthor.email) {
    ccRecipients.push({
      userEmail: flagAuthor.email,
      description: 'Flag author',
      isCarbonCopy: false,
      user: flagAuthor,
    });
  }

  if (userFlagCcDetails?.portfolioDeliveryManager?.email) {
    ccRecipients.push({
      userEmail: userFlagCcDetails.portfolioDeliveryManager.email,
      isCarbonCopy: true,
      user: userFlagCcDetails.portfolioDeliveryManager,
      description: 'Portfolio delivery manager',
    });
  }

  if (userAutomaticFlagWatchers.seniorManagers.length > 0) {
    userAutomaticFlagWatchers.seniorManagers
      .filter(({ user }) => user.isDeliveryManager)
      .forEach((deliveryManager) => {
        deliveryManager.user.email &&
          ccRecipients.push({
            userEmail: deliveryManager.user.email,
            isCarbonCopy: true,
            user: deliveryManager.user,
            description: 'Delivery manager',
          });
      });
  }

  const ccRecipientsWithoutFlagFor = ccRecipients.filter((item) => item.user?.id !== flagFor.id);

  return ccRecipientsWithoutFlagFor;
};
