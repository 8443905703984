import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';

import { State } from './types';
import { SLICE_NAME } from './constants';

const initialState: State = {
  allDepartments: { data: null, state: DataState.Idle },
  hiddenDepartments: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setAllDepartments(state, { payload }) {
      state.allDepartments = payload;
    },
    setHiddenDepartments(state, { payload }: PayloadAction<State['hiddenDepartments']>) {
      state.hiddenDepartments = payload;
    },
  },
});

export const { setAllDepartments, setHiddenDepartments } = slice.actions;

export default slice.reducer;
