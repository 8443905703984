import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  detailView: {
    right: 0,
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    backgroundColor: COLORS.WHITE,
    boxShadow: '0 -2px 8px -4px hsl(228deg 27% 50% / 40%)',
    pointerEvents: 'all',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
      bottom: 0,
      left: 4,
      right: 4,
      height: 'calc(100vh - 64px)',
      position: 'absolute',
      zIndex: 2,
      borderRadius: '8px 8px 0 0',
      boxShadow: '0 4px 10px 4px hsl(228deg 27% 50% / 40%)',
    },
  },
  mainInfo: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    rowGap: 0,
    padding: '30px 16px 16px',
  },
  avatar: {
    width: 96,
    height: 96,
    borderRadius: 28,
  },
  mainText: {
    margin: '8px 0 4px',
    fontSize: '18px',
    fontWeight: '500',
    lineHeight: '120%',
    textAlign: 'center',
    textDecorationThickness: '1px',
    textUnderlinePosition: 'from-font',
  },
  secondaryText: {
    fontSize: '13px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
  },
  primaryText: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.PRIMARY,
  },
  divider: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    margin: '0 24px',
  },
  detailInfo: {
    width: 300,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
    rowGap: 24,
    padding: '16px 8px 16px 24px',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },
  infoRow: {
    display: 'flex',
    columnGap: 16,
  },
  infoRowIcon: {
    '& svg': {
      fill: COLORS.TEXT.DISABLED,
    },
  },
  infoRowDetail: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
  },
  closeBtn: {
    position: 'absolute',
    top: 15,
    right: 20,
    fontSize: '24px',
    cursor: 'pointer',
    width: 13,
    height: 'auto',

    '& svg': {
      fill: COLORS.TEXT.DISABLED,
    },
  },
  textAlignCenter: {
    textAlign: 'center',
  },
  projectName: {
    margin: '12px 0 8px',
    fontSize: '14px',
    ':first-child > &': {
      marginTop: 0,
    },
  },
  roleBlock: {
    margin: '-8px 0 8px',
    fontSize: '13px',
    color: COLORS.TEXT.SECONDARY,
    display: 'flex',
    gap: 6,
    alignItems: 'baseline',
  },
  roleIconHolder: {
    width: 12,
    transform: ' translateY(1px)',
  },
  showMoreButton: {
    background: 'none',
    marginTop: 12,
    padding: 0,
    border: 'none',
    color: COLORS.ACTION.PRIMARY,
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  reportIssue: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: 13,
    textDecoration: 'underline',

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
      cursor: 'pointer',
    },
  },
  linkBlock: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  sabbaticalAlert: {
    margin: '0.5rem',
    alignSelf: 'stretch',
    backgroundColor: COLORS.HIGHLIGHTS.PROMINENT,
    '& p': {
      color: COLORS.TEXT.SECONDARY,
    },
  },
  reportingToLink: {
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
      textDecoration: 'underline',
    },
  },
});
