import React, { FC, memo } from 'react';
import { useStyles } from './InfoView.styles';
import classNames from 'classnames';

interface Props {
  text?: string;
  icon?: string;
  className?: string;
  inComponent?: boolean;
  children?: React.ReactNode;
}

const InfoViewComponent: FC<Props> = ({ icon, text, className, inComponent, children }) => {
  const styles = useStyles();

  return (
    <div className={classNames(styles.contentBlock, className, { [styles.inComponent]: inComponent })}>
      {children ? (
        children
      ) : (
        <>
          <img src={icon} />
          <div className={styles.infoText}>{text}</div>
        </>
      )}
    </div>
  );
};

export const InfoView = memo(InfoViewComponent);
