import { AutomaticFlagWatchers, FlagWatcherData } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { UserFlagCcDetails } from '@modules/HappinessModule/interfaces/UserFlagCcDetails';
import { FLAG_WATCHERS_READ_UPDATE_PERMISSIONS } from '../constants/constants';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { FlagWatcherRole } from '../enums/flagWatcherRole';
import { AutomaticFlagWatchersGroups } from '../enums/automaticFlagWatchersGroups';

export const getCcFlagWatchers = ({
  userFlagCcDetails,
  flagForId,
}: {
  userFlagCcDetails: UserFlagCcDetails | null;
  flagForId: string;
}): FlagWatcherData[] => {
  const ccFlagWatchers: FlagWatcherData[] = [
    !userFlagCcDetails?.creativeDirectorDesignStudio1
      ? null
      : {
          user: userFlagCcDetails?.creativeDirectorDesignStudio1,
          flagWatcherRole: FlagWatcherRole.CreativeDirectorDesignStudio1,
          canBeRemoved: false,
          isAutoCc: true,
          permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
        },
    !userFlagCcDetails?.creativeDirectorDesignStudio2
      ? null
      : {
          user: userFlagCcDetails?.creativeDirectorDesignStudio2,
          flagWatcherRole: FlagWatcherRole.CreativeDirectorDesignStudio2,
          canBeRemoved: false,
          isAutoCc: true,
          permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
        },
    !userFlagCcDetails?.portfolioDeliveryManager
      ? null
      : {
          user: userFlagCcDetails?.portfolioDeliveryManager,
          flagWatcherRole: FlagWatcherRole.PortfolioDeliveryManager,
          canBeRemoved: false,
          isAutoCc: true,
          permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
        },
  ].filter((item) => item !== null) as FlagWatcherData[];

  const ccFlagWatchersWithoutFlagFor = ccFlagWatchers.filter(({ user }) => flagForId !== user.id);

  return ccFlagWatchersWithoutFlagFor;
};

const getGroupForFlagWatcherAlreadyExistingInAutomaticFlagWatchers = ({
  flagWatcher,
  userAutomaticFlagWatchers,
}: {
  flagWatcher: FlagWatcherData;
  userAutomaticFlagWatchers: AutomaticFlagWatchers;
}): AutomaticFlagWatchersGroups | null => {
  if (flagWatcher.flagWatcherRole !== FlagWatcherRole.ReportingTo) {
    return null;
  }
  let group: AutomaticFlagWatchersGroups | null = null;
  Object.entries(userAutomaticFlagWatchers).forEach(([key, value]) => {
    if (key === AutomaticFlagWatchersGroups.MainFlagWatchers) {
      return;
    }
    if (value.some(({ user }) => user.id === flagWatcher.user?.id)) {
      group = key as AutomaticFlagWatchersGroups;
    }
  });

  return group;
};

export const getAutomaticFlagWatchers = ({
  automaticFlagWatchersFromBE,
  userFlagCcDetails,
  reportsTo,
  flagAuthor,
  manuallyAddedFlagWatchers,
  flagForId,
}: {
  automaticFlagWatchersFromBE: AutomaticFlagWatchers;
  userFlagCcDetails: UserFlagCcDetails;
  reportsTo: UserInfo | null;
  flagAuthor: UserInfo;
  manuallyAddedFlagWatchers: FlagWatcherData[];
  flagForId: string;
}): AutomaticFlagWatchers => {
  const flagAuthorIsReportsTo = reportsTo?.id === flagAuthor?.id;
  const ccFlagWatchers = getCcFlagWatchers({ userFlagCcDetails, flagForId });

  const reportsToFlagWatcher: FlagWatcherData | null =
    !flagAuthorIsReportsTo && reportsTo
      ? {
          user: reportsTo,
          permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
          canBeRemoved: true,
          flagWatcherRole: FlagWatcherRole.ReportingTo,
        }
      : null;

  const mainBasicFlagWatchers: FlagWatcherData[] = [
    {
      user: flagAuthor,
      flagWatcherRole: flagAuthorIsReportsTo ? FlagWatcherRole.FlagAuthorAndReportingTo : FlagWatcherRole.FlagAuthor,
      canBeRemoved: false,
      permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
    },
    reportsToFlagWatcher
      ? {
          ...reportsToFlagWatcher,
          automaticFlagWatcherGroupName: getGroupForFlagWatcherAlreadyExistingInAutomaticFlagWatchers({
            flagWatcher: reportsToFlagWatcher,
            userAutomaticFlagWatchers: automaticFlagWatchersFromBE,
          }),
        }
      : null,
  ].filter((item) => item !== null) as FlagWatcherData[];

  const mainFlagWatchers = [
    ...mainBasicFlagWatchers,
    ...ccFlagWatchers.filter(
      ({ user }) => !mainBasicFlagWatchers.some(({ user: basicUser }) => basicUser.id === user.id)
    ),
  ].filter((item) => item !== null) as FlagWatcherData[];

  let automaticFlagWatchers: AutomaticFlagWatchers = {
    ...automaticFlagWatchersFromBE,
    mainFlagWatchers,
  };

  if (manuallyAddedFlagWatchers) {
    automaticFlagWatchers = Object.entries(automaticFlagWatchers).reduce((result, [key, value]) => {
      if (key === AutomaticFlagWatchersGroups.MainFlagWatchers) {
        result[key] = value;
      } else {
        result[key] = value
          .filter((item): item is FlagWatcherData => item !== null)
          .filter(
            ({ user }) =>
              !manuallyAddedFlagWatchers.some(({ user: manuallyAddedUser }) => manuallyAddedUser.id === user.id) &&
              !mainBasicFlagWatchers.some(({ user: basicUser }) => basicUser.id === user.id)
          );
      }
      return result;
    }, {} as AutomaticFlagWatchers);
  }

  return automaticFlagWatchers;
};
