import { useDispatch } from 'react-redux';
import { useJsApiLoader } from '@react-google-maps/api';
import { useEffect } from 'react';
import { setIsGoogleApiLoaded } from '../redux/googleApi/reducers';
import type { LoadScriptProps } from '@react-google-maps/api';

const LIBRARIES_TO_LOAD_FROM_GOOGLE_API: LoadScriptProps['libraries'] = ['places'];

export const useGoogleApi = (): void => {
  const dispatch = useDispatch();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY ?? '',
    libraries: LIBRARIES_TO_LOAD_FROM_GOOGLE_API,
  });

  useEffect(() => {
    if (isLoaded) {
      dispatch(setIsGoogleApiLoaded(isLoaded));
    }
  }, [isLoaded]);
};
