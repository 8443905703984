import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { useStyles } from './MobileSort.styles';
import ArrowUpwardRoundedIcon from '@mui/icons-material/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@mui/icons-material/ArrowDownwardRounded';
import { SortDirection } from '@shared/enums/sortDirection';
import classNames from 'classnames';

interface Props {
  sortInfo: SortInfo;
  tableHeaders: { title?: string; fieldName?: string }[];
  onSortClick: (sortInfo: SortInfo) => void;
}

const MobileSortComponent: FC<Props> = ({ tableHeaders, sortInfo, onSortClick }) => {
  const [isMobileSortMenuOpen, setIsMobileSortMenuOpen] = useState(false);
  const styles = useStyles();
  const headersToDisplay = tableHeaders.filter(({ fieldName }) => fieldName);

  useEffect(() => {
    document.body.style.overflow = isMobileSortMenuOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileSortMenuOpen]);

  const handleSortClick = useCallback(
    (fieldName) => {
      onSortClick({
        field: fieldName,
        sortDirection:
          sortInfo.field === fieldName
            ? sortInfo.sortDirection === SortDirection.Desc
              ? SortDirection.Asc
              : SortDirection.Desc
            : SortDirection.Asc,
      });
      setIsMobileSortMenuOpen(false);
    },
    [sortInfo, onSortClick]
  );

  const handleMobileSortClick = useCallback(() => {
    setIsMobileSortMenuOpen(true);
  }, [setIsMobileSortMenuOpen]);

  const handleMobileSortCloseClick = useCallback(() => {
    setIsMobileSortMenuOpen(false);
  }, [setIsMobileSortMenuOpen]);

  const sortArrow =
    sortInfo.sortDirection === SortDirection.Asc ? (
      <ArrowUpwardRoundedIcon color="disabled" fontSize="small" />
    ) : (
      <ArrowDownwardRoundedIcon color="disabled" fontSize="small" />
    );

  return (
    <>
      <div className={styles.root} onClick={handleMobileSortClick}>
        <h2 className={styles.sortField}>
          {tableHeaders.find(({ fieldName }) => sortInfo.field === fieldName)?.title}
        </h2>
        {sortArrow}
      </div>
      {isMobileSortMenuOpen && (
        <div className={styles.overlay}>
          <div className={styles.overlayFill} onClick={handleMobileSortCloseClick}></div>
          <div className={styles.sortContainer}>
            <div className={styles.sortHeaderHolder}>
              <h1 className={styles.sortTitle}>Sort by</h1>
              <div className={styles.close} onClick={handleMobileSortCloseClick}></div>
            </div>
            <div className={styles.sortItemsContainer}>
              {headersToDisplay.map(({ title, fieldName }, i) => (
                <div
                  key={i}
                  className={classNames(styles.sortItem, { [styles.active]: fieldName === sortInfo.field })}
                  onClick={() => handleSortClick(fieldName)}
                >
                  <p className={styles.fieldTitle}>{title}</p>
                  {fieldName === sortInfo.field && sortArrow}
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const MobileSort = memo(MobileSortComponent);
