import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  goalItem: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    transition: 'all 100ms linear',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& td': {
      padding: '16px',
      verticalAlign: 'middle',
      [BREAKPOINTS.MOBILE]: {
        verticalAlign: 'top',
      },
    },
    '& td:first-child': {
      paddingLeft: '24px',
    },
    '& td:last-child': {
      paddingRight: '24px',
    },
    '&:hover': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
      cursor: 'pointer',
    },
    [BREAKPOINTS.MOBILE]: {
      '& td:first-child': {
        paddingLeft: '16px',
      },
      '& td:last-child': {
        paddingRight: '16px',
      },
    },
  },
  goalItemSmall: {
    '& td': {
      padding: '16px 8px',
      verticalAlign: 'top',
    },
    '& td:first-child': {
      paddingLeft: '8px',
    },
    '& td:last-child': {
      paddingRight: '8px',
    },
  },
  goalCategory: {
    color: COLORS.TEXT.SECONDARY,
  },
  goalCompletenessSmall: {
    width: '80px',
    textAlign: 'right',
  },
  goalCompletenessBadge: {
    display: 'inline-block',
    borderRadius: 20,
    padding: '4px 12px 5px',
    margin: '-4px 0 -5px',
    fontWeight: 500,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    color: COLORS.WHITE,
  },
  goalDateDueSmall: {
    width: '90px',
  },
  goalStatusProgress: {
    color: COLORS.ACTION.PRIMARY,
  },
  goalStatusAchieved: {
    color: COLORS.STATUS.SUCCESS,
  },
});
