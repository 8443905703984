import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

const INPUT_DEBOUNCE_MS = 500;

export const useSearchFunctions = (
  querySearchAction: ActionCreatorWithPayload<string>,
  resetSearchResultAction: ActionCreatorWithPayload<void>
): {
  inputValue: string;
  handleInputChange: (value: string) => void;
} => {
  const [inputValue, setInputValue] = useState('');
  const dispatch = useDispatch();

  const debouncedQuerySearch = useCallback(
    debounce((value) => {
      dispatch(querySearchAction(value));
    }, INPUT_DEBOUNCE_MS),
    []
  );

  useEffect(() => {
    if (inputValue.length > 1) {
      debouncedQuerySearch(inputValue);
    } else {
      debouncedQuerySearch.cancel();
      dispatch(resetSearchResultAction());
    }
  }, [inputValue]);

  const handleInputChange = useCallback((value) => {
    setInputValue(value);
  }, []);

  return {
    inputValue,
    handleInputChange,
  };
};
