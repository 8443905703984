import { put, all, call, takeLatest, select, takeEvery } from 'redux-saga/effects';
import {
  getUserEvalData,
  setUserEvalDataLoading,
  setUserEvalData,
  startEvalOnUserEvalPage,
  failEopOnUserEvalPage,
} from './actions';
import { DataState } from '@shared/enums/DataState';
import { UserEvalPageData } from '@modules/EvaluationModule/interfaces/UserEvalPageData';
import { fetchUserEvalData } from '@modules/EvaluationModule/api/userEvalData';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { setCurrentlyStartedEvalId, setFailingEopId } from './reducers';
import { RootState } from '@modules/App/redux/store';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { EvaluationsHistoryDataItem } from '@modules/EvaluationModule/interfaces/EvaluationsHistoryDataItem';
import { startSelectedEvals } from '@modules/EvaluationModule/api/startSelectedEvals';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { patchCloseEval, patchEvalEopStatus } from '@modules/EvaluationModule/api/evalPageData';
import { EopResult } from '@modules/EvaluationModule/enums/EopResult';

function* getUserEvalDataAsync({ payload }: ReturnType<typeof getUserEvalData>) {
  try {
    const setLoadingAction = setUserEvalDataLoading();
    yield put(setLoadingAction);
    const response: UserEvalPageData = yield call(fetchUserEvalData, payload);
    response.history = [...response.history].sort((a, b) => (a.date > b.date ? -1 : 1));
    const action = setUserEvalData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUserEvalData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* setStartEvalAsync({ payload: evalIdToStart }: ReturnType<typeof startEvalOnUserEvalPage>) {
  try {
    yield put(setCurrentlyStartedEvalId(evalIdToStart));
    yield call(startSelectedEvals, { evaluationId: evalIdToStart });
    yield put(setCurrentlyStartedEvalId(undefined));
    const previousEvalData: UserEvalPageData = yield select(
      (state: RootState) => state.evaluation.userEvalPage.userEvalData.data
    );
    const newEvalData = {
      ...previousEvalData,
      history: previousEvalData.history.map((evaluation: EvaluationsHistoryDataItem) => ({
        ...evaluation,
        status: evaluation.evaluationId === evalIdToStart ? EvaluationStatus.Ongoing : evaluation.status,
      })),
    };
    const startedEval = newEvalData.history.find(({ evaluationId }) => evalIdToStart.includes(evaluationId));
    if (startedEval) {
      window.location.href = `/users/${previousEvalData.user.id}/evaluations/${startedEval.evaluationId}`;
    }
    const action = setUserEvalData({ data: newEvalData, state: DataState.Fulfilled });
    yield put(action);
  } catch {
    yield put(setCurrentlyStartedEvalId(null));
    yield put(displayNotification('Failed to start the evaluation 🥺'));
  }
}

function* failEopAsync({ payload }: ReturnType<typeof failEopOnUserEvalPage>) {
  try {
    yield put(setFailingEopId(payload.evalId));

    yield call(patchEvalEopStatus, payload.evalId, {
      eopStatus: EopResult.NotPassed,
    });
    yield call(patchCloseEval, payload.evalId, { notes: '', goals: '' });

    yield put(getUserEvalData(payload.userId));

    yield put(displayNotification('This evaluation is now closed 🎉'));
  } catch {
    yield put(displayNotification("Sorry... This evaluation can't be closed 🤧"));
  }
  yield put(setFailingEopId(''));
}

function* watchGetUserEvalData() {
  yield takeLatest(startEvalOnUserEvalPage.type, setStartEvalAsync);
  yield takeLatest(getUserEvalData.type, getUserEvalDataAsync);
}

function* watchFailEop() {
  yield takeEvery(failEopOnUserEvalPage.type, failEopAsync);
}

export function* userEvalPageSaga(): Generator {
  yield all([watchGetUserEvalData(), watchFailEop()]);
}
