import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  datePicker: {
    padding: '6px 8px 8px 12px',
    backgroundColor: COLORS.ACTION.HIGHLIGHT,
    color: COLORS.ACTION.PRIMARY,
    borderRadius: 4,
    height: 32,
    maxWidth: 200,
    width: 'fit-content',
    cursor: 'pointer',

    '& .MuiInputBase-root': {
      all: 'unset',
      height: '100%',
      width: '100%',
      display: 'flex',
      gap: 14,
      alignItems: 'center',

      '&:before': {
        all: 'unset',
      },
      '&:after': {
        all: 'unset',
      },
    },
    '& .MuiInputBase-input': {
      all: 'unset',
      display: 'block',
      height: '100%',
      width: '100%',
      fontSize: 13,
      fontWeight: 500,
    },
    '& .MuiInputAdornment-root': {
      all: 'unset',
      width: 24,

      '& svg': {
        width: 20,
        height: 20,
      },
    },
    '& .MuiButtonBase-root': {
      all: 'unset',
      width: ' 100%',
      display: 'flex',
      alignItems: 'center',
    },
  },
  datePickerDisabled: {
    backgroundColor: COLORS.TEXT.DISABLEDBG,
    pointerEvents: 'none',
    cursor: 'default',
    color: COLORS.TEXT.DISABLED,
  },
  datePickerLoading: {
    '& .MuiInputAdornment-root': {
      '& svg': {
        width: 24,
        height: 24,
        fill: 'currentColor',
      },
    },
  },
});
