import React, { FC } from 'react';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { Link, Typography } from '@mui/material';
import { getLinkToZohoRecruitUserProfile } from '../../helpers/getLinkToZohoRecruitUserProfile';

interface Props {
  user: UserInfo;
}

export const ZohoRecruitLink: FC<Props> = ({ user }) => {
  return (
    <Typography variant="body2">
      <Link target="_blank" href={getLinkToZohoRecruitUserProfile(user?.zohoRecruitId ?? '')}>
        {user.name} profile in Zoho Recruit <sup>↗</sup>
      </Link>
    </Typography>
  );
};
