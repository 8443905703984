import { ErrorInfo } from '@shared/interfaces/ErrorInfo';
import axios, { AxiosError } from 'axios';

export const getErrorInfo = (error: unknown): ErrorInfo | undefined => {
  if (!axios.isAxiosError(error)) {
    return undefined;
  }
  const err = error as AxiosError;
  const errorMessage: string = err.response?.data.Message || err.response?.data.message;
  const errorCode = err.response?.status;
  return { code: errorCode, message: errorMessage };
};
