import { paths } from '@shared/enums/paths';
import { Role } from '@shared/enums/role';

export const pageAccessibilityByRoles = {
  [paths.clientContacts]: [
    Role.clientPartner,
    Role.portfolioDeliveryManager,
    Role.deliveryManager,
    Role.projectManager,
  ],
  [paths.clientSurveys]: [Role.csatMember, Role.deliveryManager, Role.portfolioDeliveryManager],
  [paths.myPeopleEvals]: [Role.member],
  [paths.surveyManagement]: [Role.admin],
  [paths.surveyHistory]: [Role.admin],
};
