import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setPeerEvalData } from './actions';

import { State } from './types';

const initialState: State = {
  peerEvalData: { data: null, state: DataState.Pending },
  permissions: {
    data: null,
    state: DataState.Pending,
  },
};

export const slice = createSlice({
  name: 'peerEvalPage',
  initialState,
  reducers: {
    setPeerEvalDataLoading(state) {
      state.peerEvalData = { data: null, state: DataState.Pending };
    },
    setPeerEvalSubmitted(state) {
      if (!state.peerEvalData.data) {
        return;
      }
      state.peerEvalData.data.status = EvalFeedbackStatus.Review;
    },
    setPeerEvalFeedbackPermissions(state, { payload }) {
      state.permissions = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setPeerEvalData, (state, { payload }) => {
      state.peerEvalData = payload;
    });
  },
});

export const { setPeerEvalDataLoading, setPeerEvalSubmitted, setPeerEvalFeedbackPermissions } = slice.actions;

export default slice.reducer;
