import { RootState } from '@modules/App/redux/store';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { SurveyQueue } from '../interfaces';

export const surveyQueueRelector = (state: RootState): AsyncData<SurveyQueue> =>
  state.happiness.surveyManagementPage.surveyQueue;

export const runSurveyStatusSelector = (state: RootState): DataState =>
  state.happiness.surveyManagementPage.runSurveyStatus;
