import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  meetingInfoButton: {
    columnGap: 4,
  },
  userInMetting: {
    padding: '10px 20px',
    transition: 'all 0.1s ease-out',
    cursor: 'pointer',
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    height: 60,
    borderBottom: `1px solid ${COLORS.ACTION.HIGHLIGHT}`,
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,
    outline: 'none',

    '&:hover': {
      background: COLORS.HIGHLIGHTS.NEUTRAL,
    },
  },
  popup: {
    '&-content': {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
      minWidth: 'fit-content',
      padding: '0',
    },
    '&-arrow': {
      display: 'none',
    },
  },
  skeleton: {
    width: 100,
  },
  moreArrow: {
    fill: COLORS.ACTION.PRIMARY,
    stroke: COLORS.ACTION.PRIMARY,
    width: '12px',
    height: '12px',
  },
  rotateArrow: {
    fill: COLORS.ACTION.PRIMARY,
    stroke: COLORS.ACTION.PRIMARY,
    width: '12px',
    height: '12px',
    transform: 'rotate(180deg) translateY(2px)',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
  },
  iconText: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -52%)',
    zIndex: 2,
    color: COLORS.ACTION.PRIMARY,
    fontSize: '7px',
    fontWeight: '700',
  },
  scheduleLink: {
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    columnGap: 4,
    color: 'inherit',
  },
  iconTextSmall: {
    fontSize: 7,
  },
});
