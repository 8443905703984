import React, { SyntheticEvent } from 'react';
import { useStyles } from './FiltersAutocomplete.styles';
import { Field, FormikProps } from 'formik';
import { Autocomplete, TextField, TextFieldProps } from '@mui/material';

interface Props {
  name: string;
  value: string;
  handleChange: (fieldName: string, fieldValue: string) => void;
  options: string[];
  label: string;
}

export const FiltersAutocomplete: React.FC<Props> = ({ name, value, handleChange, options, label }) => {
  const styles = useStyles();

  return (
    <Field
      name={name}
      component={Autocomplete}
      value={value}
      onChange={(_: SyntheticEvent, newValue: string) => handleChange(name, newValue)}
      options={options}
      disableClearable
      limitTags={1}
      renderInput={(params: FormikProps<TextFieldProps>) => (
        <TextField {...params} className={styles.textField} label={label} />
      )}
    />
  );
};
