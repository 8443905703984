import { all, call, put, takeLatest } from 'redux-saga/effects';

import { DataState } from '@shared/enums/DataState';
import { setAutomaticFlagWatchers, setFlagDataState, setUserFlagCcDetails } from './reducers';
import { raiseFlagAction, editFlagAction, getUserFlagCcDetails, getAutomaticFlagWatchers } from './actions';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { Edit, fetchAutomaticFlagWatchers, flagCcDetails, raiseFlag } from '@modules/HappinessModule/api/flagData';
import { UserFlagCcDetails } from '@modules/HappinessModule/interfaces/UserFlagCcDetails';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { AutomaticFlagWatchers } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { resolveSituation } from '@modules/HappinessModule/pages/MyTeamHappinessPage/redux/actions';

function* raiseFlagAsync({ payload }: ReturnType<typeof raiseFlagAction>) {
  try {
    yield put(setFlagDataState({ loadingState: DataState.Pending }));
    yield call(raiseFlag, payload);
    yield put(setFlagDataState({ loadingState: DataState.Fulfilled }));
    if (payload.surveyMessageId) yield put(resolveSituation({ id: payload.surveyMessageId, userId: payload.user }));
  } catch {
    yield put(setFlagDataState({ loadingState: DataState.Rejected }));
    yield put(displayNotification('Something went wrong 🥺'));
  }
}

function* editFlagAsync({ payload }: ReturnType<typeof editFlagAction>) {
  try {
    yield put(setFlagDataState({ loadingState: DataState.Pending }));
    yield call(Edit, payload);
    yield put(setFlagDataState({ loadingState: DataState.Fulfilled, isNoNavigation: payload.isNoNavigation }));
  } catch (error) {
    yield put(setFlagDataState({ loadingState: DataState.Rejected }));
    yield put(displayNotification(String(getErrorInfo(error)?.message) || 'Error when editing flag 🥺'));
  }
}

function* getUserFlagCcDetailsAsync({ payload }: ReturnType<typeof getUserFlagCcDetails>) {
  try {
    const response: UserFlagCcDetails = yield call(flagCcDetails, payload);
    yield put(setUserFlagCcDetails({ data: response, state: DataState.Fulfilled }));
  } catch (error) {
    yield put(setUserFlagCcDetails({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}

function* getAutomaticFlagWatchersAsync({ payload }: ReturnType<typeof getAutomaticFlagWatchers>) {
  try {
    yield put(setAutomaticFlagWatchers({ data: null, state: DataState.Pending }));
    const response: AutomaticFlagWatchers = yield call(fetchAutomaticFlagWatchers, payload);
    yield put(setAutomaticFlagWatchers({ data: response, state: DataState.Fulfilled }));
  } catch (error) {
    yield put(setAutomaticFlagWatchers({ data: null, state: DataState.Rejected, error: getErrorInfo(error) }));
  }
}

function* watchQuerySearch() {
  yield takeLatest(raiseFlagAction.type, raiseFlagAsync);
  yield takeLatest(editFlagAction.type, editFlagAsync);
  yield takeLatest(getUserFlagCcDetails.type, getUserFlagCcDetailsAsync);
  yield takeLatest(getAutomaticFlagWatchers.type, getAutomaticFlagWatchersAsync);
}

export function* flagFormPageSaga(): Generator {
  yield all([watchQuerySearch()]);
}
