export const getNoResponseEmailLink = ({ email, name }: { email: string; name: string }): string =>
  `mailto:${email}?subject=Please respond to Rocky survey&body=Hi%20${name}%2C%0D%0ACould you please ` +
  'take a few seconds to respond to the latest Rocky survey%3F %0D%0A%0D%0AEnsuring your' +
  ' well-being at AgileEngine is a %231 priority%2C and your feedback is central to doing' +
  ' that.%0D%0A%0D%0AThe survey contains one question and takes one click to complete. Let' +
  ' me know if there are any problems with accessing the survey or if you have any questions.';

export const getLowMarkEmailLink = ({ email, name, mark }: { email: string; name: string; mark: number }): string =>
  `mailto:${email}?subject=I'm here if you need me&body=${name}%2C%0D%0ASorry to hear that things aren%E2%80%99t` +
  ` going too well. Your latest ${mark}%2F10 survey response means there is a lot we should do to` +
  ' improve your well being at AgileEngine. Let%E2%80%99s focus on what needs to be done first.' +
  '%0D%0A %0D%0AWhat are the main issues you%E2%80%99ve been facing recently and how can I help you tackle them%3F%0D%0A';
