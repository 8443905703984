import React, { FC, memo, useCallback } from 'react';
import { useStyles } from './FlagWatchersSearch.styles';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import { DataState } from '@shared/enums/DataState';
import { PeerSearchOption } from '@modules/EvaluationModule/pages/EvalSettingsPage/components/PeerSearch/components/PeerSearchOption';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { searchCustomStyles } from '@styles/SearchCustomStyles';
import { useSearchFunctions } from '@shared/helpers/useSearchFunctions';
import { useFormikContext } from 'formik';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagFormFields } from '../../../../constants/FlagFormFields';
import { loadActiveUsersList } from '@modules/App/redux/users/actions';
import { resetSearchResult } from '@modules/App/redux/users';
import { selectUsersSearchData } from '@modules/App/redux/users/selectors';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { AutomaticFlagWatchers, FlagWatcherData } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { FLAG_WATCHERS_READ_UPDATE_PERMISSIONS } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { FlagWatcherRole } from '@modules/HappinessModule/pages/FlagPage/enums/flagWatcherRole';
import { Avatar, Chip, Typography } from '@mui/material';
import blue from '@mui/material/colors/blue';
import { grey } from '@mui/material/colors';

interface Props {
  flagFor: UserInfo;
  automaticFlagWatchers: AutomaticFlagWatchers;
  engineeringManager: UserInfo | null;
}

const FlagWatchersSearchComponent: FC<Props> = ({ flagFor, automaticFlagWatchers, engineeringManager }) => {
  const styles = useStyles();
  const { inputValue, handleInputChange } = useSearchFunctions(loadActiveUsersList, resetSearchResult);
  const { setFieldValue, values } = useFormikContext<FlagFields>();

  const searchData = useSelector(selectUsersSearchData);

  const handleValueSelect = useCallback(
    (recipient) => {
      const newFlagWatcher: FlagWatcherData = {
        permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
        user: recipient as UserInfo,
        canBeRemoved: true,
        flagWatcherRole: FlagWatcherRole.Watcher,
      };
      setFieldValue(FlagFormFields.flagWatchers.name, [...values.flagWatchers, newFlagWatcher]);
    },
    [values.flagWatchers]
  );

  const allAutomaticFlagWatcherUserIds = [
    ...Object.values(automaticFlagWatchers)
      .map((automaticFlagWatcher) => automaticFlagWatcher.map((flagWatcher) => flagWatcher?.user?.id))
      .flat(),
    ...values.flagWatchers.map((flagWatcher) => flagWatcher.user?.id),
    flagFor.id,
  ];

  const searchedUsers =
    searchData.data?.filter((recipient) => !allAutomaticFlagWatcherUserIds.includes(recipient.id)) || [];

  const isEngineeringManagerAmongFlagWatchers =
    engineeringManager && values.flagWatchers.map((fw) => fw.user.id).some((fwid) => fwid === engineeringManager.id);

  const isEgineeringManagerShortcutButtonAvailable = engineeringManager && !isEngineeringManagerAmongFlagWatchers;

  return (
    <div className={styles.selectBlock}>
      <div className={styles.searchIcon}>
        <SearchRoundedIcon />
      </div>
      <Select
        styles={searchCustomStyles}
        value={[]}
        autoFocus={false}
        placeholder="Add people"
        components={{ Option: PeerSearchOption }}
        onChange={handleValueSelect}
        isLoading={searchData.state === DataState.Pending}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        noOptionsMessage={() => (inputValue === '' ? 'Start typing to search' : 'No results')}
        options={searchedUsers}
        filterOption={null}
      />
      {isEgineeringManagerShortcutButtonAvailable && (
        <Chip
          clickable
          onClick={() => {
            handleValueSelect(engineeringManager);
          }}
          onDelete={() => {
            handleValueSelect(engineeringManager);
          }}
          deleteIcon={<AddCircleOutlineIcon sx={{ fill: blue[500] }} />}
          sx={{
            borderRadius: 8,
            backgroundColor: blue[50],
            marginTop: 1,
            '&:hover': {
              backgroundColor: blue[100],
            },
          }}
          avatar={<Avatar sx={{ borderRadius: 8 }} src={engineeringManager?.imageUrl ?? ''} />}
          label={
            <>
              <Typography display="inline">{engineeringManager?.name}</Typography>
              <Typography display="inline" sx={{ color: grey[500] }}>
                , EM
              </Typography>
            </>
          }
        />
      )}
    </div>
  );
};

export const FlagWatchersSearch = memo(FlagWatchersSearchComponent);
