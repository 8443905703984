import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  backLink: {
    marginTop: 25,
  },
  root: {
    width: '100%',
    height: '100%',
    maxWidth: '1400px',
    margin: '12px auto 24px',
    paddingTop: 9,
  },
  loaderHolder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});
