import * as Sentry from '@sentry/react';
import { all, call, spawn } from 'redux-saga/effects';

import { happinessSaga } from '../../HappinessModule/redux/sagas';
import { feedbackSaga } from './feedback/sagas';
import { notificationsSaga } from './notifications/sagas';
import { userSaga } from './user/sagas';
import { evaluationSaga } from '../../EvaluationModule/redux/sagas';
import { userPageSaga } from '../pages/UserPage/redux/sagas';
import { orgChartPageSaga } from '../pages/OrgChartPage/redux/sagas';
import { userProfilePageSaga } from '../pages/UserProfilePage/redux/sagas';
import { featuresSaga } from './features/sagas';
import { permissionsSaga } from './permissions/sagas';
import { usersSaga } from './users/sagas';
import { departmentsSaga } from './departments/sagas';

export function* rootSaga(): Generator {
  const sagas = [
    happinessSaga,
    userSaga,
    permissionsSaga,
    feedbackSaga,
    notificationsSaga,
    evaluationSaga,
    userPageSaga,
    orgChartPageSaga,
    userProfilePageSaga,
    featuresSaga,
    usersSaga,
    departmentsSaga,
  ];

  yield all(
    sagas.map((saga) =>
      spawn(function* () {
        while (true) {
          try {
            yield call(saga);
            break;
          } catch (e) {
            if (e instanceof Error) {
              Sentry.captureMessage(e.message);
            }
          }
        }
      })
    )
  );
}
