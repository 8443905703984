import { Tab } from '@shared/interfaces/Tab';
import { paths } from '@shared/enums/paths';
import { EvalPermisssionsT } from '@modules/EvaluationModule/interfaces/EvalPermissions';

interface Params {
  userId: string;
  evaluationId: string;
  permissions?: EvalPermisssionsT;
  isEvalClosed: boolean;
  isEop: boolean;
  isEvalOngoing: boolean;
}
export const getEvalTabs = ({
  userId,
  evaluationId,
  permissions,
  isEvalClosed,
  isEop,
  isEvalOngoing,
}: Params): Tab[] | null => {
  const basePath = `${paths.users}/${userId}/${paths.evaluations}/${evaluationId}`;
  const evalTabs = {
    reviews: {
      linkTo: basePath,
      linkName: 'Reviews',
    },
    clientsAndPeers: {
      linkTo: `${basePath}/${paths.settings}`,
      linkName: 'Clients and peers',
    },
    summary: {
      linkTo: `${basePath}/${paths.summary}`,
      linkName: 'Summary',
    },
    endOfProbation: {
      linkTo: `${basePath}/${paths.endOfProbation}`,
      linkName: 'End of probation',
    },
  };

  let tabs: Tab[] = [evalTabs.reviews];
  if (permissions?.isUpdatable && !isEvalClosed) {
    tabs = [...tabs, evalTabs.clientsAndPeers];
  }
  if (permissions?.isUpdatable && isEop && (isEvalOngoing || isEvalClosed)) {
    tabs = [evalTabs.endOfProbation, ...tabs];
  }

  return tabs ? [...tabs, evalTabs.summary] : [evalTabs.summary];
};
