import React, { useEffect } from 'react';
import { useUserHappinessData } from './hooks/useUserHappinessData';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { ResponsesActivity } from '@modules/HappinessModule/components/ResponsesActivity/ResponsesActivity';
import { getResponseActivityTableData } from '@modules/HappinessModule/helpers/tableDataHelpers';
import { pageTitles } from '@shared/enums/pageTitles';
import { useSelector } from 'react-redux';
import { useTitle } from '@shared/hooks/useTitle';
import { userDataSelector } from '@modules/App/pages/UserPage/redux/selectors';

export const UserHappinessPage: React.FC = () => {
  const userHappinessData = useUserHappinessData();
  const tableData = userHappinessData.data ? getResponseActivityTableData(userHappinessData.data) : null;
  const isFailed = userHappinessData.state === DataState.Rejected;
  const isDataLoading = userHappinessData.state === DataState.Pending;
  const userData = useSelector(userDataSelector);
  const userName = userData?.data?.name;
  const setPageTitle = useTitle();

  useEffect(() => {
    if (userName) {
      setPageTitle(`${userName} ${pageTitles.userReportHappinessPage}`);
    }
  }, [userName]);

  return isFailed ? (
    <DataErrorView error={userHappinessData.error} />
  ) : (
    <ResponsesActivity data={tableData} isDataLoading={isDataLoading} user={userData.data} />
  );
};
