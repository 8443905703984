import React, { ReactNode, useEffect } from 'react';
import { Outlet, useSearchParams } from 'react-router-dom';
import { Enviroment } from '@shared/enums/EnvironmentNames';
import { requestCustomToken } from '@modules/App/api/requestCustomToken';
import addSeconds from 'date-fns/addSeconds';
import { EMAIL_DOMAIN } from '@shared/consts/constants';

interface Props {
  children?: ReactNode;
}

export const RoutesWrapper: React.FC<Props> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const tokenFromParams = searchParams.get('user');
    const isStagingOrLower = [Enviroment.Local, Enviroment.Develop, Enviroment.Staging].includes(
      process.env.REACT_APP_ENV as Enviroment
    );
    if (!isStagingOrLower || !tokenFromParams) {
      return;
    }
    const token = tokenFromParams.includes('@') ? tokenFromParams : `${tokenFromParams}@${EMAIL_DOMAIN}`;
    setSearchParams('');
    requestCustomToken({ email: token }).then((data) => {
      setSearchParams('');
      const now = new Date();
      const expireDate = addSeconds(now, +data.expiresIn);
      localStorage.setItem('authEmail', token);
      localStorage.setItem('authToken', data.idToken);
      localStorage.setItem('authRefresh', data.refreshToken);
      localStorage.setItem('authExpire', expireDate.toISOString());
      window.location.reload();
    });
  }, []);

  return (
    <>
      <Outlet>
        <div>{children}</div>
      </Outlet>
    </>
  );
};
