import { useCallback, useState } from 'react';

export const useModalState = (
  initialState = false
): { openModal: () => void; closeModal: () => void; isModalOpen: boolean } => {
  const [isModalOpen, setIsModalOpen] = useState(initialState);

  const openModal = useCallback(() => setIsModalOpen(true), []);
  const closeModal = useCallback(() => setIsModalOpen(false), []);

  return {
    openModal,
    closeModal,
    isModalOpen,
  };
};
