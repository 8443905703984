export enum PeopleHappinessFilterNumberFieldsNames {
  ScoreFrom = 'reactionGte',
  ScoreTo = 'reactionLte',
}

export enum PeopleHappinessFilterAnotherFieldsNames {
  IncludeNotResponded = 'includeNotResponded',
  IncludeResponded = 'includeResponded',
  UserIds = 'userIds',
  PrimaryReportsToIds = 'primaryReportsToIds',
  Departments = 'departments',
  LegalCountries = 'legalCountries',
  Resolution = 'resolution',
  GroupBy = 'groupBy',
}

export enum PeopleHappinessFilterUsersFieldsNames {
  Users = 'users',
  PrimaryReportsTos = 'primaryReportsTos',
}
