import { getCurrentToken } from '@shared/helpers/auth';
import { useAuth } from '@shared/hooks/useAuth';
import { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStyles } from './Bonuses.styles';
import { RocketLoader } from '@shared/components/RocketLoader';

const EVENT_LISTENER_TYPE = 'message';
const TOKEN_TYPE = 'token';
const REQUEST_TOKEN_TYPE = 'requestTokenFromRocky';
const BONUSES_READY = 'kpiToolBonusesReady';
const BONUS_TAB_DEFAULT_OFFSET_HEIGHT = 584;

const Bonuses: FC = () => {
  const { zohoId } = useParams();
  const styles = useStyles();
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const { currentUser } = useAuth();
  const [isIframeInit, setIsIframeInit] = useState(false);
  const [offSetHeight, setOffSetHeight] = useState(BONUS_TAB_DEFAULT_OFFSET_HEIGHT);
  const sendToken = async (refreshToken?: boolean) => {
    const iframe = iframeRef.current;
    if (iframe && iframe.contentWindow) {
      const token = await getCurrentToken(refreshToken);
      iframe.contentWindow.postMessage(
        {
          type: TOKEN_TYPE,
          token,
        },
        '*'
      );
    }
  };

  useEffect(() => {
    if (!currentUser && !isIframeInit) return;

    const messageHandler = (event: MessageEvent) => {
      if (![process.env.REACT_APP_KPI_TOOL_SITE_URL].includes(event.origin)) return;

      const { type } = event.data;

      if (type === BONUSES_READY) {
        const { height } = event.data;

        setOffSetHeight(Math.max(height, offSetHeight));
        return;
      }

      if (type === REQUEST_TOKEN_TYPE) {
        const { refresh } = event.data;

        sendToken(refresh);
      }
    };
    window.addEventListener(EVENT_LISTENER_TYPE, messageHandler);

    return () => {
      window.removeEventListener(EVENT_LISTENER_TYPE, messageHandler);
    };
  }, [currentUser, isIframeInit]);

  return (
    <div className={styles.root}>
      {!!zohoId && (
        <iframe
          ref={iframeRef}
          style={{ height: offSetHeight }}
          className={styles.iframe}
          src={`${process.env.REACT_APP_KPI_TOOL_SITE_URL}/rocky-bonuses/${zohoId}`}
          onLoad={() => setIsIframeInit(true)}
        />
      )}
      {!isIframeInit && (
        <div className={styles.loaderHolder}>
          <RocketLoader />
        </div>
      )}
    </div>
  );
};

export default Bonuses;
