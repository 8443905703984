import { ClientEvalOptionsData } from '@modules/EvaluationModule/interfaces/ClientEvalOptionsData';
import { autoSaveClientEvalForm } from '@modules/EvaluationModule/pages/ClientEvalPage/redux/actions';
import { FormikProps, useFormikContext } from 'formik';
import throttle from 'lodash/throttle';
import { FC, memo, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

const FORM_SAVE_DELAY_MS = 1500;

const ClientFormObserverComponent: FC = () => {
  const { values, dirty }: FormikProps<ClientEvalOptionsData> = useFormikContext();
  const dispatch = useDispatch();
  const { feedbackId = '' } = useParams();

  const autoSave = useCallback(
    throttle((values) => dispatch(autoSaveClientEvalForm({ feedbackId, evaluation: values })), FORM_SAVE_DELAY_MS),
    []
  );

  useEffect(() => {
    if (!values.isConfirmed && dirty) {
      autoSave(values);
    }
  }, [values]);

  return null;
};

export const ClientFormObserver = memo(ClientFormObserverComponent);
