import { FlagListData } from '@modules/HappinessModule/interfaces/FlagListData';
import React, { FC } from 'react';
import { useStyles } from './FlagDetailBlock.styles';
import { ReactComponent as RedFlagIcon } from '@assets/icons/redFlagIcon.svg';
import { ReactComponent as YellowFlagIcon } from '@assets/icons/yellowFlagIcon.svg';
import { FlagSeverity } from '@modules/HappinessModule/enums/FlagSeverity';

interface Props {
  flagData: FlagListData;
}

export const FlagDetailBlock: FC<Props> = ({ flagData }) => {
  const styles = useStyles();
  const { primaryTriggerType, isAnotherOfferInvolved, severity } = flagData;

  return (
    <div>
      <div className={styles.flagContainer}>
        <div className={styles.flagIconContainer}>
          {severity === FlagSeverity.red ? <RedFlagIcon /> : <YellowFlagIcon />}
        </div>
        <div>
          <div>{primaryTriggerType}</div>
          {isAnotherOfferInvolved && <div className={styles.flagSecondaryLabel}>Another offer involved</div>}
        </div>
      </div>
    </div>
  );
};
