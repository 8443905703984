import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './EvaluationForm.styles';
import { Formik, Form, FormikProps, FormikErrors } from 'formik';
import { EvalQuestionBlock } from './components/EvalQuestionBlock';
import { questionBlockFieldsDescriptions } from '../../../EvalPage/constants/questionBlockFieldsDescriptions';
import { RockyModal } from '@shared/components/RockyModal';
import { useModalState } from '@shared/hooks/useModalState';
import { SubmitEvalFormModalContent } from './components/SubmitEvalFormModalContent';
import isEmpty from 'lodash/isEmpty';
import { useDispatch } from 'react-redux';
import { submitEvalForm } from '@modules/EvaluationModule/pages/EvalPage/redux/actions';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { FormObserver } from './components/FormObserver/FormObserver';
import Lock from '@mui/icons-material/LockOutlined';
import { ReactComponent as LockCircle } from '@assets/icons/LockCircle.svg';
import get from 'lodash/get';
import { RockyButton } from '@shared/components/RockyButton';
import Pen from '@mui/icons-material/Edit';
import { FormExplanationLabel } from './components/FormExplanationLabel';
import { validationSchema } from './constants/validationSchema';
import { PrevEvalData } from '../../../EvalPage/components/PrevEvalData';
import { additionalFieldsDescriptions } from '@modules/EvaluationModule/pages/EvalPage/constants/additionalFieldsDescriptions';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { DataState } from '@shared/enums/DataState';
import { useAuth } from '@shared/hooks/useAuth';
import { GoalsDuringEval } from '@modules/EvaluationModule/pages/UserGoalsPage/components/GoalsDuringEval/GoalsDuringEval';
import { QuestionNamesToDisplayOnlyForEvaluatee } from './enums/QuestionNamesToDisplayOnlyForEvaluatee';

interface Props {
  evalFormPageData: AsyncData<EvalPageData>;
}

const EvaluationFormComponent: React.FC<Props> = ({ evalFormPageData }) => {
  const styles = useStyles();
  const { openModal, closeModal, isModalOpen } = useModalState();
  const formRef = useRef<FormikProps<EvaluationOptionsData>>(null);
  const [isUserConfirmed, setIsUserConfirmed] = useState(false);
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const dispatch = useDispatch();
  const { currentUser } = useAuth();

  const prevEvalData = evalFormPageData.data?.previousEvaluation;
  const userId = evalFormPageData.data?.userId;
  const isCurrentUserEvaluator = currentUser?.id === evalFormPageData.data?.owner.id;
  const isLoading = evalFormPageData.state === DataState.Pending;
  const evalFormFields = isCurrentUserEvaluator
    ? evalFormPageData.data?.evaluation.evaluator
    : evalFormPageData.data?.evaluation.evaluatee;
  const confirmFormSubmit = () => {
    setIsUserConfirmed(true);
  };

  useEffect(() => {
    if (!evalFormFields) {
      return;
    }
    const hasDirtyFields = Object.values(evalFormFields).some(
      (field) => field && (field.mark !== null || field.notes !== null)
    );
    if (hasDirtyFields) {
      formRef.current?.validateForm();
    }
  }, [evalFormFields]);

  useEffect(() => {
    if (!formRef.current) {
      return;
    }
    if (isUserConfirmed) {
      formRef.current.handleSubmit();
      closeModal();
    }
  }, [isUserConfirmed]);

  const handleFormSubmit = useCallback(
    (values) => {
      dispatch(submitEvalForm(values));
      closeModal();
      setIsUserConfirmed(false);
    },
    [isUserConfirmed]
  );

  const openModalIfValid = (
    validateForm: (values?: EvaluationOptionsData) => Promise<FormikErrors<EvaluationOptionsData>>
  ) => {
    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        openModal();
      }
    });
  };

  return (
    <div className={styles.root}>
      <div className={styles.formContainer}>
        {evalFormFields && (
          <Formik
            enableReinitialize
            initialValues={evalFormFields}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleFormSubmit}
          >
            {({
              validateForm,
              errors,
            }: {
              validateForm: (values?: EvaluationOptionsData) => Promise<FormikErrors<EvaluationOptionsData>>;
              errors: FormikErrors<EvaluationOptionsData>;
            }) => (
              <Form>
                <div className={styles.questionBlock}>
                  <FormObserver />
                  {questionBlockFieldsDescriptions.map((fieldProps, index) => (
                    <EvalQuestionBlock
                      emptyCommentError={get(errors, fieldProps.questionComments)}
                      error={isSubmitAttempted ? get(errors, fieldProps.questionMark) : undefined}
                      {...fieldProps}
                      key={index}
                      disabled={isLoading}
                    />
                  ))}
                  {prevEvalData && <PrevEvalData prevEvalData={prevEvalData} userId={userId} />}
                  <GoalsDuringEval goalCreationAvailable />
                  {additionalFieldsDescriptions
                    .filter((fieldProps) =>
                      isCurrentUserEvaluator
                        ? ![
                            QuestionNamesToDisplayOnlyForEvaluatee.ProjectGoals,
                            QuestionNamesToDisplayOnlyForEvaluatee.ProfessionalGoals,
                          ].includes(fieldProps.questionName as QuestionNamesToDisplayOnlyForEvaluatee)
                        : true
                    )
                    .map((fieldProps, index) => (
                      <div key={index}>
                        <EvalQuestionBlock
                          {...fieldProps}
                          questionName={fieldProps.questionName}
                          key={index}
                          disabled={isLoading}
                        />
                      </div>
                    ))}
                  <div className={styles.footer}>
                    <div>
                      <RockyButton
                        action
                        onClick={() => {
                          setIsSubmitAttempted(true);
                          openModalIfValid(validateForm);
                        }}
                        className={styles.submitButton}
                      >
                        {isLoading ? (
                          <div className={styles.spinner}></div>
                        ) : (
                          <div className={styles.buttonContent}>
                            {!isEmpty(errors) ? <Pen /> : <Lock />}
                            <p className={styles.buttonText}>
                              {!isEmpty(errors) ? 'Provide feedback' : 'Submit response'}
                            </p>
                          </div>
                        )}
                      </RockyButton>
                      {Object.keys(errors).length > 0 && isSubmitAttempted && (
                        <div className={styles.errorLabel}>There must be no empty fields</div>
                      )}
                    </div>
                    <div className={styles.helpInfo}>
                      <LockCircle />
                      <div className={styles.helpInfoText}>
                        Your responses are saved as a draft visible for you only until you submit it.
                      </div>
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
      <FormExplanationLabel />
      <RockyModal
        onClose={closeModal}
        isOpen={isModalOpen}
        title="Submitting response"
        modalTitleStyles={styles.modalTitleStyles}
        modalBodyStyles={styles.modalBodyStyles}
        crossIcon={false}
      >
        <SubmitEvalFormModalContent onConfirm={confirmFormSubmit} onCancel={closeModal}></SubmitEvalFormModalContent>
      </RockyModal>
    </div>
  );
};

export const EvaluationForm = memo(EvaluationFormComponent);
