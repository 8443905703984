import httpNodeClient from '@shared/api/httpNodeClient';
import { Coordinates } from '@shared/interfaces/Coordinates';
import { User } from '@shared/interfaces/user';

export const fetchCurrentUser = (): Promise<{ data: User }> =>
  httpNodeClient.get<{ data: User }>('users/current-user').then(({ data }) => data);

export const postCheckCurrentLocation = async (
  coordinates?: Coordinates
): Promise<{ locationNotMatchingSince: string }> => {
  const data = await httpNodeClient.post<{ locationNotMatchingSince: string }>(
    'users/current-user/check-current-location',
    coordinates
  );

  return data.data;
};

export const postAcceptTermsOfUse = (version: string) => {
  httpNodeClient.post('terms-of-use/accept', { version }).then(({ data }) => data);
};
