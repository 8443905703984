import { ChatMessage } from '@modules/HappinessModule/interfaces/ChatMessage';
import { DataState } from '@shared/enums/DataState';
import { paths } from '@shared/enums/paths';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ROCKY_CHAT_MESSAGES_LIMIT, ROCKY_CHAT_MESSAGES_OFFSET } from '../constants/Pagination';
import { alreadyVotedMessageAction, getChatData, voteAction } from '../redux/actions';
import { chatDataSelector } from '../redux/selectors';

export const useChatData = (): {
  chatData: AsyncData<ChatMessage[]>;
  reaction: number;
} => {
  const chatData = useSelector(chatDataSelector);
  const [reaction, setReaction] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isVoted, setIsVoted] = useState(false);

  useEffect(() => {
    dispatch(getChatData({ limit: ROCKY_CHAT_MESSAGES_LIMIT, offset: ROCKY_CHAT_MESSAGES_OFFSET }));
  }, []);

  useEffect(() => {
    // TODO: Rewrite with useSearchParams
    const urlSearchParams = new URLSearchParams(window.location.search);
    const reaction = urlSearchParams.get('reaction');
    if (!isVoted && chatData.state === DataState.Fulfilled && reaction) {
      const lastRevote = chatData.data?.filter(({ reaction }) => reaction || reaction === null).pop();
      if (lastRevote?.reaction) {
        dispatch(alreadyVotedMessageAction(lastRevote.reaction));
      } else {
        setReaction(+reaction);
        dispatch(voteAction(+reaction));
        setIsVoted(true);
      }
      navigate(paths.chat);
    }
  }, [chatData, isVoted]);

  return { chatData, reaction };
};
