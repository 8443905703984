import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    fontSize: '14px',
  },
  title: {
    fontWeight: '500',
  },
  rescheduledBlockItem: {
    marginTop: 4,
  },
  rescheduledDates: {
    fontSize: '14px',
    display: 'flex',
    columnGap: 5,
    color: COLORS.TEXT.SECONDARY,
  },
  rescheduledReason: {
    marginTop: 6,
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '400',
  },
});
