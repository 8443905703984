import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadToms } from '@modules/App/redux/users/actions';
import { selectToms } from '@modules/App/redux/users/selectors';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserInfo } from '@shared/interfaces/UserInfo';

export const useToms = ({ isNeedToBeFetched = true }: { isNeedToBeFetched?: boolean } = {}): AsyncData<UserInfo[]> => {
  const dispatch = useDispatch();
  const tomsAsyncData = useSelector(selectToms);

  useEffect(() => {
    if (tomsAsyncData.data?.length || !isNeedToBeFetched) {
      return;
    }
    dispatch(loadToms());
  }, [tomsAsyncData.data]);

  return tomsAsyncData;
};
