import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { usePeerEvalData } from './hooks/usePeerEvalData';
import { useStyles } from './PeerEvalPage.styles';
import { DataState } from '@shared/enums/DataState';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { DataErrorView } from '@shared/components/DataErrorView';
import { PeerEvalForm } from './components/PeerEvalForm/PeerEvalForm';
import { PeerEvalFormClosed } from './components/PeerEvalFormClosed/PeerEvalFormClosed';
import { RocketLoader } from '@shared/components/RocketLoader';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { PeerEvalPageHeader } from './components/PeerEvalPageHeader';
import { paths } from '@shared/enums/paths';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';

export const PeerEvalPage: React.FC = () => {
  const styles = useStyles();
  const peerEvalData = usePeerEvalData();
  const { data, state, error } = peerEvalData.peerEvalData;
  const [reviewStatus, setReviewStatus] = useState('');
  const evalFeedbackStatus = data?.status;
  const isFeedbackSubmitted = data?.evaluation.isConfirmed;
  const hasUpdatePermissions = peerEvalData.peerEvalPermissions.data?.peerFeedback.information.isUpdatable;
  const hasReadPermissions =
    peerEvalData.peerEvalPermissions.data?.peerFeedback.information.isReadable ||
    peerEvalData.peerEvalPermissions.data?.peerFeedback.status.isReadable;

  const hasApprovalPermissions = peerEvalData.peerEvalPermissions.data?.peerFeedback.status.isUpdatable || false;
  const isFeedbackNotFound = peerEvalData.peerEvalPermissions.error?.code === 404;

  const isFailed = state === DataState.Rejected;
  const isLoading = state === DataState.Pending;
  const setPageTitle = useTitle();

  const navigate = useNavigate();

  const [isAnonymousField, setIsAnonymousField] = useState<boolean>(data?.isAnonymous ?? false);
  const toggleIsAnonymous = () => {
    setIsAnonymousField((prev) => !prev);
  };

  useEffect(() => {
    setIsAnonymousField(data?.isAnonymous ?? false);
  }, [data?.isAnonymous]);

  useEffect(() => {
    if (data?.evaluatee?.name) {
      setPageTitle(`${data?.evaluatee?.name} ${pageTitles.userEvalPage}`);
    }
  }, [data]);

  useEffect(() => {
    if (evalFeedbackStatus !== EvalFeedbackStatus.Review && reviewStatus) {
      navigate(`${paths.myTeam}/${paths.evaluations}`);
    }
  }, [evalFeedbackStatus, reviewStatus]);

  const isPeerFeedbackDisplayedInClosedView =
    (evalFeedbackStatus === EvalFeedbackStatus.Review && isFeedbackSubmitted) ||
    evalFeedbackStatus === EvalFeedbackStatus.Approved;

  return (
    <div className={styles.root}>
      {isLoading ? (
        <div className={styles.loaderHolder}>
          <RocketLoader />
        </div>
      ) : isFailed && !isFeedbackNotFound ? (
        <DataErrorView error={error} />
      ) : isFeedbackNotFound ? (
        <div className={styles.notFoundHolder}>
          <h1>Feedback does not exist or was deleted</h1>
        </div>
      ) : (
        data && (
          <div>
            <PeerEvalPageHeader isAnonymous={isAnonymousField} data={data} />
            {hasReadPermissions && isPeerFeedbackDisplayedInClosedView ? (
              <PeerEvalFormClosed
                reviewStatus={reviewStatus}
                setReviewStatus={setReviewStatus}
                data={data}
                hasApprovalPermissions={hasApprovalPermissions}
              />
            ) : hasUpdatePermissions ? (
              <PeerEvalForm toggleIsAnonymous={toggleIsAnonymous} data={data} isLoading={isLoading} />
            ) : (
              <AccessDeniedView />
            )}
          </div>
        )
      )}
    </div>
  );
};
