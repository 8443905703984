import React, { HTMLAttributes, SyntheticEvent } from 'react';
import { useStyles } from './PeopleSerchSelector.styles';
import {
  Autocomplete,
  AutocompleteRenderInputParams,
  Avatar,
  Chip,
  FilterOptionsState,
  TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { Field, FormikProps, useFormikContext } from 'formik';
import { FlagFiltersFields } from '../../interfaces/FlagFiltersFields';
import { useSearchFunctions } from '@shared/helpers/useSearchFunctions';
import { loadActiveUsersList, loadAllUsers } from '@modules/App/redux/users/actions';
import { resetSearchResult } from '@modules/App/redux/users';
import { useDispatch, useSelector } from 'react-redux';
import { selectUsersSearchData } from '@modules/App/redux/users/selectors';
import { DataState } from '@shared/enums/DataState';
import { FilterFields } from '../../enums/FilterFields';

interface Props {
  title: string;
  name: string;
  onChange: (fieldName: string, newValue: UserInfo[]) => void;
  value?: UserInfo[];
}

export const PeopleSerchSelector: React.FC<Props> = ({ title, name, onChange, value }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const formik: FormikProps<FlagFiltersFields> = useFormikContext();
  const { inputValue, handleInputChange } = useSearchFunctions(
    name === FilterFields.InvolvedUsers ? loadAllUsers : loadActiveUsersList,
    resetSearchResult
  );
  const searchData = useSelector(selectUsersSearchData);
  const noOptionsText =
    searchData.data === null
      ? 'Start typing to search'
      : searchData.state === DataState.Pending
      ? 'Search people...'
      : 'Start typing to search';

  return (
    <Field
      name={name}
      component={Autocomplete}
      multiple
      value={value}
      onChange={(_: SyntheticEvent, newValue: UserInfo[]) => onChange(name, newValue)}
      options={searchData.data ?? []}
      disableClearable
      limitTags={1}
      getOptionLabel={(option: UserInfo) => option.name}
      isOptionEqualToValue={(option: UserInfo, value: UserInfo) => option.id === value.id}
      renderOption={(props: HTMLAttributes<HTMLLIElement>, option: UserInfo) => (
        <li {...props} key={option.id}>
          <UserReporterBlock statusDisplayPosition="right side" user={option} />
        </li>
      )}
      noOptionsText={noOptionsText}
      filterOptions={(options: UserInfo[], state: FilterOptionsState<UserInfo>) =>
        options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      }
      renderTags={(values: UserInfo[]) =>
        values.map((value, index) => (
          <Chip
            key={index}
            variant="filled"
            avatar={
              <Avatar
                sx={{
                  borderRadius: '50%',
                }}
                variant="circular"
                src={value.imageUrl ?? undefined}
              >
                {value.name}
              </Avatar>
            }
            label={value.name}
            onDelete={() => {
              const newValue = (formik.values[name as keyof FlagFiltersFields] as UserInfo[]) ?? [];
              onChange(
                name,
                newValue.filter((user) => user.id !== value.id)
              );
            }}
            deleteIcon={<CancelIcon />}
          />
        ))
      }
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          className={styles.selectorInput}
          label={title}
          onChange={(e) => handleInputChange(e.target.value)}
          value={inputValue}
          onBlur={() => dispatch(resetSearchResult())}
          placeholder={value && value.length > 0 ? '' : 'Anyone'}
          InputLabelProps={{ shrink: true }}
        />
      )}
    />
  );
};
