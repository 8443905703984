import React, { FC, MouseEvent } from 'react';
import { useStyles } from './PipLinkItem.styles';
import { IconButton, Typography } from '@mui/material';
import { ReactComponent as ClipIcon } from '@assets/icons/clipIcon.svg';
import { ReactComponent as ArrowRight } from '@assets/icons/ArrowRight.svg';
import classnames from 'classnames';
import { PipLinkBody } from '../../interfaces/PipLinkBody';
import format from 'date-fns/format';
import { Link } from 'react-router-dom';
import isSameYear from 'date-fns/isSameYear';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';
import { mapFlagStatus } from '../../constants/mapFlagStatus';

interface Props {
  link: PipLinkBody;
  isVisible: boolean;
  isFlagDetailsVisible: boolean;
}
export const PipLinkItem: FC<Props> = ({ link, isVisible, isFlagDetailsVisible }) => {
  const styles = useStyles();
  const isThisYear = isSameYear(new Date(link.escalationDate), new Date());
  const fromDate = link.escalationDate && format(new Date(link.escalationDate), isThisYear ? 'MMM dd' : 'MMM dd, yyyy');

  const handleRowClick = (e: MouseEvent) => {
    if (!link || e.target !== e.currentTarget) {
      e.preventDefault();
    }
  };

  return isVisible ? (
    <tr>
      <td className={classnames(styles.tableDataRow, { [styles.tableDataRowLink]: link })}>
        <a className={styles.root} href={link?.link} target="_blank" onClick={(e) => handleRowClick(e)}>
          <Typography variant="body1" className={classnames(styles.title, { [styles.titleLink]: link })}>
            <ClipIcon className={classnames(styles.clipIcon, { [styles.clipIconLink]: link })} />
            Performance improvement plan
          </Typography>
          {isFlagDetailsVisible ? (
            <>
              <Typography
                variant="body1"
                className={classnames(styles.flagStatus, {
                  [styles.inProgressStatus]: link.status === FlagStatus.InProgress,
                })}
              >
                {mapFlagStatus[link.status]}
              </Typography>
              <Link to={`/flags/${link.flagId}`} className={styles.flagLink}>
                {link.severity.charAt(0).toLocaleUpperCase() + link.severity.slice(1)} flag from {fromDate}
              </Link>
            </>
          ) : (
            <Typography variant="body1" className={styles.flagLink}>
              {fromDate}
            </Typography>
          )}
          {!isFlagDetailsVisible && (
            <IconButton aria-label="Edit" color="primary" size="small" className={styles.linkButton}>
              <ArrowRight />
            </IconButton>
          )}
        </a>
      </td>
    </tr>
  ) : (
    <></>
  );
};
