import httpClient from '@shared/api/httpClient';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { ClientEvalData } from '../interfaces/ClientEvalData';
import { EvalFeedbackStatus } from '../enums/EvalFeedbackStatus';
import { getEvalMarksPageData, mapEvaluationOptionsData } from './helpers/evaluationResponse';
import { mapUserData } from '@shared/interfaces/helpers/mapUserData';
import { EvaluationOptionsData } from '../interfaces/EvaluationOptionsData';
import { SubmitEvalPayloadClient } from '../interfaces/SubmitEvalPayload';
import { ClientEvalResponseData } from './types';

export const fetchClientEvalData = (feedbackId: string): Promise<ClientEvalData> =>
  httpClient.get<{ data: ClientEvalResponseData }>(`/evaluations/client-feedback/${feedbackId}/`).then((resp) => {
    const { full_name, position, email, user, evaluation, evalutionDate } = resp.data.data;
    return {
      id: feedbackId,
      evaluator: {
        name: full_name,
        position: position,
        email: email,
        imageUrl: ImageUrls.Client,
      },
      evaluatee: mapUserData(user),
      status: evaluation.is_confirmed ? EvalFeedbackStatus.Approved : EvalFeedbackStatus.Pending,
      evaluation: getEvalMarksPageData(evaluation),
      evaluationDate: evalutionDate,
    };
  });

export const saveClientEvalData = ({ evaluation, feedbackId }: SubmitEvalPayloadClient): Promise<ClientEvalData> => {
  const mappedPayload = mapEvaluationOptionsData(evaluation);
  return httpClient.put<EvaluationOptionsData, ClientEvalData>(
    `/evaluations/client-feedback/${feedbackId}/`,
    mappedPayload
  );
};
