import { v4 as uuidv4 } from 'uuid';

import { ClientReviewer, PeerReviewer } from '../types';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';

export const peerReviewerParser = (peerReviewer: PeerReviewer) => ({
  id: peerReviewer.id,
  status: peerReviewer.status,
  user: {
    id: peerReviewer.id,
    name: peerReviewer.full_name,
    imageUrl: peerReviewer.imageUrl,
    position: peerReviewer.position,
    country: peerReviewer.country,
    city: peerReviewer.city,
  },
  feedback: null,
});

export const clientReviewerParser = (clientReview: ClientReviewer) => ({
  id: clientReview.client_feedback_id,
  status: clientReview.is_confirmed ? EvalFeedbackStatus.Approved : EvalFeedbackStatus.Pending,
  user: {
    id: uuidv4(),
    name: clientReview.full_name,
    position: clientReview.position,
    email: clientReview.email,
    imageUrl: ImageUrls.Client,
  },
  feedback: null,
  isAskWithEmail: clientReview.isAskWithEmail,
});
