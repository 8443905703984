import { FormikProps, useFormikContext } from 'formik';
import throttle from 'lodash/throttle';
import { FC, memo, useCallback, useEffect } from 'react';
import { EvaluationCloseData } from '@modules/EvaluationModule/interfaces/EvaluationCloseData';
import { autosaveEvalNotesAndGoals } from '@modules/EvaluationModule/pages/EvalPage/redux/actions';
import { useDispatch } from 'react-redux';

const FORM_SAVE_DELAY_MS = 1500;

const EvalNotesEvalNotesFormObserver: FC = () => {
  const { values, dirty }: FormikProps<EvaluationCloseData> = useFormikContext();
  const dispatch = useDispatch();
  const autoSave = useCallback(
    throttle((values) => dispatch(autosaveEvalNotesAndGoals(values)), FORM_SAVE_DELAY_MS),
    []
  );

  useEffect(() => {
    if (dirty) {
      autoSave({ ...values, autosave: true });
    }
  }, [values]);

  return null;
};

export const EvalNotesFormObserver = memo(EvalNotesEvalNotesFormObserver);
