import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  flagIcon: {
    borderRadius: 4,
    cursor: 'pointer',
  },
  largeFlagIcon: {
    fontSize: 24,
    borderRadius: 0,
  },
  smallFlagIcon: {
    fontSize: '16px',
  },
});
