import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  flagHistory: {
    borderRadius: 4,
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    cursor: 'pointer',
  },
  flagHistoryItem: {
    padding: '9px 12px 9px 24px',
    transition: 'background 0.3s ease',

    '&:first-child': {
      borderRadius: 4,
      borderBottomRightRadius: 0,
      borderBottomLeftRadius: 0,
    },

    '&:last-child': {
      borderRadius: 4,
      borderTopRightRadius: 0,
      borderTopLeftRadius: 0,
    },

    '&:hover': {
      background: '#E3E7F0',
      mixBlendMode: 'multiply',
    },
  },

  flagChanges: {
    '& span': {
      display: 'inline-block',

      '&::first-letter': {
        textTransform: 'uppercase',
      },
    },
  },
});
