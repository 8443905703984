import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  textareaBlock: {
    position: 'relative',
    width: '100%',

    [BREAKPOINTS.MOBILE]: {
      textAlign: 'center',
    },
  },
  textarea: {
    border: `2px solid ${COLORS.ACTION.PRIMARY}`,
    borderRadius: 4,
    padding: '16px 20px',
    paddingRight: '70px',
    resize: 'none',
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    minHeight: 74,
    maxHeight: 300,

    '&:focus': {
      outline: 'none',
    },

    [BREAKPOINTS.MOBILE]: {
      width: 360,
      minHeight: 44,
    },
  },
  icon: {
    position: 'absolute',
    width: 40,
    height: 40,
    border: 'none',
    cursor: 'pointer',
    bottom: '20px',
    right: '18px',
  },
  sendBtn: {
    background: COLORS.ACTION.HIGHLIGHT,
    borderRadius: 14,
    animation: '$showUp 0.5s ease-in forwards',

    [BREAKPOINTS.MOBILE]: {
      bottom: '10px',
    },
  },
  user: {
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  '@keyframes showUp': {
    '0%': { opacity: 0, right: 0 },
    '100%': { opacity: 1, right: 18 },
  },
});
