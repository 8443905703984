import { FC } from 'react';
import { Box, Typography } from '@mui/material';

import { useStyles } from './UserNotFoundStyles';
import { ImageUrls } from '@shared/enums/ImageUrls';

export const UserNotFound: FC = () => {
  const styles = useStyles();
  return (
    <Box className={styles.container}>
      <img src={ImageUrls.Puzzle} />
      <Typography variant="h2" className={styles.title}>
        This person does not exist or was deactivated
      </Typography>
    </Box>
  );
};
