import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  contentBlock: {
    height: 'calc(100vh - 100px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    margin: '0 60px 50px 10px',
  },
  infoText: {
    maxWidth: 325,
    fontWeight: '400',
    fontSize: 18,
    lineHeight: '140%',
    color: COLORS.TEXT.SECONDARY,
    textAlign: 'center',
    whiteSpace: 'pre-wrap',
  },
  inComponent: {
    boxShadow: 'none',
    height: 'auto',
    margin: 0,
  },
});
