import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    position: 'fixed',
    bottom: 0,
    right: 20,
    zIndex: 2,
    maxWidth: 744,
    minWidth: 320,
    display: 'flex',
    flexWrap: 'wrap',
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 8px 24px rgba(143, 149, 178, 0.24)',
    borderRadius: '8px 8px 0px 0px',
    padding: '14px 24px 24px 24px',
    transition: 'bottom ease 250ms',
  },
  isClose: {
    cursor: 'pointer',
  },
  goalsInput: {
    width: '100%',
    height: '90%',
    padding: 15,
    border: `1px solid ${COLORS.INPUTBORDER}`,
    marginTop: 11,
  },
  changeSizeIcon: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 24,
    height: 24,
    transform: 'rotate(180deg)',
  },
  pinBtn: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    border: 'none',
    padding: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 'auto',
    width: 24,
    height: 24,

    '& svg': {
      fill: COLORS.TEXT.SECONDARY,
      width: 24,
      height: 20,
    },

    '&:hover svg': {
      fill: COLORS.ACTION.PRIMARY,
    },
  },
  notesLabel: {
    fontWeight: '500',
  },
  panelHandle: {
    transform: 'translate(10px, 10px)',
  },
});
