import { CompensationTypeLabel } from '@modules/EvaluationModule/enums/CompensationTypeLabel';
import { HistoricalCompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { formatFinancialValue } from '@modules/EvaluationModule/pages/UserCompensationPage/helpers/currencyOptions';
import { Typography } from '@mui/material';
import React, { FC } from 'react';

interface Props {
  compensation: HistoricalCompensationData;
}

export const CompensationDetailsModalMoney: FC<Props> = ({ compensation }) => {
  return (
    <span>
      <Typography component="span" variant="body2">
        $
      </Typography>
      {formatFinancialValue(compensation.currentCompensation)}
      {CompensationTypeLabel[compensation.compensationType as keyof typeof CompensationTypeLabel]}
    </span>
  );
};
