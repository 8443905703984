import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClientContactData } from '../redux/actions';
import { setClientContactDataLoading } from '../redux/reducers';
import { clientContactDataSelector } from '../redux/selectors';

export const useClientContactData = (): AsyncData<ClientContactData[]> => {
  const clientContactData = useSelector(clientContactDataSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getClientContactData());
    return () => {
      dispatch(setClientContactDataLoading());
    };
  }, []);

  return clientContactData;
};
