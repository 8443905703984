import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  btn: {
    backgroundColor: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    border: 'none',
    borderRadius: 4,
    fontWeight: '500',
    padding: '10px 20px',
    transition: 'all 0.1s',

    '&[disabled]': {
      backgroundColor: COLORS.TEXT.DISABLEDBG,
      color: COLORS.TEXT.DISABLED,
    },

    '&:hover:not([disabled])': {
      opacity: 0.9,
      cursor: 'pointer',
    },
  },
  btnCancel: {
    backgroundColor: COLORS.ACTION.HIGHLIGHT,
    border: 'none',
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      color: COLORS.ACTION.DARK,
      cursor: 'pointer',
    },
  },
  disabledSubmitBtn: {
    backgroundColor: 'transparent',
    border: `1px solid ${COLORS.DIVIDER}`,
    borderRadius: 10,
    color: COLORS.TEXT.DISABLED,
    fontWeight: '500',
    pointerEvents: 'none',
  },
  acceptContainer: {
    background: COLORS.HIGHLIGHTS.PROMINENT,
    padding: '12px 16px 14px',
    borderRadius: 8,
  },
  acceptCheckbox: {
    transform: 'scale(1.5)',
    margin: '0 19px 0 3px',
    transformOrigin: '0 50%',
  },
  questionText: {
    margin: '18px 0',
    fontSize: 24,
    fontWeight: '500',
    textAlign: 'center',
  },
  popupTitle: {
    margin: '4px 0 12px',
    fontSize: 24,
  },
  dateCheckWarning: {
    color: COLORS.STATUS.CRITICAL,
    margin: '10px 0',
  },
  closeIcon: {
    fill: COLORS.BRAND.PRIMARY,
  },
  controlsContainer: {
    display: 'flex',
    marginTop: 8,
    gap: 8,
  },
});
