import * as Yup from 'yup';
import { questionBlockFieldsDescriptions } from '@modules/EvaluationModule/pages/EvalPage/constants/questionBlockFieldsDescriptions';

const EMPTY_FIELD_ERROR_TEXT = 'Please select your response';

const fieldsSchema = questionBlockFieldsDescriptions.reduce(
  (res, field) => ({
    ...res,
    [field.questionName]: Yup.object().shape({
      mark: Yup.string().nullable().required(EMPTY_FIELD_ERROR_TEXT),
      notes: Yup.string().nullable(),
    }),
  }),
  {}
);

export const validationSchema = Yup.object().shape(fieldsSchema);
