import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SurveyQueue } from '../interfaces';
import { getSurveyQueue, setSurveyQueueLoading } from '../redux/actions';
import { surveyQueueRelector } from '../redux/selectors';

export const useSurveyQueue = (): AsyncData<SurveyQueue> => {
  const dispatch = useDispatch();
  const surveyQueue = useSelector(surveyQueueRelector);

  useEffect(() => {
    dispatch(getSurveyQueue());

    return () => {
      dispatch(setSurveyQueueLoading());
    };
  }, []);

  return surveyQueue;
};
