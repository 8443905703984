import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    minHeight: 50,
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    padding: '0 4px',
    display: 'flex',
    alignItems: 'center',
    gridTemplateColumns: 'max-content minmax(50px, auto) max-content max-content ',
    columnGap: '10px',
    [BREAKPOINTS.MOBILE]: {
      padding: '12px 4px 4px',
    },
  },
  title: {
    fontSize: '14px',
    fontWeight: '400',
    margin: '0 0 0 18px',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  datesContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 4,
    [BREAKPOINTS.MOBILE]: {
      justifySelf: 'end',
    },
  },
  calendarIconContainer: {},
  dateText: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '13px',
    fontWeight: '400',
    margin: 0,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 12,
      lineHeight: '120%',
      width: 48,
    },
  },
  votedBlockContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  dropMenuQuestions: {
    display: 'block',
    fontSize: '14px',
    fontWeight: '400',

    [BREAKPOINTS.MOBILE]: {
      gridRow: '2',
      gridColumn: '1/-1',

      '& > div': {
        paddingLeft: 3,
      },
    },
  },
  latestTitle: {
    fontSize: '14px',
    fontWeight: '500',
    color: COLORS.STATUS.SUCCESS,
    justifySelf: 'end',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  selectorContainer: {
    position: 'relative',
  },
  redDot: {
    position: 'absolute',
    right: -4,
    top: -4,
    width: 12,
    height: 12,
    borderRadius: '50%',
    backgroundColor: COLORS.HIGHLIGHTS.DANGER,
  },
});
