import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  radioGroup: {
    display: 'flex',
    flexWrap: 'nowrap',
  },
  rowRadioBtn: {
    gap: 8,
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
  },
});
