import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    height: '584px',
    position: 'relative',
  },
  iframe: {
    width: '100%',
    height: '100%',
    border: 'none',
  },
  loaderHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)',
    zIndex: 10,
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
});
