import { ChatMessage } from '@modules/HappinessModule/interfaces/ChatMessage';
import classNames from 'classnames';
import { FC, memo } from 'react';
import { useStyles } from './ChatMessageBlock.styles';
import { ReactComponent as MessageTail } from '@assets/icons/messageTail.svg';
import getTime from 'date-fns/getTime';
import format from 'date-fns/format';
import { ChatAuthor } from '@modules/HappinessModule/pages/ChatPage/enums/ChatAuthor';
import { requestRevoteAction } from '@modules/HappinessModule/pages/ChatPage/redux/actions';
import { useDispatch } from 'react-redux';
import { RockyChatAnswers } from '@modules/HappinessModule/pages/ChatPage/enums/RockyChatAnswers';

interface Props {
  message: ChatMessage;
  hasTail: boolean;
}

const ChatMessageBlockComponent: FC<Props> = ({ message, hasTail }) => {
  const { text, ts, author, attachmentUrl } = message;
  const styles = useStyles();
  const time = format(getTime(new Date(ts)), 'HH:mm');
  const isRockyMessage = !author || author.toLocaleLowerCase() === ChatAuthor.Rocky;
  const lineBreakedText = text.split('\n').map((t) => (t === '' ? '\u00A0' : t));
  const dispatch = useDispatch();

  return (
    <div className={styles.root}>
      <div
        className={classNames(styles.messageBody, {
          [styles.userMessage]: !isRockyMessage,
          [styles.rockyMessage]: isRockyMessage,
        })}
      >
        {hasTail && (
          <MessageTail className={classNames(styles.rockyMessageTail, { [styles.userMessageTail]: !isRockyMessage })} />
        )}
        {attachmentUrl && <img className={styles.gifImage} src={attachmentUrl} />}
        {text.startsWith(RockyChatAnswers.AlreadyVoted) && isRockyMessage ? (
          <div>
            {text.replace('revote?', '')}
            <span className={styles.revote} onClick={() => dispatch(requestRevoteAction())}>
              revote
            </span>
            ?
          </div>
        ) : (
          lineBreakedText.map((el, i) => (
            <p key={i} className={styles.messageText}>
              {el}
            </p>
          ))
        )}
        <span className={styles.date}>{time}</span>
      </div>
    </div>
  );
};

export const ChatMessageBlock = memo(ChatMessageBlockComponent);
