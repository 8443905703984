import { FLAG_PAGE_BLOCKS_PADDING } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  mainContainer: {
    padding: FLAG_PAGE_BLOCKS_PADDING,
  },
  anotherOfferInvolvedBlockHrView: {
    padding: '29px 65px 84px 59px',
  },
});
