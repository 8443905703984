import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  navItem: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textDecoration: 'none',
    minHeight: 48,
    paddingLeft: 16,
    borderRadius: 6,
    marginTop: 4,
    cursor: 'pointer',
    color: COLORS.TEXT.DISABLED,
    backgroundColor: COLORS.WHITE,
    transition: 'all 0.2s linear',

    '& svg': {
      color: COLORS.TEXT.SECONDARY,
      transition: 'all 0.2s linear',
    },

    '&:hover': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,

      '& svg': {
        color: COLORS.BRAND.PRIMARY,
        transform: 'scale(1.1)',
      },
    },

    [BREAKPOINTS.TABLET]: {
      justifyContent: 'center',
      paddingLeft: 0,
    },
    [BREAKPOINTS.MOBILE]: {
      justifyContent: 'flex-start',
      paddingLeft: 24,
      marginRight: 12,
      marginLeft: 12,
      width: 'auto',
    },
  },
  navContainer: {
    marginLeft: 16,
  },
  navText: {
    margin: 0,
    fontSize: '14px',
    lineHeight: '140%',
    fontWeight: '400',
  },
  subText: {
    fontSize: 12,
  },
});
