import { Role } from '../enums/role';
import { ProjectRole } from '@shared/enums/projectRole';

export const RoleMap: {
  [key in ProjectRole]: string;
} = {
  [Role.projectManager]: 'Project Manager',
  [Role.teamLead]: 'Team Lead',
  [Role.clientPartner]: 'Client Partner',
  [Role.deliveryManager]: 'Delivery Manager',
  [Role.engineeringManager]: 'Engineering Manager',
  [Role.portfolioDeliveryManager]: 'Portfolio Delivery Manager',
};
