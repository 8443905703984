import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  flagInfoBlock: {
    background: COLORS.HIGHLIGHTS.PROMINENT,
    padding: '38px 40px !important',
  },
  flagInfoBlockIcon: {
    fontSize: '36px',
  },
  flagReasonBlock: {
    padding: '35px 15px 59px 59px',
  },
});
