import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    color: COLORS.TEXT.PRIMARY,
    height: 'fit-content',
    width: '100%',
  },
  header: {
    textTransform: 'capitalize',
    padding: '12px 9px 12px 16px',
    fontSize: '14px',
    fontWeight: '500',
    borderBottom: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
  body: {
    padding: '12px 9px 12px 16px',
    overflowWrap: 'anywhere',
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 20,
  },
  notCommented: {
    color: COLORS.TEXT.SECONDARY,
  },
  needDev: {
    background: COLORS.HIGHLIGHTS.CRITICAL,
    color: COLORS.STATUS.CRITICAL,
  },
  markNotes: {
    whiteSpace: 'pre-wrap',
  },
  tooltip: {
    alignSelf: 'center',
  },
});
