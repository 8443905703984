import React, { FC, memo, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useStyles } from './UsersAlias.styles';
import { useAuth } from '@shared/hooks/useAuth';
import { RocketLoader } from '@shared/components/RocketLoader';
import { paths } from '@shared/enums/paths';
import { fetchUserIdFromEmail } from '@modules/App/api/users';

const UsersAliasComponent: FC = () => {
  const styles = useStyles();
  const { emailUserName } = useParams();
  const navigate = useNavigate();
  const [id, setId] = useState('');
  const location = useLocation();

  const { currentUser } = useAuth();

  useEffect(() => {
    fetchUserIdFromEmail(emailUserName ?? currentUser?.email).then((res) => setId(res.id));
  }, [currentUser]);

  useEffect(() => {
    if (id) {
      navigate(
        `${location.pathname.replace(`${paths.usersAlias}`, `${paths.users}`).replace(`${emailUserName}`, `${id}`)}`
      );
    }
  }, [id]);

  return (
    <div className={styles.root}>
      <RocketLoader></RocketLoader>
      <h3 className={styles.loadingText}>Taking you to the spaceship</h3>
    </div>
  );
};

export const UsersAlias = memo(UsersAliasComponent);
