import React, { FC, memo } from 'react';
import { SurveyInfo } from '../../interfaces';
import { useStyles } from './UpcomingSurveyCard.styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays';
import classNames from 'classnames';
import Popup from 'reactjs-popup';
import { StartSurvey } from '../StartSurvey';

interface Props {
  upcomingSurvey?: SurveyInfo;
}

const UpcomingSurveyCardComponent: FC<Props> = ({ upcomingSurvey }) => {
  const styles = useStyles();

  const dateFormatted = upcomingSurvey ? format(parseISO(upcomingSurvey.date), 'dd MMM yyyy') : '';

  const today = new Date();
  const expiredDayCount = upcomingSurvey ? differenceInCalendarDays(today, parseISO(upcomingSurvey.date)) : 0;

  return (
    <div className={classNames(styles.root, { [styles.expiredRoot]: expiredDayCount > 0 })}>
      <h1 className={styles.cardHeader}>Upcoming survey</h1>
      {upcomingSurvey ? (
        <>
          <h2 className={styles.categoryText}>{upcomingSurvey.categoryName}</h2>
          <h3 className={styles.questionText}>{upcomingSurvey.questionText}</h3>
          <div className={styles.dateHolder}>
            <span className={styles.dateText}>
              {dateFormatted}&nbsp;
              {expiredDayCount > 0 && `(${expiredDayCount} day${expiredDayCount > 1 ? 's' : ''} late)`}
            </span>
          </div>
          <Popup
            trigger={<button className={styles.runSurveyBtn}>Start survey</button>}
            position="left center"
            closeOnDocumentClick
            className={styles.startSurveyPopup}
            modal
          >
            {(close: () => void) => <StartSurvey closeFn={close} questionText={upcomingSurvey.questionText} />}
          </Popup>
        </>
      ) : (
        <h2 className={styles.categoryText}>No survey scheduled!</h2>
      )}
    </div>
  );
};

export const UpcomingSurveyCard = memo(UpcomingSurveyCardComponent);
