import React from 'react';
import { useStyles } from './EmptyPromotionView.styles';
import emptyPromotionsIcon from '@assets/img/emptyPromotions.png';
import { Typography } from '@mui/material';
import { useAuth } from '@shared/hooks/useAuth';
import { useSelector } from 'react-redux';
import { userDataSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { ManagePromotionButton } from '../ManagePromotionButton/ManagePromotionButton';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';

export const EmptyPromotionView: React.FC = () => {
  const styles = useStyles();
  const userData = useSelector(userDataSelector);
  const userActionsPermissions = useSelector(userPermissionsSelector);
  const { currentUser } = useAuth();
  const isCurrentUserPage = userData.data?.id === currentUser?.id;
  const userFirstName = userData.data?.name.split(' ')[0];
  const isPromotionsUpdatable = userActionsPermissions.data?.promotions.isUpdatable;

  return (
    <div className={styles.emptyViewContainer}>
      <img src={emptyPromotionsIcon} className={styles.emptyViewIcon}></img>
      <Typography variant="h2">
        {isCurrentUserPage ? 'You' : `${userFirstName}'s`} further promotions will appear here
      </Typography>
      <Typography variant="body1">Past career history is not available in Rocky yet</Typography>
      {isPromotionsUpdatable && <ManagePromotionButton />}
    </div>
  );
};
