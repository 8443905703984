import React, { FC } from 'react';
import format from 'date-fns/format';
import { Release } from '@modules/App/pages/WhatIsNewPage/constants/whatsNewData';
import { useStyles } from './ReleaseBlock.styles';

interface Props {
  release: Release;
}

export const ReleaseBlock: FC<Props> = ({ release }) => {
  const styles = useStyles();
  return (
    <div key={release.version}>
      <div className={styles.divider}></div>
      <div className={styles.block}>
        <div className={styles.title}>
          <h3>{format(release.date, 'LLL d, yyyy')}</h3>
          <div className={styles.version}>{release.version}</div>
        </div>
        <h5>Improvements:</h5>
        <ul>
          {release.features?.map((feature, index) => (
            <li key={index} className={styles.item}>
              {feature}
            </li>
          ))}
        </ul>
        {release.fixes?.length && (
          <>
            <h5>Fixed issues:</h5>
            <ul>
              {release.fixes?.map((fix, index) => (
                <li key={index} className={styles.item}>
                  {fix}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};
