import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  drawlerRoot: {
    width: 385,
    padding: 32,
  },
  textField: {
    '& .MuiInputBase-root': {
      paddingTop: '10px',
      paddingBottom: '12px',
    },
  },
});
