import React, { FC } from 'react';
import { useStyles } from './RecipientsPreview.styles';
import { RecipientData } from '@modules/HappinessModule/interfaces/RecipientData';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { CC_DESCRIPTIONS } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/CcInfo';

interface Props {
  recipients: RecipientData[];
}
export const RecipientsPreview: FC<Props> = ({ recipients }) => {
  const styles = useStyles();
  return (
    <div className={styles.recipientsListContainer}>
      <div>
        {recipients
          .filter((recipient) => !recipient.isCarbonCopy)
          .map(({ user }, i) => {
            return (
              user && (
                <div key={i} className={styles.recipientItem}>
                  <UserReporterBlock user={user} isDetailInfo key={i} />
                </div>
              )
            );
          })}
        {recipients
          .filter((recipient) => recipient.isCarbonCopy)
          .map(({ userEmail }, i) => (
            <div className={styles.recipientCcItem} key={i}>
              <div>cc: {userEmail}</div>
              <div className={styles.recipientDescription}>{CC_DESCRIPTIONS[userEmail]}</div>
            </div>
          ))}
      </div>
    </div>
  );
};
