import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AutomaticFlagWatchers } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { userAutomaticFlagWatchersSelector } from '../pages/CreateFlagFormPage/redux/selectors';
import { getAutomaticFlagWatchers } from '../pages/CreateFlagFormPage/redux/actions';
import { setAutomaticFlagWatchers } from '../pages/CreateFlagFormPage/redux/reducers';
import { DataState } from '@shared/enums/DataState';

export const useUserAutomaticFlagWatchers = ({
  userId,
}: {
  userId: string | null;
}): AsyncData<AutomaticFlagWatchers> => {
  const dispatch = useDispatch();
  const userAutomaticFlagWatchers = useSelector(userAutomaticFlagWatchersSelector);

  useEffect(() => {
    if (!userId) {
      return;
    }

    dispatch(getAutomaticFlagWatchers(userId));

    return () => {
      dispatch(setAutomaticFlagWatchers({ data: null, state: DataState.Idle }));
    };
  }, [userId]);

  return userAutomaticFlagWatchers;
};
