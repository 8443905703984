import { createSlice } from '@reduxjs/toolkit';
import { SortDirection } from '@shared/enums/sortDirection';
import { DataState } from '@shared/enums/DataState';

import { MainPageState } from './types';
import { MyPeerReviewDataFields } from '../constants/FlagFormFields';

const initialState: MainPageState = {
  myPeerReviewsData: {
    data: null,
    state: DataState.Pending,
  },
  sortInfo: { field: MyPeerReviewDataFields.evaluationDate.name, sortDirection: SortDirection.Desc },
};

export const slice = createSlice({
  name: 'myPeerReviewsPage',
  initialState,
  reducers: {
    setSortInfo(state, action) {
      state.sortInfo = action.payload;
    },
    setMyPeerReviewsDataLoading(state) {
      state.myPeerReviewsData = { data: null, state: DataState.Pending };
    },
    setMyPeerReviewsData(state, action) {
      state.myPeerReviewsData = action.payload;
    },
  },
});

export const { setSortInfo, setMyPeerReviewsData, setMyPeerReviewsDataLoading } = slice.actions;

export default slice.reducer;
