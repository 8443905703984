import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setChatData } from './actions';

import { State } from './types';

const initialState: State = {
  chatData: {
    data: null,
    state: DataState.Pending,
  },
};

export const slice = createSlice({
  name: 'chatPage',
  initialState,
  reducers: {
    setChatDataLoading(state) {
      state.chatData = { data: null, state: DataState.Pending };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setChatData, (state, action) => {
      state.chatData = action.payload;
    });
  },
});

export default slice.reducer;
