import { createAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './constants';

export const loadUsersList = createAction<string>(`${SLICE_NAME}/search`);
export const loadActiveUsersList = createAction<string>(`${SLICE_NAME}/searchActive`);
export const loadLegalCountryOfUser = createAction<string>(`${SLICE_NAME}/loadLegalCountryOfUser`);
export const loadToms = createAction(`${SLICE_NAME}/loadToms`);
export const loadPrs = createAction(`${SLICE_NAME}/loadPrs`);
export const loadAllUsers = createAction<string>(`${SLICE_NAME}/loadAllUsers`);
export const loadUsersWhomICanCreateFlag = createAction(`${SLICE_NAME}/loadUsersWhomICanCreateFlag`);
