import { OrgChartData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import { createAction } from '@reduxjs/toolkit';
import { FilterInfoOrgChart } from '@modules/App/pages/OrgChartPage/interfaces/FilterInfoOrgChart';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { AsyncData } from 'shared/interfaces/asyncData';
import { DataState } from '@shared/enums/DataState';
import { OrgChartSearchData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartSearchData';

export const getOrgChartDepartmentData = createAction('orgChartPage/getOrgChartDepartmentData');
export const getOrgChartReportingData = createAction('orgChartPage/getOrgChartReportingData');
export const setOrgChartData = createAction<AsyncData<OrgChartData[]>>('orgChartPage/setOrgChartData');
export const setOrgChartDataLoading = createAction<void>('orgChartPage/setOrgChartDataLoading');
export const setSortInfo = createAction<SortInfo>('orgChartPage/setSortInfo');
export const setFilterInfo = createAction<FilterInfoOrgChart>('orgChartPage/setFilterInfo');
export const setIsDepartmentChart = createAction<boolean>('orgChartPage/setIsDepartmentChart');
export const setRootId = createAction<string>('orgChartPage/setRootId');
export const removeFilterInfo = createAction<string>('orgChartPage/removeFilterInfo');
export const resetFilters = createAction('orgChartPage/resetFilters');
export const loadSearchData = createAction<string>('orgChartPage/search');
export const setSearchDataState = createAction<DataState>('orgChartPage/setSearchDataState');
export const setSearchData = createAction<OrgChartSearchData[]>('orgChartPage/setSearchData');
export const resetSearchResult = createAction<void>('orgChartPage/resetSearchResult');
