import { apiUrlNode } from '@configs/environment';
import axios from 'axios';
import { httpInterceptorsRequestUse, httpInterceptorsResponseUse } from '@shared/helpers/httpHelpers';

const httpNodeClient = axios.create({
  baseURL: apiUrlNode,
  headers: { Accept: 'Application/Json' },
});

httpInterceptorsRequestUse(httpNodeClient);

httpInterceptorsResponseUse(httpNodeClient);

export default httpNodeClient;
