import httpNodeClient from '@shared/api/httpNodeClient';
import { OrgChartData } from '../pages/OrgChartPage/interfaces/OrgChartData';
import { OrgChartSearchData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartSearchData';

export const fetchOrgChartDepartmentData = (): Promise<OrgChartData[]> =>
  httpNodeClient
    .get<OrgChartData[]>('/org-structure/get-departments-structure', { params: { sabbatical: true } })
    .then((resp) =>
      resp.data.map((item) => ({
        ...item,
        happiness: item.happiness
          ? { ...item.happiness, reaction: item.happiness.reaction !== null ? +item.happiness.reaction : null }
          : item.happiness,
        name: item.name ? item.name.slice(0, 40) : '',
      }))
    );

export const fetchOrgChartReportingData = (): Promise<OrgChartData[]> =>
  httpNodeClient
    .get('/org-structure/get-reporting-to-structure', { params: { sabbatical: true } })
    .then((resp) => resp.data.map((item: OrgChartData) => ({ ...item, parentId: item.reportsToId })));

export const fetchOrgChartSearchData = (search: string): Promise<OrgChartSearchData[]> =>
  httpNodeClient
    .get<OrgChartSearchData[]>('/org-structure/search', {
      params: { search, sabbatical: true },
    })
    .then((response) => response.data);
