import { CompensationType } from '@modules/EvaluationModule/enums/CompensationType';
import { CompensationTypeRaw } from '@modules/EvaluationModule/enums/ComperationTypeRaw';

export const CompensationTypeMap: {
  [key: string]: CompensationType;
} = {
  [CompensationTypeRaw.Biweekly]: CompensationType.Biweekly,
  [CompensationTypeRaw.Hourly]: CompensationType.Hourly,
  [CompensationTypeRaw.Monthly]: CompensationType.Monthly,
  [CompensationTypeRaw.Yearly]: CompensationType.Yearly,
};
