import httpNodeClient from '@shared/api/httpNodeClient';
import { UserMainData, UserProfileData } from '@shared/interfaces/user';
import { EditContactsPayloadData } from '../interfaces/EditUserContactPayloadData';

export const fetchUserProfilePartialData = (id: string): Promise<UserMainData> =>
  httpNodeClient.get<UserMainData>(`/users/${id}`).then((resp) => resp.data);

export const fetchUserProfileData = (id: string): Promise<UserProfileData> =>
  httpNodeClient.get<UserProfileData>(`/users/${id}/profile`).then((resp) => ({
    ...resp.data,
    legalAddress: resp.data.legalAddress === '' ? 'Not specified' : resp.data.legalAddress,
  }));

export const editUserProfileData = (payload: EditContactsPayloadData): Promise<UserProfileData> =>
  httpNodeClient
    .patch<{ data: UserProfileData }>(`users/${payload.id}/profile/contacts`, { ...payload })
    .then((resp) => resp.data.data);
