import { apiUrl } from '@configs/environment';
import httpClient from '@shared/api/httpClient';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { AxiosResponse } from 'axios';
import { ClientContactData } from '../interfaces/ClientContactData';
import { ClientSubProjectData } from '../interfaces/ClientSubProjectData';
import { AddClietContactData, ClietContactFormData } from '../interfaces/ClietContactFormData';
import { ClientContactResponseData, ClientSubProjectResponseData } from './types';
import { ClientsTypeOfContacts } from '../enums/ClientsTypeOfContact';
import { YesNo } from '@shared/enums/yesNo';

export const fetchProjectListData = (): Promise<ClientContactData[]> =>
  httpClient
    .get<ClientContactResponseData>('/my-projects-list/')
    .then((resp) =>
      resp.data.data.map(({ zoho_crm_id, ...restValues }) => ({ ...restValues, zohoCrmId: zoho_crm_id }))
    );

export const fetchClientSubProjectData = (project: {
  name: string | null;
  id: string | null;
}): Promise<ClientSubProjectData[]> =>
  httpClient
    .get<{ data: ClientSubProjectResponseData[] }>(
      `/client-contacts?department_name=${project.name}&department_id=${project.id}`
    )
    .then((resp) => {
      return resp.data.data.map(({ name, id, contacts, is_workstream }) => ({
        name,
        id,
        isWorkstream: is_workstream,
        contacts: contacts.map(
          ({
            zoho_id,
            first_name,
            last_name,
            do_not_touch,
            title,
            email_ae,
            account_name,
            work_stream_id,
            technical_person,
            feedback_giver,
            location,
            location_area,
            rm_type_of_contact,
            rm_contact_status,
            csat_giver,
            csat_emails_enabled,
          }) => ({
            id: zoho_id,
            firstName: first_name ? first_name : 'No name',
            lastName: last_name ? last_name : 'No name',
            title: title ? title : '',
            accountName: account_name,
            workstream: work_stream_id,
            emailAeTool: email_ae ? email_ae : '',
            technicalPerson: technical_person === YesNo.Yes ? true : false,
            feedbackGiver: feedback_giver,
            csatGiver: csat_giver,
            location: location ? location : '',
            customTitle: '',
            locationArea: location_area ? location_area : '',
            rmTypeOfContact:
              rm_type_of_contact && rm_type_of_contact !== 'null' ? rm_type_of_contact : ClientsTypeOfContacts.client,
            rmContactStatus: rm_contact_status,
            imageUrl: ImageUrls.Client,
            doNotTouch: do_not_touch,
            csatEmailsEnabled: csat_emails_enabled,
          })
        ),
      }));
    });

export const postClientContact = (payload: AddClietContactData): Promise<AxiosResponse> => {
  const {
    firstName,
    lastName,
    title,
    customTitle,
    workstream,
    emailAeTool,
    technicalPerson,
    location,
    locationArea,
    rmTypeOfContact,
    rmContactStatus,
    doNotTouch,
    feedbackGiver,
    csatGiver,
    zohoCrmId,
    csatEmailsEnabled,
  } = payload;

  return httpClient.post<void>(`${apiUrl}/client-contacts/`, {
    first_name: firstName,
    last_name: lastName,
    title: title !== 'Other' ? title : customTitle,
    work_stream_id: workstream,
    email_ae: emailAeTool,
    technical_person: technicalPerson ? YesNo.Yes : YesNo.No,
    feedback_giver: feedbackGiver,
    location: location,
    location_area: locationArea,
    rm_type_of_contact: rmTypeOfContact,
    rm_contact_status: rmContactStatus,
    do_not_touch: doNotTouch,
    csat_giver: csatGiver,
    zoho_crm_id: zohoCrmId,
    CSAT_emails_enabled: csatEmailsEnabled,
  });
};

export const patchClientContact = (payload: ClietContactFormData): Promise<AxiosResponse> => {
  const {
    firstName,
    lastName,
    title,
    customTitle,
    workstream,
    emailAeTool,
    technicalPerson,
    location,
    locationArea,
    rmTypeOfContact,
    rmContactStatus,
    doNotTouch,
    feedbackGiver,
    csatGiver,
    id,
    csatEmailsEnabled,
  } = payload;

  return httpClient.patch<void>(`${apiUrl}/client-contacts/`, {
    first_name: firstName,
    last_name: lastName,
    title: title !== 'Other' ? title : customTitle,
    work_stream_id: workstream,
    email_ae: emailAeTool,
    technical_person: technicalPerson ? YesNo.Yes : YesNo.No,
    feedback_giver: feedbackGiver,
    location: location,
    location_area: locationArea,
    rm_type_of_contact: rmTypeOfContact,
    rm_contact_status: rmContactStatus,
    do_not_touch: doNotTouch,
    csat_giver: csatGiver,
    zoho_id: id,
    CSAT_emails_enabled: csatEmailsEnabled,
  });
};
