import { FlagListData } from '@modules/HappinessModule/interfaces/FlagListData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMyTeamFlagsData } from '../redux/actions';
import { myTeamFlagsDataSelector } from '../redux/selectors';

export const useMyTeamFlagsData = (): AsyncData<FlagListData[]> => {
  const myTeamFlagsData = useSelector(myTeamFlagsDataSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMyTeamFlagsData());
  }, []);

  return myTeamFlagsData;
};
