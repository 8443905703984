import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OrgChartData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import {
  getOrgChartDepartmentData,
  getOrgChartReportingData,
  resetFilters,
  setOrgChartDataLoading,
  setRootId,
  setSortInfo,
} from '../redux/actions';
import { useParams } from 'react-router-dom';
import { useAuth } from '@shared/hooks/useAuth';
import {
  getOrgChartDataSelector,
  getSortedOrgChartDataSelector,
  selectIsDepartmentChart,
  selectOrgChartDataAsync,
} from '../redux/selectors';
import { SortDirection } from '@shared/enums/sortDirection';
import { useHiddenDepartments } from '@shared/hooks/useHiddenDepartments';
import { getTheSameNodeButForAnotherParentIfExist, getOrgChartDataWithoutHiddenElements } from '../helpers/helpers';
import { HiddenNodes } from '../interfaces/HiddenNodes';

export const useOrgChartData = (): {
  initialOrgChartData: AsyncData<OrgChartData[]>;
  sortedAndFilteredOrgChartData: OrgChartData[];
  initiallySelectedNodeId: string | null;
  hiddenNodes: HiddenNodes;
} => {
  const dispatch = useDispatch();
  const { selectedNodeId } = useParams();
  const hiddenDepartments = useHiddenDepartments();
  const { currentUser } = useAuth();
  const initialOrgChartData = useSelector(selectOrgChartDataAsync);
  const { rootNodeId } = useSelector(getOrgChartDataSelector);
  const sortedAndFilteredOrgChartData = useSelector(getSortedOrgChartDataSelector);
  const isDepartmentChart = useSelector(selectIsDepartmentChart);
  const [initiallySelectedNodeId, setInitiallySelectedNodeId] = useState<string | null>(null);
  const [isInitiallySelectedNodeIdSet, setIsInitiallySelectedNodeIdSet] = useState(false);
  const [hiddenNodes, setHiddenNodes] = useState<
    | {
        hiddenNode: OrgChartData;
        anotherNode: OrgChartData | null;
      }[]
    | null
  >(null);

  const resetAllFiltersAndSort = () => {
    dispatch(resetFilters());
    dispatch(setSortInfo({ field: 'name', sortDirection: SortDirection.Asc }));
  };

  useEffect(() => {
    dispatch(setRootId(rootNodeId));
  }, [rootNodeId]);

  useEffect(() => {
    if (isDepartmentChart) {
      dispatch(getOrgChartDepartmentData());
    } else {
      resetAllFiltersAndSort();
      dispatch(getOrgChartReportingData());
    }

    return () => {
      resetAllFiltersAndSort();
      dispatch(setOrgChartDataLoading());
    };
  }, [isDepartmentChart]);

  useEffect(() => {
    if (isInitiallySelectedNodeIdSet || !currentUser) {
      return;
    }
    const selectedNode = selectedNodeId ? selectedNodeId : currentUser.id;
    setInitiallySelectedNodeId(selectedNode);
    setIsInitiallySelectedNodeIdSet(true);
  }, [selectedNodeId, currentUser, isInitiallySelectedNodeIdSet]);

  const sortedAndFilteredOrgChartDataWithoutHiddenDepartments =
    isDepartmentChart && hiddenDepartments.data
      ? getOrgChartDataWithoutHiddenElements(sortedAndFilteredOrgChartData, hiddenDepartments.data)
      : sortedAndFilteredOrgChartData;

  useEffect(() => {
    if (hiddenNodes || !initialOrgChartData.data || !sortedAndFilteredOrgChartDataWithoutHiddenDepartments) {
      return;
    }
    setHiddenNodes(
      initialOrgChartData.data
        ?.filter(
          (node) =>
            !sortedAndFilteredOrgChartDataWithoutHiddenDepartments.some((sortedNode) => sortedNode.id === node.id)
        )
        .map((node) => ({
          hiddenNode: node,
          anotherNode: getTheSameNodeButForAnotherParentIfExist({
            isDepartmentChart,
            node,
            orgChartData: sortedAndFilteredOrgChartDataWithoutHiddenDepartments,
            hiddenDepartments: hiddenDepartments.data ?? [],
          }),
        }))
    );
  }, [hiddenNodes, initialOrgChartData, sortedAndFilteredOrgChartDataWithoutHiddenDepartments]);

  return {
    initialOrgChartData,
    sortedAndFilteredOrgChartData: sortedAndFilteredOrgChartDataWithoutHiddenDepartments,
    initiallySelectedNodeId,
    hiddenNodes,
  };
};
