import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  tableHead: {
    '& th:first-child': {
      paddingLeft: 0,
      fontWeight: 'bold',
    },
    '& th:not(:first-child)': {
      color: COLORS.TEXT.SECONDARY,
    },
  },
  tableBody: {
    '& tr': {
      height: '3.5rem',
    },

    '& td': {
      borderBottom: 'none',
      paddingTop: 0,
      paddingBottom: 0,

      '&:first-child': {
        paddingLeft: 0,
      },
    },
  },
});
