export enum DepartmentsIds {
  Pmo = '416726000004122239',
  Engeneering = '416726000000119001',
  DesignStudio = '416726000004078087',
  BusDevAndClientSuccess = '416726000004122381',
  EnterpriseSolutions = '416726000005855183',
  EngeneetingTeams = '416726000005855265',
  Finance = '416726000004566543',
  ClientSuccessAnalytics = '416726000006421027',
  Tom = '416726000009074347',
  Pr = '416726000008639007',
  PeopleOperations = '416726000008632076',
}
