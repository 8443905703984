import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {},
  emptyView: {
    width: '100%',
    height: 300,
    margin: 0,
  },
});
