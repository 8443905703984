import { FlagState } from '@modules/HappinessModule/enums/FlagState';
import { PageState } from '@shared/enums/PageState';

export const getFlagPageState = ({ flagState }: { flagState: FlagState }): PageState => {
  if (flagState === FlagState.Edit) {
    return PageState.EditView;
  } else if (flagState === FlagState.Resolved) {
    return PageState.ReadOnlyView;
  } else {
    return PageState.NoAccessView;
  }
};
