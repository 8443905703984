import { User } from '@shared/interfaces/user';
import { useEffect } from 'react';
import { logEvent, registerUser } from '@shared/helpers/analytics/analytics';
import { RockyAnalyticsEvent } from '@shared/enums/rockyAnalyticsEvent';

export const useAmplitude = (currentUser: User) => {
  useEffect(() => {
    registerUser(currentUser);
    logEvent(RockyAnalyticsEvent.CheckIn);
  }, [currentUser]);
};
