import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  fieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
  },
  goalCompletenessBadge: {
    display: 'inline-block',
    borderRadius: 20,
    padding: '4px 12px 5px',
    margin: '-4px 0 -5px',
    fontWeight: 500,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    color: COLORS.WHITE,
  },
});
