import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  evalStatus: {
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
  },
  overdue: {
    color: COLORS.STATUS.CRITICAL,
  },
  ongoing: {
    color: COLORS.STATUS.SUCCESS,
  },
  thisMonth: {
    color: COLORS.BRAND.PRIMARY,
  },
});
