import React, { FC } from 'react';
import { useStyles } from './NewHomeResolutionErrorModal.styles';
import { RockyModal } from '@shared/components/RockyModal';
import ErrorMapIcon from '@assets/img/errorMap.png';
import { Stack } from '@mui/system';
import { Button, Link, Typography } from '@mui/material';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

export const NewHomeResolutionErrorModal: FC<Props> = ({ handleClose, isOpen }) => {
  const styles = useStyles();

  return (
    <RockyModal
      modalStyles={styles.modal}
      crossIcon
      title="Unable to close the flag"
      isOpen={isOpen}
      onClose={handleClose}
    >
      <Stack justifyContent="center" alignItems="center" rowGap="25px">
        <img src={ErrorMapIcon} />
        <Typography textAlign="center" variant="body1">
          The NewHome resolution must be set first.
          <br />
          Please <Link href="mailto:tom@agileengine.com">contact the Talent Operations Team</Link> for the assistance.
        </Typography>
        <Button variant="contained" onClick={handleClose}>
          Got it
        </Button>
      </Stack>
    </RockyModal>
  );
};
