import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    minHeight: 200,
    height: '100vh',
    width: '100vw',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  signoutButton: {
    backgroundColor: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    border: 'none',
    height: 40,
    width: 120,
    fontWeight: '500',
    marginTop: 20,
    cursor: 'pointer',
    borderRadius: 8,
  },
});
