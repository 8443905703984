import React, { FC, memo, useCallback } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { SurveyQueue } from '../../interfaces';
import { useStyles } from './SurveyQueuesBlock.styles';
import { SurveyQueueCard } from '../SurveyQueueCard';
import { useDispatch } from 'react-redux';
import { ACTIVE_QUEUE_ID, INACTIVE_QUEUE_ID } from '../../constants';
import { setSurveyCategoryOrder } from '../../redux/actions';

interface Props {
  surveyQueue: SurveyQueue;
}

const SurveyQueuesBlockComponent: FC<Props> = ({ surveyQueue }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const activeCategories = surveyQueue.categories.filter(({ isActive }) => isActive);
  const inactiveCategories = surveyQueue.categories.filter(({ isActive }) => !isActive);

  const handleDragEnd = useCallback(
    (event) => {
      if (!event.destination) {
        return;
      }
      const isActive = event.destination.droppableId === ACTIVE_QUEUE_ID;
      const prevIsActive = event.source.droppableId === ACTIVE_QUEUE_ID;
      const order =
        inactiveCategories.length === 0
          ? activeCategories.length - 1
          : prevIsActive && !isActive && inactiveCategories.length > 0
          ? event.destination.index - 1
          : event.destination.index;

      dispatch(
        setSurveyCategoryOrder({
          categoryId: event.draggableId,
          order,
          isActive,
          prevIsActive,
        })
      );
    },
    [dispatch, inactiveCategories, activeCategories]
  );

  return (
    <div className={styles.root}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <SurveyQueueCard
          startingIndex={activeCategories.length}
          categories={inactiveCategories}
          queueId={INACTIVE_QUEUE_ID}
        ></SurveyQueueCard>
        <SurveyQueueCard categories={activeCategories} queueId={ACTIVE_QUEUE_ID}></SurveyQueueCard>
      </DragDropContext>
    </div>
  );
};

export const SurveyQueuesBlock = memo(SurveyQueuesBlockComponent);
