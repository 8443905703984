import React, { memo } from 'react';
import { useStyles } from './CloseEvalModal.module';
import { RockyModal } from '@shared/components/RockyModal';
import { RockyButton } from '@shared/components/RockyButton';
import classNames from 'classnames';

interface Props {
  closeModal: () => void;
  isModalOpen: boolean;
  changeModal: () => void;
  confirmFormSubmit: () => void;
}

const CloseEvalModalComponent: React.FC<Props> = ({ closeModal, isModalOpen, changeModal, confirmFormSubmit }) => {
  const styles = useStyles();

  return (
    <RockyModal
      onClose={closeModal}
      isOpen={isModalOpen}
      title="Closing evaluation"
      modalTitleStyles={styles.modalTitleStyles}
      modalBodyStyles={styles.modalBodyStyles}
      crossIcon={false}
    >
      <div>
        <p className={classNames(styles.modalText, styles.modalWarningText)}>
          After submitting you will not be able to edit your response.
        </p>
        <p className={styles.modalText}>Make sure you've written down everything you wanted to say.</p>
      </div>
      <div className={styles.modalBtnContainer}>
        <RockyButton className={styles.button} onClick={closeModal}>
          Cancel
        </RockyButton>
        <RockyButton
          action
          type="submit"
          className={styles.button}
          onClick={() => {
            changeModal();
            confirmFormSubmit();
          }}
        >
          Ok, let's proceed
        </RockyButton>
      </div>
    </RockyModal>
  );
};

export const CloseEvalModal = memo(CloseEvalModalComponent);
