import { apiUrl } from '@configs/environment';
import httpClient from '@shared/api/httpClient';
import { ResolveUserHappinessPayload } from '../pages/PeopleHappinessPage/interfaces/ResolveUserHappinessPayload';

export const postResolveSituation = (payload: ResolveUserHappinessPayload): void => {
  httpClient.post<void>(`${apiUrl}/resolved_by/`, {
    user_id: payload.userId,
    message_id: payload.questionId,
    notes: payload.resolvedBy.notes,
    resolved_at: payload.resolvedBy.resolvedAt,
  });
};
