import { put, all, call, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  getAllLocationsData,
  getQuestionGroupsData,
  setAllLocationsData,
  setAllLocationsDataLoading,
  setQuestionGroupsData,
  setQuestionGroupsDataLoading,
} from './actions';

import { DataState } from '@shared/enums/DataState';
import { SurveyHistoryPageData } from '@modules/HappinessModule/interfaces/SurveyHistoryPageData';
import { fetchQuestionGroupHistoryData } from '@modules/HappinessModule/api/questionGroupHistory';
import { fetchAllLocationsData } from '@modules/HappinessModule/api/allLocationsData';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';

function* getQuestionGroupsDataAsync({ payload }: ReturnType<typeof getQuestionGroupsData>) {
  try {
    const setLoadingAction = setQuestionGroupsDataLoading();
    yield put(setLoadingAction);
    const response: SurveyHistoryPageData[] = yield call(fetchQuestionGroupHistoryData, payload);
    const action = setQuestionGroupsData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setQuestionGroupsData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* getAllLocationsDataAsync() {
  const setLoadingAction = setAllLocationsDataLoading();
  yield put(setLoadingAction);
  try {
    const response: string[] = yield call(fetchAllLocationsData);
    const action = setAllLocationsData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch {
    yield put(
      setAllLocationsData({
        data: null,
        state: DataState.Rejected,
      })
    );
  }
}

function* watchQuestionGroupsData() {
  yield takeLatest(getQuestionGroupsData.type, getQuestionGroupsDataAsync);
}

function* watchAllLocationsData() {
  yield takeEvery(getAllLocationsData.type, getAllLocationsDataAsync);
}
export function* surveyHistoryPageSaga(): Generator {
  yield all([watchQuestionGroupsData(), watchAllLocationsData()]);
}
