import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { State } from './types';

const initialState: State = {
  addFlagDataState: { loadingState: DataState.Idle, isNoNavigation: false },
  userFlagCcDetails: { data: null, state: DataState.Pending },
  automaticFlagWatchers: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: 'flagFormPage',
  initialState,
  reducers: {
    setFlagDataState(state, { payload }: PayloadAction<{ loadingState: DataState; isNoNavigation?: boolean }>) {
      state.addFlagDataState = {
        loadingState: payload.loadingState,
        isNoNavigation: payload.isNoNavigation ?? false,
      };
    },
    setUserFlagCcDetails(state, { payload }) {
      state.userFlagCcDetails = payload;
    },
    setUserFlagCcDetailsLoading(state) {
      state.userFlagCcDetails = { data: null, state: DataState.Pending };
    },
    setAutomaticFlagWatchers(state, { payload }: PayloadAction<State['automaticFlagWatchers']>) {
      state.automaticFlagWatchers = payload;
    },
  },
});

export const { setFlagDataState, setUserFlagCcDetails, setUserFlagCcDetailsLoading, setAutomaticFlagWatchers } =
  slice.actions;

export default slice.reducer;
