import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  container: {
    height: 'calc(100vh - 85px)',
    margin: '40px 80px 45px 0',

    [BREAKPOINTS.TABLET]: {
      margin: '40px 0 45px 0',
    },
  },
  homeLink: {
    backgroundColor: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: 'none',
    height: 40,
    width: 120,
    fontWeight: '500',
    marginTop: 20,
    cursor: 'pointer',
    borderRadius: 8,
  },
  loaderHolder: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  '@keyframes blink': {
    '0%': { opacity: 0.3 },
    '50%': { opacity: 0.8 },
    '100%': { opacity: 0.3 },
  },
  loadingText: {
    color: COLORS.TEXT.SECONDARY,
    animation: '$blink 1.5s linear infinite',
    fontSize: '13px',
    fontWeight: '400',
    marginTop: '20px',
  },
});
