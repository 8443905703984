import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
    width: 24,

    '& svg': {
      fill: COLORS.BRAND.PRIMARY,
    },
  },
  overlayMenu: {
    position: 'fixed',
    backgroundColor: COLORS.WHITE,
    height: '100vh',
    width: '100vw',
    top: 0,
    left: 0,
    animation: '$slideUp 0.2s ease-out',
  },
  '@keyframes slideUp': {
    from: { left: '-100%' },
    to: { top: 0 },
  },
  hidden: {
    display: 'none',
  },
  menuBurgerControls: {
    margin: '16px 16px 36px',
  },
  close: {
    width: 26,
    height: 26,
    position: 'relative',

    '& svg': {
      fill: COLORS.BRAND.PRIMARY,
    },
  },
  feedbackHolder: {
    position: 'fixed',
    bottom: 16,
    left: 24,
    zIndex: 2,
  },
  staticButtonContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 6,
    cursor: 'pointer',
  },
  feedbackButtonStatic: {
    height: 45,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    transition: '0.2s ease-out',
    padding: 0,
    position: 'relative',

    [BREAKPOINTS.TABLET]: {
      height: 55,
    },
  },
  feedbackText: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
    transition: 'all 0.2s linear',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
    },
    textDecoration: 'none',
  },
});
