import { UserInfo } from '@shared/interfaces/UserInfo';

export const idToUser = (data: UserInfo[], param: string | null) =>
  data.reduce((acc, user) => {
    const arrOfIds = param?.split(',');
    arrOfIds?.map((id) => {
      if (id === user.id) {
        acc.push(user);
      }
    });
    return acc;
  }, [] as UserInfo[]);
