import { all } from 'redux-saga/effects';
import { evaluationsManegmentSaga } from './evaluationsManegment/sagas';
import { myTeamEvalDataPageSaga } from '../../EvaluationModule/pages/MyTeamEvalPage/redux/sagas';
import { evalPageDataSaga } from '../../EvaluationModule/pages/EvalPage/redux/sagas';
import { myPeopleEvalDataPageSaga } from '../../EvaluationModule/pages/MyPeopleEvalPage/redux/sagas';
import { userEvalPageSaga } from '../../EvaluationModule/pages/UserEvalPage/redux/sagas';
import { clientEvalPageSaga } from '../../EvaluationModule/pages/ClientEvalPage/redux/sagas';
import { peerEvalPageSaga } from '../../EvaluationModule/pages/PeerEvalPage/redux/sagas';
import { googleCalendarEvalSaga } from './googleCalendarEval/sagas';
import { myPeerReviewsSaga } from '../pages/MyPeerReviewsPage/redux/sagas';
import { evalSettingsPageSaga } from '../pages/EvalSettingsPage/redux/sagas';
import { clientContactPageSaga } from '../../EvaluationModule/pages/ClientContactPage/redux/sagas';
import { usersFlagsPageSaga } from '@modules/HappinessModule/pages/UserFlagsPage/redux/sagas';
import { userCompensationPageSaga } from '../pages/UserCompensationPage/redux/sagas';
import { externalLinksSaga } from '@modules/App/pages/ExternalLinksPage/redux/sagas';
import { userGoalsPageSaga } from '../pages/UserGoalsPage/redux/sagas';
import { userPromotionsPageSaga } from '../pages/UserPromotionsPage/redux/sagas';

export function* evaluationSaga(): Generator {
  yield all([
    myTeamEvalDataPageSaga(),
    myPeerReviewsSaga(),
    evalPageDataSaga(),
    myPeopleEvalDataPageSaga(),
    evaluationsManegmentSaga(),
    userEvalPageSaga(),
    clientEvalPageSaga(),
    clientContactPageSaga(),
    peerEvalPageSaga(),
    googleCalendarEvalSaga(),
    usersFlagsPageSaga(),
    userCompensationPageSaga(),
    evalSettingsPageSaga(),
    externalLinksSaga(),
    userGoalsPageSaga(),
    userPromotionsPageSaga(),
  ]);
}
