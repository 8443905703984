import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router';

import { LocationData } from '@shared/interfaces/LocationData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { getUserEvalPageData, getUserEvalPermissions } from '../redux/actions';
import { setUserEvalPageDataLoading } from '../redux/reducers';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { DataState } from '@shared/enums/DataState';
import { evalPageDataSelector, evalPermissionsSelector } from '../redux/selectors';
import { EvalPermissions } from '@modules/EvaluationModule/interfaces/EvalPermissions';

export const useMyEvalPageData = (): {
  evalPageData: AsyncData<EvalPageData>;
  fromLocation: LocationData | null;
  evalPermissions: AsyncData<EvalPermissions>;
} => {
  const [fromLocation, setFromLocation] = useState(null);
  const dispatch = useDispatch();
  const { evaluationId } = useParams();
  const location = useLocation();
  const evalPageData = useSelector(evalPageDataSelector);
  const evalPermissions = useSelector(evalPermissionsSelector);

  useEffect(() => {
    setFromLocation(location?.state);
  }, [location]);

  useEffect(() => {
    if (!evaluationId) {
      return;
    }
    dispatch(getUserEvalPageData(evaluationId));
    dispatch(getUserEvalPermissions(evaluationId));
    return () => {
      dispatch(setUserEvalPageDataLoading(DataState.Pending));
    };
  }, [dispatch, evaluationId]);

  return {
    evalPageData,
    fromLocation,
    evalPermissions,
  };
};
