import { FC, memo, useCallback } from 'react';
import { useStyles } from './SurveyQueueCard.styles';
import { Droppable } from 'react-beautiful-dnd';
import { SurveyCategory } from '../../interfaces';
import { CategoryCard } from '../CategoryCard';
import classNames from 'classnames';
import { ACTIVE_QUEUE_ID } from '../../constants';
import QueueOutlinedIcon from '@mui/icons-material/QueueOutlined';
import { useDispatch } from 'react-redux';
import { createCategory } from '../../redux/actions';
import { nanoid } from '@reduxjs/toolkit';

interface Props {
  categories: SurveyCategory[];
  queueId: string;
  startingIndex?: number;
}

const SurveyQueueCardComponent: FC<Props> = ({ categories, queueId, startingIndex = 0 }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const isActiveQueue = queueId === ACTIVE_QUEUE_ID;
  const handleAddCategoryClick = useCallback(
    () =>
      dispatch(
        createCategory({
          categoryName: 'New Category',
          categoryId: nanoid(),
          questionText: 'New Question',
          questionId: nanoid(),
        })
      ),
    [dispatch]
  );
  const totalActiveCategories = categories.length;

  return (
    <div className={classNames(styles.root, { [styles.activeQueueRoot]: isActiveQueue })}>
      <div className={styles.header}>
        <h2 className={styles.heading}>{isActiveQueue ? 'Survey Queue' : 'Inactive Surveys'}</h2>
        {!isActiveQueue && (
          <div className={styles.addCategoryButton} onClick={handleAddCategoryClick}>
            <QueueOutlinedIcon />
            <span className={styles.addCategoryButtonText}>Add new category</span>
          </div>
        )}
      </div>
      <Droppable droppableId={queueId}>
        {(provided) => (
          <div className={styles.queueHolder} ref={provided.innerRef} {...provided.droppableProps}>
            {categories.map((category, i) => (
              <CategoryCard
                isInActiveQueue={queueId === ACTIVE_QUEUE_ID}
                key={category.id}
                category={category}
                totalActiveCategories={totalActiveCategories}
                index={startingIndex + i}
              ></CategoryCard>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </div>
  );
};

export const SurveyQueueCard = memo(SurveyQueueCardComponent);
