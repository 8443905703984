import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  email: {
    overflow: 'hidden',
    maxWidth: 220,
    textOverflow: 'ellipsis',
    padding: '0 16px 0 0',
    position: 'relative',

    '& svg': {
      width: 16,
      height: 16,
      fill: COLORS.ACTION.PRIMARY,
      cursor: 'pointer',
      position: 'absolute',
      right: 0,
      top: 0,

      '&:hover': {
        fill: COLORS.ACTION.DARK,
      },
    },
  },
});
