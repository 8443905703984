import { FC, memo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import { COLORS } from '@styles/colors';
import { useStyles } from './UsersHappinessTable.styles';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { UserRate } from '@modules/HappinessModule/components/UserRate/UserRate';
import { UsersHappinessTableDataItem } from '@modules/HappinessModule/interfaces/usersHappinessTableDataItem';
import { NO_CHANGE_THRESHOLD, NO_MARK_THRESHOLD } from '@modules/HappinessModule/constants/reportConstants';
import classNames from 'classnames';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { paths } from '@shared/enums/paths';
import { Table } from '@shared/components/Table';
import { UserHappinessTableActions } from '../UserHappinessTableActions';
import { ReactComponent as NoDataIcon } from '@assets/icons/NoData.svg';

interface Props {
  data: UsersHappinessTableDataItem[] | null;
  sortInfo: SortInfo;
  isDataLoading: boolean;
  onSortClick: (sortInfo: SortInfo) => void;
}

const UsersHappinessTableComponent: FC<Props> = ({ data, sortInfo, isDataLoading, onSortClick }) => {
  const styles = useStyles();
  const location = useLocation();
  const isDataExist = data && data.length > 0;

  const tableHeaders = [
    { title: 'Reporter', fieldName: 'name' },
    { title: 'Rate', fieldName: 'mark' },
    { title: 'vs previous', fieldName: 'change' },
    { title: '' },
  ];

  const tableContent =
    data &&
    data.map((dataItem, i) => {
      const { name, mark, change, imageUrl, email, position, userId, reminderCount, country, city, isPrimaryReporter } =
        dataItem;

      return (
        <tr key={`${email}+${i}`} className={styles.tableDataRow}>
          <td>
            <Link
              className={styles.personBlock}
              to={`${paths.users}/${userId}/${paths.responses}`}
              state={{ path: location.pathname, name: 'team responses' }}
            >
              <RockyAvatar medium imageUrl={imageUrl} fullName={name || ''} country={country} city={city}></RockyAvatar>
              <div className={styles.personInfoBlock}>
                <p className={styles.personName}>{name}</p>
                <p className={styles.team}>{position}</p>
              </div>
            </Link>
          </td>
          <td>
            <div className={styles.rateHolder}>
              <UserRate reminderCount={reminderCount} rate={mark}></UserRate>
            </div>
          </td>
          <td>
            <span className={styles.mobileLabel}>Rate: {mark === NO_MARK_THRESHOLD ? 'N/A' : `${mark}/10`}</span>{' '}
            {change !== NO_CHANGE_THRESHOLD ? (
              <span
                className={styles.markDelta}
                style={{
                  color:
                    change > 0 ? COLORS.STATUS.SUCCESS : change === 0 ? COLORS.TEXT.SECONDARY : COLORS.STATUS.CRITICAL,
                }}
              >
                {change > 0 ? '+' : ''}
                <span className={styles.changeDesktop}>{change}</span>
                <span className={styles.changeMobile}>{change === 0 ? '' : change}</span>
              </span>
            ) : (
              <span className={classNames(styles.markDelta, styles.changeDesktop)}>N/A</span>
            )}
          </td>
          <td>
            {isPrimaryReporter ? (
              <UserHappinessTableActions
                isResolveAvailable
                userHappinessTableDataItem={dataItem}
              ></UserHappinessTableActions>
            ) : (
              <span className={styles.secondaryReporterLabel}>Secondary reporter</span>
            )}
          </td>
        </tr>
      );
    });

  const noDataContent = (
    <tr className={styles.tableDataRow}>
      <td colSpan={4}>
        <div className={styles.noContentBlock}>
          <NoDataIcon />
          No responses for this survey
        </div>
      </td>
    </tr>
  );

  const tableSkeleton = Array(5)
    .fill(0)
    .map((_, i) => (
      <tr key={i} className={styles.tableDataRow}>
        <td>
          <div className={styles.personBlockSkeleton}>
            <Skeleton className={styles.personAvatar} />
            <div className={styles.personInfoSkeleton}>
              <Skeleton className={styles.textSkeleton} />
              <Skeleton className={styles.textSkeleton} />
            </div>
          </div>
        </td>
        <td>
          <Skeleton className={styles.meterSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.cellSkeleton} />
        </td>
        <td>
          <Skeleton className={styles.cellSkeleton} />
        </td>
      </tr>
    ));

  return (
    <Table
      sortInfo={sortInfo}
      isDataLoading={isDataLoading}
      onSortClick={onSortClick}
      tableHeaders={isDataExist ? tableHeaders : []}
      skeleton={tableSkeleton}
      customStyles={{ tableHeaderRow: styles.tableHeaderRow, tableDataRow: styles.tableDataRow }}
    >
      {isDataExist ? tableContent : noDataContent}
    </Table>
  );
};

export const UsersHappinessTable = memo(UsersHappinessTableComponent);
