import React, { FC, useState } from 'react';
import { Grid, Stack } from '@mui/material';
import { FlagWatcherItem, FlagWatcherItemSharedProps } from '../FlagWatcherItem';
import { AutomaticFlagWatchers } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import {
  FLAG_WATCHERS_NO_PERMISSIONS,
  FLAG_WATCHERS_READ_PERMISSIONS,
  FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
} from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { CC_DESCRIPTIONS, CC_RECIPIENTS } from '../../../../constants/CcInfo';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { FlagWatcherRole } from '@modules/HappinessModule/pages/FlagPage/enums/flagWatcherRole';

interface Props
  extends FlagWatcherItemSharedProps,
    Pick<FlagFields, 'emailRecipients' | 'flagWatchers' | 'isRecipientsDisabled'> {
  userAutomaticFlagWatchers: AutomaticFlagWatchers;
  isNewFlag: boolean;
}

export const FlagWatchersTable: FC<Props> = ({
  userAutomaticFlagWatchers,
  isNewFlag,
  handleDeleteRecipientClick,
  addWatcherToFlagWatchers,
  toggleRecipients,
  emailRecipients,
  flagWatchers,
  isRecipientsDisabled,
}) => {
  const ccRecipients = emailRecipients.filter(
    ({ isCarbonCopy, userEmail }) =>
      isCarbonCopy &&
      Object.values(CC_RECIPIENTS).some(({ userEmail: ccEmail }) => ccEmail === userEmail) &&
      userEmail !== CC_RECIPIENTS.tom.userEmail
  );
  const isTomsIncludedToRecipients = emailRecipients.some(
    (ccRecipient) => ccRecipient.userEmail === CC_RECIPIENTS.tom.userEmail
  );
  const manuallyAddedFlagWatchersWithoutMainFlagWatchers = flagWatchers?.filter(
    ({ user }) =>
      !userAutomaticFlagWatchers.mainFlagWatchers.some(({ user: basicRecipient }) => basicRecipient.id === user.id)
  );
  const [isTomsWatchersIsExpanded, setIsTomsWatchersIsExpanded] = useState(false);
  const [isHrsWatchersIsExpanded, setIsHrsWatchersIsExpanded] = useState(false);

  const checkIfWatcherWasNotified = (watcherId: string | null): boolean =>
    emailRecipients.some((recipient) => recipient?.user && watcherId && recipient.user.id === watcherId);
  const checkIfUserInFlagWatchersList = (user: UserInfo) =>
    flagWatchers.some((flagWatcher) => flagWatcher.user?.id === user?.id);

  return (
    <Grid item xs={12}>
      <Stack>
        {[
          ...userAutomaticFlagWatchers.mainFlagWatchers,
          ...userAutomaticFlagWatchers.seniorManagers,
          ...manuallyAddedFlagWatchersWithoutMainFlagWatchers,
        ].map((flagWatcherData) => {
          const isReportingTo = flagWatcherData.flagWatcherRole === FlagWatcherRole.ReportingTo;
          const isInFlagWatchers = checkIfUserInFlagWatchersList(flagWatcherData.user);

          return flagWatcherData ? (
            <FlagWatcherItem
              isNewFlag={isNewFlag}
              key={flagWatcherData.user.id}
              {...{
                ...flagWatcherData,
                permissions:
                  isReportingTo && !isInFlagWatchers ? FLAG_WATCHERS_NO_PERMISSIONS : flagWatcherData.permissions,
              }}
              isNotified={checkIfWatcherWasNotified(flagWatcherData?.user?.id ?? null)}
              isRecipientsDisabled={isRecipientsDisabled}
              handleDeleteRecipientClick={handleDeleteRecipientClick}
              addWatcherToFlagWatchers={addWatcherToFlagWatchers}
              isInRecipients={emailRecipients.some((recipient) => recipient.user?.id === flagWatcherData.user.id)}
              isInFlagWatchers={isInFlagWatchers}
              toggleRecipients={toggleRecipients}
            />
          ) : null;
        })}
        {isTomsIncludedToRecipients && userAutomaticFlagWatchers.tomMembers.length > 0 && (
          <>
            <FlagWatcherItem
              isNewFlag={isNewFlag}
              permissions={userAutomaticFlagWatchers.tomMembers[0].permissions}
              canBeRemoved={false}
              flagWatcherRole={null}
              isAutoCc
              group={{
                title: `Talent Operations <${CC_RECIPIENTS.tom.userEmail}>`,
                description: CC_RECIPIENTS.tom.description,
                expander: {
                  isExpanded: isTomsWatchersIsExpanded,
                  setGroupExpanded: setIsTomsWatchersIsExpanded,
                },
              }}
              isRecipientsDisabled={isRecipientsDisabled}
            />
            {isTomsWatchersIsExpanded &&
              userAutomaticFlagWatchers.tomMembers.map((flagWatcherData) => (
                <FlagWatcherItem
                  isNewFlag={isNewFlag}
                  key={flagWatcherData.user.id}
                  {...flagWatcherData}
                  isRecipientsDisabled={isRecipientsDisabled}
                />
              ))}
          </>
        )}
        {userAutomaticFlagWatchers.hr.length > 0 && (
          <>
            <FlagWatcherItem
              isNewFlag={isNewFlag}
              permissions={userAutomaticFlagWatchers.hr[0].permissions}
              canBeRemoved={false}
              flagWatcherRole={null}
              isNotBeingNotified
              group={{
                title: 'Everyone from HR department',
                description: 'HR team members can access any flags',
                expander: {
                  isExpanded: isHrsWatchersIsExpanded,
                  setGroupExpanded: setIsHrsWatchersIsExpanded,
                },
              }}
              isRecipientsDisabled={isRecipientsDisabled}
            />
            {isHrsWatchersIsExpanded &&
              [...userAutomaticFlagWatchers.hr, ...userAutomaticFlagWatchers.hrAssistants].map((flagWatcherData) => (
                <FlagWatcherItem
                  isNewFlag={isNewFlag}
                  key={flagWatcherData.user.id}
                  {...flagWatcherData}
                  isRecipientsDisabled={isRecipientsDisabled}
                />
              ))}
          </>
        )}
        {ccRecipients.map(({ userEmail, description }) => (
          <FlagWatcherItem
            key={userEmail}
            isNewFlag={isNewFlag}
            permissions={
              userEmail === CC_RECIPIENTS.flags.userEmail
                ? FLAG_WATCHERS_READ_PERMISSIONS
                : FLAG_WATCHERS_READ_UPDATE_PERMISSIONS
            }
            canBeRemoved={false}
            flagWatcherRole={null}
            isAutoCc
            group={{
              title: userEmail,
              description: description ?? CC_DESCRIPTIONS[userEmail],
            }}
            isRecipientsDisabled={isRecipientsDisabled}
          />
        ))}
      </Stack>
    </Grid>
  );
};
