import { combineReducers } from 'redux';
import usersHappinessPageReducer from '../pages/MyTeamHappinessPage/redux/reducers';
import surveyManagementPageReducer from '../pages/SurveyManagementPage/redux/reducers';
import userHappinessPageReducer from '../pages/UserHappinessPage/redux/reducers';
import surveyHistoryPageReducer from '../pages/SurveyHistoryPage/redux/reducers';
import questionsReducer from './questions/reducers';
import chatPageReducer from '../pages/ChatPage/redux/reducers';
import peopleHappinessPageReducer from '../pages/PeopleHappinessPage/redux/reducers';
import myTeamFlagsReduser from '../pages/MyTeamFlagsPage/redux/reducers';
import editAndPreviewFlagPageReducer from '../pages/FlagPage/pages/EditFlagFormPage/redux/reducers';
import usersFlagsReduser from '../pages/UserFlagsPage/redux/reducers';
import createFlagFormPageReducer from '../pages/FlagPage/pages/CreateFlagFormPage/redux/reducers';
import allFlagsPageReducer from '../pages/AllFlagsPage/redux/reducers';
import clientSurveyResponsePageReducer from '../pages/ClientSurveyResponsePage/redux/reducers';
import clientSurveysPageReducer from '../pages/ClientSurveysPage/redux/reducers';

export default combineReducers({
  myTeamHappinessPage: usersHappinessPageReducer,
  surveyManagementPage: surveyManagementPageReducer,
  userHappinessPage: userHappinessPageReducer,
  surveyHistoryPage: surveyHistoryPageReducer,
  peopleHappinessPage: peopleHappinessPageReducer,
  questions: questionsReducer,
  chatPage: chatPageReducer,
  myTeamFlags: myTeamFlagsReduser,
  usersFlags: usersFlagsReduser,
  flagFormPage: createFlagFormPageReducer,
  flagPage: editAndPreviewFlagPageReducer,
  allFlagsPage: allFlagsPageReducer,
  clientSurveyResponsePage: clientSurveyResponsePageReducer,
  clientSurveysPage: clientSurveysPageReducer,
});
