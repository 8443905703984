import httpClient from '@shared/api/httpClient';
import { apiUrl } from '@configs/environment';
import { AxiosResponse } from 'axios';

export interface SurveyQueueResponse {
  data: SurveyQueueResponseData;
}

export interface SurveyQueueResponseData {
  queue: {
    question: {
      id: string;
      text: string;
      question_group: {
        id: string;
        name: string;
      };
    };
    is_active: boolean;
    question_position: number;
    group_position: number;
  }[];
  last_survey: {
    date: string;
    question_text: string;
    category_name: string;
  };
}

export const fetchSurveyQueueData = (): Promise<SurveyQueueResponse> =>
  httpClient.get<SurveyQueueResponse>(`${apiUrl}/questions-queue/`).then((resp) => {
    return resp.data;
  });

export const patchSurveyQuestionOrder = (questionId: string, order: number): void => {
  httpClient.patch<void>(`${apiUrl}/questions-queue/question-change/`, {
    question_id: questionId,
    new_position: order,
  });
};

export const patchSurveyCategoryOrder = (categoryId: string, order: number): void => {
  httpClient.patch<void>(`${apiUrl}/questions-queue/category-change/`, {
    category_id: categoryId,
    new_position: order,
  });
};

export const patchActivateCategoryAndChangeOrder = (categoryId: string, order: number): void => {
  httpClient.patch<void>(`${apiUrl}/questions-queue/activate/`, {
    category_id: categoryId,
    new_position: order,
  });
};

export const patchDeactivateCategoryAndChangeOrder = (categoryId: string, order: number): void => {
  httpClient.patch<void>(`${apiUrl}/questions-queue/deactivate/`, {
    category_id: categoryId,
    new_position: order,
  });
};

export const patchCategoryName = (categoryId: string, name: string): void => {
  httpClient.patch<void>(`${apiUrl}/questions-category/${categoryId}/`, {
    name,
  });
};

export const patchQuestionText = (questionId: string, text: string): void => {
  httpClient.patch<void>(`${apiUrl}/questions/update/${questionId}/`, {
    question_text: text,
  });
};

export const deleteCategoryEntity = (categoryId: string): void => {
  httpClient.delete<void>(`${apiUrl}/questions-category/${categoryId}/`);
};

export const deleteQuestionEntity = (questionId: string): void => {
  httpClient.delete<void>(`${apiUrl}/questions/delete/${questionId}/`);
};

export const postQuestion = ({
  questionId,
  categoryId,
  text,
}: {
  questionId: string;
  categoryId: string;
  text: string;
}): void => {
  httpClient.post<void>(`${apiUrl}/questions/create/`, {
    question_id: questionId,
    group_id: categoryId,
    text,
  });
};

export const postCategory = ({
  questionId,
  categoryId,
  questionText,
  categoryName,
}: {
  questionId: string;
  categoryId: string;
  questionText: string;
  categoryName: string;
}): void => {
  httpClient.post<void>(`${apiUrl}/questions-category/create/`, {
    question_id: questionId,
    category_id: categoryId,
    question_text: questionText,
    category_name: categoryName,
  });
};

export const fetchRunSurveyStatus = (): Promise<AxiosResponse> => httpClient.post<void>(`${apiUrl}/run-survey/`);
