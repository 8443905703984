import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  selectBlock: {
    position: 'relative',
  },
  searchIcon: {
    '& svg': {
      width: '24px',
      height: '24px',
      color: COLORS.INPUTBORDER,
      position: 'absolute',
      left: '19px',
      top: '15px',
      zIndex: 5,
      pointerEvents: 'none',
      boxSizing: 'border-box',
    },
  },
  correctReportBlock: {
    margin: '16px 0',
  },
  dropdownIcon: {
    position: 'absolute',
    right: 10,
    top: 'calc(50% - 0.9em)',
    '& svg': {
      width: 20,
      height: 20,
      display: 'inline-block',
      pointerEvents: 'none',
      fill: 'rgba(0, 0, 0, 0.54)',
    },
  },
  title: {
    color: COLORS.TEXT.PRIMARY,
    fontWeight: 400,
    fontSize: 16,
    display: 'block',
    margin: '10px 0',
  },
});
