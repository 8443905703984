import { apiUrl } from '@configs/environment';
import httpClient from '@shared/api/httpClient';
import { SurveyHistoryPageData } from '../interfaces/SurveyHistoryPageData';
import { SurveyHistoryPageDataParams } from '../interfaces/SurveyHistoryPageDataParams';
import { QuestionGroupHistoryData } from './types';

export const fetchQuestionGroupHistoryData = ({
  locationName,
  departmentId,
}: SurveyHistoryPageDataParams): Promise<SurveyHistoryPageData[]> =>
  httpClient
    .get<QuestionGroupHistoryData>(`${apiUrl}/question_group_history/`, {
      params: { location: locationName, department: departmentId },
    })
    .then((resp) => {
      const data = resp.data.data.map((r) => {
        const { group_id, name, questions } = r;

        return {
          groupId: group_id,
          name,
          questions: questions.map((q) => {
            const { text, start_date, total_voted, total_people, average } = q;

            return {
              text,
              startDate: start_date,
              totalVoted: total_voted,
              totalPeople: total_people,
              average,
            };
          }),
        };
      });

      return data;
    });
