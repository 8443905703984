import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  goalItem: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    transition: 'all 100ms linear',
    '&:last-child': {
      borderBottom: 'none',
    },
    '& td': {
      padding: '16px',
      verticalAlign: 'middle',
      [BREAKPOINTS.MOBILE]: {
        verticalAlign: 'top',
      },
    },
    '& td:first-child': {
      paddingLeft: '24px',
    },
    '& td:last-child': {
      paddingRight: '24px',
    },
    '&:hover': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
      cursor: 'pointer',
    },
    [BREAKPOINTS.MOBILE]: {
      '& td:first-child': {
        paddingLeft: '16px',
      },
      '& td:last-child': {
        paddingRight: '16px',
      },
    },
  },
  goalDialogClose: {
    position: 'absolute',
    right: 12,
    top: 16,
    color: COLORS.TEXT.DISABLED,
  },
});
