import React, { FC, memo, useCallback, useEffect } from 'react';
import { clearCustomToken } from '@shared/helpers/auth';
import { useStyles } from './UnauthorizedPage.styles';
import { useNavigate } from 'react-router';
import { setCurrentUser } from '@modules/App/redux/user/actions';
import { useDispatch } from 'react-redux';
import { DataState } from '@shared/enums/DataState';
import { useAuth } from '@shared/hooks/useAuth';
import { paths } from '@shared/enums/paths';
import isEmpty from 'lodash/isEmpty';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';

const UnauthorizedPageComponent: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(pageTitles.unauthorizedPage);
  }, []);

  useEffect(() => {
    if (!isEmpty(currentUser?.rockyRoles)) {
      navigate(paths.root);
    }
  }, [currentUser]);

  const signOut = useCallback(() => {
    clearCustomToken();
    navigate(paths.root);

    dispatch(setCurrentUser({ data: null, state: DataState.Pending }));
  }, [navigate]);

  return (
    <div className={styles.root}>
      <h1>Looks like you don't have permissions to use Rocky</h1>
      <h2>Please, contact your people partner to start using the website</h2>
      {/* TODO: Looks like this page is almost never usable in Rocky, though we need to make it working */}
      {/* Also get rid of old signOut func and replace with firebase one */}
      <button className={styles.signoutButton} onClick={signOut}>
        Sign out
      </button>
    </div>
  );
};

export const UnauthorizedPage = memo(UnauthorizedPageComponent);
