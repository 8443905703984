import { RockyButton } from '@shared/components/RockyButton';
import { RockyModal } from '@shared/components/RockyModal';
import React, { FC } from 'react';
import { useStyles } from './AddNewReviewerWarningModal.styles';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  onAdd: () => void;
  isClient?: boolean;
}

export const AddNewReviewerWarningModal: FC<Props> = ({ isModalOpen, closeModal, isClient, onAdd }) => {
  const styles = useStyles();

  return (
    <RockyModal
      title="New reviewer"
      isOpen={isModalOpen}
      onClose={closeModal}
      modalBodyStyles={styles.modalBody}
      crossIcon
    >
      <div>
        <div className={styles.modalBodyTitle}>{`${
          isClient ? 'Client' : 'Peer'
        } will receive an email once you add them.`}</div>
        <div className={styles.modalBodySubitle}>
          Also they will be reminded 3 times each Monday before the evaluation.
        </div>
        <div className={styles.modalBtnContainer}>
          <RockyButton onClick={() => closeModal()}>Cancel</RockyButton>
          <RockyButton
            action
            onClick={() => {
              onAdd && onAdd();
              closeModal();
            }}
          >
            Continue
          </RockyButton>
        </div>
      </div>
    </RockyModal>
  );
};
