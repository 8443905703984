import { emojisplosion } from 'emojisplosion';

export const launchEmojisplosion = (xPos: number, yPos: number): void => {
  emojisplosion({
    emojis: ['🤩', '👍', '❤️', '🍭'],
    position: { x: xPos, y: yPos },
    physics: {
      fontSize: {
        max: 72,
        min: 16,
      },
    },
  });
};
