import React, { useEffect, useRef, useState } from 'react';
import { useStyles } from './ProfileComponent.styles';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { useAuth } from '@shared/hooks/useAuth';
import { getGoogleCalendarMeetingLink } from '@modules/HappinessModule/helpers/googleCalendar';
import { getMailForUser } from '@modules/App/pages/UserProfilePage/helpers/getMailForUser';
import { UserReporterBlock } from '../UserReporterBlock/UserReporterBlock';
import { UserInfo } from '@shared/interfaces/UserInfo';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import classNames from 'classnames';
import { paths } from '@shared/enums/paths';
import { Link } from 'react-router-dom';
import { getUserSeniority } from '@shared/helpers/getUserSeniority';
import { getUserProjectName } from '@shared/helpers/getUserProjectName';
import { useClickOutside } from '@shared/hooks/useClickOutside';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { initialValuesStructure } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { useDispatch } from 'react-redux';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { UserActionsPermissions } from '@modules/App/interfaces/UserActionsPermissions';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { Link as MuiLink, Stack } from '@mui/material';

interface Props {
  user?: ReturnType<typeof userSelector>;
  isLoading: boolean;
  isNoActions?: boolean;
  permissions: UserActionsPermissions | null;
}

export const ProfileComponent: React.FC<Props> = ({ user, isLoading, isNoActions, permissions }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const [isOpen, setIsOpen] = useState(false);
  const { isFlagsEnabled } = useUserEnabledFeatures();

  const openFeedback = () => {
    dispatch(setIsFeedbackOpen({ isOpen: true, initialValues: initialValuesStructure }));
  };

  const wrapperRef = useRef(null);
  useClickOutside(wrapperRef, () => {
    setIsOpen(false);
  });
  const calendarLink = getGoogleCalendarMeetingLink({
    inviterName: currentUser?.name || '',
    inviteeEmail: user?.email || '',
    inviteeName: user?.name || '',
  });
  const emailLink = getMailForUser({
    name: user?.name?.split(' ')[0] || '',
    email: user?.email || '',
  });
  const userSeniority = getUserSeniority({ position: user?.position, seniority: user?.seniority });

  useEffect(() => {
    setIsOpen(false);
  }, [user]);

  const hasReportingTo =
    user?.primaryReportsTo && [user?.primaryReportsTo, user?.secondaryReportsTo, user?.locationHr].some((r) => r);

  const actionItem = (title: string, icon: React.ReactNode, href: string, internal: boolean) =>
    isLoading ? (
      <div className={styles.link}>
        <RockySkeleton className={styles.iconSkeleton} element={<div></div>} isLoading={isLoading} />
        <RockySkeleton className={styles.smallTextSkeleton} element={<div></div>} isLoading={isLoading} />
      </div>
    ) : internal ? (
      <Link className={styles.link} to={href}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.linkTitle}>{title}</div>
      </Link>
    ) : (
      <a className={styles.link} href={href} target={'_blank'}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.linkTitle}>{title}</div>
      </a>
    );

  const infoRowReportTo = (title: string, value: UserInfo | null, isLink?: boolean) =>
    isLoading ? (
      <div className={styles.infoBlock}>
        <RockySkeleton className={styles.smallTextSkeleton} element={<div></div>} isLoading={isLoading} />
        <UserReporterBlock isLoading={isLoading} isDetailInfo user={{ imageUrl: '', name: '', position: '' }} />
      </div>
    ) : (
      value && (
        <div className={styles.infoBlock}>
          <div className={styles.secondaryText}>{title}</div>
          <UserReporterBlock isLink={isLink} isDetailInfo user={value} />
        </div>
      )
    );

  return (
    <div className={styles.header}>
      <div className={styles.user}>
        <RockySkeleton
          element={
            <RockyAvatar
              imageUrl={user?.imageUrl}
              country={user?.country}
              city={user?.city}
              fullName={user?.name || ''}
              large
              className={styles.avatar}
            />
          }
          isLoading={isLoading}
          className={styles.avatar}
        />
        <div className={styles.mainInfoBlock}>
          <RockySkeleton
            className={styles.mediumTextSkeleton}
            element={<div className={styles.mainText}>{user?.name}</div>}
            isLoading={isLoading}
          />
          <RockySkeleton
            className={styles.mediumTextSkeleton2}
            element={<div className={styles.secondaryText}>{`${userSeniority} ${user?.position ?? ''}`}</div>}
            isLoading={isLoading}
          />
          <RockySkeleton
            className={styles.smallTextSkeleton}
            element={
              <div className={styles.secondaryText}>
                {user?.primaryDepartment
                  ? getUserProjectName({
                      project: user?.primaryDepartment.name ?? null,
                      account: user?.account ?? null,
                    })
                  : ''}
              </div>
            }
            isLoading={isLoading}
          />
        </div>
      </div>
      {!isNoActions && (
        <div className={styles.actions}>
          {actionItem('Write email', <ForwardToInboxOutlinedIcon />, emailLink, false)}
          {actionItem('Schedule 1-on-1', <VideoCameraFrontOutlinedIcon />, calendarLink, false)}
          {permissions?.flagPermissions.flag.isCreatable &&
            isFlagsEnabled &&
            actionItem('Raise flag', <FlagOutlinedIcon />, `/${paths.flags}/create/${user?.id}`, true)}
        </div>
      )}
      {isLoading ? (
        <button className={styles.reportsBlock}>
          {infoRowReportTo('Reporting to', user?.primaryReportsTo ?? null, true)}
        </button>
      ) : (
        hasReportingTo && (
          <div ref={wrapperRef}>
            <button className={styles.reportsBlock} onClick={() => setIsOpen(true)}>
              <div className={styles.reportsToContainter}>
                {infoRowReportTo('Reporting to', user?.primaryReportsTo)}
              </div>
              <div className={styles.toggleIcon}>
                <ExpandMoreIcon />
              </div>
            </button>
            {isOpen && (
              <div className={classNames(styles.reportsBlock, { [styles.reportsBlockActive]: isOpen })}>
                <div className={styles.reportsToContainter}>
                  {infoRowReportTo('Reporting to', user?.primaryReportsTo, true)}
                  {infoRowReportTo('Secondary reporting', user?.secondaryReportsTo, true)}
                  {infoRowReportTo('Engineering manager', user?.engineeringManager, true)}
                  {infoRowReportTo('Local HR Contact', user?.locationHr, true)}
                  <Stack gap="4px">
                    <MuiLink component={Link} to={`${paths.orgChart}/${user.id}`}>
                      <span className={styles.reportIssue}>View in org chart</span>
                    </MuiLink>
                    <span className={styles.reportIssue} onClick={openFeedback}>
                      Report incorrect "Reporting to"
                    </span>
                  </Stack>
                </div>
                <div className={styles.toggleIcon}>
                  <div className={styles.rotate}>
                    <ExpandMoreIcon />
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};
