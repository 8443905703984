import { ClientEvalData } from '@modules/EvaluationModule/interfaces/ClientEvalData';
import { SubmitEvalPayloadClient } from '@modules/EvaluationModule/interfaces/SubmitEvalPayload';
import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from '@shared/interfaces/asyncData';

export const getClientEvalData = createAction<string>('clientEvalPage/getClientEvalData');
export const setClientEvalData = createAction<AsyncData<ClientEvalData>>('clientEvalPage/setClientEvalData');
export const postClientEvalDataAction = createAction<SubmitEvalPayloadClient>(
  'clientEvalPage/postClientEvalDataAction'
);
export const autoSaveClientEvalForm = createAction<SubmitEvalPayloadClient>('clientEvalPage/autoSaveClientEvalForm');
