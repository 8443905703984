import { apiUrlNode } from '@configs/environment';
import httpNodeClient from '@shared/api/httpNodeClient';
import { FeedbackData } from '../interfaces/feedback';

export const postFeedback = (feedbackData: FeedbackData): Promise<void> => {
  const formData = new FormData();
  formData.append('feedbackText', feedbackData.feedbackText);
  formData.append('type', feedbackData.feedbackReason);
  formData.append('teamMember', feedbackData.teamMember);
  feedbackData.attachmentFiles.forEach((file) => {
    formData.append('attachmentFiles', file.file);
  });

  return httpNodeClient
    .post(`${apiUrlNode}/feedback/submit`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(({ data }) => data);
};
