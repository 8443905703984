import { RootState } from '@modules/App/redux/store';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { State } from './types';
import { createSelector } from '@reduxjs/toolkit';
import { getPeopleHappinessDataGroupedExpanded } from '../helpers/getPeopleHappinessDataGroupedExpanded';

export const selectPeopleHappinessData = (state: RootState): State['peopleHappinessData'] =>
  state.happiness.peopleHappinessPage.peopleHappinessData;

export const sortPeopleHappinessPageInfoSelector = (state: RootState): SortInfo =>
  state.happiness.peopleHappinessPage.sortInfo;

export const isPeopleHappinessDataLoadingSelector = (state: RootState): boolean =>
  state.happiness.peopleHappinessPage.dataChunkLoading;

export const happinessFiltersSelector = (state: RootState): State['filters'] =>
  state.happiness.peopleHappinessPage.filters;

export const happinessApplyFiltersSelector = (state: RootState): State['filtersToAplly'] =>
  state.happiness.peopleHappinessPage.filtersToAplly;

export const groupBySelector = (state: RootState): State['groupBy'] => state.happiness.peopleHappinessPage.groupBy;

export const peopleHappinessDataGroupedSelector = (state: RootState): State['peopleHappinessGroupedData'] =>
  state.happiness.peopleHappinessPage.peopleHappinessGroupedData;

export const peopleHappinessDataGroupedExpandedSelector = createSelector(
  [peopleHappinessDataGroupedSelector, happinessApplyFiltersSelector],
  (peopleHappinessDataGrouped, filterInfo) => {
    return {
      ...peopleHappinessDataGrouped,
      data: peopleHappinessDataGrouped.data
        ? {
            totalCount: peopleHappinessDataGrouped.data.totalCount,
            items:
              getPeopleHappinessDataGroupedExpanded({
                peopleHappinessDataGrouped: peopleHappinessDataGrouped.data?.items ?? null,
                filterInfo,
              }) ?? [],
          }
        : null,
    };
  }
);
