import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  toggleContainer: {
    minWidth: 30,
    minHeight: 17,
    borderRadius: 48,
    padding: 3,
    cursor: 'pointer',
    backgroundColor: COLORS.TEXT.SECONDARY,
  },
  toggleItem: {
    width: 11,
    height: 11,
    borderRadius: '50%',
    backgroundColor: COLORS.WHITE,
  },
  toggleContainerActiveOn: {
    backgroundColor: COLORS.BRAND.PRIMARY,
    '& div': {
      transform: 'translateX(12px)',
    },
  },
  toggleItemActiveOff: {},
  toggleItemActiveOn: {},
  toggleContainerActiveOff: {},
});
