import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';

export const useStyles = makeStyles({
  root: {
    padding: 40,
    display: 'flex',
    gap: 50,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      padding: '32px 16px',
      gap: 64,
    },
  },
  loaderHolder: {
    width: '100%',
    height: 260,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});
