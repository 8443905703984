import { UserEvalPageData } from '@modules/EvaluationModule/interfaces/UserEvalPageData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUserEvalData } from '../redux/actions';
import { userEvalDataSelector } from '../redux/selectors';

export const useMyEvalData = (id?: string): AsyncData<UserEvalPageData> => {
  const userEvalData = useSelector(userEvalDataSelector);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id || userEvalData.data?.user.id === id) {
      return;
    }
    dispatch(getUserEvalData(id));
  }, [id]);

  return userEvalData;
};
