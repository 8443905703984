import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { useStyles } from './PromotionsTable.styles';
import { Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { UserPromotionsData } from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { EmptyPromotionView } from '../EmptyPromotionView/EmptyPromotionView';
import { PromotionTableRow } from '../PromotionTableRow/PromotionTableRow';
import { ManagePromotionButton } from '../ManagePromotionButton/ManagePromotionButton';

interface Props {
  data: UserPromotionsData[] | null;
  isDataLoading: boolean;
  isPromotionsUpdatable?: boolean;
}

export const PromotionsTable: React.FC<Props> = ({ data, isDataLoading, isPromotionsUpdatable }) => {
  const styles = useStyles();
  const isDataExist = data && data.length > 0;

  const tableSkeleton = (
    <Table>
      <TableBody>
        {Array(5)
          .fill(0)
          .map((_, i) => (
            <TableRow key={i}>
              <TableCell>
                <Skeleton className={styles.meterSkeleton} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.meterSkeleton} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.meterSkeleton} />
              </TableCell>
              <TableCell>
                <Skeleton className={styles.meterSkeleton} />
              </TableCell>
              <TableCell>
                <div className={styles.memberBlock}>
                  <div className={styles.personBlock}>
                    <Skeleton className={styles.personAvatar} />
                    <div className={styles.personInfoBlock}>
                      <Skeleton className={styles.personNameSkeleton} />
                    </div>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );

  const tableContent =
    data &&
    data.map((promotion, i) => {
      const prevPromotion = i < data.length && data[i + 1];

      return (
        <PromotionTableRow
          promotion={promotion}
          prevPromotion={prevPromotion || null}
          key={i}
          isPromotionsUpdatable={isPromotionsUpdatable}
        />
      );
    });

  return (
    <>
      <Stack
        direction="row"
        sx={{ justifyContent: 'space-between', alignItems: 'center', padding: '0 24px', height: 71 }}
      >
        <Typography variant="h2">Promotions and title changes</Typography>
        {isDataExist && isPromotionsUpdatable && <ManagePromotionButton />}
      </Stack>
      {isDataLoading ? (
        tableSkeleton
      ) : !isDataExist ? (
        <EmptyPromotionView />
      ) : (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow className={styles.tableHeader}>
                  <TableCell align="left">Date</TableCell>
                  <TableCell align="left">Position</TableCell>
                  <TableCell align="left">Reason</TableCell>
                  <TableCell align="left">Manager</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tableContent}</TableBody>
            </Table>
          </TableContainer>
          <Typography variant="body2" className={styles.footer}>
            Past career history is not available in Rocky yet
          </Typography>
        </>
      )}
    </>
  );
};
