import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { State } from './types';
import { SLICE_NAME } from '../constants/sliceName';
import { AsyncData } from '@shared/interfaces/asyncData';
import { ExternalLinks } from '../interfaces/ExternalLinks';

const initialState: State = {
  externalLinks: { data: null, state: DataState.Idle },
  pipLinks: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setExternalLinkDataState(state, action: PayloadAction<DataState>) {
      state.externalLinks.state = action.payload;
    },
    setExternalLinks(state, action: PayloadAction<AsyncData<ExternalLinks>>) {
      state.externalLinks = action.payload;
    },
    setPipLinksDataState(state, action: PayloadAction<DataState>) {
      state.pipLinks.state = action.payload;
    },
    setPipLinks(state, { payload }) {
      state.pipLinks = payload;
    },
  },
});

export const { setExternalLinks, setExternalLinkDataState, setPipLinksDataState, setPipLinks } = slice.actions;

export default slice.reducer;
