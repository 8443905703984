import { ExternalLinks } from '../interfaces/ExternalLinks';
import { PipLinkBody } from '../interfaces/PipLinkBody';

interface Props {
  externalLinks: ExternalLinks | null;
  pipLinks: PipLinkBody[] | null;
  isOneToOneAvailable: boolean;
  isPdpAvailable: boolean;
  isEvaluationAvailable: boolean;
  isEngineeringManagerNotesAvailable: boolean;
  isCvAvailable: boolean;
}

export const isSomeLinkExist = ({
  externalLinks,
  pipLinks,
  isOneToOneAvailable,
  isPdpAvailable,
  isEvaluationAvailable,
  isEngineeringManagerNotesAvailable,
  isCvAvailable,
}: Props): boolean => {
  if (!externalLinks && !pipLinks) {
    return false;
  }
  const isPipLinksExist = pipLinks && pipLinks.length > 0;
  const isCvLinkExist = externalLinks?.cvLink !== null && isCvAvailable;
  const isEngineeringManagerNotesLinkExist =
    externalLinks?.engineeringManagerNotesLink !== null && isEngineeringManagerNotesAvailable;
  const isEvaluationLinkExist = externalLinks?.evaluationLink !== null && isEvaluationAvailable;
  const isOneToOneLinkExist = externalLinks?.oneToOneNotesLink !== null && isOneToOneAvailable;
  const isPdpLinkExist = externalLinks?.pdpLink !== null && isPdpAvailable;
  if (
    isPipLinksExist ||
    isCvLinkExist ||
    isEngineeringManagerNotesLinkExist ||
    isEvaluationLinkExist ||
    isOneToOneLinkExist ||
    isPdpLinkExist
  ) {
    return true;
  }

  return false;
};
