import classNames from 'classnames';
import React, { FC, memo, ReactNode, useCallback, useEffect, useState } from 'react';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useStyles } from './MenuBurger.styles';
import { paths } from '@shared/enums/paths';
import { initialValuesOther } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { Link } from 'react-router-dom';
import { FeedbackFormData } from '@modules/App/interfaces/FeedbackFormData';
import { useDispatch } from 'react-redux';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';

interface Props {
  children: ReactNode;
}

const MenuBurgerComponent: FC<Props> = ({ children }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const styles = useStyles();
  const dispatch = useDispatch();

  const openFeedbackForm = (initialValues: FeedbackFormData) =>
    dispatch(setIsFeedbackOpen({ isOpen: true, initialValues }));

  useEffect(() => {
    document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMenuOpen]);

  const handleBurgerClick = useCallback(() => {
    setIsMenuOpen(true);
  }, [setIsMenuOpen]);

  const handleCloseClick = useCallback(() => {
    setIsMenuOpen(false);
  }, [setIsMenuOpen]);

  return (
    <>
      <div className={styles.root} onClick={handleBurgerClick}>
        <MenuRoundedIcon />
      </div>
      <div className={classNames(styles.overlayMenu, { [styles.hidden]: !isMenuOpen })}>
        <div className={styles.menuBurgerControls}>
          <div className={styles.close} onClick={handleCloseClick}>
            <CloseRoundedIcon />
          </div>
        </div>
        <div onClick={handleCloseClick}>{children}</div>
        <div className={styles.feedbackHolder}>
          <div className={styles.staticButtonContainer}>
            <button
              className={styles.feedbackButtonStatic}
              onClick={() => {
                openFeedbackForm(initialValuesOther);
              }}
            >
              <span className={styles.feedbackText}>Report issue</span>
            </button>
            <Link to={paths.whatIsNew} className={styles.feedbackText}>
              What's new
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export const MenuBurger = memo(MenuBurgerComponent);
