import classNames from 'classnames';
import { FC } from 'react';
import { OptionProps } from 'react-select';
import { useStyles } from './QuestionOption.styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { Typography } from '@mui/material';

interface QuestionOptionData {
  label: string;
  value: string;
  date: string;
  unresolvedCount: number;
  isUnresolvedCount?: boolean;
}

const QuestionOptionComponent: FC<OptionProps> = ({ innerProps, isDisabled, isSelected, label, data }) => {
  const styles = useStyles();
  const { date, unresolvedCount, isUnresolvedCount } = data as QuestionOptionData;

  return !isDisabled ? (
    <div {...innerProps} className={classNames(styles.root, { [styles.isFocused]: isSelected })}>
      <div className={styles.labelContainer}>
        <div className={styles.label}>{label}</div>
        {isUnresolvedCount && unresolvedCount > 0 && (
          <Typography variant="body2" className={styles.unresolvedLabel}>
            {unresolvedCount} unresolved
          </Typography>
        )}
        <small className={classNames(styles.dateText)}>
          {format(parseISO(date ? date : new Date().toISOString()), 'dd MMM yyyy')}
        </small>
      </div>
    </div>
  ) : null;
};

export const QuestionOption = QuestionOptionComponent;
