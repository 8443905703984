import { RootState } from '@modules/App/redux/store';
import { AutomaticFlagWatchers } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { UserFlagCcDetails } from '@modules/HappinessModule/interfaces/UserFlagCcDetails';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';

export const addFlagDataStateSelector = (
  state: RootState
): {
  loadingState: DataState;
  isNoNavigation: boolean;
} => state.happiness.flagFormPage.addFlagDataState;

export const userFlagCcDetailsSelector = (state: RootState): AsyncData<UserFlagCcDetails> =>
  state.happiness.flagFormPage.userFlagCcDetails;

export const userAutomaticFlagWatchersSelector = (state: RootState): AsyncData<AutomaticFlagWatchers> =>
  state.happiness.flagFormPage.automaticFlagWatchers;
