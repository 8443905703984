import { nanoid } from '@reduxjs/toolkit';
import classNames from 'classnames';
import React, { ChangeEvent, FC, memo } from 'react';
import { useStyles } from './RockyCheckbox.styles';

interface Props {
  children?: React.ReactNode;
  checked?: boolean;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  disabled?: boolean;
}
const RockyCheckboxComponent: FC<Props> = ({ children, checked, onChange, name, disabled }) => {
  const checkboxId = nanoid();
  const styles = useStyles();

  return (
    <>
      {!checked && disabled ? null : (
        <div className={classNames(styles.root, { [styles.disabled]: disabled })}>
          <div className={styles.checkboxContainer}>
            <input
              name={name}
              type="checkbox"
              disabled={disabled}
              className={styles.checkMarker}
              onChange={onChange}
              id={checkboxId}
              checked={checked}
            />
            <div className={styles.checkmark}></div>
          </div>
          {children && (
            <label className={styles.checkboxLabel} htmlFor={checkboxId}>
              {children}
            </label>
          )}
        </div>
      )}
    </>
  );
};

export const RockyCheckbox = memo(RockyCheckboxComponent);
