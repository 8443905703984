import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { SurveyCategory } from '../interfaces';
import {
  createCategory,
  createQuestion,
  deleteCategory,
  deleteQuestion,
  setCategoryName,
  setQuestionText,
  setSurveyCategoryOrder,
  setSurveyQuestionOrder,
  setSurveyQueue,
} from './actions';
import { CreateCategoryPayload, CreateQuestionPayload, State } from './types';

const initialState: State = {
  surveyQueue: {
    data: null,
    state: DataState.Pending,
  },
  runSurveyStatus: DataState.Idle,
};

export const slice = createSlice({
  name: 'surveyManagementPage',
  initialState,
  reducers: {
    setSurveyQueueLoading(state) {
      state.surveyQueue.state = DataState.Pending;
      state.surveyQueue = {
        data: null,
        state: DataState.Pending,
      };
    },
    setSurveyQueueFulfilled(state) {
      state.surveyQueue.state = DataState.Fulfilled;
    },
    setRunSurveyStatus(state, { payload }: { payload: DataState }) {
      state.runSurveyStatus = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setSurveyQueue, (state, action) => {
      state.surveyQueue = action.payload;
    });
    builder.addCase(setSurveyQuestionOrder, (state, { payload }) => {
      const queue = state.surveyQueue.data;
      const category = queue?.categories.find((c) => c.questions.some((q) => q.id === payload.questionId));
      if (!category) {
        return state;
      }
      const targetQuestion = category.questions.find((q) => q.id === payload.questionId);
      if (!targetQuestion) {
        return state;
      }
      category.questions = category.questions.filter((q) => q.id !== payload.questionId);
      category.questions.splice(payload.order, 0, targetQuestion);
    });
    builder.addCase(setSurveyCategoryOrder, (state, { payload }) => {
      const queue = state.surveyQueue.data;
      const targetCategory = queue?.categories.find((c) => c.id === payload.categoryId);
      if (!targetCategory) {
        return state;
      }
      if (!queue) {
        return state;
      }
      queue.categories = queue.categories.filter((c) => c.id !== payload.categoryId);
      queue.categories.splice(payload.order, 0, targetCategory);
      targetCategory.isActive = payload.isActive;
    });
    builder.addCase(deleteCategory, (state, { payload }) => {
      if (!state.surveyQueue.data) {
        return;
      }
      state.surveyQueue.data.categories = state.surveyQueue.data.categories.filter((c) => c.id !== payload);
    });
    builder.addCase(deleteQuestion, (state, { payload }) => {
      if (!state.surveyQueue.data) {
        return;
      }
      const targetCategory = state.surveyQueue.data.categories.find((c) =>
        c.questions.some(({ id }) => id === payload)
      );
      if (!targetCategory) {
        return;
      }
      targetCategory.questions = targetCategory.questions.filter((q) => q.id !== payload);
    });
    builder.addCase(setCategoryName, (state, { payload }) => {
      if (!state.surveyQueue.data) {
        return;
      }
      const targetCategory = state.surveyQueue.data.categories.find((c) => c.id === payload.categoryId);
      if (!targetCategory) {
        return;
      }
      targetCategory.name = payload.name;
    });
    builder.addCase(setQuestionText, (state, { payload }) => {
      if (!state.surveyQueue.data) {
        return;
      }
      const targetCategory = state.surveyQueue.data.categories.find((c) =>
        c.questions.some((q) => q.id === payload.questionId)
      );
      if (!targetCategory) {
        return;
      }
      const targetQuestion = targetCategory.questions.find((q) => q.id === payload.questionId);
      if (!targetQuestion) {
        return;
      }
      targetQuestion.text = payload.text;
    });
    builder.addCase(createCategory, (state, { payload }: { payload: CreateCategoryPayload }) => {
      if (!state.surveyQueue.data) {
        return;
      }
      const newCategory: SurveyCategory = {
        id: payload.categoryId,
        name: payload.categoryName,
        isActive: false,
        questions: [{ text: payload.questionText, id: payload.questionId }],
      };
      const activeCategoriesNum = state.surveyQueue.data.categories.reduce((res, c) => (c.isActive ? res + 1 : res), 0);
      state.surveyQueue.data.categories.splice(activeCategoriesNum, 0, newCategory);
    });
    builder.addCase(createQuestion, (state, { payload }: { payload: CreateQuestionPayload }) => {
      if (!state.surveyQueue.data) {
        return;
      }
      const targetCategory = state.surveyQueue.data.categories.find((c) => c.id === payload.categoryId);
      if (!targetCategory) {
        return;
      }
      targetCategory.questions.push({
        id: payload.questionId,
        text: payload.text,
      });
    });
  },
});

export const { setRunSurveyStatus } = slice.actions;

export default slice.reducer;
