import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { titleSelector } from '@modules/App/redux/pageTitle/selectors';

const DEFAULT_TITLE = 'Rocky';
const ROCKY_TITLE_POSTFIX = ' | Rocky';

export const useObserveTitle = (): string => {
  const title = useSelector(titleSelector);

  useEffect(() => {
    document.title = title !== DEFAULT_TITLE ? `${title} ${ROCKY_TITLE_POSTFIX}` : DEFAULT_TITLE;
  }, [title]);

  return title;
};
