import React, { FC, memo, ReactNode, useEffect, forwardRef, ForwardedRef } from 'react';
import { createPortal } from 'react-dom';
import { useStyles } from './RockyModal.styles';
import CrossIcon from '@mui/icons-material/Close';
import classNames from 'classnames';

export interface RockyModalProps {
  title: string;
  children?: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  modalStyles?: string;
  modalHeaderStyles?: string;
  modalTitleStyles?: string;
  crossIcon?: boolean;
  modalBodyStyles?: string;
  headerContent?: ReactNode;
  backdropClose?: boolean;
}

const RockyModalComponent: FC<RockyModalProps & { ref?: ForwardedRef<HTMLDivElement> }> = forwardRef(
  (
    {
      title,
      isOpen,
      children,
      onClose,
      modalStyles,
      modalHeaderStyles,
      modalTitleStyles,
      modalBodyStyles,
      crossIcon,
      headerContent,
      backdropClose: backdropClose = true,
    }: RockyModalProps,
    ref
  ) => {
    const styles = useStyles();
    const layoutRoot = document.getElementById('layout-root')!;

    useEffect(() => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';

      return () => {
        document.body.style.overflow = 'auto';
      };
    }, [isOpen]);

    const handleOverlayClick = (e: React.MouseEvent<HTMLElement>) => {
      if (backdropClose && e.currentTarget === e.target) {
        onClose();
      }
    };

    return (
      layoutRoot &&
      createPortal(
        isOpen ? (
          <>
            <div className={styles.overlay}></div>
            <div className={styles.root} onClick={(e) => handleOverlayClick(e)}>
              <div className={classNames(styles.modal, modalStyles)}>
                <div className={classNames(styles.header, modalHeaderStyles)}>
                  <h2 className={classNames(styles.title, modalTitleStyles)}>{title}</h2>
                  {headerContent}
                  {crossIcon && (
                    <div className={styles.crossIcon}>
                      <CrossIcon onClick={onClose} />
                    </div>
                  )}
                </div>
                <div ref={ref} className={classNames(styles.modalBody, modalBodyStyles)}>
                  {children}
                </div>
              </div>
            </div>
          </>
        ) : null,
        layoutRoot
      )
    );
  }
);

export const RockyModal = memo(RockyModalComponent);
