import React, { FC, memo } from 'react';
import { useStyles } from './LeadershipQuestionDescription.styles';

const LeadershipQuestionDescriptionComponent: FC = () => {
  const styles = useStyles();

  return (
    <div>
      <p className={styles.mainInfo}>
        Note, if there's no team to lead that's ok, please read the detailed explanation of what skills fall under this
        category and choose score based on the results.
      </p>
      <p className={styles.additionalInfo}>
        <span>
          <b>Delegation</b>
        </span>
        <br></br>
        Maintains ownership of project and overall responsibility for its success. Clearly defines and communicates
        expectations and results, provides support and feedback, and shares credit in success.
      </p>
      <p className={styles.additionalInfo}>
        <span>
          <b>Conflict management</b>
        </span>
        <br></br>
        Applies appropriate skills to manage team conflict. Identifies and handles conflict in a fair, and efficient
        manner, effectively communicating and solving problems through negotiation.
      </p>
      <p className={styles.additionalInfo}>
        <span>
          <b>Coaching</b>
        </span>
        <br></br>
        Assesses skill levels in relation to tasks, provides resources and direction. Monitors performance and gives
        constructive feedback. Ensures team members have clear understanding of roles and responsibilities.
      </p>
      <p className={styles.additionalInfo}>
        <span>
          <b>Support and motivation</b>
        </span>
        <br></br>
        Offers support and inspiration while keeping team focused on tasks. Encourages creativity, problem-solving and
        ownership.
      </p>
      <p className={styles.additionalInfo}>
        <span>
          <b>Decision quality</b>
        </span>
        <br></br>
        Realizes when a decision is needed and makes the best possible decision based on analysis of facts. Keeps
        managers and others updated and informed of decisions.
      </p>
    </div>
  );
};

export const LeadershipQuestionDescription = memo(LeadershipQuestionDescriptionComponent);
