import { useCallback } from 'react';
import { paths } from '@shared/enums/paths';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { StartEvalData } from '@modules/EvaluationModule/interfaces/StartEvalData';
import {
  failingEopIdSelector,
  startingEvalIdSelector,
} from '@modules/EvaluationModule/pages/MyTeamEvalPage/redux/selectors';
import { failEop } from '@modules/EvaluationModule/pages/MyTeamEvalPage/redux/actions';

export const useSelectEvals = (
  setSelectedEvalsAction: ActionCreatorWithPayload<StartEvalData> | null
): {
  handleSendTheFormButtonClick: (startEvalData: StartEvalData) => void;
  handleFailEopButtonClick: (evaluationId: string, failReason: string) => void;
  handleClickOnRow: (status: string | null, userId: string, evaluationId: string) => void;
  startingEvalId: string;
  failingEopId: string;
} => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const startingEvalId = useSelector(startingEvalIdSelector);
  const failingEopId = useSelector(failingEopIdSelector);

  const handleClickOnRow = useCallback((status: string | null, userId: string, evaluationId: string): void => {
    if (status === EvaluationStatus.Upcoming) {
      navigate(`${paths.users}/${userId}/${paths.evaluations}/${evaluationId}/${paths.settings}`, {
        state: { path: location.pathname, name: 'evaluation settings' },
      });
      return;
    }
    if (status === EvaluationStatus.Ongoing) {
      navigate(`${paths.users}/${userId}/${paths.evaluations}/${evaluationId}`, {
        state: { path: location.pathname, name: 'team evaluations' },
      });
      return;
    }
  }, []);

  const handleSendTheFormButtonClick = useCallback((startEvalData: StartEvalData) => {
    setSelectedEvalsAction && dispatch(setSelectedEvalsAction(startEvalData));
  }, []);

  const handleFailEopButtonClick = (evaluationId: string, failReason: string) => {
    dispatch(failEop({ evaluationId, failReason }));
  };

  return {
    handleSendTheFormButtonClick,
    handleFailEopButtonClick,
    handleClickOnRow,
    startingEvalId,
    failingEopId,
  };
};
