import React, { FC, memo } from 'react';
import { useStyles } from './RocketLoader.styles';
import { ReactComponent as Rocket } from '@assets/icons/rocket.svg';

const RocketLoaderComponent: FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.loader}>
      <div className={styles.loaderSpinned}>
        <Rocket className={styles.loaderIcon}></Rocket>
      </div>

      <div className={styles.pufs}>
        {Array(45)
          .fill(0)
          .map((_v, i) => (
            <i key={i}></i>
          ))}
      </div>
      <div className={styles.particles}>
        {Array(45)
          .fill(0)
          .map((_v, i) => (
            <i key={i}></i>
          ))}
      </div>
    </div>
  );
};

export const RocketLoader = memo(RocketLoaderComponent);
