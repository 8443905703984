import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    borderRadius: 8,
    display: 'flex',
    flexGrow: 1,
    minWidth: 300,
    width: '100%',
    maxHeight: '100%',
    flexDirection: 'column',
    overflow: 'hidden',
    backgroundColor: COLORS.WHITE,
  },
  header: {
    width: '100%',
    padding: '0 24px',
    minHeight: 64,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,

    [BREAKPOINTS.MOBILE]: {
      padding: '0 16px',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'baseline',
    padding: '16px 0',
  },
  title: {
    fontSize: '24px',
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '500',
    marginRight: 20,
  },
  titleSkeleton: {
    width: 250,
  },
  responsesCount: {
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    fontSize: '14px',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
});
