import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',

    [BREAKPOINTS.MOBILE]: {
      minWidth: '800px',
    },
  },
  emptyView: {
    display: 'flex',
    flexDirection: 'column',
    gap: 30,
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100vh',
    fontWeight: '400',
    fontSize: 24,
    color: COLORS.TEXT.SECONDARY,
  },
  workstreamView: {
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    padding: 24,
    minHeight: '100vh',
  },
  emailStatusContainer: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 'auto',
    gap: 6,
    cursor: 'pointer',
  },
  popup: {
    '&-content': {
      width: 'fit-content !important',
      padding: '4px 10px',
    },
  },
  contactRow: {
    padding: '8px 2px',
    margin: '0 -2px',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  contactEmail: {
    fontWeight: '400',
    fontSize: '14px',
    width: 300,
  },
  nonEmailBlock: {
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.STATUS.WARNING,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',

    '& svg': {
      marginRight: 5,
    },
  },
  userInfoHolder: {
    width: 250,
  },
  loaderHolder: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100vh',
  },
  emptyClients: {
    padding: '8px 0',
    color: COLORS.TEXT.SECONDARY,
  },
  nothingInZoho: {
    marginBottom: 32,
  },
  zohoWarning: {
    margin: '-24px -4px 16px',
    position: 'sticky',
    top: 0,
    zIndex: 2,
    borderRadius: '0 0 4px 4px',
  },
});
