import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    padding: '64px 32px 32px',
    color: COLORS.TEXT.PRIMARY,
  },
  infoSensitive: {
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    paddingBottom: 40,
    borderRadius: 8,
  },
  infoSensitiveHint: {
    '& > svg': {
      fill: COLORS.TEXT.DISABLED,
    },
  },
  email: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',

    '& svg': {
      width: 16,
      height: 16,
      fill: COLORS.ACTION.HIGHLIGHT,
      marginLeft: 10,
      cursor: 'pointer',
    },
  },
  legalAddress: {
    overflowWrap: 'anywhere',
  },
});
