import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';

interface Props {
  field: { label: string; value: string | null | number | React.ReactNode };
}

export const CompensationDetailsModalField: FC<Props> = ({ field }) => {
  return (
    <Stack key={field.label} sx={{ rowGap: '4px' }}>
      <Typography variant="body2">{field.label}</Typography>
      <Typography
        variant="body1"
        sx={{
          whiteSpace: 'pre-line',
          wordBreak: 'break-word',
        }}
      >
        {field.value ? field.value : 'None'}
      </Typography>
    </Stack>
  );
};
