import { useEffect, useState } from 'react';
import { enabledEnvFeatures } from '@shared/helpers/features';
import { FeaturesTriggers } from '@shared/interfaces/FeaturesTriggers';

function useUserEnabledFeatures(): FeaturesTriggers {
  const [userEnabledFeatures, setUserEnabledFeatures] = useState<FeaturesTriggers>({
    isCompensationEnabled: false,
    isFlagsEnabled: false,
    isCsatEnabled: false,
    isBonusesEnabled: false,
    isSmartGoalsEnabled: false,
    isPromotionsEnabled: false,
  });

  useEffect(() => {
    setUserEnabledFeatures({
      isCompensationEnabled: enabledEnvFeatures.compensation,
      isFlagsEnabled: enabledEnvFeatures.flags,
      isCsatEnabled: enabledEnvFeatures.csat,
      isBonusesEnabled: enabledEnvFeatures.bonuses,
      isSmartGoalsEnabled: enabledEnvFeatures.smartGoals,
      isPromotionsEnabled: enabledEnvFeatures.promotions,
    });
  }, []);

  return userEnabledFeatures;
}

export default useUserEnabledFeatures;
