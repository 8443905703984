import { OrgChartData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartData';
import { RootState } from '@modules/App/redux/store';
import { createSelector } from '@reduxjs/toolkit';
import { FilterInfoOrgChart } from '@modules/App/pages/OrgChartPage/interfaces/FilterInfoOrgChart';
import { applyFiltersFunc, getOrgChartTreeData, getRoles, sortOrgChartData } from '../helpers/helpers';
import { AsyncData } from '@shared/interfaces/asyncData';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { OrgChartSearchData } from '@modules/App/pages/OrgChartPage/interfaces/OrgChartSearchData';

export const selectOrgChartDataAsync = (state: RootState): AsyncData<OrgChartData[]> => state.orgChartPage.orgChartData;
export const selectOrgChartData = (state: RootState): OrgChartData[] | null => state.orgChartPage.orgChartData.data;
export const selectFilterInfo = (state: RootState): FilterInfoOrgChart[] => state.orgChartPage.filterInfo;
export const selectSortInfo = (state: RootState): SortInfo => state.orgChartPage.sortInfo;
export const selectIsDepartmentChart = (state: RootState): boolean => state.orgChartPage.isDepartmentChart;
export const selectRootId = (state: RootState): string => state.orgChartPage.rootId;

export const getRolesSelector = createSelector(selectOrgChartData, getRoles);
export const getOrgChartDataSelector = createSelector(selectOrgChartData, getOrgChartTreeData);

export const getFilteredOrgChartDataSelector = createSelector(
  [getOrgChartDataSelector, selectFilterInfo],
  (orgChartTreeData, filterInfo) => applyFiltersFunc(orgChartTreeData.chartData, filterInfo)
);
export const getSortedOrgChartDataSelector = createSelector(
  [getFilteredOrgChartDataSelector, selectSortInfo],
  (orgChartData, sortInfo) => sortOrgChartData(orgChartData, sortInfo)
);

export const selectOrgChartSearchData = (state: RootState): AsyncData<OrgChartSearchData[]> =>
  state.orgChartPage.searchData;
