import { all } from 'redux-saga/effects';
import { userHappinessPageSaga } from '../pages/UserHappinessPage/redux/sagas';
import { myTeamHappinessPageSaga } from '../pages/MyTeamHappinessPage/redux/sagas';
import { surveyManagementPageSaga } from '../pages/SurveyManagementPage/redux/sagas';
import { surveyHistoryPageSaga } from '../pages/SurveyHistoryPage/redux/sagas';
import { questionsSaga } from './questions/sagas';
import { chatPageSaga } from '../pages/ChatPage/redux/sagas';
import { flagFormPageSaga } from '../pages/FlagPage/pages/CreateFlagFormPage/redux/sagas';
import { myTeamFlagsPageSaga } from '../pages/MyTeamFlagsPage/redux/sagas';
import { flagPageSaga } from '../pages/FlagPage/pages/EditFlagFormPage/redux/sagas';
import { peopleHappinessPageSaga } from '../pages/PeopleHappinessPage/redux/sagas';
import { allFlagsPageSaga } from '../pages/AllFlagsPage/redux/sagas';
import { clientSurveyResponsePageSaga } from '../pages/ClientSurveyResponsePage/redux/sagas';
import { clientSurveysPageSaga } from '../pages/ClientSurveysPage/redux/sagas';

export function* happinessSaga(): Generator {
  yield all([
    myTeamHappinessPageSaga(),
    userHappinessPageSaga(),
    surveyManagementPageSaga(),
    surveyHistoryPageSaga(),
    questionsSaga(),
    chatPageSaga(),
    myTeamFlagsPageSaga(),
    flagFormPageSaga(),
    peopleHappinessPageSaga(),
    flagPageSaga(),
    allFlagsPageSaga(),
    clientSurveyResponsePageSaga(),
    clientSurveysPageSaga(),
  ]);
}
