import { FC } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import Avatar from '@mui/material/Avatar';
import Autocomplete from '@mui/material/Autocomplete';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';

import { FlagFormFields } from '../../../../constants/FlagFormFields';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { selectPrs } from '@modules/App/redux/users/selectors';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { DataState } from '@shared/enums/DataState';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';

interface Props {
  disabled: boolean;
}

export const AssignedPR: FC<Props> = ({ disabled }) => {
  const { values, setFieldValue } = useFormikContext<FlagFields>();
  const value = values.assignedPR ? [values.assignedPR] : [];
  const { state, data } = useSelector(selectPrs);
  const isLoading = state === DataState.Pending;
  const options: UserInfo[] = data || [];

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      value={value}
      loading={isLoading}
      options={options}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      onChange={(_, newValue) => {
        setFieldValue(FlagFormFields.assignedPR.name, newValue.length ? newValue[newValue.length - 1] : null);
      }}
      filterOptions={(options, state) =>
        options.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
      }
      renderOption={(props, option) => (
        <li {...props} key={option.id}>
          <UserReporterBlock user={option} />
        </li>
      )}
      getOptionLabel={(option) => option.name}
      renderTags={(tagValue) =>
        tagValue.map((option, index) => (
          <Chip
            key={index}
            variant="filled"
            avatar={
              <Avatar
                sx={{
                  borderRadius: '50%',
                }}
                variant="circular"
                src={option.imageUrl ?? undefined}
              >
                {option.name}
              </Avatar>
            }
            label={option.name}
            onDelete={disabled ? undefined : () => setFieldValue(FlagFormFields.assignedPR.name, null)}
            deleteIcon={<CancelIcon />}
          />
        ))
      }
      renderInput={(params) => (
        <TextField
          {...params}
          sx={{ '.MuiInputBase-root': { backgroundColor: 'white' } }}
          helperText="Only HR can set this"
          label={FlagFormFields.assignedPR.label}
        />
      )}
      style={{ maxWidth: '20rem' }}
    />
  );
};
