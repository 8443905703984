export interface Release {
  date: Date;
  version: string;
  fixes?: string[];
  features?: string[];
}
export const whatsNewData: Array<Release> = [
  {
    date: new Date(2023, 0, 26),
    version: 'v1.7',
    features: [
      'Launch EoPs and Evaluations for the part-timers.',
      'Allow removing the peer reviewer during the evaluation.',
      'View the teammate`s project on the My team evaluations page.',
      'People Ops and People Relations teams are provided with comprehensive visibility on happiness and evaluations.',
      'Find the Location HR contact on the Rocky profile.',
      'Improved global search: Faster and typo-friendly to extend your AE peer circle',
    ],
  },
  {
    date: new Date(2023, 1, 17),
    version: 'v1.8',
    features: [
      'Allow connecting the feedback givers contacts directly to the Client.',
      'Client contact for the evaluation feedback can be highlighted as other vendor team member.',
      'View the teammate`s position on the project on the Teams and Clients chart.',
    ],
    fixes: [
      'The Peer reviewer can provide feedback in the closed evaluation.',
      'Fuzzy search returning incorrect results in some cases.',
      'Search by department in Teams and people is broken.',
    ],
  },
  {
    date: new Date(2023, 2, 2),
    version: 'v1.9',
    features: [
      'Client satisfaction survey - Rocky for clients is now live!',
      'Project managers positions are now reflected on the org chart',
      "Both subproject and the main project are now shown on the org chart AgileEnginer's details",
    ],
  },
  {
    date: new Date(2023, 2, 31),
    version: 'v1.11',
    features: [
      'You can update your profile in Rocky! This includes personal information, emergency contact and physical location',
      'Now your manager can leave links to google docs in your profile related to you, so that both of you can easily find them in Rocky: PDP, 1-1 notes, pre-Rocky evaluation spreadsheets',
      'You can report an issue directly to HR support if you notice incorrect manager or project of a person in Rocky',
    ],
    fixes: [
      'Peer reviewers can no longer provide feedback in closed evaluations.',
      'Client can no longer provide feedback for upcoming evaluations.',
      'Evaluation feedback reminders are no longer sent if the evaluated AgileEnginer leaves the company.',
    ],
  },
  {
    date: new Date(2023, 3, 14),
    version: 'v1.12',
    features: [
      'Introducing the Metro area concept - you can choose the city you want to work from, meet with your colleagues, or attend AE days, and our HR team will keep you in the loop about the activities happening there.',
    ],
  },
  {
    date: new Date(2023, 4, 2),
    version: 'v1.13',
    features: [
      'Introducing the new Goals module! With this update, managers can track their professional, project, and company goals in Rocky. Set measurable goals, monitor progress, and update achievements in one convenient location.',
    ],
    fixes: [
      'Fixed a bug when personal contacts were not updated after having submitted the form',
      'Fixed inability to select metro area without having updated physical location',
      'Evaluations which were conducted outside Rocky are now properly highlighted in the evaluation history',
    ],
  },
  {
    date: new Date(2023, 4, 17),
    version: 'v1.14',
    features: [
      'Now you can leave peer feedback anonymously.',
      'Goals are now available in evaluations.',
      'Engineering Manager is displayed in user profile.',
      'Actualized the list of available metro areas.',
    ],
  },
  {
    date: new Date(2023, 5, 7),
    version: 'v1.15',
    features: [
      'End of probation can now be closed and resoltion set without having to start the evaluation.',
      'People can be assigned to up to 5 projects.',
      'Various customer satisfaction survey improvements.',
    ],
    fixes: [
      'The initial value of base compensation is now displayed correctly in the edit modal.',
      'Choosing a different city when editing personal location no longer resest the values of address line fields.',
      'Autocomplete values always default to English in physical location form.',
    ],
  },
  {
    date: new Date(2023, 5, 20),
    version: 'v1.16',
    features: [
      'Reach out to HR team using Rocky “Report issue” form if you have any concerns that you want to raise to someone else besides your manager.',
    ],
    fixes: [
      'The issue with the display of the peer reviewer’s avatar in the feedback request email has been resolved.',
      'The "My Previous Responses" link for the Rocky happiness survey emails is now functioning correctly.',
    ],
  },
  {
    date: new Date(2023, 6, 13),
    version: 'v1.17',
    features: [
      'Various risk management process improvements',
      'Updated permissions for evaluations to enhance security and access control.',
      'Talent Operation Managers can now track New Home cases through Rocky, improving case management efficiency.',
      'Business Development Team and Delivery Managers can now directly follow up with clients by copying and sharing the customer satisfaction survey link.',
    ],
    fixes: ['The system now accepts updates to phone numbers in valid international formats.'],
  },
  {
    date: new Date(2023, 6, 26),
    version: 'v1.18',
    features: [
      'Various New Home process improvements',
      'Senior managers now have the ability to review all ongoing and overdue evaluations within their reporting structure.',
      'Links to the Personal Improvement Plans are now accessible from the Profile links tab.',
      'Managers are now copied on client reminders for the evaluation feedback.',
    ],
    fixes: [
      'The flag on avatars now correctly matches the country set in the user profile.',
      'Managers are now redirected to the Feedback form once they initiate the evaluation.',
      'The Evaluation goals fields are now available when filling out the self-evaluation form and are displayed on both the side-by-side and summary evaluation pages. ',
    ],
  },
  {
    date: new Date(2023, 6, 31),
    version: 'v1.19',
    features: [
      'Upcoming evaluations are now included to the Evlauations page for managers.',
      'Managers can now reuse the peer reviews list from previous evaluation.',
      'Improvements have been made to the process for handling low happiness scores.',
    ],
    fixes: [
      'If a new manager is assigned, the manager who started an evaluation now can continue the evaluation process.',
    ],
  },
  {
    date: new Date(2023, 7, 22),
    version: 'v1.20',
    features: [
      'You can now update your delivery address in the Rocky profile!',
      'When a manager leaves, their team evaluations automatically move to the new managers.',
      "The teammate's manager is shown in Team and people profile.",
    ],
    fixes: [
      'Numerous fixes for the evaluation process.',
      'Projects duplicates removed from the user profile.',
      'Incorrect People partner update record removed from the happiness history.',
    ],
  },
  {
    date: new Date(2023, 7, 31),
    version: 'v1.21',
    features: [
      'All projects, where AgileEnginers are involved are now displayed on their profile pages',
      'AE initiatives are now listed in personal profiles of AgileEnginers',
      'Various improvements of Customer Satisfaction surveys including UX and wording',
      'Added a shortcut to resolve unhappy ca`ses in email reminders',
      'The exact date is now displayed for End of Probation evaluations instead of month',
    ],
    fixes: [
      'Fixed an issue when Team Leads counted as senior managers for the Delivery Managers to whom they report',
      'Fixed styling of Client Satisfaction and client evaluation feedback emails in the desktop version of MS Outlook',
      "Fixed an error when trying to input fractional values as goals' expected and actual results",
      'People are no longer mentioned as Leads or Managers of closed departments',
      'Inactive AgileEnginers are no longer listed in the happiness-related email summaries and reminders for managers',
      'Fixed a bug when direct members of the HR department were not counted as HR by Rocky in terms of permissions',
      'Fixed a bug when rescheduling an evaluation to a certain month would result in another month being selected',
    ],
  },
  {
    date: new Date(2023, 8, 18),
    version: 'v1.22',
    features: ['Smart filtering for AgileEngineers’ Happiness status', 'Sync with ZohoPeople now runs every 4 hours'],
    fixes: [
      'Engineering Managers automatically added to peers during evaluations now have proper permissions for leaving feedback',
      'CSAT form now warns the user when they are about to leave the page without submitting the response',
      'Date of join is no longer shifted one day back in certain timezones',
      'Organizational chart now correctly displays all projects and allocations of AgileEnginers',
      'Fixed an issue when links could not be added, if no prior links existed for a person',
    ],
  },
  {
    date: new Date(2023, 9, 2),
    version: 'v1.23',
    features: [
      'AgileEngineers’ Happiness status now supports Grouping by Projects and Locations',
      'Extended visibility for Talent Operation Managers to view teammates assigned to them',
      'Validation for the Goal Planned Date has been removed, allowing more flexibility in goal setting',
      'Delivery Managers, Portfolio Delivery Managers and Team Leads no longer count as senior manager for people on respective projects. "Reporting to" rules still apply.',
    ],
    fixes: [
      'Portfolio Delivery managers now also receieve a "customer contacts list" email before start of each customer satisfaction survey',
    ],
  },
  {
    date: new Date(2023, 9, 17),
    version: 'v1.24',
    features: [
      "Promotions: Compensation managers can now update their teammates' roles and seniority directly through Rocky.",
      'Evaluation Enhancements: Managers have the capability to promote TMs as a part of the evaluation process.',
      'Extended Happiness Survey: Participants can now respond until the start of the next survey.',
      'Terms of Use Enforcement: For added security and compliance, all users are now required to accept the Terms of Use before proceeding with Rocky.',
      'Minor adjustments to the compensation management form',
    ],
    fixes: [
      'Resolved unstable column width issue in evaluations tables',
      'Physical address editing and saving issue on the TM profile has been fixed.',
      'Addressed a data loading error on the "Evaluations" tab.',
      'Resolved an issue where RT couldn not initiate evaluations from the "My team evaluations" form.',
      'Fixed email sending issues related to evaluation rescheduling.',
      'Corrected the display of Evaluation managers when using the Anonymous feedback switcher.',
      "Ensured client's email addresses are set/retrieved correctly during evaluations.",
      'Fixed an access issue where Senior managers were unable to resolve unhappy cases.',
      'Ensured that previous manager feedback is correctly transferred for ongoing evaluations.',
    ],
  },
  {
    date: new Date(2023, 9, 31),
    version: 'v1.25',
    features: [
      'Roles and seniorities are now uniformly displayed across profile and Teams and people pages.',
      'HR location is now included in the successful End of Probation completion email.',
      'Client Contact Management for CSAT Survey: DMs and PDMs can now effectively manage client contacts used for CSAT surveys.',
      'Clients will receive CSAT survey emails from their current Client Partners.',
    ],
    fixes: [
      'Fixed a bug where the last chunk of data was not displayed on the happiness listing.',
      'Resolved issues related to the automatic addition of peers in the evaluations process.',
      'Fixed an issue with being unable to revote in happiness survey.',
      'Addressed an issue in the "Promotions and title changes" form where manager were unable to add the PDP link.  Corrected the issue where the PDP link was missing from the promotion modal despite being specified for the user.',
    ],
  },
].sort((a, b) => b.date.getTime() - a.date.getTime());
