import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import { EditUserGoalData } from '@modules/EvaluationModule/interfaces/EditUserGoalData';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';

export const editGoalPayloadGenerate = (
  values: EditUserGoalData,
  customDueDate: string | null,
  data: UserGoalPageData
): EditUserGoalData => {
  const payload = {
    dueDate: customDueDate ?? '',
    id: values.id,
    objective: values.objective,
    bestCaseResult: data.isRange ? values.bestCaseResult : values.worstCaseResult,
    worstCaseResult: values.worstCaseResult,
    isRange: data.isRange,
    actualResult: values.actualResult,
    category: values.category,
    status: values.status,
    description: values.description,
    cancellationNote: values.status === GoalsStatus.Canceled ? values.cancellationNote : null,
    expectationChangeReason: values.expectationChangeReason,
  };
  return payload;
};
