import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  tableHeader: {
    '& .MuiTableCell-head': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '24px',
      color: COLORS.TEXT.SECONDARY,
    },
  },

  personNameSkeleton: {
    fontSize: '14px',
    fontWeight: '500',
    margin: 0,
    textDecoration: 'none',
    width: 160,
  },
  personBlock: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,
    padding: '4px 12px',
    gap: 8,
    borderRadius: 12,
    transition: 'all 0.2s',
  },
  personInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  personAvatar: {
    width: 40,
    height: 40,
    borderRadius: 14,
    objectFit: 'cover',
  },
  meterSkeleton: {
    margin: '0 auto',
    width: 100,
    height: 20,
  },
  memberBlock: {
    display: 'flex',
    alignItems: 'center',
  },
  footer: {
    padding: '24px 16px',
    color: COLORS.TEXT.DISABLED,
  },
});
