import { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyles } from './PeerSearch.styles';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { DataState } from '@shared/enums/DataState';
import { PeerSearchOption } from './components/PeerSearchOption';
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { searchCustomStyles } from '@styles/SearchCustomStyles';
import { useSearchFunctions } from '@shared/helpers/useSearchFunctions';
import { addPeerFeedbackAction } from '../../redux/actions';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { useModalState } from '@shared/hooks/useModalState';
import { AddNewReviewerWarningModal } from '../AddNewReviewerWarningModal';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { loadActiveUsersList } from '@modules/App/redux/users/actions';
import { resetSearchResult } from '@modules/App/redux/users';
import { selectUsersSearchData } from '@modules/App/redux/users/selectors';
import { evalPageDataSelector, peerReviewsSelector } from '@modules/EvaluationModule/pages/EvalPage/redux/selectors';

const PeerSearchComponent: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { inputValue, handleInputChange } = useSearchFunctions(loadActiveUsersList, resetSearchResult);
  const { openModal, closeModal, isModalOpen } = useModalState();
  const [selectedPeer, setSelectedPeer] = useState<UserInfo>();

  const searchData = useSelector(selectUsersSearchData);
  const evalPageData = useSelector(evalPageDataSelector);

  const peerReviews = useSelector(peerReviewsSelector);
  const isEvalOngoing = evalPageData.data?.status === EvaluationStatus.Ongoing;

  const addNewReviwer = useCallback(
    (user: UserInfo) => {
      if (evalPageData.data) {
        dispatch(addPeerFeedbackAction([{ user, evalId: evalPageData.data?.evaluationId }]));
        dispatch(resetSearchResult());
        setSelectedPeer(undefined);
      }
    },
    [evalPageData]
  );

  useEffect(() => {
    if (!selectedPeer) return;
    if (isEvalOngoing) {
      openModal();
      return;
    }
    addNewReviwer(selectedPeer);
  }, [selectedPeer, evalPageData]);

  const options =
    searchData.data && evalPageData
      ? searchData.data.filter(
          (user) =>
            !peerReviews?.some((peer) => user.id === peer.user.id) &&
            user.id !== evalPageData.data?.userId &&
            user.id !== evalPageData.data?.owner.id
        )
      : [];

  return (
    <div className={styles.selectBlock}>
      <div className={styles.searchIcon}>
        <SearchRoundedIcon />
      </div>

      <Select
        isDisabled={evalPageData.data === null}
        styles={searchCustomStyles}
        value={selectedPeer ? { label: selectedPeer?.name } : []}
        autoFocus={true}
        placeholder="Add peers"
        components={{ Option: PeerSearchOption }}
        onChange={(value) => setSelectedPeer(value as UserInfo)}
        isLoading={searchData.state === DataState.Pending}
        inputValue={inputValue}
        onInputChange={handleInputChange}
        noOptionsMessage={() => (inputValue === '' ? 'Start typing to search' : 'No results')}
        options={options}
        filterOption={null}
      />
      <AddNewReviewerWarningModal
        isModalOpen={isModalOpen}
        closeModal={() => {
          closeModal();
          setSelectedPeer(undefined);
        }}
        onAdd={() => selectedPeer && addNewReviwer(selectedPeer)}
      />
    </div>
  );
};

export const PeerSearch = memo(PeerSearchComponent);
