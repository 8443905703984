import React, { FC } from 'react';
import { Typography } from '@mui/material';
import { PeerFormData } from '@modules/EvaluationModule/interfaces/PeerEvalData';
import { useStyles } from './HelperMsgForAnonymousFeedback.styles';

interface Props {
  isAnonymous: boolean;
  peerReviewData: PeerFormData;
  isManager?: boolean;
}

export const HelperMsgForAnonymousFeedback: FC<Props> = ({ isAnonymous, peerReviewData, isManager }) => {
  const styles = useStyles();
  const { evaluatee, evaluator } = peerReviewData;
  const evalManagerName = evaluatee.primaryReportsTo.name;

  return (
    <Typography variant="body2">
      {!isAnonymous ? (
        <>
          {evaluatee.name} and the evaluation manager
          <br />
          {evalManagerName} will see your name in the feedback.
        </>
      ) : isManager ? (
        <>
          {evaluatee.name} <span className={styles.warningText}>will not see that feedback</span> is from{' '}
          {evaluator.name}.
          <br />
          Only you as a manager can see it.
        </>
      ) : (
        <>
          {evaluatee.name} <span className={styles.warningText}>will not see your name</span> in the feedback,
          <br />
          while evaluation manager {evalManagerName} will see it.
        </>
      )}
    </Typography>
  );
};
