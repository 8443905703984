import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  questionBackdrop: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gridTemplateColumns: 'repeat(10, 1fr)',
    alignItems: 'center',
    padding: '8px 24px',
    transition: 'all 0.1s',
    cursor: 'pointer',

    '&$isClose': {
      backgroundColor: COLORS.TEXT.DISABLEDBG,
    },

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },

    [BREAKPOINTS.MOBILE]: {
      gridTemplateRows: '2fr 1fr',
    },
  },
  questionTitle: {
    gridColumn: '1/4',
    gridRow: '1/1',
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '2/7',
    },
  },
  userRate: {
    gridColumn: '4/8',
    gridRow: '1/1',

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '1/9',
      gridRow: '2/3',
    },
  },
  rateDonut: {
    gridColumn: '8/9',
    gridRow: '1/1',
    justifySelf: 'center',

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '7/9',
    },
  },
  responsesCount: {
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,
    gridColumn: '9/10',
    gridRow: '1/1',

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '9/11',
    },
  },
  openCloseBtn: {
    gridColumn: '10/11',
    justifySelf: 'center',
    display: 'flex',
    width: 22,
    height: 22,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    padding: '7px 4px',
    background: COLORS.INPUTBORDER,
    color: COLORS.WHITE,

    '& svg': {
      width: 18,
    },

    [BREAKPOINTS.MOBILE]: {
      gridColumn: '1/2',
      gridRow: '1/2',
    },

    '& $isClose': {
      transform: 'rotate(180deg)',
    },
  },
  isClose: {},
  mobileRate: {
    display: 'none',

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      gridColumn: '9/11',
      justifySelf: 'center',
      gridRow: '2/3',
      fontWeight: '500',
      fontSize: '14px',
      color: COLORS.TEXT.SECONDARY,
    },
  },
});
