import { FeedbackFormData } from '@modules/App/interfaces/FeedbackFormData';
import { DataState } from '@shared/enums/DataState';
import { User } from '@shared/interfaces/user';
import { RootState } from '../store';
import { UserInfo } from '@shared/interfaces/UserInfo';

export const feedbackDataStateSelector = (state: RootState): DataState => state.feedback.feedbackDataState;
export const isFeedbackOpenSelector = (state: RootState): boolean => state.feedback.isFeedbackOpen;
export const feedbackFormInitialValuesSelector = (state: RootState): FeedbackFormData => state.feedback.initialValues;
export const selectedUserSelector = (state: RootState): UserInfo | null => state.feedback.selectedUser;
export const selectedManagerSelector = (state: RootState): User | null => state.feedback.selectedManager;
