import React, { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import addDays from 'date-fns/addDays';
import startOfDay from 'date-fns/startOfDay';
import endOfDay from 'date-fns/endOfDay';
import addMonths from 'date-fns/addMonths';
import { useStyles } from './AllFlagsPage.styles';
import { getAllFlagsData, setFlagAuthorsIds, setFlagManagersIds, setInvolvedUsersIds } from './redux/actions';
import { AllFlagsFilters } from './components/AllFlagsFilters/AllFlagsFilters';
import { FilterFields } from './enums/FilterFields';
import { useAllFlagsData } from './hooks/useAllFlagsData';
import { FlagsTable } from '../../components/FlagsTable';
import {
  ALL_FLAGS_FETCH_LIMIT,
  DEFAULT_PAGINATION_DATA,
  escalationObject,
  projectEndObject,
  statusObject,
  userStatusesObject,
} from './constants/constants';
import { FlagsTableHeader } from '@modules/HappinessModule/enums/FlagsTableHeader';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { DataState } from '@shared/enums/DataState';
import { useToms } from '@shared/hooks/useToms';
import {
  setAllLocationsDataLoading,
  setFlagAuthorsData,
  setFlagAuthorsLoading,
  setFlagManagersData,
  setFlagManagersLoading,
  setInvolvedUsersData,
  setInvolvedUsersLoading,
} from './redux';
import { usePrs } from '@shared/hooks/usePrs';
import { useAllDepartments } from '@shared/hooks/useAllDepartments';
import { AllFlagsDataFilters } from '@modules/HappinessModule/interfaces/AllFlagsDataFilters';
import { labelsFromValues, labelsFromValuesArr } from './helpers/labelsFromValues';
import { idToUser } from './helpers/idToUser';
import { projectIdsToName } from './helpers/projectIdsToName';
import {
  selecFlagManagersData,
  selectFlagAuthorsData,
  selectFlagAuthorsState,
  selectFlagManagersState,
  selectInvolvedUsersData,
  selectInvolvedUsersState,
} from './redux/selectors';
import { commaToArrayParser } from './helpers/commaToArrayParser';
import startOfQuarter from 'date-fns/startOfQuarter';
import startOfYear from 'date-fns/startOfYear';
import { UserStatus } from '@shared/enums/UserStatus';
import { Typography } from '@mui/material';
import { FlagFiltersFields } from './interfaces/FlagFiltersFields';

export const AllFlagsPage: React.FC = () => {
  const styles = useStyles();
  const { allFlags } = useAllFlagsData();
  const allFlagsData = allFlags.data?.items ?? [];
  const totalCount = allFlags.data?.totalCount ?? 0;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { isFlagsEnabled } = useUserEnabledFeatures();
  const { data: tomData, state } = useToms();
  const prs = usePrs();
  const allDepartments = useAllDepartments();
  const isDataLoading =
    allFlags.state === DataState.Pending ||
    state === DataState.Pending ||
    prs.state === DataState.Pending ||
    allDepartments.state === DataState.Pending;

  const flagInvolvedUserState = useSelector(selectInvolvedUsersState);
  const flagAuthorsState = useSelector(selectFlagAuthorsState);
  const flagManagersState = useSelector(selectFlagManagersState);
  const isFilterDataLoading =
    state === DataState.Pending ||
    prs.state === DataState.Pending ||
    allDepartments.state === DataState.Pending ||
    flagInvolvedUserState === DataState.Pending ||
    flagAuthorsState === DataState.Pending ||
    flagManagersState === DataState.Pending;
  const statusesFromParams = searchParams.get(FilterFields.Status);
  const userStatusesFromParam = searchParams.get(FilterFields.UserStatuses);
  const escalationDateFromParams = searchParams.get(FilterFields.EscalationDate);
  const assignedTomsFromParams = searchParams.get(FilterFields.AssignedTomIds);
  const assignedPrsFromParams = searchParams.get(FilterFields.AssignedPrIds);
  const legalLocationsFromParams = searchParams.get(FilterFields.LegalLocations);
  const projectsFromParams = searchParams.get(FilterFields.Projects);
  const involvedUserFromParams = searchParams.get(FilterFields.InvolvedUsers);
  const flagAuthorsFromParams = searchParams.get(FilterFields.FlagAuthors);
  const reasonsSubReasonsFromParams = searchParams.get(FilterFields.ReasonsSubReasons);
  const flagManagersFromParams = searchParams.get(FilterFields.FlagManagers);
  const involvedUserIds = involvedUserFromParams?.split(',');
  const flagAuthorsIds = flagAuthorsFromParams?.split(',');
  const flagManagersIds = flagManagersFromParams?.split(',');
  const involvedUsersData = useSelector(selectInvolvedUsersData) ?? [];
  const flagAuthorsData = useSelector(selectFlagAuthorsData) ?? [];
  const flagManagersData = useSelector(selecFlagManagersData) ?? [];

  const initialValues: FlagFiltersFields = {
    severity: searchParams.get(FilterFields.Severity) ?? 'all',
    status: statusesFromParams ? labelsFromValuesArr(statusesFromParams, statusObject) : [statusObject.all.label],
    escalationDate: escalationDateFromParams
      ? labelsFromValues(escalationDateFromParams, escalationObject)
      : escalationObject.anyTime.label,
    isNewHomeNeeded: Boolean(searchParams.get(FilterFields.IsNewHomeNeeded)) ?? false,
    projectEnd: searchParams.get(FilterFields.ProjectEnd) ?? projectEndObject.anyTime.value,
    userStatuses: userStatusesFromParam
      ? labelsFromValuesArr(userStatusesFromParam, userStatusesObject)
      : [userStatusesObject.all.label],
    involvedUsers: idToUser(involvedUsersData, involvedUserFromParams),
    flagAuthors: idToUser(flagAuthorsData, flagAuthorsFromParams),
    flagManagers: idToUser(flagManagersData, flagManagersFromParams),
    assignedTomIds: assignedTomsFromParams ? idToUser(tomData ?? [], assignedTomsFromParams) : [],
    assignedPrIds: assignedPrsFromParams ? idToUser(prs.data ?? [], assignedPrsFromParams) : [],
    legalLocations: legalLocationsFromParams ? legalLocationsFromParams.split(',') : [],
    projects: projectsFromParams ? projectIdsToName(projectsFromParams.split(','), allDepartments.data ?? []) : [],
    reasonsSubReasons: reasonsSubReasonsFromParams?.split(',') ?? [],
  };

  useEffect(() => {
    if (involvedUserIds) dispatch(setInvolvedUsersIds(involvedUserIds));
    else dispatch(setInvolvedUsersData({ undefined, state: DataState.Fulfilled }));

    if (flagAuthorsIds) dispatch(setFlagAuthorsIds(flagAuthorsIds));
    else dispatch(setFlagAuthorsData({ undefined, state: DataState.Fulfilled }));

    if (flagManagersIds) dispatch(setFlagManagersIds(flagManagersIds));
    else dispatch(setFlagManagersData({ undefined, state: DataState.Fulfilled }));

    return () => {
      dispatch(setAllLocationsDataLoading());
      dispatch(setFlagManagersLoading());
      dispatch(setFlagAuthorsLoading());
      dispatch(setInvolvedUsersLoading());
    };
  }, []);

  const getFlagPayload = (overrides?: Partial<AllFlagsDataFilters>): AllFlagsDataFilters => {
    const limit = overrides?.limit ?? ALL_FLAGS_FETCH_LIMIT;
    const offset = overrides?.offset ?? DEFAULT_PAGINATION_DATA.offset;
    const statuses = commaToArrayParser(searchParams.get(FilterFields.Status));
    const authorIds = commaToArrayParser(searchParams.get(FilterFields.FlagAuthors));
    const primaryReportsToIds = commaToArrayParser(searchParams.get(FilterFields.FlagManagers));
    const assignedTomIds = commaToArrayParser(searchParams.get(FilterFields.AssignedTomIds));
    const assignedPrIds = commaToArrayParser(searchParams.get(FilterFields.AssignedPrIds));
    const userIds = commaToArrayParser(searchParams.get(FilterFields.InvolvedUsers));
    const countries = commaToArrayParser(searchParams.get(FilterFields.LegalLocations));
    const projectIds = commaToArrayParser(searchParams.get(FilterFields.Projects));
    const severity = searchParams.get(FilterFields.Severity) ?? undefined;
    const userStatuses = commaToArrayParser(searchParams.get(FilterFields.UserStatuses));

    const payload: AllFlagsDataFilters = {
      isNewHomeNeeded: searchParams.get(FilterFields.IsNewHomeNeeded) === 'true' ? true : undefined,
      assignedTomIds,
      assignedPrIds,
      countries,
      projectIds,
      limit,
      offset,
      statuses,
      userStatuses,
      severity,
      userIds,
      primaryReportsToIds,
      authorIds,
      reasonsSubReasons: commaToArrayParser(searchParams.get(FilterFields.ReasonsSubReasons)) ?? [],
    };

    const today = new Date();
    const beginningOfTime = new Date(0);
    const todayPlusTwoWeeks = addDays(today, 14);
    const todayMinusTwoWeeks = addDays(today, -14);
    const todayMinusWeek = addDays(today, -7);
    const todayMinusMonth = addMonths(today, -1);
    const lastQuarter = startOfQuarter(today);
    const lastYear = startOfYear(today);
    const projectEnd = searchParams.get(FilterFields.ProjectEnd);
    const escalationDate = searchParams.get(FilterFields.EscalationDate);
    switch (projectEnd) {
      case projectEndObject.anyTime.value:
        break;
      case projectEndObject.next2Weeks.value:
        payload.lastDayOnTheProjectTo = endOfDay(todayPlusTwoWeeks).toISOString();
        payload.lastDayOnTheProjectFrom = startOfDay(today).toISOString();
        break;
      case projectEndObject.alreadyEnded.value:
        payload.lastDayOnTheProjectTo = endOfDay(today).toISOString();
        payload.lastDayOnTheProjectFrom = startOfDay(beginningOfTime).toISOString();
        break;
    }
    if (userStatuses?.includes(userStatusesObject.inactive.value)) {
      payload.userStatuses = payload.userStatuses?.filter((status) => status !== userStatusesObject.inactive.value);
      payload.userStatuses?.push(UserStatus.Disabled);
    }
    switch (escalationDate) {
      case escalationObject.anyTime.value:
        break;
      case escalationObject.last2Weeks.value:
        payload.escalationDateFrom = todayMinusTwoWeeks.toISOString();
        payload.escalationDateTo = today.toISOString();
        break;
      case escalationObject.last7Days.value:
        payload.escalationDateFrom = todayMinusWeek.toISOString();
        payload.escalationDateTo = today.toISOString();
        break;
      case escalationObject.lastMonth.value:
        payload.escalationDateFrom = todayMinusMonth.toISOString();
        payload.escalationDateTo = today.toISOString();
        break;
      case escalationObject.lastQuarter.value:
        payload.escalationDateFrom = lastQuarter.toISOString();
        payload.escalationDateTo = today.toISOString();
        break;
      case escalationObject.lastYear.value:
        payload.escalationDateFrom = lastYear.toISOString();
        payload.escalationDateTo = today.toISOString();
        break;
    }

    return { ...payload, ...overrides };
  };

  useEffect(() => {
    dispatch(getAllFlagsData(getFlagPayload()));
  }, [searchParams]);

  const getMoreData = useCallback(
    (offset = 0) => {
      const payload = getFlagPayload({ offset });
      dispatch(getAllFlagsData(payload));
    },
    [getFlagPayload]
  );

  const loadMoreItems = useCallback(
    (startIndex: number, stopIndex: number) => {
      if (allFlagsData && stopIndex > allFlagsData.length) {
        getMoreData && getMoreData(startIndex);
      }
    },
    [allFlagsData, getMoreData]
  );

  const tableHeaders = [
    { title: FlagsTableHeader.Member },
    { title: FlagsTableHeader.FlagDetails },
    { title: FlagsTableHeader.FlagAuthor },
    { title: FlagsTableHeader.Escalated },
    { title: FlagsTableHeader.Resolved },
    { title: FlagsTableHeader.Status },
    { title: FlagsTableHeader.Actions },
  ];

  return isFlagsEnabled ? (
    <div className={styles.root}>
      <div className={styles.header}>
        <div className={styles.title}>
          Flags{' '}
          {!isDataLoading && (
            <Typography component="span" variant="body2" sx={{ marginLeft: '16px' }}>
              {totalCount} {totalCount === 1 ? 'flag' : 'flags'} found
            </Typography>
          )}
        </div>
        <AllFlagsFilters
          tomData={tomData ?? []}
          prData={prs.data ?? []}
          allDeparttmentsData={allDepartments.data ?? []}
          isLoading={isDataLoading}
          isFilterLoading={isFilterDataLoading}
          initialValues={initialValues}
        />
      </div>
      <FlagsTable
        isDataLoading={isDataLoading}
        limit={ALL_FLAGS_FETCH_LIMIT}
        loadMoreItems={loadMoreItems}
        isVirtualized
        data={{
          data: allFlagsData ?? [],
          state: allFlags.state,
        }}
        tableHeaders={tableHeaders}
      />
    </div>
  ) : (
    <AccessDeniedView />
  );
};
