import { UserInfo } from '@shared/interfaces/UserInfo';
import { FlagUserData } from '../types';

export const flagUserMapFunc = (user: FlagUserData): UserInfo => ({
  name: user.name,
  id: user.id,
  imageUrl: user.avatar,
  position: user.position,
  city: user.officeCity,
  country: user.officeCountry,
  email: user.email,
  status: user.status,
  zohoRecruitId: user.zohoRecruitId,
  isActive: user.isActive,
  primaryReportsTo: user.primaryReportsTo ? flagUserMapFunc(user.primaryReportsTo) : null,
  departments: user.departments,
  primaryDepartment: user.primaryDepartment,
  seniority: user.seniority,
});
