import { RootState } from '@modules/App/redux/store';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { MetroAreaData } from '@shared/interfaces/MetroAreaData';
import { UserProfileData } from '@shared/interfaces/user';

export const userProfileDataSelector = (state: RootState): AsyncData<UserProfileData> =>
  state.userProfilePage.userProfileData;

export const userPhysicalAddressUpdatingStateSelector = (state: RootState): DataState =>
  state.userProfilePage.physicalAddressUpdatingState;

export const editUserContactsUpdatingStateSelector = (state: RootState): DataState =>
  state.userProfilePage.userContactsUpdatingState;

export const userContactsUpdateNetworkErrorSelector = (state: RootState): string | null =>
  state.userProfilePage.userContactsUpdateNetworkError;

export const userProfileOfficeCountrySelector = (state: RootState): string | undefined =>
  state.userProfilePage.userProfileData.data?.officeCountry;

export const metroAreasSelector = (state: RootState): AsyncData<MetroAreaData> => state.userProfilePage.metroAreas;
