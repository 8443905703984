import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
    maxHeight: '100vh',
    overflow: 'auto',

    [BREAKPOINTS.MOBILE]: {
      height: 'auto',
      margin: 0,
    },
  },
  tableContainer: {
    display: 'flex',
    borderRadius: 8,
    flexDirection: 'column',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    backgroundColor: COLORS.WHITE,
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',

    [BREAKPOINTS.DESKTOP13]: {
      margin: 0,
      borderRadius: 0,
    },

    [BREAKPOINTS.MOBILE]: {
      marginTop: 0,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      maxHeight: 'inherit',
    },

    '&:after': {
      content: '""',
      display: 'block',
      height: 20,
    },
  },
  header: {
    width: '100%',
    minHeight: 64,
    fontSize: '24px',
    fontWeight: '500',
    padding: '0 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  title: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: '500',
    margin: 0,
  },
});
