import { put, all, call, takeLatest, select } from 'redux-saga/effects';
import { setClientEvalData, getClientEvalData, postClientEvalDataAction, autoSaveClientEvalForm } from './actions';
import { setClientEvalDataLoading, setPostingClientFeedback } from './reducers';
import { DataState } from '@shared/enums/DataState';
import { fetchClientEvalData, saveClientEvalData } from '@modules/EvaluationModule/api/clientEvalData';
import { ClientEvalData } from '@modules/EvaluationModule/interfaces/ClientEvalData';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { RootState } from '@modules/App/redux/store';

function* getClientEvalDataAsync({ payload }: ReturnType<typeof getClientEvalData>) {
  try {
    const setLoadingAction = setClientEvalDataLoading();
    yield put(setLoadingAction);
    const response: ClientEvalData = yield call(fetchClientEvalData, payload);
    const action = setClientEvalData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setClientEvalData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* postClientEvalDataActionAsync({
  payload: { evaluation, feedbackId },
}: ReturnType<typeof postClientEvalDataAction>) {
  try {
    yield put(setPostingClientFeedback(DataState.Pending));
    yield call(saveClientEvalData, { evaluation, feedbackId });
    yield put(setPostingClientFeedback(DataState.Fulfilled));
    if (evaluation.isConfirmed) {
      const clientEvalData: ClientEvalData = yield select(
        (state: RootState) => state.evaluation.clientEvalPage.clientEvalData.data
      );
      const mappedData = {
        ...clientEvalData,
        evaluation: {
          ...evaluation,
        },
      };
      yield put(setClientEvalData({ data: mappedData, state: DataState.Fulfilled }));
    }
  } catch {
    yield put(setPostingClientFeedback(DataState.Rejected));
    yield put(displayNotification('Something went wrong. Please submit your feedback again.'));
  }
}

function* autoSaveClientEvalFormAsync({ payload }: ReturnType<typeof autoSaveClientEvalForm>) {
  yield call(saveClientEvalData, payload);
}

function* watchGetUserEvalData() {
  yield takeLatest(getClientEvalData.type, getClientEvalDataAsync);
  yield takeLatest(postClientEvalDataAction.type, postClientEvalDataActionAsync);
  yield takeLatest(autoSaveClientEvalForm.type, autoSaveClientEvalFormAsync);
}

export function* clientEvalPageSaga(): Generator {
  yield all([watchGetUserEvalData()]);
}
