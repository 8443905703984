import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserMainData } from '@shared/interfaces/user';

import { State } from './types';

const initialState: State = {
  userPersonalData: { data: null, state: DataState.Pending },
};

export const slice = createSlice({
  name: 'userPage',
  initialState,
  reducers: {
    setUserPersonalDataLoading(state) {
      state.userPersonalData = { data: null, state: DataState.Pending };
    },
    setUserPersonalData(state, action: PayloadAction<AsyncData<UserMainData>>) {
      state.userPersonalData = action.payload;
    },
  },
});

export const { setUserPersonalData, setUserPersonalDataLoading } = slice.actions;

export default slice.reducer;
