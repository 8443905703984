import React from 'react';
import { useStyles } from './SubProjectTable.styles';
import { ReactComponent as EmptyViewIcon } from '@assets/icons/EmptyContactIcon.svg';
import { useClientSubProjectData } from '../../hooks/useClientSubProjectData';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import CopyLinkIcon from '@mui/icons-material/Link';
import Popup from 'reactjs-popup';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { RocketLoader } from '@shared/components/RocketLoader';
import { ManageClientModal } from '@modules/EvaluationModule/pages/ClientContactPage/components/ManageClientModal/ManageClientModal';
import { Alert, Stack, Typography } from '@mui/material';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import classNames from 'classnames';
import ContactMailOutlinedIcon from '@mui/icons-material/ContactMailOutlined';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';

interface Props {
  choosenProject: ClientContactData;
}

export const SubProjectTable: React.FC<Props> = ({ choosenProject }) => {
  const styles = useStyles();
  const clientSubProjectData = useClientSubProjectData(choosenProject);
  const isRejected = clientSubProjectData.state === DataState.Rejected;
  const isDataLoading = clientSubProjectData.state === DataState.Pending;

  return isRejected ? (
    <DataErrorView />
  ) : (
    <div className={styles.root}>
      {choosenProject.name === null || choosenProject.id === null ? (
        <div className={styles.emptyView}>
          <EmptyViewIcon />
          Select client to edit contacts
        </div>
      ) : isDataLoading ? (
        <div className={styles.loaderHolder}>
          <RocketLoader />
        </div>
      ) : (
        <div className={styles.workstreamView}>
          <Alert severity="warning" icon={false} className={styles.zohoWarning}>
            <Stack flexDirection="row" columnGap="10px" alignItems="center">
              <PermContactCalendarOutlinedIcon color="disabled" />
              <Typography variant="body2">
                Recently created client contacts take 1-2 minutes to be loaded from ZohoCRM
              </Typography>
            </Stack>
          </Alert>
          <Typography variant="h1" mb={4}>
            {choosenProject.name}
          </Typography>
          {clientSubProjectData.data?.map((workstream, i) => {
            return (
              <div key={i}>
                <Typography variant="h3">{workstream.name}</Typography>
                {!workstream.id ? (
                  <div className={classNames(styles.emptyClients, styles.nothingInZoho)}>
                    There is no information associated with {workstream.name}.<br />
                    Please contact Zoho CRM admins.
                  </div>
                ) : workstream.contacts.length === 0 ? (
                  <div className={styles.emptyClients}>No clients yet.</div>
                ) : (
                  workstream.contacts.map((contact, i) => {
                    const contactName: string =
                      contact.firstName === 'No name' && contact.lastName === 'No name'
                        ? 'No name'
                        : contact.firstName === 'No name' && contact.lastName !== 'No name'
                        ? contact.lastName
                        : contact.firstName !== 'No name' && contact.lastName === 'No name'
                        ? contact.firstName
                        : `${contact.firstName} ${contact.lastName}`;

                    return (
                      <div key={i} className={styles.contactRow}>
                        <div className={styles.userInfoHolder}>
                          <UserReporterBlock
                            user={{
                              name: contactName,
                              imageUrl: contact.imageUrl,
                              position: contact.title,
                              email: contact.emailAeTool,
                            }}
                            isDetailInfo
                          />
                        </div>
                        {contact.emailAeTool ? (
                          <div className={styles.contactEmail}>{contact.emailAeTool}</div>
                        ) : (
                          <div className={styles.nonEmailBlock}>
                            <WarningRoundedIcon />
                            Email missing
                          </div>
                        )}
                        <table>
                          <tbody>
                            <tr>
                              <td style={{ minWidth: '200px' }}>
                                {contact.feedbackGiver && (
                                  <Popup
                                    trigger={
                                      <div className={styles.emailStatusContainer}>
                                        {contact.doNotTouch ? (
                                          <CopyLinkIcon color="disabled" />
                                        ) : (
                                          <WorkspacePremiumOutlinedIcon color="disabled" />
                                        )}{' '}
                                        Ask eval feedback
                                      </div>
                                    }
                                    on={['hover']}
                                    position="top center"
                                    className={styles.popup}
                                  >
                                    <Typography>
                                      {contact.doNotTouch
                                        ? 'Sending link manually'
                                        : 'Feedback request with automated emails'}
                                    </Typography>
                                  </Popup>
                                )}
                              </td>
                              <td style={{ minWidth: '200px' }}>
                                {contact.csatGiver && (
                                  <Popup
                                    trigger={
                                      <div className={styles.emailStatusContainer}>
                                        <ContactMailOutlinedIcon color="disabled" />
                                        Send CSAT surveys
                                      </div>
                                    }
                                    on={['hover']}
                                    position="top center"
                                    className={styles.popup}
                                  >
                                    <Typography>
                                      {!contact.csatEmailsEnabled
                                        ? 'Sending link manually'
                                        : 'Feedback request with automated emails'}
                                    </Typography>
                                  </Popup>
                                )}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        {workstream.id && (
                          <ManageClientModal
                            contact={contact}
                            choosenProject={choosenProject}
                            workstream={workstream}
                          />
                        )}
                      </div>
                    );
                  })
                )}
                {(workstream.isWorkstream || choosenProject.name === workstream.name) && workstream.id ? (
                  <ManageClientModal choosenProject={choosenProject} workstream={workstream} />
                ) : workstream.id ? (
                  <div className={styles.emptyClients}>
                    There is no possibility to add client to {workstream.name}. Please contact Zoho CRM admins.
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
