import { put, all, call, takeLatest, takeEvery } from 'redux-saga/effects';
import { DataState } from '@shared/enums/DataState';
import {
  createCategory,
  createQuestion,
  deleteCategory,
  deleteQuestion,
  getSurveyQueue,
  runSurvey,
  setCategoryName,
  setQuestionText,
  setSurveyCategoryOrder,
  setSurveyQuestionOrder,
  setSurveyQueue,
  setSurveyQueueLoading,
} from './actions';
import {
  deleteCategoryEntity,
  deleteQuestionEntity,
  fetchRunSurveyStatus,
  fetchSurveyQueueData,
  patchActivateCategoryAndChangeOrder,
  patchCategoryName,
  patchDeactivateCategoryAndChangeOrder,
  patchQuestionText,
  patchSurveyCategoryOrder,
  patchSurveyQuestionOrder,
  postCategory,
  postQuestion,
  SurveyQueueResponse,
} from '@modules/HappinessModule/api/surveyQueue';
import { mapResponseDataToSurveyQueue } from '../helpers';
import { setRunSurveyStatus } from './reducers';
import { AxiosResponse } from 'axios';

function* getSurveyQueueAsync() {
  yield put(setSurveyQueueLoading());
  const responseData: SurveyQueueResponse = yield call(fetchSurveyQueueData);
  const surveyQueue = mapResponseDataToSurveyQueue(responseData.data);
  const setSurveyQueueAction = setSurveyQueue({
    data: surveyQueue,
    state: DataState.Fulfilled,
  });
  yield put(setSurveyQueueAction);
}

function* runSurveyAsync() {
  yield put(setRunSurveyStatus(DataState.Pending));
  try {
    const res: AxiosResponse = yield call(fetchRunSurveyStatus);
    yield put(setRunSurveyStatus(res.status === 200 ? DataState.Fulfilled : DataState.Rejected));
  } catch {
    yield put(setRunSurveyStatus(DataState.Rejected));
  }
}

function* setSurveyQuestionOrderAsync({ payload }: ReturnType<typeof setSurveyQuestionOrder>) {
  yield call(patchSurveyQuestionOrder, payload.questionId, payload.order);
}

function* setSurveyCategoryOrderAsync({ payload }: ReturnType<typeof setSurveyCategoryOrder>) {
  if (payload.prevIsActive !== payload.isActive) {
    if (payload.isActive) {
      yield call(patchActivateCategoryAndChangeOrder, payload.categoryId, payload.order);
    } else {
      yield call(patchDeactivateCategoryAndChangeOrder, payload.categoryId, payload.order);
    }
  } else {
    yield call(patchSurveyCategoryOrder, payload.categoryId, payload.order);
  }
}

function* setCategoryNameAsync({ payload }: ReturnType<typeof setCategoryName>) {
  yield call(patchCategoryName, payload.categoryId, payload.name);
}

function* setQuestionTextAsync({ payload }: ReturnType<typeof setQuestionText>) {
  yield call(patchQuestionText, payload.questionId, payload.text);
}

function* deleteCategoryAsync({ payload }: ReturnType<typeof deleteCategory>) {
  yield call(deleteCategoryEntity, payload);
}

function* deleteQuestionAsync({ payload }: ReturnType<typeof deleteQuestion>) {
  yield call(deleteQuestionEntity, payload);
}

function* createQuestionAsync({ payload }: ReturnType<typeof createQuestion>) {
  yield call(postQuestion, payload);
}

function* createCategoryAsync({ payload }: ReturnType<typeof createCategory>) {
  yield call(postCategory, payload);
}

function* watchQuestionSurvey() {
  yield takeEvery(setSurveyQuestionOrder.type, setSurveyQuestionOrderAsync);
  yield takeEvery(setSurveyCategoryOrder.type, setSurveyCategoryOrderAsync);
  yield takeLatest(getSurveyQueue.type, getSurveyQueueAsync);
  yield takeLatest(setCategoryName.type, setCategoryNameAsync);
  yield takeLatest(setQuestionText.type, setQuestionTextAsync);
  yield takeLatest(deleteCategory.type, deleteCategoryAsync);
  yield takeLatest(deleteQuestion.type, deleteQuestionAsync);
  yield takeLatest(createQuestion.type, createQuestionAsync);
  yield takeLatest(createCategory.type, createCategoryAsync);
  yield takeLatest(runSurvey.type, runSurveyAsync);
}

export function* surveyManagementPageSaga(): Generator {
  yield all([watchQuestionSurvey()]);
}
