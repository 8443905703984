import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
  },
  rootVirtualized: {
    height: 'calc(100vh - 60px)',
    minWidth: '1000px',

    [BREAKPOINTS.MOBILE]: {
      height: 'calc(100vh - 114px)',
    },
  },
  tableHeaderRow: {
    '& td': {
      paddingLeft: 24,
    },
    '& td:nth-child(1)': {
      minWidth: 220,
      width: '30%',
    },
    '& td:nth-child(2)': {
      width: '10%',
    },
    '& td:nth-child(3)': {
      width: '20%',
    },
    '& td:nth-child(4)': {
      width: '20%',
    },
    '& td:nth-child(6)': {
      width: '7%',
    },
    '& td:nth-child(7)': {
      width: '15%',
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  tableDataRow: {
    transition: 'background-color 0.1s',
    height: 70,
    backgroundColor: COLORS.WHITE,

    '& td': {
      paddingLeft: 24,
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },
    '&:last-child': {
      '& td': {
        borderBottom: 'none',
      },
    },

    '&:hover': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
      cursor: 'pointer',
    },
  },
  meterSkeleton: {
    width: '70%',
    height: 22,
  },
  date: {
    paddingLeft: 0,
    fontWeight: '400',
    fontSize: '14px',
  },
  datePlaceholder: {
    '&::after': {
      content: '""',
      display: 'inline-block',
      width: 20,
      height: 1,
      backgroundColor: COLORS.TEXT.SECONDARY,
    },
  },
  status: {
    fontWeight: '400',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    paddingRight: 10,
  },
  emptyView: {
    width: '100%',
    height: 600,
    margin: 0,
  },
  popup: {
    '&-content': {
      padding: '0px !important',
    },
  },
});
