import { RockyAvatar } from '@shared/components/RockyAvatar';
import { RockyPopup } from '@shared/components/RockyPopup';
import { UserInfo } from '@shared/interfaces/UserInfo';
import React, { FC, ReactElement } from 'react';
import { useStyles } from './Comment.styles';
import MessageIcon from '@mui/icons-material/Message';
import classNames from 'classnames';

interface Props {
  title: string;
  comment: string | ReactElement;
  user: UserInfo;
  triggerClassName?: string;
}

export const Comment: FC<Props> = ({ title, comment, user, triggerClassName }) => {
  const styles = useStyles();

  return (
    <RockyPopup
      trigger={
        <div className={classNames(styles.trigger, triggerClassName)}>
          <MessageIcon />
        </div>
      }
      on={['click']}
      position="bottom left"
      className={styles.popup}
    >
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.title}>{title}</div>
          <RockyAvatar small fullName={user.name} imageUrl={user.imageUrl} />
        </div>
        <div className={styles.divider}></div>
        <div className={styles.body}>{comment}</div>
      </div>
    </RockyPopup>
  );
};
