import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modal: {
    width: 700,
  },
  loader: {
    margin: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalBody: {
    padding: '0px',
  },
  modalHeader: {
    borderBottom: '0px',
    paddingBottom: '10px',
  },
  fieldBlock: {
    color: COLORS.TEXT.PRIMARY,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    fontWeight: '500',
    fontSize: '14px',
    padding: '16px 30px 24px 50px',
  },
  fieldBlockHighlighted: {
    backgroundColor: COLORS.HIGHLIGHTS.NOTE,
  },
  fieldValue: {
    fontWeight: '400',
    lineHeight: '130%',
    wordBreak: 'break-word',
    whiteSpace: 'pre-line',
  },
  fieldBlockRight: {
    borderLeft: `1px solid ${COLORS.DIVIDER}`,
  },
});
