import React, { memo, useState } from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import { Resizable } from 're-resizable';
import debounce from 'lodash/debounce';

import { useStyles } from './StickyNotes.styles';
import { Textarea } from '@shared/components/Textarea';
import { ReactComponent as ChangeSizeSvg } from '@assets/icons/changeSizeSvg.svg';
import { ReactComponent as Pin } from '@assets/icons/pin.svg';
import { ReactComponent as UnPin } from '@assets/icons/unpin.svg';

interface Props {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const StickyNotesComponent: React.FC<Props> = ({ isOpen, setIsOpen }) => {
  const styles = useStyles();
  const [heightToClose, setHeightToClose] = useState('calc(-400px + 45px)');

  const handleOpen = () => {
    if (isOpen) {
      return;
    }
    setIsOpen(true);
  };

  const debouncedSetHeightToCose = debounce(setHeightToClose, 200);

  return (
    <div onClick={() => handleOpen()}>
      <Resizable
        onResize={(_, __, el) => {
          debouncedSetHeightToCose(`calc(-${el.style.height} + 45px)`);
        }}
        defaultSize={{
          width: 320,
          height: 400,
        }}
        maxHeight={450}
        minHeight={200}
        enable={{
          top: false,
          right: false,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: isOpen,
        }}
        handleClasses={{ topLeft: styles.panelHandle }}
        style={{ position: 'fixed', bottom: `${isOpen ? 0 : heightToClose}` }}
        className={classNames(styles.root, {
          [styles.isClose]: !isOpen,
        })}
      >
        <span className={styles.notesLabel}>Edit notes</span>
        <button type="button" className={styles.pinBtn} onClick={() => setIsOpen(!isOpen)}>
          {!isOpen ? <UnPin /> : <Pin />}
        </button>
        <Field
          className={styles.goalsInput}
          component={Textarea}
          maxLength={4096}
          placeholder="Your notes and comments"
          name="notes"
        />
        {isOpen && <ChangeSizeSvg className={styles.changeSizeIcon} />}
      </Resizable>
    </div>
  );
};

export const StickyNotes = memo(StickyNotesComponent);
