import React, { FC, memo, useCallback, useEffect } from 'react';
import { pageTitles } from '@shared/enums/pageTitles';
import { paths } from '@shared/enums/paths';
import { useTitle } from '@shared/hooks/useTitle';
import { useStyles } from './ErrorView.styles';
import classNames from 'classnames';

interface Props {
  errorTextMain?: string;
  errorTextSecondary?: string;
  className?: string;
  component?: boolean;
}
const ErrorViewComponent: FC<Props> = ({
  errorTextMain = 'Something went wrong',
  errorTextSecondary = 'Our repair drones are working on it already',
  className,
  component,
}) => {
  const styles = useStyles();
  const setPageTitle = useTitle();

  const refresh = useCallback(() => {
    window.location.reload();
  }, []);

  useEffect(() => {
    setPageTitle(pageTitles.errorViewPage);
  }, []);

  return (
    <div className={classNames(styles.root, component ? styles.rootComponent : styles.rootPage, className)}>
      <h1 className={styles.mainHeading}>{errorTextMain}</h1>
      <h1 className={styles.symbol}>🙊</h1>
      <h3 className={styles.explainText}>{errorTextSecondary}</h3>
      <div className={styles.buttonsContainer}>
        <a className={styles.link} href={paths.root}>
          Home
        </a>
        <button className={styles.link} onClick={refresh}>
          Refresh
        </button>
      </div>
    </div>
  );
};

export const ErrorView = memo(ErrorViewComponent);
