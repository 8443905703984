import React, { FC } from 'react';
import { Paper, Stack, Typography } from '@mui/material';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { FlagFormFields } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagFormFields';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { AutomaticFlagWatchers, FlagWatcherData } from '@modules/HappinessModule/interfaces/FlagWatcherData';
import { FlagWatchersTable } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/components/FlagWhoIsInvolvedBlock/components/FlagWatchersTable';
import { ReactComponent as HandshakeIcon } from '@assets/icons/handshake.svg';
import { FLAG_WATCHERS_READ_UPDATE_PERMISSIONS } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { FlagWatcherRole } from '@modules/HappinessModule/pages/FlagPage/enums/flagWatcherRole';

interface Props {
  flagData: FlagData;
  userFlagPermissions: FlagPermissionsData;
  userAutomaticFlagWatchers: AutomaticFlagWatchers;
}

export const FlagWhoIsInvolvedBlockPreview: FC<Props> = ({
  flagData,
  userFlagPermissions,
  userAutomaticFlagWatchers,
}) => {
  const {
    flagFields: { isPrTeamInvolved, emailRecipients, flagWatchers, isRecipientsDisabled },
  } = flagData;
  const { assignedPR } = flagData.flagFields;

  const assignedPrFlagWatcher: FlagWatcherData | null =
    isPrTeamInvolved && userFlagPermissions.prTeamSection.isReadable && assignedPR
      ? {
          user: assignedPR,
          isAutoCc: false,
          permissions: FLAG_WATCHERS_READ_UPDATE_PERMISSIONS,
          flagWatcherRole: FlagWatcherRole.AssignedPRTeamMember,
          canBeRemoved: false,
        }
      : null;

  return (
    <Paper variant="elevation" elevation={2}>
      <Stack padding="61px 50px 43px 50px">
        <Stack marginLeft="16px" marginBottom="16px" columnGap="40px" flexDirection="row" alignItems="center">
          <Typography variant="h2">People with access to this flag</Typography>
          <Stack flexDirection="row" columnGap="10px" alignItems="center">
            <HandshakeIcon style={{ width: '24px', height: '24px' }} />
            <Typography variant="body2">
              {isPrTeamInvolved ? FlagFormFields.isPrTeamInvolved.label : "HR team isn't involved"}
            </Typography>
          </Stack>
        </Stack>

        <FlagWatchersTable
          userAutomaticFlagWatchers={userAutomaticFlagWatchers}
          emailRecipients={emailRecipients}
          flagWatchers={assignedPrFlagWatcher ? [assignedPrFlagWatcher, ...flagWatchers] : flagWatchers}
          isRecipientsDisabled={isRecipientsDisabled}
          isNewFlag={false}
        />
      </Stack>
    </Paper>
  );
};
