import { RockyNotifications } from '@modules/App/components/RockyNotifications';
import { ErrorView } from '@modules/App/pages/ErrorView';
import React, { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { useStyles } from './NotProtectedRoute.styles';
import * as Sentry from '@sentry/react';

export const NotProtectedRoute: FC = () => {
  const styles = useStyles();

  return (
    <div className={styles.root} id="layout-root">
      <Sentry.ErrorBoundary fallback={<ErrorView component />}>
        <Outlet></Outlet>
      </Sentry.ErrorBoundary>
      <div className={styles.notificationsHolder}>
        <RockyNotifications />
      </div>
    </div>
  );
};
