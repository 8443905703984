import * as Yup from 'yup';
import { isValidPhoneNumber } from 'mui-tel-input';

import DeliveryAddressType from '@shared/enums/DeliveryAddressType';

const INCORRECT_NUMBER_FORMAT = 'Incorrect number format';

const fieldsSchema = {
  deliveryAddress: Yup.object().shape({
    phoneNumber: Yup.string().test('phoneNumber', INCORRECT_NUMBER_FORMAT, (value, context) => {
      if (context.parent.type !== DeliveryAddressType['Nova Poshta']) return true;
      if (!value) return false;

      return isValidPhoneNumber(value);
    }),
  }),
};

export const validationSchema = Yup.object().shape(fieldsSchema);
