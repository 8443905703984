import React, { FC, memo } from 'react';
import { paths } from '@shared/enums/paths';
import TabSwitcher from '../TabSwitcher/TabSwitcher';
import { useStyles } from './RockyModulesTabs.styles';

interface Props {
  path: string;
  peerReviews?: boolean;
  profile?: boolean;
  flags?: boolean;
  bonuses?: boolean;
  userZohoId?: string;
  compensation?: boolean;
  links?: boolean;
  goals?: boolean;
  happiness?: boolean;
  evaluations?: boolean;
  promotions?: boolean;
}

const RockyModulesTabsComponent: FC<Props> = ({
  path,
  peerReviews,
  flags,
  profile,
  compensation,
  bonuses,
  userZohoId,
  links,
  goals,
  happiness = true,
  evaluations = true,
  promotions,
}) => {
  const styles = useStyles();
  const noTab = {
    linkTo: '',
    linkName: '',
  };

  return (
    <div className={styles.root}>
      <TabSwitcher
        tabs={[
          profile ? { linkTo: `${path}/${paths.profile}`, linkName: 'Profile' } : noTab,
          happiness ? { linkTo: `${path}/${paths.responses}`, linkName: 'Happiness' } : noTab,
          evaluations ? { linkTo: `${path}/${paths.evaluations}`, linkName: 'Evaluations' } : noTab,
          promotions ? { linkTo: `${path}/${paths.promotions}`, linkName: 'Career' } : noTab,
          goals ? { linkTo: `${path}/${paths.goals}`, linkName: 'Goals' } : noTab,
          flags ? { linkTo: `${path}/${paths.flags}`, linkName: 'Flags' } : noTab,
          peerReviews ? { linkTo: `${path}/${paths.peerReviews}`, linkName: 'My peer reviews' } : noTab,
          links ? { linkTo: `${path}/${paths.links}`, linkName: 'Links' } : noTab,
          compensation ? { linkTo: `${path}/${paths.compensation}`, linkName: 'Compensation' } : noTab,
          bonuses ? { linkTo: `${path}/${paths.bonuses}/${userZohoId}`, linkName: 'Bonuses' } : noTab,
        ]}
      />
    </div>
  );
};

export const RockyModulesTabs = memo(RockyModulesTabsComponent);
