import React, { FC } from 'react';
import { useStyles } from './AnotherOfferInvolvedBlockPreview.styles';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { FlagFormFields } from '../../../../../../components/FlagForm/constants/FlagFormFields';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { noAdditionalNotes, yesOrNoByBoolean } from '@shared/helpers/yesOrNoByBoolean';
import { FlagFieldBlockPreview } from '../FlagFieldBlockPreview';
import { formatFinancialValue } from '@modules/EvaluationModule/pages/UserCompensationPage/helpers/currencyOptions';
import compact from 'lodash/compact';
import { NOT_SETTED_VALUE_MSG } from '@modules/HappinessModule/pages/FlagPage/constants/constants';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';

interface Props {
  flagData: FlagData;
  userFlagPermissions: FlagPermissionsData;
}

export const AnotherOfferInvolvedBlockPreview: FC<Props> = ({ flagData, userFlagPermissions }) => {
  const styles = useStyles();
  const { flagFields } = flagData;

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid
        container
        className={
          userFlagPermissions.compensationDetails.isReadable
            ? styles.anotherOfferInvolvedBlockHrView
            : styles.mainContainer
        }
        columnSpacing={1}
        rowGap="23px"
      >
        <Grid item xs={12}>
          <Typography variant="h2">
            {flagFields.isAnotherOfferInvolved
              ? FlagFormFields.isAnotherOfferInvolved.label
              : 'No other offer is involved'}
          </Typography>
        </Grid>
        {userFlagPermissions.compensationDetails.isReadable &&
          flagFields.isAnotherOfferInvolved &&
          userFlagPermissions.compensationDetails.isReadable && (
            <Grid item xs={12}>
              <Stack flexDirection="row" columnGap="40px">
                {[
                  {
                    label: flagFields.companyName ? 'Company' : 'Employment type',
                    value: flagFields.companyName
                      ? compact([flagFields.companyName, flagFields.hiringType]).join(', ')
                      : flagFields.hiringType
                      ? flagFields.hiringType
                      : NOT_SETTED_VALUE_MSG,
                  },
                  {
                    label: 'Compensation',
                    value: formatFinancialValue(flagFields.compensation)
                      ? compact([
                          compact([formatFinancialValue(flagFields.compensation), flagFields.currencyType]).join(' '),
                          flagFields.amountType,
                        ]).join(' / ')
                      : NOT_SETTED_VALUE_MSG,
                  },
                  {
                    label: 'Offer accepted',
                    value: yesOrNoByBoolean(flagFields.isOfferAccepted ?? null),
                  },
                  { label: 'Benefits and bonuses', value: noAdditionalNotes(flagFields.benefits ?? null) },
                ].map(({ label, value }) => (
                  <FlagFieldBlockPreview key={label} label={label} value={value ?? ''} />
                ))}
              </Stack>
            </Grid>
          )}
      </Grid>
    </Paper>
  );
};
