import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { DataState } from '@shared/enums/DataState';
import { useAuth } from '@shared/hooks/useAuth';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { EvaluationForm } from './components/EvaluationForm/EvaluationForm';
import { EvaluationFormResults } from './components/EvaluationFormResults/EvaluationFormResults';
import { RocketLoader } from '@shared/components/RocketLoader';
import { useStyles } from './EvalFormAndResultsPage.styles';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { evalPageDataSelector, evalPermissionsSelector } from '../EvalPage/redux/selectors';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';

export const EvalFormAndResultsPage: React.FC = () => {
  const styles = useStyles();
  const evalPageData = useSelector(evalPageDataSelector);
  const evalPermissions = useSelector(evalPermissionsSelector);
  const { currentUser } = useAuth();

  const isCurrentUserEvaluator = currentUser?.id === evalPageData.data?.owner.id;
  const evaluatorHasNotConfirmed =
    evalPageData.data?.evaluation.evaluator && !evalPageData.data?.evaluation.evaluator.isConfirmed;

  const isCurrentUserEvaluatee = currentUser?.id === evalPageData.data?.userId;
  const evaluateeHasNotConfirmed =
    evalPageData.data?.evaluation.evaluatee && !evalPageData.data?.evaluation.evaluatee.isConfirmed;

  const hasReadPermissions = evalPermissions.data?.evaluation.isReadable;

  const isShowForm =
    evalPageData.data?.status !== EvaluationStatus.Closed &&
    ((isCurrentUserEvaluatee && evaluateeHasNotConfirmed) || (isCurrentUserEvaluator && evaluatorHasNotConfirmed));

  const isDataLoading = evalPageData.state === DataState.Pending;

  const evaluateeName = evalPageData.data?.userHistory.name;
  const setPageTitle = useTitle();

  useEffect(() => {
    if (evaluateeName) {
      setPageTitle(`${evaluateeName} ${pageTitles.userEvalPage}`);
    }
  }, [evaluateeName]);

  return (
    <>
      {isDataLoading ? (
        <div className={styles.loaderHolder}>
          <RocketLoader />
        </div>
      ) : isShowForm ? (
        <EvaluationForm evalFormPageData={evalPageData} />
      ) : hasReadPermissions ? (
        <EvaluationFormResults evalFormPageData={evalPageData} />
      ) : (
        <AccessDeniedView inComponent />
      )}
    </>
  );
};
