import React, { FC, ReactNode } from 'react';
import { useStyles } from './FlagNoValueFallback.styles';

interface Props {
  value?: string | number | null | [];
  field: ReactNode;
  noValueText?: string;
}

export const FlagNoValueFallback: FC<Props> = ({ value, field, noValueText = 'None' }) => {
  const styles = useStyles();

  return <>{!value && value !== 0 ? <div className={styles.noValueText}>{noValueText}</div> : field ? field : null}</>;
};
