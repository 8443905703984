import React, { FC } from 'react';
import { Table } from '@shared/components/Table';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { useStyles } from './PeopleHappinessTable.styles';
import { PeopleHappinessDataItem } from '@modules/HappinessModule/pages/PeopleHappinessPage/interfaces/PeopleHappinessDataItem';
import { PeopleHappinessTableRow } from '../PeopleHappinessTableRow';

interface Props {
  data: PeopleHappinessDataItem[];
  sortInfo: SortInfo;
  isDataLoading: boolean;
  isDataChunkLoading: boolean;
  onSortClick: (sortInfo: SortInfo) => void;
  onScrollThresholdPass: () => void;
  tableSkeleton: React.ReactNode;
}

export const PeopleHappinessTable: FC<Props> = ({
  data,
  sortInfo,
  isDataLoading,
  isDataChunkLoading,
  onSortClick,
  onScrollThresholdPass,
  tableSkeleton,
}) => {
  const styles = useStyles();
  const tableHeaders = [
    { title: 'Team members', fieldName: 'fullName' },
    { title: 'Rate', fieldName: 'reaction' },
    { title: 'Project', fieldName: '' },
    { title: '', fieldName: '' },
  ];

  return (
    <div className={styles.root}>
      <Table
        isDataChunkLoading={isDataChunkLoading}
        sortInfo={sortInfo}
        isDataLoading={isDataLoading}
        onSortClick={onSortClick}
        tableHeaders={tableHeaders}
        skeleton={tableSkeleton}
        onScrollThresholdPass={onScrollThresholdPass}
        customStyles={{ tableHeaderRow: styles.tableHeaderRow }}
      >
        {data.map((dataItem) => (
          <PeopleHappinessTableRow key={dataItem.id} dataItem={dataItem} />
        ))}
      </Table>
    </div>
  );
};
