import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  recipientsListContainer: {
    display: 'flex',
    flexDirection: 'column',
    margin: '0 -16px',
  },
  recipientDescription: {
    color: COLORS.TEXT.SECONDARY,
  },
  recipientItem: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '13px 16px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  recipientCcItem: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 2,
    padding: '13px 16px',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '120%',
  },
});
