import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setCurrentUser } from './actions';

import { State } from './types';

const initialState: State = {
  currentUser: { data: null, state: DataState.Pending },
  locationNotMatchingSince: null,
};

export const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLocationNotMatchingSince(state, action) {
      state.locationNotMatchingSince = action.payload.locationNotMatchingSince;
    },
    setTermsOfUseAcceptedVersion(state, { payload }) {
      if (!state.currentUser.data) {
        return;
      }
      state.currentUser.data.latestTermsOfUseVersionAccepted = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setCurrentUser, (state, action) => {
      state.currentUser = action.payload;
    });
  },
});

export const { setLocationNotMatchingSince, setTermsOfUseAcceptedVersion } = slice.actions;
export default slice.reducer;
