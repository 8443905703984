import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {},
  popup: {
    '&-content': {
      width: 'fit-content !important',
      padding: 24,
      boxShadow: '0px 2px 14px -3px rgba(52, 72, 87, 0.25)',
      borderRadius: 8,
      fontWeight: '400',
      fontSize: '14px',
    },
  },
  flagIconContainer: {
    marginTop: -3,
    paddingRight: 3,
  },
  popupTriger: {
    fontWeight: '500',
    fontSize: '14px',
    marginBottom: 8,

    '&:not(:first-child)': {
      marginTop: 23,
    },
  },
  flagContainer: {
    display: 'flex',
    alignItems: 'self-start',
    fontWeight: '400',
    fontSize: '14px',
    cursor: 'pointer',
  },
  flagSecondaryLabel: {
    fontSize: '13px',
    color: COLORS.TEXT.SECONDARY,
    lineHeight: '120%',
    whiteSpace: 'nowrap',
  },
  shevron: {
    width: 13,
    fill: COLORS.TEXT.SECONDARY,
    margin: '0 0 0 5px',
  },
});
