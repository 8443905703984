import React, { FC, memo, useCallback, useEffect } from 'react';
import { useStyles } from './PageNotFound.styles';
import img404 from './images/404.png';
import { Link } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';

const PageNotFoundComponent: FC = () => {
  const styles = useStyles();
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(pageTitles.pageNotFound);
  }, []);

  const goToPrevPage = useCallback(() => {
    history.back();
  }, []);

  return (
    <div className={styles.root}>
      <img className={styles.notFoundImg} src={img404}></img>
      <h1 className={styles.notFoundText}>The page you are looking for isn't here</h1>
      <div className={styles.buttonsContainer}>
        <Link className={styles.link} to={paths.root}>
          Home
        </Link>
        <button className={styles.link} onClick={goToPrevPage}>
          Previous page
        </button>
      </div>
    </div>
  );
};

export const PageNotFound = memo(PageNotFoundComponent);
