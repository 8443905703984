import { FeedbackReasonValues } from '../enums/FeedbackReasonsValues';

export const initialValuesOther = {
  feedbackText: '',
  feedbackReason: FeedbackReasonValues.Other,
};

export const initialValuesStructure = {
  feedbackText: '',
  feedbackReason: FeedbackReasonValues.IncorectStructure,
};

export const initialValuesProject = {
  feedbackText: '',
  feedbackReason: FeedbackReasonValues.IncorectProject,
};

export const initialValuesContactPR = {
  feedbackText: '',
  feedbackReason: FeedbackReasonValues.ContactPRTeam,
};
