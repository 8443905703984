import { CompensationType } from '@modules/EvaluationModule/enums/CompensationType';

export const TOOLTIP_EXPLAINING_TOTAL_COMPENSATION =
  'Target compensation that consists of base + sum of assigned active KPI bonuses';

const NO_HINTS_OBJ = {
  currentUser: {
    withoutBonus: '',
    withBonus: '',
  },
  thirdUser: {
    withoutBonus: '',
    withBonus: '',
  },
};

export const hintsExplainingHowTotalCompensationIsCalculated = {
  [CompensationType.Hourly]: {
    currentUser: {
      withoutBonus:
        'This is your compensation plan, including historical changes. The “Total” amount is calculated as your hourly rate multiplied by the average number of working hours per month in your location.\n\n To see your payroll with payments history please check out your payroll spreadsheet.',
      withBonus:
        'This is your compensation plan, including historical changes. The “Total” amount is calculated as your hourly rate multiplied by the average number of working hours per month in your location plus sum of all assigned KPI bonuses.\n\nTo see details of your KPI bonuses results and payouts please click Bonuses tab. To see your payroll with payments history please check out your payroll spreadsheet.',
    },
    thirdUser: {
      withoutBonus:
        'This is a compensation plan, including historical changes. The “Total” amount is calculated as hourly rate multiplied by the average number of working hours per month in this location.',
      withBonus:
        'This is a compensation plan, including historical changes. The “Total” amount is calculated as hourly rate multiplied by the average number of working hours per month in this location plus sum of all assigned KPI bonuses.\n\nTo see details of KPI actual results or to assign a new bonus please check out Bonuses tab.',
    },
  },
  [CompensationType.Monthly]: {
    currentUser: {
      withoutBonus:
        'This is your compensation plan, including historical changes. To see your payroll with payments history please check out your payroll spreadsheet.',
      withBonus:
        'This is your compensation plan, including historical changes. The “Total” amount means target compensation calculated as the sum of your base amount and all assigned KPI bonuses.\n\nTo see details of your KPI bonuses results and payouts please click Bonuses tab. To see your payroll with payments history please check out your payroll spreadsheet.',
    },
    thirdUser: {
      withoutBonus: 'This is a compensation plan, including historical changes.',
      withBonus:
        'This is a compensation plan, including historical changes. The “Total” amount means target compensation calculated as the sum of base amount and all assigned KPI bonuses.\n\nTo see details of KPI actual results or to assign a new bonus please check out Bonuses tab.',
    },
  },
  [CompensationType.Biweekly]: NO_HINTS_OBJ,
  [CompensationType.Yearly]: NO_HINTS_OBJ,
};
