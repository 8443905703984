import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AvatarGroup, Box, Button, Fade, FormControlLabel, Stack, Switch, Tooltip, Typography } from '@mui/material';
import PlusCircle from '@mui/icons-material/AddCircleOutline';
import { TransitionGroup } from 'react-transition-group';
import classNames from 'classnames';

import { useStyles } from './EvalSettingsPage.styles';
import { PeerSearch } from './components/PeerSearch';
import { useAuth } from '@shared/hooks/useAuth';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { DataState } from '@shared/enums/DataState';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { useEvalSettingsPageData } from './hooks/useEvalSettingsPageData';
import { ReviewerBlock } from './components/ReviewerBlock';
import {
  addClientToReviewersAction,
  addPeerFeedbackAction,
  deleteClientFeedbackAction,
  deletePeerFeedbackAction,
} from './redux/actions';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { ClientSuggestionData } from '@modules/EvaluationModule/interfaces/ClientSuggestionData';
import { RockyButton } from '@shared/components/RockyButton';
import { paths } from '@shared/enums/paths';
import { Role } from '@shared/enums/role';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { evalPageDataSelector, evalPermissionsSelector } from '../EvalPage/redux/selectors';
import { hideAnonymousPeerReviews } from '../EvalPage/helpers/hideAnonymousPeerReviews';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { AddAllReviewersModal } from './components/AddAllReviewersModal';
import { useModalState } from '@shared/hooks/useModalState';

const MAX_NUM_OF_PEERS = 10;

export const EvalSettingsPage: React.FC = () => {
  const navigate = useNavigate();
  const evalPageData = useSelector(evalPageDataSelector);
  const evalData = evalPageData.data;
  const isLoading = evalPageData.state === DataState.Pending;
  const evaluationId = evalData?.evaluationId;
  const peerReviews = evalData?.peerReviews;
  const clientReviews = evalData?.clientReviews;
  const evaluationStatus = evalData?.status;
  const styles = useStyles();
  const { currentUser } = useAuth();
  const user = useSelector(userSelector);
  const evalPermissions = useSelector(evalPermissionsSelector);
  const { openModal, closeModal, isModalOpen } = useModalState();

  const evaluator = user?.primaryReportsTo;
  const isCurrentUserEvaluator = evaluator?.id === currentUser?.id;
  const hasUpdatePermissions = evalPermissions.data?.evaluation.isUpdatable;

  const isEvalClosed = evalData?.status === EvaluationStatus.Closed;
  const isEvalNotStarted = evalData?.status === EvaluationStatus.Upcoming;
  const { clientsSuggestions, peerSuggestions } = useEvalSettingsPageData();
  const dispatch = useDispatch();
  const showClientsSuggestions =
    !isEvalClosed &&
    (!clientReviews ||
      (clientReviews.length < MAX_NUM_OF_PEERS && clientsSuggestions.data && !!clientsSuggestions.data?.length));

  const filteredAndSortedClientSuggestions = [
    ...Array.from(clientsSuggestions.data || []).reduce((acc, workstream) => {
      const filteredContacts = {
        name: workstream.name,
        contacts: workstream.contacts.filter((contact) => {
          if (!clientReviews) {
            return false;
          }
          const evalNames = clientReviews.map((el) => el.user.name);
          return !evalNames.includes(contact.name);
        }),
      };
      return [...acc, filteredContacts];
    }, [] as ClientSuggestionData[]),
  ].sort((a, b) => (a.contacts.length === 0 ? 1 : b.contacts.length === 0 ? -1 : 0));

  const handlePeerSelect = useCallback(
    (value) => {
      if (evalPageData.data) {
        dispatch(addPeerFeedbackAction([{ user: value, evalId: evalPageData.data?.evaluationId }]));
      }
    },
    [evalPageData]
  );
  const handleAddAll = useCallback(
    (value) => {
      if (evalPageData.data) {
        dispatch(
          addPeerFeedbackAction(value.map((user: UserInfo) => ({ user, evalId: evalPageData.data?.evaluationId })))
        );
      }
    },
    [evalPageData]
  );
  const [isAnonymousPeerFeedbacksShown, setIsAnonymousPeerFeedbacksShown] = useState(false);
  const isThereAnyAnonymousPeerFeedbacks = peerReviews?.some((el) => el.isAnonymous);

  const peerReviewsList =
    peerReviews && isThereAnyAnonymousPeerFeedbacks
      ? isAnonymousPeerFeedbacksShown
        ? peerReviews
        : hideAnonymousPeerReviews(peerReviews)
      : peerReviews;

  const isUserCanSeeAnonymousPeerFeedbacks = isThereAnyAnonymousPeerFeedbacks && isCurrentUserEvaluator;

  const filteredPeerSuggestion = peerSuggestions?.data?.prevEvalPeers.filter((peer) =>
    peerReviewsList?.every((pr) => pr.user.id !== peer.id)
  );
  const isAddAllPrevPeerCauseLimitExceed =
    peerReviewsList &&
    peerSuggestions?.data &&
    peerSuggestions.data.prevEvalPeers.length + peerReviewsList.length > MAX_NUM_OF_PEERS;
  const [isPrevEvalExpanded, setIsPrevEvalExpanded] = useState(false);

  return (
    <>
      {!isLoading && !hasUpdatePermissions ? (
        <AccessDeniedView inComponent />
      ) : (
        <div className={styles.root}>
          <div className={classNames(styles.block, styles.leftBlock)}>
            <Stack flexDirection="row" columnGap="16px" alignItems="center" className={styles.blockTitle}>
              <Typography variant="h2">Peer reviews</Typography>
              {isUserCanSeeAnonymousPeerFeedbacks && (
                <FormControlLabel
                  control={
                    <Switch
                      checked={isAnonymousPeerFeedbacksShown}
                      onChange={() => setIsAnonymousPeerFeedbacksShown((value) => !value)}
                    />
                  }
                  label={<Typography variant="body1">Show hidden names</Typography>}
                />
              )}
            </Stack>

            {peerReviewsList && evaluationId && (
              <>
                {peerReviewsList.map((peerReview, index) => (
                  <ReviewerBlock
                    isFeedbackSubmitted={peerReview.feedback?.isConfirmed}
                    evaluationStatus={evaluationStatus as EvaluationStatus}
                    onDelete={() =>
                      dispatch(
                        deletePeerFeedbackAction({
                          feedbackId: peerReview.id,
                          evalId: evaluationId,
                          user: peerReview.user,
                        })
                      )
                    }
                    key={`${peerReview.id}-${index}`}
                    user={peerReview.user}
                    feedbackStatus={peerReview.status}
                    evalId={evaluationId}
                    feedbackId={peerReview.id}
                    isLoading={!peerReview.id}
                  />
                ))}
                {peerReviewsList.length > 0 && isEvalNotStarted && (
                  <div className={classNames(styles.infoBlock, styles.infoBlockNote)}>
                    Peers will receive an email once you start the evaluation.
                  </div>
                )}
                {!isEvalClosed && (
                  <div className={styles.infoBlock}>
                    {peerReviewsList.length < MAX_NUM_OF_PEERS
                      ? `Please select up to ${MAX_NUM_OF_PEERS} peers from evaluatee's projects or other AgileEngine departments`
                      : 'Max number of peers is reached'}
                  </div>
                )}
              </>
            )}

            {peerSuggestions.state === DataState.Pending ? (
              <RockySkeleton
                isLoading={peerSuggestions.state === DataState.Pending}
                className={styles.userBlockSkeleton}
                count={2}
                element={null}
              />
            ) : (
              (!peerReviewsList || peerReviewsList.length < MAX_NUM_OF_PEERS) &&
              peerSuggestions.data &&
              peerSuggestions.data.prevEvalPeers.length > 0 &&
              !isEvalClosed && (
                <>
                  <Typography className={styles.projectLabel} marginBottom={1}>
                    Previous evaluation
                  </Typography>

                  <Stack direction="row" marginBottom={1}>
                    <AvatarGroup
                      max={7}
                      className={styles.avatarGroup}
                      onClick={() => setIsPrevEvalExpanded((isExpanded) => !isExpanded)}
                    >
                      {peerSuggestions.data.prevEvalPeers.map((peer: UserInfo, i: number) => (
                        <Box key={peer.id} marginLeft={i === 0 ? 0 : '-0.25rem'} zIndex={7 - i}>
                          <RockyAvatar
                            imageUrl={peer.imageUrl}
                            country={peer.country || ''}
                            city={peer.city || ''}
                            fullName={peer.name}
                            medium
                          />
                        </Box>
                      ))}
                    </AvatarGroup>

                    <Tooltip
                      title={
                        isAddAllPrevPeerCauseLimitExceed &&
                        'Limit reached: Only 10 peer reviewers allowed per evaluation.'
                      }
                    >
                      <Box sx={{ marginLeft: 'auto' }}>
                        <Button
                          sx={{ height: '100%' }}
                          onClick={openModal}
                          disabled={isAddAllPrevPeerCauseLimitExceed || !filteredPeerSuggestion?.length}
                        >
                          Add all
                          <PlusCircle sx={{ marginLeft: '0.25rem' }} />
                        </Button>
                      </Box>
                    </Tooltip>
                  </Stack>

                  <TransitionGroup>
                    {isPrevEvalExpanded && (
                      <Fade>
                        <ul className={styles.peersList}>
                          {peerSuggestions.data.prevEvalPeers.map((peer: UserInfo) => {
                            const peerReview = peerReviewsList?.find((pr) => pr.user.id === peer.id);
                            const onAdd = !peerReview ? () => handlePeerSelect(peer) : undefined;

                            return (
                              <ReviewerBlock
                                key={peer.id}
                                feedbackId={peerReview?.id || null}
                                evalId={evaluationId || null}
                                evaluationStatus={evaluationStatus as EvaluationStatus}
                                user={peer}
                                onAdd={onAdd}
                                showFeedbackStatus={false}
                                feedbackStatus={peerReview?.status}
                              />
                            );
                          })}
                        </ul>
                      </Fade>
                    )}
                  </TransitionGroup>
                </>
              )
            )}

            {(!peerReviewsList || peerReviewsList.length < MAX_NUM_OF_PEERS) && !isEvalClosed && (
              <div className={styles.search}>
                <Typography className={styles.projectLabel} marginBottom={1}>
                  All AgileEngine
                </Typography>

                <PeerSearch />
              </div>
            )}

            {peerSuggestions.state === DataState.Pending ? (
              <RockySkeleton
                isLoading={peerSuggestions.state === DataState.Pending}
                className={styles.userBlockSkeleton}
                count={2}
                element={null}
              />
            ) : (
              (!peerReviewsList || peerReviewsList.length < MAX_NUM_OF_PEERS) &&
              peerSuggestions.data &&
              peerSuggestions.data.suggestedPeers.length > 0 &&
              !isEvalClosed && (
                <>
                  <div className={styles.projectLabel}>{evalData?.userHistory.department.name}</div>
                  <ul className={styles.peersList}>
                    {peerSuggestions.data.suggestedPeers.map((peer: UserInfo) => (
                      <ReviewerBlock
                        key={peer.id}
                        feedbackId={null}
                        evalId={null}
                        evaluationStatus={evaluationStatus as EvaluationStatus}
                        user={peer}
                        onAdd={() => handlePeerSelect(peer)}
                      />
                    ))}
                  </ul>
                </>
              )
            )}
          </div>

          <div className={classNames(styles.block, styles.rightBlock)}>
            <div className={styles.clientBlockTitle}>
              Client reviews
              {currentUser &&
                (currentUser.rockyRoles.includes(Role.teamLead) ||
                  currentUser.rockyRoles.includes(Role.projectManager)) && (
                  <RockyButton small inverted onClick={() => navigate(`${paths.clientContacts}`)}>
                    Manage client reviewers
                  </RockyButton>
                )}
            </div>
            {clientsSuggestions.state === DataState.Pending ? (
              <RockySkeleton
                isLoading={clientsSuggestions.state === DataState.Pending}
                className={styles.userBlockSkeleton}
                count={3}
                element={null}
              />
            ) : isEvalClosed ||
              (clientsSuggestions &&
                filteredAndSortedClientSuggestions?.length === 0 &&
                clientReviews?.length === 0) ? (
              <div>No clients found</div>
            ) : (
              <div className={styles.evalClientsList}>
                {clientReviews && clientReviews?.length && evaluationStatus && evaluationId ? (
                  clientReviews.map((clientReview) => (
                    <ReviewerBlock
                      isAskWithEmail={clientReview.isAskWithEmail}
                      evaluationStatus={evaluationStatus as EvaluationStatus}
                      key={clientReview.id}
                      user={clientReview.user}
                      feedbackStatus={clientReview.status}
                      isLoading={!clientReview.id && EvaluationStatus.Upcoming === evaluationStatus}
                      onDelete={() =>
                        dispatch(deleteClientFeedbackAction({ feedbackId: clientReview.id, evalId: evaluationId }))
                      }
                      feedbackId={clientReview.id}
                      evalId={evaluationId}
                      isClient
                    />
                  ))
                ) : (
                  <div className={styles.infoBlock}>Select feedback givers from evaluatee's projects</div>
                )}
                {showClientsSuggestions &&
                  filteredAndSortedClientSuggestions?.map((workstream, i) =>
                    workstream.contacts.length > 0 ? (
                      <div key={i}>
                        <div className={styles.subtitle}>{workstream.name}</div>
                        {evaluationId &&
                          workstream.contacts.map((client) => (
                            <ReviewerBlock
                              feedbackId={null}
                              evalId={evaluationId}
                              evaluationStatus={evaluationStatus as EvaluationStatus}
                              key={client.id}
                              user={client}
                              onAdd={() => dispatch(addClientToReviewersAction({ evalId: evaluationId, client }))}
                              isClient
                            />
                          ))}
                      </div>
                    ) : (
                      <div className={styles.noClientBlock} key={i}>
                        <div className={styles.subtitle}>{workstream.name}</div>
                        <div className={styles.noClientsLabel}>
                          No client reviewers available for {workstream.name} project
                        </div>
                      </div>
                    )
                  )}
              </div>
            )}
          </div>

          <AddAllReviewersModal
            isModalOpen={isModalOpen}
            closeModal={closeModal}
            onContinue={handleAddAll}
            peers={filteredPeerSuggestion}
          />
        </div>
      )}
    </>
  );
};
