import classNames from 'classnames';
import { FC, memo } from 'react';
import { OptionProps } from 'react-select';
import { useStyles } from './PeerSearchOption.styles';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import PlusCircle from '@mui/icons-material/AddCircleOutline';
import { UserInfo } from '@shared/interfaces/UserInfo';

const PeerSearchOptionComponent: FC<OptionProps> = ({ innerProps, isFocused, data }) => {
  const styles = useStyles();
  const userData = data as UserInfo;

  return (
    <div {...innerProps} className={classNames(styles.root, { [styles.isFocused]: isFocused })}>
      <UserReporterBlock isDetailInfo={true} user={userData} />
      <div className={styles.icon}>
        <PlusCircle />
      </div>
    </div>
  );
};

export const PeerSearchOption = memo(PeerSearchOptionComponent);
