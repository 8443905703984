import { FC, memo, useEffect } from 'react';
import { useStyles } from './ScheduleEvalMetting.styles';
import { getGoogleCalendarEvalEventsMeetingLink } from '@modules/EvaluationModule/helpers/evaluationMeeting';
import { paths } from '@shared/enums/paths';
import { format, parseISO } from 'date-fns';
import Popup from 'reactjs-popup';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import { ReactComponent as Calendar } from '@assets/icons/Calendar.svg';
import ArrowDown from '@mui/icons-material/ExpandMore';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { GoogleCalendarEvent } from '@modules/EvaluationModule/interfaces/GoogleCalendarEvent';
import { EvalGoogleCalendarMeetingLinkData } from '@modules/EvaluationModule/interfaces/EvalGoogleCalendarMeetingLinkData';
import { RockyButton } from '@shared/components/RockyButton';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { subscribeToGoogleCalnedarEvalEvent } from '@modules/EvaluationModule/redux/googleCalendarEval/sagas';

interface Props {
  googleCalendarEvalEventsMeetingLinkData: EvalGoogleCalendarMeetingLinkData;
  googleCalendarEvent: GoogleCalendarEvent | null;
  isScheduleBtnShowen: boolean;
  isLoading?: boolean;
}

const ScheduleEvalMettingComponent: FC<Props> = ({
  googleCalendarEvalEventsMeetingLinkData,
  googleCalendarEvent,
  isScheduleBtnShowen,
  isLoading = false,
}) => {
  const styles = useStyles();

  useEffect(() => {
    if (googleCalendarEvent || isScheduleBtnShowen) {
      subscribeToGoogleCalnedarEvalEvent(googleCalendarEvalEventsMeetingLinkData.evaluationId);
    }
  }, [googleCalendarEvalEventsMeetingLinkData.evaluationId]);

  return (
    <RockySkeleton
      isLoading={isLoading}
      className={styles.skeleton}
      element={
        googleCalendarEvent ? (
          <Popup
            trigger={(open) => (
              <div>
                <RockyButton small inverted className={styles.meetingInfoButton}>
                  <div className={styles.icon}>
                    <div className={classNames(styles.iconText, styles.iconTextSmall)}>
                      {googleCalendarEvent?.datetime && format(parseISO(`${googleCalendarEvent.datetime}Z`), 'd ')}
                    </div>
                    <Calendar />
                  </div>
                  {googleCalendarEvent?.datetime &&
                    format(parseISO(`${googleCalendarEvent.datetime}Z`), 'MMM d, h:mm aaa')}
                  {!open ? <ArrowDown className={styles.moreArrow} /> : <ArrowDown className={styles.rotateArrow} />}
                </RockyButton>
              </div>
            )}
            on={['click']}
            position="bottom right"
            className={styles.popup}
          >
            {googleCalendarEvent.attendees.map((user: UserInfo) => (
              <Link
                key={user.id}
                to={`${paths.users}/${user.id}/${paths.evaluations}`}
                className={styles.userInMetting}
              >
                <UserReporterBlock user={user} isDetailInfo />
              </Link>
            ))}
          </Popup>
        ) : (
          isScheduleBtnShowen && (
            <RockyButton small inverted>
              <a
                target="_blank"
                href={getGoogleCalendarEvalEventsMeetingLink(googleCalendarEvalEventsMeetingLinkData)}
                className={styles.scheduleLink}
              >
                <div className={styles.icon}>
                  <div className={styles.iconText}>+</div>
                  <Calendar />
                </div>
                Schedule
              </a>
            </RockyButton>
          )
        )
      }
    />
  );
};

export const ScheduleEvalMetting = memo(ScheduleEvalMettingComponent);
