import { StylesConfig } from 'react-select';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

// custom styles for Select component
export const customStyles: StylesConfig = {
  control: (provided) => ({
    ...provided,
    background: COLORS.BRAND.HIGHLIGHT,
    border: 'none',
    cursor: 'pointer',
    boxShadow: 'none',
    paddingLeft: '6px',
    transitionDuration: '0.1s',
    transitionProperty: 'all',
    '&:hover': {
      background: COLORS.BRAND.HIGHLIGHT,
    },
    [BREAKPOINTS.MOBILE]: {
      background: COLORS.BRAND.HIGHLIGHT,
    },
  }),
  indicatorSeparator: (styles) => ({
    ...styles,
    display: 'none',
  }),
  indicatorsContainer: (styles) => ({
    ...styles,
    '& div': {
      padding: '8px 8px 8px 0',
      margin: '0 0 0 -4px',
      color: COLORS.TEXT.SECONDARY,
    },
    '& div svg': {
      width: 16,
      height: 16,
    },
  }),
  menu: (styles) => ({
    ...styles,
    zIndex: 3,
  }),
};
