import React, { ComponentProps, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import format from 'date-fns/format';
import { useModalState } from '@shared/hooks/useModalState';
import { userDataSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { selectRolesSenioritiesMap, selectUserPromotions } from '../../redux/selectors';
import { ManagePromotionModal } from '../ManagePromotionModal/ManagePromotionModal';
import { getValidationSchema } from '../../helpers/validationSchema';
import { createPromotion, updateUserPromotion } from '../../redux/actions';
import startOfMonth from 'date-fns/startOfMonth';
import {
  UserPromotionFormDataRequired,
  UserPromotionsData,
} from '@modules/EvaluationModule/interfaces/UserPromotionsData';
import { Button, IconButton, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { selectExternalLinks } from '@modules/App/pages/ExternalLinksPage/redux/selectors';
import { isZohoPeopleUpdateDisabled } from '@configs/environment';
import { ZOHO_PEOPLE_UPDATE_DISABLED_INFO } from '@shared/consts/constants';

interface Props {
  evaluationHistoryId?: string;
  promotionToUpdate?: UserPromotionsData;
  btnSize?: ComponentProps<typeof Button>['size'];
  editIcon?: boolean;
}

const NO_SENIORITY = 'N/A';

export const ManagePromotionButton: React.FC<Props> = ({
  evaluationHistoryId,
  promotionToUpdate,
  btnSize = 'small',
  editIcon,
}) => {
  const dispath = useDispatch();
  const { data: userData } = useSelector(userDataSelector);
  const { data: userPromotions } = useSelector(selectUserPromotions);
  const { data: rolesSenioritiesMap } = useSelector(selectRolesSenioritiesMap);
  const { data: externalLinks } = useSelector(selectExternalLinks);
  const { isModalOpen, closeModal, openModal } = useModalState();
  const { id: userId } = useParams();
  const defaultEffectiveDate =
    userPromotions && userPromotions?.length > 0
      ? new Date(userPromotions[0].effectiveDate).toISOString()
      : startOfMonth(new Date()).toISOString();

  const currentUserStream = rolesSenioritiesMap?.find((stream) =>
    stream.designations.some((designation) => designation.name === userData?.position)
  );
  const currentUserRole = currentUserStream?.designations.find(
    (designation) => designation.name === userData?.position
  );
  const noSeniority = currentUserRole?.seniorities?.find((seniority) => seniority.name === NO_SENIORITY);
  const currentUserSeniority = userData?.seniority
    ? currentUserRole?.seniorities?.find((seniority) => seniority.name === userData?.seniority)
    : noSeniority;

  const initialValues: UserPromotionFormDataRequired | null = promotionToUpdate
    ? {
        stream: promotionToUpdate.stream,
        seniority: promotionToUpdate.seniority,
        role: promotionToUpdate.role,
        reason: promotionToUpdate.reason,
        effectiveDate: promotionToUpdate.effectiveDate,
        notes: promotionToUpdate.notes,
        pdpLink: promotionToUpdate.pdpLink ?? externalLinks?.pdpLink?.link ?? null,
        peerInterviewResultsLink: promotionToUpdate.peerInterviewResultsLink,
        evaluationHistoryId: evaluationHistoryId ?? null,
      }
    : userData && currentUserStream && currentUserRole
    ? {
        stream: currentUserStream,
        seniority: currentUserSeniority ?? null,
        role: currentUserRole,
        reason: '',
        effectiveDate: defaultEffectiveDate,
        notes: '',
        pdpLink: externalLinks?.pdpLink?.link ?? null,
        peerInterviewResultsLink: null,
        evaluationHistoryId: evaluationHistoryId ?? null,
      }
    : userData
    ? {
        stream: { id: '', name: '' },
        seniority: { id: '', name: userData?.seniority ?? '' },
        role: { id: '', name: '' },
        reason: '',
        effectiveDate: defaultEffectiveDate,
        notes: '',
        pdpLink: externalLinks?.pdpLink?.link ?? null,
        peerInterviewResultsLink: null,
        evaluationHistoryId: evaluationHistoryId ?? null,
      }
    : null;

  const handleFormSubmit = useCallback(
    (values: UserPromotionFormDataRequired) => {
      const sharedPayloadValues = {
        ...values,
        effectiveDate: format(new Date(values.effectiveDate) as Date, 'yyyy-MM-dd'),
      };

      dispath(
        promotionToUpdate
          ? updateUserPromotion({
              id: promotionToUpdate.id,
              payload: sharedPayloadValues,
            })
          : userId && createPromotion({ ...sharedPayloadValues, userId })
      );
    },
    [evaluationHistoryId, promotionToUpdate, userId]
  );

  return (
    <div>
      <Tooltip placement="top" title={isZohoPeopleUpdateDisabled ? ZOHO_PEOPLE_UPDATE_DISABLED_INFO : ''}>
        {editIcon ? (
          <IconButton
            onClick={openModal}
            disabled={isZohoPeopleUpdateDisabled}
            sx={{
              '&.Mui-disabled': {
                pointerEvents: 'auto',
              },
            }}
          >
            <EditIcon fontSize="small" color={isZohoPeopleUpdateDisabled ? 'disabled' : 'primary'} />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            size={btnSize}
            onClick={openModal}
            disabled={isZohoPeopleUpdateDisabled}
            sx={{
              '&.Mui-disabled': {
                pointerEvents: 'auto',
              },
            }}
          >
            Edit seniority or role
          </Button>
        )}
      </Tooltip>
      {rolesSenioritiesMap && initialValues && (
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={getValidationSchema(rolesSenioritiesMap)}
          onSubmit={handleFormSubmit}
          validateOnMount
        >
          <Form noValidate>
            <ManagePromotionModal
              rolesSenioritiesMap={rolesSenioritiesMap}
              isEditMode={!!promotionToUpdate}
              isOpen={isModalOpen}
              closeModal={closeModal}
              userData={userData}
            />
          </Form>
        </Formik>
      )}
    </div>
  );
};
