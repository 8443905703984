import { SortDirection } from '@shared/enums/sortDirection';

export const PEOPLE_HAPPINESS_TABLE_FETCH_ROWS_LIMIT = 100;
export const PEOPLE_HAPPINESS_TABLE_OFFSET_STEP = 100;
export const PEOPLE_HAPPINESS_FILTER_DELAY = 500;
export const REACTION_MAX_VALUE = 10;
export const REACTION_MIN_VALUE = 1;
export const DEFAULT_SORTING = {
  field: 'reaction',
  sortDirection: SortDirection.Asc,
};
export const NUMBER_OF_CHILDREN_TO_EXPAND = 2;
