import React, { useCallback, useRef, useState } from 'react';
import { useStyles } from './PeerEvalForm.styles';
import { ReactComponent as LockCircle } from '@assets/icons/LockCircle.svg';
import { RockyButton } from '@shared/components/RockyButton';
import { RockyModal } from '@shared/components/RockyModal';
import { ReactComponent as Heart } from '@assets/icons/CaringHeart.svg';
import ReviewReject from '@mui/icons-material/SpeakerNotesOff';
import { useModalState } from '@shared/hooks/useModalState';
import { PeerFormData } from '@modules/EvaluationModule/interfaces/PeerEvalData';
import { FormExplanationLabel } from '@modules/EvaluationModule/pages/EvalFormAndResultsPage/components/EvaluationForm/components/FormExplanationLabel';
import { QuestionRadioInput } from '@shared/components/QuestionRadioInput/QuestionRadioInput';
import { useDispatch } from 'react-redux';
import { savePeeEvalData } from '../../redux/actions';
import { Formik, Form, FormikProps, Field, FormikErrors, FieldProps } from 'formik';
import { questionBlockFieldsDescriptions } from '@modules/EvaluationModule/pages/EvalPage/constants/questionBlockFieldsDescriptions';
import { useParams } from 'react-router-dom';
import { peerMarkValues } from '../../constants/peerMarkValues';
import isEmpty from 'lodash/isEmpty';
import { validationSchema } from './constants/validationSchema';
import get from 'lodash/get';
import { PeerFormObserver } from './components/PeerFormObserver/PeerFormObserver';
import { FormControlLabel, Stack, Switch, Typography } from '@mui/material';
import { PeerFormValues } from '../../interfaces/PeerFormValues';
import { HelperMsgForAnonymousFeedback } from '../HelperMsgForAnonymousFeedback';

interface Props {
  data: PeerFormData;
  isLoading: boolean;
  toggleIsAnonymous: () => void;
}

// TODO: extract field names to object like we did it in FlagForm namely FlagFormFields

export const PeerEvalForm: React.FC<Props> = ({ data, isLoading, toggleIsAnonymous }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { evalId = '', feedbackId = '' } = useParams();
  const {
    openModal: openSuccessModal,
    closeModal: closeSuccessModal,
    isModalOpen: isSuccessModalOpen,
  } = useModalState();
  const {
    openModal: openAttentionModal,
    closeModal: closeAttentionModal,
    isModalOpen: isAttentionModalOpen,
  } = useModalState();
  const [isSubmitAttempted, setIsSubmitAttempted] = useState(false);
  const formRef = useRef<FormikProps<PeerFormValues>>(null);
  const { evaluation, evaluatee, isAnonymous } = data;

  const closeAllModal = () => {
    closeSuccessModal();
    closeAttentionModal();
  };

  const handleClickSubmitFeedback = () => {
    if (!formRef.current) {
      return;
    }
    closeAttentionModal();
    formRef.current.handleSubmit();
    openSuccessModal();
  };

  const handleSubmitFeedback = useCallback(
    (values) => {
      const { isAnonymous, ...evaluation } = values;

      dispatch(
        savePeeEvalData({
          evalId,
          feedbackId,
          evaluation: { ...evaluation, isConfirmed: true },
          isAnonymous,
        })
      );
    },
    [evalId, feedbackId]
  );

  const openModalIfValid = (validateForm: (values?: PeerFormValues) => Promise<FormikErrors<PeerFormValues>>) => {
    setIsSubmitAttempted(true);
    validateForm().then((errors) => {
      if (isEmpty(errors)) {
        openAttentionModal();
      } else {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }
    });
  };

  return (
    <div>
      {data.reviwerNotes && (
        <div className={styles.reviewContainer}>
          <div className={styles.reviewIconContainer}>
            <ReviewReject />
          </div>
          <div className={styles.reviewLabel}>
            Your feedback was rejected by {evaluatee.primaryReportsTo.name} with a note:
          </div>
          <div className={styles.reviewNotes}>{data.reviwerNotes}</div>
        </div>
      )}
      <div className={styles.mainBlock}>
        {evaluation && (
          <Formik
            enableReinitialize
            initialValues={{ ...evaluation, isAnonymous }}
            validationSchema={validationSchema}
            innerRef={formRef}
            onSubmit={handleSubmitFeedback}
          >
            {({ validateForm, errors, values, setFieldValue }: FormikProps<PeerFormValues>) => (
              <Form>
                <PeerFormObserver evalId={evalId} feedbackId={feedbackId} />
                {data.reviwerNotes && <h2 className={styles.updateTitle}>Update your feedback</h2>}
                <div className={styles.mainContent}>
                  <div className={styles.questionsContainer}>
                    <Stack className={styles.anonymusBlock}>
                      <Field
                        name="isAnonymous"
                        type="checkbox"
                        component={({ field }: FieldProps) => (
                          <FormControlLabel
                            control={
                              <Switch
                                {...field}
                                checked={field.value}
                                onChange={() => {
                                  setFieldValue('isAnonymous', !field.value);
                                  toggleIsAnonymous();
                                }}
                              />
                            }
                            label={<Typography variant="body1">Anonymous feedback</Typography>}
                          />
                        )}
                      />
                      <HelperMsgForAnonymousFeedback isAnonymous={values.isAnonymous} peerReviewData={data} />
                    </Stack>
                    {questionBlockFieldsDescriptions.map((fieldProps, i) => {
                      return (
                        <QuestionRadioInput
                          isWithComments
                          markValues={peerMarkValues}
                          {...fieldProps}
                          error={isSubmitAttempted ? get(errors, fieldProps.questionMark) : undefined}
                          key={i}
                          markDetail={get(data.evaluation, fieldProps.questionName)}
                          disabled={isLoading}
                        />
                      );
                    })}
                  </div>
                  <div>
                    <p className={styles.fieldsTitle}>What {evaluatee.name} should focus on improving</p>
                    <p className={styles.fieldsDescription}>Especially if you put "Needs Development" above</p>
                    <Field
                      component="textarea"
                      name="professionalGoals"
                      disabled={isLoading}
                      className={styles.fieldsText}
                    />
                    <p className={styles.fieldsTitle}>
                      What is the most valuable contribution {evaluatee.name} made to you and your team
                    </p>
                    <Field
                      component="textarea"
                      name="achievements"
                      disabled={isLoading}
                      className={styles.fieldsText}
                    />
                    <p className={styles.fieldsTitle}>If you have any additional comments please share below</p>
                    <Field component="textarea" name="notes" disabled={isLoading} className={styles.fieldsText} />
                  </div>
                  <div className={styles.btnContainer}>
                    <RockyButton action onClick={() => openModalIfValid(validateForm)} className={styles.submitBtn}>
                      Submit feedback
                    </RockyButton>
                    <LockCircle />
                    <div className={styles.helpInfoText}>
                      Your responses are saved as a draft visible for you only until you submit it.
                    </div>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
        <FormExplanationLabel />
      </div>
      <RockyModal
        title={''}
        isOpen={isSuccessModalOpen}
        onClose={closeAllModal}
        crossIcon
        modalHeaderStyles={styles.modalHeaderStyles}
        modalBodyStyles={styles.modalBodyStyles}
      >
        <Heart />
        <h2 className={styles.modalTitle}>Thank you for your feedback!</h2>
        <p className={styles.modalText}>{data.evaluator.name} may get back to you to clarify details.</p>
        <RockyButton action onClick={closeAllModal} className={styles.submitBtn}>
          Dismiss
        </RockyButton>
      </RockyModal>
      <RockyModal
        title="Submitting feedback"
        isOpen={isAttentionModalOpen}
        onClose={closeAttentionModal}
        crossIcon={false}
        modalTitleStyles={styles.attentionModalTitle}
        modalBodyStyles={styles.attentionModalBodyStyles}
      >
        <div className={styles.attentionModalAlert}>After submitting you will not be able to edit your feedback.</div>
        <div className={styles.attentionModalText}>
          <Stack flexDirection="column" gap="16px">
            <Typography variant="body1">Make sure you've written down everything you wanted to say.</Typography>
            <HelperMsgForAnonymousFeedback isAnonymous={!!formRef.current?.values.isAnonymous} peerReviewData={data} />
          </Stack>
        </div>
        <div className={styles.modalBtnContainer}>
          <RockyButton onClick={() => closeAttentionModal()} className={styles.canselBtn}>
            Cancel
          </RockyButton>
          <RockyButton onClick={handleClickSubmitFeedback} action className={styles.submitBtn}>
            Yes, submit feedback
          </RockyButton>
        </div>
      </RockyModal>
    </div>
  );
};
