import React, { FC } from 'react';
import { Dialog, DialogContent, DialogTitle, Divider, IconButton, Stack, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from '@styles/colors';
import { ResolvedByData } from '@modules/HappinessModule/api/helpers/resolvedByTypeToUserInfo';
import { getHistoryChangeDate } from '@shared/helpers/getHistoryChangeDate';

interface Props {
  onClose: () => void;
  resolvedBy: ResolvedByData;
}

export const ResolveModalReadonly: FC<Props> = ({ onClose, resolvedBy }) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '524px',
          },
        },
      }}
      open={true}
      onClose={onClose}
    >
      <DialogTitle marginTop="18px">Issue was resolved 👌</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 28,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider
        sx={{
          marginTop: '16px',
        }}
      />

      <DialogContent
        sx={{
          paddingTop: '27px',
        }}
      >
        <Stack
          sx={{
            padding: '16px 24px 24px 24px',
            background: COLORS.HIGHLIGHTS.NEUTRAL,
            borderRadius: 4,
          }}
        >
          <Typography variant="body2">
            {resolvedBy.user.name}
            {resolvedBy.resolvedAt && (
              <Typography variant="caption" component="span">
                {' '}
                • {getHistoryChangeDate(resolvedBy.resolvedAt)}
              </Typography>
            )}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              whiteSpace: 'pre-wrap',
              overflowWrap: 'anywhere',
            }}
          >
            {resolvedBy.notes}
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
