import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DataState } from '@shared/enums/DataState';
import { SLICE_NAME } from './constants';
import { State } from './types';

const initialState: State = {
  searchData: { data: [], state: DataState.Fulfilled },
  isGlobalSearchOverlayOpen: false,
  legalCountryOfUser: { data: null, state: DataState.Idle },
  toms: { data: null, state: DataState.Idle },
  prs: { data: null, state: DataState.Idle },
  usersWhomICanCreateFlag: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    setSearchDataState(state, { payload }) {
      state.searchData.state = payload;
    },
    setSearchResult(state, { payload }) {
      // TODO: implement server-side pagination
      state.searchData = {
        data: payload.splice(0, state.isGlobalSearchOverlayOpen ? 6 : 10),
        state: DataState.Fulfilled,
      };
    },
    resetSearchResult(state) {
      state.searchData = { data: [], state: DataState.Fulfilled };
    },
    setIsGlobalSearchOverlayOpen(state, { payload }) {
      state.isGlobalSearchOverlayOpen = payload;
    },
    setLegalCountryOfUser(state, action: PayloadAction<State['legalCountryOfUser']>) {
      state.legalCountryOfUser = action.payload;
    },
    setToms(state, action: PayloadAction<State['toms']>) {
      state.toms = action.payload;
    },
    setPrs(state, action: PayloadAction<State['prs']>) {
      state.prs = action.payload;
    },
    setUsersWhomICanCreateFlag(state, action: PayloadAction<State['usersWhomICanCreateFlag']>) {
      state.usersWhomICanCreateFlag = action.payload;
    },
  },
});

export const {
  setSearchDataState,
  setSearchResult,
  resetSearchResult,
  setIsGlobalSearchOverlayOpen,
  setLegalCountryOfUser,
  setToms,
  setPrs,
  setUsersWhomICanCreateFlag,
} = slice.actions;

export default slice.reducer;
