import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  popup: {
    '&-content': {
      maxWidth: 'fit-content',
      minWidth: 'fit-content',
      padding: '4px 10px',
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    },
  },
  capitalize: {
    textTransform: 'capitalize',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  approveBtn: {
    color: COLORS.STATUS.SUCCESS,
  },
  rejectBtn: {
    color: COLORS.STATUS.CRITICAL,
  },
  reviewBtn: {
    color: COLORS.STATUS.ACTIONABLE,
  },
  pendingBtn: {
    color: COLORS.TEXT.DISABLED,
  },
});
