import React, { FC } from 'react';

import { RockyButton } from '@shared/components/RockyButton';
import classNames from 'classnames';
import { RockyModal } from '@shared/components/RockyModal';
import { useStyles } from './ClientSurveyConfirmModal.styles';

interface Props {
  isModalOpen: boolean;
  closeModal: () => void;
  confirmScheduleSurvey: () => void;
  pendingConfirmation: boolean;
  date: string;
}
export const ClientSurveyConfirmModal: FC<Props> = ({
  closeModal,
  isModalOpen,
  date,
  pendingConfirmation,
  confirmScheduleSurvey,
}) => {
  const styles = useStyles();

  const onCloseModal = () => {
    closeModal();
  };
  const onConfirmClientSurvey = (): void => {
    confirmScheduleSurvey();
  };

  return (
    <RockyModal
      onClose={closeModal}
      isOpen={isModalOpen}
      title={'Scheduling CSAT survey'}
      modalStyles={styles.modal}
      modalHeaderStyles={styles.modalHeaderStyles}
      modalBodyStyles={styles.modalBody}
      crossIcon
    >
      <div className={styles.modalContent}>
        <p>
          {`Survey will be sent on ${date} at 10:00 AM client’s time.`}{' '}
          <a
            href="https://crm.zoho.com/crm/org31013314/tab/Reports/998335000320416005"
            target="_blank"
            className={styles.link}
          >
            Zoho list of clients
          </a>{' '}
          who will receive it.
        </p>
      </div>

      <div className={styles.modalBtnsContainer}>
        <RockyButton className={styles.button} onClick={onCloseModal} disabled={pendingConfirmation}>
          Cancel
        </RockyButton>
        <RockyButton
          action
          className={classNames(styles.button, styles.actionBtn)}
          onClick={onConfirmClientSurvey}
          isLoading={pendingConfirmation}
        >
          Yes, schedule it
        </RockyButton>
      </div>
    </RockyModal>
  );
};
