import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  rateHolder: {
    maxWidth: 600,
    [BREAKPOINTS.MOBILE]: {
      overflow: 'hidden',
      width: 42,
      paddingLeft: 40,
      position: 'absolute',
      top: -8,
      right: 2,
    },
  },
  team: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
    margin: '2px 0 0',
  },
  personBlock: {
    cursor: 'pointer',
    display: 'flex',
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,
    margin: '0 12px',
    padding: '12px 0 12px 12px',
    gap: 16,
    borderRadius: 8,
    transition: 'all 0.2s',

    [BREAKPOINTS.MOBILE]: {
      padding: '0 45px 0 0',
      margin: 0,
    },

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
    },
  },

  tableDataRow: {
    height: 76,

    '& td:nth-child(3)': {
      width: 'auto',
      maxWidth: 200,
      paddingLeft: 20,
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      backgroundColor: COLORS.WHITE,
      borderRadius: 8,
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
      padding: 16,
      width: '98%',
      marginBottom: 4,
      position: 'relative',

      '& td:nth-child(3)': {
        maxWidth: 'auto',
        padding: '8px 0 0',
      },
    },
  },
});
