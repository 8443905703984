import { PaletteOptions } from '@mui/material/styles/createPalette';
import { grey, brown, amber, red, lightGreen, blue } from '@mui/material/colors';

export default <PaletteOptions>{
  background: {
    default: '#F6F8FC', //grey[50],
  },
  primary: {
    main: blue['A200'],
  },
  action: {
    hover: blue[50],
  },
  highlight: {
    main: amber[100],
    dark: amber[500],
    hover: amber[200],
    error: red[50],
    success: lightGreen[50],
    neutral: grey[50],
  },
  warning: {
    main: brown[700],
    light: brown[400],
    dark: brown[800],
  },
  brand: {
    primary: '#FF7A00',
    secondary: 'black',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.87)',
    secondary: 'rgba(0, 0, 0, 0.6)',
    disabled: 'rgba(0, 0, 0, 0.4)',
    disabledbg: 'rgba(0, 0, 0, 0.05)',
    regular: '#081735',
  },
};
