import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modalTitleStyles: {
    fontWeight: '500',
    lineHeight: '120%',
    color: COLORS.TEXT.PRIMARY,
  },
  modalBodyStyles: {
    width: 504,
    padding: '0 0 36px',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
    },
  },
  modalText: {
    color: COLORS.TEXT.PRIMARY,
    fontSize: '14px',
    lineHeight: 1.25,
    padding: '0 120px 0 50px',
    margin: '0 0 50px',
  },
  modalWarningText: {
    color: COLORS.WHITE,
    background: COLORS.STATUS.WARNING,
    padding: '24px 0 24px 50px',
    marginBottom: 20,
  },
  modalBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 10,
    marginRight: 42,
  },
  button: {
    width: 210,
    fontSize: '14px',
    padding: '14px 28px',
  },
});
