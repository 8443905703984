import { ValueLabelObject } from '../interfaces/ValueLabelObject';

export const valuesFromLabelsArr = (newValue: string[], obj: ValueLabelObject): string[] =>
  Object.values(obj).reduce((acc, el) => {
    newValue.map((value) => value === el.label && acc.push(el.value));
    return acc.filter((value) => value !== obj.all.value);
  }, [] as string[]);

export const valuesFromLabels = (newValue: string, obj: ValueLabelObject): string => {
  return Object.values(obj).reduce((acc, el) => {
    if (newValue === el.label) acc = el.value;
    return acc;
  }, '' as string);
};
