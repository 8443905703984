import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  goalActions: {
    display: 'flex',
    alignItems: 'end',
    justifyContent: 'end',
    gap: 8,
    [BREAKPOINTS.MOBILE]: {
      '& button': {
        width: '100%',
      },
    },
  },
  goalDue: {
    width: '170px',
    marginRight: 'auto',
  },
  hideHintBtn: {
    cursor: 'pointer',
  },
  fieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
  },
  customFieldError: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    maxWidth: 406,
    padding: '0 14px',
  },
  networkError: {
    textAlign: 'right',
    color: COLORS.STATUS.WARNING,
  },
});
