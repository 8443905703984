import { NO_MARK_THRESHOLD } from '@modules/HappinessModule/constants/reportConstants';
import { getGoogleCalendarMeetingLink } from '@modules/HappinessModule/helpers/googleCalendar';
import { UsersHappinessTableDataItem } from '@modules/HappinessModule/interfaces/usersHappinessTableDataItem';
import { ChatAuthor } from '@modules/HappinessModule/pages/ChatPage/enums/ChatAuthor';
import { ChatCommands } from '@modules/HappinessModule/pages/ChatPage/enums/ChatCommands';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { useAuth } from '@shared/hooks/useAuth';
import { format, parseISO } from 'date-fns';
import React, { FC, useCallback } from 'react';
import ChatRoundedIcon from '@mui/icons-material/ChatRounded';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import VideoCameraFrontOutlinedIcon from '@mui/icons-material/VideoCameraFrontOutlined';
import HowToRegOutlinedIcon from '@mui/icons-material/HowToRegOutlined';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';
import Popup from 'reactjs-popup';
import { getNoResponseEmailLink, getLowMarkEmailLink } from '../UsersHappinessTable/helpers';
import { useStyles } from './UserHappinessTableActions.styles';
import { IconButton, Tooltip } from '@mui/material';
import { ResolveModal } from '../ResolveModal/ResolveModal';
import { useModalState } from '@shared/hooks/useModalState';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ReactComponent as RedFlagIcon } from '@assets/icons/redFlagIcon.svg';
import { ReactComponent as YellowFlagIcon } from '@assets/icons/yellowFlagIcon.svg';
import { FlagSeverity } from '@modules/HappinessModule/enums/FlagSeverity';
import { selectedQuestionSelector } from '@modules/HappinessModule/redux/questions/selectors';
import { Question } from '@modules/HappinessModule/interfaces/question';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import classNames from 'classnames';
import { ResolveModalReadonly } from '../ResolveModalReadonly';

export type UserHappinessTableDataItemType = Pick<
  UsersHappinessTableDataItem,
  'name' | 'mark' | 'email' | 'userId' | 'comments' | 'resolvedBy' | 'flag' | 'id'
>;

export type QuestionDataType = Pick<Question, 'date' | 'questionText' | 'id'> | null | undefined;

interface Props {
  userHappinessTableDataItem: UserHappinessTableDataItemType;
  isResolveAvailable: boolean;
  hideActions?: {
    schedule?: boolean;
    email?: boolean;
    resolve?: boolean;
    comments?: boolean;
  };
  currentQuestion?: QuestionDataType;
}

export const UserHappinessTableActions: FC<Props> = ({
  userHappinessTableDataItem,
  isResolveAvailable,
  hideActions,
  currentQuestion,
}) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { name, mark, email, userId, comments, resolvedBy, flag, id } = userHappinessTableDataItem;
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const question = currentQuestion ?? useSelector(selectedQuestionSelector);
  const { openModal, closeModal, isModalOpen } = useModalState();
  const {
    openModal: openResolveReadonlyModal,
    closeModal: closeResolveReadonlyModal,
    isModalOpen: isResolveReadonlyModalOpen,
  } = useModalState();

  const calendarLink = getGoogleCalendarMeetingLink({
    inviterName: currentUser?.name || '',
    inviteeEmail: email,
    inviteeName: name,
  });

  const isCurrentUserEqualToUserFromTable = currentUser?.id === userId;

  const filteredComments = comments?.filter(
    ({ author, text }) => !author || (author.toLocaleLowerCase() !== ChatAuthor.Rocky && text !== ChatCommands.Revote)
  );

  const isResolvedWithNotes = resolvedBy?.notes;

  const handleOpeningResolveReadonlyModal = useCallback(() => {
    if (!isResolvedWithNotes) {
      return;
    }
    openResolveReadonlyModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isResolvedWithNotes]);

  return isCurrentUserEqualToUserFromTable ? null : (
    <div className={styles.root}>
      {mark === NO_MARK_THRESHOLD && (
        <Popup
          trigger={
            <a
              className={styles.scheduleButton}
              href={getNoResponseEmailLink({
                name: name.split(' ')[0],
                email,
              })}
              target="_blank"
            >
              <ForwardToInboxOutlinedIcon />
            </a>
          }
          on={['hover', 'focus']}
          position="top center"
          className={styles.resolvedPersonePopup}
        >
          <span>Contact via email</span>
        </Popup>
      )}
      {!hideActions?.email && mark <= 6 && (
        <a
          className={styles.scheduleButton}
          href={getLowMarkEmailLink({
            name: name.split(' ')[0],
            email,
            mark,
          })}
          target="_blank"
        >
          <ForwardToInboxOutlinedIcon />
        </a>
      )}
      {!hideActions?.schedule && (mark === NO_MARK_THRESHOLD || mark <= 6) && (
        <Popup
          trigger={
            <a className={styles.scheduleButton} href={calendarLink} target="_blank">
              <VideoCameraFrontOutlinedIcon />
            </a>
          }
          on={['hover', 'focus']}
          position="top center"
          className={styles.resolvedPersonePopup}
        >
          <span>Schedule 1 to 1</span>
        </Popup>
      )}
      {!hideActions?.resolve && mark <= 6 && (
        <>
          {flag ? (
            <div>
              <Tooltip title="Low-rate issue becomes resolved, now all is happening in the flag.">
                <IconButton
                  className={styles.scheduleButton}
                  onClick={() => {
                    navigate(`/flags/${flag.id}`);
                  }}
                >
                  {flag.severity === FlagSeverity.red ? <RedFlagIcon /> : <YellowFlagIcon />}
                </IconButton>
              </Tooltip>
            </div>
          ) : resolvedBy !== null ? (
            <Popup
              trigger={
                <button
                  className={classNames(styles.openPopupBtn, {
                    [styles.resolvedPersonePopupWithNotes]: isResolvedWithNotes,
                  })}
                  onClick={handleOpeningResolveReadonlyModal}
                >
                  <RockyAvatar small imageUrl={resolvedBy.user.imageUrl} fullName={resolvedBy.user.name || ''} />
                  {isResolvedWithNotes ? (
                    <PlaylistAddCheckIcon className={styles.checkIcon} />
                  ) : (
                    <CheckBoxRoundedIcon className={styles.checkIcon} />
                  )}
                </button>
              }
              position="top center"
              on={['hover', 'focus']}
              className={styles.resolvedPersonePopup}
            >
              <span>Resolved by {resolvedBy.user.name || ''}</span>
            </Popup>
          ) : (
            <>
              <Tooltip title="Raise flag" placement="top">
                <IconButton
                  className={styles.scheduleButton}
                  onClick={() => {
                    navigate(
                      `/flags/create/${userId}?mark=${mark}&date=${question?.date}&question=${question?.questionText}&id=${id}`
                    );
                  }}
                >
                  <FlagOutlinedIcon />
                </IconButton>
              </Tooltip>
              {isResolveAvailable && (
                <Tooltip title="Resolve" placement="top">
                  <IconButton className={styles.scheduleButton} onClick={openModal}>
                    <HowToRegOutlinedIcon />
                  </IconButton>
                </Tooltip>
              )}
            </>
          )}
        </>
      )}
      {!hideActions?.comments && filteredComments && filteredComments.length > 0 && (
        <Popup
          trigger={
            <IconButton className={styles.scheduleButton}>
              <ChatRoundedIcon />
            </IconButton>
          }
          position="top center"
          closeOnDocumentClick
          className={styles.comemntsPopup}
        >
          {filteredComments?.map((comment) => (
            <span key={comment.ts}>
              {format(parseISO(`${comment.ts}Z`), 'dd MMM yyyy hh:mm aaa')}: {comment.text}
            </span>
          ))}
        </Popup>
      )}
      {isResolveAvailable && (
        <ResolveModal
          openModal={openModal}
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          data={userHappinessTableDataItem}
          question={question}
        />
      )}
      {isResolveReadonlyModalOpen && userHappinessTableDataItem.resolvedBy && (
        <ResolveModalReadonly resolvedBy={userHappinessTableDataItem.resolvedBy} onClose={closeResolveReadonlyModal} />
      )}
    </div>
  );
};
