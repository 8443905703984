import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '@modules/App/redux/store';
import { User } from '@shared/interfaces/user';

export const useIsBrowsingOwnData = (): boolean => {
  //TODO: change to <userId> throughout the app in all params related to users/<id>
  const { id } = useParams();
  const currentUser = useSelector<RootState, User | null>((state) => state.user.currentUser.data);

  return id === currentUser?.id;
};
