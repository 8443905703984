import React, { useState, useEffect } from 'react';
import { useStyles } from './ClientContactsPage.styles';
import { useClientContactData } from './hooks/useClientContactData';
import { SubProjectTable } from './components/SubProjectTable/SubProjectTable';
import { ClientContactData } from '@modules/EvaluationModule/interfaces/ClientContactData';
import classNames from 'classnames';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { RocketLoader } from '@shared/components/RocketLoader';

export const ClientContactsPage: React.FC = () => {
  const styles = useStyles();
  const clientContactData = useClientContactData();
  const [choosenProject, setChoosenProject] = useState<ClientContactData | null>(null);

  useEffect(() => {
    if (clientContactData.data && clientContactData.data.length > 0) {
      setChoosenProject(clientContactData.data[0]);
    }
  }, [clientContactData]);

  const isRejected = clientContactData.state === DataState.Rejected;
  const isLoading = clientContactData.state === DataState.Pending;

  const handleClickOnTeamRow = (project: ClientContactData) => {
    setChoosenProject(project);
  };

  return isLoading ? (
    <div className={styles.loaderContainer}>
      <RocketLoader />
    </div>
  ) : isRejected ? (
    <DataErrorView />
  ) : (
    <div className={styles.root}>
      <div className={styles.projectListContainer}>
        <div className={styles.projectList}>
          {clientContactData.data?.map((project, i) => (
            <div
              key={i}
              className={classNames(styles.projectItem, {
                [styles.chossenProjectItem]: project.name === choosenProject?.name,
              })}
              onClick={() => handleClickOnTeamRow(project)}
            >
              {project.name}
            </div>
          ))}
        </div>
      </div>
      {choosenProject && <SubProjectTable choosenProject={choosenProject} />}
    </div>
  );
};
