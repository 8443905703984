import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    animation: '$fadeIn 0.3s',
  },
  messageBody: {
    position: 'relative',
    padding: '20px 10px 20px 20px',
    boxSizing: 'border-box',
    background: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    borderRadius: 8,
    maxWidth: 484,

    [BREAKPOINTS.MOBILE]: {
      maxWidth: 358,
    },
  },
  '@keyframes fadeIn': {
    '0%': {
      opacity: 0,
      transform: 'translate(60px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translate(0)',
    },
  },

  rockyMessageTail: {
    position: 'absolute',
    top: 20,
    left: -15,
    width: 29,
    height: 29,
    fill: COLORS.WHITE,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  question: {
    margin: '0 0 10px',
    fontWeight: '500',
    fontSize: 24,
    lineHeight: '130%',
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      fontWeight: '500',
      fontSize: '14px',
    },
  },
  buttonContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 4,
    marginBottom: 10,
  },
  button: {
    display: 'block',
    textDecoration: 'none',
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: 1,
    minWidth: 40,
    width: 40,
    height: 40,
    border: 'none',
    borderRadius: '30%',
    textAlign: 'center',
    boxSizing: 'border-box',
    padding: '8.5px 0',
    background: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    transition: 'all .5s',

    '&:hover': {
      transform: 'scale(1.05)',
      color: COLORS.WHITE,
    },

    '&:disabled': {
      background: COLORS.TEXT.DISABLEDBG,
      color: COLORS.TEXT.DISABLED,
      textShadow: 'none',
      fontSize: 14,

      '&:hover': {
        transform: 'scale(1)',
      },
    },

    [BREAKPOINTS.MOBILE]: {
      fontWeight: 500,
      fontSize: 14,
      width: 29,
      minWidth: 29,
      height: 29,
      padding: '4px 0',
      borderRadius: 4,
    },
  },
  activeButton: {
    color: `${COLORS.WHITE} !important`,
  },
  selectedMark: {
    pointerEvents: 'none',
  },
  responseMarks: {
    display: 'flex',
    justifyContent: 'space-between',
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '130%',
    color: COLORS.TEXT.SECONDARY,
  },
  responseText: {
    margin: 0,
  },
  date: {
    position: 'absolute',
    right: 5,
    bottom: 1,
    fontSize: '10px',
    color: COLORS.TEXT.DISABLED,
  },
  avatar: {
    width: 40,
    height: 40,
    background: 'linear-gradient(333.01deg, #FF754C -23.54%, #ECB801 173.81%)',
    borderRadius: 12,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 20px 0 0',

    '& svg': {
      fill: COLORS.WHITE,
      width: 24,
      height: 24,
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  closedNote: {},
  closedSurveyTextNote: {
    color: COLORS.TEXT.DISABLED,
    fontWeight: '400',
    fontSize: 13,
  },
});
