import { createWebsocketConnection } from '@modules/EvaluationModule/api/ws';
import { EvaluationSocketEvents } from '@modules/EvaluationModule/enums/EvaluationSocketEvents';
import { SocketGoogleCalendarEvent } from '@modules/EvaluationModule/interfaces/SocketGoogleCalendarEvent';
import { EventChannel, eventChannel } from 'redux-saga';
import { all, put, take } from 'redux-saga/effects';
import { Socket } from 'socket.io-client';
import { updateGoogleCalendarEvalAction } from './actions';

let socket: Socket;

function createSocketChannel(socket: Socket): EventChannel<SocketGoogleCalendarEvent> {
  return eventChannel((emit) => {
    const changeEvalDateInGoogleCalendar = (event: SocketGoogleCalendarEvent) => {
      emit(event);
    };

    socket.on(EvaluationSocketEvents.GoogleCalendarEvent, changeEvalDateInGoogleCalendar);

    const unsubscribe = () => {
      socket.off(EvaluationSocketEvents.GoogleCalendarEvent, changeEvalDateInGoogleCalendar);
    };

    return unsubscribe;
  });
}

export const subscribeToGoogleCalnedarEvalEvent = (evaluationId: string): void => {
  socket.emit(EvaluationSocketEvents.PushEvalId, { evaluationId });
};

export function* watchGoogleCalendarEvalEvents(): Generator {
  socket = createWebsocketConnection();
  const socketChannel: EventChannel<SocketGoogleCalendarEvent> = createSocketChannel(socket);

  while (true) {
    const payload = (yield take(socketChannel)) as SocketGoogleCalendarEvent;
    yield put(updateGoogleCalendarEvalAction(payload));
  }
}

export function* googleCalendarEvalSaga(): Generator {
  yield all([watchGoogleCalendarEvalEvents()]);
}
