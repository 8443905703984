import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  tableDataRow: {
    height: 64,
    cursor: 'pointer',

    '& td:nth-child(1)': {
      paddingLeft: 16,
    },

    '& td:nth-child(3)': {
      width: 'auto',
      maxWidth: 200,
      paddingLeft: 50,
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      backgroundColor: COLORS.WHITE,
      borderRadius: 8,
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
      padding: 16,
      width: '98%',
      marginBottom: 4,
      position: 'relative',

      '& td:nth-child(3)': {
        maxWidth: 'auto',
        padding: '8px 0 0',
      },
    },
  },
  rateHolder: {
    maxWidth: 600,
    [BREAKPOINTS.MOBILE]: {
      overflow: 'hidden',
      width: 42,
      paddingLeft: 40,
      position: 'absolute',
      top: -8,
      right: 2,
    },
  },
});
