import { FC } from 'react';
import { useStyles } from './CommunicationModal.styles';
import EmailSentMessage from './components/EmailSentMessage';
import { RockyModal } from '@shared/components/RockyModal';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { CommunicationModalComponents } from '../../../enums/CommunicationModalComponents';
import SendEmailLaterMessage from './components/SendEmailLaterMessage';
import SendEmail from './components/SendEmail';
import UserNotified from './components/UserNotified';
import ReminderMessage from './components/ReminderMessage';
import EmailWillBeSentWhenUserIsAssignedToTom from './components/EmailWillBeSentWhenUserIsAssignedToTom';
import { NewHomeEmailSentStatus } from '@modules/HappinessModule/enums/NewHomeEmailSentStatus';
import WaitingForNHDetailsModal from './components/WaitingForNHDetailsModal';

export interface Props {
  saveFlagWithSettedNhEmailStatus: (nhEmailSentStatus: NewHomeEmailSentStatus) => void;
  step: CommunicationModalComponents;
  setStep: (step: CommunicationModalComponents) => void;
  userInfo: UserInfo;
  userFlagPermissions: FlagPermissionsData;
  isOpen: boolean;
  onClose: () => void;
  isNHRequestedWithoutTom?: boolean;
  isNewFlag: boolean;
}

const CommunicationModal: FC<Props> = ({
  userInfo,
  saveFlagWithSettedNhEmailStatus,
  isNHRequestedWithoutTom,
  step,
  setStep,
  isNewFlag,
  ...props
}) => {
  const styles = useStyles();

  const ModalViews = {
    [CommunicationModalComponents.UserNotified]: (
      <UserNotified isNewFlag={isNewFlag} userInfo={userInfo} onClose={props.onClose} setStep={setStep} />
    ),
    [CommunicationModalComponents.SendEmail]: (
      <SendEmail
        saveFlagWithSettedNhEmailStatus={saveFlagWithSettedNhEmailStatus}
        userInfo={userInfo}
        userFlagPermissions={props.userFlagPermissions}
        onClose={props.onClose}
        setStep={setStep}
        isNewFlag={isNewFlag}
      />
    ),
    [CommunicationModalComponents.EmailSentMessage]: (
      <EmailSentMessage userInfo={userInfo} onClose={props.onClose} isNHRequestedWithoutTom={isNHRequestedWithoutTom} />
    ),
    [CommunicationModalComponents.ReminderMessage]: (
      <ReminderMessage
        userInfo={userInfo}
        onClose={props.onClose}
        isNewFlag={isNewFlag}
        saveFlagWithSettedNhEmailStatus={saveFlagWithSettedNhEmailStatus}
      />
    ),
    [CommunicationModalComponents.SendEmailLaterMessage]: (
      <SendEmailLaterMessage userInfo={userInfo} onClose={props.onClose} />
    ),
    [CommunicationModalComponents.EmailWillBeSentWhenUserIsAssignedToTom]: (
      <EmailWillBeSentWhenUserIsAssignedToTom userInfo={userInfo} onClose={props.onClose} />
    ),
    [CommunicationModalComponents.WaitingForNHDetails]: <WaitingForNHDetailsModal onClose={props.onClose} />,
  };

  return (
    <RockyModal
      title={
        step === CommunicationModalComponents.EmailWillBeSentWhenUserIsAssignedToTom
          ? 'Waiting for the NH details'
          : step === CommunicationModalComponents.WaitingForNHDetails
          ? 'Waiting for NH details'
          : 'Communicating NewHome process'
      }
      modalStyles={styles.modal}
      {...props}
      backdropClose={false}
    >
      {ModalViews[step]}
    </RockyModal>
  );
};

export default CommunicationModal;
