import classNames from 'classnames';
import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useStyles } from './EditableLabel.styles';

interface Props {
  value: string;
  className: string;
  onSubmit: (value: string) => void;
}

const EditableLabelComponent: FC<Props> = ({ value, className, onSubmit }) => {
  const styles = useStyles();
  const inputRef = useRef<HTMLInputElement>(null);
  const [intrinsicValue, setIntrinsicValue] = useState(value);
  const [isEditablle, setIsEditablle] = useState(false);
  useEffect(() => {
    const disableEdit = (event: MouseEvent) => {
      if (!inputRef.current || !inputRef.current.contains(event.target as Node)) {
        setIsEditablle(false);
        setIntrinsicValue(value);
      }
    };
    if (isEditablle) {
      document.addEventListener('mousedown', disableEdit);
    }
    return () => document.removeEventListener('mousedown', disableEdit);
  }, [setIsEditablle, isEditablle]);
  const handleInput = useCallback((e) => setIntrinsicValue(e.target.value), [setIntrinsicValue]);
  const handleLabelClick = useCallback(() => {
    setIsEditablle(true);
  }, [setIsEditablle]);

  const handleKeyPress = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        onSubmit(intrinsicValue);
        setIsEditablle(false);
      }
      if (e.key === 'Escape') {
        setIsEditablle(false);
        setIntrinsicValue(value);
      }
    },
    [setIsEditablle, intrinsicValue]
  );

  useEffect(() => {
    if (isEditablle && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditablle, setIntrinsicValue]);

  return isEditablle ? (
    <input
      ref={inputRef}
      onInput={handleInput}
      value={intrinsicValue}
      className={classNames(styles.input, className)}
      onKeyDown={handleKeyPress}
      maxLength={120}
    />
  ) : (
    <div onClick={handleLabelClick} className={classNames(className, styles.label)}>
      <span>{value}</span>
    </div>
  );
};

export const EditableLabel = memo(EditableLabelComponent);
