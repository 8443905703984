export enum RockyChatAnswers {
  Low = 'Oh well, looks like something is not right. We want to make you happier! What can we do?',
  Average = 'We want to make you happier! What can we do?',
  High = 'Wow ok! \n Please share your thoughts if you have any concerns.',
  SuccessfulRevote = 'Ok, please select your new response👌',
  ExpiredRevote = 'Sorry, you can revote only within 24h after the initial response.',
  AdditionalQuestion = 'Please tell me, what can we do to make you happy?',
  TwiceRevote = `"No man ever steps in the same river twice."
    \n\n
    ...and you cannot /revote while there is an active poll among the messages above. So vote there!
    \n\n
        If you don't see it, please, report an issue in the bottom left corner.`,
  AlreadyVoted = "You've already left your",
}
