import { FlagPermissionsData } from '../interfaces/FlagPermissionsData';

export const FLAG_PAGE_BLOCKS_PADDING = '50px 65px 50px 59px';
export const NOT_SETTED_VALUE_MSG = 'Not specified';

export const FLAG_WATCHERS_READ_UPDATE_PERMISSIONS: Partial<FlagPermissionsData> = {
  flag: {
    isCreatable: true,
    isUpdatable: true,
    isReadable: true,
  },
};

export const FLAG_WATCHERS_READ_PERMISSIONS: Partial<FlagPermissionsData> = {
  flag: {
    isCreatable: false,
    isUpdatable: false,
    isReadable: true,
  },
};

export const FLAG_WATCHERS_NO_PERMISSIONS: Partial<FlagPermissionsData> = {
  flag: {
    isCreatable: false,
    isUpdatable: false,
    isReadable: false,
  },
};
