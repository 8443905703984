import { MenuItem, Typography, Stack, SelectProps } from '@mui/material';
import { FC, useState } from 'react';
import { FlagReason } from '@modules/HappinessModule/enums/FlagsReasons';
import { ReasonSelectFormik } from '../../ReasonSelectFormik';
import { flagReasonsOptions } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/flagsReasonsObject';

interface Props extends SelectProps {
  label: string;
  name: string;
  reason: FlagReason | null;
}

export const SubReasonSelectFormik: FC<Props> = ({ label, name, reason, ...props }) => {
  const [hoverOption, setHoverOption] = useState<null | {
    label: string;
    description: string;
  }>(null);

  const options = [...flagReasonsOptions.involuntary.options, ...flagReasonsOptions.voluntary.options].find(
    (option) => option.value === reason
  )?.subReasons;

  const modifiedProps = { ...props };
  if (modifiedProps.value) {
    modifiedProps.value = modifiedProps.value ?? '';
  }

  return (
    <ReasonSelectFormik
      name={name}
      label={label}
      dropdownContent={
        hoverOption ? (
          <>
            <Typography variant="subtitle1">{hoverOption.label}</Typography>
            <Typography variant="body1">{hoverOption.description}</Typography>
          </>
        ) : (
          <Stack rowGap="20px">
            <div>
              <Typography variant="subtitle1" color="#9C27B0">
                Teammate initiated
              </Typography>
              <Typography variant="body1">
                AgileEngineer willingly raises a concern that jeopardizes the continuity of their collaboration with
                AgileEngine. Can be caused both by internal working conditions or external circumstances.
              </Typography>
            </div>
            <div>
              <Typography variant="subtitle1" color="#9C27B0">
                Manager or client initiated
              </Typography>
              <Typography variant="body1">
                Concern is raised by AgileEngine or one of our clients that jeopardizes the continuity of an
                AgileEngineer's collaboration.
              </Typography>
            </div>
          </Stack>
        )
      }
      {...modifiedProps}
    >
      {options?.map(({ value, description }) => (
        <MenuItem
          onMouseEnter={() =>
            setHoverOption({
              label: value,
              description,
            })
          }
          key={value}
          value={value}
        >
          {value}
        </MenuItem>
      ))}
    </ReasonSelectFormik>
  );
};
