import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: 504,
  },
  title: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  content: {
    padding: 24,
  },
  radioConteiner: {
    display: 'flex',
  },
  helperText: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '15.6px',
    color: COLORS.TEXT.SECONDARY,
    marginBottom: 40,
  },
  actionBtns: {
    display: 'flex',
    justifyContent: 'right',
    gap: 10,
  },
  image: {
    height: 87,
    width: 87,
    display: 'block',
    margin: '0 auto 15px auto',
  },
  doneBtn: {
    margin: '25px auto 0 auto',
    display: 'block',
  },
});
