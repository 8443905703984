import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { EvaluationMarkDetails } from '@modules/EvaluationModule/interfaces/EvaluationMarkDetails';
import { EvaluationOptionDetailsRaw } from '@modules/EvaluationModule/interfaces/EvaluationOptionDetailsRaw';
import { EvaluationOptionsData } from '@modules/EvaluationModule/interfaces/EvaluationOptionsData';
import { EvaluationOptionsDataRaw } from '@modules/EvaluationModule/interfaces/EvaluationOptionsDataRaw';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { EvalResponseData } from '../types';
import { evaluationRescheduleHistoryMapFunc } from './../../helpers/mapFunctions/evaluationRescheduleHistoryMapFunc';
import addMonths from 'date-fns/addMonths';
import parseISO from 'date-fns/parseISO';
import { EvaluationType } from '@modules/EvaluationModule/enums/EvaluationType';
import { mapUserFromBePythonToFeUser } from './mapUserFromBePythonToFeUser';
import { getIsoDateWithoutTz } from '@shared/helpers/date';

const mapToEvaluationMarkDetails = (evaluationOptionsDataRaw: EvaluationOptionDetailsRaw): EvaluationMarkDetails => ({
  mark: evaluationOptionsDataRaw.mark,
  notes: evaluationOptionsDataRaw.notes,
});

export const mapEvaluationOptionsData = (evaluation: EvaluationOptionsData): EvaluationOptionsDataRaw => {
  const {
    quantityOfWork,
    qualityOfWork,
    functionalExpertise,
    communication,
    teamworkAndAttitude,
    initiativeAndOwnership,
    projectLeadership,
    english,
    notes,
    isConfirmed,
    projectGoals,
    professionalGoals,
    achievements,
  } = evaluation;

  return {
    quantity_of_work: quantityOfWork,
    quality_of_work: qualityOfWork,
    functional_expertise: functionalExpertise,
    communication,
    teamwork_and_attitude: teamworkAndAttitude,
    initiative_and_ownership: initiativeAndOwnership,
    project_leadership: projectLeadership,
    english,
    project_goals: projectGoals,
    achievements,
    professional_goals: professionalGoals,
    notes,
    is_confirmed: isConfirmed,
  };
};

export const getEvalMarksPageData = (evalMarkRawData: EvaluationOptionsDataRaw): EvaluationOptionsData => {
  return {
    communication: mapToEvaluationMarkDetails(evalMarkRawData.communication),
    projectGoals: evalMarkRawData.project_goals,
    english: mapToEvaluationMarkDetails(evalMarkRawData.english),
    functionalExpertise: mapToEvaluationMarkDetails(evalMarkRawData.functional_expertise),
    initiativeAndOwnership: mapToEvaluationMarkDetails(evalMarkRawData.initiative_and_ownership),
    isConfirmed: evalMarkRawData.is_confirmed,
    notes: evalMarkRawData.notes,
    professionalGoals: evalMarkRawData.professional_goals,
    projectLeadership: mapToEvaluationMarkDetails(evalMarkRawData.project_leadership),
    qualityOfWork: mapToEvaluationMarkDetails(evalMarkRawData.quality_of_work),
    quantityOfWork: mapToEvaluationMarkDetails(evalMarkRawData.quantity_of_work),
    achievements: evalMarkRawData.achievements,
    teamworkAndAttitude: mapToEvaluationMarkDetails(evalMarkRawData.teamwork_and_attitude),
  };
};

export const getEvalPageDataFromResponse = (resp: EvalResponseData): EvalPageData => {
  const data = resp.data;
  const { evaluatee, evaluator } = data.evaluation;

  const evaluation = {
    evaluatee: evaluatee ? getEvalMarksPageData(evaluatee) : null,
    evaluator: evaluator ? getEvalMarksPageData(evaluator) : null,
  };

  const google_calendar_event = data.google_calendar_event;
  const peer_reviews = data.peer_reviews;
  const client_reviews = data.client_reviews;
  const evaluationRescheduleHistory = evaluationRescheduleHistoryMapFunc(data.evaluation_reschedulings);

  const {
    id,
    email,
    role,
    is_active,
    full_name,
    position,
    location,
    department,
    people_partner,
    manager,
    primary_reports_to,
    secondary_reports_to,
    imageUrl,
    employment_type,
    office_country,
  } = data.user_history;

  const userId = data.user_id;

  const userHistory = {
    id,
    imageUrl,
    email,
    rockyRole: role,
    isActive: is_active,
    name: full_name,
    position,
    location,
    department: {
      ...department,
      id: department.department_id,
    },
    peoplePartner: people_partner
      ? {
          ...people_partner,
          name: people_partner.full_name,
        }
      : null,
    manager: manager ? { ...manager, name: manager.full_name } : null,
    primaryReportsTo: {
      ...primary_reports_to,
      name: primary_reports_to.full_name,
    },
    secondaryReportsTo: secondary_reports_to
      ? {
          ...secondary_reports_to,
          name: secondary_reports_to.full_name,
        }
      : null,
    employmentType: employment_type,
    officeCountry: office_country,
  };

  let previousEvaluation;

  if (data.previous_evaluation) {
    const { evaluation_id, evaluation_date, notes, goals, is_not_rocky } = data.previous_evaluation;

    previousEvaluation = {
      evaluationId: evaluation_id,
      evaluationDate: evaluation_date,
      notes,
      goals,
      isNotRocky: is_not_rocky,
    };
  }
  const isEop = data.type_of_interaction === EvaluationType.EndOfProbation;

  const nextEvalDate =
    isEop && data.eop_extended_to
      ? data.eop_extended_to
      : getIsoDateWithoutTz(addMonths(parseISO(data.evaluation_date), 6));

  return {
    evaluationId: data.evaluation_id,
    compensationRaiseUsd: data.compensation_raise_usd,
    evaluation,
    googleCalendarEvent: google_calendar_event
      ? {
          ...google_calendar_event,
          attendees: google_calendar_event.attendees.map((attendee) => {
            return {
              name: attendee.full_name,
              email: attendee.email,
              id: attendee.id,
              imageUrl: attendee.imageUrl,
              position: attendee.position,
              city: attendee.city,
              country: attendee.country,
            };
          }),
        }
      : null,
    evaluationDate: data.evaluation_date,
    goals: data.goals,
    isNotRocky: data.is_not_rocky,
    notes: data.notes,
    status: data.status,
    typeOfInteraction: data.type_of_interaction,
    userHistory,
    userId,
    previousEvaluation,
    peerReviews: peer_reviews
      ? peer_reviews.map((peerReview) => ({
          id: peerReview.id,
          status: peerReview.status,
          user: {
            ...peerReview.peer_reviewer,
            name: peerReview.peer_reviewer ? peerReview.peer_reviewer.full_name : '',
          },
          feedback: getEvalMarksPageData(peerReview.feedback),
          isAnonymous: peerReview.is_anonymous,
        }))
      : null,
    clientReviews: client_reviews
      ? client_reviews.map((clientReview) => ({
          id: clientReview.id,
          status: clientReview.evaluation.is_confirmed ? EvalFeedbackStatus.Submitted : EvalFeedbackStatus.Pending,
          user: {
            id: clientReview.id,
            name: clientReview.full_name,
            email: clientReview.email,
            position: clientReview.position,
            imageUrl: ImageUrls.Client,
          },
          feedback: getEvalMarksPageData(clientReview.evaluation),
          isAskWithEmail: clientReview.was_request_email_sent,
        }))
      : null,
    eopResult: data.eop_status,
    nextEvalDate: nextEvalDate,
    evaluationRescheduleHistory,
    eopResolvedAt: data.eop_resolved_at,
    owner: mapUserFromBePythonToFeUser(data.evaluation_owner),
    isExtendable: data.is_extendable,
  };
};
