import { paths } from '@shared/enums/paths';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import { getUserProfileData } from '../redux/actions';
import { userProfileDataSelector } from '../redux/selectors';

export const useUserProfileData = (userId?: string): ReturnType<typeof userProfileDataSelector> => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const userIdTofetch = userId ?? id;
  const userProfileData = useSelector(userProfileDataSelector);
  useEffect(() => {
    if (!userIdTofetch) {
      navigate(paths.root);

      return;
    }

    dispatch(getUserProfileData(userIdTofetch));
  }, [id]);

  return userProfileData;
};
