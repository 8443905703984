import React, { FC } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Dialog from '@mui/material/Dialog';
import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { useStyles } from './UserGoalPreviewDialog.styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { getGoalStatusData } from '@modules/EvaluationModule/pages/UserGoalsPage/helpers/getGoalStatusData';
import { UserGoalStatusIcon } from '@modules/EvaluationModule/pages/UserGoalsPage/components/UserGoalStatusIcon/UserGoalStatusIcon';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import Stack from '@mui/material/Stack';
import { ClassNameMap } from '@mui/styles';
import { getFormattedDate } from '@modules/EvaluationModule/pages/UserGoalsPage/helpers/getFormattedDate';

interface Props {
  isModalOpen: boolean;
  close: () => void;
  goal: UserGoalPageData;
}

const GoalDataItem: FC<{ title: string; data: string; styles: ClassNameMap }> = ({ title, data, styles }) => (
  <div>
    <Typography variant="body2" className={styles.goalCategory}>
      {title}
    </Typography>
    <Typography variant="body2" className={styles.goalData}>
      {data}
    </Typography>
  </div>
);
export const UserGoalPreviewDialog: FC<Props> = ({ isModalOpen, close, goal }) => {
  const styles = useStyles();

  const { isOverdue, labelColor, percentageOfDone } = getGoalStatusData(goal);

  return goal ? (
    <Dialog open={isModalOpen} onClose={close}>
      <DialogTitle>
        <Typography variant="body2" className={styles.goalCategory}>
          Goal objective
        </Typography>
        <div className={styles.modalHeaderStyles}>
          <UserGoalStatusIcon status={goal?.status} isOverdue={isOverdue} />
          <div className={styles.modalHeaderTitle}>{goal?.objective}</div>
        </div>

        <IconButton
          aria-label="close"
          onClick={close}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ width: 600 }}>
        <div>
          <div className={styles.modalContent}>
            <div className={styles.goalContentWrapper}>
              <div className={styles.goalContentWrapper}>
                <div className={styles.goalResult}>
                  <Typography variant="body2" className={styles.goalCategory}>
                    {goal.isRange ? 'Worst result' : 'Expected result'}
                  </Typography>
                  <Typography variant="h1" className={styles.goalData}>
                    {goal?.worstCaseResult}
                  </Typography>
                </div>
                {goal.isRange && (
                  <div className={styles.goalResult}>
                    <Typography variant="body2" className={styles.goalCategory}>
                      Best case
                    </Typography>
                    <Typography variant="h1" className={styles.goalData}>
                      {goal?.bestCaseResult}
                    </Typography>
                  </div>
                )}
                <div>
                  <Typography variant="body2" className={styles.goalCategory}>
                    Actual result
                  </Typography>
                  <Typography variant="h1" className={styles.goalData}>
                    {goal?.actualResult}
                  </Typography>
                </div>
              </div>
              {percentageOfDone !== null && (
                <div>
                  <Typography
                    variant="h3"
                    className={classnames(styles.goalCompletenessBadge, {
                      [styles.goalCompletenessBadgeOff]: goal?.status === GoalsStatus.Canceled,
                    })}
                    sx={{
                      background: labelColor,
                    }}
                  >
                    {percentageOfDone}%
                  </Typography>
                </div>
              )}
            </div>
            {goal.expectationChangeReason && (
              <Stack marginTop="16px" rowGap="2px">
                <Typography variant="body2" color="text.secondary" fontSize="14px">
                  Reason of changing expectations
                </Typography>
                <Typography
                  variant="body1"
                  color="text.primary"
                  sx={{
                    whiteSpace: 'pre-wrap',
                    wordBreak: 'break-word',
                  }}
                >
                  {goal.expectationChangeReason}
                </Typography>
              </Stack>
            )}
          </div>

          {!!goal?.description && (
            <div className={styles.descriptionWrapper}>
              <Typography variant="body2" className={styles.goalCategory}>
                Description
              </Typography>
              <Typography variant="body1" className={styles.goalData}>
                {goal?.description}
              </Typography>
            </div>
          )}
          <div className={styles.descriptionWrapper}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
              <GoalDataItem title={'Set by'} data={goal?.author.fullName} styles={styles} />
              <GoalDataItem title={'Status'} data={goal?.status} styles={styles} />
              <GoalDataItem title={'Created'} data={getFormattedDate(goal.createdAt)} styles={styles} />
              <GoalDataItem title={'Due'} data={getFormattedDate(goal.dueDate)} styles={styles} />
            </Stack>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  ) : null;
};
