import React, { FC } from 'react';
import { useStyles } from './FlagUrgencyAndResolutionsBlockPreview.styles';
import { Grid, Paper, Stack, Typography } from '@mui/material';
import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { noAdditionalNotes } from '@shared/helpers/yesOrNoByBoolean';
import { FlagFieldBlockPreview } from '../FlagFieldBlockPreview';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';
import { FlagFormFields } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagFormFields';
import { FlagSeverityComponent } from '@modules/HappinessModule/pages/FlagPage/components/FlagSeverityComponent';
import { FlagStatusMapToName } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagStatusMapToName';
import { FlagHistory } from '@modules/HappinessModule/pages/FlagPage/components/FlagHistory';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';
import { LinkManageButton } from '@modules/App/pages/ExternalLinksPage/components/LinkManageButton/LinkManageButton';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';

interface Props {
  flagData: FlagData;
  userFlagPermissions: FlagPermissionsData;
}

export const FlagUrgencyAndResolutionsBlockPreview: FC<Props> = ({ flagData, userFlagPermissions }) => {
  const styles = useStyles();
  const { flagFields, flagChangeEvents } = flagData;
  const shouldDisplayResolutionNotes =
    flagFields.status !== FlagStatus.InProgress &&
    flagFields.status !== FlagStatus.OnHold &&
    userFlagPermissions.compensationDetails.isReadable;

  const statusLabel =
    flagFields.status && [FlagStatus.InProgress, FlagStatus.OnHold].includes(flagFields.status)
      ? 'Status:'
      : 'Resolved:';

  return (
    <Paper variant="elevation" elevation={2}>
      <Grid container rowGap="36px" className={styles.mainContainer} justifyContent="space-between">
        <Grid item xs={12}>
          {flagFields.status && (
            <Typography variant="h2">
              {statusLabel}&nbsp;
              {FlagStatusMapToName[flagFields.status].toLowerCase()}&nbsp;
              {flagFields.status === FlagStatus.Positive && <>🍀</>}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <Stack rowGap="32px">
            {[
              {
                label: 'Severity',
                value: flagFields.severity && (
                  <FlagSeverityComponent isHelperTextShown severity={flagFields.severity} />
                ),
              },
              { label: FlagFormFields.whatWasDone.label, value: noAdditionalNotes(flagFields.whatWasDone) },
              {
                label: FlagFormFields.suggestedActionItems.label,
                value: noAdditionalNotes(flagFields.suggestedActionItems),
              },
            ].map((field) => (
              <FlagFieldBlockPreview key={field.label} {...field} />
            ))}
            {flagFields.pipLink && (
              <div>
                <Typography variant="subtitle1">{FlagFormFields.pipLink.label}</Typography>
                <LinkManageButton
                  isOutside
                  type={ExternalLinksTypes.pip}
                  link={{
                    link: flagFields.pipLink,
                    type: ExternalLinksTypes.pip,
                  }}
                  canEdit={false}
                />
              </div>
            )}
            {userFlagPermissions.hrNotes.isReadable && (
              <FlagFieldBlockPreview
                label={FlagFormFields.hrNotes.label}
                value={noAdditionalNotes(flagFields.hrNotes ?? null)}
              />
            )}
            {shouldDisplayResolutionNotes && flagFields.resolutionNotes !== null && (
              <FlagFieldBlockPreview
                label={FlagFormFields.resolutionNotes.label}
                value={noAdditionalNotes(flagFields.resolutionNotes)}
              />
            )}
          </Stack>
        </Grid>

        <Grid item xs={3}>
          {flagChangeEvents && flagChangeEvents.length > 0 && <FlagHistory flagChangeEvents={flagChangeEvents} />}
        </Grid>
      </Grid>
    </Paper>
  );
};
