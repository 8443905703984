import { FC, useEffect, useRef } from 'react';
import { Alert, Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { Field, FieldProps, useFormikContext } from 'formik';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { addFlagDataStateSelector } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { useStyles } from '../CommunicationModal.styles';
import { Props as CommunicationModalProps } from '../CommunicationModal';
import { YesNo } from '@shared/enums/yesNo';
import { FlagFormFields } from '../../../../constants/FlagFormFields';
import { CommunicationModalComponents } from '../../../../enums/CommunicationModalComponents';
import { NewHomeEmailSentStatus } from '@modules/HappinessModule/enums/NewHomeEmailSentStatus';
import { useSelector } from 'react-redux';
import MailOutlineIcon from '@mui/icons-material/MailOutline';

const SendEmail: FC<Omit<CommunicationModalProps, 'step' | 'isOpen'>> = ({
  userInfo,
  onClose,
  setStep,
  saveFlagWithSettedNhEmailStatus,
  isNewFlag,
}) => {
  const styles = useStyles();
  const flagUpdated = useRef(false);
  const addFlagDataState = useSelector(addFlagDataStateSelector);
  const { values } = useFormikContext<FlagFields>();

  useEffect(() => {
    if (flagUpdated.current && addFlagDataState.loadingState === DataState.Fulfilled) {
      setStep(
        values.nhEmailSentStatus === NewHomeEmailSentStatus.Sent ||
          (values.nhEmailSentStatus === NewHomeEmailSentStatus.Requested && values.assignedTom)
          ? CommunicationModalComponents.EmailSentMessage
          : values.nhEmailSentStatus === NewHomeEmailSentStatus.Requested && !values.assignedTom
          ? CommunicationModalComponents.EmailWillBeSentWhenUserIsAssignedToTom
          : CommunicationModalComponents.SendEmailLaterMessage
      );
    }
  }, [addFlagDataState, values.nhEmailSentStatus, values.assignedTom]);

  const handleUpdateFlag = () => {
    flagUpdated.current = true;
    saveFlagWithSettedNhEmailStatus(values.nhEmailSentStatus);
  };

  const userFirstName = userInfo.name.split(' ')[0];

  return (
    <>
      <Typography variant="subtitle1">Send {userInfo.name} an automated email about NewHome?</Typography>
      <Field
        name={FlagFormFields.nhEmailSentStatus.name}
        component={(props: FieldProps) => (
          <RadioGroup {...props.field}>
            <FormControl>
              <Stack direction="row" spacing={0.5}>
                <FormControlLabel
                  control={<Radio required />}
                  label={YesNo.Yes}
                  value={NewHomeEmailSentStatus.Requested}
                />
                <FormControlLabel
                  control={<Radio required />}
                  label={YesNo.No}
                  value={NewHomeEmailSentStatus.NotRequested}
                />
              </Stack>
            </FormControl>
          </RadioGroup>
        )}
      />
      <Typography variant="body2" marginTop="2px">
        Since the flag is non-confidential {userFirstName} will receive an email describing NH process.
      </Typography>

      {isNewFlag && values.nhEmailSentStatus === NewHomeEmailSentStatus.Requested && !values.assignedTom && (
        <Alert
          icon={<MailOutlineIcon color="disabled" />}
          severity="info"
          sx={{
            marginTop: '28px',
          }}
        >
          <Typography variant="body2" fontSize="14px">
            An automated “Welcome to NewHome” email will be sent only once Talent Operations team assign a responsible
            TOM to guide {userFirstName} through further NH activities.
          </Typography>
        </Alert>
      )}
      <Box marginTop={5} className={styles.buttonsContainer}>
        <Button type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          type="button"
          variant="contained"
          onClick={handleUpdateFlag}
          disabled={!values.nhEmailSentStatus}
          sx={{ minWidth: '7rem' }}
        >
          {addFlagDataState.loadingState === DataState.Pending ? (
            <CircularProgress sx={{ color: 'white' }} size={'1rem'} />
          ) : isNewFlag ? (
            'Create flag'
          ) : (
            'Update flag'
          )}
        </Button>
      </Box>
    </>
  );
};

export default SendEmail;
