// TODO: Delete this file when the backend will change the response on the permissions request

export enum AvailableFlagPermissions {
  Read = 'FLAGS:FLAG:READ',
  Create = 'FLAGS:FLAG:CREATE',
  Update = 'FLAGS:FLAG:UPDATE',
}

export enum AvailableFlagHrNotesPermissions {
  Read = 'FLAGS:HR_NOTES:READ',
  Create = 'FLAGS:HR_NOTES:CREATE',
  Update = 'FLAGS:HR_NOTES:UPDATE',
}

export enum AvailableFlagResolutionPermissions {
  Read = 'FLAGS:RESOLUTION:READ',
  Reopen = 'FLAGS:RESOLUTION:REOPEN',
  Update = 'FLAGS:RESOLUTION:UPDATE',
}

export enum AvailableFlagCompensationDetailsPermissions {
  Read = 'FLAGS:COMPENSATION_DETAILS:READ',
  Create = 'FLAGS:COMPENSATION_DETAILS:CREATE',
  Update = 'FLAGS:COMPENSATION_DETAILS:UPDATE',
}

export enum AvailableFlagNewHomeSectionPermissions {
  Read = 'FLAGS:NEW_HOME_SECTION:READ',
  Create = 'FLAGS:NEW_HOME_SECTION:CREATE',
  Update = 'FLAGS:NEW_HOME_SECTION:UPDATE',
}

export enum AvailableFlagNewHomeTomSectionPermissions {
  Read = 'FLAGS:NEW_HOME_TOM_SECTION:READ',
  Create = 'FLAGS:NEW_HOME_TOM_SECTION:CREATE',
  Update = 'FLAGS:NEW_HOME_TOM_SECTION:UPDATE',
}

export enum AvailableFlagPRTeamSectionPermissions {
  Read = 'FLAGS:PR_TEAM_SECTION:READ',
  Create = 'FLAGS:PR_TEAM_SECTION:CREATE',
  Update = 'FLAGS:PR_TEAM_SECTION:UPDATE',
}

export type AllAvailableFlagPermissions =
  | AvailableFlagPermissions
  | AvailableFlagHrNotesPermissions
  | AvailableFlagResolutionPermissions
  | AvailableFlagCompensationDetailsPermissions
  | AvailableFlagNewHomeSectionPermissions
  | AvailableFlagNewHomeTomSectionPermissions
  | AvailableFlagPRTeamSectionPermissions;
