export const getGoogleCalendarMeetingLink = ({
  inviteeName,
  inviterName,
  inviteeEmail,
}: {
  inviteeName: string;
  inviterName: string;
  inviteeEmail: string;
}): string =>
  `https://calendar.google.com/calendar/u/0/r/eventedit?text=${`${inviterName} and ${inviteeName}`}&add=${inviteeEmail}`;
