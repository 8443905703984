import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    position: 'relative',
    display: 'flex',
    backgroundColor: COLORS.TEXT.PRIMARY,
    minHeight: '100vh',
    paddingTop: 0,
    transition: 'padding-top 0.35s',
  },
  notificationsHolder: {
    position: 'fixed',
    bottom: 0,
    zIndex: 10000,
    left: '50%',
    transform: 'translateX(-50%)',
    pointerEvents: 'none',
  },
});
