import React from 'react';
import 'normalize.css';
import 'react-loading-skeleton/dist/skeleton.css';
import * as Sentry from '@sentry/react';
import 'reactjs-popup/dist/index.css';
import 'react-datepicker/dist/react-datepicker.css';
import 'filepond/dist/filepond.min.css';
import { AppModule } from './modules/App/App';
import { Provider } from 'react-redux';
import store from '@modules/App/redux/store';
import { ErrorView } from '@modules/App/pages/ErrorView';

const App: React.FC = () => (
  <Provider store={store}>
    <Sentry.ErrorBoundary fallback={<ErrorView />}>
      <AppModule />
    </Sentry.ErrorBoundary>
  </Provider>
);
export default App;
