import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  toolbar: {
    background: COLORS.WHITE,
    width: '100%',
    margin: '0px 10px',
    minHeight: 56,
    boxShadow: '0 -1px 8px -4px hsl(228deg 27% 50% / 40%)',
    borderRadius: 8,
    pointerEvents: 'all',
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
      minHeight: 'auto',
      bottom: 0,
      left: 4,
      right: 4,
      maxHeight: 'calc(100vh - 64px)',
      position: 'absolute',
      zIndex: 1,
      margin: '0px 4px',
      borderRadius: '8px 8px 0 0',
      boxShadow: '0 4px 10px 4px hsl(228deg 27% 50% / 40%)',
    },
  },
  toolbarHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: 10,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column-reverse',
      rowGap: 4,
    },
  },
  toolbarBody: {
    borderTop: `1px solid ${COLORS.DIVIDER}`,
    padding: '32px 40px',
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
    display: 'flex',
    justifyContent: 'space-between',
    minHeight: 200,

    [BREAKPOINTS.MOBILE]: {
      padding: 16,
      flexDirection: 'column',
      rowGap: 16,
      minHeight: 'auto',
    },
  },
  toolbarTitle: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
  },
  toolbarBodyItem: {
    display: 'flex',
    flexDirection: 'column',
    rowGap: 12,
    maxWidth: 250,

    [BREAKPOINTS.MOBILE]: {
      rowGap: 4,
      maxWidth: 'none',
    },
  },
  bodyItemTitle: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.PRIMARY,
  },
  toggle: {
    display: 'flex',
    columnGap: 8,
    alignItems: 'start',
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.PRIMARY,
  },
  radioBtn: {
    display: 'flex',
    columnGap: 8,
    alignItems: 'start',
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.PRIMARY,

    '& input': {
      width: 20,
      height: 20,
    },
  },
  tool: {
    borderRadius: 4,
    backgroundColor: COLORS.ACTION.HIGHLIGHT,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    overflow: 'hidden',
    color: COLORS.TEXT.SECONDARY,
    fontWeight: '400',
    transition: 'all 0.2s ease-in-out',
    fontSize: '24px',
    padding: '0px 10px',
    userSelect: 'none',
    minWidth: 40,
    height: 40,

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
    },
    '& svg': {
      width: 16,
      height: 16,
    },

    [BREAKPOINTS.MOBILE]: {
      flex: 1,
    },
  },
  toolText: {
    fontSize: '13px',
  },
  active: {
    background: COLORS.BRAND.HIGHLIGHT,
    color: COLORS.TEXT.PRIMARY,

    '&:hover': {
      color: COLORS.TEXT.PRIMARY,
    },
  },
  tools: {
    display: 'flex',
    gap: 4,
  },
  structures: {},
  zoom: {
    [BREAKPOINTS.MOBILE]: {
      width: '50%',
    },
  },
  tail: {
    display: 'flex',
    columnGap: 4,
    marginLeft: 'auto',
    width: 'fit-content',
  },
  flex: {
    display: 'flex',
    columnGap: 4,
  },
  btn: {
    height: 40,
  },
});
