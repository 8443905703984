import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

const pufRules = Array(45)
  .fill(0)
  .reduce(
    (res, _val, i) => ({
      ...res,
      [`& > i:nth-child(${i + 1})`]: {
        transform: `rotate(${8 * (i + 1)}deg)`,
      },
      [`& > i:nth-child(${i + 1}):after`]: {
        animationDelay: `${0.03333333 * (i + 1)}s`,
        marginTop: i % 2 === 0 ? 7 : 3,
      },
    }),
    {}
  );

const particlesRules = Array(45)
  .fill(0)
  .reduce(
    (res, _val, i) => ({
      ...res,
      [`& > i:nth-child(${i + 1})`]: {
        transform: `rotate(${8 * (i + 1)}deg)`,
      },
      [`& > i:nth-child(${i + 1}):after`]: {
        animationDelay: `${0.03333333 * (i + 1)}s`,
        animationName: i % 2 === 0 ? '$particle' : '$particleO',
      },
    }),
    {}
  );

export const useStyles = makeStyles({
  loader: {
    width: 100,
    height: 100,
    position: 'relative',
    display: 'block',
    margin: 10,
    transition: 'all 5s ease-out',
    transform: 'scale(1.5)',
  },
  loaderIcon: {
    color: COLORS.TEXT.PRIMARY,
    width: 32,
    height: 32,
    transform: 'rotate(90deg) translate(0, -40px)',
    fill: COLORS.BRAND.PRIMARY,
  },

  '@keyframes orbit': {
    from: {
      transform: 'rotate(0deg)',
    },
    to: {
      transform: 'rotate(360deg)',
    },
  },

  '@keyframes puf': {
    '0%': {
      opacity: 1,
      color: 'blue',
      transform: 'scale(1)',
    },
    '10%': {
      color: 'red',
      transform: 'scale(1.5)',
    },
    '100%, 100%': {
      opacity: 0,
      color: 'yellow',
      transform: 'scale(0.4)',
    },
  },

  '@keyframes pufWhite': {
    '0%': {
      opacity: 1,
      color: 'rgba(0, 0, 0, 0.75)',
      transform: 'scale(1)',
    },
    '10%': {
      color: 'rgba(255, 255, 255, 0.9)',
      transform: 'scale(1.5)',
    },
    '100%, 100%': {
      opacity: 0,
      color: 'rgba(0, 0, 0, 0.3)',
      transform: 'scale(0.4)',
    },
  },

  '@keyframes particle': {
    '0%': {
      opacity: 1,
      marginTop: 12,
    },
    '10%': {
      marginTop: -2,
    },
    '75%': {
      opacity: 0.5,
      marginTop: 4,
    },
    '100%': {
      opacity: 0,
      marginTop: -4,
    },
  },

  '@keyframes particleO': {
    '0%': {
      opacity: 1,
      marginTop: 20,
    },
    '10%': {
      marginTop: 18,
    },
    '75%': {
      opacity: 0.5,
      marginTop: 12,
    },
    '100%': {
      opacity: 0,
      marginTop: 14,
    },
  },

  loaderSpinned: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    zIndex: 100,
    position: 'absolute',
    display: 'block',
    animation: '$orbit 1.5s linear infinite',
  },
  pufs: {
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'block',
    position: 'absolute',
    opacity: 0.5,

    '& > i': {
      display: 'block',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      position: 'absolute',
    },
    '& > i:after': {
      content: '""',
      background: COLORS.BRAND.PRIMARY,
      height: 7,
      width: 7,
      position: 'relative',
      borderRadius: '100%',
      display: 'block',
      margin: '0 auto',
      top: 7,
      fontSize: 9,
      opacity: 0,
      animationName: '$pufWhite',
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-out',
      animationDuration: '1.5s',
    },
    ...pufRules,
  },
  particles: {
    position: 'absolute',
    display: 'block',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    opacity: 0.5,

    '& > i': {
      display: 'block',
      top: 0,
      right: 0,
      left: 0,
      bottom: 0,
      position: 'absolute',
    },
    '& > i:after': {
      content: '""',
      background: COLORS.BRAND.PRIMARY,
      height: 3,
      width: 3,
      position: 'relative',
      borderRadius: '100%',
      display: 'block',
      margin: '0 auto',
      top: 7,
      fontSize: 9,
      opacity: 0,
      animationIterationCount: 'infinite',
      animationTimingFunction: 'ease-out',
      animationDuration: '1.5s',
    },
    ...particlesRules,
  },
});
