import React from 'react';
import { useStyles } from './FiltersForm.styles';
import { Field, FieldProps, Form, FormikProps, useFormikContext } from 'formik';
import Switch from '@mui/material/Switch';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { Autocomplete, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { escalationObject, projectEndObject, statusObject, userStatusesObject } from '../../constants/constants';
import { PeopleSerchSelector } from '../PeopleSerchSelector/PeopleSerchSelector';
import { FilterFields } from '../../enums/FilterFields';
import { PeopleSelector } from '../PeopleSelector/PeopleSelector';
import { FiltersRadioButtons } from '../FiltersRadioButtons/FiltersRadioButtons';
import { FiltersAutocomplete } from '../FiltersAutocomplete/FiltersAutocomplete';
import { FiltersMultipleAutocomplete } from '../FiltersMultipleAutocomplete/FiltersMultipleAutocomplete';
import { AutocompleteAsync } from '../AutocompleteAsync/AutocompleteAsync';
import { FlagFiltersFields } from '../../interfaces/FlagFiltersFields';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { useHandlers } from '../../hooks/useHandlers';
import { rockySwitcherStyles } from '@styles/rockySwitcherStyles';
import { useAllFlagsData } from '../../hooks/useAllFlagsData';

interface Props {
  allDeparttmentsData: DepartmentData[];
  tomData: UserInfo[];
  prData: UserInfo[];
}

export const FiltersForm: React.FC<Props> = ({ allDeparttmentsData, tomData, prData }) => {
  const styles = useStyles();
  const rockySwitcher = rockySwitcherStyles();
  const formik: FormikProps<FlagFiltersFields> = useFormikContext();
  const { locationOptions: locations } = useAllFlagsData();
  const {
    handleNewHomeChange,
    handleChangeRadioBtn,
    handleStatusChange,
    handleEscalationDateChange,
    handleLocationChange,
    handleProjectsChange,
    handlePeopleSelectorChange,
    handleUserStatusesChange,
    handleReasonsSubReasonsChange,
    reasonsSubReasonsOptions,
  } = useHandlers(allDeparttmentsData);

  const selectedReasonsSubReasonsOptions = reasonsSubReasonsOptions.filter((option) =>
    formik.values.reasonsSubReasons.some((x) => x === option.value)
  );
  const selectedLocations = (locations ?? []).filter((option) =>
    formik.values.legalLocations.some((x) => x === option.value)
  );

  return (
    <Form>
      <Grid container spacing={2} className={styles.drawlerRoot}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Severity and status</Typography>
          <FiltersRadioButtons row name={FilterFields.Severity} handleChange={handleChangeRadioBtn} severity />
        </Grid>
        <Grid item xs={12}>
          <FiltersMultipleAutocomplete
            name={FilterFields.Status}
            value={formik.values.status}
            handleChange={handleStatusChange}
            options={Object.values(statusObject).reduce((acc, item) => {
              acc.push(item.label);
              return acc;
            }, [] as string[])}
            label="Status"
          />
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                padding: '10px',
              },
            }}
            multiple
            filterOptions={(x, state) => x.filter((option) => option.label.toLowerCase().includes(state.inputValue))}
            getOptionLabel={(option) => option.label}
            groupBy={(option) => option.mainFlagReason}
            noOptionsText="No such reasons"
            options={reasonsSubReasonsOptions}
            value={selectedReasonsSubReasonsOptions}
            onChange={(_, value) => handleReasonsSubReasonsChange(FilterFields.ReasonsSubReasons, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Reasons"
                placeholder={selectedReasonsSubReasonsOptions.length === 0 ? 'All reasons' : undefined}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  style={{
                    paddingLeft: '24px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                  }}
                >
                  {option.isPrimaryReason ? (
                    option.label
                  ) : (
                    <>
                      <Typography variant="body2" fontSize="22px" sx={{ marginRight: '10px' }}>
                        ∙
                      </Typography>
                      {option.label}
                    </>
                  )}
                </li>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <FiltersAutocomplete
            name={FilterFields.EscalationDate}
            value={formik.values.escalationDate}
            handleChange={handleEscalationDateChange}
            options={Object.values(escalationObject).reduce((acc, item) => {
              acc.push(item.label);
              return acc;
            }, [] as string[])}
            label="Escalation date"
          />
        </Grid>
        <Grid item xs={12}>
          <div className={styles.switcherContainer}>
            <Field
              name={FilterFields.IsNewHomeNeeded}
              onChange={formik.handleChange}
              type="checkbox"
              component={({ field }: FieldProps) => (
                <FormControlLabel
                  sx={{
                    '& .MuiTypography-root': {
                      marginLeft: '10px',
                    },
                  }}
                  control={
                    <Switch
                      {...field}
                      className={rockySwitcher.switcher}
                      checked={formik.values.isNewHomeNeeded}
                      onChange={handleNewHomeChange}
                    />
                  }
                  label={<Typography variant="subtitle1">NewHome needed</Typography>}
                />
              )}
            />
          </div>
        </Grid>
        {formik.values.isNewHomeNeeded && (
          <Grid item xs={12}>
            <Typography variant="body2" className={styles.radioBtnTitle}>
              Project end date
            </Typography>
            <FiltersRadioButtons
              name={FilterFields.ProjectEnd}
              handleChange={handleChangeRadioBtn}
              labelValueObject={projectEndObject}
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={styles.chapterTitle}>
            Who's involved
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <FiltersMultipleAutocomplete
            name={FilterFields.UserStatuses}
            value={formik.values.userStatuses}
            handleChange={handleUserStatusesChange}
            options={Object.values(userStatusesObject).reduce((acc, item) => {
              acc.push(item.label);
              return acc;
            }, [] as string[])}
            label="Engagement status"
          />
        </Grid>
        <Grid item xs={12}>
          <PeopleSerchSelector
            title="Flags raised for"
            name={FilterFields.InvolvedUsers}
            onChange={handlePeopleSelectorChange}
            value={formik.values.involvedUsers}
          />
        </Grid>
        <Grid item xs={12}>
          <PeopleSerchSelector
            title="Manager"
            name={FilterFields.FlagManagers}
            onChange={handlePeopleSelectorChange}
            value={formik.values.flagManagers}
          />
        </Grid>
        <Grid item xs={12}>
          <PeopleSerchSelector
            title="Author"
            name={FilterFields.FlagAuthors}
            onChange={handlePeopleSelectorChange}
            value={formik.values.flagAuthors}
          />
        </Grid>
        {formik.values.isNewHomeNeeded && (
          <Grid item xs={12}>
            <PeopleSelector
              data={tomData}
              title="Assigned TOM"
              name={FilterFields.AssignedTomIds}
              onChange={handlePeopleSelectorChange}
              value={formik.values.assignedTomIds}
              tom
            />
          </Grid>
        )}
        <Grid item xs={12}>
          <PeopleSelector
            data={prData}
            title="Assigned HR"
            name={FilterFields.AssignedPrIds}
            onChange={handlePeopleSelectorChange}
            value={formik.values.assignedPrIds}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={styles.chapterTitle}>
            Location
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Autocomplete
            sx={{
              '& .MuiAutocomplete-inputRoot': {
                padding: '10px',
              },
            }}
            multiple
            filterOptions={(x, state) =>
              x.filter((option) => option.name.toLowerCase().includes(state.inputValue.toLowerCase()))
            }
            getOptionLabel={(option) => option.name}
            noOptionsText="No such location"
            options={locations ?? []}
            value={selectedLocations}
            onChange={(_, value) => handleLocationChange(FilterFields.LegalLocations, value)}
            renderInput={(params) => (
              <TextField
                {...params}
                fullWidth
                label="Legal Locations"
                placeholder={selectedLocations.length === 0 ? 'All locations' : undefined}
              />
            )}
            renderOption={(props, option) => {
              return (
                <li
                  {...props}
                  style={{
                    paddingLeft: '24px',
                    paddingTop: '6px',
                    paddingBottom: '6px',
                  }}
                >
                  {option.isLocationArea ? (
                    option.name
                  ) : (
                    <>
                      <Typography variant="body2" fontSize="22px" sx={{ marginRight: '10px' }}>
                        ∙
                      </Typography>
                      {option.name}
                    </>
                  )}
                </li>
              );
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1" className={styles.chapterTitle}>
            More
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <AutocompleteAsync
            name={FilterFields.Projects}
            value={formik.values.projects}
            handleChange={handleProjectsChange}
            options={
              Object.values(allDeparttmentsData).reduce((acc, project) => {
                acc.push(project.name);
                return acc;
              }, [] as string[]) ?? []
            }
            label="Department and project"
          />
        </Grid>
      </Grid>
    </Form>
  );
};
