import React, { FC } from 'react';
import { PeopleHappinessGroupedData } from '../../interfaces/PeopleHappinessDataItem';
import { Table } from '@shared/components/Table';
import { useStyles } from './PeopleHappinessGroupedTable.styles';
import { PeopleHappinessGroupedTableRow } from './components/PeopleHappinessGroupedTableRow';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { PeopleHappinessDataGroupBy } from '../../enums/PeopleHappinessDataGroupBy';

interface Props {
  sortInfo: SortInfo;
  onSortClick: (sortInfo: SortInfo) => void;
  groupedData: PeopleHappinessGroupedData[];
  isDataLoading: boolean;
  tableSkeleton: React.ReactNode;
  groupBy: PeopleHappinessDataGroupBy;
}

export const PeopleHappinessGroupedTable: FC<Props> = ({
  sortInfo,
  onSortClick,
  groupedData,
  isDataLoading,
  tableSkeleton,
  groupBy,
}) => {
  const styles = useStyles();

  const tableHeaders = [
    { title: groupBy === PeopleHappinessDataGroupBy.Location ? 'Locations' : 'Departments', fieldName: '' },
    { title: 'Average rate', fieldName: 'reaction' },
    { title: 'vs previous', fieldName: '' },
    { title: 'Responses', fieldName: '' },
  ];

  return (
    <Table
      tableHeaders={tableHeaders}
      sortInfo={sortInfo}
      customStyles={{ tableHeaderRow: styles.tableHeaderRow }}
      isDataLoading={isDataLoading}
      onSortClick={onSortClick}
      skeleton={tableSkeleton}
    >
      {groupedData.map((data, i) => (
        <PeopleHappinessGroupedTableRow groupBy={groupBy} key={i} rowData={data} />
      ))}
    </Table>
  );
};
