import * as Yup from 'yup';

const EMPTY_FIELD_ERROR_TEXT = 'Required field';

const fieldsSchema = {
  feedbackText: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
  feedbackReason: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
};

export const validationSchema = Yup.object().shape(fieldsSchema);
