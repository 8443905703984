import React, { FC, useEffect, useState } from 'react';
import { useStyles } from './ClientSurveyResponseDmNotes.styles';
import { useAuth } from '@shared/hooks/useAuth';
import { ClientSurveyResponseDmNotesData } from '@modules/HappinessModule/interfaces/ClientSurveyResponseData';
import { Button, Link, Stack, TextField, Typography } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useDispatch, useSelector } from 'react-redux';
import { updateDmNotes } from '../../redux/actions';
import { LoadingButton } from '@mui/lab';
import { updatingDmNotesStateSelector } from '../../redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { getHistoryChangeDate } from '@shared/helpers/getHistoryChangeDate';
import { COLORS } from '@styles/colors';

export const ClientSurveyResponseDmNotes: FC<ClientSurveyResponseDmNotesData> = ({
  responsibleManagersIds,
  notes: notesFromBE,
  lastUpdatedAt,
  lastUpdatedBy,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { currentUser } = useAuth(true);
  const [notes, setNotes] = useState(notesFromBE ?? '');
  const [isEdit, setIsEdit] = useState(!notesFromBE);
  const isVisible = currentUser ? responsibleManagersIds?.includes(currentUser.id) : false;
  const updatingDmNotesState = useSelector(updatingDmNotesStateSelector);
  const isUpdatingDmNotes = updatingDmNotesState === DataState.Pending;
  const isNotAddedNotesYet = !notesFromBE;
  const noNotes = !notes.trim();

  useEffect(() => {
    if (updatingDmNotesState === DataState.Fulfilled) {
      setIsEdit(false);
    }
  }, [updatingDmNotesState]);

  return isVisible ? (
    <div className={styles.root}>
      <Stack alignItems="center">
        <Stack>
          <Stack
            sx={{
              marginBottom: '25px',
            }}
            direction="row"
            alignItems="center"
            columnGap="30px"
            justifyContent="space-between"
          >
            <Typography variant="h2">AgileEngine DM notes</Typography>
            <Typography variant="body2">
              <Stack direction="row" alignItems="center" columnGap="4px">
                <LockOutlinedIcon />
                Not visible to the client
              </Stack>
            </Typography>
          </Stack>
          {isEdit ? (
            <>
              <TextField
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                multiline
                rows={3}
                label="Notes"
                focused={isEdit}
              />
              <Stack direction="row" marginTop="14px" columnGap="9px">
                <LoadingButton
                  loading={isUpdatingDmNotes}
                  variant="contained"
                  onClick={() => dispatch(updateDmNotes(notes))}
                  disabled={noNotes}
                >
                  Save
                </LoadingButton>
                <Button
                  disabled={isNotAddedNotesYet ? noNotes : isUpdatingDmNotes}
                  onClick={() => {
                    if (isNotAddedNotesYet) {
                      setNotes('');
                    } else {
                      setIsEdit(false);
                      setNotes(notesFromBE ?? '');
                    }
                  }}
                >
                  {isNotAddedNotesYet ? 'Clear' : 'Cancel'}
                </Button>
              </Stack>
            </>
          ) : (
            lastUpdatedAt &&
            lastUpdatedBy && (
              <Stack
                sx={{
                  padding: '16px 24px 24px 24px',
                  background: COLORS.HIGHLIGHTS.NEUTRAL,
                  borderRadius: 4,
                }}
              >
                <Typography variant="subtitle2">Last edited by:</Typography>
                <Typography variant="body2">
                  {lastUpdatedBy?.name}
                  <Typography variant="caption" component="span">
                    {' '}
                    • {getHistoryChangeDate(lastUpdatedAt)}
                  </Typography>
                </Typography>
                <Typography variant="body1">{notes}</Typography>
                <Link sx={{ cursor: 'pointer', mt: '10px' }} onClick={() => setIsEdit(true)}>
                  Edit
                </Link>
              </Stack>
            )
          )}
        </Stack>
      </Stack>
    </div>
  ) : null;
};
