import React, { useRef } from 'react';
import { useStyles } from './PeopleHappinessPageFilter.styles';
import { useSearchParams } from 'react-router-dom';
import { Formik, FormikProps } from 'formik';
import { Button, DialogTitle, Drawer, IconButton, Stack } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as FilterIcon } from '@assets/icons/filtersIcon.svg';
import { ReactComponent as ResetFiltersIcon } from '@assets/icons/resetFiltersIcon.svg';
import { useModalState } from '@shared/hooks/useModalState';
import { PeopleHappinessPageFilterForm } from '../PeopleHappinessPageFilterForm/PeopleHappinessPageFilterForm';
import { PeopleHappinessFilterFormData } from '../../interfaces/PeopleHappinessFilterData';
import noop from 'lodash/noop';
import { LoadingButton } from '@mui/lab';
import { getPeopleHappinessFiltersLength } from '../../helpers/getPeopleHappinessFiltersLength';
import { PeopleHappinessFilterAnotherFieldsNames } from '../../enums/PeopleHappinessFilterFieldsNames';

interface Props {
  isLoading?: boolean;
  initialValues: PeopleHappinessFilterFormData;
  defaultValues: PeopleHappinessFilterFormData;
}

export const PeopleHappinessPageFilter: React.FC<Props> = ({ isLoading, initialValues, defaultValues }) => {
  const styles = useStyles();
  const { openModal, closeModal, isModalOpen } = useModalState();
  const formRef = useRef<FormikProps<PeopleHappinessFilterFormData>>(null);
  const [searchParams, setSearchParams] = useSearchParams();

  const filtersLength = getPeopleHappinessFiltersLength(searchParams);
  const isFilterExist = filtersLength > 0;

  const handleResetFilters = () => {
    const newSearchParams = new URLSearchParams();
    const groupBy = searchParams.get(PeopleHappinessFilterAnotherFieldsNames.GroupBy);
    if (groupBy) {
      newSearchParams.set(PeopleHappinessFilterAnotherFieldsNames.GroupBy, groupBy);
    }
    setSearchParams(newSearchParams);
    formRef.current?.setValues(defaultValues);
  };

  const ResetBtn = (
    <Button startIcon={<ResetFiltersIcon />} onClick={handleResetFilters} size="small">
      Reset all
    </Button>
  );

  return (
    <>
      <div className={styles.btnsContainer}>
        <LoadingButton
          variant="contained"
          startIcon={<FilterIcon />}
          onClick={openModal}
          loading={isLoading}
          size="small"
        >
          Filters {isFilterExist && `: ${filtersLength}`}
        </LoadingButton>
        {isFilterExist && ResetBtn}
      </div>
      {!isLoading && (
        <Drawer anchor="right" open={isModalOpen} onClose={closeModal} keepMounted>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{ padding: '32px', paddingBottom: 0 }}
          >
            <DialogTitle sx={{ padding: 0 }}>
              <Stack direction="row" alignItems="center" gap="12px">
                <div>Filters</div> {isFilterExist && ResetBtn}
              </Stack>
            </DialogTitle>
            <div>
              <IconButton aria-label="close" onClick={closeModal}>
                <CloseIcon />
              </IconButton>
            </div>
          </Stack>

          <Formik initialValues={initialValues} innerRef={formRef} onSubmit={noop} enableReinitialize>
            <PeopleHappinessPageFilterForm defaultValues={defaultValues} />
          </Formik>
        </Drawer>
      )}
    </>
  );
};
