import { fetchFeaturesData } from '@modules/App/api/features';
import { DataState } from '@shared/enums/DataState';
import { Features } from '@shared/enums/Features';
import { all, call, put, takeLeading } from 'redux-saga/effects';
import { getFeatures } from './actions';
import { setFeatures } from './reducers';

function* getFeaturesAsync() {
  const responce: string[] = yield call(fetchFeaturesData);

  yield put(
    setFeatures({
      data: {
        flags: responce.includes(Features.Flag),
        compensation: responce.includes(Features.Compensation),
      },
      state: DataState.Fulfilled,
    })
  );
}

function* watchFeatures() {
  yield takeLeading(getFeatures.type, getFeaturesAsync);
}

export function* featuresSaga(): Generator {
  yield all([watchFeatures()]);
}
