import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  title: {
    userSelect: 'none',
  },
  sortableTitle: {
    cursor: 'pointer',
    margin: 0,
  },
  sortHolder: {
    display: 'flex',
    cursor: 'pointer',
    flexDirection: 'column',

    '& svg': {
      margin: -3,
      height: 16,
      width: 'auto',
    },
  },
});
