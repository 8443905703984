import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    color: COLORS.TEXT.PRIMARY,
    height: '100%',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
    },
  },
  questionBlock: {
    width: 600,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },
  questionBlockTitle: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '120%',
    margin: '0 0 18px 0',

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
    },
  },
  additionalNotes: {
    width: '100%',
    height: 80,
    border: `1px solid ${COLORS.INPUTBORDER}`,
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '400',
    lineHeight: '130%',
    borderRadius: 4,

    '&:focus': {
      border: `2px solid ${COLORS.ACTION.PRIMARY}`,
      outline: 'none',
    },
  },
  errorLabel: {
    color: COLORS.STATUS.CRITICAL,
    fontSize: '14px',
    marginTop: 8,
  },
  spinner: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    borderTop: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderBottom: `3px solid ${COLORS.DIVIDER}`,
    borderLeft: `3px solid ${COLORS.DIVIDER}`,
    animation: '$spin 0.5s infinite linear',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  modalTitleStyles: {
    fontWeight: '500',
    lineHeight: '120%',
    color: COLORS.TEXT.PRIMARY,
  },
  modalBodyStyles: {
    width: 504,
    padding: '0 0 36px 0',

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
    },
  },
  submitButton: {
    [BREAKPOINTS.MOBILE]: {
      width: 290,
    },
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: '14px',
    alignItems: 'center',
    columnGap: 10,
    '& svg': {
      width: 18,
      height: 18,
      fill: COLORS.WHITE,
    },
  },
  buttonText: {
    textAlign: 'center',
    margin: 0,
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 33,

    [BREAKPOINTS.MOBILE]: {
      flexDirection: 'column',
      alignItems: 'start',
      rowGap: 16,
    },
  },
  helpInfo: {
    display: 'flex',
    alignItems: 'center',
    columnGap: 10,
    '& svg': {
      fill: COLORS.TEXT.SECONDARY,
    },
  },
  helpInfoText: {
    width: 250,
    fontSize: '14px',
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },
  eopBlock: {
    marginBottom: 48,
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
});
