import { RootState } from '@modules/App/redux/store';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserInfo } from '@shared/interfaces/UserInfo';

export const selectUsersSearchData = (state: RootState): AsyncData<UserInfo[]> => state.users.searchData;
export const selectIsGlobalUsersSearchOpened = (state: RootState): boolean => state.users.isGlobalSearchOverlayOpen;
export const selectToms = (state: RootState): AsyncData<UserInfo[]> => state.users.toms;
export const selectPrs = (state: RootState): AsyncData<UserInfo[]> => state.users.prs;
export const selectUsersWhomICanCreateFlag = (state: RootState): AsyncData<UserInfo[]> =>
  state.users.usersWhomICanCreateFlag;
