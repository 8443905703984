import * as Yup from 'yup';

const EMPTY_FIELD_ERROR_TEXT = 'required field';

const fieldsSchema = {
  firstName: Yup.string().trim().required(EMPTY_FIELD_ERROR_TEXT),
  lastName: Yup.string().trim().required(EMPTY_FIELD_ERROR_TEXT),
  title: Yup.string().trim().required(EMPTY_FIELD_ERROR_TEXT),
  customTitle: Yup.string().when('title', { is: 'Other', then: Yup.string().trim().required(EMPTY_FIELD_ERROR_TEXT) }),
  emailAeTool: Yup.string().trim().email('must be a valid email').required(EMPTY_FIELD_ERROR_TEXT),
  location: Yup.string().trim().required(EMPTY_FIELD_ERROR_TEXT),
  locationArea: Yup.string().trim().required(EMPTY_FIELD_ERROR_TEXT),
};

export const validationSchema = Yup.object().shape(fieldsSchema);
