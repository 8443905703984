import { ClientEvalData } from '@modules/EvaluationModule/interfaces/ClientEvalData';
import { paths } from '@shared/enums/paths';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getClientEvalData } from '../redux/actions';
import { setClientEvalDataLoading } from '../redux/reducers';
import { autoSaveClientEvalForm, setClientEvalData } from '../redux/actions';
import { DataState } from '@shared/enums/DataState';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { selectClientEvalData } from '../redux/selectors';

export const useClientEvalData = (): AsyncData<ClientEvalData> => {
  const clientEvalData = useSelector(selectClientEvalData);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { feedbackId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!feedbackId) {
      navigate(paths.root);

      return;
    }

    dispatch(getClientEvalData(feedbackId));
    return () => {
      dispatch(setClientEvalDataLoading());
    };
  }, [feedbackId]);

  useEffect(() => {
    const data = clientEvalData.data;
    const evaluation = data?.evaluation;
    const markFromParams = searchParams.get('mark') as EvaluationMark;
    if (evaluation && evaluation.qualityOfWork.mark) {
      return;
    }
    if (evaluation && markFromParams && feedbackId) {
      if (!Object.values(EvaluationMark).some((mark) => mark === markFromParams)) {
        dispatch(
          displayNotification(
            'Invalid value for quality of work provided, please select one of the options above manually.'
          )
        );
        setSearchParams('');
        return;
      }
      const newQualityOfWork = {
        ...evaluation,
        qualityOfWork: { ...evaluation.qualityOfWork, mark: markFromParams },
      };
      dispatch(
        autoSaveClientEvalForm({
          feedbackId,
          evaluation: newQualityOfWork,
        })
      );
      dispatch(
        setClientEvalData({
          data: {
            ...data,
            evaluation: newQualityOfWork,
          },
          state: DataState.Fulfilled,
        })
      );
      setSearchParams('');
    }
  }, [clientEvalData, feedbackId]);

  return clientEvalData;
};
