import React, { UIEvent, useCallback, useEffect, useRef, useState } from 'react';
import throttle from 'lodash/throttle';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import { useDispatch } from 'react-redux';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { useStyles } from './ChatPage.styles';
import { useChatData } from './hooks/useChatData';
import { ChatMessageBlock } from '@modules/HappinessModule/components/ChatMessageBlock/ChatMessageBlock';
import { ChatMessage } from '@modules/HappinessModule/interfaces/ChatMessage';
import { ChatQuestionVoteBlock } from './components/ChatQuestionVoteBlock';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { InputMessageField } from './components/InputMessageField/InputMessageField';
import { getChatData, setChatData } from './redux/actions';
import { ROCKY_CHAT_MESSAGES_LIMIT } from './constants/Pagination';
import { pageTitles } from '@shared/enums/pageTitles';
import { useTitle } from '@shared/hooks/useTitle';
import { ChatHeader } from './components/ChatHeader/ChatHeader';
import { useAuth } from '@shared/hooks/useAuth';
import { setIsFeedbackOpen } from '@modules/App/redux/feedback';
import { initialValuesContactPR } from '@shared/components/Sidebar/components/Feedback/constants/feedbackInitialValues';
import { getIsSurveyExpired } from './helpers/helpers';

const DISTANCE_TO_THE_CHAT_TOP_PX = 2000;

export const ChatPage: React.FC = () => {
  const { chatData, reaction: reactionFromMail } = useChatData();
  const isFailed = chatData.state === DataState.Rejected;
  const isDataLoading = chatData.state === DataState.Pending;
  const chatContainterRef = useRef<HTMLDivElement | null>(null);
  const [latestSurvey, setlatestSurvey] = useState<ChatMessage | undefined>(undefined);
  const [isRevote, setIsRevote] = useState(false);
  const styles = useStyles();
  const dispatch = useDispatch();
  const setPageTitle = useTitle();
  const [totalMsgCountCheck, setMsgCountCheck] = useState(0);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [currentReaction, setCurrentReaction] = useState<null | undefined | number>();
  const [isSurveyExpired, setIsSurveyExpired] = useState(false);
  const { currentUser } = useAuth();

  useEffect(
    () => () => {
      dispatch(setChatData({ data: [], state: DataState.Pending }));
    },
    []
  );

  let counter = 0;

  const dateArr = chatData.data?.reduce((acc: string[], el: ChatMessage) => {
    const messageDate = format(new Date(el.ts), 'yyyy MMMM dd');

    if (!acc.includes(messageDate)) {
      acc.push(messageDate);
    }
    return acc;
  }, []);

  const scrollHandlerThrottled = throttle(() => {
    if (
      chatData.data &&
      chatData.data.length &&
      chatData.data.length >= ROCKY_CHAT_MESSAGES_LIMIT &&
      chatData.data.length >= totalMsgCountCheck
    ) {
      dispatch(getChatData({ limit: ROCKY_CHAT_MESSAGES_LIMIT, offset: chatData.data.length }));
      setMsgCountCheck(totalMsgCountCheck + ROCKY_CHAT_MESSAGES_LIMIT);
      setScrollToBottom(false);
    }
  }, 100);

  const scrollPageToBottom = useCallback(() => {
    setScrollToBottom(true);
  }, [scrollToBottom]);

  useEffect(() => {
    if (chatContainterRef.current && scrollToBottom) {
      chatContainterRef.current.scrollTo({ top: chatContainterRef.current.scrollHeight });
    }
    const lastVote = chatData.data?.filter(({ reaction }) => reaction).pop();
    setCurrentReaction(lastVote?.reaction);
    const lastRevote = chatData.data?.filter(({ reaction }) => reaction || reaction === null).pop();
    setlatestSurvey(lastRevote);
    const surveyExpirationDate = lastRevote && lastRevote.expirationDate ? parseISO(lastRevote.expirationDate) : null;
    const isSurveyExpired = getIsSurveyExpired(surveyExpirationDate);
    setIsSurveyExpired(isSurveyExpired);
    setIsRevote(lastRevote?.reaction !== null && !!lastRevote?.isRevotable);
  }, [chatData.data]);

  useEffect(() => {
    setPageTitle(pageTitles.chatHappinessPage);
  }, []);

  const openFeedback = () => {
    dispatch(setIsFeedbackOpen({ isOpen: true, initialValues: initialValuesContactPR }));
  };

  return isFailed ? (
    <DataErrorView error={chatData.error} />
  ) : (
    <div className={styles.root}>
      <ChatHeader
        isRevote={isRevote}
        latestSurvey={latestSurvey}
        isLoading={chatData.state === DataState.Pending}
        user={currentUser}
        reaction={currentReaction}
        scrollToBottom={scrollPageToBottom}
      />
      <div className={styles.backgroundGradient}></div>
      <div className={styles.backgroundPattern}></div>
      <div className={styles.content}>
        <div className={styles.chat}>
          <div
            className={styles.messagesContainer}
            ref={chatContainterRef}
            onScroll={(e: UIEvent<HTMLDivElement>) =>
              e.currentTarget.scrollTop < DISTANCE_TO_THE_CHAT_TOP_PX && scrollHandlerThrottled()
            }
          >
            {isDataLoading ? (
              <div className={styles.spinner}></div>
            ) : (
              dateArr?.map((dateStr) => {
                counter = 0;

                return (
                  <div className={styles.messagesHolder} key={dateStr}>
                    <div className={styles.dateContainer}>
                      <span className={styles.dateLabel}>{dateStr}</span>

                      {chatData.data?.map((message, index, messages) => {
                        const { id, ts, reaction, author } = message;
                        const messageDate = format(new Date(ts), 'yyyy MMMM dd');

                        if (messages[index === 0 ? 0 : index - 1].author !== author) {
                          counter = 0;
                        }

                        if (messageDate === dateStr) {
                          counter++;
                        }

                        return messageDate === dateStr ? (
                          <div key={id}>
                            {reaction !== undefined ? (
                              <ChatQuestionVoteBlock
                                message={message}
                                latestSurvey={message}
                                shouldLaunchEmojisplosion={!isSurveyExpired && reactionFromMail !== 0}
                              />
                            ) : (
                              <ChatMessageBlock message={message} hasTail={counter === 1} />
                            )}
                          </div>
                        ) : null;
                      })}
                    </div>
                  </div>
                );
              })
            )}
          </div>

          <Stack direction="row" className={styles.buttonsContainer}>
            <Button className="button" onClick={openFeedback}>
              I need HR team support
              <HelpOutlineIcon color="secondary" fontSize="small" sx={{ marginLeft: 1 }} />
            </Button>
          </Stack>
          <div className={styles.inputField}>
            <InputMessageField scrollToBottom={scrollPageToBottom} user={currentUser} />
          </div>
        </div>
      </div>
    </div>
  );
};
