import { FC, memo, useEffect } from 'react';
import { OrganizationalChart } from './components/OrganizationalChart';
import { useOrgChartData } from './hooks/useOrgChartData';
import { useStyles } from './OrgChartPage.styles';
import { DataState } from '@shared/enums/DataState';
import { RocketLoader } from '@shared/components/RocketLoader';
import * as Sentry from '@sentry/react';
import { ErrorView } from '../ErrorView';
import { logEvent } from '@shared/helpers/analytics/analytics';
import { RockyAnalyticsEvent } from '@shared/enums/rockyAnalyticsEvent';

const OrgChartPageComponent: FC = () => {
  const styles = useStyles();

  useEffect(() => {
    logEvent(RockyAnalyticsEvent.OrgChartPageVisit);
  }, []);

  const { initialOrgChartData, sortedAndFilteredOrgChartData, initiallySelectedNodeId, hiddenNodes } =
    useOrgChartData();

  return (
    <div className={styles.root}>
      <div className={styles.backgroundGradient}></div>
      <div className={styles.backgroundPattern}></div>
      <div className={styles.orgChartHolder}>
        {initialOrgChartData.state === DataState.Pending ? (
          <div className={styles.loaderHolder}>
            <RocketLoader />
          </div>
        ) : (
          initialOrgChartData.data && (
            <Sentry.ErrorBoundary
              fallback={<ErrorView component errorTextSecondary={'while building the organizational chart'} />}
            >
              <OrganizationalChart
                initiallySelectedNodeId={initiallySelectedNodeId}
                orgChartData={sortedAndFilteredOrgChartData}
                initialOrgChartData={initialOrgChartData.data}
                hiddenNodes={hiddenNodes}
              />
            </Sentry.ErrorBoundary>
          )
        )}
      </div>
    </div>
  );
};

export const OrgChartPage = memo(OrgChartPageComponent);
