import { FC } from 'react';
import { Box, Button, Typography } from '@mui/material';
import format from 'date-fns/format';
import { useStyles } from '../CommunicationModal.styles';
import { Props as CommunicationModalProps } from '../CommunicationModal';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { useFormikContext } from 'formik';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { addFlagDataStateSelector } from '@modules/HappinessModule/pages/FlagPage/pages/CreateFlagFormPage/redux/selectors';
import { useSelector } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { DataState } from '@shared/enums/DataState';
import { NewHomeEmailSentStatus } from '@modules/HappinessModule/enums/NewHomeEmailSentStatus';

const ReminderMessage: FC<Omit<CommunicationModalProps, 'step' | 'isOpen' | 'userFlagPermissions' | 'setStep'>> = ({
  userInfo,
  onClose,
  isNewFlag,
  saveFlagWithSettedNhEmailStatus,
}) => {
  const styles = useStyles();
  const { values } = useFormikContext<FlagFields>();
  const lastDayOnTheProject = `last day on the project${
    values.lastDayOnTheProject ? ' ' + format(new Date(values.lastDayOnTheProject as string), 'PPP') : ''
  }`;

  const addFlagDataState = useSelector(addFlagDataStateSelector);

  return (
    <Box textAlign="center">
      <img src={ImageUrls.Time} />
      {isNewFlag && (
        <Typography variant="h3" marginBottom="13px">
          The flag remains confidential{' '}
        </Typography>
      )}

      {isNewFlag ? (
        <Typography padding="0px 40px">
          Be sure to communicate the NewHome activity with {userInfo.name} before the {lastDayOnTheProject} and update
          the flag’s “Confidential” checkbox accordingly.
        </Typography>
      ) : (
        <Typography variant="subtitle1">
          Ehmm, ok. Be sure to communicate the NewHome activity with {userInfo.name}{' '}
          <span className={styles.lastDayReminder}>before the {lastDayOnTheProject}</span>
        </Typography>
      )}
      <Box marginTop={5} className={styles.buttonsContainer}>
        {isNewFlag && <Button onClick={onClose}>Cancel</Button>}
        <LoadingButton
          loading={addFlagDataState.loadingState === DataState.Pending}
          type="button"
          variant="contained"
          onClick={isNewFlag ? () => saveFlagWithSettedNhEmailStatus(NewHomeEmailSentStatus.NotRequested) : onClose}
        >
          {isNewFlag ? 'Create flag' : 'Done'}
        </LoadingButton>
      </Box>
    </Box>
  );
};

export default ReminderMessage;
