import httpClient from '@shared/api/httpClient';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { UserByEvalIdData } from '../interfaces/UserByEvalIdData';
import { EvalAndFeedbackId } from '../interfaces/EvalAndFeedbackId';
import { ClientSuggestionResponseData, SuggestedPeersRaw } from './types';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { ClientSuggestionData } from '../interfaces/ClientSuggestionData';
import { ClientByEvalIdData } from '../interfaces/ClientByEvalIdData';
import { PeerSuggestions } from '../interfaces/PeerSuggestions';

export const addPeerFeedback = (peer: UserByEvalIdData): Promise<string> =>
  httpClient
    .post(`/evaluations/${peer.evalId}/peer-feedbacks/`, { user_id: peer.user.id })
    .then(({ data }) => data.data.id);

export const deletePeerFeedback = (peer: EvalAndFeedbackId): Promise<UserInfo> =>
  httpClient
    .delete<UserInfo>(`/evaluations/${peer.evalId}/peer-feedbacks/${peer.feedbackId}/`)
    .then(({ data }) => data);

export const getClientsSuggestions = (evalId: string): Promise<ClientSuggestionData[]> =>
  httpClient.get<{ data: ClientSuggestionResponseData[] }>(`/evaluations/${evalId}/client-contacts/`).then((resp) =>
    resp.data.data.reduce((acc, project) => {
      const workstreams = project.workstreams.reduce((acc, workstream) => {
        const clients = {
          name: workstream.name,
          contacts: workstream.contacts
            .filter((client) => client.first_name || client.last_name)
            .map((client) => ({
              name:
                client.first_name && client.last_name
                  ? `${client.first_name} ${client.last_name}`
                  : client.first_name ?? client.last_name,
              email: client.email_ae,
              position: client.title,
              imageUrl: ImageUrls.Client,
              id: client.zoho_id,
              doNotTouch: client.do_not_touch,
            })),
        };
        return [...acc, clients];
      }, [] as ClientSuggestionData[]);
      return [...acc, ...workstreams];
    }, [] as ClientSuggestionData[])
  );

export const getPeerSuggestions = (evalId: string): Promise<PeerSuggestions> =>
  httpClient.get<{ data: SuggestedPeersRaw }>(`/evaluations/${evalId}/suggested-peers/`).then(({ data: { data } }) => {
    const suggestedPeers = data.suggested_peer_reviewers.map(
      ({ full_name, project_title, imageUrl, id, city, country }) => ({
        name: full_name,
        position: project_title,
        imageUrl,
        id,
        city,
        country,
      })
    );

    const prevEvalPeers = data.previous_evaluation_peer_reviewers.map(
      ({ full_name, project_title, imageUrl, id, city, country }) => ({
        name: full_name,
        position: project_title,
        imageUrl,
        id,
        city,
        country,
      })
    );

    return { suggestedPeers, prevEvalPeers };
  });

export const addClientToReviewers = ({ evalId, client }: ClientByEvalIdData): Promise<string> =>
  httpClient
    .post(`/evaluations/${evalId}/client-feedback/`, {
      first_name: client.name.split(' ')[0],
      last_name: client.name.split(' ')[1],
      email: client.email,
      title: client.position,
      zoho_id: client.id,
      do_not_touch: client.doNotTouch,
    })
    .then(({ data }) => data.data.id);

export const deleteClientFeedback = (peer: EvalAndFeedbackId): Promise<UserInfo> =>
  httpClient
    .delete<UserInfo>(`/evaluations/${peer.evalId}/client-feedback/${peer.feedbackId}/`)
    .then(({ data }) => data);
