import { FlagData } from '@modules/HappinessModule/interfaces/FlagData';
import { FlagDataResponse } from '../types';
import { flagUserMapFunc } from './flagUserMapFunc';
import { getFlagsPermissions } from './getFlagsPermissions';
import { FlagWatcherRole } from '@modules/HappinessModule/pages/FlagPage/enums/flagWatcherRole';

const mapFlagWatchers = (flagWatchers: FlagDataResponse['flagWatchers']) => {
  if (!flagWatchers) {
    return [];
  }
  const flgaWatchersPermissions = getFlagsPermissions(flagWatchers.permissions);

  return flagWatchers.users.map((user) => ({
    user,
    permissions: flgaWatchersPermissions,
    flagWatcherRole: FlagWatcherRole.Watcher,
  }));
};

export const flagDataMapFunc = (flagData: FlagDataResponse): FlagData => {
  const { id, createdAt, resolvedAt, user, flagChangeEvents: flagChangeEventsUnsorted } = flagData;

  const flagChangeEventsSorted = flagChangeEventsUnsorted?.sort((ev1, ev2) => (ev2.date > ev1.date ? 1 : -1));

  const mappedFlagData = {
    id,
    user: flagUserMapFunc(user),
    author: flagUserMapFunc(flagData.author),
    createdAt,
    resolvedAt,
    flagChangeEvents: flagChangeEventsSorted,
    flagFields: {
      ...flagData,
      secondaryTriggerType: flagData.secondaryTriggerType,
      emailRecipients: (flagData.emailRecipients ?? []).map((recipient) => ({
        ...recipient,
        user: recipient.user ? flagUserMapFunc(recipient.user) : null,
      })),
      assignedTom: flagData.assignedTom ? flagUserMapFunc(flagData.assignedTom) : null,
      assignedPR: flagData.assignedPR ? flagUserMapFunc(flagData.assignedPR) : null,
      flagWatchers: mapFlagWatchers(flagData.flagWatchers),
      departments: flagData.departments ?? [],
    },
  };

  return mappedFlagData;
};
