import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100%',
  },
  usersHappinessTable: {
    height: '100%',
    width: '100%',
  },
  tableHeaderRow: {
    zIndex: 1,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },

    '& td:nth-child(1)': {
      paddingLeft: 24,
      minWidth: 250,
      maxWidth: 300,
      width: '25%',
    },
    '& td:nth-child(2)': {
      width: 'auto',
    },
    '& td:nth-child(3)': {
      width: 100,

      '& div': {
        justifyContent: 'center',
      },
    },
    '& td:nth-child(4)': {
      width: 150,
    },
  },
  tableDataRow: {
    height: 76,

    '& td:nth-child(3)': {
      textAlign: 'center',
    },
    '& td:nth-child(4)': {
      textAlign: 'left',
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'grid',
      height: 'auto',
      backgroundColor: COLORS.WHITE,
      borderRadius: 8,
      boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
      padding: 16,
      width: '98%',
      marginBottom: 4,

      gridTemplateColumns: 'auto max-content',
      gridTemplateRows: 'auto auto auto',
      gap: '0px 8px',
      gridTemplateAreas: `
        "member ratetext"
        "rateline rateline"
        "actions actions"`,

      '& td:nth-child(1)': {
        gridArea: 'member',
      },
      '& td:nth-child(2)': {
        gridArea: 'rateline',
      },
      '& td:nth-child(3)': {
        gridArea: 'ratetext',
        display: 'flex',
        alignItems: 'center',
        gap: 4,
      },
      '& td:nth-child(4)': {
        gridArea: 'actions',
      },
    },
  },
  rateHolder: {
    maxWidth: 600,
    [BREAKPOINTS.MOBILE]: {
      margin: '-24px 0 -24px 7px',
    },
  },
  resolveContainer: {
    position: 'relative',
  },
  personName: {
    fontSize: '14px',
    fontWeight: '400',
    margin: 0,
    textDecoration: 'none',
  },
  team: {
    fontSize: '13px',
    fontWeight: '400',
    lineHeight: '120%',
    color: COLORS.TEXT.SECONDARY,
    margin: '2px 0 0',
  },
  markDelta: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    margin: 0,
  },
  mobileLabel: {
    fontSize: '14px',
    fontWeight: '400',
    color: COLORS.TEXT.SECONDARY,
    display: 'none',

    [BREAKPOINTS.MOBILE]: {
      display: 'inline',
    },
  },
  personBlock: {
    cursor: 'pointer',
    display: 'inline-flex',
    textDecoration: 'none',
    color: COLORS.TEXT.PRIMARY,
    margin: '0 12px',
    padding: '12px 0 12px 12px',
    gap: 16,
    borderRadius: 8,

    [BREAKPOINTS.MOBILE]: {
      padding: 0,
      margin: 0,
      gap: 8,
    },

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,

      '& > div:first-child': {
        transform: 'scale(1.05)',
      },
    },
  },
  personInfoBlock: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  personAvatar: {
    width: 40,
    height: 40,
    borderRadius: 12,
    objectFit: 'cover',
  },
  resolvePersonAvatar: {
    display: 'block',
    width: 40,
    height: 40,
    borderRadius: 12,
    objectFit: 'cover',
  },
  personBlockSkeleton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'stretch',
    marginLeft: 24,
    gap: 18,
  },
  personInfoSkeleton: {
    flexGrow: 1,
    gap: 2,
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'stretch',
  },
  cellSkeleton: {
    width: '80%',
    height: 30,
  },
  meterSkeleton: {
    width: '100%',
    maxWidth: 600,
    height: 10,
  },
  textSkeleton: {
    width: '80%',
    height: 10,
  },
  changeDesktop: {
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  changeMobile: {
    display: 'none',

    [BREAKPOINTS.MOBILE]: {
      display: 'inline',
    },
  },
  sortHolder: {
    display: 'none',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      display: 'flex',
      paddingLeft: 16,
      flexDirection: 'row',
      justifyContent: 'flex-start',
      background: COLORS.WHITE,
    },
  },
  secondaryReporterLabel: {
    color: COLORS.TEXT.SECONDARY,
  },
  noContentBlock: {
    textAlign: 'center',
    color: COLORS.SCALEEVAL.CANT,
    fontWeight: 400,
    fontSize: 24,
    lineHeight: '140%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: 130,
  },
});
