import { createAction } from '@reduxjs/toolkit';
import { AsyncData } from 'shared/interfaces/asyncData';
import { SurveyQueue } from '../interfaces';
import {
  CreateCategoryPayload,
  CreateQuestionPayload,
  SetCategoryNamePayload,
  SetQuestionTextPayload,
  SetSurveyCategoryOrderPayload,
  SetSurveyQuestionOrderPayload,
} from './types';

export const getSurveyQueue = createAction<void>('surveyManagementPage/getSurveyQueue');
export const runSurvey = createAction<void>('surveyManagementPage/runSurvey');
export const setSurveyQueue = createAction<AsyncData<SurveyQueue>>('surveyManagementPage/setSurveyQueue');
export const setSurveyQueueLoading = createAction<void>('surveyManagementPage/setSurveyQueueLoading');
export const setSurveyQuestionOrder = createAction<SetSurveyQuestionOrderPayload>(
  'surveyManagementPage/setSurveyQuestionOrder'
);
export const setSurveyCategoryOrder = createAction<SetSurveyCategoryOrderPayload>(
  'surveyManagementPage/setSurveyCategoryOrder'
);
export const setCategoryName = createAction<SetCategoryNamePayload>('surveyManagementPage/setCategoryName');
export const setQuestionText = createAction<SetQuestionTextPayload>('surveyManagementPage/setQuestionText');
export const deleteCategory = createAction<string>('surveyManagementPage/deleteCategory');
export const deleteQuestion = createAction<string>('surveyManagementPage/deleteQuestion');
export const createCategory = createAction<CreateCategoryPayload>('surveyManagementPage/createCategory');
export const createQuestion = createAction<CreateQuestionPayload>('surveyManagementPage/createQuestion');
