import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Stack } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import { useStyles } from './ClientSurveyResponsePage.styles';
import { ReactComponent as RockyLogo } from '@assets/icons/RockyLogo.svg';
import { RockyButton } from '@shared/components/RockyButton';
import { revokeClientSurveyResponse, updateClientSurveyResponse } from './redux/actions';
import { DataState } from '@shared/enums/DataState';
import { useClientSurveyResponsePageData } from './hooks/useClientSurveyResponsePageData';
import { RockyVoteBlock } from '@shared/components/RockyVoteBlock';
import { DataErrorView } from '@shared/components/DataErrorView';
import { ReactComponent as Spinner } from '@assets/icons/Spinner.svg';
import { Textarea } from '@shared/components/Textarea';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { FeedBackText } from '@modules/HappinessModule/pages/ClientSurveyResponsePage/components/FeedBackTextComponent/FeedBackTextComponent';
import { ClientSurveyResponseDmNotes } from './components/ClientSurveyResponseDmNotes';
import { logEvent } from '@shared/helpers/analytics/analytics';
import { RockyAnalyticsEvent } from '@shared/enums/rockyAnalyticsEvent';

export const ClientSurveyResponsePage: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { clientSurveyResponse, whatIsEditedInClientSurveyResponse } = useClientSurveyResponsePageData();
  const clientSurveyResponseData = clientSurveyResponse.data;
  const [isResponseFinished, setIsResponseFinished] = useState(Boolean(clientSurveyResponseData?.score));
  const [score, setScore] = useState<number | null>(clientSurveyResponseData?.score || null);
  const [commentValue, setCommentValue] = useState(clientSurveyResponseData?.comment);
  const [isScoreFromUrlCounted, setIsScoreFromUrlCounted] = useState(false);

  const [searchParams, setSearchParams] = useSearchParams();
  const [scoreFromUrl] = useState(Number(searchParams.get('score')));

  const isClientSurveyResponseRejected = clientSurveyResponse.state === DataState.Rejected;
  const isClientSurveyResponseLoading = clientSurveyResponse.state === DataState.Pending;

  const scoreState = whatIsEditedInClientSurveyResponse.scoreState;
  const commentState = whatIsEditedInClientSurveyResponse.commentState;
  const revokeState = whatIsEditedInClientSurveyResponse.revokeState;
  const isScoreChanging = scoreState === DataState.Pending;
  const isRevoking = revokeState === DataState.Pending;

  const [isVisitEventSent, setIsVisitEventSent] = useState(false);

  useEffect(() => {
    setCommentValue(clientSurveyResponseData?.comment);
    setScore(clientSurveyResponseData?.score || null);
    setIsResponseFinished(Boolean(clientSurveyResponseData?.score));
  }, [clientSurveyResponseData?.comment && clientSurveyResponseData?.score]);

  useEffect(() => {
    if (!clientSurveyResponse.data || !scoreFromUrl || isScoreFromUrlCounted) {
      return;
    }
    if (clientSurveyResponse.data.score === null) {
      setScore(scoreFromUrl);
      setIsScoreFromUrlCounted(true);
    } else if (clientSurveyResponse.data.score && !isScoreFromUrlCounted) {
      dispatch(displayNotification('Score already counted'));
    }
  }, [clientSurveyResponse, scoreFromUrl, isScoreFromUrlCounted]);

  useEffect(() => {
    setSearchParams('');
  }, [searchParams]);

  useEffect(() => {
    if (!isResponseFinished) addEventListener('beforeunload', beforeUnloadListener);
    else removeEventListener('beforeunload', beforeUnloadListener);
  }, [isResponseFinished]);

  const onChangeComment = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setCommentValue(e.target.value);
  };

  const onChangeVoteResponse = (): void => {
    setIsResponseFinished(false);
    dispatch(revokeClientSurveyResponse());
  };

  const mutalEventInto = clientSurveyResponseData
    ? {
        surveyQuestion: clientSurveyResponseData.surveyText,
        clientContactName: clientSurveyResponseData.contact.name,
        clientContactTitle: clientSurveyResponseData.contact.position,
        clientAccountName: clientSurveyResponseData.contact.departmentName,
        clientResponseId: clientSurveyResponseData.id,
      }
    : null;

  const onFinishSurveyResponse = () => {
    dispatch(
      updateClientSurveyResponse({
        score,
        comment: commentValue || '',
      })
    );

    logEvent(RockyAnalyticsEvent.CsatSurveySubmit, { ...mutalEventInto, score });

    setCommentValue('');
  };

  useEffect(() => {
    if (!isVisitEventSent && clientSurveyResponseData && clientSurveyResponse.state === DataState.Fulfilled) {
      logEvent(RockyAnalyticsEvent.CsatPageVisit, {
        ...mutalEventInto,
        scoreFromUrl: scoreFromUrl || null,
      });
      setIsVisitEventSent(true);
    }
  }, [scoreFromUrl, clientSurveyResponse, isVisitEventSent]);

  return (
    <div className={styles.root}>
      <div>
        <div className={styles.logo}>
          <RockyLogo />
          <div>AgileEngine</div>
        </div>
      </div>
      {isClientSurveyResponseRejected ? (
        <DataErrorView error={clientSurveyResponse.error} />
      ) : (
        <div className={styles.body}>
          <div className={styles.card}>
            <div className={styles.cardBody}>
              <RockyVoteBlock
                isDisabled={isResponseFinished}
                isWithEmojiExplosion
                isScoreFromUrl={isScoreFromUrlCounted}
                survey={clientSurveyResponseData?.surveyText ?? null}
                description={clientSurveyResponseData?.surveyDescription}
                score={score}
                onClick={(score) => {
                  setScore(score);
                }}
                isScoreChanging={isScoreChanging}
                isLoading={isClientSurveyResponseLoading}
                isScoreSuccessfullyChanged={scoreState === DataState.Fulfilled}
              />

              <div className={styles.commentContainer}>
                <Textarea
                  className={styles.textarea}
                  placeholder="Optional: What improvement points or notes would you like to add?"
                  maxLength={4096}
                  value={commentValue || ''}
                  onChange={onChangeComment}
                  disabled={isResponseFinished}
                />
              </div>

              {isResponseFinished ? (
                <Stack direction="row" alignItems="center">
                  <p className={styles.finalText}>Thank you, we’ll keep in touch 🌟</p>
                  {isScoreChanging ? (
                    <Spinner />
                  ) : (
                    Boolean(clientSurveyResponseData?.score) &&
                    clientSurveyResponseData?.canRevoke && (
                      <div className={styles.revokeLink} onClick={onChangeVoteResponse}>
                        Change your response
                        {isRevoking && (
                          <span>
                            <Spinner />
                          </span>
                        )}
                      </div>
                    )
                  )}
                </Stack>
              ) : (
                <div>
                  <RockyButton
                    isLoading={commentState === DataState.Pending}
                    action
                    onClick={onFinishSurveyResponse}
                    className={styles.addComentBtn}
                    disabled={score === null}
                  >
                    Submit
                  </RockyButton>
                </div>
              )}

              <Box marginTop={5}>
                <FeedBackText email={clientSurveyResponseData?.pdm?.email} />
              </Box>
            </div>

            <div className={styles.bottomGradient}></div>
          </div>
        </div>
      )}
      {clientSurveyResponseData?.dmNotesData && (
        <ClientSurveyResponseDmNotes {...clientSurveyResponseData.dmNotesData} />
      )}
    </div>
  );
};

const beforeUnloadListener = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  return (event.returnValue = 'Changes you made may not be saved.');
};
