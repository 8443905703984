import { apiUrlNode } from '@configs/environment';
import httpNodeClient from '@shared/api/httpNodeClient';
import { FlagListData } from '../interfaces/FlagListData';
import { FlagDataResponse, FlagSnapshotDataResponse } from './types';
import { FlagData } from '../interfaces/FlagData';
import { ResponseListFlagData } from './types';
import { UserFlagCcDetails } from '../interfaces/UserFlagCcDetails';
import { FlagSnapshotPayloadData } from '../interfaces/FlagSnapshotPayloadData';
import { flagsListDataMapFunc } from './helpers/flagsListDataMapFunc';
import { FlagSnapshotData } from '../interfaces/FlagSnapshotData';
import { RaiseFlagData } from '../interfaces/RaiseFlagData';
import { FlagPermissionsData } from '../pages/FlagPage/interfaces/FlagPermissionsData';
import { AllAvailableFlagPermissions } from '../pages/FlagPage/enums/flagPermissionsData';
import { flagDataMapFunc } from './helpers/flagDataMapFunc';
import { getFlagsPermissions } from './helpers/getFlagsPermissions';
import { AutomaticFlagWatchers, AutomaticFlagWatchersResponse } from '../interfaces/FlagWatcherData';
import { AutomaticFlagWatchersGroups } from '../pages/FlagPage/enums/automaticFlagWatchersGroups';
import { FlagWatcherRole } from '../pages/FlagPage/enums/flagWatcherRole';
import { DataWithTotalCount } from '@shared/interfaces/DataWithTotalCount';

export const raiseFlag = (payload: RaiseFlagData): Promise<void> =>
  httpNodeClient.post<void>('flags', payload).then(({ data }) => data);

export const fetchMyTeamFlagsData = (): Promise<FlagListData[]> =>
  httpNodeClient
    .get<DataWithTotalCount<ResponseListFlagData>>('flags', {
      params: { scope: 'myPrimaryReporters,mySecondaryReporters' },
    })
    .then((resp) => flagsListDataMapFunc(resp.data.items));

export const fetchUserFlagsData = (userId: string): Promise<FlagListData[]> =>
  httpNodeClient
    .get<DataWithTotalCount<ResponseListFlagData>>('flags', {
      params: {
        userIds: userId,
      },
    })
    .then((resp) => flagsListDataMapFunc(resp.data.items));

export const fetchFlagData = (flagId: string): Promise<FlagData> =>
  httpNodeClient.get<FlagDataResponse>(`${apiUrlNode}/flags/${flagId}`).then((resp) => flagDataMapFunc(resp.data));

export const fetchFlagSnapshot = ({ snapshotId }: FlagSnapshotPayloadData): Promise<FlagSnapshotData> =>
  httpNodeClient.get<FlagSnapshotDataResponse>(`${apiUrlNode}/flag-events/${snapshotId}`).then((resp) => ({
    ...resp.data,
    flagSnapshot: flagDataMapFunc({ ...resp.data.flagSnapshot, author: resp.data.author }),
  }));

export const getUserPermissionToModifyFlag = (flagId: string): Promise<FlagPermissionsData> =>
  httpNodeClient.get<AllAvailableFlagPermissions[]>(`${apiUrlNode}/flags/${flagId}/permissions`).then(({ data }) => {
    return getFlagsPermissions(data);
  });

export const Edit = ({ fields, flagId }: { fields: Partial<RaiseFlagData>; flagId: string }): Promise<void> =>
  httpNodeClient.patch<void>(`${apiUrlNode}/flags/${flagId}`, fields).then(({ data }) => data);

export const flagCcDetails = (id: string): Promise<UserFlagCcDetails> =>
  httpNodeClient.get<UserFlagCcDetails>(`${apiUrlNode}/users/${id}/flag-cc-details/`).then(({ data }) => data);

export const fetchAutomaticFlagWatchers = (id: string): Promise<AutomaticFlagWatchers> =>
  httpNodeClient.get<AutomaticFlagWatchersResponse>(`/users/${id}/flag-watchers`).then(({ data }) =>
    Object.entries(data).reduce((acc, [key, value]) => {
      const permissions = getFlagsPermissions(value.permissions);
      const flagWatcherRole = AutomaticFlagWatchersGroups.SeniorManagers === key ? FlagWatcherRole.SeniorManager : null;
      const isAutoCc = [AutomaticFlagWatchersGroups.TomMembers].includes(key as AutomaticFlagWatchersGroups);
      const isNotBeingNotified = [AutomaticFlagWatchersGroups.Hr, AutomaticFlagWatchersGroups.HrAssistants].includes(
        key as AutomaticFlagWatchersGroups
      );

      acc[key] = value.users.map((user) => ({
        user,
        permissions,
        canBeRemoved: false,
        flagWatcherRole: user.isDeliveryManager ? FlagWatcherRole.DeliveryManager : flagWatcherRole,
        isNotBeingNotified,
        isAutoCc: isAutoCc || user.isDeliveryManager,
      }));

      return acc;
    }, {} as AutomaticFlagWatchers)
  );

export const reopenFlag = (id: string): Promise<void> =>
  httpNodeClient.post<void>(`${apiUrlNode}/flags/${id}/reopen`).then(({ data }) => data);
