import classNames from 'classnames';
import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useStyles, contentStyle } from './RockyAvatar.styles';
import Popup from 'reactjs-popup';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { RockyFlag } from '../RockyFlag';
import { Size } from '@shared/enums/sizes';

interface Props {
  fullName: string;
  imageUrl?: string | null;
  country?: string;
  city?: string;

  extraSmall?: boolean;
  small?: boolean;
  medium?: boolean;
  large?: boolean;
  className?: string;
  isLoading?: boolean;
}

const RockyAvatarComponent: FC<Props> = ({
  fullName,
  imageUrl,
  city,
  country = '',
  large,
  small,
  medium,
  className,
  isLoading,
}) => {
  const styles = useStyles();
  const noAvatarSrc = `https://eu.ui-avatars.com/api/?background=ffd9a3&color=000&name=${
    fullName
      ? fullName
          .split(' ')
          .filter((s) => s.length > 0)
          .splice(0, 2)
          .join('+')
      : ''
  }`;
  const [src, setSrc] = useState(imageUrl || noAvatarSrc);

  useEffect(() => {
    if (!imageUrl) {
      setSrc(noAvatarSrc);
      return;
    }
    setSrc(imageUrl);
  }, [imageUrl]);

  const avatarSizeMap = {
    extraSmall: styles.extraSmallAvatar,
    small: styles.smallAvatar,
    medium: styles.mediumAvatar,
    large: styles.largeAvatar,
  };

  const size = large ? Size.Large : small ? Size.Small : medium ? Size.Medium : Size.ExtraSmall;

  const onImageLoadError = useCallback(() => setSrc(noAvatarSrc), []);

  return (
    <>
      {isLoading ? (
        <RockySkeleton
          isLoading
          element={<div></div>}
          className={classNames(styles.avatar, { [avatarSizeMap[size]]: size }, className, styles.skeleton)}
        />
      ) : (
        <div className={styles.root}>
          <img
            className={classNames(styles.avatar, { [avatarSizeMap[size]]: size }, className)}
            src={src}
            onError={onImageLoadError}
          ></img>
          {country && (
            <Popup
              trigger={
                <span
                  className={classNames(styles.flag, {
                    [styles.largeFlag]: large,
                    [styles.smallFlag]: small || medium,
                  })}
                >
                  <RockyFlag country={country} size={size} />
                </span>
              }
              on={['hover', 'focus']}
              position="top center"
              {...{ contentStyle }}
            >
              <div>
                {city}
                {city ? ', ' : ''} {country}
              </div>
            </Popup>
          )}
        </div>
      )}
    </>
  );
};

export const RockyAvatar = memo(RockyAvatarComponent);
