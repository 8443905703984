import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { setUserEvalData } from './actions';

import { State } from './types';

const initialState: State = {
  userEvalData: { data: null, state: DataState.Pending },
  currentlyStartedEvalId: undefined,
  failingEopId: '',
};

export const slice = createSlice({
  name: 'userEvalPage',
  initialState,
  reducers: {
    setUserEvalDataLoading(state) {
      state.userEvalData = { data: null, state: DataState.Pending };
    },
    setCurrentlyStartedEvalId(state, { payload }) {
      state.currentlyStartedEvalId = payload;
    },
    setFailingEopId(state, { payload }) {
      state.failingEopId = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setUserEvalData, (state, { payload }) => {
      state.userEvalData = payload;
    });
  },
});

export const { setCurrentlyStartedEvalId, setFailingEopId } = slice.actions;

export default slice.reducer;
