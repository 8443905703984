import React, { FC, useCallback, useEffect, useState } from 'react';
import { useStyles } from './ResolveModal.styles';
import {
  Dialog,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Link,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { YesNo } from '@shared/enums/yesNo';
import { RockyButton } from '@shared/components/RockyButton';
import flagImg from '@assets/img/flag.png';
import starImg from '@assets/img/star.png';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { QuestionDataType, UserHappinessTableDataItemType } from '../UserHappinessTableActions';
import { useDispatch } from 'react-redux';
import { resolveSituationFromPeoplePage } from '@modules/HappinessModule/pages/PeopleHappinessPage/redux/actions';
import { useAuth } from '@shared/hooks/useAuth';
import { mapUserToSharedUser } from '@shared/helpers/mapUserToSharedUser';

interface Props {
  closeModal: () => void;
  openModal: () => void;
  isModalOpen: boolean;
  data: UserHappinessTableDataItemType;
  question: QuestionDataType;
}

export const ResolveModal: FC<Props> = ({ closeModal, isModalOpen, data, openModal, question }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const { name, mark, userId, id } = data;
  const [issueResolution, setIssueResolution] = useState(YesNo.Yes);
  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [notes, setNotes] = useState('');
  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  const isIssueResolved = issueResolution === YesNo.Yes;
  const isContinueDisabled = isIssueResolved && !notes;

  const resolveLowRateHappiness = useCallback(
    (id, userId) => {
      if (!currentUser) return;
      dispatch(
        resolveSituationFromPeoplePage({
          questionId: id,
          userId,
          resolvedBy: { user: mapUserToSharedUser(currentUser), notes, resolvedAt: new Date().toISOString() },
        })
      );
    },
    [currentUser, notes]
  );

  const handleClose = () => {
    setIsContinueClicked(false);
    setIssueResolution(YesNo.Yes);
    setNotes('');
    closeModal();
  };

  const handleResolve = () => {
    setIsContinueClicked(true);
    if (isIssueResolved) {
      resolveLowRateHappiness(id, userId);
    }
  };

  useEffect(() => {
    const idFromParams = searchParams.get('open');
    if (idFromParams === data.id) {
      openModal();
      setSearchParams({});
    }
  }, []);

  return (
    <Dialog open={isModalOpen} onClose={handleClose}>
      <div className={styles.root}>
        <DialogTitle className={styles.title}>
          Resolving low rate answer
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <div className={styles.content}>
          {!isContinueClicked ? (
            <>
              <Typography variant="subtitle1">Have you resolved the issue with {name.split(' ')[0]}? </Typography>
              <RadioGroup
                className={styles.radioConteiner}
                row
                value={issueResolution}
                onChange={(e) => setIssueResolution(e.target.value as YesNo)}
              >
                <FormControlLabel control={<Radio />} value={YesNo.Yes} label={YesNo.Yes} />
                <FormControlLabel control={<Radio />} value={YesNo.No} label={YesNo.No} />
              </RadioGroup>
              <Typography className={styles.helperText}>
                Please set up the resolution after having a meeting with the unhappy team member and finding out the
                reasons for the low rating, what needs to be improved and how we can help.
              </Typography>
              {isIssueResolved && (
                <TextField
                  multiline
                  rows={4}
                  fullWidth
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  label="Resolution notes *"
                  placeholder={`Add a brief summary of what you've discussed with ${name.split(' ')[0]}`}
                  sx={{
                    marginBottom: '18px',
                  }}
                />
              )}
              <div className={styles.actionBtns}>
                <RockyButton small onClick={handleClose}>
                  Cancel
                </RockyButton>
                <RockyButton disabled={isContinueDisabled} small action onClick={handleResolve}>
                  Continue
                </RockyButton>
              </div>
            </>
          ) : isContinueClicked && isIssueResolved ? (
            <>
              <img src={starImg} className={styles.image} />
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                Thank you for your help! <br /> Please update{' '}
                <Link href={`/users/${userId}/links`}>the meeting notes</Link> with the resolutions.
              </Typography>
              <RockyButton action onClick={handleClose} className={styles.doneBtn}>
                Done
              </RockyButton>
            </>
          ) : (
            <>
              <img src={flagImg} className={styles.image} />
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                Please raise a flag and HR team will offer regular support if the situation needs more attention.
              </Typography>
              <RockyButton
                action
                onClick={() =>
                  navigate(
                    `/flags/create/${userId}?mark=${mark}&date=${question?.date}&question=${question?.questionText}&id=${id}`
                  )
                }
                className={styles.doneBtn}
              >
                Raise a flag
              </RockyButton>
            </>
          )}
        </div>
      </div>
    </Dialog>
  );
};
