import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { ClientSuggestionData } from '@modules/EvaluationModule/interfaces/ClientSuggestionData';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { getClientsSuggestionsAction, getPeerSuggestionsAction } from '../redux/actions';
import { clientsSuggestionsSelector, peerSuggestionsSelector } from '../redux/selectors';
import { PeerSuggestions } from '@modules/EvaluationModule/interfaces/PeerSuggestions';

export const useEvalSettingsPageData = (): {
  clientsSuggestions: AsyncData<ClientSuggestionData[]>;
  peerSuggestions: AsyncData<PeerSuggestions>;
} => {
  const clientsSuggestions = useSelector(clientsSuggestionsSelector);
  const peerSuggestions = useSelector(peerSuggestionsSelector);
  const dispatch = useDispatch();
  const { evaluationId } = useParams();

  useEffect(() => {
    if (!evaluationId) {
      return;
    }
    if (clientsSuggestions.state === DataState.Pending) {
      dispatch(getClientsSuggestionsAction(evaluationId));
    }
    if (peerSuggestions.state === DataState.Pending) {
      dispatch(getPeerSuggestionsAction(evaluationId));
    }
  }, [evaluationId]);

  return { clientsSuggestions, peerSuggestions };
};
