import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  evaluateeBlock: {
    display: 'flex',
    alignItems: 'center',
    gap: 15,
    fontWeight: '400',
    fontSize: 24,
    lineHeight: '120%',
    color: COLORS.TEXT.PRIMARY,

    [BREAKPOINTS.MOBILE]: {
      fontSize: 18,
      gap: 8,

      '& div img': {
        margin: 0,
      },
    },
  },
  evaluateeBlockContainer: {
    width: 420,
    marginTop: 30,
    marginBottom: 40,
    display: 'flex',
    flexDirection: 'column',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
      marginTop: 16,
      marginBottom: 24,
    },
  },
  divider: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    marginLeft: -60,
    marginRight: -56,

    [BREAKPOINTS.MOBILE]: {
      marginLeft: -16,
      marginRight: -16,
    },
  },
  questionsContainer: {
    marginTop: 28,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
  },
  questionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  questionTitle: {
    width: 109,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
    margin: '0 8px 0 0',

    [BREAKPOINTS.MOBILE]: {
      width: '50%',
      fontWeight: 400,
    },
  },
  markLabel: {
    padding: '10px 15px',
    height: 40,
    borderRadius: 8,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.WHITE,
    textTransform: 'capitalize',

    [BREAKPOINTS.MOBILE]: {
      width: '50%',
    },
  },
  mainMarkLabel: {
    marginTop: 10,
    textAlign: 'center',

    [BREAKPOINTS.MOBILE]: {
      width: '100%',
    },
  },
  notesContainer: {
    marginTop: 40,
    fontWeight: '400',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,
    whiteSpace: 'pre-wrap',
    overflowWrap: 'anywhere',

    '& p': {
      fontWeight: '500',
      fontSize: '14px',
      color: COLORS.TEXT.PRIMARY,
      margin: '0 0 12px 0',
    },
  },
  closedThanksBlock: {
    lineHeight: '135%',
    marginTop: 33,
    marginLeft: -15,
    marginRight: -15,
    padding: '15px 20px',
    width: 564,
    background: COLORS.HIGHLIGHTS.PROMINENT,
    borderRadius: 8,
    fontWeight: '500',
    fontSize: '14px',
    color: COLORS.TEXT.PRIMARY,

    '& span': {
      fontWeight: '500',
    },

    [BREAKPOINTS.MOBILE]: {
      width: 'auto',
      fontWeight: 400,
      borderRadius: 0,
      padding: 16,
      margin: '16px -16px -40px',
    },
  },
});
