import { createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { ImageUrls } from '@shared/enums/ImageUrls';
import { setClientContactData, setClientSubProjectData, setClientSubProjectNetworkError } from './actions';

import { State } from './types';

const initialState: State = {
  clientContactData: { data: null, state: DataState.Pending },
  clientSubProjectData: { data: null, state: DataState.Pending },
  clientSubProjectNetworkError: null,
  editClientDataState: DataState.Idle,
};

export const slice = createSlice({
  name: 'clientContactPage',
  initialState,
  reducers: {
    setClientContactDataLoading(state) {
      state.clientContactData = { data: null, state: DataState.Pending };
    },
    setClientSubProjectDataLoading(state) {
      state.clientSubProjectData = { data: null, state: DataState.Pending };
      state.editClientDataState = DataState.Idle;
    },
    setEditClientDataState(state, { payload }) {
      state.editClientDataState = payload;
    },
    addClientContactStore(state, { payload }) {
      if (!state.clientSubProjectData.data) {
        return;
      }
      const title = payload.title !== 'Other' ? payload.title : payload.customTitle;
      state.clientSubProjectData.data = state.clientSubProjectData.data.map((subProject) => {
        if (subProject.name === payload.accountName) {
          subProject.contacts.push({
            ...payload,
            title,
            imageUrl: ImageUrls.Client,
          });
        }
        return subProject;
      });
    },
    editClientContactStore(state, { payload }) {
      if (!state.clientSubProjectData.data) {
        return;
      }
      const title = payload.title !== 'Other' ? payload.title : payload.customTitle;
      state.clientSubProjectData.data = state.clientSubProjectData.data.map((subProject) => {
        if (subProject.id === payload.workstream) {
          subProject.contacts = subProject.contacts.map((contact) => {
            if (contact.id === payload.id) {
              contact = {
                ...payload,
                title,
                imageUrl: ImageUrls.Client,
              };
            }
            return contact;
          });
        }
        return subProject;
      });
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setClientContactData, (state, { payload }) => {
      state.clientContactData = payload;
    });
    builder.addCase(setClientSubProjectData, (state, { payload }) => {
      state.clientSubProjectData = payload;
    });
    builder.addCase(setClientSubProjectNetworkError, (state, { payload }) => {
      state.clientSubProjectNetworkError = payload;
    });
  },
});

export const {
  setClientContactDataLoading,
  setClientSubProjectDataLoading,
  editClientContactStore,
  addClientContactStore,
  setEditClientDataState,
} = slice.actions;

export default slice.reducer;
