import React, { FC, memo } from 'react';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import { Link } from 'react-router-dom';
import { useStyles } from './BackLink.styles';
import { createPortal } from 'react-dom';
import { useBackLinkPosition } from '@shared/hooks/useBackLinkPosition';

interface Props {
  name: string | null | undefined;
  path: string | null | undefined;
  classNameHolder?: string;
}

const BackLinkComponent: FC<Props> = ({ name, path, classNameHolder }) => {
  const styles = useStyles();
  const headerContentHolder = document.getElementById('header-content-holder')!;
  const isBackLinkInHeader = useBackLinkPosition();

  const backLink = name && path && (
    <div className={styles.root}>
      <Link to={path} className={styles.link}>
        <ArrowBackRoundedIcon />
        Back to {name}
      </Link>
    </div>
  );

  return (
    <>
      {backLink &&
        (!isBackLinkInHeader ? (
          <div className={classNameHolder}>{backLink}</div>
        ) : (
          createPortal(backLink, headerContentHolder)
        ))}
    </>
  );
};

export const BackLink = memo(BackLinkComponent);
