import { UserEvalPageData } from '@modules/EvaluationModule/interfaces/UserEvalPageData';
import { paths } from '@shared/enums/paths';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getUserEvalData, setUserEvalDataLoading } from '../redux/actions';
import { userEvalDataSelector } from '../redux/selectors';

export const useUserEvalData = (): AsyncData<UserEvalPageData> => {
  const userEvalData = useSelector(userEvalDataSelector);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  useEffect(() => {
    if (!id) {
      navigate(paths.root);

      return;
    }
    dispatch(getUserEvalData(id));
    return () => {
      dispatch(setUserEvalDataLoading());
    };
  }, [id]);

  return userEvalData;
};
