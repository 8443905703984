import { SyntheticEvent } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import CountryType from '@shared/interfaces/CountryType';
import countries from '@shared/consts/countries';

interface Props {
  value?: string;
  label?: string;
  onChange: (event: SyntheticEvent, value: CountryType | null) => void;
}

export default function CountrySelect(props: Props) {
  const value = countries.find((country) => props.value === country.label) || null;

  return (
    <Autocomplete
      value={value}
      options={countries}
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
          />
          {option.label}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={props.label}
          inputProps={{
            ...params.inputProps,
          }}
        />
      )}
      onChange={props.onChange}
      autoHighlight
    />
  );
}
