import React, { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { Field, FieldProps, Form, FormikProps, useFormikContext } from 'formik';
import { useStyles } from './PeopleHappinessPageFilterForm.styles';
import {
  Autocomplete,
  FilterOptionsState,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { FlagResolution } from '../../enums/FlagResolution';
import { HappinessSlider } from '@shared/components/HappinessSlider';
import { PeopleSerchSelector } from '@modules/HappinessModule/pages/AllFlagsPage/components/PeopleSerchSelector/PeopleSerchSelector';
import { PeopleHappinessFilterFormData } from '../../interfaces/PeopleHappinessFilterData';
import {
  PeopleHappinessFilterAnotherFieldsNames,
  PeopleHappinessFilterNumberFieldsNames,
  PeopleHappinessFilterUsersFieldsNames,
} from '../../enums/PeopleHappinessFilterFieldsNames';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { AutocompleteAsync } from '@modules/HappinessModule/pages/AllFlagsPage/components/AutocompleteAsync/AutocompleteAsync';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocations } from '@modules/HappinessModule/pages/AllFlagsPage/redux/selectors';
import { debounce, isEqual } from 'lodash';
import { selectAllDepartments } from '@modules/App/redux/departments/selectors';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { setFiltersToAplly } from '../../redux';
import { PEOPLE_HAPPINESS_FILTER_DELAY } from '../../constants';

interface Props {
  defaultValues: PeopleHappinessFilterFormData;
}

export const PeopleHappinessPageFilterForm: React.FC<Props> = ({ defaultValues }) => {
  const styles = useStyles();
  const { values, setFieldValue, initialValues }: FormikProps<PeopleHappinessFilterFormData> = useFormikContext();
  const [searchParams, setSearchParams] = useSearchParams();
  const locations = (useSelector(selectLocations) ?? []).filter((area) => !area.isLocationArea).map((a) => a.value);
  const allDepartments = useSelector(selectAllDepartments);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const isReactionRangeNotDefault =
    values.reactionGte !== defaultValues.reactionGte || values.reactionLte !== defaultValues.reactionLte;

  const setDefaultValuesForReactionRange = () => {
    setFieldValue(PeopleHappinessFilterNumberFieldsNames.ScoreFrom, defaultValues.reactionGte);
    setFieldValue(PeopleHappinessFilterNumberFieldsNames.ScoreTo, defaultValues.reactionLte);
  };

  const handleChangeUsers = (fieldName: string, users: UserInfo[]) => {
    setFieldValue(fieldName, users);
  };

  const handleChangeFieldsWithStringValues = (fieldName: string, values: string[]) => {
    setFieldValue(fieldName, values);
  };

  const handleSliderValues = (values: [number, number]) => {
    const [gte, lte] = values;
    setFieldValue(PeopleHappinessFilterNumberFieldsNames.ScoreFrom, gte);
    setFieldValue(PeopleHappinessFilterNumberFieldsNames.ScoreTo, lte);
  };

  const dispatch = useDispatch();
  const debounceApplyFilters = useCallback(
    debounce((values: PeopleHappinessFilterFormData) => {
      dispatch(setFiltersToAplly(values));
    }, PEOPLE_HAPPINESS_FILTER_DELAY),
    []
  );

  useEffect(() => {
    if (!isEqual(values, initialValues) && !isFormTouched) {
      setIsFormTouched(true);
    }
  }, [values, initialValues, isFormTouched]);

  // TODO: extract this piece of logics inside to helpers
  useEffect(() => {
    if (!isFormTouched) {
      return;
    }
    Object.entries(values).forEach(([key, value]) => {
      const userArrayFields = Object.values(PeopleHappinessFilterUsersFieldsNames);

      if (key === PeopleHappinessFilterAnotherFieldsNames.Departments) {
        const depIds = (
          value as PeopleHappinessFilterFormData[PeopleHappinessFilterAnotherFieldsNames.Departments]
        ).map((dep) => dep?.id);
        searchParams.delete(key);
        if (depIds.length > 0) {
          depIds.forEach((depId) => depId && searchParams.append(key, depId));
        }
      } else if (key === PeopleHappinessFilterAnotherFieldsNames.LegalCountries) {
        searchParams.delete(key);
        (value as PeopleHappinessFilterFormData[PeopleHappinessFilterAnotherFieldsNames.LegalCountries]).forEach(
          (country) => searchParams.append(key, country)
        );
      } else if (userArrayFields.includes(key as PeopleHappinessFilterUsersFieldsNames)) {
        const userIds = (value as PeopleHappinessFilterFormData[PeopleHappinessFilterUsersFieldsNames.Users]).map(
          (user) => user.id?.toString()
        );
        searchParams.delete(key);
        if (userIds.length > 0) {
          userIds.forEach((userId) => userId && searchParams.append(key, userId));
        }
      } else if (value !== defaultValues[key]) {
        searchParams.set(key, value.toString());
      } else {
        searchParams.delete(key);
      }
      setSearchParams(searchParams);
      debounceApplyFilters(values);
    });
  }, [values, isFormTouched]);

  useEffect(() => {
    if (!values.includeResponded) {
      setDefaultValuesForReactionRange();
    }
  }, [values.includeResponded]);

  return (
    <Form>
      <Grid container rowGap="40px" className={styles.drawlerRoot}>
        <Grid item xs={12}>
          <Stack rowGap="16px">
            <Typography variant="body2">Responses</Typography>
            <Stack>
              <Field
                name={PeopleHappinessFilterAnotherFieldsNames.IncludeNotResponded}
                type="checkbox"
                component={({ field }: FieldProps) => (
                  <FormControlLabel
                    control={<Switch {...field} />}
                    label={<Typography sx={{ marginLeft: 1 }}>Show not responded people</Typography>}
                  />
                )}
              />
              <Stack direction="row" alignItems="center">
                <Field
                  name={PeopleHappinessFilterAnotherFieldsNames.IncludeResponded}
                  type="checkbox"
                  component={({ field }: FieldProps) => (
                    <FormControlLabel
                      control={<Switch {...field} />}
                      label={<Typography sx={{ marginLeft: 1 }}>Show responses:</Typography>}
                    />
                  )}
                />
                {values.includeResponded && (
                  <Link
                    component="button"
                    variant="body2"
                    sx={{
                      textDecoration: isReactionRangeNotDefault ? 'underline' : 'none',
                      color: isReactionRangeNotDefault ? 'primary.main' : 'text.secondary',
                      fontSize: '12px',
                      cursor: isReactionRangeNotDefault ? 'pointer' : 'default',
                    }}
                    onClick={(e) => {
                      if (isReactionRangeNotDefault) {
                        e.stopPropagation();
                        setDefaultValuesForReactionRange();
                      }
                    }}
                  >
                    {isReactionRangeNotDefault ? 'Show' : 'Showing'} only unhappy
                  </Link>
                )}
              </Stack>
            </Stack>
            <HappinessSlider
              disabled={!values.includeResponded}
              value={[values.reactionGte, values.reactionLte]}
              onChange={(_, values) => {
                handleSliderValues(values as [number, number]);
              }}
            />
          </Stack>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body2">Resolution</Typography>
          <Field name="resolution" as={RadioGroup}>
            <Stack>
              {Object.entries(FlagResolution).map(([, value], i) => (
                <FormControlLabel
                  key={i}
                  control={<Radio />}
                  label={
                    <Typography>
                      {value}{' '}
                      {value === FlagResolution.Resolved ? (
                        <Typography component="span" variant="body2">
                          without flag
                        </Typography>
                      ) : (
                        ''
                      )}
                    </Typography>
                  }
                  value={value}
                />
              ))}
            </Stack>
          </Field>
        </Grid>
        <Grid item xs={12}>
          <Stack rowGap="24px">
            <PeopleSerchSelector
              title="Person"
              name={PeopleHappinessFilterUsersFieldsNames.Users}
              onChange={handleChangeUsers}
              value={values.users}
            />
            <PeopleSerchSelector
              title="Manager"
              name={PeopleHappinessFilterUsersFieldsNames.PrimaryReportsTos}
              onChange={handleChangeUsers}
              value={values.primaryReportsTos}
            />
            <Field
              name={PeopleHappinessFilterAnotherFieldsNames.Departments}
              component={Autocomplete}
              multiple
              getOptionLabel={(option: DepartmentData) => option.name}
              filterOptions={(options: DepartmentData[], state: FilterOptionsState<DepartmentData>) => {
                const searchString = String(state.inputValue).toLowerCase();
                return options.filter((option) => option.name.toLowerCase().includes(searchString));
              }}
              value={values.departments}
              onChange={(_: SyntheticEvent, newValue: DepartmentData[]) =>
                setFieldValue(PeopleHappinessFilterAnotherFieldsNames.Departments, newValue)
              }
              options={allDepartments.data ?? []}
              disableClearable
              limitTags={1}
              renderInput={(params: FormikProps<TextFieldProps>) => (
                <TextField {...params} className={styles.textField} label={'Department and project'} />
              )}
            />
            <AutocompleteAsync
              name={PeopleHappinessFilterAnotherFieldsNames.LegalCountries}
              value={values.legalCountries}
              handleChange={handleChangeFieldsWithStringValues}
              options={locations ?? []}
              label="Legal location"
            />
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};
