import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  block: {
    padding: '16px 24px',
    maxWidth: '80ch',

    '& ul': {
      padding: '0 0 0 14px',
    },

    '& h5': {
      margin: '0 0 8px',
      fontSize: 14,
      fontWeight: 400,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',

    '& h3': {
      fontWeight: 500,
    },
  },
  version: {
    marginLeft: 10,
    color: COLORS.SCALEEVAL.CANT,
  },
  divider: {
    width: '100%',
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
  },
  item: {
    margin: '0 0 10px',
  },
});
