import React, { FC } from 'react';
import { useStyles } from './RockySpinner.styles';

interface Props {
  color?: string;
}

export const RockySpinner: FC<Props> = () => {
  const styles = useStyles();

  return <div className={styles.spinner}></div>;
};
