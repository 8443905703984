import { StreamsRolesSenioritiesMapData } from '@modules/EvaluationModule/interfaces/StreamsRolesSenioritiesMapData';
import { CompanyRoleData } from '@shared/interfaces/Roles';
import * as Yup from 'yup';
import { checkIfSeniorityExist } from './checkIfSeniorityExist';
import { PromotionFormFieldsNames } from '@modules/EvaluationModule/enums/PromotionFormFieldsNames';

const EMPTY_FIELD_ERROR_TEXT = 'Required field';

export const getValidationSchema = (rolesSenioritiesMap: StreamsRolesSenioritiesMapData[]) => {
  const fieldsSchema = {
    stream: Yup.object().required(EMPTY_FIELD_ERROR_TEXT).nullable(),
    role: Yup.object().required(EMPTY_FIELD_ERROR_TEXT).nullable(),
    seniority: Yup.object()
      .when(PromotionFormFieldsNames.Role, {
        is: (role: CompanyRoleData) => checkIfSeniorityExist({ rolesSenioritiesMap, role }),
        then: Yup.object().nullable().required(EMPTY_FIELD_ERROR_TEXT),
      })
      .nullable(),
    reason: Yup.string().nullable().required(EMPTY_FIELD_ERROR_TEXT),
    effectiveDate: Yup.string().nullable().required(EMPTY_FIELD_ERROR_TEXT),
  };

  return Yup.object().shape(fieldsSchema);
};
