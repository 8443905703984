import { FC } from 'react';
import { useStyles } from './EditFlagFormPage.styles';
import { BackLink } from '@shared/components/BackLink';
import { DataState } from '@shared/enums/DataState';
import { RocketLoader } from '@shared/components/RocketLoader';
import { FlagForm } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/FlagForm';
import { useFlagData } from './hooks/useFlagData';
import { FlagSnapshot } from './components/FlagSnapshot';
import { FlagState } from '@modules/HappinessModule/enums/FlagState';
import { AccessDeniedView } from '@shared/components/DataErrorView/components/AccessDeniedView';
import { DataErrorView } from '@shared/components/DataErrorView';
import { getFlagPageState } from './helpers/getFlagPageState';
import { PageState } from '@shared/enums/PageState';
import { FlagPreview } from '@modules/HappinessModule/pages/FlagPage/pages/EditFlagFormPage/components/FlagPreview/FlagPreview';
import { flagBackLinkPathByUserIdEditPage } from './helpers/flagBackLinkPathByUserIdEditPage';
import { useToms } from '@shared/hooks/useToms';
import { usePrs } from '@shared/hooks/usePrs';
import { checkIfFlagResolved } from '../../helpers/checkIfFlagResolved';
import { useUserAutomaticFlagWatchers } from '../../hooks/useUserAutomaticFlagWatchers';
import { getAutomaticFlagWatchers } from '../../helpers/getAllAutomaticFlagWatchers';
import { useUserFlagCcDetails } from '../CreateFlagFormPage/hooks/useUserFlagCcDetails';
import { useMyEvalData } from '@modules/EvaluationModule/pages/UserEvalPage/hooks/useMyEvalData';

export const EditFlagFormPage: FC = () => {
  const styles = useStyles();
  const { flagData, snapshotData, userFlagPermissions } = useFlagData();
  useToms({
    isNeedToBeFetched:
      userFlagPermissions.data?.newHomeTomSection.isCreatable ||
      userFlagPermissions.data?.newHomeTomSection.isUpdatable,
  });
  usePrs();

  const isAtLeastOneFieldUpdatable =
    userFlagPermissions.data && Object.values(userFlagPermissions.data).some((el) => el.isUpdatable || el.isCreatable);
  const data = flagData.data;
  const isFlagReadOnly = isAtLeastOneFieldUpdatable
    ? !!data?.flagFields?.status && checkIfFlagResolved(data?.flagFields.status)
    : true;
  const isFlagDataLoading = flagData.state === DataState.Pending;
  const isPermissionsLoading = userFlagPermissions.state === DataState.Pending;
  const usersFirstName = data?.user.name.split(' ')[0];
  const flagState = isFlagReadOnly ? FlagState.Resolved : FlagState.Edit;

  const flagAuthor = data?.author;
  const flagFor = data?.user;
  useMyEvalData(flagFor?.id);

  const flagForId = flagFor?.id ?? null;

  const { data: automaticFlagWatchersFromBE, state: automaticFlagWatchersFromBEDataState } =
    useUserAutomaticFlagWatchers({
      userId: flagForId,
    });
  const { data: userFlagCcDetails, state: userFlagCcDetailsState } = useUserFlagCcDetails(flagForId ?? undefined);

  const isFailed =
    flagData.state === DataState.Rejected ||
    userFlagPermissions.state === DataState.Rejected ||
    automaticFlagWatchersFromBEDataState === DataState.Rejected ||
    userFlagCcDetailsState === DataState.Rejected;
  const pageState = userFlagPermissions.data ? getFlagPageState({ flagState }) : null;

  const isPageLoading =
    isFlagDataLoading ||
    isPermissionsLoading ||
    !pageState ||
    automaticFlagWatchersFromBEDataState === DataState.Pending ||
    userFlagCcDetailsState === DataState.Pending;

  const reportsTo = flagFor?.primaryReportsTo ?? null;

  const userAutomaticFlagWatchers =
    automaticFlagWatchersFromBE &&
    flagAuthor &&
    userFlagCcDetails &&
    flagForId &&
    getAutomaticFlagWatchers({
      automaticFlagWatchersFromBE,
      userFlagCcDetails,
      reportsTo: reportsTo,
      flagAuthor,
      manuallyAddedFlagWatchers: data?.flagFields?.flagWatchers ?? null,
      flagForId,
    });

  return (
    <div>
      {!isFailed && (
        <BackLink
          name={isFlagDataLoading ? '...' : `${usersFirstName}'s profile`}
          path={flagBackLinkPathByUserIdEditPage(data?.user.id ?? '')}
          classNameHolder={styles.backLink}
        />
      )}
      <div className={styles.root}>
        {pageState === PageState.NoAccessView ? (
          <AccessDeniedView inComponent />
        ) : isFailed ? (
          <DataErrorView inComponent error={flagData.error} />
        ) : (
          <>
            {isPageLoading ? (
              <div className={styles.loaderHolder}>
                <RocketLoader />
              </div>
            ) : pageState === PageState.ReadOnlyView &&
              data &&
              userFlagPermissions.data &&
              userAutomaticFlagWatchers ? (
              <FlagPreview
                flagData={data}
                userFlagPermissions={userFlagPermissions.data}
                userAutomaticFlagWatchers={userAutomaticFlagWatchers}
              />
            ) : (
              data &&
              userFlagPermissions.data &&
              userAutomaticFlagWatchers && (
                <FlagForm
                  userAutomaticFlagWatchers={userAutomaticFlagWatchers}
                  userFlagPermissions={userFlagPermissions.data}
                  flagData={data}
                  flagFormState={pageState}
                />
              )
            )}
            {data?.flagChangeEvents && snapshotData.state !== DataState.Idle && (
              <FlagSnapshot flagChangeEvents={data.flagChangeEvents} snapshotDataAsync={snapshotData} />
            )}
          </>
        )}
      </div>
    </div>
  );
};
