import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modal: {
    maxWidth: '27rem',
  },
  peerList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  peerListItem: {
    margin: '0 -1rem',
    padding: '1rem',
    display: 'flex',
    justifyContent: 'space-between',

    '&:not(:last-child)': {
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },

    '& button': {
      padding: 0,
      minWidth: 0,
      color: COLORS.TEXT.SECONDARY,
      background: 'none !important',
    },
  },
});
