import React from 'react';

export const VirtualTableContext = React.createContext<{
  top: number;
  setTop: (top: number) => void;
}>({
  top: 0,
  setTop: (value: number) => {
    value;
  },
});
