import React, { useCallback, useEffect } from 'react';
import { pageTitles } from '@shared/enums/pageTitles';
import { useTitle } from '@shared/hooks/useTitle';
import { useDispatch } from 'react-redux';
import { UsersHappiness } from '../../components/UsersHappiness/UsersHappiness';
import { useMyTeamHappinessData } from './hooks/useMyTeamHappinessData';
import { setSortInfo } from './redux';
import { RockyModulesTabs } from '@shared/components/RockyModulesTabs';
import { paths } from '@shared/enums/paths';
import useUserEnabledFeatures from '@shared/hooks/useUserEnabledFeatures';
import { SeeAllReportersLink } from '@modules/EvaluationModule/components/SeeAllReportersLink';
import TheaterComedyOutlinedIcon from '@mui/icons-material/TheaterComedyOutlined';

export const MyTeamHappinessPage: React.FC = () => {
  const dispatch = useDispatch();
  const { usersHappinessData, sortInfo, averageMark, averageChange } = useMyTeamHappinessData();
  const onTableSortClick = useCallback((sortInfo) => dispatch(setSortInfo(sortInfo)), [dispatch]);
  const setPageTitle = useTitle();
  const { isFlagsEnabled } = useUserEnabledFeatures();

  useEffect(() => {
    setPageTitle(pageTitles.myTeamReportHappinessPage);
  }, []);

  const userHappinessDataFilter = {
    ...usersHappinessData,
    data: usersHappinessData.data
      ? usersHappinessData.data.filter(({ isPrimaryReporter }) => !!isPrimaryReporter)
      : null,
  };

  return (
    <UsersHappiness
      tableTitle="My team"
      sideBlock={
        <SeeAllReportersLink
          path={paths.allPeople}
          linkTitle="See all happiness"
          icon={<TheaterComedyOutlinedIcon />}
        />
      }
      sortInfo={sortInfo}
      usersHappinessData={userHappinessDataFilter}
      averageMark={averageMark}
      averageChange={averageChange}
      onSortClick={onTableSortClick}
      tabs={<RockyModulesTabs path={paths.myTeam} flags={isFlagsEnabled} />}
    ></UsersHappiness>
  );
};
