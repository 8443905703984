import React, { FC } from 'react';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import Tooltip from '@mui/material/Tooltip';
import { useStyles } from './UserGoalStatusIcon.styles';

interface Props {
  status: GoalsStatus;
  isOverdue: boolean;
}
export const UserGoalStatusIcon: FC<Props> = ({ status, isOverdue }) => {
  const styles = useStyles();

  return (
    <Tooltip title={`${status}${isOverdue ? ', overdue' : ''}`} placement="top">
      {status === GoalsStatus.InProgress ? (
        <AccessTimeOutlinedIcon className={styles.goalStatusProgress} />
      ) : status === GoalsStatus.Achieved ? (
        <CheckBoxIcon className={styles.goalStatusAchieved} />
      ) : (
        <HighlightOffOutlinedIcon className={styles.goalStatusCanceled} />
      )}
    </Tooltip>
  );
};
