import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  contentBlock: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: COLORS.WHITE,
    borderRadius: 8,
    padding: '5% 0px',
  },
  infoText: {
    maxWidth: 325,
    fontWeight: '400',
    lineHeight: '140%',
    color: COLORS.TEXT.SECONDARY,
    textAlign: 'center',
    margin: '0px 0 16px',
  },
  infoTextTitle: {
    fontSize: 24,
    marginBottom: 0,
  },
  buttonsContainer: {
    display: 'flex',
    gap: 16,
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.ACTION.PRIMARY,
    color: COLORS.WHITE,
    border: 'none',
    fontSize: '14px',
    padding: '6px 16px 7px',
    fontWeight: '500',
    cursor: 'pointer',
    borderRadius: 4,

    '&:hover': {
      backgroundColor: COLORS.ACTION.DARK,
    },
  },
  inComponent: {
    boxShadow: 'none',
    height: 'auto',
    margin: 0,
  },
});
