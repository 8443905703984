import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    color: 'red',
  },
  reviseBtn: {
    display: 'flex',
    gap: 10,
  },
  modalRoot: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: '16px 24px',
    width: 420,
    backgroundColor: COLORS.WHITE,
    boxShadow:
      '0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12)',
    borderRadius: 4,
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
  },
  modalItem: {
    display: 'flex',
    gap: 16,
    alignItems: 'center',
  },
  itemNameBlock: {
    width: 175,
  },
  nameBlockTitle: {
    fontWeight: '400',
    fontSize: '13px',
    lineHeight: '16px',
    color: COLORS.TEXT.PRIMARY,
    opacity: 0.7,
    display: 'flex',
    alignItems: 'center',
    gap: 5,
    '& svg': {
      width: 12,
      height: 12,
    },
  },
  nameBlockValue: {
    fontWeight: '600',
    fontSize: 24,
    lineHeight: '150%',
    letterSpacing: '0.15px',

    '& span': {
      fontWeight: '400',
      color: COLORS.TEXT.SECONDARY,
    },
  },
  itemSelectBlock: {
    width: 183,
  },
  formSelect: {
    margin: 0,
    width: '100%',
  },
  payRateInput: {
    fontWeight: '500',
    appearance: 'none',
    fontSize: 24,
    lineHeight: '150%',
    letterSpacing: '0.15px',
    margin: 0,

    '& .MuiInputAdornment-root': {
      margin: 0,
    },

    '& .MuiTypography-root': {
      fontWeight: '400',
      fontSize: 24,
      lineHeight: '150%',
      letterSpacing: '0.15px',
    },

    '& .MuiFilledInput-input::-webkit-outer-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
    '& .MuiFilledInput-input::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none',
      margin: 0,
    },
  },
  nameDefaultValue: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',
    color: COLORS.TEXT.PRIMARY,
  },
  btnContainer: {
    display: 'flex',
    gap: 10,
    marginLeft: 'auto',
    marginTop: 30,
  },
  nameBlockRange: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '17px',

    '& span': {
      color: COLORS.TEXT.SECONDARY,
    },
  },
  checkIcon: {
    fill: COLORS.STATUS.SUCCESS,
    width: 24,
    height: 24,
    position: 'absolute',
    top: 30,
    right: 12,
  },
  verticalAlignTopIcon: {
    fill: COLORS.STATUS.WARNING,
    width: 24,
    height: 24,
    position: 'absolute',
    top: 30,
    right: 12,
  },
  modalTitle: {
    fontWeight: '500',
    fontSize: '20px',
    lineHeight: '160%',
    letterSpacing: '0.15px',
    margin: '0 0 18px 0',
  },
  cancelBtn: {
    backgroundColor: COLORS.ACTION.HIGHLIGHT,
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      color: COLORS.ACTION.PRIMARY,
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
    },
  },
  error: {
    fontWeight: '400',
    fontSize: '13px',
    color: COLORS.STATUS.CRITICAL,
    marginTop: 5,
  },
  effectiveFrom: {
    '& .MuiFilledInput-root::before': {
      borderBottom: `2px solid ${COLORS.STATUS.CRITICAL}`,
    },
  },
  spinner: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    borderTop: '3px solid transparent',
    borderRight: '3px solid transparent',
    borderBottom: `3px solid ${COLORS.DIVIDER}`,
    borderLeft: `3px solid ${COLORS.DIVIDER}`,
    animation: '$spin 0.5s infinite linear',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  nonInfo: {
    fontWeight: 400,
    fontSize: 14,
    lineHeight: '143%',
    letterSpacing: '0.17px',
    color: COLORS.TEXT.SECONDARY,
  },
  nonInfoLocation: {
    color: COLORS.STATUS.CRITICAL,
  },
});
