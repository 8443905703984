import React, { FC, ReactNode, useState } from 'react';
import classNames from 'classnames';
import { useStyles } from './ExpandableGroup.styles';
import MenuIcon from '@mui/icons-material/Menu';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface Props {
  children: ReactNode;
}

export const ExpandableGroup: FC<Props> = ({ children }) => {
  const styles = useStyles();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <>
      <div className={classNames(styles.navItem)} onClick={() => setIsExpanded((isExpanded) => !isExpanded)}>
        {isExpanded ? <KeyboardArrowUpIcon /> : <MenuIcon />}
        <div className={styles.navContainer}>
          <p className={styles.navText}>More</p>
          <p className={classNames(styles.navText, styles.subText)}>Report issue, terms, etc</p>
        </div>
      </div>
      {isExpanded && <>{children}</>}
    </>
  );
};
