import { UserGoalPageData } from '@modules/EvaluationModule/interfaces/UserGoalPageData';
import { GoalsStatus } from '@modules/EvaluationModule/enums/GoalsStatus';
import * as Yup from 'yup';
import { GoalsFormField } from '../enums/GoalsFormField';

const OBJECTIVE_LENGTH = 150;
const DESCRIPTION_LENGTH = 5000;
const MAX_CASE_RESULT = 999_999_999_999;
const EMPTY_FIELD_ERROR_TEXT = 'Required';
const NEGATIVE_VALUE = "Can't be negative or zero";
const ACTUAL_RESULT_FIELD_ERROR_TEXT = "Can't be negative value";
const BEST_CASE_FIELD_ERROR_TEXT = "Can't be negative value";
const EMPTY_EXPECTED_RESULT_ERROR_TEXT = 'Set expected result';
const EMPTY_DESCRIPTION_ERROR_TEXT = 'Please describe the goal';
const EMPTY_OBJECTIVE_FIELD = 'Goal objective can not be blank';
const INCORECT_OBJECTIVE_LENGTH = `Should be shorter that ${OBJECTIVE_LENGTH} character`;
const INCORECT_DESCRIPTION_LENGTH = `Should be shorter that ${DESCRIPTION_LENGTH} character`;

export const isFieldDisabledByStatus = (status: GoalsStatus): boolean => status === GoalsStatus.Canceled;
const statusFieldName: keyof UserGoalPageData = GoalsFormField.Status;

const editFieldsSchema = {
  objective: Yup.string()
    .max(OBJECTIVE_LENGTH, INCORECT_OBJECTIVE_LENGTH)
    .when(statusFieldName, {
      is: (status: GoalsStatus) => !isFieldDisabledByStatus(status),
      then: Yup.string().required(EMPTY_OBJECTIVE_FIELD),
    }),
  worstCaseResult: Yup.number()
    .min(1, NEGATIVE_VALUE)
    .max(MAX_CASE_RESULT)
    .when(statusFieldName, {
      is: (status: GoalsStatus) => !isFieldDisabledByStatus(status),
      then: Yup.number().required(EMPTY_EXPECTED_RESULT_ERROR_TEXT),
    }),
  actualResult: Yup.number()
    .when(statusFieldName, {
      is: (status: GoalsStatus) => !isFieldDisabledByStatus(status),
      then: Yup.number().nullable().max(MAX_CASE_RESULT).min(0, ACTUAL_RESULT_FIELD_ERROR_TEXT),
    })
    .required(EMPTY_EXPECTED_RESULT_ERROR_TEXT),
  dueDate: Yup.string().when(statusFieldName, {
    is: (status: GoalsStatus) => !isFieldDisabledByStatus(status),
    then: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
  }),
  description: Yup.string()
    .max(DESCRIPTION_LENGTH, INCORECT_DESCRIPTION_LENGTH)
    .when(statusFieldName, {
      is: (status: GoalsStatus) => !isFieldDisabledByStatus(status),
      then: Yup.string().required(),
    }),
  cancellationNote: Yup.string()
    .when(GoalsFormField.Status, {
      is: GoalsStatus.Canceled,
      then: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
    })
    .nullable(),
  status: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
};

const editFieldsSchemaForRanges = {
  objective: Yup.string().max(OBJECTIVE_LENGTH, INCORECT_OBJECTIVE_LENGTH).required(EMPTY_FIELD_ERROR_TEXT),
  actualResult: Yup.number()
    .when(statusFieldName, {
      is: (status: GoalsStatus) => !isFieldDisabledByStatus(status),
      then: Yup.number().nullable().max(MAX_CASE_RESULT).min(0, ACTUAL_RESULT_FIELD_ERROR_TEXT),
    })
    .required(EMPTY_EXPECTED_RESULT_ERROR_TEXT),
  worstCaseResult: Yup.number().max(MAX_CASE_RESULT).min(1, NEGATIVE_VALUE).required(EMPTY_FIELD_ERROR_TEXT),
  bestCaseResult: Yup.number().max(MAX_CASE_RESULT).min(0, BEST_CASE_FIELD_ERROR_TEXT).required(EMPTY_FIELD_ERROR_TEXT),
  dueDate: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
  cancellationNote: Yup.string()
    .when(GoalsFormField.Status, {
      is: GoalsStatus.Canceled,
      then: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
    })
    .nullable(),
  description: Yup.string().max(DESCRIPTION_LENGTH, INCORECT_DESCRIPTION_LENGTH).required(EMPTY_DESCRIPTION_ERROR_TEXT),
  status: Yup.string().required(EMPTY_FIELD_ERROR_TEXT),
};

const creationFieldsSchema = {
  objective: Yup.string().max(OBJECTIVE_LENGTH, INCORECT_OBJECTIVE_LENGTH).required(EMPTY_OBJECTIVE_FIELD),
  worstCaseResult: Yup.number()
    .min(1, NEGATIVE_VALUE)
    .typeError(EMPTY_FIELD_ERROR_TEXT)
    .when(GoalsFormField.IsRange, {
      is: false,
      then: Yup.number()
        .typeError(EMPTY_FIELD_ERROR_TEXT)
        .max(MAX_CASE_RESULT)
        .required(EMPTY_EXPECTED_RESULT_ERROR_TEXT),
    })
    .max(MAX_CASE_RESULT)
    .required(EMPTY_FIELD_ERROR_TEXT),
  bestCaseResult: Yup.number()
    .min(0, BEST_CASE_FIELD_ERROR_TEXT)
    .when(GoalsFormField.IsRange, {
      is: true,
      then: Yup.number().typeError(EMPTY_FIELD_ERROR_TEXT).max(MAX_CASE_RESULT).required(EMPTY_FIELD_ERROR_TEXT),
    })
    .notRequired()
    .nullable(),
  description: Yup.string().max(DESCRIPTION_LENGTH, INCORECT_DESCRIPTION_LENGTH).required(EMPTY_DESCRIPTION_ERROR_TEXT),
};

export const editValidationSchema = Yup.object().shape(editFieldsSchema);
export const creationValidationSchema = Yup.object().shape(creationFieldsSchema);
export const editValidationSchemaWithRanges = Yup.object().shape(editFieldsSchemaForRanges);
