import { Slider } from '@mui/material';
import React, { FC } from 'react';
import { sliderCustomStyles } from './HappinessSlider.styles';

export const HappinessSlider: FC<React.ComponentProps<typeof Slider>> = (props) => {
  return (
    <Slider
      {...props}
      marks
      valueLabelDisplay="auto"
      min={props.min ?? 1}
      max={props.min ?? 10}
      sx={{ ...sliderCustomStyles, ...props.sx }}
    />
  );
};
