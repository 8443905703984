import { RaiseFlagData } from '@modules/HappinessModule/interfaces/RaiseFlagData';
import { createAction } from '@reduxjs/toolkit';

export const raiseFlagAction = createAction<RaiseFlagData>('flagFormPage/raiseFlagAction');
export const editFlagAction =
  createAction<{ fields: Partial<RaiseFlagData>; flagId: string; isNoNavigation?: boolean }>(
    'flagFormPage/editFlagAction'
  );
export const getUserFlagCcDetails = createAction<string>('flagFormPage/getUserFlagCcDetails');
export const getAutomaticFlagWatchers = createAction<string>('flagFormPage/getAutomaticFlagWatchers');
