import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  alert: {
    width: 'fit-content',
    whiteSpace: 'pre-line',
  },
  accountingAlert: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'rgba(0, 0, 0, 0.6)',

    '& svg': {
      color: 'rgba(0, 0, 0, 0.38)',
    },
  },
  resolutionEop: {
    marginBottom: 16,
  },
  textField: {
    marginBottom: '16px',
    maxWidth: '750px',
    display: 'block',
  },
});
