import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AsyncData } from '@shared/interfaces/asyncData';
import { selectAllDepartments } from '@modules/App/redux/departments/selectors';
import { loadAllDepartments } from '@modules/App/redux/departments/actions';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { DepartmentsQueryI } from '@modules/App/interfaces/DepartmentsQueryI';

export const useAllDepartments = (query: DepartmentsQueryI = { onlyActive: true }): AsyncData<DepartmentData[]> => {
  const dispatch = useDispatch();
  const allDepartments = useSelector(selectAllDepartments);

  useEffect(() => {
    dispatch(loadAllDepartments(query));
  }, [query.onlyActive]);

  return allDepartments;
};
