import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  tableHeader: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: '14px',
    fontWeight: '400',
    rowGap: 7,
  },
  tableHeaderTitle: {
    color: COLORS.TEXT.SECONDARY,
  },
  graph: {
    display: 'flex',
    flexDirection: 'column',
  },
  graphItem: {
    display: 'flex',
    columnGap: 5,
    cursor: 'pointer',
    fontSize: 12,
    fontWeight: 400,
    alignItems: 'center',
  },
  graphItemPopup: {
    '&-content': {
      width: 'fit-content !important',
      height: 'fit-content !important',
      padding: '5px 10px',
      overflow: 'hidden',
      overflowWrap: 'anywhere',
      textTransform: 'capitalize',
    },
  },
  feedbackMarkIndicator: {
    width: '100%',
    height: 8,
    borderRadius: 8,
  },
});
