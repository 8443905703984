import classNames from 'classnames';
import { Field } from 'formik';
import React from 'react';
import { useStyles } from './CustomRadioInput.styles';

type CustomRadioInputProps = {
  additionalFieldLabelColor?: string;
  title: string;
  field: { name: string };
  value: string;
  disabled: boolean;
  className: string;
};

export const CustomRadioInput: React.FC<CustomRadioInputProps> = ({
  className,
  additionalFieldLabelColor,
  title,
  field,
  value,
  disabled,
}) => {
  const styles = useStyles();

  return (
    <div className={classNames(styles.root, className)}>
      <label className={classNames(styles.container, additionalFieldLabelColor)}>
        {title}
        <Field className={styles.input} type="radio" {...field} value={value} disabled={disabled} />
        <span className={classNames(styles.checkmark, { [styles.checkmarkDisabled]: disabled })}></span>
      </label>
    </div>
  );
};
