import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    display: 'flex',
    gap: 0,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    minHeight: '100vh',

    [BREAKPOINTS.MOBILE]: {
      gap: 0,
      padding: '12px 0',
      overflowX: 'auto',
    },
  },
  projectList: {
    overflow: 'hidden',
    maxHeight: '100vh',
    overflowY: 'auto',
    position: 'sticky',
    top: 0,
  },
  projectItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px 8px 12px 24px',
    fontSize: '14px',
    lineHeight: '140%',
    '&:hover, &:focus': {
      backgroundColor: COLORS.ACTION.HIGHLIGHT,
      cursor: 'pointer',
    },

    [BREAKPOINTS.MOBILE]: {
      padding: '8px 4px 8px 12px',
      fontSize: 12,
      fontWeight: 400,
    },
  },
  chossenProjectItem: {
    backgroundColor: COLORS.BRAND.HIGHLIGHT,
    color: COLORS.BRAND.PRIMARY,
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  projectListContainer: {
    borderRadius: 8,
    width: 242,

    [BREAKPOINTS.MOBILE]: {
      width: 150,
    },
  },
});
