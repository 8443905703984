import { RockyModal, RockyModalProps } from '@shared/components/RockyModal';
import { DataState } from '@shared/enums/DataState';
import React, { FC } from 'react';
import { useStyles } from './ConfirmationModalForRaisingFlag.styles';
import { useFormikContext } from 'formik';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { LoadingButton } from '@mui/lab';
import { Button } from '@mui/material';

interface Props extends RockyModalProps {
  flagRaisingState: DataState;
  handleRaisingFlag: () => void;
}

export const ConfirmationModalForRaisingFlag: FC<Props> = ({ flagRaisingState, handleRaisingFlag, ...modalProps }) => {
  const styles = useStyles();
  const { values } = useFormikContext<FlagFields>();

  return (
    <RockyModal
      {...modalProps}
      modalTitleStyles={styles.modalTitleStyles}
      modalStyles={styles.modalStyles}
      modalBodyStyles={styles.modalBodyStyles}
    >
      {!values.isRecipientsDisabled && (
        <div className={styles.importantNote}>Raising a flag will notify all participants</div>
      )}
      <div className={styles.notImportantNote}>Make sure you’ve written down everything correct.</div>
      <div className={styles.btns}>
        <Button onClick={modalProps.onClose}>Cancel</Button>
        <LoadingButton loading={flagRaisingState === DataState.Pending} onClick={handleRaisingFlag} variant="contained">
          Yes, raise a flag
        </LoadingButton>
      </div>
    </RockyModal>
  );
};
