import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  emptyViewContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    color: COLORS.TEXT.SECONDARY,
    padding: '80px 0',
  },
  emptyViewIcon: {
    display: 'block',
    width: 128,
  },
});
