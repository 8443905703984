import { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { Link as ReactRouterLink } from 'react-router-dom';
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import format from 'date-fns/format';

import { useStyles } from './LastEvalsTable.styles';
import { paths } from '@shared/enums/paths';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { useMyEvalData } from '@modules/EvaluationModule/pages/UserEvalPage/hooks/useMyEvalData';
import { DataState } from '@shared/enums/DataState';
import { UserEvalPageData } from '@modules/EvaluationModule/interfaces/UserEvalPageData';
import { EvaluationsHistoryDataItem } from '@modules/EvaluationModule/interfaces/EvaluationsHistoryDataItem';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';

enum LastEvalsTableHeader {
  Title = 'Last evaluations',
  Status = 'Status',
  Manager = 'Manager',
}

const createTableContent = (evaluations: UserEvalPageData | null, isLoading: boolean) => {
  const tableRow = {
    [LastEvalsTableHeader.Title]: {
      content: (evaluation: EvaluationsHistoryDataItem) => (
        <TableCell>{format(new Date(evaluation.date), 'MMMM yyyy')}</TableCell>
      ),
      skeleton: (
        <TableCell>
          <Skeleton />
        </TableCell>
      ),
    },
    [LastEvalsTableHeader.Status]: {
      content: (evaluation: EvaluationsHistoryDataItem) => {
        const isEOP = evaluation.evalType.toLowerCase().includes('end of probation');

        return (
          <TableCell sx={{ textTransform: 'capitalize' }}>
            {evaluation.status} {isEOP ? 'EOP' : 'Eval'}
          </TableCell>
        );
      },
      skeleton: (
        <TableCell>
          <Skeleton />
        </TableCell>
      ),
    },
    [LastEvalsTableHeader.Manager]: {
      content: (evaluation: EvaluationsHistoryDataItem) => (
        <TableCell>
          {evaluation.primaryReportsTo && (
            <UserReporterBlock
              isDetailInfo
              user={{
                ...evaluation.primaryReportsTo,
                position: '',
              }}
            />
          )}
        </TableCell>
      ),
      skeleton: (
        <TableCell>
          <Skeleton />
        </TableCell>
      ),
    },
  };

  const recentEvals: EvaluationsHistoryDataItem[] = [];
  const upcomingEval =
    evaluations?.history.find((historyItem) => historyItem.status === EvaluationStatus.Upcoming) ||
    evaluations?.history.find((historyItem) => historyItem.status === EvaluationStatus.Ongoing);

  const closedEval = evaluations?.history.find((historyItem) => historyItem.status === EvaluationStatus.Closed);

  if (upcomingEval) recentEvals.push(upcomingEval);
  if (closedEval) recentEvals.push(closedEval);

  return isLoading
    ? Array(2)
        .fill(null)
        .map((_, i) => <TableRow key={i}>{Object.values(tableRow).map((row) => row.skeleton)}</TableRow>)
    : recentEvals.map((evaluation) => (
        <TableRow key={evaluation.evaluationId}>
          {Object.values(tableRow).map((row) => row.content(evaluation))}
        </TableRow>
      ));
};

interface Props {
  userId?: string;
}

const LastEvalsTable: FC<Props> = ({ userId }) => {
  const styles = useStyles();
  const evalData = useMyEvalData(userId);
  const isLoading = evalData.state === DataState.Pending;

  const tableContent = createTableContent(evalData.data, isLoading);
  const viewAllLink = userId ? `${paths.users}/${userId}/${paths.evaluations}` : '';

  return (
    <TableContainer>
      <Table>
        <TableHead className={styles.tableHead}>
          <TableRow>
            {Object.values(LastEvalsTableHeader).map((header) => (
              <TableCell key={header}>{header}</TableCell>
            ))}
            <TableCell>
              <Link component={ReactRouterLink} to={viewAllLink}>
                View all
              </Link>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody className={styles.tableBody}>{tableContent}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default LastEvalsTable;
