import React, { FC } from 'react';
import { useStyles } from './RockyFlag.styles';
import ReactCountryFlag from 'react-country-flag';
import classNames from 'classnames';
import lookup from 'country-code-lookup';
import { normalizeCountry } from '@shared/helpers/normalizeCountry';
import { ReactCountryFlagProps } from 'react-country-flag';
import { Size } from '@shared/enums/sizes';

type Props = Omit<ReactCountryFlagProps, 'countryCode'> & {
  size?: Size;
  country: string;
};

export const RockyFlag: FC<Props> = ({ size = Size.Medium, country, ...props }) => {
  const styles = useStyles();

  const countryCode = lookup.byCountry(normalizeCountry(country));

  const large = size === Size.Large;
  const small = size === Size.Small;
  const medium = size === Size.Medium;

  return !countryCode ? null : (
    <ReactCountryFlag
      className={classNames(styles.flagIcon, {
        [styles.largeFlagIcon]: large,
        [styles.smallFlagIcon]: small || medium,
      })}
      svg
      style={{
        width: '1em',
        height: 'auto',
      }}
      aria-label={countryCode.country}
      {...props}
      countryCode={countryCode?.iso2}
    />
  );
};
