import React from 'react';
import { useStyles } from './ClientEvalFormClosed.styles';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { clientEvalFieldsDescription, clientEvalMainField } from '../../constants/clientEvalFielsDescription';
import { get } from 'lodash';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { ClientEvalData } from '@modules/EvaluationModule/interfaces/ClientEvalData';
import { getColorByFeedbackMark } from '@modules/EvaluationModule/helpers/getColorByFeedbackMark';
import classNames from 'classnames';

interface Props {
  data: ClientEvalData;
}

export const ClientEvalFormClosed: React.FC<Props> = ({ data }) => {
  const styles = useStyles();
  const { evaluation, evaluatee } = data;

  return (
    <>
      <div className={styles.evaluateeBlockContainer}>
        <div className={styles.evaluateeBlock}>
          <RockyAvatar fullName={evaluatee.name} imageUrl={evaluatee.imageUrl} large />
          How would you rate quality of work of {evaluatee.name}?
        </div>
        <div
          className={classNames(styles.markLabel, styles.mainMarkLabel)}
          style={{
            backgroundColor: getColorByFeedbackMark(
              get(evaluation, clientEvalMainField.questionName).mark as EvaluationMark
            ),
          }}
        >
          {get(evaluation, clientEvalMainField.questionName).mark}
        </div>
      </div>
      <div className={styles.divider}></div>
      <div className={styles.questionsContainer}>
        {clientEvalFieldsDescription.map(({ questionName, questionTitle }, i) => (
          <div key={i} className={styles.questionContainer}>
            <p className={styles.questionTitle}>{questionTitle}</p>
            <div
              className={styles.markLabel}
              style={{
                backgroundColor: getColorByFeedbackMark(get(evaluation, questionName).mark as EvaluationMark),
              }}
            >
              {get(evaluation, questionName).mark || EvaluationMark.CantEval}
            </div>
          </div>
        ))}
      </div>
      <div className={styles.notesContainer}>
        {evaluation?.notes && (
          <>
            <p>What improvement points or achievements would you like to highlight?</p>
            {evaluation?.notes}
          </>
        )}
        <div className={styles.closedThanksBlock}>
          <span>Thank you for your response!</span>
        </div>
      </div>
    </>
  );
};
