import React, { memo, useCallback, useState } from 'react';
import { useStyles } from './InputMessageField.styles';
import { Textarea } from '@shared/components/Textarea';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import { useDispatch } from 'react-redux';
import { useAuth } from '@shared/hooks/useAuth';
import { requestRevoteAction } from '../../redux/actions';
import { sendMessageAction, sendRockyMessageAction } from './../../redux/actions';
import debounce from 'lodash/debounce';
import { ChatCommands } from '../../enums/ChatCommands';
import { User } from '@shared/interfaces/user';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { Link } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import classNames from 'classnames';

interface Props {
  scrollToBottom: () => void;
  user: User | null;
}

const TIMEOUT_FOR_ROCKY_ANSWER_MS = 3000;

const InputMessageFieldComponent: React.FC<Props> = ({ scrollToBottom, user }) => {
  const styles = useStyles();
  const [textareaValue, setTextareaValue] = useState('');
  const { currentUser } = useAuth();
  const dispatch = useDispatch();
  const [isUserSendMessage, setIsUserSendMessage] = useState(false);

  const sendRockyMessaageDebounced = useCallback(
    debounce(() => {
      dispatch(sendRockyMessageAction());
      setIsUserSendMessage(false);
    }, TIMEOUT_FOR_ROCKY_ANSWER_MS),
    []
  );

  const sendMessage = useCallback(() => {
    if (currentUser && textareaValue) {
      dispatch(sendMessageAction({ message: textareaValue, author: currentUser?.id }));
      setTextareaValue('');
      if (textareaValue.trim() === ChatCommands.Revote) {
        dispatch(requestRevoteAction());
        sendRockyMessaageDebounced.cancel();
        return;
      }
      setIsUserSendMessage(true);
      sendRockyMessaageDebounced();
      scrollToBottom();
    }
  }, [currentUser, textareaValue]);

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  const handleTextareaValueChange = useCallback(
    (e) => {
      isUserSendMessage && sendRockyMessaageDebounced();
      setTextareaValue(e.target.value);
    },
    [isUserSendMessage]
  );

  return (
    <div className={styles.textareaBlock}>
      <Textarea
        value={textareaValue}
        onChange={handleTextareaValueChange}
        onKeyDown={onEnterPress}
        className={styles.textarea}
        placeholder="Share your thoughts"
        isAutosize
      ></Textarea>
      {textareaValue ? (
        <button className={classNames(styles.icon, styles.sendBtn)} onClick={sendMessage}>
          <SendRoundedIcon color="primary" />
        </button>
      ) : (
        user && (
          <Link to={`${paths.users}/${user?.id}/${paths.responses}`} className={classNames(styles.icon, styles.user)}>
            <RockyAvatar fullName={user.name} medium imageUrl={user.imageUrl} />
          </Link>
        )
      )}
    </div>
  );
};

export const InputMessageField = memo(InputMessageFieldComponent);
