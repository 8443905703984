import { FC, memo } from 'react';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import { COLORS } from '@styles/colors';
import { useStyles } from './QuestionBlock.styles';
import { PieChart } from '../PieChart';
import { UserRate } from '../UserRate/UserRate';
import classNames from 'classnames';
import { QuestionSelector } from '../QuestionSelector';
interface Props {
  title: string;
  peopleVoted: number;
  peopleTotal: number;
  average: number;
  prevAverage: number;
  isDataLoading: boolean;
  previousVoted?: number;
  isDepartement?: boolean;
  isDataExist?: boolean | null;
}

const QuestionBlockComponent: FC<Props> = ({
  title,
  peopleVoted,
  peopleTotal,
  isDataLoading,
  average = 0,
  prevAverage,
  previousVoted,
  isDepartement,
  isDataExist,
}) => {
  prevAverage = prevAverage || average;
  const change = average - prevAverage || 0;
  const styles = useStyles();
  const votedPercentageRounded = ((peopleVoted / peopleTotal) * 100).toFixed(0);
  const votedDifference = previousVoted ? Math.abs(peopleVoted - previousVoted) : -1;
  const votedDefferencePercent = previousVoted ? Math.abs((1 - peopleVoted / previousVoted) * 100).toFixed(0) : -1;

  const vsPreviousBlock = (
    <span className={styles.engagementPercentage}>
      vs previous
      <b
        className={styles.indicator}
        style={{
          color: change > 0 ? COLORS.STATUS.SUCCESS : change === 0 ? COLORS.TEXT.SECONDARY : COLORS.STATUS.CRITICAL,
        }}
      >
        {change > 0 && '+'}
        {+change.toFixed(1)}
      </b>
    </span>
  );

  const votedBlock = (
    <span className={styles.votedLabel}>
      {peopleVoted}/{peopleTotal}&#160;voted
    </span>
  );

  const vsPreviousVotedBlock = (
    <>
      {previousVoted && previousVoted !== -1 ? (
        <span
          className={classNames(styles.votedLabel, {
            [styles.votedDifferenceLabelPositive]: peopleVoted >= previousVoted,
            [styles.votedDifferenceLabelNegative]: peopleVoted < previousVoted,
          })}
        >
          <span className={classNames(styles.votedLabel, styles.votedDifferenceLabel)}>vs&#160;previous </span>
          {`${peopleVoted >= previousVoted ? '+' : '-'}${votedDifference} (${
            peopleVoted - previousVoted >= 0 ? '+' : '-'
          }${votedDefferencePercent}%)`}
        </span>
      ) : (
        ''
      )}
    </>
  );

  return (
    <div className={styles.root}>
      <QuestionSelector title={title} isUnresolvedCount></QuestionSelector>
      {isDataExist ? (
        <div className={styles.content}>
          <RockySkeleton
            isLoading={isDataLoading}
            className={styles.meterSkeleton}
            element={
              <>
                <div className={styles.contentContainer}>
                  <div className={styles.rateContent}>
                    <div className={styles.labelsBlock}>
                      <span className={styles.avgRateLabel}>Average rate</span>
                      <span className={styles.labelMobile}>
                        <span className={styles.rateNumber}>{average.toFixed(1)}</span> <span>{vsPreviousBlock}</span>
                      </span>
                    </div>
                    <div className={styles.rateContainer}>
                      <div className={styles.rateHolder}>
                        <UserRate rate={average} isDepartament={isDepartement}></UserRate>
                      </div>
                      <span className={styles.labelDesktop}>{vsPreviousBlock}</span>
                    </div>
                  </div>
                </div>
                <div className={classNames(styles.contentContainer, styles.engagementBlock)}>
                  <div className={styles.labelsBlock}>
                    <span className={styles.avgRateLabel}>Engagement:</span>
                    <div className={styles.votedBlockContainer}>
                      <span className={styles.labelMobile}>{votedBlock}</span>
                      <span className={styles.labelMobile}>{vsPreviousVotedBlock}</span>
                    </div>
                  </div>
                  <div className={styles.pieChartHolder}>
                    <PieChart ratio={+(+votedPercentageRounded / 100).toFixed(1)}></PieChart>
                  </div>
                  <div className={styles.votedBlockContainer}>
                    <span className={styles.labelDesktop}>{votedBlock}</span>
                    <span className={styles.labelDesktop}>{vsPreviousVotedBlock}</span>
                  </div>
                </div>
              </>
            }
          />
        </div>
      ) : (
        <RockySkeleton
          isLoading={isDataLoading}
          className={styles.meterSkeleton}
          element={<div className={styles.noContentblock}>Please select another survey or project </div>}
        />
      )}
    </div>
  );
};

export const QuestionBlock = memo(QuestionBlockComponent);
