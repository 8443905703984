import { rescheduleEvaluation } from '@modules/EvaluationModule/helpers/rescheduleEvaluation';
import { rescheduleEval } from '@modules/EvaluationModule/redux/evaluationsManegment/actions';
import { updateGoogleCalendarEvalAction } from '@modules/EvaluationModule/redux/googleCalendarEval/actions';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DataState } from '@shared/enums/DataState';
import { deleteClientFeedbackAction, deletePeerFeedbackAction } from '../../EvalSettingsPage/redux/actions';
import { autosaveEvalNotesAndGoals, setUserEvalPageData } from './actions';
import { State } from './types';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { setPromotionInStateAfterCreate, updateUserPromotion } from '../../UserPromotionsPage/redux/actions';

const initialState: State = {
  evalPageData: { data: null, state: DataState.Pending },
  eopLoadingState: DataState.Idle,
  permissions: { data: null, state: DataState.Pending },
  promotion: { data: null, state: DataState.Idle },
};

export const slice = createSlice({
  name: 'evalPage',
  initialState,
  reducers: {
    setUserEvalPageDataLoading(state, { payload }) {
      state.evalPageData = { data: state.evalPageData.data, state: payload };
    },
    resetEvalForm(state) {
      state.evalPageData = { data: null, state: DataState.Pending };
    },
    setNextEvalDate(state, { payload }) {
      if (state.evalPageData.data) {
        state.evalPageData.data.nextEvalDate = payload;
      }
    },
    setEopLoadingState(state, { payload }: PayloadAction<DataState>) {
      state.eopLoadingState = payload;
    },
    setEvalNote(state, { payload }: PayloadAction<EvalPageData['notes']>) {
      if (state.evalPageData.data) {
        state.evalPageData.data.notes = payload;
      }
    },
    setEvalPermissions(state, { payload }) {
      state.permissions = payload;
    },
    setEvalPromotion(state, { payload }: PayloadAction<State['promotion']>) {
      state.promotion = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(autosaveEvalNotesAndGoals, (state, { payload }) => {
      if (!state.evalPageData.data) {
        return;
      }

      state.evalPageData.data.notes = payload.notes ?? '';
      state.evalPageData.data.goals = payload.goals ?? '';
    });
    builder.addCase(setUserEvalPageData, (state, { payload }) => {
      state.evalPageData = payload;
    });
    builder.addCase(rescheduleEval, (state, { payload }) => {
      if (!state.evalPageData.data) {
        return;
      }

      state.evalPageData.data = rescheduleEvaluation({
        rescheduledEval: state.evalPageData.data,
        rescheduleData: payload,
      });
    });
    builder.addCase(updateGoogleCalendarEvalAction, (state, { payload }) => {
      if (state.evalPageData.data) {
        state.evalPageData.data.googleCalendarEvent = payload.googleCalendarEvent;
      }
    });
    builder.addCase(deletePeerFeedbackAction, (state, { payload }) => {
      if (state.evalPageData.data) {
        state.evalPageData.data.peerReviews = state.evalPageData.data.peerReviews
          ? state.evalPageData.data.peerReviews.filter((peer) => peer.id !== payload.feedbackId)
          : null;
      }
    });
    builder.addCase(deleteClientFeedbackAction, (state, { payload }) => {
      if (state.evalPageData.data) {
        state.evalPageData.data.clientReviews = state.evalPageData.data.clientReviews
          ? state.evalPageData.data.clientReviews.filter((clientReview) => clientReview.id !== payload.feedbackId)
          : null;
      }
    });
    builder.addCase(updateUserPromotion, (state, { payload: { payload: updatedPromotion } }) => {
      if (state.promotion.data && updatedPromotion.evaluationHistoryId) {
        state.promotion.data = {
          ...state.promotion.data,
          ...updatedPromotion,
        };
      }
    });
    builder.addCase(setPromotionInStateAfterCreate, (state, { payload }) => {
      if (!state.promotion.data && payload.evaluationHistoryId) {
        state.promotion.data = payload;
      }
    });
  },
});
export const {
  setUserEvalPageDataLoading,
  resetEvalForm,
  setNextEvalDate,
  setEopLoadingState,
  setEvalNote,
  setEvalPermissions,
  setEvalPromotion,
} = slice.actions;
export default slice.reducer;
