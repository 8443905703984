import { RootState } from '@modules/App/redux/store';
import { CompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { CompensationRange } from '@modules/EvaluationModule/interfaces/CompensationRange';
import { AsyncData } from '@shared/interfaces/asyncData';

export const isAddNewCompensationCompleteSelector = (state: RootState): boolean =>
  state.evaluation.userCompensationPage.isAddNewCompensationComplete;

export const userCompensationRangeSelector = (state: RootState): AsyncData<CompensationRange> =>
  state.evaluation.userCompensationPage.userCompensationRange;

export const userCompensationSelector = (state: RootState): AsyncData<CompensationData> =>
  state.evaluation.userCompensationPage.userCompensation;
