import { RootState } from '@modules/App/redux/store';
import { createSelector, Selector } from '@reduxjs/toolkit';
import { AsyncData } from '@shared/interfaces/asyncData';
import { SortInfo } from '@shared/interfaces/sortInfo';
import { sortData } from '@shared/redux/helpers/sortData';
import { MyTeamEvalData } from '../../EvaluationModule/interfaces/MyTeamEvalData';

export const createGetSortedEvaluationTableDataSelector = (
  getMyTeamEvalData: Selector<RootState, AsyncData<MyTeamEvalData[]>>,
  getSortInfo: Selector<RootState, SortInfo>
): Selector<RootState, AsyncData<MyTeamEvalData[]>> =>
  createSelector([getMyTeamEvalData, getSortInfo], ({ state, data }, sortInfo) => {
    const filteredData = data?.filter(({ permissions }) => permissions.isReadable) || [];

    return {
      data: sortData(filteredData, sortInfo),
      state,
    };
  });
