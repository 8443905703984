import { MyTeamEvalData } from '@modules/EvaluationModule/interfaces/MyTeamEvalData';
import { RockyAvatar } from '@shared/components/RockyAvatar';
import { RockyButton } from '@shared/components/RockyButton';
import { RockyModal } from '@shared/components/RockyModal/RockyModal';
import classNames from 'classnames';
import React, { FC, memo, useState } from 'react';
import { useStyles } from './StartEvalModal.styles';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import OpenInNewTabIcon from '@mui/icons-material/OpenInNew';
import { Link } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { StartEvalData } from '@modules/EvaluationModule/interfaces/StartEvalData';
import { RescheduledBlock } from '@modules/EvaluationModule/components/RescheduledBlock';

interface Props {
  evaluation: MyTeamEvalData;
  handleSubmit: (startEvalData: StartEvalData) => void;
  isModalOpen: boolean;
  closeModal: () => void;
}

const StartEvalModalComponent: FC<Props> = ({ evaluation, handleSubmit, isModalOpen, closeModal }) => {
  const styles = useStyles();
  const [isKeepFeedbacks, setIsKeepFeedbacks] = useState<boolean>(true);
  const evaluationRescheduleHistory = evaluation.evaluationRescheduleHistory;
  const isShowEvaluationRescheduleHistory = evaluationRescheduleHistory.length > 0;
  const isRescheduledEvalHasFeedbacks = evaluation.hasFeedbacks;
  const evalMonth = format(parseISO(evaluation.evaluationDate), 'MMMM');

  return (
    <RockyModal
      onClose={closeModal}
      isOpen={isModalOpen}
      title={`Starting ${evalMonth} evaluation`}
      modalStyles={styles.modal}
      modalHeaderStyles={styles.modalHeaderStyles}
      modalTitleStyles={styles.modalTitleStyles}
      modalBodyStyles={styles.modalBody}
      crossIcon
    >
      <div className={styles.userBlock}>
        <RockyAvatar large imageUrl={evaluation.imageUrl} fullName={evaluation.name} />
        <div className={styles.userBlockItem}>
          <h4 className={styles.userNameLabel}>{evaluation.name}</h4>
          <h4 className={styles.positionLabel}>{evaluation.position}</h4>
        </div>
      </div>
      {isShowEvaluationRescheduleHistory && <RescheduledBlock rescheduledEvaluations={evaluationRescheduleHistory} />}
      <div className={styles.furtherStepsBlock}>
        <p className={styles.furtherStepsTitle}>By beginning evaluation process you will:</p>
        <ol className={styles.furtherStepsList}>
          <li>
            {!isRescheduledEvalHasFeedbacks
              ? 'Send an evaluation form to your teammate.'
              : 'Send an evaluation form to your teammate to re-submit their feedback if it was added.'}
          </li>
          <li>
            {!isRescheduledEvalHasFeedbacks
              ? 'Provide your feedback on the teammate via Rocky.'
              : 'Re-submit your feedback if it was added'}
          </li>
          {isRescheduledEvalHasFeedbacks && <li>You can edit peers and clients.</li>}
        </ol>
      </div>
      {isRescheduledEvalHasFeedbacks && (
        <div className={styles.feedbackBlock}>
          <div className={styles.title}>Client and peer feedbacks</div>
          <div className={styles.radioBtns}>
            <div className={styles.radioBtn} onClick={() => setIsKeepFeedbacks(true)}>
              <input type="radio" value="true" checked={isKeepFeedbacks} name="sort" />
              <span>Keep feedbacks</span>
            </div>
            <div className={styles.radioBtn} onClick={() => setIsKeepFeedbacks(false)}>
              <input type="radio" value="false" checked={!isKeepFeedbacks} name="sort" />
              <span>Request new</span>
            </div>
          </div>
          <Link
            target="_blank"
            to={`${paths.users}/${evaluation.userId}/${paths.evaluations}/${evaluation.evaluationId}/${paths.settings}`}
            className={styles.link}
          >
            Review feedbacks
            <OpenInNewTabIcon />
          </Link>
        </div>
      )}
      <div className={styles.modalBtnsContainer}>
        <RockyButton
          className={styles.button}
          onClick={() => {
            closeModal();
          }}
        >
          Cancel
        </RockyButton>
        <RockyButton
          action
          className={classNames(styles.button, styles.actionBtn)}
          onClick={() => {
            handleSubmit({ eraseFeedbacks: !isKeepFeedbacks, evaluationId: evaluation.evaluationId });
            closeModal();
          }}
        >
          Yes, let's begin
        </RockyButton>
      </div>
    </RockyModal>
  );
};

export const StartEvalModal = memo(StartEvalModalComponent);
