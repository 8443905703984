import React, { FC, useCallback, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import { useStyles } from './PeopleHappinessGroupedTableRow.styles';
import { UserRate } from '@modules/HappinessModule/components/UserRate/UserRate';
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess';
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore';
import { Stack, Typography } from '@mui/material';
import { COLORS } from '@styles/colors';
import { PieChart } from '@modules/HappinessModule/components/PieChart';
import { PeopleHappinessGroupedData } from '@modules/HappinessModule/pages/PeopleHappinessPage/interfaces/PeopleHappinessDataItem';
import { PeopleHappinessTableRow } from '../../../PeopleHappinessTableRow';
import { PeopleHappinessVsPreviousReaction } from '../../../PeopleHappinessVsPreviousReaction';
import { instanceOfPeopleHappinessGroupedData } from '@modules/HappinessModule/pages/PeopleHappinessPage/helpers/typeChecks';
import { PeopleHappinessDataGroupBy } from '@modules/HappinessModule/pages/PeopleHappinessPage/enums/PeopleHappinessDataGroupBy';
import { RockyFlag } from '@shared/components/RockyFlag';
import { Size } from '@shared/enums/sizes';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import { METRO_AREA_REMOTE } from '@modules/App/pages/UserProfilePage/componenes/EditUserAddressComponent/constants/metroAreasConstants';

interface Props {
  rowData: PeopleHappinessGroupedData;
  groupBy: PeopleHappinessDataGroupBy;
  depth?: number;
}

const DEPTH_STEP = 20;
const INIT_DEPTH_STEP = 16;

export const PeopleHappinessGroupedTableRow: FC<Props> = ({ rowData, groupBy, depth = 0 }) => {
  const styles = useStyles();
  const {
    id,
    name,
    averageReaction,
    prevAverageReaction,
    children,
    totalMembers,
    membersResponded,
    prevMembersResponded,
    expanded,
  } = rowData;

  const [open, setOpen] = useState(expanded);

  const handleOpenState = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const votedDifference = prevMembersResponded ? Math.abs(membersResponded - prevMembersResponded) : null;
  const votedDefferencePercent = prevMembersResponded
    ? Math.abs((1 - membersResponded / prevMembersResponded) * 100).toFixed(0)
    : null;
  const votedPercent = Number((membersResponded / totalMembers).toFixed(2));

  const isCurrentMembersRespondedBiggerThenPrev = membersResponded > prevMembersResponded;
  const signElement = (condition: boolean) => <span>{condition ? '+' : '-'}</span>;

  return (
    <>
      <tr
        className={styles.tableDataRow}
        onClick={handleOpenState}
        style={{
          backgroundColor: open ? COLORS.HIGHLIGHTS.NEUTRAL : COLORS.WHITE,
        }}
        key={id}
      >
        <td
          style={{
            paddingLeft: depth ? depth + INIT_DEPTH_STEP : INIT_DEPTH_STEP,
          }}
        >
          <Stack flexDirection="row" alignItems="center">
            <IconButton aria-label="expand row" size="small" onClick={handleOpenState}>
              {open ? <UnfoldLessIcon fontSize="small" /> : <UnfoldMoreIcon fontSize="small" />}
            </IconButton>
            <Stack flexDirection="row" alignItems="center" columnGap="6px">
              {groupBy === PeopleHappinessDataGroupBy.Location && name === METRO_AREA_REMOTE ? (
                <CottageOutlinedIcon fontSize="medium" color="secondary" />
              ) : (
                <RockyFlag country={name} size={Size.Large} />
              )}
              <div>{name}</div>
            </Stack>
          </Stack>
        </td>
        <td>
          <div className={styles.rateHolder}>
            <UserRate rate={averageReaction}></UserRate>
          </div>
        </td>
        <td>
          <PeopleHappinessVsPreviousReaction currentReaction={averageReaction} previousReaction={prevAverageReaction} />
        </td>
        <td>
          <Stack flexDirection="row" columnGap="6px" alignItems="center">
            <Typography variant="body2">
              <PieChart ratio={votedPercent} onlyIndicator />
            </Typography>
            <Typography variant="body2">
              {membersResponded}/{totalMembers}
            </Typography>
            {prevMembersResponded && (
              <Typography variant="body2">
                {membersResponded !== prevMembersResponded ? (
                  <Typography
                    component="span"
                    sx={{
                      color: isCurrentMembersRespondedBiggerThenPrev ? COLORS.STATUS.SUCCESS : COLORS.STATUS.CRITICAL,
                    }}
                  >
                    {votedDifference && (
                      <>
                        {signElement(isCurrentMembersRespondedBiggerThenPrev)}
                        {votedDifference}
                      </>
                    )}{' '}
                    {votedDefferencePercent && (
                      <>
                        ({signElement(isCurrentMembersRespondedBiggerThenPrev)}
                        {votedDefferencePercent}%)
                      </>
                    )}
                  </Typography>
                ) : (
                  'No change'
                )}
              </Typography>
            )}
          </Stack>
        </td>
      </tr>

      {open
        ? children.map((child) =>
            !instanceOfPeopleHappinessGroupedData(child) ? (
              <PeopleHappinessTableRow depth={depth + DEPTH_STEP} key={child.id} dataItem={child} displayPrevReaction />
            ) : (
              <PeopleHappinessGroupedTableRow
                key={child.name}
                depth={depth + DEPTH_STEP}
                groupBy={groupBy}
                rowData={child}
              />
            )
          )
        : null}
    </>
  );
};
