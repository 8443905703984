import { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Grid, Stack, Typography, Tooltip, Divider, IconButton } from '@mui/material';
import AutoFixHighOutlinedIcon from '@mui/icons-material/AutoFixHighOutlined';
import CakeOutlinedIcon from '@mui/icons-material/CakeOutlined';
import CommuteOutlinedIcon from '@mui/icons-material/CommuteOutlined';
import ContactPhoneOutlinedIcon from '@mui/icons-material/ContactPhoneOutlined';
import CottageOutlinedIcon from '@mui/icons-material/CottageOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import FolderSharedOutlinedIcon from '@mui/icons-material/FolderSharedOutlined';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import LockPersonOutlinedIcon from '@mui/icons-material/LockPersonOutlined';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import PermPhoneMsgOutlinedIcon from '@mui/icons-material/PermPhoneMsgOutlined';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import EmojiPeopleRounded from '@mui/icons-material/EmojiPeopleRounded';
import { ReactComponent as TShirtIcon } from '@assets/icons/TShirtIcon.svg';

import { useStyles } from './UserProfilePage.styles';
import { EditUserContactModal } from './componenes/EditUserContactModal/EditUserContactModal';
import { METRO_AREA_REMOTE } from './componenes/EditUserAddressComponent/constants/metroAreasConstants';
import { UserInfoRow } from './componenes/UserInfoRow';
import { userProfileDataSelector } from './redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { DataErrorView } from '@shared/components/DataErrorView';
import { UserEmailComponent } from '@modules/App/pages/UserProfilePage/componenes/UserEmailComponent/UserEmailComponent';
import { paths } from '@shared/enums/paths';
import { RockyFlag } from '@shared/components/RockyFlag';
import DeliveryAddressType from '@shared/enums/DeliveryAddressType';
import { UserInitiatives } from './componenes/UserInitiatives';
import ProjectsList from './componenes/ProjectsList/ProjectsList';
import { userPermissionsSelector } from '@modules/App/redux/permissions/selectors';
import { isZohoPeopleUpdateDisabled } from '@configs/environment';
import { ZOHO_PEOPLE_UPDATE_DISABLED_INFO } from '@shared/consts/constants';
import { getBirthDay, getDateOfJoining } from '@shared/helpers/date';
import { useTitle } from '@shared/hooks/useTitle';
import { pageTitles } from '@shared/enums/pageTitles';
import { userDataSelector } from '../UserPage/redux/selectors';

export const UserProfilePage: FC = () => {
  const styles = useStyles();
  const { data: user, state, error } = useSelector(userProfileDataSelector);
  const { data: userGeneralData } = useSelector(userDataSelector);
  const isLoading = state === DataState.Pending;
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const userName = userGeneralData?.name;

  const { data: userPermissions } = useSelector(userPermissionsSelector);

  const setPageTitle = useTitle();

  useEffect(() => {
    if (userName) {
      setPageTitle(`${userName} ${pageTitles.profile}`);
    }
  }, [userName]);

  const permissions = userPermissions?.userInfoPermissions;

  const initialValues = {
    mobilePhone: user ? user.mobilePhone : '',
    personalEmail: user ? user.personalEmail : '',
    emergencyContactPhone: user ? user.emergencyContactPhone : '',
    emergencyContactName: user ? user.emergencyContactName : '',
  };
  const isMetroAreaRemote = user?.selectedMetroArea === METRO_AREA_REMOTE;

  const currentCityFieldValue = user?.selectedMetroArea
    ? user?.selectedMetroArea === METRO_AREA_REMOTE
      ? `${user?.physicalAddress.city}, ${user?.physicalAddress.country}`
      : user?.selectedMetroArea === user?.physicalAddress.city
      ? user?.physicalAddress.city
      : `${user?.physicalAddress.city}, ${user?.selectedMetroArea}`
    : user?.physicalAddress.city;

  const deliveryAddrs = user?.deliveryAddress?.data || {};

  return state === DataState.Rejected ? (
    <DataErrorView error={error} />
  ) : (
    <>
      {/* TODO: change to <Grid> for easier layout handling */}
      <div className={styles.root}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Stack spacing={4}>
              <UserInfoRow
                title="Joined AgileEngine"
                icon={<StarBorderRoundedIcon />}
                value={getDateOfJoining(user?.dateOfJoin ?? null)}
                isLoading={isLoading}
              />
              <UserInfoRow
                title="Main technology"
                icon={<AutoFixHighOutlinedIcon />}
                value={user?.mainTech}
                isLoading={isLoading}
              />
              <UserInfoRow
                title="Projects"
                icon={<GroupsOutlinedIcon />}
                value={user?.projects && user.projects?.length > 0 ? <ProjectsList user={user} /> : null}
                isLoading={isLoading}
                isFeedback
              />
              {user && (
                <UserInfoRow
                  title="Initiatives"
                  icon={<EmojiPeopleRounded />}
                  value={<UserInitiatives initiatives={user?.initiatives}></UserInitiatives>}
                  isLoading={isLoading}
                />
              )}
            </Stack>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <Stack spacing={4}>
              <UserInfoRow
                title="Current city"
                icon={<PlaceOutlinedIcon />}
                value={
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Typography variant="body1" component="span">
                      {currentCityFieldValue}
                    </Typography>
                    {user?.selectedMetroArea && (
                      <>
                        <Typography variant="body1" component="span" color="text.disabled">
                          {isMetroAreaRemote ? 'remote' : 'metro area'}
                        </Typography>
                        {isMetroAreaRemote ? (
                          <CottageOutlinedIcon fontSize="small" color="secondary" sx={{ opacity: 0.5 }} />
                        ) : (
                          <CommuteOutlinedIcon fontSize="small" color="secondary" sx={{ opacity: 0.5 }} />
                        )}
                      </>
                    )}
                  </Stack>
                }
                isLoading={isLoading}
              />
              <UserInfoRow
                title="Birthday"
                icon={<CakeOutlinedIcon />}
                value={getBirthDay(user?.dateOfBirth ?? null)}
                isLoading={isLoading}
              />
              <UserInfoRow
                title="Email"
                icon={<EmailOutlinedIcon />}
                value={<UserEmailComponent email={user?.email} />}
                isLoading={isLoading}
              />
            </Stack>
          </Grid>
          {(permissions?.legalLocation.isReadable || permissions?.personal.isReadable) && (
            <Grid item xs={12} xl={6} mt={{ xs: 4, xl: 0 }} className={styles.infoSensitive}>
              <Grid container spacing={4}>
                {permissions?.personal.isReadable && (
                  <Grid item xs={12} md={6} lg={4} xl={6}>
                    <Stack spacing={4}>
                      <Stack spacing={1} direction="row" alignItems="center" marginY={-1}>
                        <Typography variant="h3">Personal contacts</Typography>
                        {permissions?.personal.isUpdatable && (
                          <Tooltip
                            title={isZohoPeopleUpdateDisabled ? ZOHO_PEOPLE_UPDATE_DISABLED_INFO : 'Edit contacts'}
                            placement="top"
                          >
                            <IconButton
                              aria-label="edit"
                              size="small"
                              color="primary"
                              onClick={() => setIsEditModalOpen(true)}
                              disabled={isZohoPeopleUpdateDisabled}
                              sx={{
                                '&.Mui-disabled': {
                                  pointerEvents: 'auto',
                                },
                              }}
                            >
                              <ModeEditIcon fontSize="inherit" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Stack>
                      <UserInfoRow
                        title="Mobile phone"
                        icon={<PermPhoneMsgOutlinedIcon />}
                        value={user?.mobilePhone}
                        isLoading={isLoading}
                      />
                      <UserInfoRow title="Personal email" icon={<EmailOutlinedIcon />} value={user?.personalEmail} />
                      <UserInfoRow
                        title="Emergency contact"
                        icon={<ContactPhoneOutlinedIcon />}
                        value={`${user?.emergencyContactPhone}, ${user?.emergencyContactName}`}
                      />
                    </Stack>
                  </Grid>
                )}

                {(permissions?.personal.isReadable || permissions.legalLocation.isReadable) && (
                  <Grid item xs={12} md={6} lg={4} xl={6}>
                    <Stack spacing={4}>
                      <Stack spacing={1} direction="row" alignItems="center" marginY={-1}>
                        <Typography variant="h3">Addresses</Typography>
                        {permissions?.personal.isUpdatable && (
                          <Tooltip
                            title={isZohoPeopleUpdateDisabled ? ZOHO_PEOPLE_UPDATE_DISABLED_INFO : 'Edit addresses'}
                            placement="top"
                          >
                            <Link to={paths.edit}>
                              <IconButton
                                aria-label="edit"
                                size="small"
                                color="primary"
                                disabled={isZohoPeopleUpdateDisabled}
                                sx={{
                                  '&.Mui-disabled': {
                                    pointerEvents: 'auto',
                                  },
                                }}
                              >
                                <ModeEditIcon fontSize="inherit" />
                              </IconButton>
                            </Link>
                          </Tooltip>
                        )}
                      </Stack>
                      {permissions?.personal.isReadable && (
                        <UserInfoRow
                          title="Physical address"
                          icon={<HomeOutlinedIcon />}
                          value={
                            <div>
                              {user?.physicalAddress.addressLine1 && user?.physicalAddress.addressLine2
                                ? `${user?.physicalAddress.addressLine1}, ${user?.physicalAddress.addressLine2}`
                                : user?.physicalAddress.addressLine1 || user?.physicalAddress.addressLine2 || null}
                              {(user?.physicalAddress.addressLine1 || user?.physicalAddress.addressLine2) && <br />}
                              {`${user?.physicalAddress.city}${
                                user?.physicalAddress.zipCode && ' ' + user?.physicalAddress.zipCode
                              }, ${user?.physicalAddress.country}`}
                            </div>
                          }
                        />
                      )}

                      {permissions?.personal.isReadable && (
                        <UserInfoRow
                          title="Delivery location"
                          icon={<LocalShippingIcon />}
                          value={
                            user?.deliveryAddress.type === DeliveryAddressType['Physical address'] ? (
                              <div>Physical address</div>
                            ) : user?.deliveryAddress.type === DeliveryAddressType['Different mailing address'] ? (
                              <div>
                                {deliveryAddrs.addressLine1 && deliveryAddrs.addressLine2
                                  ? `${deliveryAddrs.addressLine1}, ${deliveryAddrs.addressLine2}`
                                  : deliveryAddrs.addressLine1 || deliveryAddrs.addressLine2 || null}
                                {(deliveryAddrs.addressLine1 || deliveryAddrs.addressLine2) && <br />}
                                {`${deliveryAddrs.city}${deliveryAddrs.zipCode && ' ' + deliveryAddrs.zipCode}, ${
                                  deliveryAddrs.country
                                }`}
                              </div>
                            ) : (
                              <div>
                                {deliveryAddrs.country}, Nova Poshta <br />
                                {deliveryAddrs.cityAndWarehouseNumber}, {deliveryAddrs.fullName}
                                <br />
                                {deliveryAddrs.phoneNumber}
                              </div>
                            )
                          }
                        />
                      )}

                      {permissions?.personal.isReadable && user?.clothesSize && (
                        <UserInfoRow
                          title="Clothes size"
                          icon={<TShirtIcon />}
                          value={<div>{user?.clothesSize}</div>}
                        />
                      )}

                      {permissions?.personal.isReadable && user?.officeCountry && (
                        <UserInfoRow
                          title="Legal country"
                          icon={<PublicOutlinedIcon />}
                          value={
                            <div>
                              {user?.officeCountry} <RockyFlag country={user?.officeCountry} />
                            </div>
                          }
                        />
                      )}
                      {permissions.legalLocation.isReadable && (
                        <UserInfoRow
                          title="Legal address"
                          icon={<FolderSharedOutlinedIcon />}
                          value={<div className={styles.legalAddress}>{user?.legalAddress}</div>}
                        />
                      )}
                    </Stack>
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Divider sx={{ mb: 2, mt: -1, mr: 4 }} />
                  <Stack direction="row" alignItems="center" spacing={1.5} className={styles.infoSensitiveHint}>
                    <LockPersonOutlinedIcon />
                    <Typography variant="body2">
                      {permissions.legalLocation.isReadable
                        ? 'Visible only to Finance'
                        : 'Visible only to you, HR, and management'}
                    </Typography>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </div>
      <EditUserContactModal isOpen={isEditModalOpen} setIsOpen={setIsEditModalOpen} initialValues={initialValues} />
    </>
  );
};
