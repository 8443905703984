import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    paddingBottom: 12,
    flexDirection: 'column',
  },
  infoCardsHolder: {
    width: '100%',
    display: 'flex',
    gap: 12,
    paddingBottom: 12,

    '& > *': {
      flexGrow: 1,
      width: '50%',
    },
  },
  surveyQueueBlockHolder: {
    flexGrow: 1,
  },
  loaderHolder: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    flexDirection: 'column',
  },
  '@keyframes blink': {
    '0%': { opacity: 0.3 },
    '50%': { opacity: 0.8 },
    '100%': { opacity: 0.3 },
  },
  loadingText: {
    color: COLORS.TEXT.SECONDARY,
    fontWeight: 400,
    fontSize: 13,
    animation: '$blink 1.5s linear infinite',
  },
});
