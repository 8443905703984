import { put, all, call, takeLatest } from 'redux-saga/effects';
import { getUserPersonalData } from './actions';
import { DataState } from '@shared/enums/DataState';
import { fetchUserProfilePartialData } from '@modules/App/api/userProfileData';
import { UserMainData } from '@shared/interfaces/user';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { setUserPersonalData } from './reducers';

function* getUserPersonalDataAsync({ payload }: ReturnType<typeof getUserPersonalData>) {
  try {
    const response: UserMainData = yield call(fetchUserProfilePartialData, payload);
    const action = setUserPersonalData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setUserPersonalData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* watchGetUserPersonalData() {
  yield takeLatest(getUserPersonalData.type, getUserPersonalDataAsync);
}

export function* userPageSaga(): Generator {
  yield all([watchGetUserPersonalData()]);
}
