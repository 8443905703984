export enum NewHomeResolution {
  ProjectFound = 'Project Found',
  StaysOnCurrentProject = 'Stays on current project',
  LeftAENoMatchingPositions = 'Left AE: No matching positions',
  LeftAEAnotherJO = 'Left AE: Another JO',
  LeftAE2PlusInterviewsFailed = 'Left AE: 2+ interviews failed',
  LeftAEAllOptionsRejectedByPerson = 'Left AE: All options rejected by person',
  OtherMilitaryService = 'Other: military service',
  OtherRelocatedToACountryNotSupportedByAE = 'Other: relocated to a country not supported by AE',
}
