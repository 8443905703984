import React, { useState } from 'react';
import { useStyles } from './PeerEvalFormClosed.styles';
import { PeerFormData } from '@modules/EvaluationModule/interfaces/PeerEvalData';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { RockyButton } from '@shared/components/RockyButton';
import MarkChatReadIcon from '@mui/icons-material/MarkChatRead';
import SpeakerNotesOffIcon from '@mui/icons-material/SpeakerNotesOff';
import { RockyModal } from '@shared/components/RockyModal';
import { useModalState } from '@shared/hooks/useModalState';
import { useDispatch } from 'react-redux';
import { setReviewPeerFeedback } from '../../redux/actions';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { useParams } from 'react-router-dom';
import { questionBlockFieldsDescriptions } from '@modules/EvaluationModule/pages/EvalPage/constants/questionBlockFieldsDescriptions';
import get from 'lodash/get';
import { useAuth } from '@shared/hooks/useAuth';
import { getColorByFeedbackMark } from '@modules/EvaluationModule/helpers/getColorByFeedbackMark';
import { Textarea } from '@shared/components/Textarea';
import { EvalMarkTitleByValue } from '@modules/EvaluationModule/constants/EvalMarkTitleByValue';
import { Comment } from '@modules/EvaluationModule/pages/EvalSummaryPage/components/Comment';
import { Stack, Typography } from '@mui/material';
import { HelperMsgForAnonymousFeedback } from '../HelperMsgForAnonymousFeedback';

interface Props {
  data: PeerFormData;
  reviewStatus: string;
  setReviewStatus: (status: string) => void;
  hasApprovalPermissions: boolean;
}

export const PeerEvalFormClosed: React.FC<Props> = ({
  data,
  reviewStatus,
  setReviewStatus,
  hasApprovalPermissions,
}) => {
  const styles = useStyles();
  const {
    openModal: openAttentionModal,
    closeModal: closeAttentionModal,
    isModalOpen: isAttentionModalOpen,
  } = useModalState();
  const dispatch = useDispatch();
  const { evalId, feedbackId } = useParams();
  const [reviewerNotes, setReviewerNotes] = useState('');
  const { currentUser } = useAuth();
  const { evaluatee, evaluator, status, evaluation, isAnonymous } = data;

  const isManager = evaluatee.primaryReportsTo.id === currentUser?.id;
  const isPeer = evaluator.id === currentUser?.id;

  const handleSubmitReview = () => {
    if (evalId && feedbackId) {
      closeAttentionModal();
      setReviewStatus(EvalFeedbackStatus.Rejected);
      dispatch(
        setReviewPeerFeedback({ evalId, feedbackId, status: EvalFeedbackStatus.Rejected, reviewerNotes: reviewerNotes })
      );
    }
    return;
  };

  const handleClickApprove = () => {
    if (evalId && feedbackId) {
      setReviewStatus(EvalFeedbackStatus.Approved);
      dispatch(
        setReviewPeerFeedback({
          evalId,
          feedbackId,
          status: EvalFeedbackStatus.Approved,
          reviewerNotes,
        })
      );
    }
    return;
  };

  const handleClickReject = () => {
    openAttentionModal();
  };

  const handleOnChange: React.ChangeEventHandler<HTMLTextAreaElement> = (e): void => {
    setReviewerNotes(e.currentTarget.value);
  };

  const getmMessageByCurrentStatusOfFeedback = (status: EvalFeedbackStatus): string => {
    return status === EvalFeedbackStatus.Approved
      ? 'approved'
      : status === EvalFeedbackStatus.Rejected
      ? 'rejected'
      : EvalFeedbackStatus.Review
      ? 'on review'
      : '';
  };
  return (
    <div>
      {isManager && !isAnonymous ? null : (
        <Stack className={styles.anonymusBlock}>
          <Typography variant="body1">{`${isAnonymous ? 'Anonymous' : 'Personalized'} feedback`}</Typography>
          <HelperMsgForAnonymousFeedback isAnonymous={isAnonymous} isManager={isManager} peerReviewData={data} />
        </Stack>
      )}
      <div className={styles.mainBlock}>
        <div className={styles.questionsContainer}>
          {questionBlockFieldsDescriptions.map(({ questionName, questionTitle, questionMark, questionComments }, i) => (
            <div key={i} className={styles.questionContainer}>
              <p className={styles.questionTitle}>{questionTitle}</p>
              <div
                className={styles.markLabel}
                style={{
                  backgroundColor: getColorByFeedbackMark(get(evaluation, questionName).mark as EvaluationMark),
                }}
              >
                {get(evaluation, questionName).mark}
              </div>
              {get(evaluation, questionComments) && (
                <Comment
                  triggerClassName={styles.commentTrigger}
                  title={EvalMarkTitleByValue[get(evaluation, questionMark) as EvaluationMark]}
                  comment={get(evaluation, questionComments)}
                  user={evaluator}
                />
              )}
            </div>
          ))}
        </div>
        <div>
          <p className={styles.notesTitle}>What {evaluatee.name} should focus on improving</p>
          <p className={styles.notesText}>
            {evaluation.professionalGoals ? evaluation.professionalGoals : 'Not commented'}
          </p>
          <p className={styles.notesTitle}>
            What is the most valuable contribution {evaluatee.name} made to you and your team
          </p>
          <p className={styles.notesText}>{evaluation.achievements ? evaluation.achievements : 'Not commented'}</p>
          <p className={styles.notesTitle}>If you have any additional comments please share below</p>
          <p className={styles.notesText}>{evaluation.notes ? evaluation.notes : 'Not commented'}</p>
        </div>
      </div>
      {isPeer && evaluation.isConfirmed && (
        <div className={styles.summary}>
          <span>{`Evaluation is ${getmMessageByCurrentStatusOfFeedback(status as EvalFeedbackStatus)}.`}</span>
          Thank you for your response!
        </div>
      )}
      {hasApprovalPermissions && status !== EvalFeedbackStatus.Rejected && status !== EvalFeedbackStatus.Approved && (
        <div className={styles.reviewBtns}>
          <RockyButton disabled={reviewStatus !== ''} action className={styles.approveBtn} onClick={handleClickApprove}>
            {reviewStatus === EvalFeedbackStatus.Approved ? (
              <div className={styles.spinner}></div>
            ) : (
              <>
                <MarkChatReadIcon />
                Approve feedback
              </>
            )}
          </RockyButton>
          <RockyButton
            disabled={reviewStatus !== ''}
            dangerInverted
            className={styles.rejectBtn}
            onClick={handleClickReject}
          >
            {reviewStatus === EvalFeedbackStatus.Rejected ? (
              <div className={styles.spinner}></div>
            ) : (
              <>
                <SpeakerNotesOffIcon />
                Send for revision
              </>
            )}
          </RockyButton>
        </div>
      )}
      <RockyModal title="Add revision note" isOpen={isAttentionModalOpen} onClose={closeAttentionModal} crossIcon>
        <h2 className={styles.notesLabel}>Note</h2>
        <Textarea className={styles.notesField} isAutosize onChange={handleOnChange} value={reviewerNotes}></Textarea>
        <p className={styles.notesAttention}>
          {evaluator.name} will receive email with this note and request to update their feeback.
        </p>
        <div className={styles.btnContainer}>
          <RockyButton onClick={closeAttentionModal} className={styles.cancelBtn}>
            Cancel
          </RockyButton>
          <RockyButton
            disabled={reviewerNotes === ''}
            inverted
            onClick={handleSubmitReview}
            className={styles.notifyBtn}
          >
            Notify peer
          </RockyButton>
        </div>
      </RockyModal>
    </div>
  );
};
