import { createAction } from '@reduxjs/toolkit';
import { SLICE_NAME } from './constants';
import { EditClientSurveyResponseData } from '@modules/HappinessModule/interfaces/EditClientSurveyResponseData';
import { GetClientSurveyResponseData } from '@modules/HappinessModule/interfaces/GetClientSurveyResponseData';

export const loadClientSurveyResponse = createAction<GetClientSurveyResponseData>(
  `${SLICE_NAME}/loadClientSurveyResponse`
);
export const updateClientSurveyResponse = createAction<EditClientSurveyResponseData>(
  `${SLICE_NAME}/updateClientSurveyResponse`
);
export const revokeClientSurveyResponse = createAction(`${SLICE_NAME}/revokeClientSurveyResponse`);
export const updateDmNotes = createAction<string>(`${SLICE_NAME}/updateDmNotes`);
