import httpNodeClient from '@shared/api/httpNodeClient';
import { ClientSurveyResponseData } from '../interfaces/ClientSurveyResponseData';
import { EditClientSurveyResponseData } from '../interfaces/EditClientSurveyResponseData';
import { GetClientSurveyResponseData } from '../interfaces/GetClientSurveyResponseData';

export const getClientSurveyResponseData = ({
  clientSurveyResponseId,
}: GetClientSurveyResponseData): Promise<ClientSurveyResponseData> =>
  httpNodeClient
    .get<ClientSurveyResponseData>(`/client-survey-responses/${clientSurveyResponseId}`)
    .then(({ data }) => data);

export const patchClientSurveyResponseData = ({
  clientSurveyResponseId,
  payload,
}: {
  clientSurveyResponseId: string;
  payload: EditClientSurveyResponseData;
}): Promise<ClientSurveyResponseData> =>
  httpNodeClient
    .patch<ClientSurveyResponseData>(`/client-survey-responses/${clientSurveyResponseId}`, payload)
    .then(({ data }) => data);

export const postRevokeClientSurveyResponse = ({
  clientSurveyResponseId,
}: GetClientSurveyResponseData): Promise<ClientSurveyResponseData> =>
  httpNodeClient
    .post<ClientSurveyResponseData>(`/client-survey-responses/${clientSurveyResponseId}/revoke`)
    .then(({ data }) => data);

export const patchDmNotes = ({
  clientSurveyResponseId,
  payload,
}: {
  clientSurveyResponseId: string;
  payload: string;
}): Promise<ClientSurveyResponseData> =>
  httpNodeClient
    .patch<ClientSurveyResponseData>(`/client-survey-responses/${clientSurveyResponseId}/notes`, {
      managerNotes: payload,
    })
    .then(({ data }) => data);
