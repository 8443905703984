import { UsersHappinessDataItem } from '../interfaces/usersHappinessDataItem';
import { HappinessData } from '../interfaces/happinessData';
import { UsersHappinessData } from './types';
import httpClient from '@shared/api/httpClient';
import { apiUrl } from '@configs/environment';
import { resolvedByTypeToUserInfo } from './helpers/resolvedByTypeToUserInfo';

export const fetchMyTeamHappinessData = (questionId: string): Promise<HappinessData<UsersHappinessDataItem>> =>
  httpClient
    .get<UsersHappinessData>(`${apiUrl}/my-reporters/`, { params: { question_history_id: questionId } })
    .then((resp) => {
      const survey = resp.data.data.survey.map((r) => {
        const {
          message_id,
          user_id,
          email,
          full_name,
          position,
          imageUrl,
          reaction,
          reaction_prev,
          reminder_count,
          comments,
          resolved_by,
          country,
          city,
          is_primary_reporter,
          flag,
        } = r;

        return {
          id: message_id,
          userId: user_id,
          name: full_name,
          mark: reaction,
          prevMark: reaction_prev,
          reminderCount: reminder_count,
          flag,
          email,
          imageUrl,
          position,
          comments,
          resolvedBy: resolvedByTypeToUserInfo(resolved_by),
          country: country,
          city: city,
          isPrimaryReporter: is_primary_reporter,
        };
      });

      return {
        survey,
        question: resp.data.data.question,
      };
    });
