import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import React, { FC, memo } from 'react';
import { SurveyInfo } from '../../interfaces';
import { useStyles } from './LastSurveyCard.styles';

interface Props {
  lastSurvey: SurveyInfo;
}

const LastSurveyCardComponent: FC<Props> = ({ lastSurvey }) => {
  const styles = useStyles();
  const dateFormatted = format(parseISO(lastSurvey.date), 'dd MMM yyyy');

  return (
    <div className={styles.root}>
      <h1 className={styles.cardHeader}>Last asked</h1>
      <h2 className={styles.categoryText}>{lastSurvey.categoryName}</h2>
      <h3 className={styles.questionText}>{lastSurvey.questionText}</h3>
      <div className={styles.dateHolder}>
        <span className={styles.dateText}>{dateFormatted}</span>
      </div>
    </div>
  );
};

export const LastSurveyCard = memo(LastSurveyCardComponent);
