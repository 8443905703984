import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  mainText: {
    color: COLORS.TEXT.PRIMARY,
    fontSize: '24px',
    lineHeight: '120%',
    fontWeight: '500',
    marginBottom: '4px',

    [BREAKPOINTS.MOBILE]: {
      fontSize: '18px',
    },
  },
  secondaryText: {
    color: COLORS.TEXT.SECONDARY,
    fontSize: '13px',
    fontWeight: '400',
  },
  addBtn: {
    color: COLORS.ACTION.PRIMARY,
    textDecoration: 'underline',
    fontSize: 14,
    fontWeight: 400,
    lineHeight: '21px',
    '& span': {
      display: 'inline-block',
      textDecoration: 'none',
      fontSize: 18,
    },
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 12,
    height: 46,
    backgroundColor: COLORS.HIGHLIGHTS.ACTIONABLE,
    borderRadius: 4,
    padding: '6px 16px',
    textDecoration: 'none',
    cursor: 'pointer',

    '&:hover': {
      color: COLORS.WHITE,
      backgroundColor: COLORS.ACTION.PRIMARY,
    },
  },
  extendedOption: {
    display: 'flex',
    flexDirection: 'column',
  },
});
