import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  linkManageBtnContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
  },
  linkManageBtn: {
    paddingLeft: 12,
    paddingRight: 16,

    '& svg': {
      marginRight: -4,
    },
  },
});
