import React, { FC, memo, useCallback } from 'react';
import { User } from '@shared/interfaces/user';
import { useStyles } from './SidebarContent.styles';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import { ReactComponent as RockyLogoSign } from '@assets/icons/RockyLogoSign.svg';
import { ReactComponent as RockyLogoText } from '@assets/icons/RockyLogoText.svg';
import { ReactComponent as LogoSmall } from '@assets/icons/logo-small.svg';
import { CurrentUserMenu } from '@shared/components/CurrentUserMenu';
import { MenuBurger } from '../MenuBurger';
import { SidebarContentItems } from './../SidebarContentItems';
import classNames from 'classnames';
import { SearchButton } from '@shared/components/SearchButton';
import { useDispatch, useSelector } from 'react-redux';
import { setIsGlobalSearchOverlayOpen } from '@modules/App/redux/users';
import { Link } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { selectIsGlobalUsersSearchOpened } from '@modules/App/redux/users/selectors';

interface Props {
  currentUser: User;
  setIsTabletMenuOpen: (bool: boolean | ((state: boolean) => boolean)) => void;
  isTabletMenuOpen: boolean;
}

const SidebarContentComponent: FC<Props> = ({ currentUser, isTabletMenuOpen = false, setIsTabletMenuOpen }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const isGlobalSearchOverlayOpen = useSelector(selectIsGlobalUsersSearchOpened);

  const handleGlobalSearchOverlayOpen = useCallback(() => {
    setIsTabletMenuOpen(false);
    dispatch(setIsGlobalSearchOverlayOpen(true));
  }, [isGlobalSearchOverlayOpen]);

  return (
    <>
      <Link to={paths.root}>
        <div className={styles.logoContainer}>
          <div
            className={classNames(styles.logo, { [styles.shown]: isTabletMenuOpen })}
            onClick={() => setIsTabletMenuOpen(false)}
          >
            <RockyLogoSign />
            <RockyLogoText />
          </div>
          <div className={classNames(styles.logoSmall, { [styles.hidden]: isTabletMenuOpen })}>
            <LogoSmall className={styles.smallLogo} />
          </div>
        </div>
      </Link>
      <div
        className={classNames(styles.unfoldMenu, { [styles.collapseMenu]: isTabletMenuOpen })}
        onClick={() => setIsTabletMenuOpen(!isTabletMenuOpen)}
      >
        <MenuRoundedIcon />
      </div>
      <div className={styles.searchButton}>
        <SearchButton isTabletMenuOpen={isTabletMenuOpen} onClick={handleGlobalSearchOverlayOpen} />
      </div>
      <div className={styles.navContianer}>
        <SidebarContentItems
          isTabletMenuOpen={isTabletMenuOpen}
          currentUser={currentUser}
          setIsTabletMenuOpen={setIsTabletMenuOpen}
        />
      </div>
      <div onClick={() => setIsTabletMenuOpen(false)} className={isTabletMenuOpen ? styles.borderBottomShown : ''}>
        <CurrentUserMenu isTabletMenuOpen={isTabletMenuOpen} user={currentUser}></CurrentUserMenu>
      </div>
      <div id="header-content-holder" className={styles.contentHolder}></div>
      <div className={styles.menuBurgerContainer}>
        <MenuBurger>
          <SidebarContentItems
            isTabletMenuOpen={isTabletMenuOpen}
            currentUser={currentUser}
            setIsTabletMenuOpen={setIsTabletMenuOpen}
          />
        </MenuBurger>
      </div>
    </>
  );
};

export const SidebarContent = memo(SidebarContentComponent);
