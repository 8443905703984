import React, { memo, useState } from 'react';
import { Form, Formik } from 'formik';
import { useSelector } from 'react-redux';
import { Grid } from '@mui/material';

import { useStyles } from './EvaluationFormResults.styles';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { PrevEvalData } from '@modules/EvaluationModule/pages/EvalPage/components/PrevEvalData';
import { questionBlockFieldsDescriptions } from '@modules/EvaluationModule/pages/EvalPage/constants/questionBlockFieldsDescriptions';
import { useAuth } from '@shared/hooks/useAuth';
import { RockyButton } from '@shared/components/RockyButton';
import { paths } from '@shared/enums/paths';
import { FeedbackOnQuestionBlock } from './components/FeedbackOnQuestionBlock';
import {
  additionalFieldsDescriptions,
  QuestionTitle,
} from '@modules/EvaluationModule/pages/EvalPage/constants/additionalFieldsDescriptions';
import { RockyToggle } from '@shared/components/RockyToggle';
import { InfoView } from '@shared/components/InfoView/InfoView';
import EmptyResponse from '@assets/img/EmptyResponse.png';
import { EvaluationInfoViewTexts } from '@modules/EvaluationModule/enums/EvaluationInfoViewTexts';
import { clientsReviewsShowFilter, peersReviewsShowFilter } from '@modules/EvaluationModule/helpers/reviewsShowFilter';
import { hideAnonymousPeerReviews } from '@modules/EvaluationModule/pages/EvalPage/helpers/hideAnonymousPeerReviews';
import { GoalsDuringEval } from '@modules/EvaluationModule/pages/UserGoalsPage/components/GoalsDuringEval/GoalsDuringEval';
import { QuestionNamesToDisplayOnlyForEvaluatee } from '../EvaluationForm/enums/QuestionNamesToDisplayOnlyForEvaluatee';
import { StickyNotes } from '../StickyNotes/StickyNotes';
import { EvalNotesFormObserver } from '@modules/EvaluationModule/pages/EvalSummaryPage/components/EvaluationNotes/components/EvalNotesFormObserver/EvalNotesFormObserver';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { evalPermissionsSelector } from '@modules/EvaluationModule/pages/EvalPage/redux/selectors';

interface Props {
  evalFormPageData: AsyncData<EvalPageData>;
}

const EvaluationFormResultsComponent: React.FC<Props> = ({ evalFormPageData }) => {
  const { data: evalData } = evalFormPageData;
  const styles = useStyles();
  const prevEvalData = evalData?.previousEvaluation;
  const evaluationRescheduleHistory = evalData?.evaluationRescheduleHistory;
  const evaluateeUserId = evalData?.userId;
  const evaluateeUser = evalData?.userHistory;
  const evaluatorUser = evalData?.owner;
  const evaluateeFeedback = evalData?.evaluation.evaluatee;
  const evaluatorFeedback = evalData?.evaluation.evaluator;
  const peerReviews = evalData?.peerReviews;
  const clientReviews = evalData?.clientReviews;

  const { currentUser } = useAuth();
  const isCurrentUserEvaluatee = currentUser?.id === evaluateeUserId;
  const isCanSeeSummaryPage =
    !isCurrentUserEvaluatee && evaluateeFeedback?.isConfirmed && evaluatorFeedback?.isConfirmed;
  const isEvalClosed = evalData?.status === EvaluationStatus.Closed;
  const [showPeers, setShowPeers] = useState(false);
  const [showClients, setShowClients] = useState(false);
  const [isStickyNotesOpen, setIsStickyNotesOpen] = useState(false);
  const evalPermissions = useSelector(evalPermissionsSelector);

  const peerReviewsFiltered =
    showPeers && peerReviews ? hideAnonymousPeerReviews(peerReviews.filter(peersReviewsShowFilter)) : null;

  const hasUpdatePermissions = evalPermissions.data?.evaluation.isUpdatable;

  const filterGoals = (questionName: QuestionNamesToDisplayOnlyForEvaluatee) =>
    [
      QuestionNamesToDisplayOnlyForEvaluatee.ProjectGoals,
      QuestionNamesToDisplayOnlyForEvaluatee.ProfessionalGoals,
    ].includes(questionName);
  const goalsFieldsDesc = additionalFieldsDescriptions.filter((fieldProps) =>
    filterGoals(fieldProps.questionName as QuestionNamesToDisplayOnlyForEvaluatee)
  );
  const nonGoalsFieldsDesc = additionalFieldsDescriptions.filter(
    (fieldProps) => !filterGoals(fieldProps.questionName as QuestionNamesToDisplayOnlyForEvaluatee)
  );

  const goalsDuringEvalComponentResult = GoalsDuringEval({
    goalCreationAvailable: true,
  });

  const isGoalsDuringEvalComponentResultEmpty = goalsDuringEvalComponentResult === null;

  return (
    <>
      {!evaluateeFeedback?.isConfirmed && !evaluatorFeedback?.isConfirmed ? (
        <div>
          <InfoView inComponent text={EvaluationInfoViewTexts.NoFeedbacksYet} icon={EmptyResponse} />
        </div>
      ) : (
        <div className={styles.root}>
          {evaluateeFeedback?.isConfirmed && evaluatorFeedback?.isConfirmed && (
            <PrevEvalData
              prevEvalData={prevEvalData}
              evaluationRescheduleHistory={evaluationRescheduleHistory}
              userId={evaluateeUserId}
            />
          )}
          <div className={styles.reviews}>
            <h1 className={styles.reviewsTitle}>Reviews</h1>
            <div className={styles.toggles}>
              {peerReviews && peerReviews.length > 0 && peerReviews.some(peersReviewsShowFilter) && (
                <div className={styles.toggle}>
                  <RockyToggle toggleActive={showPeers} setToggleActive={setShowPeers} />
                  <span>Peer reviews</span>
                </div>
              )}
              {clientReviews && clientReviews.length > 0 && clientReviews.some(clientsReviewsShowFilter) && (
                <div className={styles.toggle}>
                  <RockyToggle toggleActive={showClients} setToggleActive={setShowClients} />
                  <span>Client reviews</span>
                </div>
              )}
            </div>
          </div>
          <div className={styles.divider}></div>
          <div>
            {questionBlockFieldsDescriptions.map(({ questionTitle, questionName }) => (
              <FeedbackOnQuestionBlock
                key={questionName}
                withMark
                evaluateeFeedback={evaluateeFeedback}
                evaluatorFeedback={evaluatorFeedback}
                questionName={questionName}
                questionTitle={questionTitle}
                isCurrentUserEvaluator={!isCurrentUserEvaluatee}
                evaluateeUser={evaluateeUser}
                evaluatorUser={evaluatorUser}
                peerReviews={peerReviewsFiltered}
                clientReviews={showClients ? clientReviews?.filter(clientsReviewsShowFilter) : null}
              />
            ))}
          </div>
          <Grid container spacing={2}>
            {!isGoalsDuringEvalComponentResultEmpty && (
              <Grid item xs={12} md={6}>
                {goalsDuringEvalComponentResult}
              </Grid>
            )}
            <Grid item xs={12} md={!isGoalsDuringEvalComponentResultEmpty ? 6 : 12} marginTop="0.375rem">
              {goalsFieldsDesc.map(({ questionTitle, questionName }) => (
                <FeedbackOnQuestionBlock
                  key={questionName}
                  evaluateeFeedback={evaluateeFeedback}
                  evaluatorFeedback={null}
                  questionName={questionName}
                  questionTitle={questionTitle}
                  isCurrentUserEvaluator={!isCurrentUserEvaluatee}
                  evaluateeUser={evaluateeUser}
                  evaluatorUser={evaluatorUser}
                  peerReviews={peerReviewsFiltered}
                  clientReviews={null}
                />
              ))}
            </Grid>
          </Grid>
          {nonGoalsFieldsDesc.map(({ questionTitle, questionName }) => (
            <FeedbackOnQuestionBlock
              key={questionName}
              evaluateeFeedback={evaluateeFeedback}
              evaluatorFeedback={evaluatorFeedback}
              questionName={questionName}
              questionTitle={questionTitle}
              isCurrentUserEvaluator={!isCurrentUserEvaluatee}
              evaluateeUser={evaluateeUser}
              evaluatorUser={evaluatorUser}
              peerReviews={peerReviewsFiltered}
              clientReviews={
                showClients && questionTitle === QuestionTitle.AdditionalNotes
                  ? clientReviews?.filter(clientsReviewsShowFilter)
                  : null
              }
            />
          ))}
          {isCanSeeSummaryPage && (
            <RockyButton to={`${paths.summary}`} className={styles.mainBtn} action>
              View summary →
            </RockyButton>
          )}
          {hasUpdatePermissions && !isEvalClosed && (
            <Formik
              initialValues={{
                notes: evalData?.notes,
              }}
              onSubmit={() => {
                return;
              }}
            >
              <Form>
                <EvalNotesFormObserver />
                <StickyNotes isOpen={isStickyNotesOpen} setIsOpen={setIsStickyNotesOpen} />
              </Form>
            </Formik>
          )}
        </div>
      )}
    </>
  );
};

export const EvaluationFormResults = memo(EvaluationFormResultsComponent);
