import { paths } from '@shared/enums/paths';
import { FC, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { useStyles } from './ReviewerBlock.styles';
import classNames from 'classnames';
import CrossIcon from '@mui/icons-material/Close';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { FeedbackStatus } from '@modules/EvaluationModule/components/FeedbackStatus';
import { EvaluationStatus } from '@modules/EvaluationModule/enums/EvaluationStatus';
import { EvalFeedbackStatus } from '@modules/EvaluationModule/enums/EvalFeedbackStatus';
import { UserInfo } from '@shared/interfaces/UserInfo';
import PlusCircle from '@mui/icons-material/AddCircleOutline';
import { RockyPopup } from '@shared/components/RockyPopup';
import MailIcon from '@mui/icons-material/MailOutline';
import CopyLinkIcon from '@mui/icons-material/Link';
import { useDispatch } from 'react-redux';
import { displayNotification } from '@modules/App/redux/notifications/actions';
import { useModalState } from '@shared/hooks/useModalState';
import { AddNewReviewerWarningModal } from '../AddNewReviewerWarningModal';
import { copyToClipBoard } from '@shared/helpers/copyToClipBoard';
import { DeleteReviewerWarningModal } from '../DeleteReviewerWarningModal';

interface Props {
  user: UserInfo;
  onDelete?: () => void;
  onAdd?: () => void;
  feedbackStatus?: EvalFeedbackStatus;
  evaluationStatus: EvaluationStatus;
  isAskWithEmail?: boolean;
  evalId: string | null;
  feedbackId: string | null;
  isFeedbackSubmitted?: boolean;
  isClient?: boolean;
  isLoading?: boolean;
  showFeedbackStatus?: boolean;
}

export const ReviewerBlock: FC<Props> = ({
  evalId,
  evaluationStatus,
  isLoading,
  onDelete,
  user,
  feedbackStatus,
  onAdd,
  isAskWithEmail,
  isClient,
  feedbackId,
  isFeedbackSubmitted,
  showFeedbackStatus = true,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const {
    openModal: openNewReviewerWarningModal,
    closeModal: closeNewReviewerWarningModal,
    isModalOpen: isNewReviewerWarningModalOpen,
  } = useModalState();
  const {
    openModal: openDeleteReviewerWarningModal,
    closeModal: closeDeleteReviewerWarningModal,
    isModalOpen: isDeleteReviewerWarningModalOpen,
  } = useModalState();

  const isEvalOngoing = evaluationStatus === EvaluationStatus.Ongoing;
  const isEvalUpcoming = evaluationStatus === EvaluationStatus.Upcoming;

  const isReviewerCanBeDeleted =
    !isLoading &&
    onDelete &&
    (evaluationStatus === EvaluationStatus.Upcoming ||
      (!isClient && !isFeedbackSubmitted && feedbackStatus !== EvalFeedbackStatus.Rejected));

  const handleCopyLink = useCallback(
    (e) => {
      if (!isEvalUpcoming) {
        copyToClipBoard(e)(`${window.location.origin}${paths.clientEval}/${feedbackId}`, () => {
          dispatch(displayNotification('Link copied to clipboard'));
        });
      }
    },
    [feedbackId, isEvalUpcoming]
  );

  const userReporterBlock = <UserReporterBlock isShowEmail={isClient} isDetailInfo user={user} />;
  const userItem = (
    <>
      {userReporterBlock}
      <div className={styles.icons} onClick={(e) => e.preventDefault()}>
        {isClient && (
          <RockyPopup
            className={styles.copyLinkPopup}
            trigger={
              <div
                className={classNames(styles.icon, styles.iconCopyLink, { [styles.copyLinkDisabled]: isEvalUpcoming })}
              >
                <CopyLinkIcon onClick={handleCopyLink} />
              </div>
            }
          >
            <span onClick={handleCopyLink}>
              {isEvalUpcoming ? 'Link will be available once evaluation is started' : 'Copy link'}
            </span>
          </RockyPopup>
        )}
        {isAskWithEmail && (
          <RockyPopup
            trigger={
              <div className={classNames(styles.icon, styles.iconMail)}>
                <MailIcon />
              </div>
            }
          >
            Asked with email
          </RockyPopup>
        )}
        {feedbackStatus && showFeedbackStatus && <FeedbackStatus status={feedbackStatus} />}
        {isReviewerCanBeDeleted && (
          <div>
            <div
              className={classNames(styles.icon, styles.removeIcon)}
              onClick={() => {
                if (isEvalOngoing) {
                  openDeleteReviewerWarningModal();
                  return;
                }
                onDelete();
              }}
            >
              <CrossIcon />
            </div>
          </div>
        )}
      </div>
    </>
  );

  return (
    <div
      className={classNames({
        [styles.loading]: isLoading,
      })}
    >
      {!feedbackStatus && onAdd ? (
        <div
          className={classNames(styles.root)}
          onClick={() => {
            if (isEvalOngoing) {
              openNewReviewerWarningModal();
              return;
            }
            onAdd();
          }}
        >
          {userReporterBlock}
          {!isLoading && (
            <div className={classNames(styles.icon, styles.addIcon)}>
              <PlusCircle />
            </div>
          )}
        </div>
      ) : feedbackStatus === EvalFeedbackStatus.Pending || feedbackStatus === EvalFeedbackStatus.Rejected ? (
        <div className={classNames(styles.root, styles.noClick)}>{userItem}</div>
      ) : (
        <Link
          to={`${isClient ? paths.clientEval : paths.peerForm}/${isClient ? feedbackId : evalId}${
            isClient ? '' : '/' + feedbackId
          }`}
          className={classNames(styles.root, styles.link)}
        >
          {userItem}
        </Link>
      )}
      {onDelete && (
        <DeleteReviewerWarningModal
          isModalOpen={isDeleteReviewerWarningModalOpen}
          closeModal={closeDeleteReviewerWarningModal}
          onDelete={onDelete}
        />
      )}
      {onAdd && (
        <AddNewReviewerWarningModal
          isModalOpen={isNewReviewerWarningModalOpen}
          closeModal={closeNewReviewerWarningModal}
          onAdd={onAdd}
          isClient={isClient}
        />
      )}
    </div>
  );
};
