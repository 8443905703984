import * as Yup from 'yup';
import { EvaluationMark } from '@modules/EvaluationModule/enums/EvaluationMark';
import { questionBlockFieldsDescriptions } from '@modules/EvaluationModule/pages/EvalPage/constants/questionBlockFieldsDescriptions';

const EMPTY_FIELD_ERROR_TEXT = 'Please select your response';
const NEEDS_DEVELOPMENT_ERROR_TEXT = 'You must specify what exactly needs development, it sounds serious.';

const fieldsSchema = questionBlockFieldsDescriptions.reduce(
  (res, field) => ({
    ...res,
    [field.questionName]: Yup.object().shape({
      mark: Yup.string().nullable().required(EMPTY_FIELD_ERROR_TEXT),
      notes: Yup.string()
        .nullable()
        .when('mark', {
          is: (mark: EvaluationMark) => mark === EvaluationMark.NeedsDevelopment,
          then: Yup.string().nullable().required(NEEDS_DEVELOPMENT_ERROR_TEXT),
        }),
    }),
  }),
  {}
);

export const validationSchema = Yup.object().shape(fieldsSchema);
