import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100%',
    overflow: 'auto',
    borderSpacing: '0',
    borderCollapse: 'collapse',
    width: '100%',
  },
  header: {
    minHeight: 60,
    padding: '16px 24px 16px 24px',
    display: 'flex',
    alignItems: 'center',
  },
  emptyView: {
    height: 500,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyViewTitle: {
    fontWeight: 500,
    fontSize: '24px',
    lineHeight: '24px',
    letterSpacing: '-0.03em',
    color: COLORS.GRAY.SLATE,
    margin: '35px 0',
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 600,
    marginRight: 30,
  },
  achievedCount: {
    color: COLORS.TEXT.SECONDARY,
  },
  tableHeader: {
    display: 'grid',
    gridTemplateColumns: 'repeat(15, 1fr)',
    alignItems: 'center',
    gridTemplateRows: '1fr',
    padding: '16px 24px',
    fontWeight: '400',
    fontSize: '14px',
    background: COLORS.HIGHLIGHTS.NEUTRAL,
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalsTableHeader: {
    display: 'table-row',

    '& td': {
      padding: '16px',
    },
    '& td:first-child': {
      paddingLeft: '24px',
    },
    '& td:last-child': {
      paddingRight: '24px',
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalItem: {
    borderBottom: `1px solid ${COLORS.DIVIDER}`,
    transition: 'all 100ms linear',

    '&:last-child': {
      borderBottom: 'none',
    },
    '& td': {
      padding: '16px',
      verticalAlign: 'middle',
      [BREAKPOINTS.MOBILE]: {
        verticalAlign: 'top',
      },
    },
    '& td:first-child': {
      paddingLeft: '24px',
    },
    '& td:last-child': {
      paddingRight: '24px',
    },
    '&:hover': {
      backgroundColor: COLORS.HIGHLIGHTS.NEUTRAL,
      cursor: 'pointer',
    },
    [BREAKPOINTS.MOBILE]: {
      '& td:first-child': {
        paddingLeft: '16px',
      },
      '& td:last-child': {
        paddingRight: '16px',
      },
    },
  },
  goalCategory: {
    color: COLORS.TEXT.SECONDARY,
  },
  goalDateCreated: {
    width: '120px',
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalCompleteness: {
    width: '92px',
    textAlign: 'right',
  },
  goalCompletenessBadge: {
    display: 'inline-block',
    borderRadius: 20,
    padding: '4px 12px 5px',
    margin: '-4px 0 -5px',
    fontWeight: 500,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    color: COLORS.WHITE,
  },
  goalCompletenessBadgeOff: {
    color: COLORS.TEXT.DISABLED,
    background: COLORS.TEXT.DISABLEDBG,
    textShadow: 'none',
  },
  goalDateDue: {
    width: '120px',
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalStatus: {
    whiteSpace: 'nowrap',
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  goalStatusProgress: {
    color: COLORS.ACTION.PRIMARY,
  },
  goalStatusAchieved: {
    color: COLORS.STATUS.SUCCESS,
  },
  goalStatusCanceled: {
    color: COLORS.TEXT.DISABLED,
  },
  goalStatusOverdue: {
    color: COLORS.STATUS.CRITICAL,
  },
  rowSkeleton: {
    boxSizing: 'border-box',
    width: 'calc(100% - 40px)',
    height: 60,
    margin: '10px 24px',
  },
  singleRowSkeleton: {
    width: 300,
    height: 20,
    lineHeight: 'auto',
  },
  meterSkeleton: {
    margin: '0 auto',
    width: '70%',
    height: 40,
    marginBottom: 15,
  },
  newGoalBtnHolder: {
    marginLeft: 'auto',
  },
});
