import { EvaluationRescheduleHistoryData } from '@modules/EvaluationModule/interfaces/EvaluationRescheduleHistory';
import React, { FC } from 'react';
import { useStyles } from './RescheduledBlock.styles';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

interface Props {
  rescheduledEvaluations: EvaluationRescheduleHistoryData[];
}

export const RescheduledBlock: FC<Props> = ({ rescheduledEvaluations }) => {
  const styles = useStyles();

  return (
    <>
      {rescheduledEvaluations.length > 0 && (
        <div className={styles.root}>
          <div className={styles.title}>This is rescheduled evaluation</div>
          {rescheduledEvaluations.map((rescheduling) => (
            <div className={styles.rescheduledBlockItem}>
              <div className={styles.rescheduledDates}>
                <div>{format(parseISO(rescheduling.from), 'MMM yyyy')}</div>
                &#8594;
                <div>{format(parseISO(rescheduling.to), 'MMM yyyy')}</div>
              </div>
              <div className={styles.rescheduledReason}>Reason: {rescheduling.reason}</div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
