import React, { FC, useCallback, useState } from 'react';
import {
  Autocomplete,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import magnifyingImg from '@assets/img/magnifying.png';
import { useDispatch } from 'react-redux';
import { DataState } from '@shared/enums/DataState';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import { resetSearchResult } from '@modules/App/redux/users';
import SearchIcon from '@mui/icons-material/Search';
import { UserInfo } from '@shared/interfaces/UserInfo';
import { useNavigate } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { DUPLICATE_FLAG_ID_QUERY_PARAM } from '@modules/HappinessModule/constants/queryParamsConstants';
import { useUsersWhomICanCreateFlag } from '@shared/hooks/useUsersWhomICanCreateFlag';

interface Props {
  duplicateFlagId: string;
  handleClose: () => void;
}

export const DuplicateFlagModal: FC<Props> = ({ duplicateFlagId, handleClose }) => {
  const dispatch = useDispatch();
  const usersWhomICanCreateFlagAsyncData = useUsersWhomICanCreateFlag();
  const [selectedUser, setSelectedUser] = useState<UserInfo | null>(null);
  const navigate = useNavigate();

  const handleContinue = useCallback(() => {
    navigate(`/${paths.flags}/create/${selectedUser?.id}`, {
      state: { [DUPLICATE_FLAG_ID_QUERY_PARAM]: duplicateFlagId },
    });
    handleClose();
  }, [selectedUser, duplicateFlagId]);

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      sx={{
        '& .MuiDialog-container': {
          '& .MuiPaper-root': {
            width: '100%',
            maxWidth: '524px',
          },
        },
      }}
      open={true}
      onClose={handleClose}
    >
      <DialogTitle
        sx={{
          marginTop: '20px',
          marginLeft: '24px',
        }}
      >
        Duplicate flag
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 32,
          top: 30,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Divider
        sx={{
          marginTop: '16px',
        }}
      />
      <DialogContent
        sx={{
          marginLeft: '24px',
          marginRight: '24px',
        }}
      >
        <Autocomplete
          loading={usersWhomICanCreateFlagAsyncData.state === DataState.Pending}
          autoComplete
          onChange={(_, value) => setSelectedUser(value)}
          includeInputInList
          getOptionLabel={(option) => option.name}
          filterSelectedOptions
          noOptionsText={'No results'}
          options={usersWhomICanCreateFlagAsyncData.data ?? []}
          renderInput={(params) => (
            <TextField
              {...params}
              sx={{
                '& .MuiInputBase-adornedStart': {
                  paddingTop: '7px',
                },
              }}
              placeholder="Search teammate"
              fullWidth
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
                startAdornment: <SearchIcon color="disabled" />,
              }}
            />
          )}
          renderOption={(props, option) => (
            <li
              {...props}
              key={option.id}
              style={{
                borderBottom: '1px solid #E5E5E5',
              }}
            >
              <UserReporterBlock isDetailInfo statusDisplayPosition="right side" user={option} />
            </li>
          )}
          onBlur={() => dispatch(resetSearchResult())}
        />
        <Stack
          alignItems="center"
          justifyContent="center"
          sx={{
            marginTop: '47px',
            marginBottom: '47px',
          }}
        >
          <img src={magnifyingImg} alt="magnifying" />
          <Typography variant="body2" sx={{ marginTop: '16px', textAlign: 'center', fontSize: '14px' }}>
            Select a person to include
            <br /> into a new flag with pre-filled data
          </Typography>
        </Stack>
      </DialogContent>
      <DialogActions
        sx={{
          marginRight: '24px',
        }}
      >
        <Button variant="text" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" color="primary" onClick={handleContinue} disabled={!selectedUser}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
};
