export const copyToClipBoard =
  (e: Event) =>
  (copiedText: string, method?: () => void): void => {
    e.stopPropagation();
    e.preventDefault();
    navigator.clipboard.writeText(copiedText);
    if (method) {
      method();
    }
  };
