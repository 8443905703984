import { RootState } from '@modules/App/redux/store';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { useSelector } from 'react-redux';

interface HookDataReturn {
  userFirstName: string;
  editCompensationDataState: DataState;
}

export const useConfirmationModalData = (): HookDataReturn => {
  const user = useSelector(userSelector);
  const userFirstName = user ? user.name.split(' ')[0] : '';
  const editCompensationDataState = useSelector<RootState, DataState>(
    (state) => state.evaluation.userCompensationPage.editCompensationDataState
  );

  return { userFirstName, editCompensationDataState };
};
