import { ClientSubProjectData } from '@modules/EvaluationModule/interfaces/ClientSubProjectData';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getClientSubProjectData } from '../redux/actions';
import { setClientSubProjectDataLoading } from '../redux/reducers';
import { clientSubProjectDataSelector } from '../redux/selectors';

export const useClientSubProjectData = (project: {
  name: string | null;
  id: string | null;
}): AsyncData<ClientSubProjectData[]> => {
  const clientSubProjectData = useSelector(clientSubProjectDataSelector);
  const dispatch = useDispatch();
  useEffect(() => {
    if (project.name === null && project.id === null) {
      return;
    }

    dispatch(getClientSubProjectData(project));
    return () => {
      dispatch(setClientSubProjectDataLoading());
    };
  }, [project]);

  return clientSubProjectData;
};
