import { FlagListData } from '@modules/HappinessModule/interfaces/FlagListData';
import { paths } from '@shared/enums/paths';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getUsersFlagsData } from '../redux/actions';
import { usersFlagDataSelector } from '../redux/selectors';

export const useUsersFlagsData = (): AsyncData<FlagListData[]> => {
  const usersFlagData = useSelector(usersFlagDataSelector);
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  useEffect(() => {
    if (!id) {
      navigate(paths.root);
      return;
    }
    dispatch(getUsersFlagsData(id));
  }, []);

  return usersFlagData;
};
