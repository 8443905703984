import { FC, useEffect, useMemo } from 'react';
import { useFormikContext } from 'formik';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FlagFormFields } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagFormFields';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { useAllDepartments } from '@shared/hooks/useAllDepartments';
import { DataState } from '@shared/enums/DataState';
import { CircularProgress, Stack } from '@mui/material';
import { ProjectGroup } from '@modules/HappinessModule/pages/FlagPage/enums/projectGroup';
import { UserInfo } from '@shared/interfaces/UserInfo';

const ALL_PROJECTS = 'ALL_PROJECTS';

interface Props {
  isNewFlag: boolean;
  userData: UserInfo;
  disabled: boolean;
}

const AffectedProjectsSelectFormik: FC<Props> = ({ isNewFlag, userData, disabled }) => {
  const { setFieldValue, values, errors, submitCount } = useFormikContext<FlagFields>();
  const { departments, primaryDepartment } = userData;
  const assignedProjects = departments ?? [];
  const value = values.departments;
  const allDepartments = useAllDepartments({ onlyActive: false });
  const loading = allDepartments.state === DataState.Pending;
  const assignedProjectsGrp = assignedProjects.map((project) => ({
    ...project,
    group: ProjectGroup.AssignedProjects,
  }));

  useEffect(() => {
    if (!isNewFlag || !primaryDepartment) return;
    setFieldValue(FlagFormFields.departments.name, [primaryDepartment]);
  }, [primaryDepartment, isNewFlag]);

  const allDepartmentsGrp = useMemo(
    () =>
      allDepartments.data?.reduce((acc, deparment) => {
        if (assignedProjectsGrp.some((project) => project.id === deparment.id)) return acc;

        return [
          ...acc,
          {
            id: deparment.id,
            name: deparment.name,
            group: ProjectGroup.OtherProjects,
          },
        ];
      }, [] as typeof assignedProjectsGrp) || [],
    [allDepartments.data]
  );
  const options = [
    ...assignedProjectsGrp,
    {
      name: 'All assigned projects',
      id: ALL_PROJECTS,
      group: ProjectGroup.AssignedProjects,
    },
    ...allDepartmentsGrp,
  ];

  return (
    <FormControl sx={{ width: '100%' }}>
      <Autocomplete
        disabled={disabled}
        value={value ? ([...value] as typeof options) : []}
        options={options}
        onChange={(_, selectedValues) => {
          if (selectedValues.some(({ id }) => id === ALL_PROJECTS)) {
            if (
              assignedProjectsGrp.every(
                (project) => values.departments && values.departments.some((pr) => pr.id === project.id)
              )
            ) {
              setFieldValue(FlagFormFields.departments.name, [
                ...values.departments.filter((project) => !assignedProjectsGrp.some((pr) => pr.id === project.id)),
              ]);
            } else {
              setFieldValue(FlagFormFields.departments.name, [...assignedProjectsGrp]);
            }

            return;
          }

          setFieldValue(FlagFormFields.departments.name, selectedValues);
        }}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => option.group}
        loading={loading}
        renderOption={(props, option) => (
          <Box component="li" {...props}>
            <Box component="div" width="100%">
              <Stack direction="row" alignItems="center" justifyContent="space-between">
                <Typography component="p" sx={{ fontSize: '14px', color: 'text.primary' }}>
                  {option.name}
                </Typography>
                {option.id === primaryDepartment?.id && <Typography variant="body2">Primary</Typography>}
              </Stack>
              {option.id === ALL_PROJECTS && (
                <Typography component="p" sx={{ fontSize: '14px', color: 'text.disabled' }}>
                  When the issue is not specific to any individual project
                </Typography>
              )}
            </Box>
          </Box>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            error={submitCount > 0 && !!errors.departments}
            name={FlagFormFields.departments.name}
            required
            label={FlagFormFields.departments.label}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? <CircularProgress color="inherit" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
        sx={{ '.MuiInputBase-adornedStart': { padding: '9px' }, '& .MuiChip-root': { borderRadius: 4 } }}
        multiple
      />
      {isNewFlag && <FormHelperText>Project DMs will be notified</FormHelperText>}
    </FormControl>
  );
};

export default AffectedProjectsSelectFormik;
