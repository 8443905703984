import { Dialog, DialogTitle, IconButton, Tooltip } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useStyles } from './NewGoalModal.styles';
import CloseIcon from '@mui/icons-material/Close';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import { RockyButton } from '@shared/components/RockyButton';
import { useDispatch, useSelector } from 'react-redux';
import { setUserGoalCreationNetworkError } from '../../redux';
import { NewGoalForm } from '../NewGoalForm/NewGoalForm';
import { userGoalCreationStateSelector } from '../../redux/selectors';
import { DataState } from '@shared/enums/DataState';
import addMonths from 'date-fns/addMonths';
import { IS_HINT_SHOWN } from '../../constants/isSmartGoalsShown';
import { useSearchParams } from 'react-router-dom';
import { GOALS_SEACH_PARAMS } from '../../constants/searchParamsNames';

interface Props {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
}

export const NewGoalModal: React.FC<Props> = ({ isOpen, openModal, closeModal }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const today = new Date();
  const [isHintShown, setIsHintShow] = useState(false);
  const [customDueDate, setCustomDueDate] = useState<string | null>(() => addMonths(today, 6).toString());
  const userGoalCreationState = useSelector(userGoalCreationStateSelector);
  const isLoading = userGoalCreationState === DataState.Pending;
  const isFailed = userGoalCreationState === DataState.Rejected;
  const [searchParams, setSearchParams] = useSearchParams();
  const openParamFromUrl = searchParams.get(GOALS_SEACH_PARAMS);

  useEffect(() => {
    if (!isLoading && !isFailed && !openParamFromUrl) {
      handleModalClose();
    }
  }, [isLoading, isFailed, openParamFromUrl]);

  const handleModalClose = () => {
    closeModal();
    dispatch(setUserGoalCreationNetworkError(null));
    setCustomDueDate(addMonths(today, 6).toString());
  };

  const clearSearcParam = () => {
    setSearchParams('');
  };

  const openGoalHint = () => {
    setIsHintShow(true);
    localStorage.setItem(IS_HINT_SHOWN, 'true');
  };

  useEffect(() => {
    localStorage.getItem(IS_HINT_SHOWN) === 'false' ? setIsHintShow(false) : setIsHintShow(true);
  }, []);

  return (
    <>
      <RockyButton onClick={openModal} action>
        Set new SMART goal
      </RockyButton>
      {isOpen && (
        <Dialog open={isOpen} fullWidth maxWidth={isHintShown ? 'md' : 'sm'} scroll="body">
          <DialogTitle className={styles.goalDialogTitle}>
            New goal
            {!isHintShown && (
              <Tooltip title="See goal hints" placement="top">
                <IconButton className={styles.goalDialogShowNotes} onClick={openGoalHint}>
                  <PsychologyAltIcon />
                </IconButton>
              </Tooltip>
            )}
            <IconButton aria-label="close" onClick={handleModalClose} className={styles.goalDialogClose}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <NewGoalForm
            handleModalClose={handleModalClose}
            isHintShown={isHintShown}
            setIsHintShow={setIsHintShow}
            customDueDate={customDueDate}
            setCustomDueDate={setCustomDueDate}
            isLoading={isLoading}
            clearSearcParam={clearSearcParam}
          />
        </Dialog>
      )}
    </>
  );
};
