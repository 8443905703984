import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocationsData } from '../../AllFlagsPage/redux/actions';
import { useAllDepartments } from '@shared/hooks/useAllDepartments';
import { useSearchParams } from 'react-router-dom';
import {
  PeopleHappinessFilterFormData,
  PeopleHappinessFilterParamsObjectData,
} from '../interfaces/PeopleHappinessFilterData';
import {
  PeopleHappinessFilterAnotherFieldsNames,
  PeopleHappinessFilterNumberFieldsNames,
  PeopleHappinessFilterUsersFieldsNames,
} from '../enums/PeopleHappinessFilterFieldsNames';
import { loadUsersFromParams } from '../redux/actions';
import { happinessApplyFiltersSelector, happinessFiltersSelector } from '../redux/selectors';
import { FlagResolution } from '../enums/FlagResolution';
import { selectAllDepartments } from '@modules/App/redux/departments/selectors';
import { idToUser } from '../../AllFlagsPage/helpers/idToUser';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { setFiltersToAplly, setPeopleHappinessDataGroupBy } from '../redux';
import { DataState } from '@shared/enums/DataState';
import { debounce, isEqual } from 'lodash';
import { TrueFalse } from '@shared/enums/TrueFalse';
import { PEOPLE_HAPPINESS_FILTER_DELAY } from '../constants';
import { getPeopleHappinessFiltersLength } from '../helpers/getPeopleHappinessFiltersLength';
import { PeopleHappinessDataGroupBy } from '../enums/PeopleHappinessDataGroupBy';

export const usePeopleHappinessPageFilters = (): {
  initialValues: PeopleHappinessFilterFormData;
  defaultValues: PeopleHappinessFilterFormData;
  debounceReaction: (reaction: number[]) => void;
} => {
  const dispatch = useDispatch();
  useAllDepartments();

  useEffect(() => {
    dispatch(getAllLocationsData());
  }, []);

  const [searchParams, setSearchParams] = useSearchParams();
  const [initSearchParamsObj, setInitSearchParamsObj] = useState<Partial<PeopleHappinessFilterParamsObjectData>>({});

  useEffect(() => {
    const params = {} as typeof initSearchParamsObj;
    for (const key of searchParams.keys()) {
      const arrayFields = [
        PeopleHappinessFilterUsersFieldsNames.Users,
        PeopleHappinessFilterUsersFieldsNames.PrimaryReportsTos,
        PeopleHappinessFilterAnotherFieldsNames.LegalCountries,
        PeopleHappinessFilterAnotherFieldsNames.Departments,
      ];
      const searchParamValue = arrayFields.includes(
        key as PeopleHappinessFilterUsersFieldsNames | PeopleHappinessFilterAnotherFieldsNames
      )
        ? searchParams.getAll(key)
        : searchParams.get(key);
      if (key === PeopleHappinessFilterAnotherFieldsNames.GroupBy) {
        dispatch(setPeopleHappinessDataGroupBy(searchParamValue as PeopleHappinessDataGroupBy));
        continue;
      }
      if (searchParamValue)
        params[key] = Object.keys(PeopleHappinessFilterNumberFieldsNames).includes(key)
          ? Number(searchParamValue)
          : searchParamValue === TrueFalse.False
          ? false
          : searchParamValue === TrueFalse.True
          ? true
          : searchParamValue;
    }

    setInitSearchParamsObj(params);
  }, []);

  const isNeededToLoadUsersGettingFromSearchParams =
    initSearchParamsObj.users?.length || initSearchParamsObj.primaryReportsTos?.length;

  useEffect(() => {
    if (!isNeededToLoadUsersGettingFromSearchParams) {
      return;
    }
    dispatch(
      loadUsersFromParams({
        userIds: initSearchParamsObj.users ?? [],
        primaryReportsToIds: initSearchParamsObj.primaryReportsTos ?? [],
      })
    );
  }, [initSearchParamsObj]);

  const filters = useSelector(happinessFiltersSelector);

  const defaultValues: PeopleHappinessFilterFormData = {
    includeNotResponded: true,
    includeResponded: true,
    reactionGte: 1,
    reactionLte: 7,
    users: [],
    primaryReportsTos: [],
    departments: [],
    legalCountries: [],
    resolution: FlagResolution.AnyStatus,
  };
  const [initialValues, setInitialValues] = useState<PeopleHappinessFilterFormData>(defaultValues);

  const allDepartments = useSelector(selectAllDepartments);
  const filtersLength = getPeopleHappinessFiltersLength(searchParams);

  useEffect(() => {
    if (!filtersLength) {
      setInitialValues(defaultValues);
      dispatch(setFiltersToAplly(defaultValues));
      return;
    }
    if (
      (isNeededToLoadUsersGettingFromSearchParams &&
        (filters.primartReportsTos.state !== DataState.Fulfilled || filters.users.state !== DataState.Fulfilled)) ||
      !allDepartments.data?.length
    ) {
      return;
    }
    const { users, primaryReportsTos, departments, ...initSearchParamsObjRest } = initSearchParamsObj;
    const initValues = {
      ...defaultValues,
      ...initSearchParamsObjRest,
      users:
        users && filters.users.data ? users.map((userId) => idToUser(filters.users.data ?? [], userId)).flat() : [],
      primaryReportsTos:
        primaryReportsTos && filters.primartReportsTos.data
          ? primaryReportsTos.map((userId) => idToUser(filters.primartReportsTos.data ?? [], userId)).flat()
          : [],
      departments:
        (departments
          ?.map((depId) => allDepartments.data?.find((dep) => dep.id === depId))
          .filter(Boolean) as DepartmentData[]) ?? [],
    };
    setInitialValues(initValues);
    dispatch(setFiltersToAplly(initValues));
  }, [initSearchParamsObj, filters.primartReportsTos.state, filters.users.state, allDepartments.data]);
  const filtersToAplly = useSelector(happinessApplyFiltersSelector);

  useEffect(() => {
    if (!filtersToAplly || isEqual(filtersToAplly, initialValues)) return;
    setInitialValues(filtersToAplly);
  }, [filtersToAplly]);

  const debounceReaction = useCallback(
    debounce((reaction: number[]) => {
      if (!filtersToAplly) return;
      dispatch(
        setFiltersToAplly({
          ...filtersToAplly,
          reactionGte: reaction[0] ?? filtersToAplly.reactionGte,
          reactionLte: reaction[1] ?? filtersToAplly.reactionLte,
        })
      );
      const reactionToSetInUrl = {
        [PeopleHappinessFilterNumberFieldsNames.ScoreFrom]: reaction[0],
        [PeopleHappinessFilterNumberFieldsNames.ScoreTo]: reaction[1],
      };
      Object.entries(reactionToSetInUrl).forEach(([key, value]) => {
        if (value !== defaultValues[key]) {
          searchParams.set(key, value.toString());
        } else {
          searchParams.delete(key);
        }
      });
      setSearchParams(searchParams);
    }, PEOPLE_HAPPINESS_FILTER_DELAY),
    [filtersToAplly]
  );

  return { initialValues, defaultValues, debounceReaction };
};
