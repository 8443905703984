import { ValueLabelObject } from '../interfaces/ValueLabelObject';

export const labelsFromValuesArr = (searchParam: string, obj: ValueLabelObject): string[] => {
  const searchParamArray = searchParam.split(',');
  return Object.values(obj).reduce((acc, el) => {
    searchParamArray.map((value) => value === el.value && acc.push(el.label));
    return acc.filter((value) => value !== obj.all.value);
  }, [] as string[]);
};

export const labelsFromValues = (searchParam: string, obj: ValueLabelObject): string => {
  const searchParamArray = searchParam.split(',');
  return Object.values(obj).reduce((acc, el) => {
    searchParamArray.map((value) => {
      if (value === el.value) acc = el.label;
    });
    return acc;
  }, '' as string);
};
