import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    backgroundColor: COLORS.WHITE,
    borderRadius: 8,
    width: '50%',
    padding: '16px 0',
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
  },
  activeQueueRoot: {
    backgroundColor: COLORS.WHITE,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 24px',
  },
  heading: {
    fontWeight: '500',
    fontSize: 18,
    margin: '0',
    lineHeight: '24px',
  },
  queueHolder: {
    flexGrow: 1,
  },
  addCategoryButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: 6,
    color: COLORS.ACTION.PRIMARY,

    '&:hover': {
      color: COLORS.ACTION.DARK,
    },
  },
  addCategoryButtonText: {
    fontSize: '14px',
    fontWeight: '500',
    marginTop: 2,
  },
});
