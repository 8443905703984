import React, { FC, FocusEvent, memo, MouseEvent, HTMLAttributeAnchorTarget } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';

import { useStyles } from './RockyButton.styles';
import { RockySpinner } from '../RockySpinner';

interface Props {
  primary?: boolean;
  action?: boolean;
  danger?: boolean;
  success?: boolean;
  warning?: boolean;
  inverted?: boolean;
  dangerInverted?: boolean;
  cancel?: boolean;

  large?: boolean;
  medium?: boolean;
  small?: boolean;

  to?: string;
  target?: HTMLAttributeAnchorTarget;
  onClick?: (() => void) | ((event: MouseEvent<HTMLElement>) => void);
  onBlur?: (() => void) | ((event: FocusEvent<HTMLButtonElement>) => void);
  children: React.ReactNode;
  download?: boolean;
  disabled?: boolean;
  id?: string;
  className?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  style?: React.CSSProperties;
  isLoading?: boolean;
}

const RockyButtonComponent: FC<Props> = ({
  action = false,
  inverted = false,
  dangerInverted,
  cancel,
  danger = false,
  warning = false,
  success = false,

  large = false,
  small = false,
  to = '',
  id,
  onClick,
  children,
  disabled,
  className,
  download,
  type = 'button',
  style,
  onBlur,
  isLoading,
  target,
}) => {
  const styles = useStyles();

  const buttonTypeMap = {
    primary: styles.primary,
    success: styles.success,
    danger: styles.danger,
    warning: styles.warning,
    inverted: styles.inverted,
    dangerInverted: styles.dangerInverted,
    action: styles.action,
    cancel: styles.cancel,
  };

  const buttonSizeMap = {
    large: styles.large,
    medium: styles.medium,
    small: styles.small,
  };

  const buttonStyleType = action
    ? 'action'
    : inverted
    ? 'inverted'
    : danger
    ? 'danger'
    : dangerInverted
    ? 'dangerInverted'
    : success
    ? 'success'
    : warning
    ? 'warning'
    : cancel
    ? 'cancel'
    : 'primary';
  const size = large ? 'large' : small ? 'small' : 'medium';

  const link = download ? (
    <a
      className={classnames(
        styles.root,
        {
          [buttonTypeMap[buttonStyleType]]: buttonStyleType,
          [buttonSizeMap[size]]: size,
        },
        className
      )}
      id={id}
      target={target}
      download
      href={to}
    >
      {children}
    </a>
  ) : (
    <Link
      className={classnames(
        styles.root,
        {
          [buttonTypeMap[buttonStyleType]]: buttonStyleType,
          [buttonSizeMap[size]]: size,
        },
        className
      )}
      id={id}
      to={to}
      onClick={onClick}
    >
      {children}
    </Link>
  );

  const button = (
    <button
      className={classnames(
        styles.root,
        {
          [buttonTypeMap[buttonStyleType]]: buttonStyleType,
          [buttonSizeMap[size]]: size,
        },
        className
      )}
      id={id}
      onClick={onClick}
      disabled={!!disabled || isLoading}
      type={type}
      style={style}
      onBlur={onBlur}
    >
      {isLoading ? <RockySpinner /> : children}
    </button>
  );

  return to ? link : button;
};

export const RockyButton = memo(RockyButtonComponent);
