import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { loadPrs } from '@modules/App/redux/users/actions';
import { selectPrs } from '@modules/App/redux/users/selectors';
import { AsyncData } from '@shared/interfaces/asyncData';
import { UserInfo } from '@shared/interfaces/UserInfo';

export const usePrs = (): AsyncData<UserInfo[]> => {
  const dispatch = useDispatch();
  const prsAsyncData = useSelector(selectPrs);
  useEffect(() => {
    if (prsAsyncData.data?.length) {
      return;
    }
    dispatch(loadPrs());
  }, [prsAsyncData.data]);

  return prsAsyncData;
};
