import { put, all, call, takeLatest } from 'redux-saga/effects';
import { getMyTeamFlagsData, setMyTeamFlagsData } from './actions';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { setMyTeamFlagsDataLoading } from './reducers';
import { FlagListData } from '@modules/HappinessModule/interfaces/FlagListData';
import { fetchMyTeamFlagsData } from '@modules/HappinessModule/api/flagData';

function* getMyTeamFlagsDataAsync() {
  try {
    const setLoadingAction = setMyTeamFlagsDataLoading();
    yield put(setLoadingAction);
    const response: FlagListData[] = yield call(fetchMyTeamFlagsData);
    const action = setMyTeamFlagsData({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setMyTeamFlagsData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* watchGetMyHappinessData() {
  yield takeLatest(getMyTeamFlagsData.type, getMyTeamFlagsDataAsync);
}

export function* myTeamFlagsPageSaga(): Generator {
  yield all([watchGetMyHappinessData()]);
}
