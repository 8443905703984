import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  noValueText: {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '130%',
    color: COLORS.TEXT.SECONDARY,
  },
});
