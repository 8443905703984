import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  backButton: {
    position: 'absolute',
    left: 16,
    width: 40,
    height: 40,
    border: 'none',
    background: COLORS.ACTION.HIGHLIGHT,
    color: COLORS.ACTION.PRIMARY,
    borderRadius: 4,
    zIndex: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',

    [BREAKPOINTS.TABLET]: {
      display: 'none',
    },

    [BREAKPOINTS.MOBILE]: {
      display: 'block',
      top: 10,
      left: 8,
    },
  },
  chatHeader: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    background: COLORS.WHITE,
    zIndex: 4,
    padding: '10px 0px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',

    [BREAKPOINTS.MOBILE]: {
      height: 105,
      display: 'block',
    },
  },
  mobileHeader: {
    display: 'none',
    [BREAKPOINTS.MOBILE]: {
      display: 'flex',
      justifyContent: 'space-between',
      padding: '0 5px 5px 0',
      borderBottom: `1px solid ${COLORS.DIVIDER}`,
    },
  },
  desktopHeader: {
    display: 'flex',
    margin: '0 auto',

    [BREAKPOINTS.MOBILE]: {
      padding: '5px 0 5px 0',
      position: 'relative',
    },
  },
  logoContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  latestSurveyBlock: {
    display: 'flex',
    alignItems: 'center',
    width: 700,
    columnGap: 16,
    '& svg': {
      width: 20,
      height: 20,
    },

    [BREAKPOINTS.MOBILE]: {
      marginLeft: 0,
    },
  },
  latestSurveyTitle: {
    fontSize: '13px',
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      fontSize: '11px',
      marginBottom: -4,
    },
  },
  latestSurveyText: {
    color: COLORS.TEXT.PRIMARY,
    fontWeight: '500',
    minWidth: 200,

    [BREAKPOINTS.MOBILE]: {
      width: 300,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  headerTail: {
    top: 0,
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    right: 16,
    height: '100%',
  },
  latestSurveyMark: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 40,
    height: 40,
    borderRadius: '30%',
    backgroundColor: COLORS.TEXT.PRIMARY,
    paddingBottom: 1,
    color: COLORS.WHITE,
    textShadow: '0 2px 0 rgba(0, 0, 0, .08)',
    fontWeight: 500,
    fontSize: 18,
    marginRight: 6,
    marginLeft: 11,

    [BREAKPOINTS.MOBILE]: {
      transform: 'scale(0.75) translate3D(24px,3px,0)',
    },
  },
  latestSurveyRevote: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 36,
    borderRadius: 8,
    backgroundColor: COLORS.ACTION.HIGHLIGHT,
    color: COLORS.ACTION.PRIMARY,
    padding: '0 16px',
    cursor: 'pointer',

    [BREAKPOINTS.MOBILE]: {
      padding: '0 10px',
    },
  },
  latestSurveyCloseDate: {
    fontSize: '13px',
    color: COLORS.TEXT.SECONDARY,

    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  headerTailSkeleton: {
    position: 'absolute',
    right: 5,
    width: 60,
    height: 25,
    borderRadius: 6,
  },
  pollIcon: {
    [BREAKPOINTS.MOBILE]: {
      display: 'none',
    },
  },
  avatar: {
    display: 'none',
    [BREAKPOINTS.MOBILE]: {
      width: 40,
      minWidth: 40,
      height: 40,
      background: 'linear-gradient(333.01deg, #FF754C -23.54%, #ECB801 173.81%)',
      borderRadius: 12,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0 5px 0 50px',

      '& svg': {
        fill: COLORS.WHITE,
        width: 24,
        height: 24,
      },
    },
  },
  rockyTitle: {
    [BREAKPOINTS.MOBILE]: {
      display: 'inline',
      fontWeight: '400',
      fontSize: '14px',
      color: COLORS.TEXT.SECONDARY,
    },
  },
});
