import { FC, useCallback } from 'react';
import { useStyles } from './UserEmailComponent.styles';
import { copyToClipBoard } from '@shared/helpers/copyToClipBoard';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { useDispatch } from 'react-redux';
import { displayNotification } from '@modules/App/redux/notifications/actions';

export const UserEmailComponent: FC<{ email?: string }> = ({ email = 'email is missing please contact support' }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const handleCopyLink = useCallback(
    (e) => {
      copyToClipBoard(e)(email!, () => {
        dispatch(displayNotification("User's email was copied to clipboard"));
      });
    },
    [email]
  );

  return (
    <div className={styles.email}>
      {email} <ContentCopyRoundedIcon onClick={handleCopyLink} />
    </div>
  );
};
