import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectHiddenDepartments } from '@modules/App/redux/departments/selectors';
import { loadHiddenDepartments } from '@modules/App/redux/departments/actions';

export const useHiddenDepartments = (): ReturnType<typeof selectHiddenDepartments> => {
  const dispatch = useDispatch();
  const hiddenDepartments = useSelector(selectHiddenDepartments);
  useEffect(() => {
    if (hiddenDepartments.data) {
      return;
    }
    dispatch(loadHiddenDepartments());
  }, [hiddenDepartments.data]);

  return hiddenDepartments;
};
