import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  getLegalCountryOfUser,
  getPrs,
  getSearchResults,
  getToms,
  getUsersWhomICanCreateFlag,
} from '@modules/App/api/users';
import { DataState } from '@shared/enums/DataState';
import { UserInfo } from '@shared/interfaces/UserInfo';
import {
  loadLegalCountryOfUser,
  loadToms,
  loadUsersList,
  loadActiveUsersList,
  loadPrs,
  loadAllUsers,
  loadUsersWhomICanCreateFlag,
} from './actions';
import {
  setLegalCountryOfUser,
  setPrs,
  setSearchDataState,
  setSearchResult,
  setToms,
  setUsersWhomICanCreateFlag,
} from './reducers';
import { LegalCountry } from '@shared/interfaces/LegalCountry';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { UserStatus } from '@shared/enums/UserStatus';
import { displayNotification } from '../notifications/actions';

function* usersSearch({ payload }: ReturnType<typeof loadUsersList>) {
  yield put(setSearchDataState(DataState.Pending));
  const data: UserInfo[] = yield call(getSearchResults, {
    text: payload,
    statuses: [UserStatus.Active, UserStatus.Sabbatical],
  });
  yield put(setSearchResult(data));
}

function* activeUsersSearch({ payload }: ReturnType<typeof loadActiveUsersList>) {
  yield put(setSearchDataState(DataState.Pending));
  const data: UserInfo[] = yield call(getSearchResults, { text: payload, statuses: [UserStatus.Active] });
  yield put(setSearchResult(data));
}

function* loadAllUsersAsync({ payload }: ReturnType<typeof loadAllUsers>) {
  yield put(setSearchDataState(DataState.Pending));
  const data: UserInfo[] = yield call(getSearchResults, { text: payload });
  yield put(setSearchResult(data));
}

function* loadUsersWhomICanCreateFlagAsync() {
  try {
    yield put(setUsersWhomICanCreateFlag({ data: null, state: DataState.Pending }));
    const data: UserInfo[] = yield call(getUsersWhomICanCreateFlag);
    yield put(setUsersWhomICanCreateFlag({ data, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(
      setUsersWhomICanCreateFlag({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
    yield put(displayNotification('Failed to load users whom you can create flag'));
  }
}

function* loadLegalCountryOfUserAsync({ payload }: ReturnType<typeof loadLegalCountryOfUser>) {
  try {
    yield put(setLegalCountryOfUser({ data: null, state: DataState.Pending }));
    const response: LegalCountry = yield call(getLegalCountryOfUser, payload);
    const action = setLegalCountryOfUser({ data: response, state: DataState.Fulfilled });
    yield put(action);
  } catch (e) {
    yield put(
      setLegalCountryOfUser({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* loadTomsAsync() {
  try {
    yield put(setToms({ data: null, state: DataState.Pending }));
    const response: UserInfo[] = yield call(getToms);
    yield put(setToms({ data: response, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(
      setToms({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* loadPrsAsync() {
  try {
    yield put(setPrs({ data: null, state: DataState.Pending }));
    const response: UserInfo[] = yield call(getPrs);
    yield put(setPrs({ data: response, state: DataState.Fulfilled }));
  } catch (e) {
    yield put(
      setPrs({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

export function* usersSaga(): Generator {
  yield all([
    takeLatest(loadUsersList.type, usersSearch),
    takeLatest(loadActiveUsersList.type, activeUsersSearch),
    takeLatest(loadLegalCountryOfUser.type, loadLegalCountryOfUserAsync),
    takeLatest(loadToms.type, loadTomsAsync),
    takeLatest(loadPrs.type, loadPrsAsync),
    takeLatest(loadAllUsers.type, loadAllUsersAsync),
    takeLatest(loadUsersWhomICanCreateFlag.type, loadUsersWhomICanCreateFlagAsync),
  ]);
}
