import { GoalsPermissions, SmartGoalsPermissions } from '@shared/enums/SmartGoalsPermissions';
import { SmartGoalsPermissionsInterface } from '@modules/App/interfaces/SmartGoalsPermissions';

export const getSmartGoalsPermissions = (permissions: SmartGoalsPermissions[]): SmartGoalsPermissionsInterface => ({
  goals: {
    isReadable: permissions.includes(GoalsPermissions.Read),
    isCreatable: permissions.includes(GoalsPermissions.Create),
    isUpdatable: permissions.includes(GoalsPermissions.Update),
  },
});
