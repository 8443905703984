import { makeStyles } from '@mui/styles';

import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  modal: {
    maxWidth: '32rem',
  },
  buttonsContainer: {
    display: 'flex',
    columnGap: '0.5rem',
    justifyContent: 'end',
  },
  lastDayReminder: {
    color: COLORS.BRAND.PRIMARY,
  },
});
