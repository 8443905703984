import { combineReducers } from 'redux';
import userEvalDataPageReducer from '../../EvaluationModule/pages/UserEvalPage/redux/reducers';
import myTeamEvalDataPageReducer from '../../EvaluationModule/pages/MyTeamEvalPage/redux/reducers';
import evalPageDataReducer from '../../EvaluationModule/pages/EvalPage/redux/reducers';
import myPeopleEvalDataPageReducer from '../../EvaluationModule/pages/MyPeopleEvalPage/redux/reducers';
import clientEvalDataPageReducer from '../../EvaluationModule/pages/ClientEvalPage/redux/reducers';
import myPeerReviewsPageReducer from '../../EvaluationModule/pages/MyPeerReviewsPage/redux/reducers';
import peerEvalDataPageReducer from '../../EvaluationModule/pages/PeerEvalPage/redux/reducers';
import evalSettingsPageReducer from '../../EvaluationModule/pages/EvalSettingsPage/redux/reducers';
import clientContactDataPageReducer from '../../EvaluationModule/pages/ClientContactPage/redux/reducers';
import userCompensationReducer from '../pages/UserCompensationPage/redux/reducers';
import userGoalsReducer from '../pages/UserGoalsPage/redux/reducers';
import userPromotionsPageReducer from '../pages/UserPromotionsPage/redux/reducers';

export default combineReducers({
  userEvalPage: userEvalDataPageReducer,
  myTeamEvalPage: myTeamEvalDataPageReducer,
  myPeerReviewsPage: myPeerReviewsPageReducer,
  evalPage: evalPageDataReducer,
  myPeopleEvalPage: myPeopleEvalDataPageReducer,
  clientEvalPage: clientEvalDataPageReducer,
  clientContactPage: clientContactDataPageReducer,
  userCompensationPage: userCompensationReducer,
  peerEvalPage: peerEvalDataPageReducer,
  evalSettingsPage: evalSettingsPageReducer,
  userGoalsPage: userGoalsReducer,
  userPromotionsPage: userPromotionsPageReducer,
});
