import { displayNotification } from '@modules/App/redux/notifications/actions';
import {
  postNewClientSurvey,
  deleteClientSurvey,
  editClientSurvey,
  getClientSurveysData,
  putConfirmToClientSurvey,
  getCategoriesListData,
} from '@modules/HappinessModule/api/clientSurveysData';
import { ClientSurveyData } from '@modules/HappinessModule/interfaces/ClientSurveyData';
import { DataState } from '@shared/enums/DataState';
import { getErrorInfo } from '@shared/helpers/getErrorInfo';
import { all, call, put, takeLatest, takeLeading } from 'redux-saga/effects';
import {
  confirmClientSurvey,
  createNewClientSurvey,
  loadCategoriesList,
  loadClientSurveys,
  removeClientSurvey,
  updateClientSurvey,
} from './actions';
import {
  setClientSurveysData,
  setUpsertSurvey,
  setConfirmClientSurvey,
  setClientSurveyConfirmModalOpen,
  setCategoriesList,
  setUpsertSurveyState,
} from './reducers';

function* getAllSurveys() {
  const clientSurveys: ClientSurveyData[] | null = yield call(getClientSurveysData);
  const setClientSurveysDataAction = setClientSurveysData({
    data: clientSurveys,
    state: DataState.Fulfilled,
  });

  yield put(setClientSurveysDataAction);
}

function* loadClientSurveysAsync() {
  try {
    yield getAllSurveys();
  } catch (e) {
    yield put(
      setClientSurveysData({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
  }
}

function* createNewClientSurveyAsync({ payload }: ReturnType<typeof createNewClientSurvey>) {
  try {
    yield put(setUpsertSurveyState(DataState.Pending));
    yield call(postNewClientSurvey, payload);
    yield put(setUpsertSurvey(undefined));
    yield getAllSurveys();
    yield put(setUpsertSurveyState(DataState.Fulfilled));
    yield put(displayNotification('Survey added'));
  } catch {
    yield put(setUpsertSurveyState(DataState.Rejected));
    yield put(displayNotification("Couldn't create a new survey, please try again"));
  }
}

function* updateClientSurveyAsync({ payload }: ReturnType<typeof updateClientSurvey>) {
  try {
    yield put(setUpsertSurveyState(DataState.Pending));
    yield call(editClientSurvey, payload);
    yield getAllSurveys();
    yield put(setUpsertSurveyState(DataState.Fulfilled));
    yield put(displayNotification('Survey edited'));
  } catch {
    yield put(setUpsertSurveyState(DataState.Rejected));
    yield put(displayNotification("Couldn't edit the survey, please try again"));
  }
}

function* removeClientSurveyAsync({ payload }: ReturnType<typeof removeClientSurvey>) {
  try {
    yield put(setUpsertSurveyState(DataState.Pending));
    yield call(deleteClientSurvey, payload);
    yield put(setUpsertSurvey(undefined));
    yield getAllSurveys();
    yield put(setUpsertSurveyState(DataState.Fulfilled));
    yield put(displayNotification('Survey deleted'));
  } catch (error) {
    yield put(setUpsertSurveyState(DataState.Rejected));
    yield put(displayNotification("Couldn't delete the survey, please try again"));
  }
}

function* putConfirmToClientSurveyAsync({ payload }: ReturnType<typeof confirmClientSurvey>) {
  try {
    yield put(
      setClientSurveyConfirmModalOpen({
        pending: true,
        open: true,
      })
    );
    const confirmedClientSurvey: ClientSurveyData = yield call(putConfirmToClientSurvey, payload.id);
    yield put(setConfirmClientSurvey(confirmedClientSurvey));
    yield put(displayNotification('Survey confirmed'));
  } catch (error) {
    yield put(
      setClientSurveyConfirmModalOpen({
        pending: false,
        open: true,
      })
    );
    yield put(
      displayNotification(String(getErrorInfo(error)?.message) || 'Unable to update the survey. Please try again.')
    );
  }
}

function* loadCategoriesListAsync() {
  try {
    yield put(
      setCategoriesList({
        data: null,
        state: DataState.Pending,
      })
    );
    const data: string[] = yield call(getCategoriesListData);
    yield put(
      setCategoriesList({
        data,
        state: DataState.Fulfilled,
      })
    );
  } catch (e) {
    yield put(
      setCategoriesList({
        data: null,
        state: DataState.Rejected,
        error: getErrorInfo(e),
      })
    );
    yield put(displayNotification('Failed to load categories list for surveys, please try again later'));
  }
}

function* watchClientSurveysPageActions() {
  yield takeLatest(loadClientSurveys.type, loadClientSurveysAsync);
  yield takeLatest(loadCategoriesList.type, loadCategoriesListAsync);

  yield takeLeading(createNewClientSurvey.type, createNewClientSurveyAsync);
  yield takeLeading(updateClientSurvey.type, updateClientSurveyAsync);
  yield takeLeading(removeClientSurvey.type, removeClientSurveyAsync);
  yield takeLeading(confirmClientSurvey.type, putConfirmToClientSurveyAsync);
}

export function* clientSurveysPageSaga(): Generator {
  yield all([watchClientSurveysPageActions()]);
}
