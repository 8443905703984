import React, { FC } from 'react';
import { useStyles } from './AddLinkButton.style';
import { RockyButton } from '@shared/components/RockyButton';
import PostAddIcon from '@mui/icons-material/PostAdd';
import { useSelector } from 'react-redux';
import { useModalState } from '@shared/hooks/useModalState';
import { ExternalLinksTypes } from '@modules/App/pages/ExternalLinksPage/enums/ExternalLinksTypes';
import { LinkManageModal } from '@modules/App/pages/ExternalLinksPage/components/LinkManageModal/LinkManageModal';
import { selectAddLinkState } from '../../redux/selectors';
import { DataState } from '@shared/enums/DataState';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { COLORS } from '@styles/colors';

interface Props {
  type: ExternalLinksTypes;
  addLink: (value: string) => void;
  isInFormik?: boolean;
  isUpdate?: boolean;
  link?: string | null;
}

export const AddLinkButton: FC<Props> = ({ type, addLink, isInFormik, isUpdate, link }) => {
  const styles = useStyles();
  const { openModal, closeModal, isModalOpen } = useModalState();
  const addLinkState = useSelector(selectAddLinkState);
  const isLinkAddLoading = addLinkState === DataState.Pending;

  const handleAddLink = (value: string) => {
    addLink(value);
    if (isInFormik) {
      closeModal();
    }
  };

  const handleClose = () => {
    if (link) {
      addLink(link);
    }
    closeModal();
  };

  return (
    <div className={styles.linkManageBtnContainer}>
      {isUpdate ? (
        <IconButton onClick={openModal} sx={{ width: '20px', height: '20px' }}>
          <EditIcon sx={{ width: '20px', height: '20px', fill: COLORS.ACTION.PRIMARY }} />
        </IconButton>
      ) : (
        <RockyButton action className={styles.linkManageBtn} onClick={openModal} small>
          <PostAddIcon /> {type === ExternalLinksTypes.pdp ? 'Add PDP link' : 'Add peer interview results'}
        </RockyButton>
      )}
      <LinkManageModal
        link={link ?? null}
        isLoading={isLinkAddLoading}
        pendingLinkUpdate={false}
        isModalOpen={isModalOpen}
        type={type}
        manageLink={handleAddLink}
        closeModal={handleClose}
      />
    </div>
  );
};
