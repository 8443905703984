import { makeStyles } from '@mui/styles';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    height: '100vh',
    width: '100vw',
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  notFoundImg: {
    width: '100%',
    maxWidth: '800px',
  },
  notFoundText: {
    fontWeight: '500',
    textAlign: 'center',
  },
  buttonsContainer: {
    display: 'flex',
    gap: 20,
  },
  link: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: COLORS.BRAND.PRIMARY,
    color: COLORS.WHITE,
    border: 'none',
    fontSize: '16px',
    height: 60,
    width: 160,
    fontWeight: '500',
    marginTop: 20,
    cursor: 'pointer',
    borderRadius: 8,
  },
});
