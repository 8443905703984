import { StreamsRolesSenioritiesMapData } from '@modules/EvaluationModule/interfaces/StreamsRolesSenioritiesMapData';
import { CompanyRoleData } from '@shared/interfaces/Roles';

export const checkIfSeniorityExist = ({
  rolesSenioritiesMap,
  role,
}: {
  rolesSenioritiesMap: StreamsRolesSenioritiesMapData[];
  role: CompanyRoleData;
}) => {
  const isSeniorityExist = rolesSenioritiesMap
    .map((stream) => stream.designations)
    .flat()
    .some(({ id, seniorities }) => id === role?.id && seniorities && seniorities.length > 0);
  return isSeniorityExist;
};
