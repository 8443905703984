import { displayNotification } from '@modules/App/redux/notifications/actions';
import { Autocomplete, Box, Grid, TextField, TextFieldProps, Typography } from '@mui/material';
import { RockyButton } from '@shared/components/RockyButton';
import { RockyModal } from '@shared/components/RockyModal';
import { useModalState } from '@shared/hooks/useModalState';
import { UserAddressData } from '@shared/interfaces/UserAddressData';
import { useFormikContext } from 'formik';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { UserAddressFieldsType, UserAddressFormFields } from '../../constants/userAddressFormFields';
import { useStyles } from './AddressAutocompleteTextField.styles';
import { GoogleAddressComponent } from './enums/AddressComponent';
import { getAddressInfo, getSelectedDetailedAddressInfo } from './helpers/getDetailedAddressInfo';
import { useGooglePlacesAutocomplete } from './hooks/useGooglePlacesAutocomplete';
import { ParsedAddressInfoType, PlaceType } from './interfaces/addressTypesAndInterfaces';
interface Props {
  fieldName: UserAddressFieldsType;
  setValuesForAutofillFields: (value: Required<UserAddressData>) => void;
}

export const AddressAutocompleteTextField: FC<Props & TextFieldProps> = ({
  fieldName,
  setValuesForAutofillFields,
  ...props
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const form = useFormikContext<Required<UserAddressData>>();
  const { placesService, placePredictions, getPlacePredictions } = useGooglePlacesAutocomplete({ fieldName });

  const selectedAddress: PlaceType = useMemo(() => {
    const description = typeof props.value === 'string' ? props.value : '';

    return { description };
  }, [props.value]);

  const setSelectedAddress = (address: PlaceType) => {
    form.setFieldValue(props.name as string, address.description);
  };

  const [options, setOptions] = useState<PlaceType[]>([]);

  const [selectedOption, setSelectedOption] = useState<{
    parsedAddressInfo: ParsedAddressInfoType | null;
    placeData: PlaceType;
  } | null>(null);
  const { openModal, closeModal, isModalOpen } = useModalState();
  const [streetNumber, setStreetNumber] = useState('');

  useEffect(() => {
    if (placePredictions.length) {
      setOptions(placePredictions);
    }
  }, [placePredictions]);

  useEffect(() => {
    const newValue = { description: props.value as string };
    if (newValue.description === selectedAddress?.description) {
      return;
    }

    setSelectedAddress(newValue);
  }, [props.value]);

  useEffect(() => {
    const { parsedAddressInfo, placeData } = selectedOption || {};
    if (!parsedAddressInfo || !placeData) {
      return;
    }
    if (
      parsedAddressInfo[GoogleAddressComponent.StreetNumber] === '' &&
      fieldName === UserAddressFormFields.addressLine1.name
    ) {
      openModal();
      return;
    }
    const addressInfo = getAddressInfo(parsedAddressInfo);
    const newValue = { description: addressInfo[fieldName], place_id: placeData.place_id };
    setOptions((options) => options.map((option) => (option.place_id === placeData.place_id ? newValue : option)));
    setValuesForAutofillFields(addressInfo);
  }, [selectedOption]);

  const onSelect = async (_: React.SyntheticEvent<Element, Event>, selectedValue: PlaceType | null) => {
    if (!selectedValue?.place_id) return;
    const parsedAddressInfo = await getSelectedDetailedAddressInfo({
      placesService,
      placeId: selectedValue.place_id,
      notifyErrorFunc: (message) => dispatch(displayNotification(message)),
    });
    if (!parsedAddressInfo) return;
    setSelectedOption({ parsedAddressInfo, placeData: selectedValue });
  };

  const onModalSubmit = useCallback(() => {
    setSelectedOption((prevState) =>
      prevState
        ? {
            ...prevState,
            parsedAddressInfo: prevState?.parsedAddressInfo
              ? {
                  ...prevState.parsedAddressInfo,
                  [GoogleAddressComponent.StreetNumber]: streetNumber,
                }
              : null,
          }
        : null
    );
    closeModal();
  }, [streetNumber]);

  return (
    <>
      <Autocomplete
        isOptionEqualToValue={(option, value) =>
          option.place_id === value.place_id || option.description === value.description
        }
        getOptionLabel={(option) => option.description}
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={selectedAddress}
        noOptionsText="No locations"
        onChange={onSelect}
        onInputChange={(_, newInputValue) => {
          if (newInputValue === '') {
            setSelectedAddress({ description: '' });
          } else {
            getPlacePredictions({ input: newInputValue });
          }
        }}
        renderInput={(params) => <TextField {...params} {...props} />}
        popupIcon={''}
        disableClearable
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item sx={{ wordWrap: 'break-word' }}>
                  <Box component="span" sx={{ fontWeight: 'regular' }}>
                    {option.structured_formatting?.main_text}
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {option.structured_formatting?.secondary_text}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
      <RockyModal title="Add number of the building" isOpen={isModalOpen} onClose={closeModal} backdropClose={false}>
        <TextField
          onChange={(e) => setStreetNumber(e.target.value)}
          fullWidth
          label="Building number"
          variant="outlined"
        />
        <div className={styles.modalBtnsContainer}>
          <RockyButton inverted onClick={closeModal}>
            Cancel
          </RockyButton>
          <RockyButton type="submit" action disabled={!streetNumber} onClick={onModalSubmit}>
            Save
          </RockyButton>
        </div>
      </RockyModal>
    </>
  );
};
