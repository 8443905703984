import { ResponsesActivityDataItem } from '@modules/HappinessModule/interfaces/ResponsesActivityDataItem';
import { RockySkeleton } from '@shared/components/RockySkeleton';
import React, { memo } from 'react';
import { InfoView } from '../../../../shared/components/InfoView/InfoView';
import ResponsesActivityTable from './components/ResponsesActivityTable/ResponsesActivityTable';
import { useStyles } from './ResponsesActivity.styles';
import ReviewsOutlinedIcon from '@mui/icons-material/ReviewsOutlined';
import { Link } from 'react-router-dom';
import { paths } from '@shared/enums/paths';
import { useAuth } from '@shared/hooks/useAuth';
import Popup from 'reactjs-popup';
import EmptyResponse from '@assets/img/EmptyResponse.png';
import { userSelector } from '@modules/App/pages/UserPage/redux/selectors';

interface Props {
  data?: ResponsesActivityDataItem[] | null;
  isDataLoading: boolean;
  user: ReturnType<typeof userSelector>;
}

const ResponsesActivityComponent: React.FC<Props> = ({ data, isDataLoading, user }) => {
  const styles = useStyles();
  const { currentUser } = useAuth();

  return (
    <>
      {data?.length === 0 && user ? (
        <InfoView icon={EmptyResponse} text={`${user.name.split(' ')[0]} hasn't received any Rocky questions yet`} />
      ) : (
        <div className={styles.root}>
          <div className={styles.header}>
            <div className={styles.titleContainer}>
              <RockySkeleton
                className={styles.titleSkeleton}
                isLoading={isDataLoading}
                element={
                  <div>
                    <span className={styles.title}>Responses activity</span>&nbsp;
                    <span className={styles.responsesCount}>{data?.length} responses</span>
                  </div>
                }
              />
              {currentUser?.email === user?.email && (
                <Popup
                  trigger={
                    <Link to={paths.chat} className={styles.headerTail}>
                      <ReviewsOutlinedIcon fontSize="small" />
                      <div className={styles.headerTailText}>Rocky conversation</div>
                    </Link>
                  }
                  on={['hover', 'focus']}
                  position="top center"
                  className={styles.popup}
                >
                  <span>Rocky Chat</span>
                </Popup>
              )}
            </div>
          </div>
          <ResponsesActivityTable user={user} isDataLoading={isDataLoading} data={data} />
        </div>
      )}
    </>
  );
};

export const ResponsesActivity = memo(ResponsesActivityComponent);
