import { makeStyles } from '@mui/styles';
import { BREAKPOINTS } from '@styles/breakpoints';
import { COLORS } from '@styles/colors';

export const useStyles = makeStyles({
  root: {
    paddingBottom: 0,
    backgroundColor: COLORS.WHITE,
    boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
    width: '100%',
    maxWidth: '1400px',
    margin: '0 auto',

    [BREAKPOINTS.MOBILE]: {
      overflowX: 'auto',
    },
  },
  header: {
    padding: '13px 24px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
  },
  filtersContainer: {
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',

    '& span': {
      fontWeight: 400,
      fontSize: 14,
      lineHeight: '150%',
    },
  },
  endOfProjectFilter: {
    display: 'flex',
    flexWrap: 'nowrap',
    gap: 10,
    alignItems: 'center',
  },
  title: {
    fontWeight: '500',
    fontSize: '24px',
  },
  selectsWrapper: {
    width: 'fit-content',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 10,
    zIndex: 2,
  },
});
