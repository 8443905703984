import { StylesConfig } from 'react-select';
import { COLORS } from '@styles/colors';

// custom styles for Select component

const disabledColor = `linear-gradient(0deg, ${COLORS.HIGHLIGHTS.NEUTRAL}, ${COLORS.HIGHLIGHTS.NEUTRAL}), ${COLORS.DIVIDER}`;
export const searchCustomStyles: StylesConfig = {
  control: (base, { isDisabled }) => ({
    ...base,
    height: 45,
    border: `1px solid ${COLORS.INPUTBORDER}`,
    boxShadow: 'none',
    '&:focus': {
      borderColor: COLORS.ACTION.PRIMARY,
    },
    background: isDisabled ? disabledColor : 'transparent',
  }),
  input: (base) => ({
    ...base,
    display: 'block',
    width: 200,
    height: 30,
    paddingTop: 5,
    fontSize: '14px',
    boxShadow: 'none',

    '&:focus': {
      borderColor: COLORS.ACTION.PRIMARY,
    },
  }),
  valueContainer: (base) => ({
    ...base,
    marginLeft: 45,
  }),
  dropdownIndicator: (base, { isDisabled }) => ({
    ...base,
    display: isDisabled ? 'none' : 'block',
  }),
};
