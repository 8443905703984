import React, { useState } from 'react';
import { useStyles } from './CompensationHistory.styles';
import { CompensationData, HistoricalCompensationData } from '@modules/EvaluationModule/interfaces/CompensationData';
import { useSelector } from 'react-redux';
import { RootState } from '@modules/App/redux/store';
import { Table } from '@shared/components/Table';
import { getTableHeaders } from './helpers/getTableHeaders';
import { getTableRow } from './helpers/getTableRow';
import { useAuth } from '@shared/hooks/useAuth';
import { useParams } from 'react-router-dom';
import { getHintExplainingHowTotalCompensationIsCalculated } from '@modules/EvaluationModule/pages/UserCompensationPage/helpers/getHintExplainingHowTotalCompensationIsCalculated';
import { CompensationDetailsModal } from '../CompensationDetailsModal';
import { getPreviousCompenstation } from './helpers/getPreviousCompenstation';

interface Props {
  data: CompensationData;
  isDetailsVisible: boolean;
}

export const CompensationHistory: React.FC<Props> = ({ data, isDetailsVisible }) => {
  const styles = useStyles();
  const [isShowPrevious, setIsShowPrevious] = useState(false);
  const slicedHistory = isShowPrevious ? data?.historicalCompensations : data?.historicalCompensations.slice(0, 10);
  const isAddNewCompensationComplete = useSelector<RootState, boolean>(
    (state) => state.evaluation.userCompensationPage.isAddNewCompensationComplete
  );
  const isShownBonusColumn = data?.historicalCompensations.some((el) => el.bonus);
  const tableHeaders = getTableHeaders({ isShownBonusColumn });
  const [compensationForModal, setCompensationForModal] = useState<HistoricalCompensationData | null>(null);
  const compensationHistoryRow = getTableRow({
    tableHeaders,
    compensationData: data,
    isAddNewCompensationComplete,
    setCompensationForModal,
    isDetailsVisible,
  });

  const { currentUser } = useAuth();
  const { id: userId } = useParams();

  const hint = currentUser
    ? getHintExplainingHowTotalCompensationIsCalculated({
        isCurrentUser: currentUser.id === userId,
        isBonusAssigned: isShownBonusColumn,
        compensationType: data.type,
      })
    : null;

  return (
    <div className={styles.root}>
      <div className={styles.history}>
        <h3 className={styles.blockTitle}>Compensation plan history</h3>
        <Table
          customStyles={{
            tableHeaderRow: styles.tableHeaderRow,
          }}
          isDataLoading={false}
          tableHeaders={tableHeaders}
        >
          {slicedHistory?.map(compensationHistoryRow)}
          {data?.historicalCompensations.length > 10 &&
            (isShowPrevious ? (
              <button className={styles.showBtn} onClick={() => setIsShowPrevious(false)}>
                Hide previous
              </button>
            ) : (
              <tr>
                <td>
                  <button className={styles.showBtn} onClick={() => setIsShowPrevious(true)}>
                    Show previous
                  </button>
                </td>
              </tr>
            ))}
        </Table>
      </div>
      {hint && <div className={styles.hint}>{hint}</div>}
      {data?.historicalCompensations && compensationForModal && isDetailsVisible && (
        <CompensationDetailsModal
          handleClose={() => setCompensationForModal(null)}
          compensationForModal={compensationForModal}
          previousCompensationForModal={getPreviousCompenstation(data?.historicalCompensations, compensationForModal)}
        />
      )}
    </div>
  );
};
