import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
  modalBody: {
    maxWidth: 504,
  },
  modalBodyTitle: {
    fontWeight: '500',
    marginBottom: 24,
  },
  modalBodySubitle: {
    lineHeight: '120%',
  },
  modalBtnContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 45,
    columnGap: 10,
  },
});
