import { FC, memo } from 'react';
import { useStyles } from './FeedBackTextComponent.styles';

interface Props {
  email?: string | null;
}
const FeedBackTextComponent: FC<Props> = ({ email }) => {
  const styles = useStyles();
  return (
    <span>
      Knowing more will help us to understand what to improve. If you have more thoughts, please add comments below or
      write to{' '}
      {email ? (
        <a className={styles.link} href={`mailto:${email}`}>
          {email}
        </a>
      ) : (
        'your portfolio delivery manager at AgileEngine'
      )}
    </span>
  );
};

export const FeedBackText = memo(FeedBackTextComponent);
