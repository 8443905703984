import React, { FC } from 'react';
import { useStyles } from './CloseEvalEopModal.styles';
import { RockyModal } from '@shared/components/RockyModal';
import { EvalEndOfProbation } from '@modules/EvaluationModule/pages/EvalPage/components/EvalEndOfProbation';
import { AsyncData } from '@shared/interfaces/asyncData';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { useDispatch } from 'react-redux';
import { setFullEop } from '@modules/EvaluationModule/pages/EvalPage/redux/actions';

interface Props {
  closeModal: () => void;
  isModalOpen: boolean;
  confirmFormSubmit?: () => void;
  evalData: AsyncData<EvalPageData>;
}

export const CloseEvalEopModal: FC<Props> = ({ closeModal, isModalOpen, evalData }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  return (
    <RockyModal
      onClose={closeModal}
      isOpen={isModalOpen}
      title="Wait... set end of probation resolution"
      crossIcon={false}
      modalStyles={styles.root}
    >
      <EvalEndOfProbation
        onCancel={closeModal}
        evalData={evalData}
        onSubmit={({ eopResult, nextEvalDate, failingReason }) => {
          dispatch(
            setFullEop({
              eopResult: eopResult,
              next_evaluation_date: nextEvalDate,
              notes: failingReason,
              autosave: false,
            })
          );
        }}
        isModal
      />
    </RockyModal>
  );
};
