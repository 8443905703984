import httpClient from '@shared/api/httpClient';
import { apiUrlNode } from '@configs/environment';
import { ExternalLinkBody } from '../pages/ExternalLinksPage/interfaces/ExternalLinkBody';
import { PipLinkBody } from '../pages/ExternalLinksPage/interfaces/PipLinkBody';
import { ExternalLinks } from '../pages/ExternalLinksPage/interfaces/ExternalLinks';
import { mapExternalLinks } from '../pages/ExternalLinksPage/helpers/mapExternalLinks';
import httpNodeClient from '@shared/api/httpNodeClient';

export const patchExternalLink = (data: ExternalLinkBody): Promise<ExternalLinkBody> =>
  httpClient.patch<ExternalLinkBody, ExternalLinkBody>(`${apiUrlNode}/external-links/${data?.id}`, {
    link: data.link,
  });

export const deleteExternalLink = (data: ExternalLinkBody): Promise<ExternalLinkBody> =>
  httpClient.delete<ExternalLinkBody, ExternalLinkBody>(`${apiUrlNode}/external-links/${data?.id}`);

export const postExternalLink = (data: ExternalLinkBody): Promise<ExternalLinkBody> =>
  httpClient.post<ExternalLinkBody, ExternalLinkBody>(`${apiUrlNode}/external-links/`, data);

export const fetchExternalLinks = (id: string): Promise<ExternalLinks> =>
  httpClient
    .get<{ id: string }, { data: ExternalLinkBody[] }>(`${apiUrlNode}/external-links`, { params: { userId: id } })
    .then((resp) => mapExternalLinks(resp.data));

export const fetchPipLinks = (id: string): Promise<PipLinkBody[]> =>
  httpNodeClient.get<PipLinkBody[]>(`users/${id}/pip-links`).then((resp) => resp.data);
