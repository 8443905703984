import { UserInfo } from '@shared/interfaces/UserInfo';
import { ResolvedByDataFromBE } from '../types';

export interface ResolvedByData {
  user: UserInfo;
  notes: string | null;
  resolvedAt: string | null;
}

export const resolvedByTypeToUserInfo = (resolvedBy: ResolvedByDataFromBE | null): ResolvedByData | null =>
  resolvedBy
    ? {
        user: { name: resolvedBy.full_name, imageUrl: resolvedBy.avatar },
        notes: resolvedBy.notes,
        resolvedAt: resolvedBy.resolved_at,
      }
    : null;
