import { FC, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import LoadingButton from '@mui/lab/LoadingButton';
import classNames from 'classnames';
import addDays from 'date-fns/addDays';
import isSameYear from 'date-fns/isSameYear';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import isAfter from 'date-fns/isAfter';

import { useStyles } from './EvalEndOfProbation.styles';
import { eopLoadingStateSelector, evalPermissionsSelector } from '../../redux/selectors';
import { EopResult } from '@modules/EvaluationModule/enums/EopResult';
import { RockyDatePicker } from '@shared/components/RockyDatePicker';
import { DataState } from '@shared/enums/DataState';
import { EmploymentType } from '@shared/enums/EmploymentType';
import { AsyncData } from '@shared/interfaces/asyncData';
import { EvalPageData } from '@modules/EvaluationModule/interfaces/EvalPageData';
import { getDateToExtend } from '@shared/components/MonthSelector/helpers/getDateToExtend';

const WARNING_NOTE_COUNTRY = 'Mexico';

interface SumitFuncParams {
  nextEvalDate?: string;
  failingReason?: string;
  eopResult: EopResult;
}

interface Props {
  evalData: AsyncData<EvalPageData>;
  isModal?: boolean;
  onSubmit: (props: SumitFuncParams) => void;
  onCancel?: () => void;
}

export const EvalEndOfProbation: FC<Props> = ({ evalData, isModal, onSubmit, onCancel }) => {
  const styles = useStyles();

  const eopResultOptions = {
    [EopResult.Passed]: 'Set as passed',
    [EopResult.NotPassed]: 'Set as failed',
    [EopResult.Extended]: 'Extend probation to',
  };

  const { data: evalPageData, state: evalPageDataState } = evalData;
  const [selectedEopResult, setSelectedEopResult] = useState<EopResult | null>(null);
  const [failingReason, setFailingReason] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);

  const today = new Date();
  const [nextEvalDate, setNextEvalDate] = useState<string>('');

  useEffect(() => {
    if (!evalPageData || !evalPageData.nextEvalDate) {
      return;
    }
    setNextEvalDate(new Date(evalPageData.nextEvalDate).toISOString());
  }, [evalPageData?.nextEvalDate, evalData]);

  useEffect(() => {
    setFailingReason(evalPageData?.notes ?? '');
  }, [evalPageData?.notes]);

  useEffect(() => {
    if (evalPageData?.eopResult) {
      setSelectedEopResult(evalPageData?.eopResult);
      setIsConfirmed(true);
    }
  }, [evalPageData?.eopResult]);

  const isShowWarningLabel =
    evalPageData?.userHistory.officeCountry === WARNING_NOTE_COUNTRY &&
    evalPageData?.userHistory.employmentType === EmploymentType.Employee;

  const isPageLoading = evalPageDataState === DataState.Pending;
  const minDate = evalPageData?.evaluationDate ? addDays(today, 1).toISOString() : '';

  const isDateThisYear = nextEvalDate ? isSameYear(new Date(nextEvalDate), today) : false;
  const isSetAsExtended = selectedEopResult === EopResult.Extended;
  const isSetAsFailed = selectedEopResult === EopResult.NotPassed;
  const isSetAsPassed = selectedEopResult === EopResult.Passed;

  const evalPermissions = useSelector(evalPermissionsSelector);
  const hasUpdatePermissions = evalPermissions.data?.evaluation.isUpdatable;

  const isSubmitDisabled =
    !hasUpdatePermissions ||
    (nextEvalDate && isAfter(new Date(minDate), new Date(nextEvalDate)) && isSetAsExtended) ||
    isPageLoading ||
    !selectedEopResult;

  const eopLoadingState = useSelector(eopLoadingStateSelector);

  useEffect(() => {
    if (eopLoadingState === DataState.Fulfilled) {
      setIsConfirmed(true);
    }
  }, [eopLoadingState]);

  const onDateChange = useCallback(
    (value: string | null) => {
      if (!value || !evalData.data?.evaluationDate) {
        return;
      }
      const formattedDate = getDateToExtend({
        selectedDate: value,
      });

      setNextEvalDate(formattedDate);
    },
    [evalData]
  );

  const submitEndOfProbation = ({ nextEvalDate, failingReason, eopResult }: SumitFuncParams) => {
    if (!evalPageData?.evaluationId) return;
    onSubmit({
      eopResult: eopResult,
      nextEvalDate: isSetAsExtended ? nextEvalDate : undefined,
      failingReason: isSetAsFailed ? failingReason : undefined,
    });
  };

  return (
    <div>
      <Stack rowGap="16px" marginBottom="30px">
        {!isModal && <Typography variant="h2">Probation period</Typography>}
        {isShowWarningLabel && (
          <Alert className={styles.alert} severity="error">
            {`Please double-check and make sure if ${evalPageData.userHistory.name} is passing probation period and
          we're ready to hire them on a full-time for a long term period.`}
          </Alert>
        )}
        <Alert className={classNames(styles.alert, styles.accountingAlert)} severity="info" icon={<FolderSharedIcon />}>
          Accounting uses end of probation resolutions in their reports and calculations.
        </Alert>
      </Stack>
      {isConfirmed ? (
        <Stack rowGap="5px">
          <Typography variant="subtitle1">
            Probation period{' '}
            {isSetAsExtended && evalPageData?.nextEvalDate
              ? `extended to  ${format(parseISO(evalPageData?.nextEvalDate), 'MMMM')} ⏳`
              : isSetAsFailed
              ? 'not passed 🚷'
              : isSetAsPassed
              ? 'passed 👍'
              : ''}
          </Typography>
          {evalPageData?.eopResolvedAt && (
            <Typography variant="body2">
              Set on {format(parseISO(evalPageData.eopResolvedAt), 'MMMM d, yyyy')}
            </Typography>
          )}
          <Typography
            variant="body2"
            sx={{
              marginTop: '27px',
            }}
          >
            Confirmed EOP resolutions are final and can not be edited.
          </Typography>
        </Stack>
      ) : (
        <Stack>
          <Stack flexDirection="row" columnGap="24px" className={styles.resolutionEop} alignItems="center">
            <FormControl disabled={isPageLoading}>
              <RadioGroup value={selectedEopResult} onChange={(e) => setSelectedEopResult(e.target.value as EopResult)}>
                <Stack flexDirection={isModal ? 'column' : 'row'} columnGap="24px">
                  {Object.entries(eopResultOptions).map(([key, value]) => {
                    const isEOPExtensionDisabled = key === EopResult.Extended && !evalPageData?.isExtendable;

                    return (
                      <Stack key={key} flexDirection="row" alignItems="center">
                        <FormControlLabel
                          control={<Radio />}
                          value={key}
                          label={value}
                          disabled={isEOPExtensionDisabled || !hasUpdatePermissions}
                        />
                        {key === EopResult.Extended && (
                          <RockyDatePicker
                            disablePast
                            openTo="month"
                            minDate={minDate}
                            views={['year', 'month']}
                            disableMaskedInput
                            inputFormat={isDateThisYear ? 'MMMM' : 'MMMM yyyy'}
                            value={nextEvalDate}
                            onChange={onDateChange}
                            renderInput={() => <></>}
                            loading={isPageLoading}
                            disabled={!isSetAsExtended}
                          />
                        )}
                      </Stack>
                    );
                  })}
                </Stack>
              </RadioGroup>
            </FormControl>
          </Stack>
          {isSetAsFailed && (
            <TextField
              value={failingReason}
              label="Failing reason"
              placeholder="Leave a note why the probation is failed"
              variant="outlined"
              fullWidth
              multiline
              rows={4}
              focused
              className={styles.textField}
              onChange={(e) => setFailingReason(e.target.value)}
            />
          )}
          <Alert
            className={styles.alert}
            severity="error"
            sx={{
              marginBottom: '16px',
            }}
          >
            This is a final EOP resolution. You won't be able to change it later. Please double-check before confirming.
          </Alert>
          <Stack flexDirection="row" justifyContent={isModal ? 'flex-end' : 'start'} gap="8px" alignItems="center">
            {isModal && (
              <Button color="primary" onClick={onCancel}>
                Cancel
              </Button>
            )}
            <LoadingButton
              loading={eopLoadingState === DataState.Pending}
              variant="contained"
              color="primary"
              disabled={isSubmitDisabled}
              onClick={() => {
                if (selectedEopResult) {
                  submitEndOfProbation({
                    nextEvalDate: nextEvalDate ?? undefined,
                    failingReason,
                    eopResult: selectedEopResult,
                  });
                }
              }}
            >
              {isModal ? 'Confirm and close' : isSetAsExtended ? 'Extend probation' : 'Confirm'}
            </LoadingButton>
          </Stack>
        </Stack>
      )}
    </div>
  );
};
