import React, { FC, useState } from 'react';
import { useStyles } from './RockyToggle.styles';
import classNames from 'classnames';

interface Props {
  toggleActive: boolean;
  setToggleActive: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RockyToggle: FC<Props> = ({ toggleActive, setToggleActive }) => {
  const styles = useStyles();
  const [isClicked, setIsClicked] = useState(false);

  return (
    <div
      className={classNames(
        styles.toggleContainer,
        isClicked && {
          [styles.toggleContainerActiveOn]: toggleActive,
          [styles.toggleContainerActiveOff]: !toggleActive,
        }
      )}
      onClick={() => {
        setIsClicked(true);
        setToggleActive((state: boolean) => !state);
      }}
    >
      <div
        className={classNames(
          styles.toggleItem,
          isClicked && {
            [styles.toggleItemActiveOn]: toggleActive,
            [styles.toggleItemActiveOff]: !toggleActive,
          }
        )}
      ></div>
    </div>
  );
};
