import { FC } from 'react';
import { FlagSnapshotData } from '@modules/HappinessModule/interfaces/FlagSnapshotData';
import { RockyModal } from '@shared/components/RockyModal';
import { DataState } from '@shared/enums/DataState';
import { AsyncData } from '@shared/interfaces/asyncData';
import { useDispatch } from 'react-redux';
import { setSnapshotDataIdle } from '../../redux/reducers';
import { useStyles } from './FlagSnapshot.styles';
import { RocketLoader } from '@shared/components/RocketLoader';
import { FlagChangeEvent } from '@modules/HappinessModule/interfaces/FlagHistory';
import { FlagFields } from '@modules/HappinessModule/interfaces/FlagFields';
import { Stack } from '@mui/material';
import { FlagFormFields } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagFormFields';
import { FlagSeverityComponent } from '@modules/HappinessModule/pages/FlagPage/components/FlagSeverityComponent';
import { RockyCheckbox } from '@shared/components/RockyCheckbox';
import { UserReporterBlock } from '@shared/components/UserReporterBlock/UserReporterBlock';
import get from 'lodash/get';
import capitalize from 'lodash/capitalize';
import isBoolean from 'lodash/isBoolean';
import format from 'date-fns/format';
import { FlagStatusMapToName } from '@modules/HappinessModule/pages/FlagPage/components/FlagForm/constants/FlagStatusMapToName';
import { FlagStatus } from '@modules/HappinessModule/enums/FlagStatus';
import { DepartmentData } from '@shared/interfaces/DepartmentData';
import { checkIfStrDate } from '@shared/helpers/date';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';
import { COLORS } from '@styles/colors';
import { getHistoryChangeDate } from '@shared/helpers/getHistoryChangeDate';
import { FlagNoValueFallback } from './components/FlagNoValueFallback';
import { RecipientsPreview } from '../FlagPreview/components/RecipientsPreview/RecipientsPreview';
interface Props {
  snapshotDataAsync: AsyncData<FlagSnapshotData>;
  flagChangeEvents: FlagChangeEvent[];
}

export const FlagSnapshot: FC<Props> = ({ snapshotDataAsync, flagChangeEvents }) => {
  const { data: snapshot, state } = snapshotDataAsync;
  const isLoading = state === DataState.Pending;
  const styles = useStyles();
  const dispatch = useDispatch();

  const flagChangeEventsMoreThanOne = flagChangeEvents.length > 1;
  const currentSnapshotChangeEvents = flagChangeEvents.find((el) => el.id === snapshot?.id);
  const prevSnapshotChangeEvents = flagChangeEventsMoreThanOne
    ? flagChangeEvents
        .filter((el) => snapshot?.createdAt && el.date < snapshot.createdAt)
        .sort((a, b) => (a.date < b.date ? 1 : -1))[0]
    : null;

  const currentSnapshoFlagFields = snapshot?.flagSnapshot.flagFields;
  const prevSnapshotFlagFields =
    Object.keys(currentSnapshotChangeEvents?.changes.previousValues ?? {}).length > 0
      ? {
          ...snapshot?.flagSnapshot.flagFields,
          ...(currentSnapshotChangeEvents?.changes.previousValues as FlagFields),
        }
      : null;

  const last2Events = [prevSnapshotChangeEvents, currentSnapshotChangeEvents].filter(Boolean) as FlagChangeEvent[];
  const fieldBlockWidth = last2Events.length > 1 ? '50%' : '100%';

  return (
    <RockyModal
      modalStyles={styles.modal}
      title={'Flag updates'}
      isOpen={isLoading || !!snapshot}
      onClose={() => dispatch(setSnapshotDataIdle())}
      crossIcon
      modalBodyStyles={styles.modalBody}
      modalHeaderStyles={styles.modalHeader}
    >
      {isLoading ? (
        <div className={styles.loader}>
          <RocketLoader />
        </div>
      ) : (
        <Stack flexDirection="row">
          <Box sx={{ width: '100%', minHeight: '100vh' }}>
            <Stack flexDirection="row">
              {last2Events.map((snapshotChangeEvents, i) => {
                const highlight = i == 1;
                return (
                  <Typography
                    component={'div'}
                    variant="body2"
                    sx={{
                      background: highlight ? COLORS.HIGHLIGHTS.NOTE : COLORS.HIGHLIGHTS.NEUTRAL,
                      padding: '11px 0px 11px 50px',
                      color: highlight ? COLORS.TEXT.PRIMARY : undefined,
                      width: fieldBlockWidth,
                    }}
                  >
                    {snapshotChangeEvents.author.name} • {getHistoryChangeDate(snapshotChangeEvents.date)}
                  </Typography>
                );
              })}
            </Stack>
            <Box>
              {Object.entries(FlagFormFields).map(([key, value]) => (
                <Stack flexDirection="row" alignItems="start">
                  {([prevSnapshotFlagFields, currentSnapshoFlagFields].filter(Boolean) as FlagFields[]).map(
                    (flagFields, i) => {
                      const highlight = i == 1;
                      const fieldValue = get(flagFields, key);

                      return key === FlagFormFields.flagWatchers.name ? null : (
                        <Box
                          key={key}
                          className={classNames(styles.fieldBlock, {
                            [styles.fieldBlockRight]: i == 1,
                            [styles.fieldBlockHighlighted]:
                              highlight &&
                              Object.keys(currentSnapshotChangeEvents?.changes.newValues ?? {}).includes(key),
                          })}
                          sx={{
                            width: fieldBlockWidth,
                          }}
                        >
                          <div>{value.label}</div>
                          <FlagNoValueFallback
                            value={fieldValue}
                            field={(() => {
                              return value.name === FlagFormFields.severity.name ? (
                                <FlagSeverityComponent severity={fieldValue} />
                              ) : value.name === FlagFormFields.pipLink.name ? (
                                <div className={styles.fieldValue}>{fieldValue}</div>
                              ) : value.name === FlagFormFields.isEscalatedByClient.name ? (
                                <RockyCheckbox checked={!!fieldValue} disabled>
                                  {FlagFormFields.isEscalatedByClient.label}
                                </RockyCheckbox>
                              ) : value.name === FlagFormFields.emailRecipients.name ? (
                                <RecipientsPreview recipients={fieldValue} />
                              ) : [FlagFormFields.assignedTom.name, FlagFormFields.assignedPR.name].includes(
                                  value.name as
                                    | typeof FlagFormFields.assignedPR.name
                                    | typeof FlagFormFields.assignedTom.name
                                ) ? (
                                <UserReporterBlock isDetailInfo user={fieldValue} />
                              ) : (
                                <div className={styles.fieldValue}>
                                  {value.name === FlagFormFields.departments.name
                                    ? (fieldValue as DepartmentData[]).map((dep) => dep.name).join(', ')
                                    : isBoolean(fieldValue)
                                    ? fieldValue === true
                                      ? 'Yes'
                                      : 'No'
                                    : !isNaN(+fieldValue)
                                    ? fieldValue
                                    : checkIfStrDate(fieldValue)
                                    ? format(new Date(fieldValue), 'dd MMM yyyy')
                                    : value.name === FlagFormFields.status.name
                                    ? FlagStatusMapToName[fieldValue as FlagStatus]
                                    : capitalize(fieldValue)}
                                </div>
                              );
                            })()}
                          />
                        </Box>
                      );
                    }
                  )}
                </Stack>
              ))}
            </Box>
          </Box>
        </Stack>
      )}
    </RockyModal>
  );
};
