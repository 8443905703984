import { EvaluationPermissions } from '@modules/EvaluationModule/enums/evalPermissions';
import {
  AllAvailableFlagPermissions,
  AvailableFlagCompensationDetailsPermissions,
  AvailableFlagHrNotesPermissions,
  AvailableFlagNewHomeSectionPermissions,
  AvailableFlagNewHomeTomSectionPermissions,
  AvailableFlagPermissions,
  AvailableFlagResolutionPermissions,
  AvailableFlagPRTeamSectionPermissions,
} from '@modules/HappinessModule/pages/FlagPage/enums/flagPermissionsData';
import { FlagPermissionsData } from '@modules/HappinessModule/pages/FlagPage/interfaces/FlagPermissionsData';

export const getFlagsPermissions = (
  permissions: (AllAvailableFlagPermissions | EvaluationPermissions)[]
): FlagPermissionsData => {
  const flagPermissionsData: FlagPermissionsData = {
    flag: {
      isReadable: permissions.includes(AvailableFlagPermissions.Read),
      isUpdatable: permissions.includes(AvailableFlagPermissions.Update),
      isCreatable: permissions.includes(AvailableFlagPermissions.Create),
    },
    flagResolution: {
      isReadable: permissions.includes(AvailableFlagResolutionPermissions.Read),
      isUpdatable: permissions.includes(AvailableFlagResolutionPermissions.Update),
      isReopenable: permissions.includes(AvailableFlagResolutionPermissions.Reopen),
      isCreatable: false,
    },
    hrNotes: {
      isReadable: permissions.includes(AvailableFlagHrNotesPermissions.Read),
      isUpdatable: permissions.includes(AvailableFlagHrNotesPermissions.Update),
      isCreatable: permissions.includes(AvailableFlagHrNotesPermissions.Create),
    },
    compensationDetails: {
      isReadable: permissions.includes(AvailableFlagCompensationDetailsPermissions.Read),
      isUpdatable: permissions.includes(AvailableFlagCompensationDetailsPermissions.Update),
      isCreatable: permissions.includes(AvailableFlagCompensationDetailsPermissions.Create),
    },
    newHomeSection: {
      isReadable: permissions.includes(AvailableFlagNewHomeSectionPermissions.Read),
      isUpdatable: permissions.includes(AvailableFlagNewHomeSectionPermissions.Update),
      isCreatable: permissions.includes(AvailableFlagNewHomeSectionPermissions.Create),
    },
    newHomeTomSection: {
      isReadable: permissions.includes(AvailableFlagNewHomeTomSectionPermissions.Read),
      isUpdatable: permissions.includes(AvailableFlagNewHomeTomSectionPermissions.Update),
      isCreatable: permissions.includes(AvailableFlagNewHomeTomSectionPermissions.Create),
    },
    prTeamSection: {
      isReadable: permissions.includes(AvailableFlagPRTeamSectionPermissions.Read),
      isUpdatable: permissions.includes(AvailableFlagPRTeamSectionPermissions.Update),
      isCreatable: permissions.includes(AvailableFlagPRTeamSectionPermissions.Create),
    },
  };
  return flagPermissionsData;
};
