export enum FlagWatcherRole {
  FlagAuthor = 'Flag Author',
  ReportingTo = 'Reporting manager',
  PortfolioDeliveryManager = 'Portfolio delivery manager',
  DeliveryManager = 'Delivery manager',
  CreativeDirectorDesignStudio1 = 'Creative director design studio',
  CreativeDirectorDesignStudio2 = 'Creative director design studio',
  FlagAuthorAndReportingTo = 'Flag author / Reporting manager',
  Watcher = 'Watcher',
  SeniorManager = 'Senior manager',
  AssignedPRTeamMember = 'Assigned HR team member',
}
