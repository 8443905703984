import React, { FC, memo } from 'react';
import { Navigate } from 'react-router-dom';
import { Role } from '@shared/enums/role';
import { useAuth } from '@shared/hooks/useAuth';
import { paths } from '@shared/enums/paths';

// TODO: redundant -redirect to users/<currentUser.id>
const HomeRedirectPageComponent: FC = () => {
  const { currentUser } = useAuth();

  const rootRedirectPath = !currentUser
    ? null
    : currentUser.hasReporters &&
      (currentUser.rockyRoles.includes(Role.vpManager) ||
        currentUser.rockyRoles.includes(Role.teamLead) ||
        currentUser.rockyRoles.includes(Role.manager))
    ? `${paths.myTeam}/${paths.responses}`
    : `${paths.users}/${currentUser?.id}`;

  return rootRedirectPath ? (
    <Navigate to={rootRedirectPath} />
  ) : (
    <h3>You will be redirected to your home page shortly...</h3>
  );
};

export const HomeRedirectPage = memo(HomeRedirectPageComponent);
