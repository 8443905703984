import { EvalPeerReviewData } from '@modules/EvaluationModule/interfaces/EvalPeerReviewData';

export const hideAnonymousPeerReviews = (reviews: EvalPeerReviewData[]): EvalPeerReviewData[] => {
  let anonymPeerCount = 1;
  return [...reviews].map((el) =>
    el.isAnonymous
      ? {
          ...el,
          user: {
            name: 'Anonymous',
            position: '',
            imageUrl: `https://ae-happines-zoho-images-storage.s3.us-east-2.amazonaws.com/anonymPeer${anonymPeerCount++}.png`,
          },
        }
      : el
  );
};
